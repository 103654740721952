import React from "react";
import PropTypes from "prop-types";
import * as ROUTES from "../../constants/routes";
import THEMES from "../theme/Theme";
import F1Avatar from "./F1Avatar";
import useTheme from "../theme/useTheme";
import { Link } from "react-router-dom";
import classNames from "../../utils/class-names-utils";
import { getUrl } from "../../utils/route-utils";
import useIsGated from "../../hooks/useIsGated";
import { useDispatch } from "react-redux";
import {
  setGatedMessageOrigin,
  setGatedMessageType
} from "../../actions/global.actions";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { trackUserProfileClick } from "../../actions/metrics.actions";

const F1AvatarLink = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { user = {}, ...rest } = props;
  const { userUuid, isDeleted, authorUuid } = user;
  const { theme } = useTheme();
  const uid = userUuid ? userUuid : authorUuid;
  const link = getUrl(ROUTES.PROFILE_DETAIL, {
    userUuid: uid
  });
  const isGated = useIsGated();
  const dispatch = useDispatch();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const gatedClick = () => {
    trackUserProfileClick({ ...user, isGated });

    dispatch(
      setGatedMessageOrigin(UNGATED_ACTIONS.ORIGINAL_ACTIONS.AVATAR_PICTURE)
    );
    dispatch(setGatedMessageType(GATED_MODAL_TYPE.PROFILE));
  };
  /** ********************************** RENDER ***************************************/
  if (isDeleted || !uid) {
    return <F1Avatar {...user} {...rest} />;
  }

  if (isGated) {
    return <F1Avatar {...user} onClick={gatedClick} {...rest} />;
  }

  return (
    <Link
      className={classNames(
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}
      to={link}>
      <F1Avatar {...user} {...rest} />
    </Link>
  );
};

F1AvatarLink.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "xl"])
};

export default F1AvatarLink;
