import React from "react";
import useResetFilter from "../../../hooks/useResetFilter";
import i18n from "../../../utils/i18n";
import BaseEndOfFeed from "./BaseEndOfFeed";

const TopicalEndOfFeedCard = () => {
  /** ********************************** CONFIG ***************************************/
  const { resetFilters } = useResetFilter();
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <BaseEndOfFeed
      title={i18n.t("EndOfFeed.Title")}
      actionText={i18n.t("FeedFilter.reset")}
      action={resetFilters}>
      <p className="text-16 text-center">{i18n.t("EndOfFeed.P1")}</p>
    </BaseEndOfFeed>
  );
};

export default TopicalEndOfFeedCard;
