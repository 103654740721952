/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const fetchUserCommunicationPreferences = async (userId) => {
  const ref = getCollectionRef(DB_CONSTANTS.USER_COMMUNICATION_PREFS).doc(
    userId
  );

  const preferences = await ref.get();
  return preferences.exists ? preferences.data() : null;
};

export const listenForCommunicationPreferenceChanges = async (
  callback,
  userId
) => {
  return getCollectionRef(DB_CONSTANTS.USER_COMMUNICATION_PREFS)
    .doc(userId)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};
