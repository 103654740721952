import React from "react";
import PropTypes from "prop-types";
import TouchableOpacity from "../../../cme/components/TouchableOpacity";
import Image from "../../../../components/common/Image";
import { CardText } from "reactstrap";
import standInImage from "../../../../assets/images/searchTextImage.png";
import "./CaseCarousel.scss";
import { getCaseTypeUrl } from "../../../../utils/case-utils";
import { useHistory } from "react-router-dom";
import { trackPreviewFeedItemClick } from "../../../../actions/metrics.actions";
import F1Carousel from "../../../../components/common/F1Carousel";

const CaseCarouselItem = ({ imgLink, title = "", onPress }) => {
  const newTitle = title == null ? "" : title;

  return (
    <TouchableOpacity onPush={onPress}>
      <div className="rounded-square-image">
        <Image src={imgLink} fallbackSrc={standInImage} alt={newTitle} />
      </div>
      {!!title && (
        <CardText
          tag="div"
          className="text-default-black whitespace-pre-line text-clamp-21-3 mt-1 mb-2">
          {title}
        </CardText>
      )}
    </TouchableOpacity>
  );
};

CaseCarouselItem.propTypes = {
  mediaSource: PropTypes.any,
  title: PropTypes.string,
  onPress: PropTypes.func.isRequired
};

const CaseCarousel = ({
  cases,
  context,
  disableClick,
  className,
  imgSize = 168,
  colSize = 4,
  nextItemVisiblePercent = 0.2
}) => {
  /****************************** Config **************************************/
  const history = useHistory();

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/
  const handlePress = ({ data }) => {
    trackPreviewFeedItemClick({
      previewFeedType: context,
      caseUuid: data?.caseUuid
    });

    history.push({
      pathname: getCaseTypeUrl(data)
    });
  };

  /****************************** Render **************************************/
  const cells = cases?.map((item, index) => {
    const imgLink = item?.media?.[0]?.url
      ? `${item?.media?.[0]?.url}?fit=crop&w=${imgSize}&h=${imgSize}`
      : standInImage;

    return (
      <div className={`col-xs-${colSize}`} key={item?.caseUuid}>
        <CaseCarouselItem
          onPress={() =>
            disableClick ? null : handlePress({ data: item, index })
          }
          title={item?.title || item?.caption}
          imgLink={imgLink}
        />
      </div>
    );
  });

  return (
    <F1Carousel
      className={className}
      colSize={colSize}
      nextItemVisiblePercent={nextItemVisiblePercent}>
      {cells}
    </F1Carousel>
  );
};

CaseCarousel.propTypes = {
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      caseUuid: PropTypes.string,
      title: PropTypes.string,
      media: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string }))
    })
  ),
  context: PropTypes.string
};

export default CaseCarousel;
