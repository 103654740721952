import React from "react";
import ActivityItem from "./ActivityItem";
import F1FlatList from "../../../components/common/F1FlatList";
import LoadingButton from "../../../components/common/LoadingButton";
import i18n from "../../../utils/i18n";

function NotificationsFound({
  activities,
  isLoading,
  isEndReached,
  onClickActivity,
  onFetchMorePastActivity
}) {
  return (
    <F1FlatList
      renderItem={({ item }) => (
        <ActivityItem
          content={item}
          key={item.activityUuid}
          onClick={() => onClickActivity(item)}
        />
      )}
      data={activities}
      ListFooterComponent={() =>
        !isEndReached && (
          <LoadingButton
            className="rounded mt-3"
            block
            size="lg"
            color="secondary"
            spinnerColor="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              if (!isLoading) {
                onFetchMorePastActivity();
              }
            }}>
            {i18n.t("common.viewMore")}
          </LoadingButton>
        )
      }
    />
  );
}

NotificationsFound.defaultProps = {
  isLoading: false,
  isEndReached: false
};

export default NotificationsFound;
