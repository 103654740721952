import { useState, useEffect } from "react";

const BREAKPOINT_SIZES = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg"
};
const getDeviceConfig = (width) => {
  if (width < 320) {
    return BREAKPOINT_SIZES.EXTRA_SMALL;
  } else if (width >= 320 && width < 768) {
    return BREAKPOINT_SIZES.SMALL;
  } else if (width >= 768 && width < 1024) {
    return BREAKPOINT_SIZES.MEDIUM;
  } else if (width >= 1024) {
    return BREAKPOINT_SIZES.LARGE;
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = function() {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, [setBrkPnt]);

  return brkPnt;
};

export { BREAKPOINT_SIZES };
export default useBreakpoint;
