import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { fetchProfessionsIfNeeded } from "../../../actions/reference.actions";
import { signUpV2 } from "../../../actions/non-user.actions";
import { Link, useHistory } from "react-router-dom";
import FormElementEmail from "./FormElementEmail";
import FormElementPassword from "./FormElementPassword";
import { signOut, toggleStateListeners } from "../../../actions/login.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  trackRegistration,
  trackSignUpPageEntry
} from "../../../actions/metrics.actions";
import FormElementCountry from "./FormElementCountry";
import FormGlobalError from "./FormGlobalError";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { isMobile } from "react-device-detect";
import { METRICS_EVENT_PROPERTY } from "../../../metrics/constants.metrics";
import { getUrl } from "../../../utils/route-utils";

const RegistrationFormV2 = ({ inModal = false }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const onboardingNavigate = useOnboardingNavigation();
  const history = useHistory();

  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onChange"
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    dispatch(fetchProfessionsIfNeeded());
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    trackSignUpPageEntry({
      [METRICS_EVENT_PROPERTY.MODAL]: inModal
    });
  }, [inModal]);

  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      formData.email === undefined ||
      formData.password === undefined ||
      formData.country === undefined
    ) {
      return;
    }
    if (!formState.isValid) {
      return;
    }

    trackRegistration({
      country: formData.country,
      email: formData.email
    });

    setIsLoading(true);
    const result = await dispatch(
      signUpV2(formData.email, formData.password, formData.country?.value)
    );

    if (result.error && result.message) {
      setIsLoading(false);
      setErrorMessage(result.message);
    } else {
      dispatch(toggleStateListeners(result.authResult?.user, true));
      if (result.user?.group_uuids && result.user?.group_uuids.length > 0) {
        history.push(
          getUrl(ROUTES.GROUP_WELCOME_SPLASH, {
            groupUuid: result.user?.group_uuids[0]
          })
        );
      } else {
        onboardingNavigate(result.user);
      }
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0">
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <h1
            className={`${
              isMobile ? "text-18" : "text-22"
            } text-center helv-bold mb-2`}>
            {i18n.t("RegistrationScreens.createRegistration.v2.title")}
          </h1>
          <p
            className={`${
              isMobile ? "text-15" : "text-16"
            } color-battleship-gray text-center mb-3 mb-lg-4`}>
            {i18n.t("RegistrationScreens.createRegistration.v2.description")}
          </p>
          <FormGlobalError errorText={errorMessage} />
          <FormElementEmail
            errors={errors}
            control={control}
            showFeedback={true}
          />
          <FormElementPassword
            control={control}
            errors={errors}
            isLoading={isLoading}
            showFeedback={true}
            useHelpAsError="true"
            helpText={i18n.t("RegistrationScreens.loginForm.passwordRequired")}
          />
          <FormElementCountry
            control={control}
            errors={errors}
            showFeedback={true}
            helpText={i18n.t(
              "RegistrationScreens.createRegistration.v2.countryHelpText"
            )}
          />

          <LoadingButton
            className="w-100 mt-4"
            color="primary"
            loading={isLoading}
            spinnerColor="white"
            size="lg"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.createRegistration.v2.btnSignUp")}
          </LoadingButton>
          <p className="d-flex w-100 mt-4 mb-1 text-15">
            <span className="text-battleship-gray">
              {i18n.t("RegistrationScreens.createRegistration.alreadyMember")}{" "}
            </span>

            <Link
              className="text-cool-blue cursor-pointer pl-1"
              to={ROUTES.REGISTRATION_LOGIN}>
              {i18n.t("RegistrationScreens.createRegistration.signIn")}
            </Link>
          </p>
        </form>
      </Card>
    </>
  );
};

export default RegistrationFormV2;
