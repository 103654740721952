import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

const RegistrationHeader = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const logo = (
    <Link to={ROUTES.REGISTRATION}>
      <span className="navbar-brand navbar-brand-white">Figure 1 PRO</span>
    </Link>
  );

  return (
    <div className="d-flex justify-content-center align-items-center bg-navy-blue py-3 text-white">
      {logo}
    </div>
  );
};

RegistrationHeader.propTypes = {
  onContactSupport: PropTypes.func
};

export default RegistrationHeader;
