/** @format */

import { isEmpty } from "lodash";
import { checkIpCountry } from "../api/ipinfo-functions";
import * as ReferenceDb from "../db/new-reference.db";
import { fetchSchoolSuggestions } from "../utils/autocomplete-utils";
import { getPotentialGroup } from "../api/user.cloud-functions";

const actionsPrefix = "reference";

export const FETCH_SCHOOL_SUGGESTIONS = `${actionsPrefix}/FETCH_SCHOOL_SUGGESTIONS`;
export const FETCH_SCHOOL_SUGGESTIONS_COMPLETE = `${actionsPrefix}/FETCH_SCHOOL_SUGGESTIONS_COMPLETE`;
export const FETCH_SCHOOL_SUGGESTIONS_ERROR = `${actionsPrefix}/FETCH_SCHOOL_SUGGESTIONS_ERROR`;
export const FETCH_FALLBACK_LOCATIONS = `${actionsPrefix}/FETCH_FALLBACK_LOCATIONS`;
export const FETCH_FALLBACK_LOCATIONS_COMPLETE = `${actionsPrefix}/FETCH_FALLBACK_LOCATIONS_COMPLETE`;
export const CLEAR_SCHOOL_SUGGESTIONS = `${actionsPrefix}/CLEAR_SCHOOL_SUGGESTIONS`;
export const CLEAR_COUNTRIES_AND_REGIONS = `${actionsPrefix}/CLEAR_COUNTRIES_AND_REGIONS`;
export const FETCH_PROFESSIONS = `${actionsPrefix}/FETCH_PROFESSIONS`;
export const FETCH_PROFESSIONS_COMPLETE = `${actionsPrefix}/FETCH_PROFESSIONS_COMPLETE`;
export const FETCH_PROFESSIONS_ERROR = `${actionsPrefix}/FETCH_PROFESSIONS_ERROR`;
export const CLEAR_PROFESSIONS = `${actionsPrefix}/CLEAR_PROFESSIONS`;
export const FETCH_PROFESSION_SPECIALTIES = `${actionsPrefix}/FETCH_PROFESSION_SPECIALTIES`;
export const FETCH_PROFESSION_SPECIALTIES_COMPLETE = `${actionsPrefix}/FETCH_PROFESSION_SPECIALTIES_COMPLETE`;
export const FETCH_PROFESSION_SPECIALTIES_ERROR = `${actionsPrefix}/FETCH_PROFESSION_SPECIALTIES_ERROR`;
export const CLEAR_PROFESSION_SPECIALTIES = `${actionsPrefix}/CLEAR_PROFESSION_SPECIALTIES`;
export const FETCH_INTERESTS = `${actionsPrefix}/FETCH_INTERESTS`;
export const FETCH_INTERESTS_COMPLETE = `${actionsPrefix}/FETCH_INTERESTS_COMPLETE`;
export const FETCH_INTERESTS_ERROR = `${actionsPrefix}/FETCH_INTERESTS_ERROR`;
export const CLEAR_INTERESTS = `${actionsPrefix}/CLEAR_INTERESTS`;
export const FETCH_PREVIEW_FEED = `${actionsPrefix}/FETCH_PREVIEW_FEED`;
export const FETCH_PREVIEW_FEED_COMPLETE = `${actionsPrefix}/FETCH_PREVIEW_FEED_COMPLETE`;
export const FETCH_COUNTRY_COMPLETE = `${actionsPrefix}/FETCH_COUNTRY_COMPLETE`;
export const FETCH_PREVIEW_FEED_META = `${actionsPrefix}/FETCH_PREVIEW_FEED_META`;
export const FETCH_PREVIEW_FEED_META_COMPLETE = `${actionsPrefix}/FETCH_PREVIEW_FEED_META_COMPLETE`;
export const FETCH_GROUPS = `${actionsPrefix}/FETCH_GROUPS`;
export const FETCH_GROUPS_COMPLETE = `${actionsPrefix}/FETCH_GROUPS_COMPLETE`;
export const FETCH_POTENTIAL_GROUP_DETAILS = `${actionsPrefix}/FETCH_POTENTIAL_GROUP_DETAILS`;
export const FETCH_POTENTIAL_GROUP_DETAILS_COMPLETE = `${actionsPrefix}/FETCH_POTENTIAL_GROUP_DETAILS_COMPLETE`;

// not used
export const fetchSchoolsSuggestions = ({
  professionUuid,
  searchTerm,
  locationUuid
}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_SCHOOL_SUGGESTIONS
      });

      const { elasticsearchAuth, elasticsearchCloudId } =
        getState()?.configuration?.elasticSearch;

      const schoolSuggestions = await fetchSchoolSuggestions({
        accessToken: elasticsearchAuth?.access_token,
        cloudId: elasticsearchCloudId,
        professionUuid,
        searchTerm,
        locationUuid
      });

      const hits = schoolSuggestions?.hits?.hits;
      let newSuggestions = [];

      if (!isEmpty(hits)) {
        newSuggestions = hits.map((hit) => ({
          uuid: hit?._id,
          name: hit?._source?.schoolName
        }));
      }

      dispatch({
        type: FETCH_SCHOOL_SUGGESTIONS_COMPLETE,
        payload: {
          schoolSuggestions: newSuggestions
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_SCHOOL_SUGGESTIONS_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

// not used
export const clearSchoolSuggestions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SCHOOL_SUGGESTIONS
    });
  };
};

/**
 * This is meant only as fallback for country data. Expect
 * to use elastic search for this.
 */
export const fetchFallbackLocationsIfNeeded = () => {
  return async (dispatch, getState) => {
    try {
      const fallbackLocations = getState()?.references?.fallbackLocations;
      if (
        fallbackLocations.isLoadingFallbackLocations ||
        (!isEmpty(fallbackLocations.countries) &&
          !isEmpty(fallbackLocations.regions))
      ) {
        return;
      }

      dispatch({
        type: FETCH_FALLBACK_LOCATIONS
      });

      const countriesAndRegions = await ReferenceDb.getAllCountriesAndRegions();
      dispatch({
        type: FETCH_FALLBACK_LOCATIONS_COMPLETE,
        payload: {
          countriesAndRegions
        }
      });
    } catch (error) {
      console.log("fetchFallbackLocationsIfNeeded failed: ", error.message);
    }
  };
};

/**
 * This data is not needed post registration.
 */
// not used
export const clearCountriesAndRegions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_COUNTRIES_AND_REGIONS
    });
  };
};

export const fetchProfessionsIfNeeded = () => {
  return async (dispatch, getState) => {
    try {
      if (!isEmpty(getState()?.references?.professions)) {
        return;
      }

      dispatch({
        type: FETCH_PROFESSIONS
      });

      const result = await ReferenceDb.getProfessions();

      dispatch({
        type: FETCH_PROFESSIONS_COMPLETE,
        payload: {
          professions: result
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROFESSIONS_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

// not used
export const clearProfessions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_COUNTRIES_AND_REGIONS
    });
  };
};

export const fetchProfessionSpecialtiesIfNeeded = (professionUuid) => {
  return async (dispatch, getState) => {
    try {
      if (
        getState()?.references?.professionSpecialties?.[professionUuid] ||
        !professionUuid
      ) {
        return;
      }

      dispatch({
        type: FETCH_PROFESSION_SPECIALTIES
      });

      const professionSpecialties = await ReferenceDb.getProfessionSpecialties(
        professionUuid
      );

      dispatch({
        type: FETCH_PROFESSION_SPECIALTIES_COMPLETE,
        payload: { professionUuid, professionSpecialties }
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROFESSION_SPECIALTIES_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

// not used
export const clearProfessionSpecialties = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PROFESSION_SPECIALTIES
    });
  };
};

export const fetchInterestsIfNeeded = () => {
  return async (dispatch, getState) => {
    try {
      if (!isEmpty(getState()?.references?.interests)) {
        return;
      }

      dispatch({
        type: FETCH_INTERESTS
      });

      const result = await ReferenceDb.getInterests();

      dispatch({
        type: FETCH_INTERESTS_COMPLETE,
        payload: {
          interests: result
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_INTERESTS_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

// not used
export const clearInterests = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_INTERESTS
    });
  };
};

export const getPreviewFeedIfNeeded = ({ feedTypeUuid, limit = 100 }) => {
  return async (dispatch, getState) => {
    try {
      if (isEmpty(getState()?.references?.feeds?.[feedTypeUuid])) {
        dispatch({
          type: FETCH_PREVIEW_FEED,
          payload: { feedTypeUuid }
        });

        const feed = await ReferenceDb.getPreviewFeed({ feedTypeUuid, limit });
        dispatch({
          type: FETCH_PREVIEW_FEED_COMPLETE,
          payload: { feed, feedTypeUuid }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPreviewFeedMetaIfNeeded = () => {
  return async (dispatch, getState) => {
    try {
      if (isEmpty(getState()?.references?.feedsMeta)) {
        dispatch({
          type: FETCH_PREVIEW_FEED_META
        });

        const feedsMeta = await ReferenceDb.getPreviewFeedMeta();
        dispatch({
          type: FETCH_PREVIEW_FEED_META_COMPLETE,
          payload: { feedsMeta }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkCurrentCountry = () => {
  return async (dispatch) => {
    try {
      const response = await checkIpCountry();
      return dispatch({
        type: FETCH_COUNTRY_COMPLETE,
        payload: {
          country: response?.country?.toUpperCase()
        }
      });
    } catch (e) {
      // if anything goes wrong with the ip check, default it to US
      return dispatch({
        type: FETCH_COUNTRY_COMPLETE,
        payload: {
          country: "US",
          error: true,
          message: e.message
        }
      });
    }
  };
};

export const getGroupsInfoIfNeeded = () => {
  return async (dispatch, getState) => {
    try {
      if (isEmpty(getState()?.references?.groups)) {
        dispatch({
          type: FETCH_GROUPS
        });

        const groups = await ReferenceDb.getGroups();
        dispatch({
          type: FETCH_GROUPS_COMPLETE,
          payload: { groups }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPotentialGroupDetails = (groupFilterUuid) => {
  return async (dispatch) => {
    try {
      if (!groupFilterUuid) return;
      dispatch({
        type: FETCH_POTENTIAL_GROUP_DETAILS
      });

      const data = await getPotentialGroup(groupFilterUuid);
      dispatch({
        type: FETCH_POTENTIAL_GROUP_DETAILS_COMPLETE,
        payload: { potentialGroupDetails: data.store }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
