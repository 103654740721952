/** @format */

/**
 * @param {Array<{language: string, text: string}>} translations
 * @param {string} language
 */
export const hasTranslation = (translations, language) => {
  return findTranslation(translations, language) !== undefined;
};

/**
 * @param {Array<{language: string, text: string}>} translations
 * @param {string} language
 */
export const findTranslation = (translations, language) => {
  return (translations ?? []).find((translation) =>
    areEqual(translation.language, language)
  );
};

/**
 * @param {string} languageA
 * @param {string} languageB
 */
export const areEqual = (languageA, languageB) => {
  const languageCodeA = getLanguageCode(languageA);
  const languageCodeB = getLanguageCode(languageB);
  return languageCodeA.toLowerCase() === languageCodeB.toLowerCase();
};

/**
 * @param {string} language The language code or tag. i.e. en-US, en-GB, en
 * @returns the language code. i.e. en-US returns en
 */
export const getLanguageCode = (language) => {
  if (language.indexOf("-") !== -1) {
    return language.split("-")[0];
  }

  if (language.indexOf("_") !== -1) {
    language = language.split("_")[0];
  }

  return language;
};
