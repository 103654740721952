import { urlRedirectCall } from "../api/legacy.cloud-functions";

export const getRouteFromLegacyUrl = async (url) => {
  async function checkLegacyLink(url) {
    const response = await urlRedirectCall(url);
    return response.headers?.location;
  }

  if (!url) {
    return null;
  }

  const legacyUrl = await checkLegacyLink(url);
  return legacyUrl;
};
