import React, { useState } from "react";
import THEMES from "./Theme";
import ThemeContext from "./ThemeContext";
import { getDisplayName } from "../../utils/hoc-utils";

export const ThemeProvider = ({
  theme: defaultTheme = THEMES.light,
  children
}) => {
  /************************************ CONFIG ***************************************/
  const [theme, setTheme] = useState(defaultTheme);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const withTheme = ({ theme = THEMES.LIGHT }) => (Component) => {
  const WithTheme = (props) => {
    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    );
  };

  WithTheme.displayName = `WithTheme(${getDisplayName(Component)})`;

  return WithTheme;
};

export const withLightTheme = withTheme({
  theme: THEMES.LIGHT
});

export const withDarkTheme = withTheme({
  theme: THEMES.DARK
});

export default ThemeProvider;
