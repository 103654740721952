import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import CaseLabelGroup from "../../../components/upload-case/CaseLabelGroup";
import i18n from "../../../utils/i18n";
import PropTypes from "prop-types";
import Select from "react-select";
import { CASE_LABEL_TYPES } from "../../../constants/case-label-types";
import { getSpecialtiesOptions } from "../../../actions/case-specialties.actions";
import FormFeedback from "reactstrap/lib/FormFeedback";
import AutoSizer from "../../../components/common/AutoSizer";
import LoadingButton from "../../../components/common/LoadingButton";
import { includesInput } from "../../../utils/search-utils";
import TitleSummaryInputs from "./TitleSummaryInputs";

const PageSideColumn = ({
  draft,
  caseLabels,
  onSubmit,
  control,
  errors,
  getValues,
  isSaving
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();

  const specialtiesOptions = dispatch(getSpecialtiesOptions());

  const [caseLabelList, setCaseLabelList] = useState(null);

  const primarySpecialtyToPageClassName = errors.specialties
    ? "react-select is-invalid"
    : "react-select";
  const subSpecialtyToPageClassName = errors.subSpecialties
    ? "react-select is-invalid"
    : "react-select";
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (caseLabels && caseLabels?.length > 0) {
      const labels = [];

      // common
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.COMMON_PRESENTATION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );

      // trending
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.TEACHING_CASE
        )[0] || {
          name: "",
          labelUuid: "nolabelresolved"
        }
      );

      // rare
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.RARE_CONDITION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );

      setCaseLabelList(labels);
    }
  }, [caseLabels]);

  /** ********************************* FUNCTIONS *************************************/

  const validateSpeciality = (value) => {
    if (Array.isArray(value) && (value.length < 1 || value.length > 3)) {
      return false;
    }

    return !!value;
  };

  const validateSubSpeciality = (value) => {
    if (!value) return false;
    if (value.value) return true; // single item, will sort this out in for data submission
    return Array.isArray(value) && value.length <= 3;
  };

  /** ********************************** RENDER ***************************************/

  return (
    <form onSubmit={onSubmit}>
      <AutoSizer
        className="position-lg-fixed b-lg-2 overflow-y-lg-auto"
        setOnce={true}>
        <Card className="p-3">
          <div className="pb-3">
            <TitleSummaryInputs
              draft={draft}
              control={control}
              errors={errors}
            />
          </div>
          <div className="border-bottom border-width-3 border-lighter-gray w-100 px-4" />
          <div className="pt-3 pb-3">
            <CaseLabelGroup
              control={control}
              caseLabels={caseLabelList}
              draftLabels={draft?.labelsUuid || []}
              label={i18n.t("shareCase.labelYourCaseMinimum")}
              requiredText={i18n.t("shareCase.labelRequired")}
              errors={errors.caseLabels}
              getValues={getValues}
            />

            <div className="border-bottom border-width-3 border-lighter-gray w-100 px-4 mt-3" />

            <p className="text-16 font-weight-bold text-default-black mt-3">
              {i18n.t("shareCase.categorizeYourCase")}
            </p>
            <FormGroup>
              <Label for="specialties">
                {i18n.t("shareCase.selectSpeciality")}
              </Label>
              <Controller
                control={control}
                name="specialties"
                rules={{
                  validate: validateSpeciality
                }}
                invalid={!!errors.specialties ? 1 : 0}
                defaultValue={specialtiesOptions.specialties.filter((s) =>
                  (draft?.specialtiesUuid || []).includes(s.value)
                )}
                as={
                  <Select
                    style={{
                      width: "100%",
                      resize: "none",
                      borderColor: "#ccd3d8",
                      borderRadius: "1px",
                      lineHeight: "1.3rem",
                      padding: "10px 15px 10px 15px"
                    }}
                    options={specialtiesOptions.specialties}
                    className={primarySpecialtyToPageClassName}
                    placeholder={i18n.t("shareCase.specialityPlaceholder")}
                    isMulti
                    filterOption={includesInput}
                  />
                }
              />
              <FormFeedback>
                {i18n.t("shareCase.primarySpecialtyRequired")}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="subSpecialties">
                {i18n.t("shareCase.selectSubSpeciality")}
              </Label>
              <Controller
                control={control}
                name="subSpecialties"
                rules={{
                  validate: validateSubSpeciality
                }}
                invalid={!!errors.subSpecialties ? 1 : 0}
                defaultValue={specialtiesOptions.subSpecialties.filter((s) =>
                  (draft?.subSpecialtiesUuid || []).includes(s.value)
                )}
                as={
                  <Select
                    style={{
                      width: "100%",
                      resize: "none",
                      borderColor: "#ccd3d8",
                      borderRadius: "1px",
                      lineHeight: "1.3rem",
                      padding: "10px 15px 10px 15px"
                    }}
                    options={specialtiesOptions.subSpecialties}
                    className={subSpecialtyToPageClassName}
                    isMulti
                    placeholder={i18n.t("shareCase.specialityPlaceholder")}
                    filterOption={includesInput}
                  />
                }
              />
              <FormFeedback>
                {i18n.t("shareCase.secondarySpecialtyMax")}
              </FormFeedback>
            </FormGroup>
          </div>

          <LoadingButton
            spinnerSize="sm"
            spinnerColor="secondary"
            disabled={isSaving}
            loading={isSaving}
            color="primary"
            size="md"
            type="submit"
            className="w-100 text-15 py-3">
            {i18n.t("shareCase.buttonPageCase")}
          </LoadingButton>
        </Card>
      </AutoSizer>
    </form>
  );
};

PageSideColumn.propTypes = {
  saveCase: PropTypes.func,
  caseLabels: PropTypes.array
};

export default PageSideColumn;
