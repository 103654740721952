/** @format */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import i18n from "../../utils/i18n";

const NextQuestionButton = ({ onClick, disabled, isPoll }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Button
      block
      className="text-16"
      color={isPoll ? "aqua" : "denim-blue"}
      disabled={disabled}
      onClick={onClick}
      outline={disabled}
      size="lg">
      {disabled
        ? i18n.t("questionDetailCard.answerRequired")
        : i18n.t("questionDetailCard.nextQuestion")}
    </Button>
  );
};

NextQuestionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isPoll: PropTypes.bool.isRequired
};

export default NextQuestionButton;
