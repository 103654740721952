/** @format */
import { sortBy } from "lodash";

import {
  CLEAR_COUNTRIES_AND_REGIONS,
  CLEAR_SCHOOL_SUGGESTIONS,
  FETCH_SCHOOL_SUGGESTIONS_COMPLETE,
  FETCH_SCHOOL_SUGGESTIONS_ERROR,
  FETCH_SCHOOL_SUGGESTIONS,
  FETCH_FALLBACK_LOCATIONS_COMPLETE,
  FETCH_FALLBACK_LOCATIONS,
  FETCH_PROFESSIONS,
  FETCH_PROFESSIONS_COMPLETE,
  FETCH_PROFESSIONS_ERROR,
  FETCH_PROFESSION_SPECIALTIES,
  FETCH_PROFESSION_SPECIALTIES_COMPLETE,
  FETCH_PROFESSION_SPECIALTIES_ERROR,
  CLEAR_PROFESSION_SPECIALTIES,
  FETCH_INTERESTS,
  FETCH_INTERESTS_COMPLETE,
  FETCH_INTERESTS_ERROR,
  FETCH_PREVIEW_FEED_COMPLETE,
  FETCH_PREVIEW_FEED,
  FETCH_PREVIEW_FEED_META_COMPLETE,
  FETCH_PREVIEW_FEED_META,
  FETCH_COUNTRY_COMPLETE,
  FETCH_GROUPS,
  FETCH_GROUPS_COMPLETE,
  FETCH_POTENTIAL_GROUP_DETAILS,
  FETCH_POTENTIAL_GROUP_DETAILS_COMPLETE
} from "../actions/reference.actions";
import { StaticStrings } from "../constants/static-string-constants";

export const INITIAL_STATE = {
  schoolSuggestions: [],
  isLoading: false,
  error: null,
  fallbackLocations: {
    isLoadingFallbackLocations: false,
    countries: [],
    regions: [] // states and provinces
  },
  professions: [],
  professionSpecialties: {},
  interests: [],
  feeds: {},
  feedsMeta: {},
  country: null,
  groups: {}
};

const referenceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SCHOOL_SUGGESTIONS:
    case FETCH_PROFESSIONS:
    case FETCH_PROFESSION_SPECIALTIES:
    case CLEAR_PROFESSION_SPECIALTIES:
    case FETCH_INTERESTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SCHOOL_SUGGESTIONS_COMPLETE:
      return {
        ...state,
        isLoading: false,
        schoolSuggestions: action?.payload?.schoolSuggestions
      };
    case FETCH_FALLBACK_LOCATIONS:
      return {
        ...state,
        fallbackLocations: {
          ...state.fallbackLocations,
          isLoadingFallbackLocations: true
        }
      };
    case FETCH_FALLBACK_LOCATIONS_COMPLETE:
      return formatFallbackLocations(state, action);
    case CLEAR_COUNTRIES_AND_REGIONS:
      return {
        ...state,
        fallbackLocations: {
          isLoadingFallbackLocations: false,
          countries: [],
          regions: []
        }
      };
    case FETCH_PROFESSIONS_COMPLETE:
      return {
        ...state,
        isLoading: false,
        professions: formatProfessions(action.payload.professions)
      };
    case FETCH_PROFESSION_SPECIALTIES_COMPLETE:
      if (action?.payload?.professionUuid) {
        const newSpecialties = { ...state.professionSpecialties };
        newSpecialties[action?.payload?.professionUuid] =
          action?.payload?.professionSpecialties;

        return {
          ...state,
          isLoading: false,
          professionSpecialties: { ...newSpecialties }
        };
      }
      return {
        ...state,
        isLoading: false
      };
    case FETCH_INTERESTS_COMPLETE:
      return {
        ...state,
        isLoading: false,
        interests: action?.payload?.interests
      };
    case FETCH_SCHOOL_SUGGESTIONS_ERROR:
    case FETCH_PROFESSIONS_ERROR:
    case FETCH_PROFESSION_SPECIALTIES_ERROR:
    case FETCH_INTERESTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error
      };
    case CLEAR_SCHOOL_SUGGESTIONS:
      return {
        ...state,
        schoolSuggestions: []
      };
    case FETCH_PREVIEW_FEED:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [action.payload.feedTypeUuid]: {
            data: state.feeds[action.payload.feedTypeUuid]?.data,
            loading: true
          }
        }
      };
    case FETCH_PREVIEW_FEED_COMPLETE:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [action.payload.feedTypeUuid]: {
            data: action.payload.feed,
            loading: false
          }
        }
      };
    case FETCH_PREVIEW_FEED_META:
      return {
        ...state,
        feedsMeta: {
          ...state.feedsMeta,
          data: {},
          loading: true
        }
      };
    case FETCH_PREVIEW_FEED_META_COMPLETE:
      return {
        ...state,
        feedsMeta: {
          ...state.feedsMeta,
          data: action.payload.feedsMeta,
          loading: false
        }
      };
    case FETCH_COUNTRY_COMPLETE:
      const newProps = {
        country: action?.payload?.country
      };
      if (action?.payload?.error) {
        newProps.error = action?.payload?.error;
      }

      return {
        ...state,
        ...newProps
      };
    case FETCH_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {},
          loading: true
        }
      };
    case FETCH_GROUPS_COMPLETE:
      return {
        ...state,
        groups: {
          ...state.groups,
          data: action.payload.groups,
          loading: false
        }
      };

    case FETCH_POTENTIAL_GROUP_DETAILS:
      return {
        ...state,
        potentialGroupDetails: {
          data: {},
          loading: true
        }
      };
    case FETCH_POTENTIAL_GROUP_DETAILS_COMPLETE:
      return {
        ...state,
        potentialGroupDetails: {
          data: action.payload.potentialGroupDetails,
          loading: false
        }
      };
    default:
      return state;
  }
};

/**
 * Separate profession categories and specific professions and format data for picker.
 *
 * Most profession categories are professions, but there are 2 categories that
 * have multiple different specific professions: "Other HCP" and "Other Student".
 *
 * Non "Other" categories (like physician, nurse etc) only have 1 profession, with
 * the same name as its category, while these "Other" categories have multiple
 * different specific professions. Selecting either of these "Other" professions
 * requires further specification of profession.
 */
const formatProfessions = (professions) => {
  const categories = [];
  const specificProfessions = {};

  Object.keys(professions).forEach((key) => {
    const profession = professions[key];

    if (
      !categories.some(
        (category) => category.value === profession.professionCategoryLabel
      )
    ) {
      categories.push({
        label: profession.professionCategoryName,
        value: profession.professionCategoryLabel,
        displayOrder: profession.displayOrder,
        ...(profession.hidden && { hidden: profession.hidden }) // Backend needs to add this in later.
      });
    }

    // Create a list of specific professions categorized by profession category
    const currentSpecificProfessions =
      specificProfessions[profession.professionCategoryLabel] || [];

    specificProfessions[profession.professionCategoryLabel] = [
      ...currentSpecificProfessions,
      profession
    ];
  });

  // Format and sort specific professions in each category
  Object.keys(specificProfessions).forEach((key) => {
    const _professions = (specificProfessions?.[key] || []).map((p) => ({
      label: p.professionName,
      value: p.professionUuid
    }));
    specificProfessions[key] = sortBy(_professions, ["label"]);
  });

  return {
    categories: sortBy(categories, ["displayOrder"]),
    specificProfessions,
    raw: professions,
    isLoadingProfessions: false
  };
};

const formatFallbackLocations = (state, action) => {
  const countriesAndRegions = action.payload.countriesAndRegions;

  const { countries, regions } = countriesAndRegions.reduce(
    (acc, country) => {
      acc.countries.push({
        label: country.countryName,
        value: country.countryUuid,
        countryCode: country.countryCode
      });

      if (country?.countryRegions?.length) {
        country.countryRegions.forEach((r) => {
          acc.regions.push({
            regionCode: r.regionCode,
            label: r.regionName,
            value: r.regionUuid,
            countryCode: country.countryUuid
          });
        });
      }

      return acc;
    },
    {
      countries: [],
      regions: []
    }
  );

  // sort country by label. Put US first, Canada second.
  countries.sort((a, b) => {
    if (a.label === b.label) return 0;
    if (a.label === StaticStrings.usa) return -1;
    if (b.label === StaticStrings.usa) return 1;
    if (a.label === StaticStrings.canada) return -1;
    if (b.label === StaticStrings.canada) return 1;

    return a.label?.localeCompare(b.label);
  });

  return {
    ...state,
    fallbackLocations: {
      ...state.fallbackLocations,
      countries: countries,
      regions: sortBy(regions, "label"),
      isLoadingFallbackLocations: false
    }
  };
};

export default referenceReducer;
