import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModalWithText = ({
  toggle,
  isOpen,
  title,
  children,
  onSubmit,
  route,
  showFooter,
  dividerClass = "border-bottom border-width-4 lighter-gray w-100 pt-3 px-4",
  confirmText = "common.confirm",
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  const titleEl = React.isValidElement(title) ? (
    title
  ) : (
    <p className="font-weight-500 text-16 mt-3 mb-0">{title}</p>
  );

  return (
    <Modal
      className="modal-profile modal-dialog-scrollable modal-profile-edit"
      fade={false}
      centered
      isOpen={isOpen}
      toggle={toggle}
      {...rest}>
      <div
        className="d-flex justify-content-end cursor-pointer p-2"
        onClick={toggle}>
        <CustomIcon
          icon="modal_exit"
          className="icon-default-black"
          size={14}
        />
      </div>

      <div className="px-4">
        {titleEl}
        <div className={dividerClass} />
      </div>

      <ModalBody>{children}</ModalBody>
      {showFooter && (
        <ModalFooter className="d-inline-flex justify-content-between align-items-center w-100">
          <Button
            color="primary"
            onClick={toggle}
            tag={Link}
            to={route}
            className="text-16 p-2">
            {t(confirmText)}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

ModalWithText.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  route: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  showFooter: PropTypes.bool
};

ModalWithText.defaultProps = {};

export default ModalWithText;
