import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import PropTypes from "prop-types";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import CommentDropdownItem from "./CommentDropdownItem";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import CaseReportDialog from "../../components/case/case-report-popup/CaseReportDialog";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { reportComment, deleteComment } from "../../actions/comment.actions";
import CommentDeleteDialog from "./comment-delete-popup/CommentDeleteDialog";
import i18n from "../../utils/i18n";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

const CommentDropdown = ({
  caseUuid,
  reportEnabled,
  deleteEnabled,
  editEnabled,
  onEditClick,
  comment
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  /** ********************************* FUNCTIONS *************************************/

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const gatedToggleDropdown = useGatedActionGate(
    toggleDropdown,
    GATED_MODAL_TYPE.GENERIC,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.COMMENT_DROPDOWN
  );

  const onSubmitReport = async (reportId) => {
    setIsOpen(false);

    await dispatch(reportComment(caseUuid, comment.commentUuid, reportId));
  };

  const onDeleteComment = async () => {
    setIsDeleteOpen(false);

    await dispatch(deleteComment(caseUuid, comment.commentUuid));
  };

  const reportCommentTap = async () => {
    setIsOpen(true);
  };

  const deleteCommentTap = async () => {
    setIsDeleteOpen(true);
  };

  const verifiedReportCaseTap = useVerifiedUserGate(reportCommentTap);

  const options = [];

  if (!deleteEnabled && reportEnabled) {
    options.push(
      <CommentDropdownItem
        handleClick={verifiedReportCaseTap}
        key="report"
        icon="sheet_report"
        iconColor="#ed1c30"
        primaryText={i18n.t("comments.reportCommentPrimaryText")}
        secondaryText={i18n.t("comments.reportCommentSecondaryText")}
      />
    );
  }

  if (deleteEnabled) {
    options.push(
      <CommentDropdownItem
        handleClick={deleteCommentTap}
        key="delete"
        icon="sheet_report"
        iconColor="#ed1c30"
        primaryText={i18n.t("comments.deleteCommentPrimaryText")}
        secondaryText={i18n.t("comments.deleteCommentSecondaryText")}
      />
    );
  }

  if (editEnabled) {
    options.push(
      <CommentDropdownItem
        handleClick={onEditClick}
        key="edit"
        icon="profile_edit_pencil"
        primaryText={i18n.t("comments.editComment")}
      />
    );
  }

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {isEmpty(options) ? null : (
        <Dropdown
          className="dropdown-profile"
          isOpen={isDropdownOpen}
          toggle={gatedToggleDropdown}>
          <DropdownToggle
            className="pl-3 cursor-pointer pr-0"
            tag="span"
            aria-expanded={isDropdownOpen}>
            <CustomIcon icon="ellipses" color="#6b778b" size={20} />
          </DropdownToggle>
          <DropdownMenu right>{options}</DropdownMenu>
        </Dropdown>
      )}
      <CaseReportDialog
        isComment={true}
        onSubmit={onSubmitReport}
        toggle={() => setIsOpen(false)}
        initialItem={null}
        isOpen={isOpen}
      />

      <CommentDeleteDialog
        onSubmit={onDeleteComment}
        toggle={() => setIsDeleteOpen(false)}
        isOpen={isDeleteOpen}
        comment={comment}
      />
    </>
  );
};

CommentDropdown.propTypes = {
  caseUuid: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  reportEnabled: PropTypes.bool
};

export default CommentDropdown;
