import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeedStructure from "./components/FeedStructure";
import { feedHasEOF } from "../../utils/feed-utils";
import feedActions from "../../actions/feeds.actions";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";

const TopicalFeedScreen = (props) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const feedStore = useSelector((state) => state.feeds?.feedList);
  const feedTypeUuid = useCurrentFeedTypeUuid();
  const contentFeedFilters = useSelector(
    (state) => state.feeds.filters[feedTypeUuid] || {}
  );
  const hasActiveFilter = !!Object.values(contentFeedFilters).find((v) => !!v);
  const visibleFeed = feedTypeUuid ? feedStore[feedTypeUuid] : null;
  const dataToServe = visibleFeed?.feed || [];
  const hasEOF = visibleFeed?.eof || feedHasEOF(dataToServe);
  const isProcessing = visibleFeed?.isProcessing;

  const onRefresh = useCallback(() => {
    dispatch(feedActions.fetchFeed(feedTypeUuid, true));
  }, [dispatch, feedTypeUuid]);

  const loadMore = useCallback(() => {
    if (!hasEOF && !isProcessing) {
      dispatch(feedActions.fetchFeed(feedTypeUuid, true));
    }
  }, [dispatch, feedTypeUuid, hasEOF, isProcessing]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (feedTypeUuid && !dataToServe?.length && !isProcessing && !hasEOF) {
      dispatch(feedActions.fetchFeed(feedTypeUuid));
    }
  }, [dispatch, dataToServe, feedTypeUuid, isProcessing, hasEOF]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (
    !isProcessing &&
    (!dataToServe.length || (dataToServe.length === 1 && hasEOF)) &&
    hasActiveFilter
  ) {
    return <>{props.emptyFeedComponent}</>;
  }

  return (
    <>
      <FeedStructure
        onRefresh={onRefresh}
        loadMore={loadMore}
        data={dataToServe}
        isLoading={isProcessing}
        isEOF={true}
        feedTypeUuid={feedTypeUuid}
        eofCard={props.eofComponent}
      />
    </>
  );
};

export default TopicalFeedScreen;
