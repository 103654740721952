import React from "react";
import { Button } from "reactstrap";
import i18n from "../../../utils/i18n";

import "./DeletedPostItem.scss";

const DeletedPostItem = ({ title }) => {
  return (
    <>
      {title}
      <div>
        <Button
          className="toggle-button color-battleship-gray px-0"
          color="link">
          {i18n.t("ActivityScreen.deletedCaseButton")}
        </Button>
      </div>
    </>
  );
};

export default DeletedPostItem;
