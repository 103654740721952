import React from "react";
import { useTranslation } from "react-i18next";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import { capitalize, get } from "lodash";
import { generateYearRangeOptions } from "../../utils/common-verification.utils";

const F1YearPicker = React.forwardRef(
  (
    {
      canSelectPresent,
      startYear,
      endYear,
      onChangeYear,
      value,
      placeholder,
      onChange,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const yearOptions = generateYearRangeOptions({
      start: startYear,
      end: endYear
    });

    const items = canSelectPresent
      ? [
          {
            key: "present",
            label: capitalize(t("common.present")),
            value: capitalize(t("common.present"))
          },
          ...yearOptions
        ]
      : yearOptions;

    return (
      <CustomInput
        innerRef={ref}
        type="select"
        onChange={(event) => onChangeYear(get(event, "target.value"))}
        value={value}
        {...props}>
        <option value="">{placeholder}</option>
        {items.map(({ key, value, label }) => (
          <option key={key} value={value}>
            {label}
          </option>
        ))}
      </CustomInput>
    );
  }
);

F1YearPicker.propTypes = {
  canSelectPresent: PropTypes.bool, // if show `Present` option
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  onChangeYear: PropTypes.func.isRequired
};

export default F1YearPicker;
