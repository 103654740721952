import { useDispatch, useSelector } from "react-redux";
import { tickUngatedViewCount } from "../actions/global.actions";
import {
  getAndParseLocalStorageItem,
  setLocalStorageItem
} from "../utils/local-storage-utils";

function useGatedBlockCounter() {
  const dispatch = useDispatch();

  const LIMIT = 3;
  const STORAGE_NAME = "gated-view-count";
  const sessionCount = useSelector((state) => state?.global?.ungatedViewCount);
  let currentCount = getAndParseLocalStorageItem(STORAGE_NAME) || 0;
  const tickCount = () => {
    const newCount = (currentCount += 1);
    setLocalStorageItem(STORAGE_NAME, newCount);
    dispatch(tickUngatedViewCount());
  };
  return {
    currentCount,
    countLimit: LIMIT,
    tickCount,
    exceededLimit: () => currentCount > LIMIT,
    sessionCount
  };
}

export default useGatedBlockCounter;
