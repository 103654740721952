/** @format */

const actionsPrefix = "videoPlayer";

export const SET_VIDEO_STATE = `${actionsPrefix}/SET_VIDEO_STATE`;

export const setVideoState = (
  contentUuid,
  videoState = {
    currentTime: 0
  }
) => {
  return (dispatch) => {
    dispatch({
      type: SET_VIDEO_STATE,
      payload: {
        contentUuid,
        videoState
      }
    });
  };
};
