/** @format */

import React, { useEffect, useRef } from "react";
import { Carousel, CarouselIndicators, CarouselItem, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, isEmpty, noop } from "lodash";
import PropTypes from "prop-types";
import { updateCaseProgress } from "../../../actions/case.actions";
import { trackQuestionAnswer } from "../../../actions/metrics.actions";
import { quizVote } from "../../../actions/quiz.actions";
import CME_SECTION from "../../../constants/cme-section";
import { CaseDataProp } from "../../../prop-types/ContentProp";
import { useSelectUserVote } from "../../../selectors/interactive-case-state";
import i18n from "../../../utils/i18n";
import { isFreeFormQuizContent } from "../../../utils/tactic-utils";
import useCarouselNavigation from "../../../hooks/useCarouselNavigation";
import CMEModalSection from "./CMEModalSection";
import CMEQuestionSlide from "./CMEQuestionSlide";
import CMEModalButton from "./CMEModalFooterButton";
import useTrackSlideView from "../../../hooks/useTrackSlideView";
import useIsGated from "../../../hooks/useIsGated";

const CMEModalSectionQuestions = ({
  caseContent,
  sectionType,
  onExit,
  navigateToSection,
  initialIndex
}) => {
  /****************************** Config **************************************/
  const dispatch = useDispatch();
  const modalSectionRef = useRef(null);

  const contentItems = caseContent?.[sectionType] || [];

  const { activeIndex, next, previous, onExiting, onExited } =
    useCarouselNavigation({ items: contentItems, currentIndex: initialIndex });

  const currentItem = contentItems?.[activeIndex];
  const isFreeFormContent = isFreeFormQuizContent(currentItem);
  const hasAnswer = useSelectUserVote(
    caseContent.caseUuid,
    currentItem?.contentUuid
  );
  const textInput = useSelector(
    (state) => state.quiz.textInput?.[currentItem?.contentUuid]
  );
  const nextDisabled = isFreeFormContent ? isEmpty(textInput) : !hasAnswer;

  /****************************** Hooks ***************************************/

  useEffect(() => {
    modalSectionRef.current.scrollTop = 0;
  }, [activeIndex]);
  const isGated = useIsGated();

  /****************************** Functions ***********************************/

  const moveNext = () => {
    const nextIndex = Math.min(activeIndex + 1, contentItems.length - 1);

    // free form quiz text needs to be submitted when user click next button,
    // This is different than normal quizzes, in which submission happens right after
    // user select any quiz option.
    if (isFreeFormContent) {
      onSubmitTextInput();
    }

    if (activeIndex === contentItems.length - 1) {
      finish();
    } else {
      updateProgress(nextIndex);
    }

    next();
  };

  const finish = () => {
    let nextSection = null;
    /*
     * Pre-test goes to activity
     * Post-test goes to test summary
     * Survey goes to complete
     * */
    if (sectionType === CME_SECTION.PRE_TEST) {
      nextSection = CME_SECTION.ACTIVITY;
    } else if (sectionType === CME_SECTION.POST_TEST) {
      nextSection = CME_SECTION.POST_TEST_SUMMARY;
    } else if (sectionType === CME_SECTION.SURVEY) {
      nextSection = CME_SECTION.COMPLETION;
    }

    if (nextSection) {
      sendMetrics();
      navigateToSection(nextSection);
    }
  };

  const updateProgress = (index) => {
    const nextItemOrder = contentItems[index]?.displayOrder;
    sendMetrics();
    dispatch(updateCaseProgress(caseContent.caseUuid, nextItemOrder, null));
  };
  const { onNext, onPrev } = useTrackSlideView({
    items: contentItems,
    activeIndex,
    next: moveNext,
    previous
  });

  /****************************** Hooks ***************************************/

  useEffect(() => {
    modalSectionRef.current.scrollTop = 0;
  }, [activeIndex]);

  /****************************** Functions ***********************************/

  const sendMetrics = () => {
    const selectedAnswer = contentItems[activeIndex].questionOptions.find(
      (q) => q.questionOptionUuid === hasAnswer
    );

    trackQuestionAnswer({
      campaignUuid: caseContent?.campaignUuid,
      caseUuid: caseContent?.caseUuid,
      contentItem: contentItems[activeIndex],
      answer: selectedAnswer,
      isUngated: isGated
    });
  };

  const onVote = async (questionOptionUuid) => {
    try {
      dispatch(
        quizVote(
          contentItems[activeIndex]?.contentUuid,
          questionOptionUuid,
          caseContent.caseUuid
        )
      );
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
  };

  const onSubmitTextInput = async () => {
    // It's a bit weird, but text quiz has a single questionOption item.
    // That questionOptionUuid is required for text input submission
    const questionOptionUuid =
      currentItem.questionOptions?.[0]?.questionOptionUuid;

    try {
      dispatch(
        quizVote(
          currentItem?.contentUuid,
          questionOptionUuid,
          caseContent.caseUuid,
          textInput
        )
      );
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
  };

  /****************************** Render **************************************/

  const slides = contentItems.map((item, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
        <CMEQuestionSlide
          content={item}
          index={index + 1}
          onVote={onVote}
          heading={sectionType}
        />
      </CarouselItem>
    );
  });

  return (
    <CMEModalSection
      ref={modalSectionRef}
      onExit={onExit}
      footer={
        <>
          <Col>
            {activeIndex !== 0 && (
              <CMEModalButton
                className="btn-cme-question-back text-lighter-gray float-left"
                color="battleship-gray"
                outline
                onClick={previous}>
                {capitalize(i18n.t("common.back"))}
              </CMEModalButton>
            )}
          </Col>
          <Col xs="auto">
            <CMEModalButton
              className="float-right"
              color="denim-blue"
              onClick={onNext}
              disabled={nextDisabled}>
              {activeIndex === contentItems.length - 1
                ? capitalize(i18n.t("common.submit"))
                : capitalize(i18n.t("common.next"))}
            </CMEModalButton>
          </Col>
        </>
      }>
      <Carousel
        className="pt-0 w-100"
        activeIndex={activeIndex}
        next={onNext}
        previous={onPrev}
        interval={false}>
        <CarouselIndicators
          className="sticky-top t-0 w-100 mx-0 mb-1 pt-4 bg-darkest opacity-90"
          items={contentItems}
          activeIndex={activeIndex}
          onClickHandler={noop}
        />
        {slides}
      </Carousel>
    </CMEModalSection>
  );
};

CMEModalSectionQuestions.propTypes = {
  caseContent: CaseDataProp.isRequired,
  sectionType: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
  navigateToSection: PropTypes.func.isRequired
};

export default CMEModalSectionQuestions;
