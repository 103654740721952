/** @format */

import React from "react";
import "./FormGlobalError.scss";

const FormGlobalError = ({ errorText, classNames = "text-center" }) => {
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  if (!errorText) return null;

  return (
    <div className={`form-global-error alert px-3 ${classNames}`}>
      {errorText}
    </div>
  );
};

export default FormGlobalError;
