import React from "react";
import i18n from "../../utils/i18n";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

import "./CategoryStructure.scss";
import SpecialtyList from "./SpecialtyList";

const CategoryStructure = (props) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Card className="nav-structure p-2 border-0 mb-3 shadow">
      <h3 className="font-weight-bold text-12 text-uppercase py-2 mb-2">
        {i18n.t("navigation.channels")}
      </h3>

      <SpecialtyList activeFeed={props.activeFeed} />
    </Card>
  );
};

CategoryStructure.propTypes = {
  activeFeed: PropTypes.string
};

export default CategoryStructure;
