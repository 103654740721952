import React from "react";

import usePopup from "../components/popup/usePopup";
import ProvisionalMemberMessage from "../components/provisional-member-message/ProvisionalMemberMessage";
import TOAST_TYPE from "../components/popup/ToastType";
import { useVerifiedMemberStatus } from "../selectors/member-status-selector";

function useVerifiedUserGate(cb) {
  const verified = useVerifiedMemberStatus();
  const { showToast } = usePopup();

  const triggerToast = () => {
    showToast({
      message: <ProvisionalMemberMessage />,
      toastType: TOAST_TYPE.INFO,
      persist: false
    });
  };

  return verified ? cb : triggerToast;
}

export default useVerifiedUserGate;
