import { useSelector } from "react-redux";
import { isUserCompletedSignup } from "../utils/onboarding-utils";

/**
 * Hook to check if a user is completed sign up and caches the result if completed
 * This is to get around old user listener updates that might return old (invalid)
 * user states during the reg flow.
 *
 * @returns boolean representing if the user has completed onboarding
 */
const useUserCompletedOnboarding = () => {
  const isInitialized = useSelector(
    (state) => state.authentication.initialized
  );

  const user = useSelector((state) => state.user);

  return isInitialized && isUserCompletedSignup(user);
};

export default useUserCompletedOnboarding;
