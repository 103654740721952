import React from "react";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import "./BaseEndOfFeed.scss";

const BaseEndOfFeed = ({ title, action, actionText, children }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="feed-card">
      <Card className="border-0 rounded-0">
        <CardBody>
          <div className="base-end-of-feed d-flex flex-column justify-content-center align-items-center text-align-center mt-3">
            <div className="text-16 helv-bold">{title}</div>
            <div className="child-container">{children}</div>
            {action && actionText && (
              <Button color="primary" onClick={action} className="cta-btn p-2">
                {actionText}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BaseEndOfFeed;
