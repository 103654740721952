/** @format */

import { SET_VIDEO_STATE } from "../actions/video-player.actions";

const initialState = {
  videos: {}
};

const videoStateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_VIDEO_STATE:
      return {
        ...state,
        videos: {
          ...state.videos,
          [action.payload.contentUuid]: action.payload.videoState
        }
      };
    default:
      return state;
  }
};

export default videoStateReducer;
