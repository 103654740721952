import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem
} from "reactstrap";
// import { propTypes } from "reactstrap/lib/TooltipPopoverWrapper";
import PropTypes from "prop-types";
import i18n from "../../utils/i18n";
import { COMMENT_SORT_TYPES } from '../../constants/case-constants';

const CommentSortDropdown = ({ onChangeSort }) => {
  /** ********************************** CONFIG ***************************************/

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [sortText, setSortText] = useState(i18n.t("comments.sortNewest"));
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleSortChange = (sort = '') => {
    onChangeSort(sort);
    switch (sort) {
      case COMMENT_SORT_TYPES.OLD:
        setSortText(i18n.t("comments.sortOldest"));
        break;

      default:
      case COMMENT_SORT_TYPES.NEW:
      case "new":
        setSortText(i18n.t("comments.sortNewest"));
        break;
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Dropdown
      className="dropdown-profile"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}>
      <DropdownToggle
        className="pl-2 cursor-pointer font-weight-bold text-13"
        tag="span"
        aria-expanded={isDropdownOpen}
        caret>
        {sortText}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu--pointer-top-right" right>
        <DropdownItem
          active={sortText === i18n.t("comments.sortNewest")}
          onClick={() => handleSortChange(COMMENT_SORT_TYPES.NEW)}>
          {i18n.t("comments.sortNewest")}
        </DropdownItem>
        <DropdownItem
          active={sortText === i18n.t("comments.sortOldest")}
          onClick={() => handleSortChange(COMMENT_SORT_TYPES.OLD)}>
          {i18n.t("comments.sortOldest")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

CommentSortDropdown.propTypes = {
  onChangeSort: PropTypes.func.isRequired
}

export default CommentSortDropdown;
