/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const USER_CONTENT_TYPES = {
  CASE: "case",
  COMMENT: "comment"
};

export const fetchUserProfile = async (userUuid) => {
  const ref = getCollectionRef(DB_CONSTANTS.USERS_PROFILE).doc(userUuid);

  const profile = await ref.get();
  return profile.exists ? profile.data() : null;
};

export const fetchUserProfileByName = async (username) => {
  const ref = getCollectionRef(DB_CONSTANTS.USERS_PROFILE).where(
    "username",
    "==",
    username
  );

  const snapshot = await ref.get();
  if (snapshot.empty) {
    return null;
  }

  const result = [];
  snapshot.forEach((doc) => {
    result.push(doc.data());
  });
  return result;
};

export const listenUserProfileUpdate = (userUuid, callback) => {
  return getCollectionRef(DB_CONSTANTS.USERS_PROFILE)
    .doc(userUuid)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const fetchUserContent = async (userUuid, size, timestamp, type) => {
  if (!timestamp) {
    throw new Error("fetchUserContent: timestamp is missing: ", timestamp);
  }
  if (
    type &&
    type !== USER_CONTENT_TYPES.COMMENT &&
    type !== USER_CONTENT_TYPES.CASE
  ) {
    throw new Error(
      `fetchUserContent: Type was provided, but invalid. type must be either 'comment' or 'case'. The following was provided: ${type}`
    );
  }

  let query = getCollectionRef(
    `${DB_CONSTANTS.USERS_PROFILE}/${userUuid}/activity`
  );

  if (type) {
    query = query
      .where("type", "==", type)
      .orderBy("createdAt", "desc")
      .startAfter(timestamp)
      .limit(size);
  } else {
    query = query
      .orderBy("createdAt", "desc")
      .startAfter(timestamp)
      .limit(size);
  }

  const contentSnap = await query.get();

  const content = [];
  contentSnap.forEach((doc) => content.push({ ...doc.data(), uid: doc.id }));
  return content;
};

export const listenUserFollowingChange = (callback, userUuid) => {
  return getCollectionRef(`${DB_CONSTANTS.USERS_PROFILE}/${userUuid}/following`)
    .orderBy("username")
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error(
        "listenUserFollowingChange.onObservableNextOrError: ",
        onObservableNextOrError
      );
    });
};

export const fetchFollow = async function (isFollower, userUuid, size, start) {
  const query = getCollectionRef(
    `${DB_CONSTANTS.USERS_PROFILE}/${userUuid}/${
      isFollower ? "followers" : "following"
    }`
  )
    .orderBy("username")
    .limit(size);

  let result;
  if (start) {
    result = await query.startAfter(start).get();
  } else {
    result = await query.get();
  }

  const data = [];
  result.forEach((doc) => data.push(doc.data()));
  return data;
};

export const fetchUserFollower = async (userUuid, size, start) => {
  return await fetchFollow(true, userUuid, size, start);
};

export const fetchUserFollowing = async (userUuid, size, start) => {
  return await fetchFollow(false, userUuid, size, start);
};
