import React, { useEffect } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import { Button } from "reactstrap";
import { getRegistrationRoute } from "../../actions/login.actions";
import { getUrl } from "../../utils/route-utils";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import i18n from "../../utils/i18n";
import CustomIcon from "../../components/custom-icon/CustomIcon";

import "./LegacyOnboardingPage.scss";
import { trackLegacyWelcomePageEntry } from "../../actions/metrics.actions";

const LegacyOnboardingPage = () => {
  /** ********************************** CONFIG ***************************************/
  const user = useSelector((state) => state.user);
  const nextRoute = getRegistrationRoute(user);
  const history = useHistory();

  useEffect(() => {
    trackLegacyWelcomePageEntry();
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  const onContinueClick = () => {
    history.push(getUrl(nextRoute));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout
      pageContentClassNames="blue-background"
      noGatedHeader={true}
      topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-5 legacy-onboarding-page">
            <div className="d-flex flex-column pt-5">
              <h1 className="color-white text-22 helv-bold mb-2">
                {i18n.t("RegistrationScreens.legacyOnboardingPage.title")}
              </h1>
              <p className="text-16 color-white mb-4">
                {i18n.t("RegistrationScreens.legacyOnboardingPage.description")}
              </p>

              <ul>
                <li className="d-flex mb-3 text-15 align-items-center">
                  <CustomIcon
                    className="mr-3 flex-shrink-0"
                    size={32}
                    icon="new-welcome-screen_check"
                  />{" "}
                  <div className="color-white w-75">
                    {i18n.t("RegistrationScreens.legacyOnboardingPage.bullet1")}
                  </div>
                </li>
                <li className="d-flex text-15 align-items-center">
                  <CustomIcon
                    className="mr-3 flex-shrink-0"
                    size={32}
                    icon="new-welcome-screen_profile"
                  />{" "}
                  <div className="color-white w-75">
                    {i18n.t("RegistrationScreens.legacyOnboardingPage.bullet2")}
                  </div>
                </li>
              </ul>

              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="mt-4 w-215px"
                  color="primary"
                  size="lg"
                  onClick={onContinueClick}>
                  {i18n.t(
                    "RegistrationScreens.legacyOnboardingPage.btnContinue"
                  )}
                </Button>
              </div>
            </div>
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default LegacyOnboardingPage;
