/** @format */
import { RESET_ALL } from "../actions/global.actions";
import {
  SAVED_CASES_UPDATE,
  SAVED_CASES_LISTENER_ON,
  SAVED_CASES_LISTENER_OFF,
  SAVED_CASES_START,
  SAVED_CASES_CANCEL,
  SAVED_CASES_COMPLETE
} from "../actions/saved-cases.actions";

export const INITIAL_STATE = {
  data: {
    all: []
  },
  isProcessing: {},
  error: {},
  listeners: {},
  message: {}
};

const SavedCasesReducer = (state = INITIAL_STATE, action) => {
  let newProcessing = state.isProcessing;
  let newError = state.error;
  let newListeners = state.listeners;
  let newMessage = state.message;

  switch (action.type) {
    case RESET_ALL: {
      return {
        ...INITIAL_STATE
      };
    }
    // FIXME: Leaving this here as a quick reference.
    // I'm thinking the below pattern is what causes some of our state to be retained, because it causes
    // a modification to the INITIAL_STATE object above since we are mutating vs creating a cloned update
    // case SAVED_CASES_UPDATE:
    //   newContent[action.savedCasesType] = action.payload;
    //   newProcessing[action.savedCasesType] = false;
    //   return { ...state, data: newContent, isProcessing: newProcessing };
    case SAVED_CASES_UPDATE:
      const nextState = {
        ...state,
        data: {
          ...state.data,
          [action.savedCasesType]: action.payload
        },
        isProcessing: {
          ...state.isProcessing,
          [action.savedCasesType]: false
        }
      };

      return nextState;

    case SAVED_CASES_LISTENER_ON:
      newListeners[action.savedCasesType] = action.listener;
      return { ...state, listeners: newListeners };

    case SAVED_CASES_START:
      newProcessing[action.savedCasesType] = true;
      return { ...state, isProcessing: newProcessing };

    case SAVED_CASES_CANCEL:
      newProcessing[action.savedCasesType] = false;
      return { ...state, isProcessing: newProcessing };

    case SAVED_CASES_COMPLETE:
      newError[action.savedCasesType] = action.error;
      newProcessing[action.savedCasesType] = false;
      newMessage[action.savedCasesType] = action.message;
      return {
        ...state,
        error: newError,
        message: newMessage,
        isProcessing: newProcessing
      };
    case SAVED_CASES_LISTENER_OFF:
      return {
        ...state,
        listeners: {}
      };
    default:
      return state;
  }
};

export default SavedCasesReducer;
