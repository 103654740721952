/** @format */

import DB_CONSTANTS from "./db-constants";
import { getCollectionRef } from "./firestore";

export const listenForTypeUpdate = async (callback, userId, savedCaseType) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.SAVED_CASES}/${userId}/${savedCaseType}`);

  return await ref.onSnapshot(callback, (onObservableNextOrError) => {
    console.error("listenForTypeUpdate listener: ", onObservableNextOrError);
  });
}
