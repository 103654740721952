/** @format */

import React from "react";
import { Button } from "reactstrap";
import classNames from "../../../utils/class-names-utils";

const CMEModalButton = ({ className, children, ...props }) => {
  return (
    <Button
      className={classNames(className, "py-2 min-w-96px text-15")}
      {...props}>
      {children}
    </Button>
  );
};

export default CMEModalButton;
