import { useDispatch } from "react-redux";
import {
  hideGlobalMessage,
  showGlobalMessage
} from "../../actions/global.actions";

/**
 * React hook to show a popup message within a component. The goal of this
 * hook is to replicate the same api as the usePopup hook in the mobile app.
 * To keep things simple for now, we only have the "showToast" property.
 * Additional properties will be added as needed.
 */
function usePopup() {
  const dispatch = useDispatch();

  return {
    showToast: ({ message, toastType, title, persist }) => {
      dispatch(showGlobalMessage(message, title, toastType, persist));
    },
    hideToast: () => {
      dispatch(hideGlobalMessage());
    }
  };
}

export default usePopup;
