/** @format */

import Firebase from "../firebase";
import { isRequestSuccess } from "../utils/general-utils";

const functions = Firebase.functions;

export const saveDraftCall = async (draftData) => {
  try {
    return functions.httpsCallable("backendDraftSave")(draftData);
  } catch (error) {
    throw error;
  }
};

export const deleteDraftCall = async (draftUid) => {
  try {
    return functions.httpsCallable("backendDraftDelete")({ draftUid });
  } catch (error) {
    throw error;
  }
};

export const deleteDraftMediaCall = async (draftUid, index) => {
  try {
    return functions.httpsCallable("backendDraftDeleteMedia")({
      draftUid,
      index
    });
  } catch (error) {
    throw error;
  }
};

export const sendToBackendCall = async (draftData) => {
  try {
    return functions.httpsCallable("backendDraftSendToBackend")(draftData);
  } catch (error) {
    throw error;
  }
};

export const uploadDraftMedia = async (formData) => {
  try {
    const postUrl = `https://us-central1-${
      Firebase.app.app().options.projectId
    }.cloudfunctions.net/backendDraftUpload/draft/upload`;

    const userToken = await Firebase.auth.currentUser.getIdToken(true);
    let response = await fetch(postUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    if (isRequestSuccess(response)) {
      return true;
    } else {
      throw new Error(`failed to upload to draft with ${response.status}`);
    }
  } catch (error) {
    throw new Error(
      `error while uploading the media file to draft: ${error.message}`
    );
  }
};
