/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { syncUserProfileWithUsernameIfNeeded } from "../../actions/user-profiles.actions";
import Loading from "../../components/loading";
import QueryPreserveRedirect from "../../components/common/QueryPreserveRedirect";
const ProfileUsernameRedirect = () => {
  /** ********************************** CONFIG ***************************************/

  const { username } = useParams();
  const dispatch = useDispatch();
  const [userUuid, setUserUuid] = useState(null);
  const [userNotFound, setUserNotFound] = useState(false);

  const profileDetailRoute = getUrl(ROUTES.PROFILE_DETAIL, {
    userUuid
  });

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!username || username.length === 0) {
      return;
    }

    const sync = async () => {
      const id = await dispatch(syncUserProfileWithUsernameIfNeeded(username));
      if (id) {
        setUserUuid(id);
      } else {
        setUserNotFound(true);
      }
    };

    try {
      sync();
    } catch (e) {
      console.log(
        "failed to sync user profile with username: ",
        username,
        e.message
      );
    }
  }, [setUserUuid, setUserNotFound, username, dispatch]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  if (userNotFound) {
    return (
      <QueryPreserveRedirect preserveQueryString={true} to={ROUTES.NOT_FOUND} />
    );
  }

  if (userUuid) {
    return (
      <QueryPreserveRedirect
        preserveQueryString={true}
        to={profileDetailRoute}
      />
    );
  }
  return <Loading />;
};

export default ProfileUsernameRedirect;
