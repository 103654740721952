/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { trim } from "lodash";

const ProfileCommentCard = ({ data, onClick }) => {
  /** ********************************** CONFIG ***************************************/
  const caseTitle = trim(data.caseTitle) || trim(data.caseCaption) || "";

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card className="border-0 rounded-0 cursor-pointer" onClick={onClick}>
      <CardBody className="p-3">
        {caseTitle ? (
          <CardTitle>
            <p className="mb-0 text-15 font-weight-bold text-clamp-21-1">
              {caseTitle}
            </p>
          </CardTitle>
        ) : null}
        <CardText
          className="border-left border-width-3 border-denim-blue pl-2"
          tag="div">
          <p className="mb-0 text-14 text-clamp-21-3">{data?.text}</p>
        </CardText>
      </CardBody>
    </Card>
  );
};

ProfileCommentCard.propTypes = {
  data: PropTypes.shape({
    caseTitle: PropTypes.string,
    caseCaption: PropTypes.string,
    text: PropTypes.string
  }),
  onClick: PropTypes.func.isRequired
};

export default ProfileCommentCard;
