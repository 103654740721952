import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label
} from "reactstrap";
import i18n from "../../../utils/i18n";
import { Controller } from "react-hook-form";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const FormElementPassword = ({
  control,
  errors,
  isLoading,
  labelText,
  helpText,
  useHelpAsError
}) => {
  /** ********************************** CONFIG ***************************************/

  const [showPassword, setShowPassword] = useState(false);
  const isInvalid = !!errors.password;

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <FormGroup>
      <Label for="password">
        {labelText || i18n.t("RegistrationScreens.loginForm.password")}
      </Label>
      <Controller
        as={
          <InputGroup
            className={isInvalid ? "is-invalid" : ""}
            style={{ borderRadius: "5px" }}>
            <Input
              style={{ borderRadius: "5px" }}
              type={showPassword ? "text" : "password"}
            />
            <div
              className="cursor-pointer position-absolute r-2 pt-1"
              style={{ zIndex: 100 }}
              onClick={() => setShowPassword(!showPassword)}>
              <CustomIcon
                className="icon-battleship-gray m-0"
                icon={showPassword ? "show" : "hide"}
                size={28}
              />
            </div>
          </InputGroup>
        }
        control={control}
        invalid={isInvalid}
        disabled={isLoading}
        id="password"
        name="password"
        placeholder={i18n.t(
          "RegistrationScreens.loginForm.passwordPlaceholder"
        )}
        rules={{ required: true, minLength: 6 }}
      />
      {helpText && (
        <FormText color={useHelpAsError && isInvalid ? "red" : "muted"}>
          {helpText}
        </FormText>
      )}
      {!useHelpAsError && (
        <FormFeedback>
          {i18n.t("RegistrationScreens.loginForm.passwordRequired")}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormElementPassword;
