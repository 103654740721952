/** @format */

import PropTypes from "prop-types";
import { CONTENT_TYPE } from "../constants/content-type";
import FEED_CARD_TYPE from "../constants/feed-card-type";
import { MediaProp } from "./MediaProp";

export const ContentFeaturesProp = PropTypes.shape({
  commentsEnabled: PropTypes.bool,
  reactionsEnabled: PropTypes.bool,
  reportEnabled: PropTypes.bool,
  requireAnswer: PropTypes.bool,
  saveEnabled: PropTypes.bool,
  shareEnabled: PropTypes.bool,
  showViews: PropTypes.bool,
  similarCasesEnabled: PropTypes.bool,
  zoomEnabled: PropTypes.bool,
  commentTabsEnabled: PropTypes.bool,
  showLabels: PropTypes.bool
});

export const SponsoredContentProp = PropTypes.shape({
  disclosureText: PropTypes.string,
  jobCode: PropTypes.string,
  sponsoredText: PropTypes.string
});

export const FeedCardMediaProp = PropTypes.shape({
  caseUuid: PropTypes.string,
  contentUuid: PropTypes.string,
  displayOrder: PropTypes.number,
  filename: PropTypes.string,
  height: PropTypes.number,
  mediaUuid: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  uuid: PropTypes.string,
  width: PropTypes.number
});

export const ContentProp = PropTypes.shape({
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  caption: PropTypes.string,
  caseUuid: PropTypes.string.isRequired,
  colour: PropTypes.string,
  commentCount: PropTypes.number,
  contentType: PropTypes.oneOf(Object.values(CONTENT_TYPE)),
  contentUuid: PropTypes.string.isRequired,
  displayOrder: PropTypes.number,
  externalLinkText: PropTypes.string,
  externalLinkUrl: PropTypes.string,
  features: ContentFeaturesProp,
  feedCardLabel: PropTypes.string,
  feedCardMedia: FeedCardMediaProp,
  feedCardTitle: PropTypes.string,
  feedCardType: PropTypes.oneOf(Object.values(FEED_CARD_TYPE)),
  heading: PropTypes.string,
  isFeedCard: PropTypes.bool,
  isiEmbeddedContentLink: PropTypes.string,
  isiLink: PropTypes.string,
  isiText: PropTypes.string,
  media: PropTypes.arrayOf(MediaProp),
  questionAnswerDetails: PropTypes.string,
  questionOptions: PropTypes.array,
  sponsoredContent: SponsoredContentProp,
  title: PropTypes.string,
  isPoll: PropTypes.bool
});

// TODO: Continue filling out prop type
export const CaseDataProp = PropTypes.shape({
  caseUuid: PropTypes.string.isRequired,
  contentItems: PropTypes.arrayOf(ContentProp)
});
