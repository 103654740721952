import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";
import NavMoreButton from "./NavMoreButton";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { CHANNEL_LIST } from "../../constants/nav-constants";
import CustomIcon from "../custom-icon/CustomIcon";
import { FEED_NAMES } from "../../constants/feed-type";

const NavItem = ({
  isCurrentChannel,
  uuid,
  name,
  iconName = "web_nav_specialty"
}) => {
  return (
    <Link
      className={`py-1 text-13 ${isCurrentChannel ? "selected-channel" : ""}`}
      disabled={isCurrentChannel}
      key={`link-${uuid}`}
      to={getUrl(ROUTES.HOME_ROOT_TOPIC, {
        feed: FEED_NAMES.TOPIC,
        feedTypeUuid: uuid
      })}>
      <ListGroupItem
        className={`border-0 p-0 bg-transparent ${
          isCurrentChannel ? "text-white" : ""
        }`}
        action>
        <div className="d-flex align-items-start">
          <CustomIcon
            className={`mr-2 flex-shrink-0 ${
              isCurrentChannel ? "icon-white" : "icon-blue-gray"
            }`}
            size={17}
            icon={iconName}
          />{" "}
          {name}
        </div>
      </ListGroupItem>
    </Link>
  );
};

/**
 *
 * @param {items} param0 should be an object with a name, uuid
 * @returns
 */
const NavLinkList = ({ items, iconName }) => {
  /** ********************************** CONFIG ***************************************/

  const routeParams = useParams();

  const [topicLength] = useState(CHANNEL_LIST.MAX_CHANNELS);
  const [isOpen, setIsOpen] = useState(false);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleSeeMore = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  /** ********************************** RENDER ***************************************/
  return (
    <>
      {!!items.length && (
        <div>
          <ListGroup>
            {items.slice(0, topicLength).map((t) => {
              const isCurrentChannel = t.uuid === routeParams.feedTypeUuid;
              return (
                <NavItem
                  key={`navItem-${t.uuid}`}
                  isCurrentChannel={isCurrentChannel}
                  uuid={t.uuid}
                  name={t.name}
                  iconName={iconName}
                />
              );
            })}
          </ListGroup>
        </div>
      )}
      {!!items.length && (
        <div className="feed-sidebar-left-container">
          <ListGroup>
            {items.length > CHANNEL_LIST.MAX_CHANNELS && (
              <Collapse isOpen={isOpen}>
                <ListGroup>
                  {items.slice(CHANNEL_LIST.MAX_CHANNELS).map((t) => {
                    const isCurrentChannel =
                      t.uuid === routeParams.feedTypeUuid;

                    return (
                      <NavItem
                        key={`navItem-${t.uuid}`}
                        isCurrentChannel={isCurrentChannel}
                        uuid={t.uuid}
                        name={t.name}
                        iconName={iconName}
                      />
                    );
                  })}
                </ListGroup>
              </Collapse>
            )}
          </ListGroup>
          {items.length > CHANNEL_LIST.MAX_CHANNELS && (
            <NavMoreButton toggleSeeMore={toggleSeeMore} isOpen={isOpen} />
          )}
        </div>
      )}
    </>
  );
};

export default NavLinkList;
