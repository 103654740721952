import React from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import CasePostPrivacy from "../../components/upload-case/CasePostPrivacy";
import * as ROUTES from "../../constants/routes";
import i18n from "../../utils/i18n";
import CaseTypeOption from "./components/CaseTypeOption";
import { CASE_TYPES } from "../../constants/case-constants";
import { trackShareCaseOption } from "../../actions/metrics.actions";
import { UPLOAD_CASE_OPTIONS } from "../../metrics/constants.metrics";
import useDialog from "../../components/dialog/useDialog";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import AppPromptAuthenticated from"../../components/app-prompt/AppPromptAuthenticated";

const CasePosting = () => {
  /** ********************************** CONFIG ***************************************/

  const { inform } = useDialog();
  const history = useHistory();

  /** ********************************* FUNCTIONS *************************************/

  const sendTrackEvent = (option) => trackShareCaseOption(option);

  const onClickPageCase = async () => {
    sendTrackEvent(UPLOAD_CASE_OPTIONS.LINK);

    const confirmed = await inform({
      title: i18n.t("casePosting.disclaimerTitle"),
      body: <p className="text-16">{i18n.t("casePosting.disclaimerText")}</p>,
      primaryButtonText: i18n.t("common.confirm")
    });

    if (confirmed) {
      history.push(
        getUrl(ROUTES.CASE_POSTING_NEW, { caseType: CASE_TYPES.PAGING })
      );
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPromptAuthenticated />}>
      <p className="bg-gradient h-200px text-white text-25 d-flex justify-content-center align-items-center m-0">
        {i18n.t("casePosting.whatToDo")}
      </p>
      <Figure1Page1Col
        mainContentSmall={
          <div className="px-5">
            <CaseTypeOption
              icon="post_case"
              primaryText={i18n.t("casePosting.shareCasePrimary")}
              secondaryText={i18n.t("casePosting.shareCaseSecondary")}
              route={ROUTES.CASE_POSTING_NEW}
              caseType={CASE_TYPES.POST}
              toggle={() => sendTrackEvent(UPLOAD_CASE_OPTIONS.SHARE)}
            />
            <CaseTypeOption
              icon="page_case"
              primaryText={i18n.t("casePosting.pageSpecialistPrimary")}
              secondaryText={i18n.t("casePosting.pageSpecialistSecondary")}
              toggle={() => {
                sendTrackEvent(UPLOAD_CASE_OPTIONS.PAGE);
                onClickPageCase();
              }}
            />
            <CasePostPrivacy />
          </div>
        }
        hideBackButton={true}
        headerClass="py-4"
      />
    </Figure1Layout>
  );
};

export default CasePosting;
