import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useCallback
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import SearchForm from "../forms/SearchForm";
import {
  useGetSearchSuggestions,
  useGetSearchResults
} from "../../hooks/search.hooks";
import SearchSuggestionContainer from "./SearchSuggestionContainer";
import { SUGGESTION_SECTIONS } from "../../constants/suggestion-section-constants";
import { trackUserSearch } from "../../actions/metrics.actions";
import { SEARCH_TYPE } from "../../metrics/constants.metrics";
import { fetchTopicsIfNeeded } from "../../actions/topics.actions";
import { HOME_ROOT_TOPIC, SEARCH_PATH } from "../../constants/routes";
import classNames from "../../utils/class-names-utils";
import { getUrl } from "../../utils/route-utils";
import {
  clearSearchSuggestions,
  updateSearchTerm
} from "../../actions/search.actions";
import {
  useSelectSearchResultType,
  useSelectSearchTerm
} from "../../selectors/search.selectors";
import { FEED_NAMES } from "../../constants/feed-type";

const FeedSearch = React.forwardRef((props, searchRef) => {
  const dispatch = useDispatch();
  const topics = useSelector((state) => get(state, "topics.data", []));
  const everythingFeed = useSelector((state) =>
    get(state, "userFeedMeta.everythingFeed", {})
  );
  const searchInput = useSelectSearchTerm();
  const searchResultType = useSelectSearchResultType();
  const search = useGetSearchResults();
  const history = useHistory();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { searchSuggestions, showSearchSuggestions } =
    useGetSearchSuggestions(searchInput);

  useEffect(() => {
    dispatch(fetchTopicsIfNeeded());
  }, [dispatch]);

  const clearSearchTerms = useCallback(() => {
    setIsDropdownOpen(false);
    dispatch(clearSearchSuggestions());
  }, [dispatch]);

  useImperativeHandle(searchRef, () => ({
    clearSearchTerms: () => {
      clearSearchTerms();
    }
  }));

  const searchUpdate = (text) => {
    setIsDropdownOpen(true);
    if (text) {
      dispatch(updateSearchTerm(text));
    } else {
      clearSearchTerms();
    }
  };

  const handleSearch = ({ _searchTerms, _searchIndex }) => {
    clearSearchTerms();
    search({
      searchTerm: _searchTerms,
      searchResultType: searchResultType,
      searchIndex: _searchIndex,
      isPaginating: true
    });

    history.push({
      pathname: getUrl(SEARCH_PATH, { tab: searchResultType }),
      search: `?s=${_searchTerms}`
    });
  };

  // _searchTerms can be whatever's typed in the search bar or a search suggestion
  const onSearchEverything = (context, _searchTerms) => {
    sendMetrics(context, _searchTerms);

    handleSearch({ _searchTerms });
  };

  const goToChannel = (channelUuid) => {
    sendMetrics(SEARCH_TYPE.FEED, searchInput);
    clearSearchTerms();

    history.push(
      getUrl(HOME_ROOT_TOPIC, {
        feed: FEED_NAMES.TOPIC,
        feedTypeUuid: channelUuid
      })
    );
  };

  const sendMetrics = (context, term) => {
    trackUserSearch({
      context,
      term
    });
  };

  const feedOptions = topics
    .filter((f) => f.name.toLowerCase().includes(searchInput.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const fixedSuggestions = [
    {
      channel: everythingFeed,
      onPress: () => onSearchEverything(SEARCH_TYPE.SEARCH_SCREEN, searchInput),
      searchTerms: searchInput
    }
  ];

  const data = [
    {
      title: SUGGESTION_SECTIONS.FIXED_CHANNELS,
      data: fixedSuggestions
    }
  ];

  if (showSearchSuggestions) {
    data.unshift({
      title: SUGGESTION_SECTIONS.SEARCH_TERM,
      data: searchSuggestions.map((item) => ({
        ...item,
        onPress: () => {
          onSearchEverything(SEARCH_TYPE.SUGGESTION, item.text);
        }
      }))
    });
  }

  if (!isEmpty(feedOptions)) {
    data.push({
      title: SUGGESTION_SECTIONS.MATCHING_CHANNELS,
      data: [
        {
          feedOptions,
          onPress: goToChannel
        }
      ]
    });
  }

  return (
    <div
      className={classNames(
        "feed-search-form",
        isDropdownOpen ? "feed-search-form--open" : undefined,
        props.className
      )}>
      <SearchForm
        defaultValue={searchInput}
        onChange={(e) => searchUpdate(e.target.value)}
        onClear={() => clearSearchTerms()}
        onEnter={() => onSearchEverything(SEARCH_TYPE.DIRECT, searchInput)}
        autoFocus={props.autoFocus}
      />
      {isDropdownOpen && (
        <SearchSuggestionContainer
          data={data}
          everythingFeed={everythingFeed}
        />
      )}
    </div>
  );
});

FeedSearch.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool
};

FeedSearch.defaultProps = {
  autoFocus: false
};

export default FeedSearch;
