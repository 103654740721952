import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "../../utils/class-names-utils";

const DropdownItemLink = ({ to, active, className, ...rest }) => {
  return (
    <NavLink
      className={classNames(
        "dropdown-item",
        className
      )}
      activeClassName="active"
      to={to}
      {...rest}
    />
  );
};

DropdownItemLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired
};

DropdownItemLink.defaultProps = {
  active: false
};

export default DropdownItemLink;
