import React from "react";
import Card from "reactstrap/lib/Card";
import PropTypes from "prop-types";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { getUrl } from "../../../utils/route-utils";
import { Link } from "react-router-dom";

const CaseTypeOption = (props) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const contents = (
    <Card className="p-4 mb-4 card-shadow  cursor-pointer">
      <div className="d-flex flex-column align-items-center">
        <CustomIcon
          icon={props.icon}
          className="icon-default-black"
          size={40}
        />

        <div className="text-18 font-weight-bold">{props.primaryText}</div>
        <div className="text-15 text-battleship-gray">
          {props.secondaryText}
        </div>
      </div>
    </Card>
  );

  if (props.route) {
    return (
      <Link
        to={
          props.caseType
            ? getUrl(props.route, {
                caseType: props.caseType
              })
            : props.route
        }
        onClick={props.toggle}>
        {contents}
      </Link>
    );
  }
  return (
    <div className="text-denim-blue cursor-pointer" onClick={props.toggle}>
      {contents}
    </div>
  );
};

CaseTypeOption.propTypes = {
  icon: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  route: PropTypes.string,
  toggle: PropTypes.func,
  caseType: PropTypes.string
};

export default CaseTypeOption;
