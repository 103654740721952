import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";
import THEMES from "../theme/Theme";
import useTheme from "../theme/useTheme";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ReactMarkdown from "react-markdown";

const CaseReferences = ({ references }) => {
  /** ********************************** CONFIG ***************************************/
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const openReferences = () => {
    setIsOpen(true);
  };
  const closeReferences = () => {
    setIsOpen(false);
  };

  /** ********************************** RENDER ***************************************/

  if (!references) return null;

  return (
    <>
      <Button
        onClick={openReferences}
        block={false}
        color={theme === THEMES.DARK ? "primary" : "link"}
        className={classNames(
          "btn btn-md cursor-pointer d-flex justify-content-start align-items-center",
          theme === THEMES.DARK ? "" : "pl-0"
        )}>
        <CustomIcon
          icon="references"
          size={17}
          className={classNames(
            theme === THEMES.DARK ? "icon-light-gray" : "icon-denim-blue"
          )}
        />
        <span
          className={classNames(
            "pl-1",
            theme === THEMES.DARK ? "text-white" : "text-battleship-gray"
          )}>
          {i18n.t("References.buttonText")}
        </span>
        <CustomIcon
          icon="drawer_chevron"
          size={10}
          className={classNames(
            theme === THEMES.DARK ? "icon-light-gray" : "icon-denim-blue"
          )}
        />
      </Button>

      <Modal
        isOpen={isOpen}
        toggle={closeReferences}
        className="modal-btn-spaced">
        <ModalHeader
          toggle={closeReferences}
          className="border-0"></ModalHeader>

        <ModalBody className="pt-0 px-5">
          <h1 className="text-20 mb-2">{i18n.t("References.modalHeader")}</h1>
          <div>
            <ReactMarkdown>{references}</ReactMarkdown>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

CaseReferences.propTypes = {
  references: PropTypes.string
};
CaseReferences.defaultProps = {};

export default CaseReferences;
