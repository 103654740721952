import React from "react";

/**
 * @typedef {object} DialogProps
 * @property {bool} isOpen
 * @property {'INFORM' | 'CONFIRM'} type
 * @property {React.ReactNode} title
 * @property {React.ReactNode} subtitle
 * @property {React.ReactNode} body
 * @property {string} confirmText
 * @property {boolean} confirmLoading
 * @property {boolean} confirmDisabled
 * @property {string} cancelText
 * @property {boolean} cancelLoading
 * @property {boolean} cancelDisabled
 * @property {Function} onCancelClick
 * @property {Function} onConfirm
 * @property {Function} onCancel
 * @property {Function} onClosed
 */

/**
 * @type {React.Context<(options: DialogProps) => Promise<boolean>>}
 */
const DialogContext = React.createContext(Promise.resolve);

export default DialogContext;
