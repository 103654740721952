/** @format */
import * as userFeedDB from "../db/user-feed.db";
import { toggleFollowTopics } from "../api/topic.cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "userFeedMeta";

export const SET_REQUIRED_FEEDS = `${actionsPrefix}/SET_REQUIRED_FEEDS`;
export const USER_FEED_META_UPDATED = `${actionsPrefix}/USER_FEED_META_UPDATED`;
export const USER_FEED_META_LISTENER_ON = `${actionsPrefix}/USER_FEED_META_LISTENER_ON`;
export const USER_FEED_META_LISTENER_OFF = `${actionsPrefix}/USER_FEED_META_LISTENER_OFF`;
export const USER_FEED_META_FOLLOW = `${actionsPrefix}/USER_FEED_META_FOLLOW`;
export const USER_FEED_META_FOLLOW_COMPLETE = `${actionsPrefix}/USER_FEED_META_FOLLOW_COMPLETE`;

const FOLLOW = "follow";
const UNFOLLOW = "unfollow";

/**
 * (De)Activate a listener on the userFeedDB collection. If no userId is provided, then it is considered
 * as a deactivation request
 * @param userId
 * @returns {function(...[*]=)}
 *
 * Caveat: Toggle Follow Channel Bottlenecks
 * This Firebase collection contains followed channel data. When these channels are toggled in bulk,
 * the UI using it will be updated automatically. However, it looks like the backend applies a bulk toggle
 * one channel at a time, thus users will see the UI change one channel at a time.
 *
 * Building off above, I think as the updates apply one at a time, any UI using this state will freeze until
 * all toggle changes are done. This can be seen when a user toggles a few channels on/off, the
 * dropdown and sidebar will be unresponsive.
 *
 * Need to coordinate with backend to see how to improve this.
 */
export const toggleUserFeedMetaListener = (userId) => {
  return async (dispatch, getState) => {
    if (userId) {
      if (getState().userFeedMeta && !getState().userFeedMeta.listener) {
        const listener = await userFeedDB.listenForChanges((doc) => {
          if (doc && doc.exists) {
            let data = doc.data();
            if (!data.feeds) {
              data.feeds = {};
            }

            dispatch({
              type: USER_FEED_META_UPDATED,
              payload: { data: data }
            });
          }
        }, userId);

        return {
          type: USER_FEED_META_LISTENER_ON,
          payload: { listener }
        };
      }
    } else {
      if (getState().userFeedMeta && getState().userFeedMeta.listener) {
        getState().userFeedMeta.listener();
      }
      return {
        type: USER_FEED_META_LISTENER_OFF
      };
    }
  };
};

/**
 * Toggle follow state of a number of feeds.
 *
 * @param {Object} updates - {
 *   [feed_type_uuid]: {
 *     shouldFollow: boolean
 *   }
 * }
 *
 */
export const toggleFollowFeeds = (updates) => {
  return async (dispatch) => {
    try {
      const data = Object.keys(updates).map((channelUuid) => ({
        feed_type_uuid: channelUuid,
        action: updates[channelUuid].shouldFollow ? FOLLOW : UNFOLLOW
      }));

      dispatch({
        type: USER_FEED_META_FOLLOW,
        payload: {
          updates
        }
      });

      const response = await toggleFollowTopics(data);

      const success = isRequestSuccess(response);
      dispatch({
        type: USER_FEED_META_FOLLOW_COMPLETE,
        error: !success,
        message: !success ? "Failed to update follow states" : null
      });
    } catch (error) {
      dispatch({
        type: USER_FEED_META_FOLLOW_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};
