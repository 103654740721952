import React from "react";
import CustomIcon from "../custom-icon/CustomIcon";

const CaseBanner = ({ bannerType }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <>
      {bannerType.text && (
        <div
          className={
            "py-1 px-3 text-13 text-white d-flex align-items-center " +
            bannerType.color
          }>
          <CustomIcon icon={bannerType.icon} color={"white"} size={14} />
          <div className="pl-2">{bannerType.text}</div>
        </div>
      )}
    </>
  );
};

export default CaseBanner;
