/** @format */

import {
  CACHE_COUNTRIES,
  FETCH_COUNTRIES_COMPLETE,
  FETCH_SUBDIVISION,
  FETCH_SUBDIVISION_COMPLETE,
  CACHE_SUBDIVISION_COMPLETE
} from "../actions/country.actions";

export const INITIAL_STATE = {
  countries: {},
  subdivision: {},
  cachedCountries: {},
  cachedSubdivisions: [],
  selected: {},
  isProcessing: false,
  error: false,
  message: null
};

const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CACHE_COUNTRIES:
    case FETCH_SUBDIVISION:
      return { ...state, isProcessing: true };
    case FETCH_COUNTRIES_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        countries: action.payload.countries || state.countries,
        error: action.error,
        message: action.message
      };
    case FETCH_SUBDIVISION_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        subdivision: action.payload.subdivision
          ? { ...state.subdivision, ...action.payload.subdivision }
          : state.subdivision,
        error: action.error,
        message: action.message
      };
    case CACHE_SUBDIVISION_COMPLETE:
      const updateUuid = action.payload.subdivisionCountryUuid;

      if (state.cachedCountries[updateUuid] === undefined) {
        const updater = Object.values(action.payload.subdivision).map((s) => {
          return {
            ...s,
            countryUuid: updateUuid
          };
        });
        const updatedSubdivisions = state.cachedSubdivisions.concat(updater);
        return {
          ...state,
          isProcessing: false,
          cachedCountries: {
            ...state.cachedCountries,
            [updateUuid]: action.payload.subdivision
          },
          cachedSubdivisions: updatedSubdivisions,
          error: action.error,
          message: action.message
        };
      }
      return state;

    default:
      return state;
  }
};

export default countryReducer;
