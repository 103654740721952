import TOAST_TYPE from "../components/popup/ToastType";

const actionsPrefix = "global";

export const RESET_ALL = `${actionsPrefix}/RESET_ALL`;
export const SHOW_GLOBAL_MESSAGE = `${actionsPrefix}/SHOW_GLOBAL_MESSAGE`;
export const HIDE_GLOBAL_MESSAGE = `${actionsPrefix}/HIDE_GLOBAL_MESSAGE`;
export const UNSUBSCRIBE_LISTENERS = `${actionsPrefix}/UNSUBSCRIBE_LISTENERS`;
export const SET_GATED = `${actionsPrefix}/SET_GATED`;
export const SET_GATED_MESSAGE_TYPE = `${actionsPrefix}/SET_GATED_MESSAGE_TYPE`;
export const SET_GATED_MESSAGE_ORIGIN = `${actionsPrefix}/SET_GATED_MESSAGE_ORIGIN`;
export const RESET_GATED_STATE = `${actionsPrefix}/RESET_GATED_STATE`;
export const APP_PROMPT_DECLINED = `${actionsPrefix}/APP_PROMPT_DECLINED`;
export const APP_PROMPT_HEIGHT_CHANGED = `${actionsPrefix}/APP_PROMPT_HEIGHT_CHANGED`;
export const SET_GATED_VIEW_COUNT = `${actionsPrefix}/SET_GATED_VIEW_COUNT`;
export const SET_EMAIL_MISMATCH_DETAILS = `${actionsPrefix}/SET_EMAIL_MISMATCH_DETAILS`;
export const CLEAR_EMAIL_MISMATCH_DETAILS = `${actionsPrefix}/CLEAR_EMAIL_MISMATCH_DETAILS`;

export const showGlobalMessage = (
  message,
  title = null,
  messageType = TOAST_TYPE.INFO,
  persist = false,
  error
) => {
  return async function (dispatch) {
    dispatch({
      type: SHOW_GLOBAL_MESSAGE,
      title,
      message,
      messageType,
      error,
      persist
    });
  };
};

export const showErrorMessage = (
  message,
  title = null,
  persist = false,
  error
) => {
  return showGlobalMessage(message, title, TOAST_TYPE.ERROR, persist, error);
};

export const showInfoMessage = (message, title = null, persist = false) => {
  return showGlobalMessage(message, title, TOAST_TYPE.INFO, persist);
};

export const showSuccessMessage = (message, title = null, persist = false) => {
  return showGlobalMessage(message, title, TOAST_TYPE.SUCCESS, persist);
};

export const hideGlobalMessage = () => {
  return async function (dispatch) {
    dispatch({
      type: HIDE_GLOBAL_MESSAGE
    });
  };
};

export const unsubscribeFirestoreListeners = () => {
  return {
    type: UNSUBSCRIBE_LISTENERS
  };
};

export const setGatedState = (isGated, isSponsored) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED,
      isGated,
      isSponsored
    });
  };
};

export const clearGatedState = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED,
      isGated: false,
      isSponsored: false
    });
  };
};

export const setGatedMessageType = (messageType) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_TYPE,
      messageType
    });
  };
};

export const clearGatedMessageType = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_TYPE,
      messageType: null
    });
  };
};

export const setGatedMessageOrigin = (origin) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_ORIGIN,
      origin
    });
  };
};

export const clearGatedMessageOrigin = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_ORIGIN,
      origin: null
    });
  };
};

export const resetGatedState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GATED_STATE });
  };
};

export const declineAppPrompt = () => {
  return {
    type: APP_PROMPT_DECLINED
  };
};

export const setAppPromptHeight = (height) => {
  return {
    type: APP_PROMPT_HEIGHT_CHANGED,
    height
  };
};

export const tickUngatedViewCount = () => {
  return (dispatch) => {
    dispatch({
      type: SET_GATED_VIEW_COUNT
    });
  };
};

export const setEmailMismatchModal = (showModal, groupFilterUuid) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EMAIL_MISMATCH_DETAILS,
      emailMismatchModal: {
        showModal,
        groupFilterUuid
      }
    });
  };
};

export const clearEmailMismatchModal = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_EMAIL_MISMATCH_DETAILS,
      emailMismatchModal: {
        showModal: false,
        groupFilterUuid: ""
      }
    });
  };
};
