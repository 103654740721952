//AtMention: /@[a-zA-Z0-9+_-]*/g,

import React from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import * as ROUTES from "../../constants/routes";
import THEMES from "../theme/Theme";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import useIsGated from "../../hooks/useIsGated";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

const CommentUserTag = ({ username }) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();
  const isGated = useIsGated();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const gatedOnClick = useGatedActionGate(
    () => {},
    GATED_MODAL_TYPE.PROFILE,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.COMMENT_AT_REPLY
  );

  /** ********************************** RENDER ***************************************/

  if (isGated) {
    return (
      <span
        className="text-denim-blue helv-med cursor-pointer"
        onClick={gatedOnClick}>
        {username}
      </span>
    );
  }

  return (
    <Link
      className={classNames(
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}
      to={getUrl(ROUTES.PROFILE_USERNAME_REDIRECT, {
        username: username.replace("@", "")
      })}>
      <span className="text-denim-blue helv-med">{username}</span>
    </Link>
  );
};

export default CommentUserTag;
