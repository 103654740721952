import React from "react";
import { CardText, CardBody } from "reactstrap";
import i18n from "../../../utils/i18n";

const NotificationEmpty = () => {
  return (
    <CardBody className="bg-secondary text-denim-blue text-center text-15 rounded">
      <CardText className="whitespace-pre-line p-4">
        {i18n.t("ActivityScreen.noActivityYet")}
      </CardText>
    </CardBody>
  );
};

NotificationEmpty.propTypes = {};

export default NotificationEmpty;
