import React from "react";
import PropTypes from "prop-types";
import Card from "reactstrap/lib/Card";
import CaseImageEditor from "../../../components/upload-case/CaseImageEditor";

const DraftMainColumn = ({ fileRef, onRemoveFile, onFileEdit, media = [] }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card className="p-3">
      <CaseImageEditor
        fileRef={fileRef}
        media={media}
        onRemoveFile={onRemoveFile}
        onFileEdit={onFileEdit}
      />
    </Card>
  );
};

DraftMainColumn.propTypes = {
  fileRef: PropTypes.any,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string
    })
  )
};

export default DraftMainColumn;
