import React from "react";
import { Button } from "reactstrap/lib";
import CustomIcon from "../custom-icon/CustomIcon";

const FilterListItem = ({ labelItem, handleClick }) => {
  return (
    <Button
      color="link"
      onClick={() => handleClick(labelItem.key)}
      className={`dropdown-nav-button text-13 helv-med text-battleship-gray d-flex align-items-center ${
        labelItem.active
          ? "dropdown-nav-button-active"
          : "dropdown-nav-button-inactive"
      }
      `}>
      <span className={`py-1 ${!labelItem.active ? `ml-0` : undefined}`}>
        {labelItem.name}
      </span>
      {labelItem.active && (
        <CustomIcon icon="feed_channel-check" color="#6b778b" size={13} />
      )}
    </Button>
  );
};

export default FilterListItem;
