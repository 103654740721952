import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommentSortDropdown from "./CommentSortDropdown";
import CommentInput from "./CommentInput";
import CommentBlock from "./CommentBlock";
import { COMMENT_TYPES } from "../../constants/case-constants";
import PropTypes from "prop-types";
import { cacheComment, postComment } from "../../actions/comment.actions";
import usePopup from "../../components/popup/usePopup";
import TOAST_TYPE from "../../components/popup/ToastType";
import i18n from "../../utils/i18n";
import { Card, CardBody } from "reactstrap";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { trackComment } from "../../actions/metrics.actions";
import useIsGated from "../../hooks/useIsGated";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import useDialog from "../dialog/useDialog";
import DIALOG_TYPE from "../dialog/DialogType";

const CommentContainer = ({
  caseUuid,
  contentUuid,
  onChangeSort,
  caseContent,
  commentStatus,
  position,
  isGroup = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const commentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();
  const author = caseContent.authors ? caseContent.authors[0] : null;
  const user = useSelector((state) => state.user);
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const isGated = useIsGated();

  const { confirm } = useDialog();
  const userHasGroups = useUserHasGroups();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const onPost = async (text, parentComment) => {
    try {
      setLoading(true);
      let confirmed = true;

      if (userHasGroups && !isGroup) {
        confirmed = await confirm({
          message: i18n.t("comments.confirmPostTitle"),
          messageDetail: i18n.t("comments.confirmPostDetails"),
          confirmText: i18n.t("comments.confirmPostButton"),
          extra: {
            type: DIALOG_TYPE.INFORM
          }
        });
      }

      if (confirmed) {
        const result = await dispatch(
          postComment(
            caseUuid,
            contentUuid,
            text,
            parentComment ? parentComment.commentUuid : null
          )
        );
        trackComment({
          caseContent,
          commentStatus,
          charCount: text.length,
          isAuthor: author.userUuid === user?.userUuid,
          position
        });

        setLoading(false);

        if (!result.error) {
          showToast({
            message: i18n.t("comments.addCommentSuccess"),
            toastType: TOAST_TYPE.SUCCESS
          });

          // cache comment locally if comment queue is enabled
          if (commentQueueEnabled) {
            dispatch(
              cacheComment(caseUuid, contentUuid, {
                text: text,
                parentUuid: parentComment?.commentUuid,
                author: user,
                authorUuid: user?.userUuid,
                avatar: user?.avatar,
                username: user?.username,
                verified: user?.verified,
                createdAt: new Date().toISOString(),
                replyable: false,
                commentUuid: "cache",
                professionLabel: user?.primarySpecialty?.caseCommentDisplayName
              })
            );
          }

          // reset the comment
          commentRef.current.value = null;
          return true;
        } else {
          showToast({
            message: i18n.t("comments.addCommentFailure"),
            toastType: TOAST_TYPE.ERROR
          });
          return false;
        }
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.log("Post comment error: ", error.message);
      return false;
    }
  };

  const verifiedOnPost = useVerifiedUserGate(onPost);
  const verifiedOnFocus = useVerifiedUserGate(() => {});
  /** ********************************** RENDER ***************************************/
  return (
    <>
      <Card
        className="border-0"
        color={theme === THEMES.DARK ? "dark-gray" : null}>
        <CardBody
          className={classNames(
            isGated ? "" : "pb-0",
            "border-bottom border-width-3",
            theme === THEMES.DARK
              ? "border-charcoal-gray"
              : "border-lighter-gray"
          )}>
          <CommentInput
            ref={commentRef}
            commentType={COMMENT_TYPES.COMMENT}
            onPost={verifiedOnPost}
            onFocus={verifiedOnFocus}
            loading={loading}
            isGroup={isGroup}
          />

          {!isGated && (
            <div
              className={classNames(
                "py-2 d-flex justify-content-end align-items-bottom",
                theme === THEMES.DARK ? "" : "text-denim-blue"
              )}>
              <div className="text-13">
                <span>{i18n.t("common.commentsSortBy")}</span>
              </div>
              <CommentSortDropdown onChangeSort={onChangeSort} />
            </div>
          )}
        </CardBody>
      </Card>
      {isGated && (
        <Card
          className="border-0"
          color={theme === THEMES.DARK ? "dark-gray" : null}>
          <CardBody
            className={classNames(
              "pb-0 border-bottom border-width-3",
              theme === THEMES.DARK
                ? "border-charcoal-gray"
                : "border-lighter-gray"
            )}>
            <div
              className={classNames(
                "py-2 d-flex align-items-bottom",
                theme === THEMES.DARK ? "" : ""
              )}>
              <CommentBlock
                campaignUuid={caseContent.campaignUuid}
                caseUuid={caseContent.caseUuid}
                caseType={caseContent.caseType}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

CommentContainer.propTypes = {
  caseUuid: PropTypes.string.isRequired,
  contentUuid: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired
};

export default CommentContainer;
