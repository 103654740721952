import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingButton from "../../../components/common/LoadingButton";
import { authCheckAndSignIn } from "../../../actions/non-user.actions";
import { toggleStateListeners } from "../../../actions/login.actions";
import FormElementPassword from "./FormElementPassword";
import { Link } from "react-router-dom";
import {
  trackEvent,
  trackLogInPageEntry,
  trackUserSignIn
} from "../../../actions/metrics.actions";
import {
  METRICS_EVENT_NAME,
  METRICS_EVENT_PROPERTY,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { useContactSupport } from "../../../hooks/useContactSupport";
import LegacyTransitionModal from "./LegacyTransitionModal";
import FormGlobalError from "./FormGlobalError";

const LoginForm = ({
  inModal = false,
  className = "",
  linksAbove = true,
  preventRedirect = false,
  onSignIn = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const { handleSubmit, control, errors } = useForm({
    mode: "onChange"
  });

  const user = useSelector((state) => state.user);
  const migratingLegacyAccount = useSelector(
    (state) => state.authentication.migratingLegacyAccount
  );

  const [signUpRoute, setSignUpRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onContactSupport = useContactSupport();

  const loginErrorMessage = useSelector((state) => {
    if (state?.authentication?.error) {
      return state.authentication.errorResult?.message;
    } else if (state?.nonUser?.error) {
      return state.nonUser.message;
    }
    return null;
  });

  /****************************** Hooks ***************************************/

  // trigger state change if it's legacy sign up route
  useEffect(() => {
    if (signUpRoute && user?.userUid) {
      setSignUpRoute(false);
    }
  }, [signUpRoute, user]);

  useEffect(() => {
    trackLogInPageEntry({
      [METRICS_EVENT_PROPERTY.MODAL]: inModal
    });
  }, [inModal]);

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (loginData) => {
    setIsLoading(true);
    trackUserSignIn();
    const result = await dispatch(
      authCheckAndSignIn(loginData.email, loginData.password, preventRedirect)
    );

    setIsLoading(false);
    if (!result.error) {
      if (result.loginResult?.user) {
        dispatch(toggleStateListeners(result.loginResult.user));
      }
      // automatic signup path for legacy user
      if (!result.onboardingCompleted) {
        setSignUpRoute(true);
      }

      onSignIn();
    } else {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: result.message
      });
    }
  };

  const forgotLinks = (
    <>
      <p className="mb-0">
        <Link
          className="pl-0 text-cool-blue text-15"
          to={ROUTES.REGISTRATION_RESET_PASSWORD}>
          {i18n.t("RegistrationScreens.loginForm.forgotPassword")}
        </Link>
      </p>
      <p className="mt-1 mb-2">
        <Button
          color="link"
          className=" pl-0 text-cool-blue text-15"
          onClick={onContactSupport}>
          {i18n.t("RegistrationScreens.loginForm.iDontKnowUsername")}
        </Button>
      </p>
    </>
  );
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitClick)} className={className}>
        <FormGlobalError errorText={loginErrorMessage} />

        <FormGroup className="mb-4">
          <Label for="email">
            {i18n.t("RegistrationScreens.loginForm.emailOrUsername")}
          </Label>
          <Controller
            as={Input}
            control={control}
            disabled={isLoading}
            id="email"
            name="email"
            type="text"
            invalid={!!errors.email}
            placeholder={i18n.t(
              "RegistrationScreens.loginForm.emailOrUsernamePlaceholder"
            )}
            rules={{ required: true }}
          />
          <FormFeedback>
            {i18n.t("RegistrationScreens.loginForm.emailRequired")}
          </FormFeedback>
        </FormGroup>

        <FormElementPassword
          control={control}
          errors={errors}
          isLoading={isLoading}
        />

        {linksAbove && forgotLinks}

        <LoadingButton
          className={`mt-2 ${linksAbove ? "" : "mb-3"} w-100`}
          color="primary"
          size="lg"
          loading={isLoading}
          spinnerColor="white"
          disabled={isLoading}>
          {i18n.t("RegistrationScreens.loginForm.btnSignIn")}
        </LoadingButton>

        {!linksAbove && forgotLinks}
      </form>

      <LegacyTransitionModal isOpen={migratingLegacyAccount} />
    </>
  );
};

LoginForm.propTypes = {
  registrationState: PropTypes.string,
  setRegistrationState: PropTypes.func
};

export default LoginForm;
