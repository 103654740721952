/** @format */

import i18n from "i18next";
import * as userDB from "../db/user.db";
import {
  updateUser,
  validateEmail,
  validateUsername
} from "../api/user.cloud-functions";
import { initUserInfo } from "./metrics.actions";
import { isEmpty } from "lodash";
import { toggleUserFollowingListener } from "./user-profiles.actions";
import moment from "moment";
import FEED_CARD_TYPE from "../constants/feed-card-type";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "user";

export const INITIALIZE_USER_COMPLETE = `${actionsPrefix}/INITIALIZE_USER_COMPLETE`;
export const SAVE_DETAILS = `${actionsPrefix}/SAVE_DETAILS`;
export const SAVE_DETAILS_COMPLETE = `${actionsPrefix}/SAVE_DETAILS_COMPLETE`;
export const SET_AUTH_SETTING = `${actionsPrefix}/SET_AUTH_SETTING`;
export const SAVE_AUTH_PIN = `${actionsPrefix}/SAVE_AUTH_PIN`;
export const SAVE_AUTH_PIN_COMPLETE = `${actionsPrefix}/SAVE_AUTH_PIN_COMPLETE`;
export const SAVE_AUTH_BIOMETRIC = `${actionsPrefix}/SAVE_AUTH_BIOMETRIC`;
export const SAVE_AUTH_BIOMETRIC_COMPLETE = `${actionsPrefix}/SAVE_AUTH_BIOMETRIC_COMPLETE`;
export const SAVE_AUTH_NOT_SET = `${actionsPrefix}/SAVE_AUTH_NOT_SET`;
export const SAVE_AUTH_NOT_SET_COMPLETE = `${actionsPrefix}/SAVE_AUTH_NOT_SET_COMPLETE`;
export const SAVE_NOTIFICATIONS = `${actionsPrefix}/SAVE_NOTIFICATIONS`;
export const SAVE_NOTIFICATIONS_COMPLETE = `${actionsPrefix}/SAVE_NOTIFICATIONS_COMPLETE`;
export const SAVE_ONBOARDING = `${actionsPrefix}/SAVE_ONBOARDING`;
export const SAVE_ONBOARDING_COMPLETE = `${actionsPrefix}/SAVE_ONBOARDING_COMPLETE`;
export const START_SAVE_DATA = `${actionsPrefix}/START_SAVE_DATA`;
export const SAVE_ADMIN_SETTINGS = `${actionsPrefix}/SAVE_ADMIN_SETTINGS`;
export const SAVE_ADMIN_SETTINGS_COMPLETE = `${actionsPrefix}/SAVE_ADMIN_SETTINGS_COMPLETE`;
export const SAVE_RELEASE_NOTES_COMPLETE = `${actionsPrefix}/SAVE_RELEASE_NOTES_COMPLETE`;
export const UPDATE_USER_PERMISSIONS = `${actionsPrefix}/UPDATE_USER_PERMISSIONS`;
export const CACHE_USER_UPDATE = `${actionsPrefix}/CACHE_USER_UPDATE`;
export const CACHE_USER_UPDATE_COMPLETE = `${actionsPrefix}/CACHE_USER_UPDATE_COMPLETE`;
export const SAVE_USER_UPDATE = `${actionsPrefix}/SAVE_USER_INFO`;
export const SAVE_USER_UPDATE_COMPLETE = `${actionsPrefix}/SAVE_USER_INFO_COMPLETE`;
export const CLEAR_ERROR = `${actionsPrefix}/CLEAR_ERROR`;
export const USER_LISTENER_ON = `${actionsPrefix}/USER_LISTENER_ON`;
export const USER_LISTENER_OFF = `${actionsPrefix}/USER_LISTENER_OFF`;
export const USER_UPDATE_EMAIL = `${actionsPrefix}/USER_UPDATE_EMAIL`;
export const USER_UPDATE_EMAIL_COMPLETE = `${actionsPrefix}/USER_UPDATE_EMAIL_COMPLETE`;
export const USER_UPDATE_LANGUAGE = `${actionsPrefix}/USER_UPDATE_LANGUAGE`;
export const USER_UPDATE_LANGUAGE_COMPLETE = `${actionsPrefix}/USER_UPDATE_LANGUAGE_COMPLETE`;
export const USER_UPDATE_INTERESTS = `${actionsPrefix}/USER_UPDATE_INTERESTS`;
export const USER_UPDATE_INTERESTS_COMPLETE = `${actionsPrefix}/USER_UPDATE_INTERESTS_COMPLETE`;
export const FETCH_USER_INTERACTIVE_CASE_STATES = `${actionsPrefix}/FETCH_USER_INTERACTIVE_CASE_STATES`;
export const FETCH_USER_INTERACTIVE_CASE_STATES_COMPLETE = `${actionsPrefix}/FETCH_USER_INTERACTIVE_CASE_STATES_COMPLETE`;
export const LISTEN_INTERACTIVE_CASE_STATE_ON = `${actionsPrefix}/LISTEN_INTERACTIVE_CASE_STATE_ON`;
export const LISTEN_INTERACTIVE_CASE_STATE_OFF = `${actionsPrefix}/LISTEN_INTERACTIVE_CASE_STATE_OFF`;
export const UPDATE_INTERACTIVE_CASE_STATE = `${actionsPrefix}/UPDATE_INTERACTIVE_CASE_STATE`;
export const OPTIMISTIC_ADD_VOTE = `${actionsPrefix}/OPTIMISTIC_ADD_VOTE`;
export const OPTIMISTIC_RESET_VOTE = `${actionsPrefix}/OPTIMISTIC_RESET_VOTE`;
export const PROMO_CARDS_LISTENER_ON = `${actionsPrefix}/PROMO_CARDS_LISTENER_ON`;
export const PROMO_CARDS_LISTENER_OFF = `${actionsPrefix}/PROMO_CARDS_LISTENER_OFF`;
export const PROMO_CARDS_UPDATE = `${actionsPrefix}/PROMO_CARDS_UPDATE`;
export const PROMO_CARDS_REMOVE = `${actionsPrefix}/PROMO_CARDS_REMOVE`;
export const PROMO_CARDS_REMOVE_COMPLETE = `${actionsPrefix}/PROMO_CARDS_REMOVE_COMPLETE`;

export const cacheUserUpdate = (data) => {
  return async (dispatch) => {
    if (data.username) {
      try {
        dispatch({
          type: CACHE_USER_UPDATE
        });
        const response = await validateUsername(data.username);
        if (isRequestSuccess(response)) {
          return dispatch({
            type: CACHE_USER_UPDATE_COMPLETE,
            userUpdate: data
          });
        } else {
          return dispatch({
            type: CACHE_USER_UPDATE_COMPLETE,
            error: true,
            message: i18n.t(
              "RegistrationScreens.CreateAccountScreens.invalidUsername"
            )
          });
        }
      } catch (error) {
        return dispatch({
          type: CACHE_USER_UPDATE_COMPLETE,
          error: true,
          message: i18n.t(
            "RegistrationScreens.CreateAccountScreens.invalidUsername"
          )
        });
      }
    } else {
      return dispatch({
        type: CACHE_USER_UPDATE_COMPLETE,
        userUpdate: data
      });
    }
  };
};

export const clearUserError = () => {
  return {
    type: CLEAR_ERROR
  };
};

export const toggleUserListener = (userId, forceListener) => {
  return async (dispatch, getState) => {
    if (userId) {
      if (forceListener || !getState().user.listener) {
        if (forceListener && getState().user.listener) {
          // kill the old listener first
          getState().user.listener();
        }

        const listener = userDB.listenToUserChanges((doc) => {
          const user = doc.data();

          dispatch({
            type: INITIALIZE_USER_COMPLETE,
            user: user
          });

          // setup following listener when we have current user's uuid
          dispatch(toggleUserFollowingListener(user?.userUuid));

          initUserInfo(user);
        }, userId);
        return dispatch({
          type: USER_LISTENER_ON,
          payload: { listener }
        });
      }
    } else {
      if (getState().user.listener) {
        getState().user.listener();
      }

      dispatch({
        type: INITIALIZE_USER_COMPLETE,
        user: null
      });

      return dispatch({
        type: USER_LISTENER_OFF
      });
    }
  };
};

export const updateEmail = (email) => {
  const SCREEN_ID = "emailUpdated";
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_EMAIL,
        email: email
      });
      const validEmailResponse = await validateEmail(email);
      if (validEmailResponse.status === 409) {
        return dispatch({
          type: USER_UPDATE_EMAIL_COMPLETE,
          error: true,
          message: "Email is associated with an existing user"
        });
      } else if (validEmailResponse.status === 422) {
        return dispatch({
          type: USER_UPDATE_EMAIL_COMPLETE,
          error: true,
          message: "Invalid Email Address"
        });
      } else if (!isRequestSuccess(validEmailResponse)) {
        return dispatch({
          type: USER_UPDATE_EMAIL_COMPLETE,
          error: true,
          message: "Invalid Email"
        });
      }

      // await firebaseAuth.updateEmail(email);
      const result = await updateUser({ email: email }, SCREEN_ID);
      if (result.error) {
        //FIXME: retry until it pass through
        return dispatch({
          type: USER_UPDATE_EMAIL_COMPLETE,
          error: true,
          message: "Failed to update email in backend."
        });
      } else {
        return dispatch({
          type: USER_UPDATE_EMAIL_COMPLETE
        });
      }
    } catch (error) {
      console.debug(error.message);
      return dispatch({
        type: USER_UPDATE_EMAIL_COMPLETE,
        error: true,
        message: "Failed to update email"
      });
    }
  };
};

export const updateLanguage = (language) => {
  return async (dispatch) => {
    dispatch({
      type: USER_UPDATE_LANGUAGE
    });
    try {
      // update language call
    } catch (error) {
      return dispatch({
        type: USER_UPDATE_LANGUAGE_COMPLETE,
        error: true,
        message: "Failed to update language"
      });
    }
  };
};

/**
 * This is a blocking call. Get user progress data needed for
 * interactive cases only if we don't have it already. Fetches in batches of 10
 * due to FireStore limitation.
 *
 * https://firebase.google.com/docs/firestore/query-data/queries#limitations_2
 *
 * TODO: If this gets big, need to think about a way to clear out older data. Maybe
 * something like a cyclical linked list, where after 50 entries, start replacing
 * older with newer. Need to then implement a way to get older ones back.
 *
 * TODO: What should happen when switching channels? When users return to channel, they
 * are brought back to where they last left off. So maybe not...
 */
const MAX_WHERE_SIZE = 10;
export const fetchInteractiveCaseStates = (caseUuids) => {
  return async (dispatch, getState) => {
    if (caseUuids) {
      const { userUid, interactiveCaseStates } = getState()?.user;

      dispatch({
        type: FETCH_USER_INTERACTIVE_CASE_STATES
      });

      const newCaseUuids = caseUuids.filter(
        (caseUuid) => !interactiveCaseStates[caseUuid]
      );

      let newStates = null;

      if (!isEmpty(newCaseUuids)) {
        const queryPromises = [];
        // Make all query calls to FireStore and wait for them all to return
        for (let i = 0; i < newCaseUuids.length; i += MAX_WHERE_SIZE) {
          queryPromises.push(
            userDB.queryInteractiveCaseStates(
              userUid,
              newCaseUuids.slice(i, i + MAX_WHERE_SIZE)
            )
          );
        }

        newStates = await Promise.all(queryPromises).then((results) => {
          // Aggregate into one object
          let data = {};
          results.forEach((result) => {
            data = {
              ...data,
              ...result
            };
          });

          return data;
        });
      }

      dispatch({
        type: FETCH_USER_INTERACTIVE_CASE_STATES_COMPLETE,
        payload: {
          interactiveCaseStates: newStates
        }
      });
    }
  };
};

export const listenInteractiveCase = (caseUuid, on) => {
  return (dispatch, getState) => {
    try {
      const userUid = getState()?.user?.userUid;
      const listener =
        getState()?.user?.interactiveCaseStates?.[caseUuid]?.listener;

      if (on && !listener) {
        const newListener = userDB.listenToInteractiveCaseStateChange(
          userUid,
          caseUuid,
          (newState) => {
            dispatch({
              type: UPDATE_INTERACTIVE_CASE_STATE,
              payload: {
                caseUuid,
                newState
              }
            });
          }
        );

        dispatch({
          type: LISTEN_INTERACTIVE_CASE_STATE_ON,
          payload: {
            caseUuid,
            listener: newListener
          }
        });
      } else if (!on && listener) {
        listener();

        dispatch({
          type: LISTEN_INTERACTIVE_CASE_STATE_OFF,
          payload: {
            caseUuid
          }
        });
      }
    } catch (error) {
      console.log("Error listening to interactive case: ", error.message);
    }
  };
};

export const optimisticAddVote = ({
  caseUuid,
  contentUuid,
  questionOptionUuid,
  isAnswer
}) => {
  return (dispatch) => {
    dispatch({
      type: OPTIMISTIC_ADD_VOTE,
      payload: {
        caseUuid,
        contentUuid,
        questionOptionUuid,
        isAnswer
      }
    });
  };
};

export const optimisticResetVote = ({ caseUuid, contentUuid }) => {
  return (dispatch) => {
    dispatch({
      type: OPTIMISTIC_RESET_VOTE,
      payload: {
        caseUuid,
        contentUuid
      }
    });
  };
};

const preparePromoCards = (cards) => {
  const currentDate = new Date().toISOString();
  const validCards = cards
    ?.filter((c) => {
      const startValid = c.startDate
        ? moment(c.startDate).isBefore(currentDate, "day")
        : true;
      const endValid = c.endDate
        ? moment(c.endDate).isAfter(currentDate, "day")
        : true;

      return c.web?.showCard && startValid && endValid;
    })
    .sort((card1, card2) => {
      // sort by priority first
      if (card1.priority > card2.priority) return 1;
      if (card1.priority < card2.priority) return -1;

      if (moment(card1.startDate).isSame(card2.startDate)) {
        return 0;
      }

      return moment(card1.startDate).isBefore(card2.startDate) ? -1 : 1;
    })
    .map((card) => {
      return { feedCardType: FEED_CARD_TYPE.PROMO, ...card };
    });

  const expiredCards = cards?.filter((c) =>
    moment(c.endDate).isBefore(currentDate, "day")
  );

  return {
    validCards,
    expiredCards
  };
};

export const listenToPromoCards = (userUid, on) => {
  return async (dispatch, getState) => {
    const promo = getState()?.user?.promoCards;
    const unsubscribe = promo?.unsubscribe;

    try {
      if (on && !unsubscribe) {
        const _unsub = userDB.listenToPromoCards(userUid, (cards) => {
          const { validCards, expiredCards } = preparePromoCards(cards);

          if (!isEmpty(expiredCards)) {
            dispatch(removePromoCards(expiredCards.map((c) => c.caseUuid)));
          }

          dispatch({
            type: PROMO_CARDS_UPDATE,
            payload: {
              promoCards: cards,
              validCards
            }
          });
        });
        dispatch({
          type: PROMO_CARDS_LISTENER_ON,
          payload: {
            unsubscribe: _unsub
          }
        });
      } else if (!on && unsubscribe) {
        unsubscribe();
        dispatch({
          type: PROMO_CARDS_LISTENER_OFF,
          payload: { unsubscribe: null }
        });
      }
    } catch (error) {
      console.log("listenToUserPreferences failed: ", error.message);
    }
  };
};

export const removePromoCards = (cardUuids) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: PROMO_CARDS_REMOVE,
        cardUuids
      });

      const userUid = getState().user.userUid;
      await userDB.removePromoCards(userUid, cardUuids);
      dispatch({
        type: PROMO_CARDS_REMOVE_COMPLETE
      });
    } catch (e) {
      console.log("removePromoCards failed:", e.message);
    }
  };
};
