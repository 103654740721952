/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  META_LISTENER_UPDATE,
  AVAILABLE_LISTENER_UPDATE,
  COMPLETED_LISTENER_UPDATE,
  META_UPDATE,
  AVAILABLE_UPDATE,
  COMPLETED_UPDATE,
  SUBMIT_CERTIFICATE_DEGREE,
  SUBMIT_CERTIFICATE_DEGREE_COMPLETE
} from "../actions/user-cme.actions";

const INITIAL_STATE = {
  meta: {},
  availableActivities: [],
  completedActivities: [],
  metaUnsub: null,
  availableUnsub: null,
  completedUnsub: null,
  isProcessing: false
};

const UserCmeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case META_LISTENER_UPDATE:
      return { ...state, metaUnsub: action.metaUnsub };
    case AVAILABLE_LISTENER_UPDATE:
      return { ...state, availableUnsub: action.availableUnsub };
    case COMPLETED_LISTENER_UPDATE:
      return { ...state, completedUnsub: action.completedUnsub };
    case META_UPDATE:
      return { ...state, meta: action.meta };
    case AVAILABLE_UPDATE:
      return { ...state, availableActivities: action.availableActivities };
    case COMPLETED_UPDATE:
      return { ...state, completedActivities: action.completedActivities };
    case SUBMIT_CERTIFICATE_DEGREE:
      return { ...state, isProcessing: true };
    case SUBMIT_CERTIFICATE_DEGREE_COMPLETE:
      return { ...state, isProcessing: false };
    case RESET_ALL:
      if (typeof state.metaUnsub === "function") state.metaUnsub();
      if (typeof state.availableUnsub === "function") state.availableUnsub();
      if (typeof state.completedUnsub === "function") state.completedUnsub();
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default UserCmeReducer;
