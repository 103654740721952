/** @format */

import { auth, getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const getActivityBatch = async (
  limit,
  startAfterTimestamp,
  callback
) => {
  const user = auth().currentUser;
  const allUserActivityRecordSnapshot = await getCollectionRef(
    `${DB_CONSTANTS.USER_ACTIVITY_RECORD}/${user.uid}/all`
  )
    .orderBy("createdAt", "desc")
    .startAfter(startAfterTimestamp)
    .limit(limit)
    .get();

  const allActivity = [];

  allUserActivityRecordSnapshot.forEach(async (doc) => {
    const data = doc.data();
    data.activityId = doc.id;
    allActivity.push(data);
  });

  callback(allActivity);
};

export const listenForUserActivityRecordChanges = async (callback) => {
  const user = auth().currentUser;

  const userActivityRecordDocument = getCollectionRef(
    DB_CONSTANTS.USER_ACTIVITY_RECORD
  ).doc(user.uid);

  return userActivityRecordDocument.onSnapshot(
    (userActivityRecordSnapshot) => {
      callback(userActivityRecordSnapshot.data());
    },
    (onObservableNextOrError) => {
      console.log("onObservableNextOrError: ", onObservableNextOrError);
    }
  );
};
