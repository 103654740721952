/** @format */

import CME_SECTION from "../constants/cme-section";
import moment from 'moment';

export const getUserCountry = (user) => {
  let country = 'IT';
  if (user && user.country && user.country.countryCode) {
    country = user.country.countryCode;
  }
  return country;
}

// Note that argument 'dateInTargetMonth' must be a value acceptable to 
// the moment constructor, by default 'undefined' means 'this month' 
// but any other value would return the total credits earned within 
// the month of the date specified in dateInTargetMonth
export const getCompletedCredits = (activities, withinMonth = false, dateInTargetMonth = null) => {
  let total = 0.0;
  if (Array.isArray(activities)) {
    total = activities.reduce((state, activity) => {
      const status = getActivityStatus(activity);
      if (status.completed) {
        let skip_calculation = false;
        if (withinMonth) {
          // The calculation is skipped if the caller of this function 
          // wants to calculate the credits earned within the month of 
          // the passed in dateInTargetMonth date but the activity's
          // completion date falls outside the month inclusive of that 
          // date (dateInTargetMonth)
          const completedAt = moment(activity.completedAt);
          if (completedAt.isValid()) {
            const today = dateInTargetMonth ? moment(dateInTargetMonth) : moment.utc();
            const startOfMonth = moment(today).startOf('month');
            const endOfMonth = moment(today).endOf('month');
            skip_calculation = completedAt.isBefore(startOfMonth) || completedAt.isAfter(endOfMonth);
          }
        }
        if (!skip_calculation) {
          try {
            const value = parseFloat(activity.credits);
            if (!isNaN(value) && value !== Infinity) {
              state += value;
            }
          } catch (error) {
            // Swallow the exception, most likely garbage came 
            // in the credits property
          }
        }
      }
      return state;
    }, total);
  }
  return total;
}

// This function returns a valid moment object if the input
// date is valid, otherwise it returns null
export const isDateValid = (date) => {
  let valid = null;
  if (date) {
    try {
      const mDate = moment(date); // expected a string UTC date value
      if (mDate.isValid()) {
        valid = mDate;
      }
    } catch (error) {
      // Swallow the exception, this could happen
      // if date brings a non-null value with a 
      // malformed date string that may cause moment
      // to raise an exception (rarely, if ever)
    }
  }
  return valid;
}

export const getActivityStatus = (activity = {}) => {
  const {
    endAt = null,
    startAt = null,
    currentSlide = 0,
    completedAt = null,
    certificateDownloadUrl = null
  } = activity;
  const now = moment.utc();

  const endDate = isDateValid(endAt);
  const startDate = isDateValid(startAt);
  const completedDate = isDateValid(completedAt);

  let expired = false;
  let startsInFuture = false;
  let completed = !!completedDate;
  let hasCertificate = !!certificateDownloadUrl;
  let inProgress = !completed && currentSlide > 0;

  if (endDate) {
    expired = endDate.isBefore(now);
  }

  if (startDate) {
    startsInFuture = startDate.isAfter(now);
  }

  return {
    expired,
    startsInFuture,
    completed,
    inProgress,
    hasCertificate
  };
}

export const getCMEBundleFor = (section, contentItems) => {
  if (!contentItems || contentItems.length === 0) {
    return [];
  }

  return contentItems
    .filter((c) => c.section === section)
    .sort((a, b) => a.displayOrder - b.displayOrder);
};

export const processCaseDataForBundles = (caseData) => {
  if (
    !caseData ||
    !caseData.contentItems ||
    caseData.contentItems.length === 0
  ) {
    return;
  }

  const contentItems = caseData.contentItems;
  caseData[CME_SECTION.FRONT_MATTER] = getCMEBundleFor(
    CME_SECTION.FRONT_MATTER,
    contentItems
  );
  caseData[CME_SECTION.PRE_TEST] = getCMEBundleFor(
    CME_SECTION.PRE_TEST,
    contentItems
  );
  caseData[CME_SECTION.ACTIVITY] = getCMEBundleFor(
    CME_SECTION.ACTIVITY,
    contentItems
  );
  caseData[CME_SECTION.POST_TEST] = getCMEBundleFor(
    CME_SECTION.POST_TEST,
    contentItems
  );
  caseData[CME_SECTION.SURVEY] = getCMEBundleFor(
    CME_SECTION.SURVEY,
    contentItems
  );
};
