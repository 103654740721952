/** @format */

import { areEqual } from "../utils/translation-utils";
import { selectIsSponsoredCase } from "./case.selectors";
import { selectCurrentLanguageCode } from "./localization.selectors";

export const selectIsLoadingTranslation = (state, commentUuid) => {
  return state.comment.translations?.[commentUuid]?.isLoading ?? false;
};

export const selectIsViewingTranslation = (state, commentUuid) => {
  return state.comment.translations?.[commentUuid]?.isTranslated ?? false;
};

export const selectIsTranslationEnabled = (state, commentUuid, caseUuid) => {
  const isSponsoredCase = selectIsSponsoredCase(state, caseUuid);
  // if (isSponsoredCase) { // disabled for the release branch only
  return false;
  // }

  const comment = selectCaseComment(state, caseUuid, commentUuid);
  const currentLanguageCode = selectCurrentLanguageCode(state);
  return !areEqual(comment?.language ?? "en-US", currentLanguageCode);
};

const selectCaseComment = (state, caseUuid, commentUuid) => {
  return state.comment.caseComments[caseUuid]?.comments.find(
    (c) => c.commentUuid === commentUuid
  );
};
