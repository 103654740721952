/** @format */

import {
  FETCH_USER_ACTIVITY_METADATA_ERROR,
  SET_ALL_ACTIVITY_READ_ERROR,
  FETCH_USER_ACTIVITY_METADATA_COMPLETE,
  SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_ON,
  SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_OFF,
  FETCH_USER_ACTIVITY_RECORDS,
  FETCH_USER_ACTIVITY_RECORDS_ERROR,
  FETCH_USER_ACTIVITY_RECORDS_COMPLETE,
  SET_ALL_ACTIVITY_READ
} from "../actions/activity-center.actions";
import { RESET_ALL } from "../actions/global.actions";
import { SIGNOUT_COMPLETE } from "../actions/login.actions";

export const INITIAL_STATE = {
  activityMeta: {},
  allActivity: [],
  unreadActivity: [],
  newActivity: [],
  remainingActivity: [],
  metaDataError: null,
  metaDataListener: null,
  activityError: null,
  isActivityLoading: false,
  endReached: false
};

const getNewActivityState = (state, { payload }) => {
  const newState = {
    ...state,
    isActivityLoading: false,
    endReached: payload.isEndReached
  };

  const shouldClearOldActivity = payload.shouldClearOldActivity;
  const newAllActivity = payload.allActivity;

  if (shouldClearOldActivity) {
    newState.allActivity = newAllActivity;
  } else if (newAllActivity.length > 0) {
    newState.allActivity = [...state.allActivity, ...newAllActivity];
  }

  return newState;
};

const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_COMPLETE: {
      return {
        ...INITIAL_STATE
      };
    }
    case FETCH_USER_ACTIVITY_METADATA_ERROR:
    case SET_ALL_ACTIVITY_READ_ERROR:
      return {
        ...state,
        metaDataError: action.payload.message
      };
    case FETCH_USER_ACTIVITY_METADATA_COMPLETE:
      return {
        ...state,
        activityMeta: action.payload.metadata
      };
    case SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_ON:
      return {
        ...state,
        metaDataListener: action.payload.listener
      };
    case SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_OFF:
      return {
        ...state,
        metaDataListener: null
      };

    case FETCH_USER_ACTIVITY_RECORDS:
      return {
        ...state,
        isActivityLoading: true
      };
    case FETCH_USER_ACTIVITY_RECORDS_ERROR:
      return {
        activityError: action.message,
        isActivityLoading: false
      };
    case FETCH_USER_ACTIVITY_RECORDS_COMPLETE:
      return getNewActivityState(state, action);
    case SET_ALL_ACTIVITY_READ:
      return {
        ...state,
        activityMeta: {
          ...state.activityMeta,
          newActivityCount: null
        }
      };
    case RESET_ALL:
      if (typeof state.metaDataListener === "function")
        state.metaDataListener();
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default activityReducer;
