import React, { useState } from "react";

const withModalManagement = ({ isInitiallyOpen = true }) => (
  ModalComponent
) => {
  const WithModalManagement = (modalProps) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    return (
      <ModalComponent
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        {...modalProps}
      />
    );
  };

  WithModalManagement.displayName = `WithModalManagement(${getDisplayName(
    ModalComponent
  )})`;

  return WithModalManagement;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export const withOpenModalManagement = withModalManagement({
  isInitiallyOpen: true
});

export default withModalManagement;
