/** @format */

import React from "react";
import "./F1HorizontalDivider.scss";

const F1HorizontalDivider = ({
  children,
  className = "",
  color = "battleship-gray"
}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className={`my-3 horizontal-divider border-${color} ${className}`}>
        <div className="flex-shrink-0">{children}</div>
      </div>
    </div>
  );
};

export default F1HorizontalDivider;
