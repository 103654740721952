import qs from "qs";
import { StaticStrings } from "../constants/static-string-constants";

export const buildMailToEmailHref = (emailAddress, subject, body) => {
  // Properly encodes query characters. Omits keys with `undefined` values.
  const query = qs.stringify({
    subject: subject,
    body: body
  });

  // Note: Have to add the "?" since query doesn't have it
  return `mailto:${emailAddress}${query ? `?${query}` : ""}`;
};

export const getSupportEmail = ({ subject, body } = {}) => {
  return buildMailToEmailHref(StaticStrings.supportEmail, subject, body);
};
