import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { get } from "lodash";
import classnames from "../../../utils/class-names-utils";

const ProfileBioEditor = React.forwardRef(
  (
    { className, value, onChangeText, onChange, invalid = false, ...rest },
    ref
  ) => {
    /************************************ CONFIG ***************************************/

    /************************************ HOOKS ****************************************/

    /*********************************** FUNCTIONS *************************************/

    /************************************ RENDER ***************************************/

    return (
      <TextareaAutosize
        ref={ref}
        className={classnames(
          "autoresize-textarea",
          "form-control",
          "resize-none",
          invalid && "is-invalid",
          className
        )}
        onChange={(event) => onChangeText(get(event, "target.value"))}
        value={value}
        {...rest}
      />
    );
  }
);

ProfileBioEditor.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  className: PropTypes.string
};

ProfileBioEditor.defaultProps = {};

export default ProfileBioEditor;
