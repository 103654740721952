/** @format */

import React, { useEffect, useState } from "react";

const ProfessionLine = (props) => {
  const { profession, ...rest } = props;
  const [stitched, setStitched] = useState([]);

  useEffect(() => {
    const pieces = profession.split("|");
    const newStitch = [];
    pieces.forEach((piece, i) => {
      newStitch.push(piece);
      if (i < pieces.length - 1) {
        newStitch.push(
          <span className="text-aqua" key={i}>
            |
          </span>
        );
      }

      setStitched(newStitch);
    });
  }, [profession]);

  return <span {...rest}>{stitched}</span>;
};

export default ProfessionLine;
