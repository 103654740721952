import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCommentsListener } from "../../actions/comment.actions";

export const useComments = (caseUuid, contentUuid, activateListener = true) => {
  const dispatch = useDispatch();

  const commentStatus = useSelector(
    (state) => state.comment.caseComments[caseUuid]
  );

  useEffect(() => {
    if (caseUuid && contentUuid && activateListener) {
      dispatch(toggleCommentsListener(caseUuid, contentUuid, true));
    }

    return () => {
      if (activateListener) {
        dispatch(toggleCommentsListener(caseUuid, null, false));
      }
    };
  }, [caseUuid, contentUuid, dispatch, activateListener]);

  return {
    commentStatus
  };
};

export default useComments;
