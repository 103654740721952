import React, { useEffect, useState } from "react";
import F1AvatarLink from "../common/F1AvatarLink";
import CommentHeader from "./CommentHeader";
import CommentFooter from "./CommentFooter";
import i18n from "../../utils/i18n";
import Collapse from "reactstrap/lib/Collapse";
import ReplyThread from "./ReplyThread";
import ReplyContainer from "./ReplyContainer";
import PropTypes from "prop-types";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { useSelector } from "react-redux";
import "./Comment.scss";
import { injectUserTag } from "../../utils/comment-utils";
import useIsCurrentUser from "../../hooks/useIsCurrentUser";
import EditContainer from "./EditContainer";
import useCommentTranslation from "../../hooks/useCommentTranslation";

const Comment = ({
  comment,
  reply,
  reportEnabled,
  caseUuid,
  contentUuid,
  position,
  caseContent,
  commentStatus,
  isGroup = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const [isOpen, setIsOpen] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [replyThreadText, setReplyThreadText] = useState(
    i18n.t("comments.viewAllReplies")
  );
  const currentUser = useSelector((state) => state.user);

  const { theme } = useTheme();
  const { isDeleted, isReported, deletedAt, reportedAt, author = {} } = comment;
  const { userUuid = null } = author;
  const isCurrentUser = useIsCurrentUser(userUuid);

  const deleteEnabled = currentUser.userUuid === userUuid;
  const [injectedCommentText, setInjectedCommentText] = useState("");
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const {
    text,
    isTranslationEnabled,
    isLoadingTranslation,
    isViewingTranslation,
    toggleTranslation
  } = useCommentTranslation(caseUuid, comment);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleReplyThread = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    setReplyThreadText(
      isOpen
        ? i18n.t("comments.hideReplies")
        : i18n.t("comments.viewAllReplies")
    );
  }, [isOpen]);

  useEffect(() => {
    setInjectedCommentText(injectUserTag(text));
  }, [text]);

  const openReply = () => {
    setShowReply(true);
  };

  const closeReply = () => {
    setShowReply(false);
  };

  const onExpandReplies = () => {
    setIsOpen(true);
  };

  const toggleEditComment = (show) => {
    setIsEditing(show);
  };
  const verifiedOpenReply = useVerifiedUserGate(openReply);

  /** ********************************** RENDER ***************************************/

  const avatar = (
    <F1AvatarLink
      // for old comments without an author, just pass the comment
      user={comment.author ? comment.author : comment}
      {...(reply && { size: "sm" })}
    />
  );

  let commentElement = (
    <div className="d-flex">
      {avatar}

      <div className="w-100 ml-2 d-flex flex-column">
        <CommentHeader comment={comment} reply />
        <div className="comment">{injectedCommentText}</div>
        <CommentFooter
          caseUuid={caseUuid}
          reportEnabled={reportEnabled}
          onReplyClick={verifiedOpenReply}
          onEditClick={() => toggleEditComment(true)}
          replyEnabled={!comment?.author?.isDeleted || comment.replyable}
          deleteEnabled={deleteEnabled}
          editEnabled={isCurrentUser && !commentQueueEnabled}
          comment={comment}
          isTranslationEnabled={isTranslationEnabled}
          isLoadingTranslation={isLoadingTranslation}
          isViewingTranslation={isViewingTranslation}
          onToggleTranslation={toggleTranslation}
        />
      </div>
    </div>
  );

  if (isDeleted || deletedAt) {
    commentElement = (
      <div className="deleted-container">
        {i18n.t("comments.commentDeleted")}
      </div>
    );
  }

  if (isReported || reportedAt) {
    commentElement = (
      <div className="deleted-container">
        {i18n.t("comments.commentReported")}
      </div>
    );
  }

  if (isEditing) {
    commentElement = (
      <EditContainer
        onCloseClick={() => toggleEditComment(false)}
        comment={comment}
      />
    );
  }

  return (
    <>
      {commentElement}

      <Collapse isOpen={showReply}>
        <ReplyContainer
          parentComment={comment}
          caseUuid={caseUuid}
          contentUuid={contentUuid}
          onCloseClick={closeReply}
          onExpandReplies={onExpandReplies}
          replyTo={comment.username}
          position={position}
          caseContent={caseContent}
          commentStatus={commentStatus}
          isGroup={isGroup}
        />
      </Collapse>

      <div className="reply-padding">
        {comment.replyThread && (
          <>
            <Collapse isOpen={isOpen}>
              <ReplyThread
                replies={comment.replyThread}
                reportEnabled={reportEnabled}
                caseUuid={caseUuid}
                contentUuid={contentUuid}
                position={position}
                caseContent={caseContent}
                commentStatus={commentStatus}
              />
            </Collapse>

            <div
              className={classNames(
                "d-inline-flex align-items-center cursor-pointer mb-3",
                theme === THEMES.DARK ? "text-light-gray-blue" : null
              )}
              onClick={toggleReplyThread}>
              <div style={{ width: "36px" }}>
                <hr
                  className={classNames(
                    "my-2",
                    theme === THEMES.DARK ? "border-light-gray-blue" : null
                  )}
                />
              </div>
              <div className="pl-2 font-weight-bold text-13">
                {replyThreadText}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  reply: PropTypes.bool,
  reportEnabled: PropTypes.bool,
  caseUuid: PropTypes.string.isRequired,
  contentUuid: PropTypes.string.isRequired
};

export default Comment;
