import { useDispatch } from "react-redux";
import feedActions from "../actions/feeds.actions";
import useCurrentFeedTypeUuid from "./useCurrentFeedTypeUuid";

const useResetFilter = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const feed = useCurrentFeedTypeUuid();
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const resetFilters = () => {
    dispatch(feedActions.updateFeedFilter(feed, {}));
  };
  /** ********************************** OUTPUT ***************************************/
  return { resetFilters };
};

export default useResetFilter;
