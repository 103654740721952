import { useEffect, useMemo, useState } from "react";

/**
 * React hook to inform you when the provided element is visible on the screen
 *
 * @param {React.MutableRefObject<HTMLElement>} elementRef
 * @param {IntersectionObserverInit} [options]
 */
function useOnScreen(elementRef, options) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options
      ),
    [options]
  );

  useEffect(() => {
    if (elementRef && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, elementRef]);

  return isIntersecting;
}

export default useOnScreen;
