/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  AUTHENTICATE,
  AUTHENTICATE_COMPLETE,
  AUTHENTICATION_REVOKED,
  START_VERIFICATION,
  AUTHENTICATE_ERROR,
  REAUTHENTICATE,
  REAUTHENTICATE_COMPLETE,
  AUTO_LOGIN,
  AUTO_LOGIN_COMPLETE,
  SIGNOUT_COMPLETE,
  SET_INITIAL_ROUTE,
  LEGACY_MIGRATION,
  LEGACY_MIGRATION_COMPLETE,
  SET_REDIRECT_ROUTE
} from "../actions/login.actions";
import NonUserActionTypes from "../actions/non-user-action-types";

export const INITIAL_STATE = {
  autoLoginComplete: false,
  isAuthenticated: false,
  initializing: false,
  initialized: false,
  accessToken: null,
  accessTokenExpirationDate: null,
  error: false,
  initialRoute: null,
  migratingLegacyAccount: false,
  redirectRoute: null
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case AUTHENTICATE_COMPLETE: {
      return {
        ...state,
        initializing: false,
        initialized: true,
        error: false,
        loginResult: action.loginResult,
        user: action.loginResult ? action.loginResult.user : null
      };
    }
    case RESET_ALL:
    case AUTHENTICATION_REVOKED:
    case NonUserActionTypes.CHECK_AUTH:
      const redirect = state.redirectRoute
        ? { redirectRoute: state.redirectRoute }
        : null;
      return {
        ...state,
        ...INITIAL_STATE,
        ...redirect
      };
    case START_VERIFICATION: {
      return {
        ...state,
        initializing: true,
        error: false
      };
    }
    case AUTHENTICATE_ERROR: {
      return {
        ...state,
        initializing: false,
        error: true,
        errorResult: action.loginResult
      };
    }
    case REAUTHENTICATE:
      return {
        ...state,
        initializing: true,
        error: false
      };
    case REAUTHENTICATE_COMPLETE:
      return {
        ...state,
        initializing: false,
        error: action.error
      };
    case AUTO_LOGIN: {
      return {
        ...state,
        autoLoginComplete: false
      };
    }
    case AUTO_LOGIN_COMPLETE: {
      return {
        ...state,
        autoLoginComplete: true,
        isAuthenticated: action.isAuthenticated
      };
    }
    case SIGNOUT_COMPLETE: {
      return {
        ...INITIAL_STATE,
        initialized: true,
        redirectRoute: state.redirectRoute
      };
    }
    case SET_INITIAL_ROUTE: {
      return {
        ...state,
        initialRoute: action.initialRoute
      };
    }
    case SET_REDIRECT_ROUTE: {
      return {
        ...state,
        redirectRoute: action.redirectRoute
      };
    }
    case LEGACY_MIGRATION: {
      return {
        ...state,
        migratingLegacyAccount: true
      };
    }
    case LEGACY_MIGRATION_COMPLETE: {
      return {
        ...state,
        migratingLegacyAccount: false
      };
    }
    default:
      return {
        ...state
      };
  }
};

export default loginReducer;
