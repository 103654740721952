import React from "react";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";

const GroupsNavItem = ({
  url,
  name,
  imgUrl,
  isActiveFn = (match) => {
    return !!match;
  }
}) => {
  /** ********************************** CONFIG ***************************************/
  const mobileClasses = isMobile ? "px-2 py-2" : "px-3 py-2";
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <NavLink
      isActive={isActiveFn}
      to={{
        pathname: url,
        state: {
          context: "Navigation"
        }
      }}
      activeClassName="active"
      className={`d-flex align-items-center  nav-item ${mobileClasses}`}>
      {imgUrl && <img className={`group-icon`} src={imgUrl} alt="" />}
      <div className="pl-2">{name}</div>
    </NavLink>
  );
};

export default GroupsNavItem;
