import React from "react";
import { Col } from "reactstrap";
import * as PropTypes from "prop-types";
import Figure1PageContainer from "./Figure1PageContainer";

const Figure1Page2Col = React.forwardRef((props, ref) => {
  return (
    <Figure1PageContainer
      isChildPage={props.isChildPage}
      isContactSupport={props.isContactSupport}
      contactSupportClick={props.contactSupportClick}
      headerButtonTitle={props.headerButtonTitle}
      headerButtonClick={props.headerButtonClick}
      headerButtonLoadingStatus={props.headerButtonLoadingStatus}
      headerRowSlot={props.headerRowSlot}>
      {props.mainContent && (
        <Col lg={props.mainContentSize} className="mb-3 mb-lg-0">
          {props.mainContent}
        </Col>
      )}

      {props.rightSidebarContent && (
        <Col lg={props.rightSidebarContentSize}>
          {props.rightSidebarContent}
        </Col>
      )}
    </Figure1PageContainer>
  );
});

Figure1Page2Col.propTypes = {
  mainContent: PropTypes.node,
  rightSidebarContent: PropTypes.node,
  mainContentSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  rightSidebarContentSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  isChildPage: PropTypes.bool,
  isContactSupport: PropTypes.bool,
  contactSupportClick: PropTypes.func,
  headerButtonTitle: PropTypes.string,
  headerButtonClick: PropTypes.func,
  headerButtonLoadingStatus: PropTypes.bool,
  headerRowSlot: PropTypes.element
};

Figure1Page2Col.defaultProps = {
  isChildPage: false,
  isContactSupport: false,
  mainContentSize: 7,
  rightSidebarContentSize: 5
};

export default Figure1Page2Col;
