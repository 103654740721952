/** @format */

import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { ContentProp } from "../../../prop-types/ContentProp";
import QuestionDetailCard from "../../../components/case/QuestionDetailCard";
import CMSlideBase from "./CMSlideBase";

const CMQuestionSlide = ({ content, index, count }) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/
  // useTrackSlideView(content);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return (
    <>
      <CMSlideBase content={content}>
        <QuestionDetailCard
          className="mb-0"
          currentIndex={index}
          nbQuestions={count}
          caseContent={content}
          hideCaseOptions={true}
          onNext={noop}
          onPrev={noop}
        />
      </CMSlideBase>
    </>
  );
};

CMQuestionSlide.propTypes = {
  content: ContentProp,
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default CMQuestionSlide;
