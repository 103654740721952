/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const postComment = async (contentUuid, commentText, parentId) => {
  const result = await functions.httpsCallable("backendCommentCreate")({
    content_uuid: contentUuid,
    comment_text: commentText,
    parent_comment_uuid: parentId
  });
  return result.data;
};

export const editComment = async (commentUuid, commentText) => {
  const result = await functions.httpsCallable("backendCommentEdit")({
    comment_uuid: commentUuid,
    comment_text: commentText
  });
  return result.data;
};

export const deleteComment = async (commentId) => {
  const result = await functions.httpsCallable("backendCommentDelete")({
    comment_uuid: commentId
  });
  return result.data;
};

export const reportComment = async (commentId, reason, moreInfo) => {
  const result = await functions.httpsCallable("backendCommentReport")({
    comment_uuid: commentId,
    reason: reason,
    text: moreInfo
  });
  return result.data;
};

export const triggerUpdateComments = async (caseUuid) => {
  const result = await functions.httpsCallable("backendCommentTriggerUpdate")({
    caseUuid: caseUuid
  });
  return result.data;
};

export const translateComment = async (commentId, targetLanguage) => {
  const result = await functions.httpsCallable("backendCommentTranslate")({
    comment_uuid: commentId,
    target_language: targetLanguage
  });
  return result.data;
};
