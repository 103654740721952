/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { PROFILE_NETWORK_TYPE } from "../../../constants/routes";
import i18n from "../../../utils/i18n";
import { getUrl } from "../../../utils/route-utils";

const ProfileFollowerFollowing = ({ profile }) => {
  const userUuid = profile.userUuid;

  return (
    <div className="d-flex text-primary mb-2">
      <Link
        to={{
          pathname: getUrl(PROFILE_NETWORK_TYPE, {
            userUuid,
            viewType: "followers"
          }),
          state: {
            context: "Profile"
          }
        }}
        className="nav-link py-0 px-2">
        {profile.followerCount || 0} {i18n.t("Network.Followers")}
      </Link>
      &#8226;
      <Link
        to={{
          pathname: getUrl(PROFILE_NETWORK_TYPE, {
            userUuid,
            viewType: "following"
          }),
          state: {
            context: "Profile"
          }
        }}
        className="nav-link py-0 px-2">
        {profile.followingCount || 0} {i18n.t("Network.Following")}
      </Link>
    </div>
  );
};

export default ProfileFollowerFollowing;
