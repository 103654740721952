import { combineReducers } from "redux";

import activityReducer from "./activity-record.reducer";
import caseLabelReducer from "./case-label.reducer";
import caseReducer from "./case.reducer";
import caseSpecialtiesReducer from "./case-specialties.reducer";
import commentReducer from "./comment.reducer";
import configurationReducer from "./configuration.reducer";
import countryReducer from "./country.reducer";
import elasticSearchReducer from "./elastic-search.reducer";
import globalReducer from "./global.reducer";
import loginReducer from "./login.reducer";
import nonUserReducer from "./non-user.reducer";
import quizReducer from "./quiz.reducer";
import referenceReducer from "./reference.reducer";
import savedCasesReducer from "./saved-cases.reducer";
import searchFeedReducer from "./search-feed.reducer";
import searchReducer from "./search.reducer";
import specialtiesReducer from "./specialties.reducer";
import topicsReducer from "./topics.reducer";
import userCmeReducer from "./user-cme.reducer";
import userCommunicationPreferencesReducer from "./user-communication-preferences.reducer";
import userDraftReducer from "./user-draft.reducer";
import userFeedMetaReducer from "./user-feed-meta.reducer";
import userProfilesReducer from "./user-profiles.reducer";
import userReducer from "./user.reducer";
import userVerificationReducer from "./user-verification.reducer";
import userVoteAnswerReducer from "./user-vote-answer.reducer";
import videoStateReducer from "./video-state.reducer";
import metricsReducer from "./metrics.reducer";
import inviteColleaguesReducer from "./inviteColleagues.reducer";
import imageUploadReducer from "./image-upload.reducer";
import feedsReducer from "./feeds.reducer";
import localizationReducer from "./localization.reducer";

const rootReducer = combineReducers({
  activity: activityReducer,
  authentication: loginReducer,
  case: caseReducer,
  caseLabels: caseLabelReducer,
  caseSpecialties: caseSpecialtiesReducer,
  comment: commentReducer,
  configuration: configurationReducer,
  countries: countryReducer,
  elasticSearch: elasticSearchReducer,
  feeds: feedsReducer,
  global: globalReducer,
  nonUser: nonUserReducer,
  quiz: quizReducer,
  references: referenceReducer,
  savedCases: savedCasesReducer,
  search: searchReducer,
  searchFeed: searchFeedReducer,
  specialties: specialtiesReducer,
  topics: topicsReducer,
  user: userReducer,
  userCme: userCmeReducer,
  userCommunicationsPreferences: userCommunicationPreferencesReducer,
  userDraft: userDraftReducer,
  userFeedMeta: userFeedMetaReducer,
  userProfiles: userProfilesReducer,
  userVerification: userVerificationReducer,
  userVoteAnswer: userVoteAnswerReducer,
  videoState: videoStateReducer,
  metrics: metricsReducer,
  inviteColleages: inviteColleaguesReducer,
  imageUpload: imageUploadReducer,
  localization: localizationReducer
});

export default rootReducer;
