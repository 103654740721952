import React, { useState } from "react";
import { Fade } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import PropTypes from "prop-types";
import { saveCase } from "../../../actions/case.actions";
import i18n from "../../../utils/i18n";
import Image from "../../../components/common/Image";
import useDialog from "../../../components/dialog/useDialog";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";
import SavedCasesActions from "./SavedCasesActions";
import standInImage from "../../../assets/images/text case_rounded.png";
import standInVideoImage from "../../../assets/images/video_case_square.png";
import { getCaseTypeUrl } from "../../../utils/case-utils";

function SavedCasesTile({ caseContent }) {
  /************************************ CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const { showToast } = usePopup();
  const [isHovering, setIsHovering] = useState(false);
  const isProcessing = useSelector((state) =>
    get(state, `case.cases.${caseContent.caseUuid}.isProcessing`, false)
  );

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const viewCase = () => {
    history.push(getCaseTypeUrl(caseContent));
  };

  const confirmDeleteCase = async () => {
    const confirmed = await confirm({
      message: i18n.t("SavedCases.deleteEntryConfirmation")
    });

    if (!confirmed) {
      return;
    }

    try {
      const result = await dispatch(
        saveCase(caseContent.caseUuid, !caseContent.isSaved)
      );

      if (result.error) {
        showToast({
          message: i18n.t("common.genericError"),
          toastType: TOAST_TYPE.ERROR
        });
      }
    } catch (e) {
      showToast({
        message: i18n.t("common.genericError"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/
  let imgLink = standInImage;
  if (caseContent?.media?.[0]?.type === "video") {
    imgLink = standInVideoImage;
  } else if (caseContent?.media?.[0]?.url) {
    imgLink = `${caseContent?.media?.[0]?.url}?fit=crop&w=138&h=138`;
  }

  return (
    <div
      className="rounded-square-image"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <Image
        src={imgLink}
        fallbackSrc={standInImage}
        alt={caseContent.title ? caseContent.title : caseContent.caption}
      />
      <Fade in={isHovering} timeout={100} unmountOnExit>
        <SavedCasesActions
          isProcessing={isProcessing}
          onViewCase={viewCase}
          onDeleteCase={confirmDeleteCase}
        />
      </Fade>
    </div>
  );
}

SavedCasesTile.propTypes = {
  caseContent: PropTypes.shape({
    caseUuid: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    ),
    isSaved: PropTypes.bool
  })
};

export default SavedCasesTile;
