/** @format */

import * as specialtyDB from "../db/specialty.db";

const actionsPrefix = "specialties";

export const FETCH_SPECIALTIES = `${actionsPrefix}/FETCH_SPECIALTIES`;
export const FETCH_SPECIALTIES_COMPLETE = `${actionsPrefix}/FETCH_SPECIALTIES_COMPLETE`;
export const SELECT_SUB_SPECIALTY = `${actionsPrefix}/SELECT_SUB_SPECIALTY`;
export const CLEAR_SELECTION = `${actionsPrefix}/CLEAR_SELECTION`;

export const fetchSpecialtiesIfNeeded = () => {
  return async (dispatch, getState) => {
    if (getState().specialties.fetched) {
      return;
    }
    dispatch(fetchSpecialties());
  };
};

export const fetchSpecialties = () => {
  return async (dispatch, getState) => {
    if (getState().specialties.fetched) {
      return;
    }

    dispatch({
      type: FETCH_SPECIALTIES
    });
    try {
      const docs = await specialtyDB.fetchSpecialties();

      let professions = [];
      let specialties = {};
      let subSpecialties = {};
      let flattenedSubSpecialties = [];

      for (const key in docs) {
        const doc = docs[key];
        switch (doc.depth) {
          case 1:
            if (!specialties[doc.parent]) {
              specialties[doc.parent] = [];
            }
            specialties[doc.parent].push(doc);
            break;
          case 2:
            if (!subSpecialties[doc.parent]) {
              subSpecialties[doc.parent] = [];
            }
            subSpecialties[doc.parent].push(doc);
            flattenedSubSpecialties.push({ ...doc, parent: doc.parent });
            break;
          default:
            professions.push(doc);
            break;
        }
      }

      dispatch({
        type: FETCH_SPECIALTIES_COMPLETE,
        fetched: true,
        professions: professions,
        specialties: specialties,
        subSpecialties: subSpecialties,
        flattenedSubSpecialties: flattenedSubSpecialties
      });
    } catch (error) {
      dispatch({
        type: FETCH_SPECIALTIES_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const selectSubSpecialty = (id) => {
  return async (dispatch, getState) => {
    const selected = getState().specialties.selectedSubs;
    const newMap = { ...selected, [id]: !selected[id] };
    dispatch({
      type: SELECT_SUB_SPECIALTY,
      selectedSubs: newMap
    });
  };
};

export const clearSubSpecialtySelection = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SELECTION
    });
  };
};

export const getSpecialtiesOptions = () => {
  return (dispatch, getState) => {
    let specialtyOptions = [];
    // loading all the specialties here
    getState().specialties.professions.foreach((profession) => {
      const professionId = profession.treeUuid;
      if (getState().specialties.specialties[professionId]) {
        getState().specialties.specialties[professionId].foreach((spe) => {
          specialtyOptions.push({
            label: `${profession.name} - ${spe.name}`,
            value: spe.treeUuid
          });
        });
      }
    });
    specialtyOptions.sort((a, b) => a.label.localeCompare(b.label));

    let subSpecialtyOptions = [];
    specialtyOptions.foreach((specialty) => {
      const specialtyId = specialty.value;
      const specialtyLabel = specialty.label.split(" - ")[1];
      if (getState().specialties.subSpecialties[specialtyId]) {
        getState().specialties.subSpecialties[specialtyId].foreach((subspe) => {
          subSpecialtyOptions.push({
            label: `${specialtyLabel} - ${subspe.name}`,
            value: subspe.treeUuid
          });
        });
      }
    });
    subSpecialtyOptions.sort((a, b) => a.label.localeCompare(b.label));

    return {
      specialties: specialtyOptions,
      subSpecialties: subSpecialtyOptions
    };
  };
};
