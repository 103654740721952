import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setInitialRoute } from "../actions/login.actions";

const useInitialRouteRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initialRoute = useSelector(
    (state) => state.authentication?.initialRoute
  );

  useEffect(() => {
    if (initialRoute) {
      // grab route, then clear it
      const heldRoute = initialRoute;
      dispatch(setInitialRoute(null));
      history.push(heldRoute);
    }
  }, [initialRoute, history, dispatch]);
};

export default useInitialRouteRedirect;
