/** @format */

const SIGN_IN_ERROR_STATE = {
  AUTH_CHECK_FAILED: "AUTH_CHECK_FAILED",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  CREDENTIAL_INVALID: "CREDENTIAL_INVALID",
  UPDATE_UID_FAILED: "UPDATE_UID_FAILED",
  GENERIC_FAILURE: "GENERIC_FAILURE"
};

export default SIGN_IN_ERROR_STATE;
