/** @format */

import _ from "lodash";
import * as commentDB from "../db/comment.db";
import {
  postComment as postCommentCall,
  reportComment as reportCommentCall,
  deleteComment as deleteCommentCall,
  editComment as editCommentCall,
  triggerUpdateComments,
  translateComment as translateCommentCall
} from "../api/comment.cloud-functions";
import {
  collapseCommentTree,
  COMMENT_STATES,
  flattenCommentTree
} from "../utils/comment-utils";
import { isRequestSuccess } from "../utils/general-utils";
import { hasTranslation } from "../utils/translation-utils";
import { selectCurrentLanguageCode } from "../selectors/localization.selectors";

const actionsPrefix = "comments";

export const FETCH_COMMENTS = `${actionsPrefix}/FETCH_COMMENTS`;
export const FETCH_COMMENTS_COMPLETE = `${actionsPrefix}/FETCH_COMMENTS_COMPLETE`;
export const CACHE_COMMENT = `${actionsPrefix}/CACHE_COMMENT`;
export const RESET_COMMENT_CACHE = `${actionsPrefix}/RESET_COMMENT_CACHE`;
export const POST_COMMENT = `${actionsPrefix}/POST_COMMENT`;
export const POST_COMMENT_COMPLETE = `${actionsPrefix}/POST_COMMENT_COMPLETE`;
export const REPORT_COMMENT = `${actionsPrefix}/REPORT_COMMENT`;
export const REPORT_COMMENT_COMPLETE = `${actionsPrefix}/REPORT_COMMENT_COMPLETE`;
export const SORT_COMMENTS = `${actionsPrefix}/SORT_COMMENTS`;
export const DELETE_COMMENT = `${actionsPrefix}/DELETE_COMMENT`;
export const DELETE_COMMENT_COMPLETE = `${actionsPrefix}/DELETE_COMMENT_COMPLETE`;
export const SYNC_COMMENTS = `${actionsPrefix}/SYNC_COMMENTS`;
export const SYNC_COMMENTS_COMPLETE = `${actionsPrefix}/SYNC_COMMENTS_COMPLETE`;
export const COMMENTS_LISTENER_UPDATE = `${actionsPrefix}/COMMENTS_LISTENER_UPDATE`;
export const COMMENTS_UPDATED = `${actionsPrefix}/COMMENTS_UPDATED`;
export const COMMENTS_EXPAND_THREAD = `${actionsPrefix}/COMMENTS_EXPAND_THREAD`;
export const COMMENTS_COLLAPSE_THREAD = `${actionsPrefix}/COMMENTS_COLLAPSE_THREAD`;
export const COMMENTS_EXPAND_ROOT = `${actionsPrefix}/COMMENTS_EXPAND_ROOT`;
export const COMMENTS_COLLAPSE_ROOT = `${actionsPrefix}/COMMENTS_COLLAPSE_ROOT`;
export const COMMENTS_RESET = `${actionsPrefix}/COMMENTS_RESET`;
export const SELECT_COMMENT_SORT = `${actionsPrefix}/SELECT_COMMENT_SORT`;
export const SELECT_COMMENT_FILTER = `${actionsPrefix}/SELECT_COMMENT_FILTER`;
export const EDIT_COMMENT = `${actionsPrefix}/EDIT_COMMENT`;
export const EDIT_COMMENT_COMPLETE = `${actionsPrefix}/EDIT_COMMENT_COMPLETE`;
export const TRANSLATE_COMMENT = `${actionsPrefix}/TRANSLATE_COMMENT`;
export const TRANSLATE_COMMENT_COMPLETE = `${actionsPrefix}/TRANSLATE_COMMENT_COMPLETE`;
export const VIEW_ORIGINAL_COMMENT = `${actionsPrefix}/VIEW_ORIGINAL_COMMENT`;

export const fetchCaseComments = (caseId) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_COMMENTS
    });
    try {
      const comments = await commentDB.fetchComments(caseId);
      const flattenComments = flattenCommentTree(comments, {}, [], 0);
      dispatch({
        type: FETCH_COMMENTS_COMPLETE,
        comments: flattenComments,
        originalTree: comments
      });
    } catch (error) {
      dispatch({
        type: FETCH_COMMENTS_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const cacheComment = (caseUuid, contentUuid, comment) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CACHE_COMMENT,
      caseUuid: caseUuid,
      payload: {
        caseUuid: caseUuid,
        contentUuid: contentUuid,
        comment: comment
      }
    });

    dispatch(findAndRefreshComments(caseUuid, getState, dispatch));
  };
};

export const postComment = (caseUuid, contentUuid, commentText, parentId) => {
  return async (dispatch) => {
    dispatch({
      type: POST_COMMENT,
      caseUuid: caseUuid
    });
    try {
      const response = await postCommentCall(
        contentUuid,
        commentText,
        parentId
      );
      if (isRequestSuccess(response)) {
        return dispatch({
          type: POST_COMMENT_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: POST_COMMENT_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "failed to post comment due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: POST_COMMENT_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const editComment = (commentUuid, commentText) => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_COMMENT,
      commentUuid
    });
    try {
      const response = await editCommentCall(commentUuid, commentText);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: EDIT_COMMENT_COMPLETE,
          commentUuid
        });
      } else {
        return dispatch({
          type: EDIT_COMMENT_COMPLETE,
          commentUuid,
          error: true,
          message: "failed to edit comment due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: EDIT_COMMENT_COMPLETE,
        commentUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const reportComment = (caseUuid, commentUuid, reason, moreInfo) => {
  return async (dispatch) => {
    dispatch({
      type: REPORT_COMMENT,
      caseUuid: caseUuid
    });
    try {
      const response = await reportCommentCall(commentUuid, reason, moreInfo);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: REPORT_COMMENT_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: REPORT_COMMENT_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "failed to report comment due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: REPORT_COMMENT_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const deleteComment = (caseUuid, commentUuid) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_COMMENT,
      caseUuid: caseUuid,
      commentUuid: commentUuid
    });
    try {
      const response = await deleteCommentCall(commentUuid);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: DELETE_COMMENT_COMPLETE,
          caseUuid: caseUuid,
          commentUuid: commentUuid
        });
      } else {
        return dispatch({
          type: DELETE_COMMENT_COMPLETE,
          caseUuid: caseUuid,
          commentUuid: commentUuid,
          error: true,
          message: "failed to delete comment due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: DELETE_COMMENT_COMPLETE,
        caseUuid: caseUuid,
        commentUuid: commentUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const requestCommentSync = (caseUuid) => {
  return async (dispatch) => {
    dispatch({
      type: SYNC_COMMENTS,
      caseUuid: caseUuid
    });
    try {
      const response = await triggerUpdateComments(caseUuid);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: SYNC_COMMENTS_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: SYNC_COMMENTS_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "failed to sync comment due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: SYNC_COMMENTS_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const toggleCommentsListener = (caseUuid, contentUuid, on) => {
  return async (dispatch, getState) => {
    let listener = _.get(
      getState().comment,
      ["caseComments", caseUuid, "listener"],
      null
    );

    if (on) {
      if (!listener) {
        listener = await commentDB.listenForComments(
          caseUuid,
          contentUuid,
          (snapshot) => {
            if (snapshot && snapshot.size > 0) {
              let comments = [];
              for (let comment of snapshot.docs) {
                comments.push(comment.data());
              }
              dispatch(
                updateComments({
                  caseUuid,
                  originalTree: comments,
                  expandedStates: getState().comment.expandedStates
                })
              );
            }
          }
        );

        dispatch({
          type: COMMENTS_LISTENER_UPDATE,
          caseUuid: caseUuid,
          listener: listener
        });
      }
    } else {
      if (listener) {
        listener();
      }
      dispatch({
        type: COMMENTS_LISTENER_UPDATE,
        caseUuid: caseUuid,
        listener: null
      });
    }
  };
};

export const expandRootComment = (caseUuid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMENTS_EXPAND_ROOT,
      caseUuid: caseUuid
    });
    dispatch(findAndRefreshComments(caseUuid, getState, dispatch));
  };
};

export const collapseRootComment = (caseUuid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMENTS_COLLAPSE_ROOT,
      caseUuid: caseUuid
    });
    dispatch(findAndRefreshComments(caseUuid, getState, dispatch));
  };
};

export const collapseCommentThread = (caseUuid, commentUuid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMENTS_COLLAPSE_THREAD,
      commentUuid: commentUuid
    });
    dispatch(findAndRefreshComments(caseUuid, getState, dispatch));
  };
};

export const expandCommentThread = (caseUuid, commentUuid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMENTS_EXPAND_THREAD,
      commentUuid: commentUuid
    });
    dispatch(findAndRefreshComments(caseUuid, getState, dispatch));
  };
};

const findAndRefreshComments = (caseUuid, getState) => {
  return async (dispatch) => {
    dispatch(
      updateComments({
        caseUuid,
        originalTree: _.get(
          getState().comment,
          ["caseComments", caseUuid, "originalTree"],
          {}
        ),
        expandedStates: getState().comment.expandedStates
      })
    );
  };
};

const updateComments = ({ caseUuid, originalTree, expandedStates, sort }) => {
  return async (dispatch, getState) => {
    if (!originalTree) {
      originalTree = _.get(
        getState().comment,
        ["caseComments", caseUuid, "originalTree"],
        {}
      );
    }

    if (!expandedStates) {
      expandedStates = getState().comment.expandedStates;
    }

    if (!sort) {
      sort = getState().comment.sort;
      dispatch(buildComments(caseUuid, originalTree, expandedStates, sort));
    } else {
      // if there is a sorting update, rebuild all stored comments
      for (const [id, commentStatus] of Object.entries(
        getState().comment.caseComments
      )) {
        dispatch(
          buildComments(
            id,
            _.get(commentStatus, ["originalTree"], {}),
            expandedStates,
            sort
          )
        );
      }
    }
  };
};

const buildComments = (caseUuid, originalTree, expandedStates, sort) => {
  return async (dispatch, getState) => {
    const cache = getState().comment.cache[caseUuid];

    const flattenComments = flattenCommentTree(
      originalTree,
      cache,
      [],
      0,
      sort
    );

    const collapsedComments = collapseCommentTree(
      flattenComments,
      expandedStates,
      caseUuid
    );

    const physicianComments = flattenCommentTree(
      originalTree,
      cache,
      [],
      0,
      sort,
      true
    );

    const collapsedPhysicianComments = collapseCommentTree(
      physicianComments,
      expandedStates,
      caseUuid
    );

    const physicianCount = physicianComments.filter(
      (c) =>
        !c.isDeleted &&
        !c.isReported &&
        c.commentState !== COMMENT_STATES.PENDING
    ).length;
    const allCount = flattenComments.filter(
      (c) =>
        !c.isDeleted &&
        !c.isReported &&
        c.commentState !== COMMENT_STATES.PENDING
    ).length;

    dispatch({
      type: COMMENTS_UPDATED,
      caseUuid: caseUuid,
      comments: collapsedComments,
      physicianComments: collapsedPhysicianComments,
      physicianCount: physicianCount,
      allCount: allCount,
      originalTree
    });
  };
};

export const selectCommentSorting = (sort) => {
  return async (dispatch, getState) => {
    const oldSort = getState().comment.sort;
    if (oldSort === sort) {
      return;
    }

    dispatch({
      type: SELECT_COMMENT_SORT,
      sort: sort
    });
    dispatch(updateComments({ sort: sort }));
  };
};

export const selectCommentFilter = (filter) => {
  return async (dispatch, getState) => {
    const oldSort = getState().comment.filter;
    if (oldSort === filter) {
      return;
    }

    dispatch({
      type: SELECT_COMMENT_FILTER,
      filter: filter
    });
  };
};

export const resetComments = (caseUuid) => {
  return {
    type: COMMENTS_RESET,
    caseUuid: caseUuid
  };
};

export const viewTranslatedComment = (comment) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSLATE_COMMENT,
      commentUuid: comment.commentUuid
    });

    const currentLanguageCode = selectCurrentLanguageCode(getState());

    if (hasTranslation(comment.translations, currentLanguageCode)) {
      return dispatch(viewTranslatedCommentSuccess(comment.commentUuid));
    }

    try {
      const response = await translateCommentCall(
        comment.commentUuid,
        currentLanguageCode
      );

      if (isRequestSuccess(response)) {
        return dispatch(viewTranslatedCommentSuccess(comment.commentUuid));
      } else {
        return dispatch(
          viewTranslatedCommentError(
            comment.commentUuid,
            "failed to sync translation due to server error"
          )
        );
      }
    } catch (error) {
      return dispatch(
        viewTranslatedCommentError(comment.commentUuid, error.message)
      );
    }
  };
};

const viewTranslatedCommentSuccess = (commentUuid) => {
  return {
    type: TRANSLATE_COMMENT_COMPLETE,
    commentUuid,
    error: false,
    message: undefined
  };
};

const viewTranslatedCommentError = (commentUuid, errorMessage) => {
  return {
    type: TRANSLATE_COMMENT_COMPLETE,
    commentUuid,
    error: true,
    message: errorMessage
  };
};

export const viewOriginalComment = (commentUuid) => {
  return {
    type: VIEW_ORIGINAL_COMMENT,
    commentUuid: commentUuid
  };
};
