/** @format */

import i18n from "../utils/i18n";

export const COMMENT_LIMIT = {
  ROOT_INITIAL_THRESHOLD: 3,
  ROOT_BATCH: Number.POSITIVE_INFINITY,
  CHILD_INITIAL_THRESHOLD: 0,
  CHILD_BATCH: Number.POSITIVE_INFINITY
};

export const COMMENT_FILTER = {
  PHYSICIAN: { name: i18n.t("common.physician"), id: 0 },
  ALL: { name: i18n.t("common.all"), id: 1 }
};

export const COMMENT_ROOT_LEVEL = 0;

export const COMMENT_REPORT_REASON = {
  DISRESPECTFUL: "DISRESPECTFUL",
  PRIVACY: "PRIVACY",
  LACK_EVIDENCE: "LACK_EVIDENCE",
  OTHER: "OTHER"
};

export const COMMENT_SORT = {
  NEWEST: { label: i18n.t("common.newest"), order: 0 },
  OLDEST: { label: i18n.t("common.oldest"), order: 1 }
};
