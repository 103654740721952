import Firebase from "../firebase";

const functions = Firebase.functions;

export const sendInviteColleagues = async ({ emails }) => {
  const result = await functions.httpsCallable(
    "backendInviteColleaguesInviteUsers"
  )({
    emails: emails
  });
  return result?.data;
};
