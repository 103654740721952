import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { useSelector } from "react-redux";
import LoadingButton from "../../../components/common/LoadingButton";

const ProfileEditModal = ({
  toggle,
  isOpen,
  title,
  children,
  onSubmit,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const isProcessing = useSelector((state) => state.userProfiles.isProcessing);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Modal
      className="modal-profile modal-profile-edit"
      centered
      isOpen={isOpen}
      toggle={toggle}
      {...rest}>
      <ModalHeader
        toggle={toggle}
        close={
          <Button close onClick={toggle}>
            <CustomIcon icon="modal_exit" size={14} color={"black"} />
          </Button>
        }>
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className="d-inline-flex justify-content-between align-items-center w-100">
        <Button outline color="primary" onClick={toggle}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          spinnerColor="light"
          loading={isProcessing}
          disabled={isProcessing}
          onClick={onSubmit}>
          Save
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

ProfileEditModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

ProfileEditModal.defaultProps = {};

export default ProfileEditModal;
