/** @format */
import React from "react";
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import VoteOptionItem, { QUESTION_TYPE } from "./VoteOptionItem";

const VoteOptionsList = (props) => {
  /** ********************************** CONFIG ***************************************/

  const totalVotes = props.options.reduce((total, currentOption) => {
    return (total += currentOption.votes);
  }, 0);

  const hasAtLeastOneAnswer = props.options.reduce((total, currentOption) => {
    return total || currentOption.isAnswer;
  }, false);

  const type = props.isCME
    ? QUESTION_TYPE.CME_QUIZ
    : hasAtLeastOneAnswer
    ? QUESTION_TYPE.QUIZ
    : QUESTION_TYPE.POLL;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onVote = (questionOptionUuid, option) => {
    if (!props.userAnswer || props.allowReselect) {
      props.onVote(questionOptionUuid, option);
    }

    // Allow feed question card to go to detail view upon tapping answered question option
    props.onAfterVote();
  };

  /** ********************************** RENDER ***************************************/

  if (props.options.length === 0) {
    return null;
  }

  return (
    <ListGroup className="list-group-spacing-y-2_5">
      {props.options
        .sort((o1, o2) => o1.displayOrder - o2.displayOrder)
        .map((option) => (
          <VoteOptionItem
            key={option.questionOptionUuid}
            userVote={props.userAnswer}
            option={option}
            onVote={(optionUuid) => onVote(optionUuid, option)}
            type={type}
            totalVotes={totalVotes}
          />
        ))}
    </ListGroup>
  );
};

VoteOptionsList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayOrder: PropTypes.number,
      isAnswer: PropTypes.bool,
      questionOptionUuid: PropTypes.string,
      text: PropTypes.string,
      votes: PropTypes.number
    })
  ).isRequired,
  userAnswer: PropTypes.string,
  onVote: PropTypes.func.isRequired,
  isCME: PropTypes.bool,
  allowReselect: PropTypes.bool,
  onAfterVote: PropTypes.func
};

VoteOptionsList.defaultProps = {
  onAfterVote: () => {}
};

export default VoteOptionsList;
