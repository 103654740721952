const actionsPrefix = "localization";

export const LOCALIZATION_UPDATED = `${actionsPrefix}/LOCALIZATION_UPDATED`;

export const updateLocalization = ({ currentLanguage }) => {
  return {
    type: LOCALIZATION_UPDATED,
    payload: { currentLanguage }
  };
};
