import Firebase from "../firebase";
import firebase from "firebase/app";

const firestore = Firebase.firestore;

export const auth = () => Firebase.auth;

export const getCollectionRef = (collectionName) => {
  return firestore.collection(collectionName);
};

export const getDocRef = (docName) => {
  return firestore.doc(docName);
};

export const firestoreBatch = () => {
  return firestore.batch();
};
/**
 * @template T
 * @param {(transaction: firebase.firestore.Transaction) => Promise<T>} updateFunction
 * @returns {Promise<T>}
 */
export const runTransaction = (updateFunction) => {
  return firestore.runTransaction(updateFunction);
};

export const user = (uid) => firestore.collection("usersDB").doc(uid);
export const users = () => firestore.collection("usersDB");

export const getNewTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();

export const getDocumentIdFieldPath = () =>
  firebase.firestore.FieldPath.documentId();

export const caseInfo = (uid) => firestore.collection("casesDB").doc(uid);
export const caseInfos = () => firestore.collection("casesDB");

export const caseTerm = (uid) => firestore.collection("meshTermsDB").doc(uid);
export const caseTerms = () => firestore.collection("meshTermsDB");

export const publication = (uid) =>
  firestore.collection("publicationsDB").doc(uid);
export const publications = () => firestore.collection("publicationsDB");

export const cases = () =>
  firestore
    .collection("casesDB")
    .limit(100)
    .get();

export const reviewedTerm = (uid) =>
  firestore.collection("reviewedTermsDB").doc(uid);
export const reviewedTerms = () => firestore.collection("reviewedTermsDB");
