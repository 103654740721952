/** @format */

import PropTypes from "prop-types";
import { DRAFT_STATES } from "../constants/case-constants";
import { MediaProp } from "./MediaProp";

export const DraftProp = PropTypes.shape({
  uid: PropTypes.string,
  draftUid: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
  media: PropTypes.arrayOf(MediaProp),
  specialtiesUuid: PropTypes.arrayOf(PropTypes.string),
  subSpecialtiesUuid: PropTypes.arrayOf(PropTypes.string),
  paging: PropTypes.bool,
  created: PropTypes.number,
  updated: PropTypes.number,
  state: PropTypes.oneOf(Object.values(DRAFT_STATES))
});
