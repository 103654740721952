import React from "react";
import Button from "reactstrap/lib/Button";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import "./BaseEmptyFeed.scss";

const BaseEmptyFeed = ({ title, action, actionText, children }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="base-empty-feed d-flex flex-column justify-content-center align-items-center text-align-center mt-3">
      <CustomIcon
        className="mb-2"
        icon="network_error"
        color="#5d6c80"
        size={60}
      />
      <h1 className="text-16 helv-bold">{title}</h1>
      <div className="child-container">{children}</div>
      {actionText && action && (
        <Button color="primary" onClick={action} className="cta-btn p-2">
          {actionText}
        </Button>
      )}
    </div>
  );
};

export default BaseEmptyFeed;
