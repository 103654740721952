import React, { useEffect, useState } from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import { saveCase } from "../../actions/case.actions";
import { trackSaveCase } from "../../actions/metrics.actions";
import { CASE_BANNER_TYPE } from "../../constants/case-banner-constants";
import classNames from "../../utils/class-names-utils";

const SaveCaseButton = ({
  caseContent,
  caseNotification,
  commentStatus,
  className
}) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(caseContent?.isSaved ?? false);
  const savedCases = useSelector((state) => state.savedCases);
  const caseUuid = caseContent?.caseUuid;

  const icon = isSaved ? "web_save_filled" : "web_save_outlined";
  const iconClass = isSaved
    ? "icon-denim-blue"
    : theme === THEMES.DARK
    ? "icon-white"
    : "icon-battleship-gray";

  useEffect(() => {
    if (savedCases?.data?.all) {
      const saved = savedCases.data.all.some((c) => c.caseUuid === caseUuid);
      setIsSaved(saved);
    }
  }, [savedCases, caseUuid]);

  const toggleSaveTap = async () => {
    if (!isSaved) {
      trackSaveCase({ caseContent, commentStatus });
    }
    setIsSaved(!isSaved);

    const result = await dispatch(saveCase(caseUuid, !isSaved));

    if (!result.error) {
      // F1P-3939: Commented out for now until speed issues are resolved -- Corey
      // if (!isSaved) {
      //   caseNotification(CASE_BANNER_TYPE.SAVE_CASE);
      // } else {
      //   caseNotification(CASE_BANNER_TYPE.UNSAVE_CASE);
      // }
    } else {
      if (!isSaved) {
        caseNotification(CASE_BANNER_TYPE.SAVE_CASE_ERROR);
      } else {
        caseNotification(CASE_BANNER_TYPE.UNSAVE_CASE_ERROR);
      }
    }
  };

  return (
    <div
      className={classNames("cursor-pointer", className)}
      onClick={toggleSaveTap}>
      <CustomIcon className={iconClass} icon={icon} size={24} />
    </div>
  );
};

export default SaveCaseButton;
