import React from "react";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "../../utils/class-names-utils";

const F1Badge = (props) => {
  const { color, textColor, name, className, ...rest } = props;

  return (
    <Badge
      {...rest}
      pill
      className={classnames(
        "f1-badge",
        textColor ? `text-${textColor}` : undefined,
        "px-2"
      )}
      color={color}>
      {name}
    </Badge>
  );
};

F1Badge.propTypes = {
  /**
   * The content within the badge
   */
  name: PropTypes.string,
  /**
   * Optional set a new text color. If not set, the text color will based off of the provided color prop.
   */
  textColor: PropTypes.string,
};

export default F1Badge;
