import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { get, size, difference, isEmpty } from "lodash";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import i18n from "../../../utils/i18n";
import {
  clearSpecialtyUpdate,
  updateUserProfileBasicInfo
} from "../../../actions/user-profiles.actions";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import { trackEditProfile } from "../../../actions/metrics.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import ProfileBioEditor from "./ProfileBioEditor";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";
import { fetchProfessionSpecialtiesIfNeeded } from "../../../actions/reference.actions";

import Select from "react-select";
import { includesInput } from "../../../utils/search-utils";

const MAX_BIO_CHAR = 140;
const WARNING_BIO_CHAR = 50;

const ProfileEditInfoModal = ({ toggle, isOpen, userUuid, close, ...rest }) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const [remainBioChar, setRemainBioChar] = useState(MAX_BIO_CHAR);
  const profileData = useSelector(
    (state) => state.userProfiles.profiles[userUuid]
  );
  const specialtiesRef = useRef([]);
  const processing = useSelector((state) => state.userProfiles.isProcessing);

  const specialties = useSelector((state) => {
    if (
      state.references.professionSpecialties[profileData?.professionUuid] &&
      !specialtiesRef.current.length
    ) {
      specialtiesRef.current = state.references.professionSpecialties[
        profileData?.professionUuid
      ]
        .filter((specialty) => !!specialty.specialty)
        .sort((a, b) => {
          if (a.onboardingDisplayName < b.onboardingDisplayName) return -1;
          if (a.onboardingDisplayName > b.onboardingDisplayName) return 1;
          return 0;
        });
    }

    return specialtiesRef.current;
  });

  /************************************ HOOKS ****************************************/
  const { setValue, handleSubmit, errors, control } = useForm();

  useEffect(() => {
    if (profileData) {
      setRemainBioChar(MAX_BIO_CHAR - size(get(profileData, "userBio", "")));
    }
    dispatch(fetchProfessionSpecialtiesIfNeeded(profileData?.professionUuid));

    return () => {
      dispatch(clearSpecialtyUpdate());
    };
  }, [dispatch, profileData]);

  const cancel = () => {
    toggle();
  };

  const save = async (values) => {
    const secondarySpecialties = (values.secondarySpecialties || []).map(
      (specialty) => specialty.treeUuid
    );

    try {
      sendEditMetrics(values, secondarySpecialties);
      const result = await dispatch(
        updateUserProfileBasicInfo({
          displayName: values.name,
          specialty: values.primarySpecialty?.treeUuid,
          userCustomSpecialty: false,
          secondarySpecialties,
          location: values.location,
          hospital: values.hospital,
          bio: values.bio
        })
      );

      if (result.error) {
        showToast({
          message: t("Profile.updateError"),
          toastType: TOAST_TYPE.ERROR
        });
      } else {
        toggle();
      }
    } catch (error) {
      showToast({
        message: t("Profile.updateError"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  const sendEditMetrics = (basics, specialties) => {
    const editedFields = [];
    if (profileData.displayName !== basics.name) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_NAME);
    }

    if ((profileData.practiceLocation || "") !== basics.location) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_LOCATION);
    }

    if ((profileData.practiceHospital || "") !== basics.hospital) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_HOSPITAL);
    }

    if ((profileData.userBio || "") !== basics.bio) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_BIO);
    }

    const originalSpecialties = profileData.secondarySpecialties.map(
      (s) => s.treeUuid
    );
    const diff = difference(originalSpecialties, specialties);
    if (!isEmpty(diff)) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_SUBSPECIALTIES);
    }

    const originalSpecialty = profileData.primarySpecialty?.treeUuid;
    if (originalSpecialty !== basics.primarySpecialty?.treeUuid) {
      editedFields.push(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_SPECIALTIES);
    }

    trackEditProfile(editedFields);
  };

  const bioCharLabel = (remainChar) => {
    let result = `${Math.abs(remainChar)} `;

    if (remainChar === MAX_BIO_CHAR) {
      result = i18n.t("Profile.bioLimit", { count: remainChar });
    } else if (remainChar >= 0) {
      result += i18n.t("Profile.bioCharLeft", { count: remainChar });
    } else {
      result += i18n.t("Profile.bioCharOver", { count: remainChar });
    }

    return result;
  };

  const primarySpecialtyClassName = errors.primarySpecialty
    ? "react-select is-invalid"
    : "react-select";

  /************************************ RENDER ***************************************/
  if (!profileData) {
    return null;
  }

  let specialitiesFields = null;
  if (specialties && specialties?.length && profileData) {
    specialitiesFields = (
      <>
        <FormGroup>
          <Label for="primarySpecialty">
            {t("Profile.primarySpecialtyLabel")}*
          </Label>
          <Controller
            control={control}
            id="primarySpecialty"
            name="primarySpecialty"
            defaultValue={profileData.primarySpecialty}
            rules={{
              required: i18n.t("Profile.primarySpecialtyPlaceholder")
            }}
            invalid={!!errors.primarySpecialty}
            as={
              <Select
                options={specialties}
                placeholder={i18n.t("Profile.primarySpecialtyError")}
                getOptionLabel={(option) => option.onboardingDisplayName}
                getOptionValue={(option) => option.treeUuid}
                className={primarySpecialtyClassName}
                filterOption={includesInput}
              />
            }
          />
          <FormFeedback>{t("Profile.primarySpecialtyError")}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="secondarySpecialties">
            {t("Profile.secondarySpecialtiesLabel")}
          </Label>
          <Controller
            control={control}
            id="secondarySpecialties"
            name="secondarySpecialties"
            defaultValue={profileData.secondarySpecialties}
            as={
              <Select
                isMulti
                options={specialties}
                placeholder={i18n.t("Profile.secondarySpecialtiesPlaceholder")}
                getOptionLabel={(option) => option.onboardingDisplayName}
                getOptionValue={(option) => option.treeUuid}
                filterOption={includesInput}
              />
            }
          />
          <FormFeedback>{t("Profile.secondarySpecialtiesError")}</FormFeedback>
        </FormGroup>
      </>
    );
  }

  return (
    <Modal
      className="modal-profile modal-profile-edit-info"
      centered
      isOpen={isOpen}
      toggle={toggle}
      {...rest}>
      <ModalHeader
        toggle={toggle}
        close={
          <Button close onClick={toggle}>
            <CustomIcon icon="modal_exit" size={14} color={"black"} />
          </Button>
        }>
        {t("Profile.manageProfileTitle")}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="name">{t("Profile.nameLabel")}*</Label>
          <Controller
            as={Input}
            control={control}
            id="name"
            name="name"
            type="text"
            placeholder={t("Profile.namePlaceholder")}
            rules={{ required: true }}
            defaultValue={profileData.displayName || ""}
            invalid={!!errors.name}
          />
          <FormFeedback>{t("Profile.nameError")}</FormFeedback>
        </FormGroup>
        {specialitiesFields}
        <FormGroup>
          <Label for="location">{t("Profile.practiceLabel")}</Label>
          <Controller
            as={Input}
            control={control}
            id="location"
            name="location"
            type="text"
            placeholder={t("Profile.practiceLabel")}
            defaultValue={profileData.practiceLocation || ""}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hospital">{t("Profile.hospitalLabel")}</Label>
          <Controller
            as={Input}
            control={control}
            id="hospital"
            name="hospital"
            type="text"
            placeholder={t("Profile.hospitalPlaceholder")}
            defaultValue={profileData.practiceHospital || ""}
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label for="bio">
            {t("Profile.bioLabel")} (
            <span
              className={
                remainBioChar < WARNING_BIO_CHAR ? "text-danger" : null
              }>
              {bioCharLabel(remainBioChar)}
            </span>
            )
          </Label>
          <Controller
            as={ProfileBioEditor}
            control={control}
            id="bio"
            name="bio"
            placeholder={t("Profile.practiceLabel")}
            type="textarea"
            defaultValue={profileData.userBio || ""}
            onChangeText={(text) => {
              setValue("bio", text, true);
              setRemainBioChar(MAX_BIO_CHAR - text.length);
            }}
            minRows={3}
            rules={{ maxLength: MAX_BIO_CHAR }}
            invalid={!!errors.bio}
          />
          {errors.bio && <FormFeedback>{t("Profile.bioError")}</FormFeedback>}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={cancel}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          spinnerColor="light"
          disabled={processing}
          loading={processing}
          onClick={handleSubmit(save)}>
          Save
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

ProfileEditInfoModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired
};

ProfileEditInfoModal.defaultProps = {};

export default ProfileEditInfoModal;
