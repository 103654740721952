import React from "react";
import { CardBody } from "reactstrap";
import * as PropTypes from "prop-types";
import CaseDropdown from "./CaseDropdown";
import CaseUserCell from "./CaseUserCell";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";

const CaseHeader = ({ caseContent, caseNotification }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <>
      {caseContent && (
        <CardBody>
          <div className="d-inline-flex justify-content-between align-items-center w-100">
            <CaseUserCell
              caseContent={caseContent}
              displayFollowUser
              truncateUsername
            />
            <CaseDropdown
              caseContent={caseContent}
              caseNotification={caseNotification}
              from={CASE_CARD_ORIGIN.FEED}
            />
          </div>
        </CardBody>
      )}
    </>
  );
};

CaseHeader.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  subName: PropTypes.string,
  avatar: PropTypes.string
};

CaseHeader.defaultProps = {};

export default CaseHeader;
