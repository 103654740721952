/** @format */

import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
import { logger } from "./middleware";

const enhancerList = [];
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === "function") {
  enhancerList.push(devToolsExtension());
}

const middlewares = [thunk, logger];

const composedEnhancer = compose(
  applyMiddleware(...middlewares),
  ...enhancerList
);

const store = createStore(rootReducer, {}, composedEnhancer);

export default store;
