import { useCallback, useState } from "react";

function useCarouselNavigation({ items = [], currentIndex = 0 }) {
  // Deal with over/undershooting
  const [activeIndex, setActiveIndex] = useState(
    items.length <= currentIndex
      ? items.length - 1
      : currentIndex < 0
      ? 0
      : currentIndex
  );
  const [animating, setAnimating] = useState(false);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, animating, items.length]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, animating, items.length]);

  const goToIndex = useCallback(
    (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating]
  );

  const onExiting = useCallback(() => {
    setAnimating(true);
  }, []);

  const onExited = useCallback(() => {
    setAnimating(false);
  }, []);

  return { activeIndex, next, previous, goToIndex, onExiting, onExited };
}

export default useCarouselNavigation;
