import i18n from "../utils/i18n";

export const CASE_BANNER_TYPE = {
  SAVE_CASE: {
    name: "save-case",
    icon: "sheet_save",
    text: i18n.t("caseBanner.saveCase"),
    color: "bg-denim-blue"
  },
  UNSAVE_CASE: {
    name: "unsave-case",
    icon: "sheet_save",
    text: i18n.t("caseBanner.unsaveCase"),
    color: "bg-denim-blue"
  },
  SAVE_CASE_ERROR: {
    name: "save-case-error",
    icon: "sheet_save",
    text: i18n.t("caseBanner.saveCaseError"),
    color: "bg-danger"
  },
  UNSAVE_CASE_ERROR: {
    name: "unsave-case-error",
    icon: "sheet_save",
    text: i18n.t("caseBanner.unsaveCaseError"),
    color: "bg-danger"
  },
  LINK_COPIED: {
    name: "link-copied",
    icon: "sheet_copy_link",
    text: i18n.t("caseBanner.linkCopied"),
    color: "bg-denim-blue"
  }
};

export const SHOW_BANNER_TIME = 3000;
