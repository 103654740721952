/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";
import { isEmpty } from "lodash";
import {
  fetchActivityRecord,
  setAllActivityRead
} from "../../actions/activity-center.actions";
import { ACTIVITY_TYPES } from "../../constants/activity-constants";
import {
  CASE_DETAIL,
  CASE_POSTING_EDIT,
  PROFILE_DETAIL
} from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import NotificationEmpty from "./components/NotificationsEmpty";
import NotificationsFound from "./components/NotificationsFound";
import NotificationsLoading from "./components/NotificationsLoading";
import {
  trackActivityCenterEntry,
  trackShareCase
} from "../../actions/metrics.actions";
import DeletedPostDialog from "./components/DeletedPostDialog";
import moment from "moment";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";

/**
 * Go to view more about activity based on its type:
 * - comment, reply, posted, updated, approve -> go to case
 * - follow -> go to follower's profile
 *
 * Note: Ignoring rejected for now since it needs more fleshing out.
 */
const caseActivityTypes = [
  ACTIVITY_TYPES.COMMENT,
  ACTIVITY_TYPES.REPLY,
  ACTIVITY_TYPES.POSTED,
  ACTIVITY_TYPES.UPDATED,
  ACTIVITY_TYPES.REACT,
  ACTIVITY_TYPES.APPROVE
];
const goToFeature = (history, activity) => {
  const { type, caseUuid, sourceUuid, draftUid } = activity;
  if (caseActivityTypes.includes(type)) {
    history.push(getUrl(CASE_DETAIL, { caseId: caseUuid }));
  } else if (type === ACTIVITY_TYPES.FOLLOW) {
    history.push(getUrl(PROFILE_DETAIL, { userUuid: sourceUuid }));
  } else if (type === ACTIVITY_TYPES.REJECT) {
    trackShareCase({ context: UPLOAD_CASE_ORIGIN.NOTIFICATION_SCREEN });
    history.push(
      getUrl(CASE_POSTING_EDIT, {
        draftUid
      })
    );
  }
};

/**
 * Supports getting more activity data in these scenarios:
 *
 * 1) On load, fetch the first set of newest activity records from now.
 * 2) On scroll down, fetch the next set of older activity records
 * based off the timestamp of the current last activity record in the redux.
 * 3) On scroll up, refresh and get the newest activity records from now.
 *
 * Activity Images:
 * - Follow: show follower's profile
 * - Case related: show case image or an icon if it's a text only case
 */
const LIMIT = 12;

const NotificationsDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.activity.isActivityLoading);
  const isEndReached = useSelector((state) => state.activity.endReached);
  const activities = useSelector((state) => state.activity.allActivity);
  const [isDeleteDialogOpen, setIsDeletedDialogOpen] = useState(false);
  const [displayedDeletedItem, setDisplayedDeletedItem] = useState({
    date: null,
    reason: null,
    type: null
  });

  const fetchMorePastActivity = useCallback(() => {
    const lastActivityTimestamp = activities[activities.length - 1].createdAt;
    if (!isLoading) {
      dispatch(
        fetchActivityRecord({
          limit: LIMIT,
          startAfterTimestamp: lastActivityTimestamp
        })
      );
    }
  }, [dispatch, activities, isLoading]);

  const fetchNewestActivity = useCallback(
    (callback) => {
      dispatch(
        fetchActivityRecord({
          limit: LIMIT,
          shouldClearOldActivity: true,
          callback
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setAllActivityRead());
    fetchNewestActivity();
  }, [fetchNewestActivity, dispatch]);

  useEffect(() => {
    trackActivityCenterEntry();
  }, []);

  const onClickActivity = (history, activity) => {
    const { type, rejectionReasonMessage } = activity;
    if (
      type === ACTIVITY_TYPES.CASE_DELETE ||
      type === ACTIVITY_TYPES.COMMENT_DELETE
    ) {
      const d = moment(activity.case?.publishedAt).toDate();

      const formattedDate = Intl.DateTimeFormat("en-US", {
        year: "numeric",
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
      }).format(d);

      setDisplayedDeletedItem({
        date: formattedDate,
        reason: rejectionReasonMessage,
        type
      });
      setIsDeletedDialogOpen(true);
    } else {
      goToFeature(history, activity);
    }
  };

  return (
    <>
      <DeletedPostDialog
        {...displayedDeletedItem}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeletedDialogOpen(false)}
      />
      <Figure1Page1Col
        isChildPage
        mainContent={
          <Card className="border-0 rounded-0">
            <CardBody>
              <CardTitle
                className="text-15 text-default-black pb-4 pt-2 mb-0"
                tag="h5">
                {i18n.t("ActivityScreen.activity")}
              </CardTitle>
              <Card className="border-0">
                {isEmpty(activities) ? (
                  isLoading ? (
                    <NotificationsLoading />
                  ) : (
                    <NotificationEmpty />
                  )
                ) : (
                  <NotificationsFound
                    activities={activities}
                    isLoading={isLoading}
                    isEndReached={isEndReached}
                    onClickActivity={(activity) =>
                      onClickActivity(history, activity)
                    }
                    onFetchMorePastActivity={fetchMorePastActivity}
                  />
                )}
              </Card>
            </CardBody>
          </Card>
        }
      />
    </>
  );
};

export default NotificationsDetailsPage;
