/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const FUNCTIONS = {
  DEVICE_USER_TOKEN_CHANGED: "backendDeviceUserTokenChanged",
  COMMUNICATION_PREFERENCES_RESET: "backendPreferencesResetUserPreferences",
  COMMUNICATION_PREFERENCES_REFRESH: "backendPreferencesRefreshUserPreferences",
  COMMUNICATION_PREFERENCES_UPDATE: "backendPreferencesUpdateUserPreferences",
  EMAIL_PREFERENCES_UPDATE: "backendPreferencesUpdateEmailPreference"
};

export const resetUserPreferences = async () => {
  const httpsCallable = functions.httpsCallable(
    FUNCTIONS.COMMUNICATION_PREFERENCES_RESET
  );
  // DO Not Pass the User, the function knows who you are by context
  const result = await httpsCallable({});
  return result.data;
};

export const refreshUserPreferences = async () => {
  const httpsCallable = functions.httpsCallable(
    FUNCTIONS.COMMUNICATION_PREFERENCES_REFRESH
  );

  // DO Not Pass the User, the function knows who you are by context
  const result = await httpsCallable({});
  return result.data;
};

export const updateUserPreference = async (userPreferenceActivity) => {
  const httpsCallable = functions.httpsCallable(
    FUNCTIONS.COMMUNICATION_PREFERENCES_UPDATE
  );

  // DO Not Pass the User, the function knows who you are by context
  const result = await httpsCallable(userPreferenceActivity);
  return result.data;
};

export const updateEmailPreferenceCall = async (pref, on) => {
  const httpsCallable = functions.httpsCallable(
    FUNCTIONS.EMAIL_PREFERENCES_UPDATE
  );

  const result = await httpsCallable({ emailUuid: pref.emailUuid, on: on });
  return result.data;
};
