/** @format */

import {
  SET_SEARCH_CRITERIA,
  RESET_SEARCH,
  SEARCH_START,
  SEARCH_COMPLETE,
  SEARCH_ERROR,
  FETCH_SEARCH_SUGGESTIONS,
  FETCH_SEARCH_SUGGESTIONS_COMPLETE,
  FETCH_SEARCH_SUGGESTIONS_ERROR,
  CLEAR_SEARCH_SUGGESTIONS,
  UPDATE_SEARCH_TERM
} from "../actions/search.actions";
import { pick, get, isEmpty } from "lodash";
import { RESET_ALL } from "../actions/global.actions";
import { SEARCH_RESULT_TYPES } from "../constants/search-result-constants";

const initialState = {
  // everything outside `searchSuggestions` is for actual search results (profiles or cases)
  isLoading: false,
  searchResults: {},
  error: {},
  searchCursor: 0,
  searchTerm: "",
  searchIndex: [],
  canSearchMore: true,
  searchResultType: SEARCH_RESULT_TYPES.CASE, // Search case is the initial screen search lands on
  searchSuggestions: {
    searchSuggestions: null,
    error: null,
    isLoading: false
  }
};

const searchReducer = (state = initialState, action = {}) => {
  const {
    searchResultType,
    searchResults,
    error,
    isPaginating,
    searchCursor,
    isNewSearchTerm
  } = pick(action?.payload, [
    "searchResultType",
    "searchResults",
    "error",
    "isPaginating",
    "searchCursor",
    "isNewSearchTerm"
  ]);

  switch (action.type) {
    case SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
        searchIndex: action.payload.searchIndex,
        searchResultType: action.payload.searchResultType
      };
    // Should I just reuse the above? I will have to make sure I remember to update `searchIndex` though!
    case UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload.input
      };
    case SEARCH_START:
      return {
        ...state,
        isLoading: true
      };
    case SEARCH_COMPLETE:
      const newResults = isNewSearchTerm
        ? searchResults
        : [
            ...get(state, `searchResults.${searchResultType}`, []),
            ...searchResults
          ];

      return {
        ...state,
        isLoading: false,
        searchResults: {
          ...state.searchResults,
          [searchResultType]: newResults
        },
        ...(isPaginating && { searchCursor }),
        canSearchMore: isPaginating ? newResults.length >= searchCursor : false
      };
    case SEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          ...state.error,
          [searchResultType]: error
        }
      };
    case RESET_SEARCH:
      return {
        ...state,
        searchResults: null,
        searchCursor: 0,
        searchTerm: "",
        searchIndex: [],
        canSearchMore: true,
        searchResultType: SEARCH_RESULT_TYPES.CASE
      };
    case FETCH_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          isLoading: true
        }
      };
    case FETCH_SEARCH_SUGGESTIONS_COMPLETE:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          isLoading: false,
          searchSuggestions: formatSearchResults(action.payload.data)
        }
      };
    case FETCH_SEARCH_SUGGESTIONS_ERROR:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          isLoading: false,
          error: action.payload.error
        }
      };
    case CLEAR_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          searchSuggestions: null
        },
        searchTerm: ""
      };
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default searchReducer;

// Checkout Kibana's dev tools to play around and view results!
const formatSearchResults = (searchResults) => {
  const suggestions = searchResults?.suggest?.suggestion?.[0]?.options;

  if (!isEmpty(suggestions)) {
    return suggestions.map((suggestion) => ({
      text: suggestion.text
    }));
  }

  return [];
};
