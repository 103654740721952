/** @format */
import {
  listenCmeMeta,
  listenAvailableActivities,
  listenCompletedActivities
} from "../db/user-cme.db";
import {
  syncCMEHubCall,
  submitCertificateDegreeCall
} from "../api/cme.cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "userCme";

export const META_LISTENER_UPDATE = `${actionsPrefix}/META_LISTENER_UPDATE`;
export const AVAILABLE_LISTENER_UPDATE = `${actionsPrefix}/AVAILABLE_LISTENER_UPDATE`;
export const COMPLETED_LISTENER_UPDATE = `${actionsPrefix}/COMPLETED_LISTENER_UPDATE`;
export const META_UPDATE = `${actionsPrefix}/META_UPDATE`;
export const AVAILABLE_UPDATE = `${actionsPrefix}/AVAILABLE_UPDATE`;
export const COMPLETED_UPDATE = `${actionsPrefix}/COMPLETED_UPDATE`;
export const SYNC_CME = `${actionsPrefix}/SYNC_CME`;
export const SUBMIT_CERTIFICATE_DEGREE = `${actionsPrefix}/SUBMIT_CERTIFICATE_DEGREE`;
export const SUBMIT_CERTIFICATE_DEGREE_COMPLETE = `${actionsPrefix}/SUBMIT_CERTIFICATE_DEGREE_COMPLETE`;

export const toggleUserCmeMetaListener = (on) => {
  return async (dispatch, getState) => {
    const metaUnsub = getState().userCme.metaUnsub;
    if (on) {
      if (!metaUnsub) {
        try {
          const userUid = getState().user.userUid;
          const unsub = await listenCmeMeta(userUid, (doc) => {
            if (doc && doc.exists) {
              dispatch({
                type: META_UPDATE,
                meta: doc.data()
              });
            }
          });

          return dispatch({
            type: META_LISTENER_UPDATE,
            metaUnsub: unsub
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    } else {
      if (metaUnsub) {
        metaUnsub();
      }
      return dispatch({
        type: META_LISTENER_UPDATE,
        metaUnsub: null
      });
    }
  };
};

export const toggleUserCmeAvailableListener = (on) => {
  return async (dispatch, getState) => {
    const availableUnsub = getState().userCme.availableUnsub;
    if (on) {
      if (!availableUnsub) {
        try {
          const userUid = getState().user.userUid;
          const unsub = await listenAvailableActivities(userUid, (doc) => {
            if (doc && doc.size > 0) {
              let activities = [];
              for (let item of doc.docs) {
                activities.push(item.data());
              }
              dispatch({
                type: AVAILABLE_UPDATE,
                availableActivities: activities
              });
            }
          });

          return dispatch({
            type: AVAILABLE_LISTENER_UPDATE,
            availableUnsub: unsub
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    } else {
      if (availableUnsub) {
        availableUnsub();
      }
      return dispatch({
        type: AVAILABLE_LISTENER_UPDATE,
        availableUnsub: null
      });
    }
  };
};

export const toggleUserCmeCompletedListener = (on) => {
  return async (dispatch, getState) => {
    const completedUnsub = getState().userCme.completedUnsub;
    if (on) {
      if (!completedUnsub) {
        try {
          const userUid = getState().user.userUid;
          const unsub = await listenCompletedActivities(userUid, (doc) => {
            if (doc && doc.size > 0) {
              let activities = [];
              for (let item of doc.docs) {
                activities.push(item.data());
              }
              dispatch({
                type: COMPLETED_UPDATE,
                completedActivities: activities
              });
            }
          });

          return dispatch({
            type: COMPLETED_LISTENER_UPDATE,
            completedUnsub: unsub
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    } else {
      if (completedUnsub) {
        completedUnsub();
      }
      return dispatch({
        type: COMPLETED_LISTENER_UPDATE,
        completedUnsub: null
      });
    }
  };
};

export const syncCMEHub = () => {
  return async (dispatch) => {
    try {
      await syncCMEHubCall();
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const submitDegree = (caseUuid, degree) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SUBMIT_CERTIFICATE_DEGREE
      });
      const result = await submitCertificateDegreeCall(caseUuid, degree);
      if (isRequestSuccess(result)) {
        return dispatch({
          type: SUBMIT_CERTIFICATE_DEGREE_COMPLETE
        });
      } else {
        return dispatch({
          type: SUBMIT_CERTIFICATE_DEGREE_COMPLETE,
          error: true,
          message: "Failed to submit certificate degree"
        });
      }
    } catch (e) {
      console.log(e.message);
      return dispatch({
        type: SUBMIT_CERTIFICATE_DEGREE_COMPLETE,
        error: true,
        message: e.message
      });
    }
  };
};
