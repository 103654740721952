import React from "react";
import PropTypes from "prop-types";
import CustomIcon from "../custom-icon/CustomIcon";

const NotificationsIcon = (props) => {
  const { active, color, size = 24 } = props;
  return (
    <CustomIcon
      icon={active ? "badged_activity_bell" : "activity_bell"}
      color={color}
      size={size}
    />
  );
};

NotificationsIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number
};

export default NotificationsIcon;
