/** @format */

import {
  CLEAR_SELECTION,
  FETCH_SPECIALTIES_COMPLETE,
  FETCH_SPECIALTIES,
  SELECT_SUB_SPECIALTY,
} from "../actions/specialties.actions";

export const INITIAL_STATE = {
  professions: [],
  specialties: {},
  subSpecialties: {},
  flattenedSubSpecialties: [],
  selectedSubs: {},
  isProcessing: false,
  fetched: false,
  error: false,
  message: null
};

const SpecialtiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SPECIALTIES:
      return { ...state, isProcessing: true };
    case FETCH_SPECIALTIES_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        professions: action.professions || state.professions,
        specialties: action.specialties || state.specialties,
        subSpecialties: action.subSpecialties || state.subSpecialties,
        flattenedSubSpecialties:
          action.flattenedSubSpecialties || state.flattenedSubSpecialties,
        fetched: action.fetched,
        error: action.error,
        message: action.message
      };
    case SELECT_SUB_SPECIALTY:
      return {
        ...state,
        selectedSubs: action.selectedSubs
      };
    case CLEAR_SELECTION:
      return {
        ...state,
        selectedSubs: {}
      };
    default:
      return state;
  }
};

export default SpecialtiesReducer;
