import React from "react";
import { Navbar, Nav, NavItem, ButtonToggle } from "reactstrap";
import FeedSearch from "./FeedSearch";
import { useDispatch } from "react-redux";
import {
  setGatedMessageOrigin,
  setGatedMessageType
} from "../../actions/global.actions";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { useTranslation } from "react-i18next";
import classNames from "../../utils/class-names-utils";
import { isMobile } from "react-device-detect";

const hiddenMobile = "d-smed-none d-inline-flex";
const visibleMobile = "d-none d-smed-inline-flex";

const GatedHeader = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /** ********************************* FUNCTIONS *************************************/
  const onGatedClick = (type) => {
    dispatch(setGatedMessageType(GATED_MODAL_TYPE.GENERIC));
    dispatch(setGatedMessageOrigin(type));
  };

  /** ********************************** RENDER ***************************************/
  return (
    <header className="bg-white header--shadowed gated-header">
      <Navbar
        className="primary-nav justify-content-between"
        color="white"
        light
        expand>
        <div className="d-inline-flex justify-content-end align-items-center side-segment search-segment">
          <div
            onClick={() =>
              onGatedClick(UNGATED_ACTIONS.ORIGINAL_ACTIONS.TOPBAR_NAV_LOGO)
            }
            className="navbar-brand">
            Figure 1 PRO
          </div>
          <FeedSearch className={hiddenMobile} />
        </div>

        <div className="d-inline-flex justify-content-end align-items-center side-segment">
          <Nav className="align-items-center" navbar>
            <NavItem className={classNames("mr-3", visibleMobile)}>
              <ButtonToggle
                className="toggle-search-sub-header"
                onClick={() => {
                  onGatedClick(
                    UNGATED_ACTIONS.ORIGINAL_ACTIONS.TOPBAR_NAV_SEARCH
                  );
                }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <CustomIcon icon={"feed_search"} size={20} />
                  <p className="helv-med text-12">{t("navigation.search")}</p>
                </div>
              </ButtonToggle>
            </NavItem>
            <span className={classNames("nav-divider mr-3", visibleMobile)} />
            <NavItem>
              <div
                className="dropdown-more mr-3"
                onClick={() =>
                  onGatedClick(UNGATED_ACTIONS.ORIGINAL_ACTIONS.TOPBAR_NAV_MENU)
                }>
                <span className="">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <CustomIcon
                      className="inactive-icon"
                      icon={"hamburger_menu"}
                      size={20}
                    />
                    <span className="helv-med text-12">
                      {i18n.t("navigation.menu")}
                    </span>
                  </div>
                </span>
              </div>
            </NavItem>
            <span className="nav-divider" />
            <NavItem>
              <div
                className="dropdown dropdown-profile"
                onClick={() =>
                  onGatedClick(
                    UNGATED_ACTIONS.ORIGINAL_ACTIONS.TOPBAR_NAV_AVATAR
                  )
                }>
                <span className="pl-3 cursor-pointer dropdown-toggle">
                  {/* Make gate's avatar match Global header's mobile vs desktop size changes. */}
                  <div
                    className={`avatar-placeholder ${
                      isMobile ? "gated-profile-mobile-placeholder-avatar" : ""
                    } rounded-circle`}></div>
                </span>
              </div>
            </NavItem>
          </Nav>
        </div>
      </Navbar>
    </header>
  );
};

export default GatedHeader;
