/** @format */

import _, { isArray } from "lodash";
import { DEFAULT_YEARS_BACK } from "../constants/extractable-constants";

export const generateRegions = (regions) => {
  return _.map(regions, (r) => ({
    ...r,
    value: r.code,
    label: r.name
  }));
};

export const generateYearOption = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const nbYearsInThePast = DEFAULT_YEARS_BACK;
  let years = [];
  for (let count = 0; count < nbYearsInThePast; count++) {
    let year = currentYear - count + "";
    years.push({ value: year, label: year, key: year });
  }
  return years;
};

export const generateYearRangeOptions = ({ start, end }) => {
  const endYear = end ? end : new Date().getFullYear();

  // If start is provided, year range goes back to same year as start (hence the +1)
  // If not, year range goes back to DEFAULT_YEARS_BACK
  const nbYearsInThePast = start ? endYear - start + 1 : DEFAULT_YEARS_BACK;
  if (nbYearsInThePast <= 0) {
    return [{ value: endYear, label: `${endYear}`, key: `${endYear}` }];
  }

  let years = [];
  for (let count = 0; count < nbYearsInThePast; count++) {
    let year = endYear - count + "";
    years.push({ value: year, label: year, key: year });
  }
  return years;
};

export const filterSchoolsForRegions = ({
  topLevelSearchObject,
  secondLevelOptions
}) => {
  return _.filter(secondLevelOptions, (option) => {
    return option.subdivisionUuid === topLevelSearchObject.uuid;
  });
};

export const filterSubdivision = ({
  topLevelSearchObject,
  secondLevelOptions
}) => {
  return secondLevelOptions;
};

export const getGradYear = (data) => {
  if (
    data.graduationYear === undefined ||
    data.graduationYear === null ||
    isNaN(data.graduationYear)
  ) {
    return "";
  }

  if (isArray(data.graduationYear)) {
    return data.graduationYear !== undefined
      ? parseInt(data.graduationYear[0])
      : "";
  } else {
    return data.graduationYear !== undefined
      ? parseInt(data.graduationYear)
      : "";
  }
};
