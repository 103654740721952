/** @format */

import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import { submitDegree } from "../../../actions/user-cme.actions";
import { showErrorMessage } from "../../../actions/global.actions";
import { trackClaimCredit } from "../../../actions/metrics.actions";
import Dialog from "../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../components/dialog/DialogType";

const MAX_LENGTH = 18;

const CMECertificateModal = ({
  // Modal Props
  toggle,
  isOpen,
  close,
  ...rest
}) => {
  /****************************** Config **************************************/
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const { caseId: caseUuid } = useParams();
  const campaignUuid = useSelector(
    (state) => state.case.cases[caseUuid]?.data.campaignUuid
  );
  const isProcessing = useSelector((state) => state.userCme.isProcessing);

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  const submit = async (values) => {
    if (isProcessing) {
      return;
    }

    trackClaimCredit({
      degree: values.degree,
      caseUuid,
      campaignUuid
    });
    const result = await dispatch(submitDegree(caseUuid, values.degree));
    if (result.error) {
      dispatch(
        showErrorMessage("AddUpdateScreen.postFailed", null, false, null)
      );
    } else {
      toggle();
    }
  };

  /****************************** Render **************************************/
  return (
    <Dialog
      type={DIALOG_TYPE.INFORM}
      isOpen={isOpen}
      title={i18n.t("CMECaseScreens.degreeTitle")}
      body={
        <FormGroup className="w-100 mb-0">
          <Label for="degree">{i18n.t("CMECaseScreens.degreeLabel")}</Label>
          <Controller
            as={Input}
            control={control}
            name="degree"
            placeholder={i18n.t("CMECaseScreens.degreePlaceholder")}
            defaultValue={""}
            rules={{ required: true }}
            maxLength={MAX_LENGTH}
            invalid={!!errors.degree}
          />
          {!!errors.degree && (
            <FormFeedback>{i18n.t("CMECaseScreens.degreeError")}</FormFeedback>
          )}
        </FormGroup>
      }
      confirmText={i18n.t("common.save")}
      confirmLoading={isProcessing}
      confirmDisabled={isProcessing}
      onConfirm={handleSubmit(submit)}
      onCancel={toggle}
      onClosed={toggle}
      toggle={toggle}
      {...rest}
    />
  );
};

CMECertificateModal.propTypes = {};

export default CMECertificateModal;
