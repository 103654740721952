import React from "react";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import CaseDropdown from "../../components/case/CaseDropdown";
import CaseMediaView from "../../components/case/CaseMediaView";
import CaseTitle from "../../components/case/CaseTitle";
import GatedCommentBar from "../../components/case/GatedCommentBar";
import TagsContainer from "../../components/case/TagsContainer";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import i18n from "../../utils/i18n";
import { getUrlWithRedirect } from "../../utils/route-utils";

const BlockedCaseContent = ({ itemContent, commentStatus, caseDetails }) => {
  const userExists = useAnonUserExists();
  const { sessionCount, countLimit } = useGatedBlockCounter();
  const { showLoginModal } = useLoginModalClick();
  const loginClick = { onClick: userExists ? showLoginModal : () => {} };

  const title =
    sessionCount > countLimit
      ? "Blocked.caseMedia.title"
      : "Blocked.caseMedia.title2";
  const explanation =
    sessionCount > countLimit
      ? "Blocked.caseMedia.explanation"
      : userExists
      ? "Blocked.caseMedia.explanation3"
      : "Blocked.caseMedia.explanation2";

  const signUpText = userExists
    ? "Blocked.caseMedia.logIn"
    : "Blocked.caseMedia.signUp";

  const commentText = userExists
    ? "Gated.CommentBar.logInText"
    : "Gated.CommentBar.signUpText";

  return (
    <Card>
      <CardBody className="d-inline-flex justify-content-between align-items-top w-100 bg-white border-0">
        <CaseTitle caseContent={itemContent} />
        <CaseDropdown
          caseContent={itemContent}
          caseNotification={() => {}}
          from={CASE_CARD_ORIGIN.DETAIL}
          commentStatus={commentStatus}
        />
      </CardBody>

      <CardBody className="pt-0">
        <TagsContainer caseContent={itemContent} />
      </CardBody>
      <div className="position-relative media-overlay-container">
        {itemContent?.media?.length > 0 && (
          <CaseMediaView
            disabled
            caseUuid={itemContent.caseUuid}
            className={itemContent.media.length === 1 ? "pb-3" : "pb-0"}
            media={itemContent.media}
            showImages={true}
            context={CASE_CARD_ORIGIN.DETAIL}
            videoPlayerConfig={{
              videoControlMode: VIDEO_CONTROL_MODE.NONE,
              forceAutoplay: false,
              forceMuted: true
            }}
          />
        )}
        <div
          className="media-overlay d-flex flex-column justify-content-center align-items-center"
          style={isMobileOnly ? { paddingTop: "90px" } : {}}>
          <div className="cta-container text-center">
            <h3 className={`helv-bold ${isMobileOnly ? "text-20" : "text-22"}`}>
              {i18n.t(title)}
            </h3>
            <p className={isMobileOnly ? "text-16" : "text-18"}>
              {i18n.t(explanation)}
            </p>

            <Link
              className={`btn ${
                isMobileOnly ? "" : "btn-lg"
              } btn-primary helv-bold mt-4`}
              style={isMobileOnly ? { width: "125px" } : { width: "166px" }}
              to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}
              {...loginClick}>
              {i18n.t(signUpText)}
            </Link>
            {!userExists && (
              <p className="mt-3 text-14 text-battleship-gray">
                {i18n.t("Blocked.caseMedia.pitchText")}
              </p>
            )}
          </div>
        </div>
      </div>

      <hr className="my-0" />
      <GatedCommentBar
        caseDetails={caseDetails}
        disableClick={true}
        linkText={i18n.t(commentText)}
      />
    </Card>
  );
};

export default BlockedCaseContent;
