/** @format */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DownloadButton from "./DownloadButton";
import moment from "moment";
import { CLINICAL_MOMENTS_DETAIL } from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import i18n from "../../../utils/i18n";
import { withRouter } from "react-router-dom";
import { trackDownloadCertificate } from "../../../actions/metrics.actions";
import * as ROUTES from "../../../constants/routes";

const CMECompletedActivityCell = ({ activity, history }) => {
  const outerStyle = {
    maxWidth: "378px",
    width: "100%",
    height: "129px",
    backgroundColor: "white",
    marginTop: "12px",
    marginBottom: "12px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    borderColor: "#ccd3d8",
    alignSelf: "center"
  };

  let url = "";
  if (activity && activity.media && activity.media.url) {
    url = activity.media.url;
  }

  const image = {
    width: "103px",
    height: "127px",
    backgroundImage: `url("${url}?fit=crop&w=103&h=127")`,
    backgroundSize: "cover",
    borderBottomLeftRadius: "4px",
    borderTopLeftRadius: "4px"
  };

  const rightPane = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  };

  const header = {
    padding: "4px 2px 4px 0px",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#ccd3d8",
    margin: "0px 12px"
  };
  const dateStyle = {
    fontSize: "11.9px",
    lineHeight: "14.6px"
  };
  const creditsStyle = {
    fontSize: "11.9px",
    lineHeight: "14.6px"
  };
  const titleStyle = {
    fontSize: "14.1px",
    lineHeight: "18.4px"
  };
  const titleContainer = {
    padding: "8px 8px",
    flex: 1
  };
  const containerStyle = {
    cursor: "pointer",
    width: "100%"
  };

  const date = moment(activity.completedAt)?.format("MMMM DD, YYYY");
  const earnedCredits = activity?.credits || 0;

  const navigateToActivity = useCallback(() => {
    history.push(
      getUrl(CLINICAL_MOMENTS_DETAIL, {
        caseId: activity.caseUuid
      })
    );
  }, [activity.caseUuid, history]);

  const onDownload = (e) => {
    if (e) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }

    trackDownloadCertificate({
      link: activity.certificateDownloadUrl,
      caseUuid: activity.caseUuid,
      campaignUuid: activity.campaignUuid
    });
    window.location.href = activity.certificateDownloadUrl;
  };

  const onInfo = (e) => {
    if (e) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }

    history.push(
      getUrl(ROUTES.CME_POST_NOTICE, {
        caseId: activity.caseUuid
      })
    );
  };

  const actionButton = activity?.certificateDownloadUrl ? (
    <DownloadButton
      style={{ alignSelf: "flex-end", margin: "10px" }}
      text={i18n.t("CMEScreens.downloadThis")}
      onPush={onDownload}
    />
  ) : (
    <DownloadButton
      color="secondary"
      style={{ alignSelf: "flex-end", margin: "10px" }}
      onPush={onInfo}
      classNames="download-button-disabled"
      text={i18n.t("CMEScreens.noCertAvailable")}
      icon="information"
    />
  );

  return (
    <div
      onClick={navigateToActivity}
      className="d-flex flex-column"
      style={containerStyle}>
      <div className="d-flex flex-row" style={outerStyle}>
        <div className="bg-lighter-grey" style={image}></div>
        <div style={rightPane}>
          <div
            className="d-flex flex-row align-items-center justify-content-between "
            style={header}>
            <span className="color-lighter-grey" style={dateStyle}>
              {date}
            </span>
            <span
              className="text-denim-blue helv-bold font-weight-bold"
              style={creditsStyle}>{`${earnedCredits} ${
              earnedCredits && earnedCredits === 1
                ? i18n.t("CMEScreens.credit")
                : i18n.t("CMEScreens.credits")
            }`}</span>
          </div>
          <div style={titleContainer}>
            <span style={titleStyle}>{activity.title}</span>
          </div>

          {actionButton}
        </div>
      </div>
    </div>
  );
};

CMECompletedActivityCell.propTypes = {
  activity: PropTypes.shape({
    caseUuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    credits: PropTypes.number,
    media: PropTypes.object,
    currentSlide: PropTypes.number,
    totalSlide: PropTypes.number,
    buttonUrl: PropTypes.string
  }).isRequired
};

export default withRouter(CMECompletedActivityCell);
