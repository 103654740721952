import React from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
// import Tooltip from "reactstrap/lib/Tooltip";

const ReactionButton = ({
  buttonId,
  buttonIcon,
  buttonReactedIcon,
  handleReactionClick,
  selectedReaction,
  reaction,
  toolTipOpen
  // toolTipInnerClass,
  // buttonText,
  // handleToggle
}) => {
  const { theme } = useTheme();
  const icon =
    selectedReaction && buttonReactedIcon ? buttonReactedIcon : buttonIcon;

  // Am commenting this out for now due to https://figure1.atlassian.net/browse/F1PW-514
  // but will be left here until it's decided if the multiple reactions (which is why
  // the labels existed) are dropped -- Corey
  // const tooltipEl = (
  //   <Tooltip
  //     placement="top"
  //     hideArrow={true}
  //     innerClassName={`${toolTipInnerClass} rounded-pill`}
  //     isOpen={toolTipOpen}
  //     autohide={true}
  //     target={buttonId}
  //     toggle={handleToggle}>
  //     {buttonText}
  //   </Tooltip>
  // );

  return (
    <>
      <div
        id={buttonId}
        className="cursor-pointer"
        onClick={() => handleReactionClick(reaction)}>
        <CustomIcon
          icon={icon}
          size={toolTipOpen ? 34 : 24}
          className={
            selectedReaction === reaction
              ? "icon-denim-blue"
              : theme === THEMES.DARK
              ? "icon-white"
              : "icon-battleship-gray"
          }
        />
      </div>
      {/* {tooltipEl} */}
    </>
  );
};

export default ReactionButton;
