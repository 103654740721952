import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../utils/i18n";
import CustomIcon, { ICON_ROTATION } from "../custom-icon/CustomIcon";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SIGN_IN } from "../../constants/routes";
import useIsGated from "../../hooks/useIsGated";
import { Button } from "reactstrap";
import "./GatedBanner.scss";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { trackGatedCTA } from "../../actions/metrics.actions";
import GatedButtons from "../gated-buttons/GatedButtons";
import LOCAL_STORAGE_CONSTANTS from "../../constants/local-storage-constants";
import { handleUngatedSignIn } from "../../actions/login.actions";
import { getAndParseLocalStorageItem } from "../../utils/local-storage-utils";
import useIsSponsored from "../../hooks/useIsSponsored";

const hiddenMobile = "d-smed-none d-inline-flex";
const visibleMobile = "d-none d-smed-inline-flex ml-2";

const ToggleCollapseButton = ({ collapsed, toggleCollapse }) => (
  <Button
    className={`toggle-button  p-1 ${
      collapsed ? "align-items-center" : "align-self-end"
    }`}
    onClick={toggleCollapse}
    color="link">
    <CustomIcon
      className={visibleMobile}
      icon="circle-chevron"
      color="white"
      size="24"
      rotation={collapsed ? ICON_ROTATION.r180 : 0}
    />
    <CustomIcon
      className={hiddenMobile}
      icon="circle-chevron"
      color="white"
      size="30"
      rotation={collapsed ? ICON_ROTATION.r180 : 0}
    />
  </Button>
);

const GatedBanner = () => {
  const history = useHistory();
  const location = useLocation();
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();
  const routeParams = useParams();
  const dispatch = useDispatch();

  const caseUuid = routeParams.caseId;
  const caseStatus = useSelector((state) => state.case.cases[caseUuid]);
  const details = caseStatus?.data || {};
  const campaignUuid = details?.campaignUuid;
  const caseType = details.caseType;

  // if we have a uuid, we have a record of em
  const userExists = !!useSelector((state) => state.user?.userUuid);

  const [collapsed, setCollapsed] = useState(false);

  const onSignIn = async () => {
    trackGatedCTA({
      campaignUuid,
      caseUuid,
      caseType,
      source: collapsed
        ? UNGATED_ACTIONS.ORIGIN.COLLAPSED_BANNER
        : UNGATED_ACTIONS.ORIGIN.EXPANDED_BANNER,
      action: UNGATED_ACTIONS.ACTION.SIGN_IN
    });

    const authUser = getAndParseLocalStorageItem(
      LOCAL_STORAGE_CONSTANTS.AUTH_USER
    );
    if (!authUser.isAnonymous) {
      await dispatch(handleUngatedSignIn());
    }
    history.push(`${SIGN_IN}?redirect=${location.pathname}`);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // If not signed in, show become member and sign in and continue
  // if signed in but not completed, show sign in and continue
  const signInProps = !userExists && !collapsed ? { outline: true } : {};

  const signInButton = (
    <Button className="cta" onClick={onSignIn} {...signInProps}>
      {i18n.t("Gated.SignIn")}
    </Button>
  );

  const content = collapsed ? (
    <>
      <h2 className="text-20 font-weight-bold">
        {i18n.t("Gated.Banner.title")}
      </h2>{" "}
      {signInButton}
    </>
  ) : (
    <>
      <h2 className="text-20 font-weight-bold">
        {i18n.t("Gated.Banner.title")}
      </h2>
      <p>{i18n.t("Gated.Banner.message")}</p>
      <GatedButtons
        color="secondary"
        caseUuid={caseUuid}
        campaignUuid={campaignUuid}
        caseType={caseType}
        source={
          collapsed
            ? UNGATED_ACTIONS.ORIGIN.COLLAPSED_BANNER
            : UNGATED_ACTIONS.ORIGIN.EXPANDED_BANNER
        }
      />
      <div className="separator m-3">or</div>
      <Button className="cta" onClick={toggleCollapse} outline={true}>
        {i18n.t("Gated.Banner.continue")}
      </Button>
    </>
  );

  if (!isGated || (isGated && !isSponsored)) {
    return null;
  }

  return (
    //  When collapsed, show chevron at end of flex row. Otherwise, it's top of column.
    <div
      className={`gated-banner bg-gradient d-flex justify-content-center ${
        collapsed ? "collapsed" : "flex-column align-items-center"
      }`}>
      {!collapsed && (
        <ToggleCollapseButton
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
        />
      )}
      <div
        className={`contents d-flex align-items-center flex-grow-1 ${
          collapsed
            ? "justify-content-between"
            : "justify-content-center flex-column text-center"
        }`}>
        {content}
      </div>
      {collapsed && (
        <ToggleCollapseButton
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
        />
      )}
    </div>
  );
};

GatedBanner.propTypes = {};

export default GatedBanner;
