import Firebase from "../firebase";

const functions = Firebase.functions;

// Toggle one topic
export const toggleTopicFollow = async (data) => {
  const result = await functions.httpsCallable("backendTopicToggleFollow")(data);
  return result.data;
};

// Accepts an array of topics to follow
export const toggleFollowTopics = async (data) => {
  const result = await functions.httpsCallable("backendTopicFollowTopics")(
    data
  );
  return result.data;
};
