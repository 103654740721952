import React from "react";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { DropdownItem } from "reactstrap";

const CommentDropdownItem = ({
  icon,
  iconColor,
  primaryText,
  secondaryText,
  handleClick
}) => {
  return (
    <DropdownItem onClick={handleClick}>
      <div className="case-dropdown-item">
        <CustomIcon icon={icon} color={iconColor} size={20} />

        <div className="case-dropdown-text-area">
          <div className="case-dropdown-text-primary">{primaryText}</div>
          {secondaryText && (
            <div className="case-dropdown-text-secondary">{secondaryText}</div>
          )}
        </div>
      </div>
    </DropdownItem>
  );
};

export default CommentDropdownItem;
