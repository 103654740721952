import React from "react";
import {
  Toast as ReactstrapToast,
  ToastBody,
  Button,
  ToastHeader
} from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomIcon from "../custom-icon/CustomIcon";

const Toast = ({
  isOpen,
  onHide,
  message = "",
  title = "",
  color = "info",
  icon = "sheet_report"
}) => {
  const { t } = useTranslation();

  let iconEl;
  if (icon) {
    iconEl = (
      <CustomIcon className={`icon-${color} mr-1 mt-0`} icon={icon} size={16} />
    );
  }
  let titleEl = title ? (
    <ToastHeader icon={iconEl}>{t(title)}</ToastHeader>
  ) : null;

  let messageEl = React.isValidElement(message) ? message : t(message);

  return (
    <ReactstrapToast
      className={`f1-toast alert-${color} text-center whitespace-pre-line py-3 px-5 mw-100`}
      isOpen={isOpen}
      color={color}>
      {titleEl}
      <ToastBody className="p-0">
        {titleEl === null && iconEl}
        {messageEl}
        <div className="alert-dismissible">
          <Button
            className="d-flex align-items-center justify-content-center p-3"
            onClick={onHide}
            close
            aria-label="close">
            <CustomIcon
              icon="modal_exit"
              size={12}
              color={"white"}
              aria-hidden="true"
            />
          </Button>
        </div>
      </ToastBody>
    </ReactstrapToast>
  );
};

Toast.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string
};

export default Toast;
