import React from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeader from "./components/RegistrationHeader";
import { useContactSupport } from "../../hooks/useContactSupport";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import Button from "reactstrap/lib/Button";
import i18n from "../../utils/i18n";

const NotFoundPage = () => {
  const onContactSupport = useContactSupport();
  const history = useHistory();
  const onClick = () => {
    history.replace(ROUTES.HOME_ROOT);
  };
  return (
    <Figure1Layout
      noGatedHeader={true}
      topHeader={<RegistrationHeader onContactSupport={onContactSupport} />}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: "10vh" }}>
        <CustomIcon
          className="mb-2"
          icon="network_error"
          color="#3f6296"
          size={40}
        />
        <p class="helv-bold">{i18n.t("404.text")}</p>
        <Button color="primary" className="px-3 py-2" onClick={onClick}>
          {i18n.t("404.action")}
        </Button>
      </div>
    </Figure1Layout>
  );
};

export default NotFoundPage;
