import React from "react";
import { Navbar } from "reactstrap";
import i18n from "../../utils/i18n";
import { Link } from "react-router-dom";
import { getUrl, getUrlWithRedirect } from "../../utils/route-utils";
import { ROOT, SIGN_IN, SIGN_UP } from "../../constants/routes";
import { isMobileOnly } from "react-device-detect";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useLoginModalClick from "../../hooks/useLoginModalClick";
const hiddenMobile = "d-smed-none d-inline-flex";

const GatedHeaderV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const userExists = useAnonUserExists();
  const { showLoginModal } = useLoginModalClick();
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <header className="bg-white header--shadowed gated-header">
      <Navbar
        className="d-flex primary-nav justify-content-between align-items-center"
        color="white"
        light
        expand>
        <Link to={getUrl(ROOT)} className="navbar-brand align-self-center">
          {i18n.t("common.figure1")}
        </Link>

        <div className="d-inline-flex justify-content-end align-items-center side-segment">
          <Link
            className={`d-inline-flex justify-content-center align-items-center nav-link btn ${
              isMobileOnly ? "btn-primary" : "btn-link btn-lg w-125px"
            } ${userExists ? "" : hiddenMobile} helv-bold text-16 text-center`}
            to={getUrlWithRedirect(SIGN_IN)}
            onClick={showLoginModal}>
            {i18n.t("RegistrationScreens.loginForm.btnSignIn")}
          </Link>

          <Link
            className={`d-inline-flex justify-content-center align-items-center nav-link btn btn-primary ${
              isMobileOnly ? "text-14" : "btn-lg text-16 w-125px"
            } helv-bold text-center ${userExists ? hiddenMobile : ""}`}
            to={getUrlWithRedirect(SIGN_UP)}>
            {i18n.t("RegistrationScreens.createRegistration.btnSignup")}
          </Link>
        </div>
      </Navbar>
    </header>
  );
};

export default GatedHeaderV2;
