import React from "react";
import useIsGated from "../../hooks/useIsGated";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import CaseThumbnail from "./CaseThumbnail";
import PropTypes from "prop-types";
import useIsSponsored from "../../hooks/useIsSponsored";

const GatedCaseThumbnail = ({ caseContent }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();

  /** ********************************* FUNCTIONS *************************************/
  const gatedNavigateToSimilarCase = useGatedActionGate(
    () => {}, // Doesn't need cb since, link handles onClick already.
    GATED_MODAL_TYPE.GENERIC,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.SIMILAR_CASE
  );
  const disabled = isGated && isSponsored;
  /** ********************************** RENDER ***************************************/

  return (
    <CaseThumbnail
      disableClick={disabled}
      caseContent={caseContent}
      onClickCallback={gatedNavigateToSimilarCase}
    />
  );
};

GatedCaseThumbnail.propTypes = {
  caseContent: PropTypes.object
};

export default GatedCaseThumbnail;
