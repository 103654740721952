import moment from "moment";

const DB_CONSTANTS = {
  CASE_MEDIA: "caseMediaDB",
  CASES: "casesDB",
  COMMENTS: "commentsDB",
  MESH_TERMS: "meshTermsDB",
  PUBLICATIONS: "publicationsDB",
  USERS: "usersDB",
  USER_ROLES: "userRolesDB",
  ASSIGNMENTS: "clientAssignmentDB",
  REVIEW_MESH_TERMS: "reviewMeshTermsDB",
  SAVED_CASES: "userSavedCasesDB",
  USER_DRAFTS: "userDraftsDB",
  REFERENCE_DATA: "b_referenceData",
  REFERENCE_DATA_NEW: "referenceData",
  CASE_FILTERS: "caseFilters",
  COMMON: "common",
  COUNTRIES: "countries",
  COUNTRY_SUBDIVISION: "subdivisions",
  SPECIALTIES: "specialties",
  PLATFORMS: "platforms",
  CASES_V2: "casesDBv2",
  MODERATION: "moderationDB",
  VERIFICATION_TAGS: "verificationTags",
  SCHOOLS: "schools",
  CONFIGURATION: "configurationDB",
  DEFAULT_CONFIG: "DEFAULT",
  ELASTIC_CONFIG: "elasticsearchConfig",
  LABELS: "labels",
  ADMIN_USER_CASE_LOCKS: "adminUserCaseLocksDB",
  USER_ACTIVITY_RECORD: "userActivityRecordDB",
  USER_FEED: "userFeedDB",
  USER_COMMUNICATION_PREFS: "userCommunicationPrefsDB",
  USERS_PROFILE: "usersProfileDB",
  USER_VOTES: "userVotesDB",
  USER_CME: "userCmeDB",
};

export const MODERATION_COMMENTS_SUB_COLLECTIONS = {
  REPORTED: {
    statsKey: "reported",
    displayName: "Reported",
    activeAuxClassName: "reported"
  },
  ALERT_WORDS: {
    statsKey: "alerted",
    displayName: "Alert Words",
    activeAuxClassName: "suggested"
  },
  FLAGGED: {
    statsKey: "flagged",
    displayName: "Flagged",
    activeAuxClassName: "flagged"
  },
  // SPONSORED_CONTENT: {
  //   collectionName: "sponsoredContent",
  //   displayName: "Sponsored Content"
  // },
  // CME: { collectionName: "cme", displayName: "CME" },
  ALL: { statsKey: "all", displayName: "All" }
};

export const MODERATION_COMMENTS_HISTORY_COLLECTIONS = {
  ALL: { statsKey: "all", displayName: "History" }
};

export const MODERATION_COMMENTS_DATE_KEYS = {
  PAST_1_DAY: {
    statAttribute: "past1DayCount",
    displayName: "Past Day",
    getDateFilter: () =>
      moment()
        .subtract(1, "days")
        .format()
  },
  PAST_7_DAY: {
    statAttribute: "past7DayCount",
    displayName: "Past 7 Days",
    getDateFilter: () =>
      moment()
        .subtract(7, "days")
        .format()
  },
  PAST_30_DAY: {
    statAttribute: "past30DayCount",
    displayName: "Past 30 Days",
    getDateFilter: () =>
      moment()
        .subtract(30, "days")
        .format()
  },
  PAST_365_DAY: {
    statAttribute: "past365DayCount",
    displayName: "Past 365 Days",
    getDateFilter: () =>
      moment()
        .subtract(365, "days")
        .format()
  }
};

export const VERIFICATION_SUB_COLLECTIONS = {
  PENDING: { statsKey: "pending_manual_verification", displayName: "Pending" },
  UPDATED_INFO: {
    statsKey: "updated_info",
    displayName: "Updated Info",
    isViewable: (userRoles) => {
      return true;
    }
  },
  INFO_NEEDED: {
    statsKey: "info_needed",
    displayName: "Info Needed"
  },
  VERIFIED: {
    statsKey: "verified",
    displayName: "Verified"
  },
  UNVERIFIABLE: { statsKey: "unverifiable", displayName: "Unverifiable" },
  ALL: { statsKey: "all", displayName: "All" }
};

export default DB_CONSTANTS;
