/** @format */

import {
  getRegistrationRoute,
  handleUserInitialRoute
} from "../actions/login.actions";
import { useHistory } from "react-router";
import { isUserCompletedSignup } from "../utils/onboarding-utils";
import { useDispatch } from "react-redux";
import useInitialRouteRedirect from "./useInitialRouteRedirect";

const useOnboardingNavigation = () => {
  /****************************** Config **************************************/
  const history = useHistory();
  const dispatch = useDispatch();

  useInitialRouteRedirect();
  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return async (user) => {
    if (!isUserCompletedSignup(user)) {
      const route = await getRegistrationRoute(user);
      if (route) {
        history.push(route);
      }
    } else {
      await dispatch(handleUserInitialRoute(user));
    }
  };
};

export default useOnboardingNavigation;
