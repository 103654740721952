/** @format */
import CASE_TYPE, {
  INTERACTIVE_CASE_TYPES,
  QUESTION_CASE_TYPES
} from "../constants/case-type";
import { CONTENT_TYPE } from "../constants/content-type";

/**
 * Used with feed data. Determine if question content is a poll.
 * Return true if all answers are false.
 */
export const isPoll = (caseContent) => {
  if (!caseContent) {
    return false;
  }

  return (
    caseContent.questionOptions &&
    caseContent.questionOptions.length > 0 &&
    !caseContent.questionOptions.some((option) => option.isAnswer)
  );
};

export const isQuiz = (caseContent) => {
  if (!caseContent) {
    return false;
  }

  return (
    caseContent.caseType === CASE_TYPE.QUIZ ||
    caseContent.caseType === CASE_TYPE.QUIZ_SERIES
  );
};

export const isInteractiveCase = (caseType) =>
  INTERACTIVE_CASE_TYPES.includes(caseType);

export const isQuestionCase = (caseType) =>
  QUESTION_CASE_TYPES.includes(caseType);

/**
 * Return a copy of contentItems with `isPoll` on each item,
 * set to true if is a poll. Otherwise, it is set to false.
 *
 * If there is a quiz summary, set `isPoll` to true if there
 * is at least 1 poll found. This is to apply a different
 * layout for the quiz summary screen.
 */
export const processInteractiveCaseDetailsContentItems = (contentItems) => {
  let hasPoll = false;

  return contentItems.map((item) => {
    let _isPoll = isPoll(item);

    if (_isPoll && !hasPoll) {
      hasPoll = true;
    }

    if (item.contentType === CONTENT_TYPE.QUIZ_SUMMARY && hasPoll) {
      _isPoll = true;
    }

    return {
      ...item,
      isPoll: _isPoll
    };
  });
};

/**
 * Check if a content item is served as free from quiz.
 * The way we populate this flag is convoluted due to backend technical difficulty
 * It's set on a question option item inside the content item.
 * */
export const isFreeFormQuizContent = (contentItem) => {
  return (
    contentItem?.questionOptions.length === 1 &&
    contentItem?.questionOptions[0].isFreeForm
  );
};

export const isSponsoredContent = (caseContent) => {
  return caseContent?.caseState?.startsWith("SC_") ?? false;
};
