/** @format */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CMECompletedActivityCell from "./CMECompletedActivityCell";
import CMECompletedActivitiesHeader from "./CMECompletedActivitiesHeader";
import CMEDateSectionHeader from "./CMEDateSectionHeader";
import { getCompletedCredits } from "../../../utils/cme-utils";
import { ACTIVITY_DATE, COMPLETED_ACTIVITY } from "../constants";
import "../cme.scss";

const CMECompletedActivitiesGallery = ({ activities = [], emptyText = "" }) => {
  const totalCredits = useMemo(() => {
    return getCompletedCredits(activities);
  }, [activities]);
  const creditsThisMonth = useMemo(() => {
    return getCompletedCredits(activities, true);
  }, [activities]);
  const outerStyle = {
    maxWidth: "406px",
    width: "98%"
  };
  const contentStyle = {
    backgroundColor: "white",
    marginTop: "10px"
  };
  return (
    <div style={outerStyle}>
      <CMECompletedActivitiesHeader
        totalCredits={totalCredits}
        creditsThisMonth={creditsThisMonth}
      />
      {activities.length === 0 ? (
        <div className="emptyActivities">{emptyText}</div>
      ) : (
        <div
          className="d-flex flex-column align-items-center flex-nowrap"
          style={contentStyle}>
          {activities.map((item, index) => {
            if (item.type === ACTIVITY_DATE) {
              return (
                <CMEDateSectionHeader key={index.toString()} date={item.date} />
              );
            } else if (item.type === COMPLETED_ACTIVITY) {
              return (
                <CMECompletedActivityCell
                  key={index.toString()}
                  activity={item}
                />
              );
            }
            return <div></div>;
          })}
        </div>
      )}
    </div>
  );
};

CMECompletedActivitiesGallery.propTypes = {
  activities: PropTypes.array.isRequired,
  emptyText: PropTypes.string.isRequired
};

export default CMECompletedActivitiesGallery;
