/** @format */

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { round } from "lodash";
import lottie from "lottie-web";
import i18n from "../../utils/i18n";
import animationData from "../../assets/lottie-files/quiz-series-results.json";
import { trackQuestionCompletion } from "../../actions/metrics.actions";
import useIsGated from "../../hooks/useIsGated";

const QuizSummaryScoreCard = (props) => {
  /** ********************************** CONFIG ***************************************/

  const lottieContainerRef = useRef(null);
  const lottieAnimation = useRef(null);
  const isGated = useIsGated();

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    trackQuestionCompletion({
      score: props.nbRightAnswers,
      caseUuid: props.caseContent.caseUuid,
      campaignUuid: props.caseContent.campaignUuid,
      type: props.caseContent.caseType,
      isUngated: isGated
    });
  }, [
    props.nbRightAnswers,
    props.caseContent.caseUuid,
    props.caseContent.campaignUuid,
    props.caseContent.caseType,
    isGated
  ]);

  useEffect(() => {
    lottieAnimation.current = lottie.loadAnimation({
      container: lottieContainerRef.current,
      loop: false,
      autoplay: false,
      animationData
    });
    // Only play the animation if the user has answered 1 question correctly
    if (props.nbRightAnswers > 0 && props.nbQuestions > 0) {
      const percentCorrect = round(props.nbRightAnswers / props.nbQuestions, 1);
      const numAnimationFrames = lottieAnimation.current.getDuration(true);
      const targetFrame = numAnimationFrames * percentCorrect;
      lottieAnimation.current.playSegments([0, targetFrame], true);
    }

    return () => {
      lottieAnimation.current.destroy();
    };
  }, [props.nbRightAnswers, props.nbQuestions]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="position-relative d-flex flex-column justify-content-center align-items-center">
      <div
        className="position-absolute t-0 r-0 b-0 l-0 d-flex flex-column justify-content-center align-items-center w-100"
        style={{ height: props.height }}>
        <h3 className="mb-0">{i18n.t("questionDetailCard.summary.label")}</h3>
        <h1 className="text-48">
          {props.nbRightAnswers}/{props.nbQuestions}
        </h1>
      </div>
      <div
        className="position-relative"
        style={{ height: props.height, width: props.height }}
        ref={lottieContainerRef}
      />
    </div>
  );
};

QuizSummaryScoreCard.propTypes = {
  nbRightAnswers: PropTypes.number.isRequired,
  nbQuestions: PropTypes.number.isRequired,
  height: PropTypes.number
};

QuizSummaryScoreCard.defaultProps = {
  height: 400
};

export default QuizSummaryScoreCard;
