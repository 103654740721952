/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const fetchComments = async (caseId) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.CASES_V2}/${caseId}/comments`);
  const comments = await ref.get();
  return comments.exists ? comments.data() : [];
};

export const listenForComments = (caseUuid, contentUuid, callback) => {
  return getCollectionRef(`${DB_CONSTANTS.CASES_V2}/${caseUuid}/${contentUuid}`)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};
