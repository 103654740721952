import React, { useEffect } from "react";
import { FormGroup, FormText, Label, Spinner } from "reactstrap";
import i18n from "../../../utils/i18n";
import { Controller } from "react-hook-form";
import FormFeedback from "reactstrap/lib/FormFeedback";
import { useSelectFallbackCountryAndRegions } from "../../../selectors/references.selectors";
import Select from "react-select";
import {
  checkCurrentCountry,
  fetchFallbackLocationsIfNeeded
} from "../../../actions/reference.actions";
import { useDispatch, useSelector } from "react-redux";

const FormElementCountry = ({ control, errors, showFeedback, helpText }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const { countries } = useSelectFallbackCountryAndRegions();
  const isInvalid = !!errors.country;

  const country = useSelector((state) =>
    // has a previously selected country? (not the default one) use it, otherwise
    // use the currentCountry from the api call
    state?.user?.country?.countryUuid
      ? state?.user?.country?.countryCode
      : state?.references?.country
  );

  const defaultCountry =
    countries && country
      ? countries.find((c) => c.countryCode === country)
      : null;

  /** ********************************* HOOKS *************************************/
  useEffect(() => {
    dispatch(fetchFallbackLocationsIfNeeded());
    if (!country) {
      dispatch(checkCurrentCountry());
    }
  }, [dispatch, country]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  // wait for country to show up
  if (!defaultCountry && !countries.length) {
    return (
      <div className="w-100 d-flex justify-content-center mb-3">
        <Spinner size="md" color="primary" />
      </div>
    );
  }

  return (
    <FormGroup>
      <Label for="country">
        {i18n.t("RegistrationScreens.createRegistration.v2.countryLabel")}
      </Label>
      <Controller
        defaultValue={defaultCountry}
        control={control}
        id="country"
        name="country"
        type="text"
        rules={{ required: true }}
        invalid={isInvalid}
        as={
          <Select
            className={isInvalid ? "is-invalid" : ""}
            style={{
              width: "100%",
              resize: "none",
              borderColor: "#ccd3d8",
              borderRadius: "5px",
              lineHeight: "1.3rem",
              padding: "10px 15px 10px 15px"
            }}
            options={countries}
            placeholder={i18n.t(
              "RegistrationScreens.createRegistration.v2.countryPlaceholder"
            )}
          />
        }
      />
      {helpText && <FormText color="muted">{helpText}</FormText>}
      {showFeedback && (
        <FormFeedback>
          {i18n.t("RegistrationScreens.createRegistration.v2.countryRequired")}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormElementCountry;
