/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ContentProp } from "../../../prop-types/ContentProp";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useTheme from "../../../components/theme/useTheme";
import THEMES from "../../../components/theme/Theme";

const CMCoverSlideRowLoading = () => {
  /****************************** Config **************************************/
  const { theme } = useTheme();

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <SkeletonTheme
      color={theme === THEMES.DARK ? "#191818" : null}
      highlightColor={theme === THEMES.DARK ? "#1d2931" : null}>
      <Row className="no-gutters mb-4">
        <Col className="bg-dark-gray" lg={6}>
          <Card className="bg-transparent text-white border-0 h-100">
            <CardBody>
              <Skeleton
                className="text-28 mb-1"
                style={{ lineHeight: "1.25em" }}
              />
              <Skeleton
                className="text-28 mb-1"
                style={{ lineHeight: "1.25em" }}
              />
              <Skeleton className="text-28" style={{ lineHeight: "1.25em" }} />
            </CardBody>
            <CardBody className="pt-0 flex-grow-0">
              <Skeleton height={25} width={75} />
            </CardBody>
          </Card>
        </Col>

        <Col className="bg-dark-gray" lg={1} />

        <Col className="bg-dark-gray" lg={5}>
          <Card className="bg-transparent text-white border-0 h-100 justify-content-between">
            <CardBody className="d-inline-flex justify-content-between align-items-center w-100 flex-grow-0">
              <div className=" d-flex align-items-center w-100">
                <Skeleton circle={true} height={50} width={50} />
                <div className="d-inline-flex justify-content-between align-items-center w-100 ml-3">
                  <Skeleton width={100} />
                  <div>
                    <Skeleton width={8} height={8} count={3} circle />
                  </div>
                </div>
              </div>
            </CardBody>
            <CardBody className="pt-0 flex-grow-0">
              <Skeleton height={65} width={"100%"} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </SkeletonTheme>
  );
};

CMCoverSlideRowLoading.propTypes = {
  content: ContentProp,
  caseMeta: PropTypes.object
};

export default CMCoverSlideRowLoading;
