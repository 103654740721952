import { forOwn, isEmpty } from "lodash";

export const userSignedIn = (auth) => {
  return !isEmpty(auth.userUid);
};

export const getUrl = (route, params = {}) => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

export const getUrlWithRedirect = (route, params = {}) => {
  return `${getUrl(route, params)}?redirect=${window.location.pathname}`;
};

export const getFullUrl = (route, params) =>
  `${window.location.protocol}//${window.location.host}${getUrl(
    route,
    params
  )}`;

export const openInTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
