import React from "react";
import { isEqual, get } from "lodash";
import moment from "moment";
import CommentUserTag from "../components/comments/CommentUserTag";
import {
  COMMENT_LIMIT,
  COMMENT_ROOT_LEVEL
} from "../constants/comment-constants";

export const COMMENT_STATES = Object.freeze({
  APPROVED: "approved",
  FLAGGED: "flagged",
  REPORTED: "reported",
  PENDING: "pending_approval"
});

export const isApprovalActive = (comment) => {
  return !isEqual(get(comment, "state"), COMMENT_STATES.APPROVED);
};

export const isFlaggedActive = (comment) => {
  return !isEqual(get(comment, "state"), COMMENT_STATES.FLAGGED);
};

export const isRejectedActive = (comment) => {
  return true;
};

export const flattenCommentTree = (
  comments,
  cache,
  result,
  level,
  sorting,
  physicianOnly = false
) => {
  if (!comments) {
    return [];
  }

  if (cache && !cache.parentUuid && level === 0) {
    comments["cache"] = cache;
  }

  const array = sortComments(Object.values(comments), sorting.order === 0);

  for (const c of array) {
    if ((physicianOnly && c.isPhysician) || !physicianOnly) {
      let comment = { ...c, level: level };

      result.push(comment);

      const children = c.children || {};
      if (cache && cache.parentUuid && c.commentUuid === cache.parentUuid) {
        children["cache"] = cache;
      }
      flattenCommentTree(children, cache, result, level + 1, sorting, false);
    }
  }

  return result;
};

export const collapseCommentTree = (
  flattenComments,
  expandedStates,
  caseUuid
) => {
  let rootCommentId = "";
  let childrenCount = 0;
  let comments = [];
  let rootCount = 0;
  const rootLimit =
    expandedStates[caseUuid] || COMMENT_LIMIT.ROOT_INITIAL_THRESHOLD;

  for (let i = 0; i < flattenComments.length; i++) {
    const comment = flattenComments[i];
    const threadLimit =
      expandedStates[rootCommentId] || COMMENT_LIMIT.CHILD_INITIAL_THRESHOLD;

    if (comment.level === COMMENT_ROOT_LEVEL) {
      childrenCount = 0;
      rootCount++;
      rootCommentId = comment.commentUuid;

      // add view more comments in root level
      if (rootCount > rootLimit) {
        comments.push({
          count: 0,
          expanderThreadUuid: caseUuid,
          isRoot: true
        });
        rootCount = rootLimit;
        break;
      }

      comments.push(comment);
    } else {
      childrenCount++;

      // if at the end of comment array, or if next comment is root comment
      // check to add view replies/hide replies bar
      const reachThreadEnd =
        i === flattenComments.length - 1 || flattenComments[i + 1].level === 0;

      if (childrenCount <= threadLimit) {
        comments.push(comment);

        if (reachThreadEnd) {
          // childrenCount is greater than initial threshold when thread reach end
          // Means that this thread is expanded to the max
          if (childrenCount > COMMENT_LIMIT.CHILD_INITIAL_THRESHOLD) {
            comments.push({
              expanderThreadUuid: rootCommentId,
              isRoot: false,
              isEnd: true
            });
          }
        }
      } else {
        if (reachThreadEnd) {
          comments.push({
            count: childrenCount - threadLimit,
            expanderThreadUuid: rootCommentId,
            isRoot: false
          });
        }
      }
    }
  }

  if (rootCount > COMMENT_LIMIT.ROOT_INITIAL_THRESHOLD) {
    comments.push({
      count: 0,
      expanderThreadUuid: caseUuid,
      isRoot: true,
      isEnd: true
    });
  }

  return comments;
};

export const filterPhysicianComments = (flattenComments) => {
  let physicianComments = [];
  for (const c of flattenComments) {
    if (c.isPhysician) {
      // For physician comment tab, show all comments in root level for now.
      physicianComments.push({ ...c, level: 0 });
    }
  }
  return physicianComments;
};

export const sortComments = (commentsArr, oldToNew) => {
  return commentsArr.length <= 1
    ? commentsArr
    : commentsArr.sort((c1, c2) => {
        const date1 = moment(c1.createdAt);
        const date2 = moment(c2.createdAt);
        return oldToNew ? date1 - date2 : date2 - date1;
      });
};

export const scrubComments = (commentsArr, userUuid) => {
  return commentsArr.filter((comment) => {
    return (
      comment.commentState !== COMMENT_STATES.PENDING ||
      (comment.commentState === COMMENT_STATES.PENDING &&
        comment.authorUuid === userUuid)
    );
  });
};

export const injectUserTag = (comment) => {
  // const reg = /(?<![\w\d])@[a-zA-Z0-9+_-]*/g; // safari doesn't support lookbehind (which this is)
  // work around it for now :/
  const reg = /@[A-Za-z0-9_-]*/g; // match on all @somethings
  const charReg = /[A-Za-z0-9_-]/; // match a letter/number/and some valid other characters
  const matches = [...comment.matchAll(reg)];
  const splitString = comment.split(reg);

  if (splitString.length === 1) {
    // no @s
    return splitString[0];
  }
  const combinedString = [];
  for (let i = 0; i < splitString.length; i++) {
    combinedString.push(splitString[i]);
    const match = matches[i];
    if (match) {
      // match if start of string OR match later in string and the preceeding character is
      // not a character/digit. This is to weed out emails.
      if (
        match.index === 0 ||
        (match.index > 0 && !charReg.test(match.input.charAt(match.index - 1)))
      ) {
        combinedString.push(<CommentUserTag username={matches[i][0]} />);
      } else {
        combinedString.push(match[0]);
      }
    }
  }

  return (
    <>
      {combinedString.map((e) => (
        <>{e}</>
      ))}
    </>
  );
};
