import { compact, get, isEmpty, map, maxBy } from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// see https://docs.imgix.com/apis/rendering/size/fit for more fit options
export const getImageUrl = (media, width = 500, height = 500, fit = "max") => {
  const isImage = get(media, "type") === "image";
  const url = get(media, "url");
  const protocol = getUrlProtocol(url);
  if (!isImage || isEmpty(url) || !protocol) {
    return null;
  }
  if (protocol === "data:") {
    return url;
  }
  if (process.env.NODE_ENV !== "production") {
    return `${url}?w=${width}&h=${height}&fit=${fit}&blur=0`;
  }

  return `${url}?w=${width}&h=${height}&fit=${fit}`;
};

export const convertUploadMedia = (media) => {
  return {
    filename: get(media, "filename"),
    type: get(media, "type"),
    height: get(media, "height"),
    width: get(media, "width"),
    url: get(media, "url")
  };
};

export const startImageDownload = (image, setDownloading) => {
  setDownloading(true);
  const url = get(image, "url");
  const filename = get(image, "filename");
  const element = document.createElement("a");
  fetch(new Request(url))
    .then((response) => response.blob())
    .then(function (myBlob) {
      element.href = URL.createObjectURL(myBlob);
      element.download = filename;
      element.click();
      setDownloading(false);
    });
};

export const startMediaDownloadZip = async (media, setDownloading) => {
  setDownloading(true);
  const zip = new JSZip();
  const images = await Promise.all(
    compact(
      map(media, (m) => {
        const url = get(m, "url");
        const filename = get(m, "filename");

        if (isEmpty(url)) {
          return null;
        }

        return fetch(new Request(url)).then(async (response) => {
          return { name: filename, data: await response.blob() };
        });
      })
    )
  );

  map(images, (i) => {
    zip.file(i.name, i.data);
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "caseImages.zip");
    setDownloading(false);
  });
};

/**
 * Get a data URL for the given file.
 *
 * @param {File} file
 * @returns {Promise<string>}
 */
export const getDataURL = (file) => {
  if (!file) {
    return Promise.reject("Error occurred reading file. No file provided.");
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "error",
      () => reject(`Error occurred reading file: ${file.name}`),
      false
    );

    reader.addEventListener("load", () => resolve(reader.result), false);

    reader.readAsDataURL(file);
  });
};

/**
 * Get the URL protocol for the provided url string
 *
 * @param {string} urlString
 */
export const getUrlProtocol = (urlString) => {
  let url;

  try {
    url = new URL(urlString);
  } catch {
    return "";
  }

  return url.protocol;
};

/**
 * Get the image that has the largest height
 *
 * @param {string[]} urls
 * @returns {Promise<{ height: number; width: number; }>}
 */
export const getMaxHeightDimension = (urls) => {
  return getMaxDimensionBy(urls, (d) => d.height);
};

/**
 * Get the image that has the largest value specified by the iteratee
 *
 * @param {string[]} urls
 * @param {({ height: number, width: number } => T)} iteratee
 * @returns {Promise<{ height: number; width: number; }>}
 */
export const getMaxDimensionBy = async (urls, iteratee) => {
  if (!urls) {
    return Promise.reject("Error occurred reading urls. No urls provided.");
  }
  const promises = urls.map((url) => getImageDimensions(url));

  const imgDimensions = await Promise.all(promises);
  return maxBy(imgDimensions, iteratee);
};

/**
 * Get the height and width of the image at the provided url
 *
 * @param {string} url
 * @returns {Promise<{ height: number; width: number; }>}
 */
export const getImageDimensions = (url) => {
  if (!url) {
    return Promise.reject("Error occurred reading url. No url provided.");
  }

  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = url;

    img.addEventListener(
      "error",
      () => reject(`Error occurred loading image: ${url}`),
      false
    );

    img.addEventListener(
      "load",
      () => resolve({ height: img.height, width: img.width, url }),
      false
    );
  });
};

export const getFileNameWithoutExtension = (filename) => {
  return filename.substring(0, filename.lastIndexOf("."));
};

export const sanitizeFileName = (filename) => {
  return filename.replace(/[^0-9a-zA-Z_\!\-\.\*'()]/g, ""); // eslint-disable-line no-useless-escape
};

/**
 *
 * @param {*} imgUrl the data url of the image
 * @param {*} maxConstraint the max size in px the image can be in the chosen dimension
 * @param {*} dimension 'width' or 'height'
 * @returns a new data url of a redrawn thumbnail
 */
export const generateImageThumbnailURI = (
  imgUrl,
  maxConstraint,
  dimension = "width"
) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = imgUrl;

    img.addEventListener(
      "error",
      () => reject(`Error processing image: ${imgUrl}`),
      false
    );

    img.addEventListener(
      "load",
      () => {
        const scale =
          img[dimension] > maxConstraint ? maxConstraint / img[dimension] : 1;
        const w = img.width * scale;
        const h = img.height * scale;
        const canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, w, h);

        resolve(canvas.toDataURL("image/jpeg"));
      },
      false
    );
  });
};
