/***
 * TODO: ADAM Make sure no moderation or admin hooks live in here AT ALL
 */
import Firebase from "../firebase";
import {
  omitBy,
  isEqual,
  isEmpty,
  has,
  isArray,
  head,
  isNil,
  compact,
  map,
  get
} from "lodash";
import { isRequestSuccess } from "../utils/general-utils";

const functions = Firebase.functions;

export * from './case.cloud-functions.js';
export * from './comment.cloud-functions.js';
export * from './communications.cloud-functions.js';
export * from './draft.cloud-functions.js';
export * from './feeds.cloud-functions.js';
export * from './quiz.cloud-functions.js';
export * from './search.cloud-functions.js';
export * from './topic.cloud-functions.js';
export * from './user-profile.cloud-functions.js';
export * from './user.cloud-functions.js';

// Moderation Endpoints

export const stateChangeCaseCall = async (caseUuid, state, reason) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationStateChange"
  );
  const result = await callableFn({ caseUuid, state, reason });
  return result.data;
};

export const addNoteCall = async (caseUuid, text) => {
  const callableFn = functions.httpsCallable("backendAdminModerationNote");
  const result = await callableFn({ caseUuid, text });
  return result.data;
};

export const editCaseCall = async (caseUuid, title, caption, suggestEdit) => {
  const callableFn = functions.httpsCallable("backendAdminModerationEdit");
  const result = await callableFn({ caseUuid, title, caption, suggestEdit });
  return result.data;
};

export const editCaseMediaCall = async (formData) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminModerationEditMedia/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  let response = await fetch(postUrl, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return true;
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

export const reviewEditCall = async (caseUuid, editUuid, action) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationReviewEdit"
  );
  const result = await callableFn({ caseUuid, editUuid, action });
  return result.data;
};

export const tineyeCall = async (url) => {
  const callableFn = functions.httpsCallable("backendAdminModerationTineye");
  const result = await callableFn({ url });
  return result.data;
};

export const caseSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationCaseSearch"
  );

  const specialityKey = "specialtyUuid";
  const subSpecialityKey = "subspecialtyUuid";
  const specialtyUuids = [];
  if (has(filters, specialityKey)) {
    specialtyUuids.push(filters[specialityKey]);
  }
  if (has(filters, subSpecialityKey)) {
    specialtyUuids.push(filters[subSpecialityKey]);
  }

  const searchFilter = omitBy(
    isEmpty(specialtyUuids)
      ? filters
      : {
          ...filters,
          specialtyUuids
        },
    (v, key) => isEqual(key, specialityKey) || isEqual(key, subSpecialityKey)
  );

  const result = await callableFn({
    filters: searchFilter,
    page,
    sortDirection,
    size
  });
  return result.data;
};

// Moderation Tagging Endpoints

export const tagStateChangeCaseCall = async (caseUuid, state) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationTagStateChange"
  );
  const result = await callableFn({ caseUuid, state });
  return result.data;
};

export const tagSaveCall = async (
  tagType,
  caseUuid,
  addTags = [],
  removeTags = []
) => {
  const callableFn = functions.httpsCallable("backendAdminModerationTagSave");
  const payload = { tagType, caseUuid };
  if (isArray(addTags) && !isEmpty(addTags)) {
    payload.addTags = addTags;
  }
  if (isArray(removeTags) && !isEmpty(removeTags)) {
    payload.removeTags = removeTags;
  }
  const result = await callableFn(payload);
  return result.data;
};

// Verification Endpoints

export const verificationGeneralNavigationCall = async (
  verificationType,
  verificationStatus,
  filters,
  page = 0
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminVerificationGeneralNavigation"
  );
  const result = await callableFn({
    verificationType,
    verificationStatus,
    filters,
    page
  });
  return result.data;
};

export const verificationSearchRefetchCall = async (
  verificationType,
  filters,
  docId
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminVerificationSearchRefetch"
  );
  const result = await callableFn({
    verificationType,
    filters,
    docId
  });
  return result.data;
};

export const verificationAddNoteCall = async (userUid, userUuid, text) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationNote");
  const result = await callableFn({ userUid, userUuid, text });
  return result.data;
};

export const verificationStateCall = async (userUids, userUuids, state) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationState");
  const userUuid = head(userUuids);
  const result = await callableFn({ userUids, userUuids, userUuid, state });
  return result.data;
};

export const verificationTagCall = async (userUids, userUuids, tagUuids) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationTag");
  const userUuid = head(userUuids);
  const result = await callableFn({ userUids, userUuids, userUuid, tagUuids });
  return result.data;
};

export const verificationTagManageCall = async (actions) => {
  const callableFn = functions.httpsCallable(
    "backendAdminVerificationTagManage"
  );
  const result = await callableFn({ actions });
  return result.data;
};

export const verificationEditCall = async (userUid, formData) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationEdit");
  const result = await callableFn({ ...formData, userUid });
  return result.data;
};

// User Endpoints

export const createUserCall = async (email, firstName, lastName) => {
  const callableFn = functions.httpsCallable("backendAdminUserCreate");
  const result = await callableFn({ email, firstName, lastName });
  return result.data;
};

// Campaign Endpoints

export const campaignSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignSearch");
  const result = await callableFn({
    filters,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const campaignGetCall = async (campaignUuid) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignGet");
  const result = await callableFn({
    campaignUuid
  });
  return result.data;
};

export const campaignCreateCall = async (data) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignCreate");
  const result = await callableFn(data);
  return result.data;
};

export const campaignEditCall = async (campaignUuid, data) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignEdit");
  const result = await callableFn({ ...data, campaignUuid });
  return result.data;
};

export const campaignStateCall = async (campaignUuid, state) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignState");
  const result = await callableFn({ campaignUuid, state });
  return result.data;
};

export const campaignSaveTacticCall = async (data, tacticType = "static") => {
  const callableFn = functions.httpsCallable("backendAdminCampaignTacticSave");
  const media = scrubMediaField(get(data, "media"));
  const converted = media ? { ...data, media } : data;
  const result = await callableFn({ ...converted, tacticType });
  return result.data;
};

const scrubMediaField = (media) => {
  if (isNil(media)) {
    return null;
  }

  return compact(
    map(media, (m) => {
      if (!has(m, "filename")) {
        return null;
      }

      const defaultData = {
        type: "image",
        filename: get(m, "filename"),
        height: get(m, "height"),
        width: get(m, "width")
      };

      if (has(m, "mediaUuid")) {
        defaultData["mediaUuid"] = get(m, "mediaUuid");
      }

      return defaultData;
    })
  );
};

export const campaignImageUploadCall = async (picture) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminCampaignImageUpload/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  const data = new FormData();
  data.append("picture", picture);

  let response = await fetch(postUrl, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return JSON.parse(await response.json());
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

// Case Explorer Endpoints

export const caseExplorerSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable("backendAdminCaseExplorerSearch");

  const result = await callableFn({
    filters: filters,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const postPromotion = async (data) => {
  const callableFn = functions.httpsCallable("backendPostPromotion");
  let body = {
    promotion_name: data.promotion_name,
    channel_uuid: data.channel_uuid,
    promotion_notes: data.promotion_notes,
    promotion_publish_date: data.promotion_publish_date,
    cases: data.cases
  };
  if (data.promotion_uuid) {
    body["promotion_uuid"] = data.promotion_uuid;
  }
  const result = await callableFn(body);
  return result.data;
};

export const getPromotion = async (promotionId) => {
  const callableFn = functions.httpsCallable("backendGetPromotion");
  const result = await callableFn({ promotionId: promotionId });
  return result.data;
};

export const getPromotions = async () => {
  const callableFn = functions.httpsCallable("backendGetPromotions");
  const result = await callableFn({});
  return result.data;
};

export const getPromotionsByCase = async (caseId) => {
  const callableFn = functions.httpsCallable("backendGetPromotionsByCase");
  const result = await callableFn({ caseId: caseId });
  return result.data;
};

export const deletePromotion = async (promotionId) => {
  const callableFn = functions.httpsCallable("backendDeletePromotion");
  const result = await callableFn({ promotionId: promotionId });
  return result.data;
};

export const removeCaseFromPromotion = async (promoId, caseId) => {
  const callableFn = functions.httpsCallable("backendRemoveCaseFromPromotion");
  const result = await callableFn({ promotionId: promoId, caseId: caseId });
  return result.data;
};

export const getPromotionChannels = async () => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerGetChannels"
  );
  const result = await callableFn({});
  return result.data;
};

export const deletePromotionChannelCall = async (channelUuid) => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerDeleteChannel"
  );
  const result = await callableFn({ channelUuid });
  return result.data;
};

export const savePromotionChannelCall = async (channelName) => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerSaveChannel"
  );
  const result = await callableFn({ channelName });
  return result.data;
};

// Activity Center Endpoints

export const markAllReadCall = async (userUid) => {
  const result = await functions.httpsCallable(
    "backendActivityCenterMarkAllRead"
  )({
    user_uid: userUid
  });
  return result;
};
