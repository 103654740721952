import React from "react";
import { ListGroupItem } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import F1FlatList from "../../../components/common/F1FlatList";

function NotificationsLoading() {
  const data = new Array(3).fill();
  return (
    <F1FlatList
      data={data}
      renderItem={({ index }) => (
        <ListGroupItem
          className="d-inline-flex align-items-center justify-content-between px-0"
          key={index}>
          <Skeleton className="mr-3" circle={false} height={75} width={75} />
          <div className="flex-fill">
            <Skeleton count={3} />
          </div>
        </ListGroupItem>
      )}
    />
  );
}

NotificationsLoading.propTypes = {};

export default NotificationsLoading;
