/** @format */

import React from "react";
import PropTypes from "prop-types";
import CaseCarousel from "./CaseCarousel";

/**
 * Component to show multiple cases in a carousel.
 *
 * Optionally supports passing in custom header/footer components.
 */
const CaseCarouselContainer = ({
  cases,
  context,
  imgSize,
  colSize,
  disableClick,
  className,
  innerClassName,
  nextItemVisiblePercent = 0.3,
  headerElement = null,
  footerElement = null
}) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <div className={`card-container feed-card ${className}`}>
      {headerElement}
      <CaseCarousel
        cases={cases}
        context={context}
        imgSize={imgSize}
        colSize={colSize}
        disableClick={disableClick}
        className={innerClassName}
        nextItemVisiblePercent={nextItemVisiblePercent}
      />
      {footerElement}
    </div>
  );
};

CaseCarouselContainer.propTypes = {
  headerElement: PropTypes.node,
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      caseUuid: PropTypes.string,
      title: PropTypes.string,
      media: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string }))
    })
  ),
  footerElement: PropTypes.node,
  context: PropTypes.string
};

export default CaseCarouselContainer;
