import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import i18n from "../../../utils/i18n";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { updateUserProfile } from "../../../actions/user-profiles.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import Regex from "../../../constants/regex";
import FormGlobalError from "./FormGlobalError";
import { trackSelectUsernameExit } from "../../../actions/metrics.actions";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { validateUsername } from "../../../api/user.cloud-functions";
import { isRequestSuccess } from "../../../utils/general-utils";

const SelectUsernameForm = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const onboardingNavigate = useOnboardingNavigation();
  const { handleSubmit, control, errors } = useForm({
    mode: "onChange"
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /** *********************************** HOOKS ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (formData) => {
    setIsLoading(true);
    setErrorMessage("");

    const payload = {
      username: formData.username
    };

    try {
      const usernameCheck = await validateUsername(formData.username);

      if (!isRequestSuccess(usernameCheck)) {
        throw new Error(
          i18n.t("RegistrationScreens.CreateAccountScreens.invalidUsername")
        );
      }

      const result = await dispatch(updateUserProfile(payload));

      if (result.error) {
        throw new Error(result.message);
      } else {
        if (result.error) {
          throw new Error(result.message);
        } else {
          trackSelectUsernameExit(payload.username);
          // route here
          onboardingNavigate(result.user);
        }
      }
    } catch (e) {
      setErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  /** ********************************** RENDER ***************************************/

  const labelColor =
    errors?.username?.type === "required" ||
    errors?.username?.type === "minLength"
      ? "red"
      : "muted";

  return (
    <Card className="p-4 rounded-0">
      <h2 className="mt-1 mb-4 helv-bold text-18">
        {i18n.t("RegistrationScreens.usernamePage.subTitle")}
      </h2>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <FormGlobalError errorText={errorMessage} />

        <FormGroup>
          <Label for="username" className="mb-0">
            {i18n.t("RegistrationScreens.usernamePage.usernameLabel")}
          </Label>
          <Controller
            className="mr-1"
            as={Input}
            control={control}
            id="username"
            name="username"
            type="text"
            placeholder={i18n.t(
              "RegistrationScreens.usernamePage.usernamePlaceholder"
            )}
            rules={{
              required: true,
              minLength: 3,
              pattern: Regex.username
            }}
            invalid={!!errors.username}
          />
          <FormText color={labelColor}>
            {i18n.t("RegistrationScreens.usernamePage.usernameRequired")}
          </FormText>
          {errors?.username?.type === "pattern" && (
            <FormFeedback>
              {i18n.t(
                "RegistrationScreens.usernamePage.usernameRestrictedCharacters"
              )}
            </FormFeedback>
          )}
        </FormGroup>

        <div className="text-center">
          <LoadingButton
            className="w-100 mt-2"
            color="primary"
            loading={isLoading}
            spinnerColor="white"
            size="lg"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.usernamePage.btnSubmit")}
          </LoadingButton>
        </div>
      </form>
    </Card>
  );
};

export default SelectUsernameForm;
