import { truncate } from "lodash";
import React from "react";
import { Button } from "reactstrap";
import i18n from "../../../utils/i18n";

const RejectedPostItem = ({ title }) => {
  return (
    <>
      {i18n.t("ActivityScreen.yourCase")}{" "}
      <span className="text-denim-blue">
        "
        {truncate(title, {
          length: 60
        })}
        "
      </span>{" "}
      {i18n.t("ActivityScreen.requiresEdit")}
      <div className="mt-2">
        <Button color="primary" outline={true}>
          {i18n.t("ActivityScreen.editCaseButton")}
        </Button>
      </div>
    </>
  );
};

export default RejectedPostItem;
