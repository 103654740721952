import React from "react";
import PropTypes from "prop-types";
import CommentDropdown from "./CommentDropdown";
import i18n from "../../utils/i18n";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { StaticStrings } from "../../constants/static-string-constants";
import LoadingButton from "../common/LoadingButton";

const CommentFooter = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();
  let replyEnabled = props?.replyEnabled != null ? props?.replyEnabled : true;
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const handleReplyClick = () => {
    props.onReplyClick();
  };

  const handleEditClick = () => {
    props.onEditClick();
  };

  const gatedHandleReplyClick = useGatedActionGate(
    handleReplyClick,
    GATED_MODAL_TYPE.COMMENT,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.COMMENT_REPLY
  );
  /** ********************************** RENDER ***************************************/

  const replyButton = replyEnabled ? (
    <div
      onClick={gatedHandleReplyClick}
      className={classNames(
        "cursor-pointer",
        theme === THEMES.DARK ? "text-light-gray-blue" : "text-denim-blue"
      )}>
      {i18n.t("comments.replyText")}
    </div>
  ) : (
    <div></div>
  );

  return (
    <div className="d-flex justify-content-between py-2">
      <div className="d-flex justify-content-start align-items-center">
        {replyButton}
        {props.isTranslationEnabled && (
          <>
            {replyEnabled && (
              <span
                className={classNames(
                  "px-2",
                  theme === THEMES.DARK
                    ? "text-light-gray-blue"
                    : "text-denim-blue"
                )}>
                {StaticStrings.middleDot}
              </span>
            )}
            <LoadingButton
              className="text-14 p-0"
              size="sm"
              color="link"
              spinnerColor={
                theme === THEMES.DARK ? "light-gray-blue" : "denim-blue"
              }
              loading={props.isLoadingTranslation}
              disabled={props.isLoadingTranslation}
              onClick={props.onToggleTranslation}>
              {i18n.t(
                props.isViewingTranslation
                  ? "CaseTranslation.seeOriginal"
                  : "CaseTranslation.seeTranslation"
              )}
            </LoadingButton>
          </>
        )}
      </div>
      <CommentDropdown
        caseUuid={props.caseUuid}
        comment={props.comment}
        reportEnabled={props.reportEnabled}
        deleteEnabled={props.deleteEnabled}
        editEnabled={props.editEnabled}
        onEditClick={handleEditClick}
      />
    </div>
  );
};

CommentFooter.propTypes = {
  reportEnabled: PropTypes.bool,
  replyEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  editEnabled: PropTypes.bool,
  onReplyClick: PropTypes.func.isRequired
};

export default CommentFooter;
