import React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";
import { SEARCH_RESULT_TYPES } from "../../constants/search-result-constants";
import { useGetSearchResults } from "../../hooks/search.hooks";
import { SEARCH_PATH } from "../../constants/routes";
import { useHistory } from "react-router";
import { getUrl } from "../../utils/route-utils";

const SearchHeader = ({ searchResultType, searchTerm }) => {
  /** ********************************** CONFIG ***************************************/
  const currentSearchIndex = useSelector((state) => state?.search?.searchIndex);
  const history = useHistory();
  const search = useGetSearchResults();
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  // Uses whatever is currently in the search bar as search term text
  const handleTabSelection = (_searchResultType) => {
    search({
      searchTerm,
      searchResultType: _searchResultType,
      searchIndex: currentSearchIndex,
      isPaginating: true
    });

    history.replace({
      pathname: getUrl(SEARCH_PATH, { tab: _searchResultType }),
      search: searchTerm ? `?s=${searchTerm}` : null
    });
  };

  /** ********************************** RENDER ***************************************/

  return (
    <ButtonGroup className="d-flex" size="lg">
      <Button
        className="rounded-0"
        color={
          searchResultType === SEARCH_RESULT_TYPES.CASE
            ? "primary"
            : "denim-blue-light"
        }
        active={searchResultType === SEARCH_RESULT_TYPES.CASE}
        onClick={() => {
          handleTabSelection(SEARCH_RESULT_TYPES.CASE);
        }}>
        Cases
      </Button>
      <Button
        className="rounded-0"
        color={
          searchResultType === SEARCH_RESULT_TYPES.PROFILE
            ? "primary"
            : "denim-blue-light"
        }
        active={searchResultType === SEARCH_RESULT_TYPES.PROFILE}
        onClick={() => {
          handleTabSelection(SEARCH_RESULT_TYPES.PROFILE);
        }}>
        User profiles
      </Button>
    </ButtonGroup>
  );
};

SearchHeader.propTypes = {
  searchResultType: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired
};

export default SearchHeader;
