/** @format */

import React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import F1Spacer from "../../../components/common/F1Spacer";
import QuizSummaryScoreCard from "../../../components/case/QuizSummaryScoreCard";
import CMSlideBase from "./CMSlideBase";

const CMEPostTestSlide = ({
  questionCount,
  correctCount,
  passingScore,
  failed,
  hasSurvey,
  caseContent
}) => {
  /****************************** Config **************************************/
  const bodyStr = failed
    ? i18n.t("CMECaseScreens.postSummaryFailed")
    : hasSurvey
    ? i18n.t("CMECaseScreens.postSummaryTakeSurvey")
    : i18n.t("CMECaseScreens.postSummaryContinue");

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return (
    <CMSlideBase content={{ media: [{}] }}>
      <Card className="border-0 rounded-0">
        <CardBody className="py-3 bg-lighter-gray text-default-black">
          <QuizSummaryScoreCard
            nbQuestions={questionCount}
            nbRightAnswers={correctCount}
            caseContent={caseContent}
          />
        </CardBody>
        <CardBody className="w-75 mx-auto px-0 pt-4">
          <CardText className="text-default-black text-16 line-height-21 opacity-90 text-center">
            {bodyStr
              // eslint-disable-next-line no-template-curly-in-string
              .replace("${CORRECT_COUNT}", correctCount)
              // eslint-disable-next-line no-template-curly-in-string
              .replace("${TOTAL_COUNT}", questionCount)
              // eslint-disable-next-line no-template-curly-in-string
              .replace("${THRESHOLD}", passingScore)}
          </CardText>
        </CardBody>
        <F1Spacer className="flex-grow-1 h-70px bg-white" />
      </Card>
    </CMSlideBase>
  );
};

CMEPostTestSlide.propTypes = {
  questionCount: PropTypes.number,
  correctCount: PropTypes.number,
  passingScore: PropTypes.number,
  failed: PropTypes.bool,
  hasSurvey: PropTypes.bool
};

export default CMEPostTestSlide;
