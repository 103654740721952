import React from "react";
import PropTypes from "prop-types";
import F1FlatList from "../../../components/common/F1FlatList";
import { DraftProp } from "../../../prop-types/DraftProp";
import DraftListItem from "./DraftListItem";

const DraftsFound = ({ drafts, isProcessing, onDeleteDraft }) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <F1FlatList
      renderItem={({ item }) => (
        <DraftListItem
          key={item.uid}
          draft={item}
          onDelete={onDeleteDraft}
          isProcessing={isProcessing}
        />
      )}
      data={drafts}
    />
  );
};

DraftsFound.propTypes = {
  drafts: PropTypes.arrayOf(DraftProp),
  isProcessing: PropTypes.bool,
  onDeleteDraft: PropTypes.func.isRequired
};

export default DraftsFound;
