import React from "react";
import Chrome from "../../../assets/images/Chrome_browser_logo.png";
import Safari from "../../../assets/images/Safari_browser_logo.png";
import Firefox from "../../../assets/images/Firefox_browser_logo.png";
import "./BrowserBlock.scss";
import i18n from "../../../utils/i18n";

const BrowserBlock = () => {
  /** ********************************** CONFIG ***************************************/
  const heading = i18n.t("browserBlock.heading");
  const description = i18n.t("browserBlock.description");

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="page-wrapper browser-block">
      <div className="page-header w-100 z-fixed bg-white ">
        <div className="d-flex text-center justify-content-center py-3">
          <span className="pl-5"></span>
          <span className="navbar-brand">Figure 1 PRO</span>
        </div>
      </div>
      <div className="page-content">
        <div className="mainContainer bg-gradient">
          <div className="browser-message d-flex text-center justify-content-start bg-white align-items-center">
            <h1 className="heading">{heading}</h1>
            <div className="description text-16">{description}</div>
            <ul className="browser-list">
              <li>
                <img src={Safari} alt="Safari" />
                <div>Safari</div>
              </li>
              <li>
                <img src={Chrome} alt="Chrome" />
                <div>Chrome</div>
              </li>
              <li>
                <img src={Firefox} alt="Firefox" />
                <div>Firefox</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserBlock;
