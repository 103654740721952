import React from "react";
import { connect } from "react-redux";
import {
  handleAuthChange,
  toggleStateListeners
} from "../../actions/login.actions";
import Firebase from "../../firebase";
import LOCAL_STORAGE_CONSTANTS from "../../constants/local-storage-constants";
import { getAndParseLocalStorageItem } from "../../utils/local-storage-utils";
import { trackUserAutoSignIn } from "../../actions/metrics.actions";
import { sendCachedMetrics } from "../../actions/metrics.actions";

const METRICS_INTERVAL = 1000 * 60;

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.handleAuthChange(
        getAndParseLocalStorageItem(LOCAL_STORAGE_CONSTANTS.AUTH_USER)
      );
    }

    state = {
      metricsTimer: null
    };

    componentDidMount() {
      this.listener = Firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (authUser) {
            // If null, we know user just signed in. If not null, user auto-logged in through
            // Firebase's auth persistence
            if (
              getAndParseLocalStorageItem(LOCAL_STORAGE_CONSTANTS.AUTH_USER)
            ) {
              trackUserAutoSignIn();
            }

            localStorage.setItem(
              LOCAL_STORAGE_CONSTANTS.AUTH_USER,
              JSON.stringify(authUser)
            );

            const timer = setInterval(
              this.props.onSendCachedMetrics,
              METRICS_INTERVAL
            );
            this.setState({
              metricsTimer: timer
            });
          } else {
            localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.AUTH_USER);

            if (this.state?.metricsTimer) {
              clearInterval(this.state.metricsTimer);
            }
          }

          this.props.handleAuthChange(authUser);
          this.props.onActivateStateListeners(authUser);
        },
        () => {
          localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.AUTH_USER);
          this.props.onDeactivateStateListeners(null);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    handleAuthChange: (authResult) => {
      dispatch(handleAuthChange(authResult));
    },
    onActivateStateListeners: (authUser) => {
      dispatch(toggleStateListeners(authUser));
    },
    onDeactivateStateListeners: () => {
      dispatch(toggleStateListeners());
      dispatch(sendCachedMetrics());
    },
    onSendCachedMetrics: () => {
      dispatch(sendCachedMetrics());
    }
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
