/** @format */

import PropTypes from "prop-types";

export const MediaProp = PropTypes.shape({
  caseUuid: PropTypes.string,
  contentUuid: PropTypes.string,
  displayOrder: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  width: PropTypes.number
});
