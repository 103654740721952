/** @format */
import {
  RESET_SEARCH_TERMS,
  SEARCH_FEED_COMPLETE,
  UPDATE_SEARCH_TERMS
} from "../actions/search-feed.actions";
import { SIGNOUT_COMPLETE } from "../actions/login.actions";

export const INITIAL_STATE = {
  searchTerms: "",
  currentFeedTypeUuidSearched: null,
  error: null,
  message: null
};

const searchFeedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_COMPLETE:
      return { ...INITIAL_STATE };
    case RESET_SEARCH_TERMS:
      return {
        ...state,
        searchTerms: ""
      };
    case UPDATE_SEARCH_TERMS:
      return {
        ...state,
        searchTerms: action.payload.searchTerms,
        currentFeedTypeUuidSearched: action.payload.feedTypeUuid
      };
    case SEARCH_FEED_COMPLETE:
      return {
        ...state,
        error: action.error,
        message: action.message
      };
    default:
      return state;
  }
};

export default searchFeedReducer;
