import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { get } from "lodash";
import { useSelector } from "react-redux";

const hasSearchResults = (state, searchResultType) => {
  return get(state, `search.searchResults.${searchResultType}`, []).length > 0;
};

const isSearchingEverything = (state) => {
  const searchIndex = state?.search?.searchIndex;
  const everythingFeedUuid =
    state?.userFeedMeta?.everythingFeed?.feed_type_uuid;

  return searchIndex.some((channelUuid) => everythingFeedUuid === channelUuid);
};

function SearchSubHeader({
  searchTerm,
  searchingChannelNames,
  searchResultType,
  onSearchAll
}) {
  const { t } = useTranslation();

  const showSearchButton = useSelector(
    (state) =>
      hasSearchResults(state, searchResultType) && !isSearchingEverything(state)
  );

  return (
    <>
      <p>
        {t("Search.searchingTermIn").replace("REPLACE_SEARCH_TERM", searchTerm)}{" "}
        <b>{searchingChannelNames}</b>
      </p>
      {showSearchButton && (
        <Button block size="lg" color="secondary" onClick={() => onSearchAll()}>
          {t("Search.searchAllOfFigure1")}
        </Button>
      )}
    </>
  );
}

SearchSubHeader.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  searchingChannelNames: PropTypes.string.isRequired,
  searchResultType: PropTypes.string.isRequired,
  onSearchAll: PropTypes.func.isRequired,
};

export default SearchSubHeader;
