import React from "react";
import PropTypes from "prop-types";
import { ModalBody } from "reactstrap";
import classNames from "../../utils/class-names-utils";

const BaseDialogBody = ({ center, children }) => {
  return (
    <ModalBody
      className={classNames(
        "d-flex flex-column mx-4 text-16",
        center
          ? "justify-content-center align-items-center text-center"
          : "justify-content-start align-items-start"
      )}>
      {children}
    </ModalBody>
  );
};

BaseDialogBody.propTypes = {
  center: PropTypes.bool
};

BaseDialogBody.defaultProps = {
  center: false
};

export default BaseDialogBody;
