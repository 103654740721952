import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { trim } from "lodash";
import ProfileSectionHeaderCard from "./ProfileSectionHeaderCard";

const ProfileCaseCommentHeaderCard = ({
  isCurrentUser,
  profile,
  className
}) => {
  /** ********************************** CONFIG ***************************************/
  const { t } = useTranslation();
  const name = trim(profile?.displayName) || trim(profile?.username) || "";

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <ProfileSectionHeaderCard className={className}>
      {profile._isInstitutional
        ? profile?.caseFeedTitle ||
          t("Profile.sponsoredPostsAndComments", { name })
        : isCurrentUser
        ? t("Profile.yourPostsAndComments")
        : t("Profile.userPostsAndComments", { name })}
    </ProfileSectionHeaderCard>
  );
};

ProfileCaseCommentHeaderCard.propTypes = {
  isCurrentUser: PropTypes.bool,
  profile: PropTypes.shape({
    displayName: PropTypes.string,
    username: PropTypes.string
  })
};

export default ProfileCaseCommentHeaderCard;
