import { find, get, isEqual, size, toLower, toUpper } from "lodash";

export const VERIFICATION_TYPES = {
  NPI: { statsKey: "npi", displayName: "NPI" },
  LICENSE: {
    statsKey: "license",
    displayName: "Medical License"
  },
  PHOTO: {
    statsKey: "photo",
    displayName: "Photo ID"
  }
};

export const VERIFICATION_TABS = {
  INFO: { tabName: "info", displayName: "Verification Info" },
  NOTES: {
    tabName: "notes",
    displayName: "Notes"
  },
  HISTORY: {
    tabName: "history",
    displayName: "History"
  }
};

export const VERIFICATION_STATUSES = {
  PENDING_MANUAL_VERIFICATION: {
    displayName: "Pending",
    state: "pending_manual_verification"
  },
  INFO_NEEDED: { displayName: "Info Needed", state: "info_needed" },
  UNVERIFIABLE: { displayName: "Unverifiable", state: "unverifiable" },
  VERIFIED: { displayName: "Verified", state: "verified" },
  UPDATED_INFO: { displayName: "Updated Info", state: "updated_info" }
};

export const VERIFICATION_TAG_COLOR = {
  ID_REQUIRED: { tagName: "ID required", colorClassName: "primary" },
  INVALID_PHOTO: { tagName: "Invalid photo", colorClassName: "secondary" },
  LICENSE_NOT_MATCH: {
    tagName: "License not a match",
    colorClassName: "success"
  },
  PROFESSION_NOT_MATCH: {
    tagName: "Profession not a match",
    colorClassName: "danger"
  },
  SPECIALTY_NOT_MATCH: {
    tagName: "Specialty not a match",
    colorClassName: "warning"
  },
  NOT_IN_DB: { tagName: "Not in db", colorClassName: "info" }
};

export const convertVerificationStatus = (verificationStatus) => {
  return (
    VERIFICATION_STATUSES[toUpper(verificationStatus)] ||
    VERIFICATION_STATUSES.PENDING_MANUAL_VERIFICATION
  );
};

export const getDisplayName = (user) => {
  return `${get(user, "firstName")} ${get(user, "lastName")}`;
};

export const getUserNumber = (user, verificationType) => {
  switch (verificationType) {
    case VERIFICATION_TYPES.NPI.statsKey:
      const npi = get(user, "npiNumber");
      return npi ? `#${npi}` : "";
    case VERIFICATION_TYPES.LICENSE.statsKey:
      const license = get(user, "license.licenseNumber");
      return license ? `#${license}` : "";
    case VERIFICATION_TYPES.PHOTO.statsKey:
    default:
      return "";
  }
};

export const isNpiUser = (verificationType) => {
  return VERIFICATION_TYPES.NPI.statsKey === verificationType;
};

export const isLicenseUser = (verificationType) => {
  return VERIFICATION_TYPES.LICENSE.statsKey === verificationType;
};

export const getProfession = (user) => {
  return find(get(user, "public.specialties", []), (f) => f.depth === 0);
};

export const getSpeciality = (user) => {
  return find(get(user, "public.specialties", []), (f) => f.depth === 1);
};

export const getVerificationTagColor = (tagName) => {
  const lowerTagName = toLower(tagName);
  const config = find(VERIFICATION_TAG_COLOR, (o) =>
    isEqual(toLower(o.tagName), lowerTagName)
  );

  return config ? config.colorClassName : "info";
};

export const getUsersRefreshPage = (currentPage, users) => {
  if (size(users) > 1 || currentPage === 0) {
    return currentPage;
  }

  return currentPage - 1;
};

export const userIsVerified = (user) => {
  return (
    user?.verification?.verificationStatus ===
    VERIFICATION_STATUSES.VERIFIED.state
  );
};
