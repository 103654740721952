/** @format */

export const StaticStrings = {
  usa: "United States",
  canada: "Canada",
  supportEmail: "support@figure1.com",
  loginSupportEmail: "support@figure1.com?subject=Login%20Help",
  privacyLink: "https://www.figure1.com/pro-privacy",
  termsOfServiceUrl: "https://www.figure1.com/pro-terms-of-service",
  communityGuidelines: "https://www.figure1.com/pro-community-guidelines",
  clinicalCases: "https://www.figure1.com/resources/clinical-cases",
  contentPolicy: "https://www.figure1.com/content-policy",
  npiRegistry: "https://npiregistry.cms.hhs.gov/",
  feedback: "https://www.figure1.com/resources/faqs/feedback/feedback/",
  aboutFigure1: "https://www.figure1.com/about/",
  appPromptDeepLink:
    "https://figure1.onelink.me/dtxs/8f1a0c2/?deep_link_value=https://app.figure1.com",
  middleDot: "\u2022"
};
