/** @format */

import { fetchSearchSuggestions } from "../utils/autocomplete-utils";

const actionsPrefix = "elasticSearch";

export const FETCH_SEARCH_SUGGESTIONS = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS`;
export const FETCH_SEARCH_SUGGESTIONS_COMPLETE = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS_COMPLETE`;
export const FETCH_SEARCH_SUGGESTIONS_ERROR = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS_ERROR`;
export const CLEAR_SEARCH_SUGGESTIONS = `${actionsPrefix}/CLEAR_SEARCH_SUGGESTIONS`;

export const getSearchSuggestions = (searchTerms) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_SEARCH_SUGGESTIONS });

    const {
      elasticsearchAuth,
      elasticsearchCloudId
    } = getState()?.configuration?.elasticSearch;

    try {
      const results = await fetchSearchSuggestions({
        accessToken: elasticsearchAuth?.access_token,
        cloudId: elasticsearchCloudId,
        searchTerms
      });

      if (results.timed_out || results.error) {
        throw results.error || new Error("Finding search results timed out.");
      }

      dispatch({
        type: FETCH_SEARCH_SUGGESTIONS_COMPLETE,
        payload: {
          data: results
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_SEARCH_SUGGESTIONS_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

export const clearSearchSuggestions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_SUGGESTIONS
    });
  };
};
