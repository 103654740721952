/** @format */

export const PROFESSION_CATEGORY_LABELS = {
  OTHER_HCP: "otherhcp",
  OTHER_STUDENT: "otherstudent",
  MEDICAL_STUDENT: "medicalstudent",
  NON_HCP: "non-healthcareprofessional",
  // The 3 below are only from Legacy Migration
  LEGACY_OTHER_HEALTHCARE_PROFESSIONAL: "otherhealthcareprofessional",
  LEGACY_NURSING_PROFESSIONAL: "nursingprofessional",
  LEGACY_OTHER_NON_HCP_STUDENT: "othernon-hcpstudent"
};

export const LEGACY_ONLY_PROFESSIONS = [
  PROFESSION_CATEGORY_LABELS.LEGACY_OTHER_HEALTHCARE_PROFESSIONAL,
  PROFESSION_CATEGORY_LABELS.LEGACY_NURSING_PROFESSIONAL,
  PROFESSION_CATEGORY_LABELS.LEGACY_OTHER_NON_HCP_STUDENT
];

// Force users to fill out entire legacy form
export const NO_SPECIALTY_LEGACY_PROFESSION_CATEGORY_LABELS = [
  PROFESSION_CATEGORY_LABELS.LEGACY_OTHER_HEALTHCARE_PROFESSIONAL,
  PROFESSION_CATEGORY_LABELS.LEGACY_NURSING_PROFESSIONAL
];

export const STUDENT_CATEGORY_LABELS = [
  PROFESSION_CATEGORY_LABELS.MEDICAL_STUDENT,
  PROFESSION_CATEGORY_LABELS.OTHER_STUDENT
];

export const OTHER_PROFESSION_CATEGORY_LABELS = [
  PROFESSION_CATEGORY_LABELS.OTHER_HCP,
  PROFESSION_CATEGORY_LABELS.LEGACY_OTHER_HEALTHCARE_PROFESSIONAL,
  PROFESSION_CATEGORY_LABELS.OTHER_STUDENT
];
