import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { SEARCH_RESULT_TYPES } from "../../constants/search-result-constants";
import { useGetSearchResults } from "../../hooks/search.hooks";
import i18n from "../../utils/i18n";
import SearchHeader from "./SearchHeader";
import SearchSubHeader from "./SearchSubHeader";
import SearchResultsEmpty from "./SearchResultsEmpty";
import SearchResultsFound from "./SearchResultsFound";
import SearchResultsLoading from "./SearchResultsLoading";
import {
  useSelectSearchResultType,
  useSelectSearchTerm
} from "../../selectors/search.selectors";
import { resetSearch, SET_SEARCH_CRITERIA } from "../../actions/search.actions";
import { useLocation, useParams } from "react-router-dom";

const formatCases = (data) =>
  data.map((raw) => ({
    image: raw?.media?.[0]?.url,
    type: raw?.media?.[0]?.type,
    title: raw?.title,
    summary: raw?.caption,
    caseUuid: raw?.caseUuid,
    caseType: raw?.caseType
  }));

const formatProfiles = (data, currentUsersFollowedUserUuids) =>
  data.map((raw) => ({
    image: raw?.avatar,
    username: raw?.username,
    displayName: raw?.displayName,
    profileDisplayName: raw?.profileDisplayName,
    isFollowing: currentUsersFollowedUserUuids.includes(raw?.userUuid),
    userUuid: raw?.userUuid
  }));

const SearchPage = () => {
  /** ********************************** CONFIG ***************************************/
  const { tab } = useParams();
  const searchResultType = useSelectSearchResultType();
  const activeSearchResultType = tab || searchResultType;

  const searchTerm = useSelectSearchTerm();
  const [isPaginating, setIsPaginating] = useState(false);
  const dispatch = useDispatch();
  const search = useGetSearchResults();

  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const urlSearchValue = urlParams.get("s");

  const followedUserUuids = useSelector((state) =>
    (state?.user?.following || []).map((followedUser) => followedUser.userUuid)
  );
  const everythingFeedUuid = useSelector(
    (state) => state?.userFeedMeta?.everythingFeed?.feed_type_uuid
  );

  const isLoading = useSelector((state) => state?.search?.isLoading);
  const canSearchMore = useSelector((state) => state?.search?.canSearchMore);

  const results = useSelector((state) => {
    const rawResults = state?.search?.searchResults?.[activeSearchResultType];

    if (isEmpty(rawResults)) {
      return [];
    } else if (activeSearchResultType === SEARCH_RESULT_TYPES.CASE) {
      return formatCases(rawResults);
    } else if (activeSearchResultType === SEARCH_RESULT_TYPES.PROFILE) {
      return formatProfiles(rawResults, followedUserUuids);
    }
  });

  const currentSearchIndex = useSelector((state) => state?.search?.searchIndex);

  const searchingChannelNames = useSelector((state) => {
    const topics = state?.topics?.data || [];
    const everythingFeed = state?.userFeedMeta?.everythingFeed;
    const recommendedForYouFeed = state?.userFeedMeta?.madeForYouFeed;
    const searchIndex = state?.search?.searchIndex;

    return searchIndex
      .map((channelUuid) => {
        let channelName = "";

        if (everythingFeed.feed_type_uuid === channelUuid) {
          channelName = i18n.t("Search.allOfFigure1");
        } else if (recommendedForYouFeed.feed_type_uuid === channelUuid) {
          channelName = recommendedForYouFeed.feed_name;
        } else {
          channelName = topics.find(
            (topic) => topic.uuid === channelUuid
          )?.name;
        }

        return channelName;
      })
      .join(", .");
  });

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!searchTerm && urlSearchValue) {
      dispatch({
        type: SET_SEARCH_CRITERIA,
        payload: {
          searchTerm: urlSearchValue,
          searchIndex: currentSearchIndex.length
            ? currentSearchIndex
            : [everythingFeedUuid],
          searchResultType: activeSearchResultType
        }
      });

      search({
        searchTerm: urlSearchValue,
        searchResultType: activeSearchResultType,
        searchIndex: currentSearchIndex.length
          ? currentSearchIndex
          : [everythingFeedUuid],
        isPaginating: true
      });
    }
  }, [
    dispatch,
    search,
    searchTerm,
    urlSearchValue,
    currentSearchIndex,
    everythingFeedUuid,
    tab
  ]);

  /** ********************************* FUNCTIONS *************************************/

  const onSearchAll = () => {
    search({
      searchTerm,
      searchResultType: activeSearchResultType
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetSearch());
    };
  }, [dispatch]);
  /** ********************************** RENDER ***************************************/

  let isFetchingNewResults = isLoading && !isPaginating;

  return (
    <div>
      <SearchHeader
        searchResultType={activeSearchResultType}
        searchTerm={searchTerm}
      />
      {
        <Card className="border-0 rounded-0">
          <CardBody>
            <CardTitle className="text-15">
              <SearchSubHeader
                searchTerm={searchTerm}
                searchingChannelNames={searchingChannelNames}
                searchResultType={activeSearchResultType}
                onSearchAll={onSearchAll}
              />
            </CardTitle>
            <Card className="border-0">
              {isFetchingNewResults ? (
                <SearchResultsLoading
                  searchResultType={activeSearchResultType}
                />
              ) : isEmpty(results) ? (
                <SearchResultsEmpty
                  searchingChannelNames={searchingChannelNames}
                  onSearchAll={onSearchAll}
                />
              ) : (
                <SearchResultsFound
                  results={results}
                  searchResultType={activeSearchResultType}
                  setIsPaginating={setIsPaginating}
                  search={search}
                  isLoading={isLoading}
                  currentSearchIndex={currentSearchIndex}
                  searchTerm={searchTerm}
                  canSearchMore={canSearchMore}
                />
              )}
            </Card>
          </CardBody>
        </Card>
      }
    </div>
  );
};

export default SearchPage;
