/** @format */

import Firebase from "../firebase";
import { CASE_ACTION } from "../constants/case-constants";

const functions = Firebase.functions;

export const submitCaseReaction = async (caseId, reaction, isOn) => {
  const result = await functions.httpsCallable("backendCaseReaction")({
    case_uuid: caseId,
    reaction: reaction,
    value: isOn
  });
  return result.data;
};

export const saveCase = async (caseId, isSave) => {
  return submitCaseAction(
    caseId,
    isSave ? CASE_ACTION.SAVE : CASE_ACTION.UNSAVE
  );
};

export const reportCase = async (caseId, reason) => {
  return submitCaseAction(caseId, CASE_ACTION.REPORT, reason);
};

export const submitCaseAction = async (caseId, action, value) => {
  const result = await functions.httpsCallable("backendCaseAction")({
    case_uuid: caseId,
    action: action,
    value: value
  });
  return result.data;
};

export const triggerCaseSyncCall = async (caseUuid) => {
  const result = await functions.httpsCallable("backendCaseTriggerUpdate")({
    caseUuid: caseUuid
  });
  return result.data;
};

export const submitCaseUpdateCall = async (caseUuid, text, resolved) => {
  const result = await functions.httpsCallable("backendCaseAddUpdate")({
    caseUuid: caseUuid,
    text: text,
    resolved: resolved
  });
  return result.data;
};

export const updateCaseProgressCall = async (
  caseUuid,
  contentPosition,
  isComplete
) => {
  const result = await functions.httpsCallable("backendCaseProgressUpdate")({
    caseUuid: caseUuid,
    contentPosition: contentPosition,
    isComplete: isComplete
  });
  return result.data;
};
