import React from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import figure1Logo from "../../assets/images/Fig.1 app icon_60px.png";
import i18n from "../../utils/i18n";

const AppPromptFull = React.forwardRef(function AppPromptFull(
  { appUrl, onDecline, onOpenApp },
  ref
) {
  return (
    <Card
      className="app-prompt-full text-center w-100 rounded-0 border-0"
      innerRef={ref}>
      <CardBody className="pt-4 px-4">
        <img
          className="rounded-sm"
          src={figure1Logo}
          alt="Figure 1"
          height={50}
          width={50}
        />
      </CardBody>
      <CardBody className="pt-0 px-4 text-default-black">
        <CardTitle tag="h5" className="line-height-snug">
          {i18n.t("AppPrompt.largePrompt.title")}
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 mt-n1 line-height-snug">
          {i18n.t("AppPrompt.largePrompt.subtitle")}
        </CardSubtitle>
      </CardBody>
      <CardBody className="pt-0 px-4">
        <Button
          tag="a"
          href={appUrl}
          color="denim-blue"
          size="lg"
          block
          onClick={onOpenApp}>
          {i18n.t("AppPrompt.btnConfirm")}
        </Button>
        <Button
          className="btn-text font-weight-bold mt-3"
          color="denim-blue"
          size="lg"
          block
          onClick={onDecline}>
          {i18n.t("AppPrompt.btnDecline")}
        </Button>
      </CardBody>
    </Card>
  );
});

export default AppPromptFull;
