import React from "react";
import { Card, CardHeader } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "../../../utils/class-names-utils";

const ProfileSectionHeaderCard = ({ className, children }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card
      className={classNames(
        "profile-section-header sticky-top border-0 rounded-0",
        className
      )}>
      <CardHeader
        className="bg-denim-blue text-white font-weight-500 text-12 text-uppercase py-2 rounded-0 border-0"
        style={{
          letterSpacing: "0.0625rem",
          lineHeight: "normal"
        }}>
        {children}
      </CardHeader>
    </Card>
  );
};

ProfileSectionHeaderCard.propTypes = {
  className: PropTypes.string
};

export default ProfileSectionHeaderCard;
