/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";

const F1CarouselControlButton = ({
  direction,
  directionText,
  onClickHandler,
  buttonColor,
  iconColor,
  className,
  disabled = false
}) => {
  return (
    <Button
      className={classNames("rounded-circle p-0", className)}
      color={buttonColor}
      onClick={onClickHandler}
      disabled={disabled}>
      <CustomIcon
        className={classNames(
          `icon-${iconColor} m-2`,
          direction === "prev" ? "rotate-180" : null
        )}
        icon="drawer_chevron"
        size={16}
      />
      <span className="sr-only">{directionText || direction}</span>
    </Button>
  );
};

F1CarouselControlButton.propTypes = {
  direction: PropTypes.oneOf(["prev", "next"]).isRequired,
  directionText: PropTypes.string,
  onClickHandler: PropTypes.func,
  buttonColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default F1CarouselControlButton;
