/** @format */

import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { getImageUrl } from "../../../utils/media-utils";

const BACKGROUND_IMG_MAX_WIDTH = 1024;
const BACKGROUND_IMG_HEIGHT_PERCENTAGE = 0.3;

const BackgroundImage = ({ profileImage }) => {
  const width = Math.min(window.innerWidth, BACKGROUND_IMG_MAX_WIDTH);
  const height = width * BACKGROUND_IMG_HEIGHT_PERCENTAGE;

  const avatarUrl = getImageUrl(
    { type: "image", url: profileImage },
    width,
    height,
    "crop"
  );

  return isEmpty(avatarUrl) ? null : <img src={avatarUrl} alt="" />;
};

const ProfileImageBackground = ({ avatar, isInstitutional }) => {
  if (!isInstitutional || !avatar) {
    return null;
  }

  return (
    <div className="profile-img-bg">
      <BackgroundImage profileImage={avatar} />
    </div>
  );
};

ProfileImageBackground.propTypes = {
  avatar: PropTypes.string,
  isInstitutional: PropTypes.bool
};

export default ProfileImageBackground;
