import mixpanel from "mixpanel-browser";
import AppConfig from "../AppConfig";
import getQueryParam from "../utils/getQueryParam";

// TODO: Implement mixpanel methods once we get around to implementing the various metrics
const MixpanelMetrics = {
  setup: async () => {
    try {
      const token = AppConfig.mixpanelToken;
      return await mixpanel.init(token);
    } catch (e) {
      console.warn(e.message);
    }
  },
  toggleTracking: (enabled) => {
    if (enabled) {
      mixpanel.opt_in_tracking();
    } else {
      mixpanel.opt_out_tracking();
    }
  },
  track: (eventName, payload) => {
    try {
      mixpanel.track(eventName, payload);
    } catch (error) {
      console.log(eventName, error.message);
    }
  },
  updateUser: (user) => {
    try {
      mixpanel.identify(user.userUuid);
      mixpanel.people.set(user);
    } catch (error) {
      console.log(error.message);
    }
  },
  updateUserProperties: (payload) => {
    try {
      mixpanel.people.set(payload);
    } catch (error) {
      console.log(error.message);
    }
  },
  setSessionProperty: (payload) => {
    try {
      mixpanel.register(payload);
    } catch (error) {
      console.log(error.message);
    }
  },
  // doesn't work in mobile, will it work here?
  userIncrement: (key, amount) => {
    try {
      mixpanel.people.increment(key, amount);
    } catch (error) {
      console.log(error.message);
    }
  },
  registerCampaignParams: () => {
    try {
      const campaign_keywords =
          "utm_source utm_medium utm_campaign utm_content utm_term".split(" "),
        params = {
          "utm_source [last touch]": null,
          "utm_medium [last touch]": null,
          "utm_campaign [last touch]": null,
          "utm_content [last touch]": null,
          "utm_term [last touch]": null
        };

      let index,
        keysFound = false,
        kw = "";

      for (index = 0; index < campaign_keywords.length; ++index) {
        kw = getQueryParam(document.URL, campaign_keywords[index]);
        if (kw.length) {
          keysFound = true;
          params[campaign_keywords[index] + " [last touch]"] = kw;
        }
      }

      if (keysFound) {
        MixpanelMetrics.setSessionProperty(params);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
};

export default MixpanelMetrics;
