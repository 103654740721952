import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { SEARCH_RESULT_TYPES } from "../../constants/search-result-constants";
import F1FlatList from "../../components/common/F1FlatList";
import { PROFILE_DETAIL } from "../../constants/routes";
import SearchCaseResultItem from "./components/SearchCaseResultItem";
import SearchProfileResultItem from "./components/SearchProfileResultItem";
import { getUrl } from "../../utils/route-utils";
import { getCaseTypeUrl } from "../../utils/case-utils";

function SearchResultsFound({
  results,
  searchResultType,
  setIsPaginating,
  search,
  isLoading,
  currentSearchIndex,
  searchTerm,
  canSearchMore
}) {
  const history = useHistory();

  return (
    <F1FlatList
      data={results}
      renderItem={({ item }) =>
        searchResultType === SEARCH_RESULT_TYPES.CASE ? (
          <SearchCaseResultItem
            key={`case-search-result-item-${item.caseUuid}`}
            {...item}
            onClick={() => {
              history.push(
                getUrl(getCaseTypeUrl(item), { caseId: item.caseUuid })
              );
            }}
          />
        ) : (
          <SearchProfileResultItem
            key={`profile-search-result-item-${item.userUuid}`}
            {...item}
            onClick={() => {
              history.push(getUrl(PROFILE_DETAIL, { userUuid: item.userUuid }));
            }}
          />
        )
      }
      ListFooterComponent={() =>
        canSearchMore && (
          <Button
            className="rounded mt-3"
            block
            size="lg"
            color="secondary"
            disabled={isLoading}
            onClick={() => {
              if (!isLoading) {
                // Note: Does not show loading indicator
                search(
                  {
                    searchTerm,
                    searchResultType,
                    searchIndex: currentSearchIndex,
                    isPaginating: true
                  },
                  () => {
                    setIsPaginating(false);
                  }
                );

                setIsPaginating(true);
              }
            }}>
            {isLoading ? <Spinner size="sm" /> : "View more"}
          </Button>
        )
      }
    />
  );
}

SearchResultsFound.propTypes = {
  results: PropTypes.array,
  searchResultType: PropTypes.string,
  setIsPaginating: PropTypes.func,
  search: PropTypes.func,
  isLoading: PropTypes.bool,
  currentSearchIndex: PropTypes.array,
  searchTerm: PropTypes.string,
  canSearchMore: PropTypes.bool
};

export default SearchResultsFound;
