/** @format */

import { get } from "lodash";
import { loadCommunicationPreferenceGroupings } from "../db/communication-preference-groups.db";
import {
  fetchUserCommunicationPreferences,
  listenForCommunicationPreferenceChanges
} from "../db/user-preferences.db";
import {
  updateEmailPreferenceCall,
  updateUserPreference
} from "../api/communications.cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "preferencesCenter";

export const LOAD_SYSTEM_COMMUNICATION_TYPES = `${actionsPrefix}/LOAD_SYSTEM_COMMUNICATION_TYPES`;
export const RESET_USER_PREFERENCES = `${actionsPrefix}/RESET_USER_PREFERENCES`;
export const LOAD_USER_PREFERENCES = `${actionsPrefix}/LOAD_USER_PREFERENCES`;
export const LOAD_USER_PREFERENCES_COMPLETE = `${actionsPrefix}/LOAD_USER_PREFERENCES_COMPLETE`;
export const UPDATE_USER_PREFERENCE = `${actionsPrefix}/UPDATE_USER_PREFERENCE`;
export const UPDATE_USER_PREFERENCE_COMPLETE = `${actionsPrefix}/UPDATE_USER_PREFERENCE_COMPLETE`;
export const USER_PREFERENCES_UPDATED = `${actionsPrefix}/USER_PREFERENCES_UPDATED`;
export const USER_PREFERENCES_LISTENER_ON = `${actionsPrefix}/USER_PREFERENCES_LISTENER_ON`;
export const USER_PREFERENCES_LISTENER_OFF = `${actionsPrefix}/USER_PREFERENCES_LISTENER_OFF`;
export const UPDATE_EMAIL_PREFERENCE = `${actionsPrefix}/UPDATE_EMAIL_PREFERENCE`;
export const UPDATE_EMAIL_PREFERENCE_COMPLETE = `${actionsPrefix}/UPDATE_EMAIL_PREFERENCE_COMPLETE`;

export const fetchUserPreferences = (userUid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_USER_PREFERENCES
    });
    try {
      const groupings = await loadCommunicationPreferenceGroupings();
      const userPreferences = await fetchUserCommunicationPreferences(userUid);

      dispatch({
        type: LOAD_USER_PREFERENCES_COMPLETE,
        userPreferences,
        groupings
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleListenForUserPreferences = (userUid) => {
  const preferencesUpdated = (dispatch) => (querySnapshot) => {
    if (querySnapshot) {
      const userPreferences = querySnapshot.data();
      dispatch({
        type: USER_PREFERENCES_UPDATED,
        userPreferences
      });
    } else {
      console.log(`No preferences were updated in this invocation ${userUid}`);
    }
  };

  return async (dispatch, getState) => {
    if (userUid) {
      if (
        getState().userCommunicationsPreferences &&
        !getState().userCommunicationsPreferences.listener
      ) {
        const listener = await listenForCommunicationPreferenceChanges(
          preferencesUpdated(dispatch),
          userUid
        );

        return dispatch({
          type: USER_PREFERENCES_LISTENER_ON,
          payload: { listener }
        });
      }
    } else {
      if (
        getState().userCommunicationsPreferences &&
        getState().userCommunicationsPreferences.listener
      ) {
        getState().userCommunicationsPreferences.listener();
      }
      return {
        type: USER_PREFERENCES_LISTENER_OFF
      };
    }
  };
};

export const updateNotificationPreference = (activity) => {
  return async (dispatch) => {
    try {
      const result = updateUserPreference(activity);
      console.log(
        `Preference updated for activity=${activity} with result=${result}`
      );
      dispatch({
        type: UPDATE_USER_PREFERENCE_COMPLETE
      });
    } catch (error) {
      console.log(
        `There was an error updating preferences for activity ${activity}`,
        error
      );
    }
  };
};

export const updateEmailPreference = (pref, on) => {
  return async (dispatch, getState) => {
    const updateLocalState = (onFailed) => {
      const userPreferences = getState().userCommunicationsPreferences
        .userPreferences;
      const emailPref = get(
        userPreferences,
        ["emailPreferences", pref.emailUuid],
        {}
      );
      emailPref.enabled = onFailed ? !on : on;
      dispatch({
        type: USER_PREFERENCES_UPDATED,
        userPreferences: { ...userPreferences }
      });
    };

    try {
      dispatch({
        type: UPDATE_EMAIL_PREFERENCE
      });

      // update email pref state optimistically
      updateLocalState(false);

      const result = await updateEmailPreferenceCall(pref, on);
      if (isRequestSuccess(result)) {
        return dispatch({
          type: UPDATE_EMAIL_PREFERENCE_COMPLETE
        });
      } else {
        throw new Error("failed to update email pref due to server error");
      }
    } catch (error) {
      updateLocalState(true);

      return dispatch({
        type: UPDATE_EMAIL_PREFERENCE_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};
