/** @format */
import {
  refreshMediaUploadUrlCall,
  uploadMediaToAWS
} from "../api/mediaUpload.cloud-functions";
import UploadImageType from "../constants/uploadImageType.constants";
import { sanitizeFileName } from "../utils/media-utils";

const actionsPrefix = "imageUpload";

export const MEDIA_UPDATED = `${actionsPrefix}/MEDIA_UPDATED`;
export const UPLOAD_MEDIA = `${actionsPrefix}/UPLOAD_MEDIA`;
export const UPLOAD_MEDIA_COMPLETE = `${actionsPrefix}/UPLOAD_MEDIA_COMPLETE`;
export const GET_MEDIA_UPLOAD_LOCATION_START = `${actionsPrefix}/GET_MEDIA_UPLOAD_LOCATION_START`;
export const GET_MEDIA_UPLOAD_LOCATION_COMPLETE = `${actionsPrefix}/GET_MEDIA_UPLOAD_LOCATION_COMPLETE`;

export const getMediaUploadLocation = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MEDIA_UPLOAD_LOCATION_START
      });
      const result = await refreshMediaUploadUrlCall(params);
      return dispatch({
        type: GET_MEDIA_UPLOAD_LOCATION_COMPLETE,
        payload: { awsResponse: result?.data?.store }
      });
    } catch (error) {
      dispatch({
        type: GET_MEDIA_UPLOAD_LOCATION_COMPLETE,
        payload: { error }
      });
      throw error;
    }
  };
};

export const getVerificationUploadLocation = () => {
  return async (dispatch) => {
    return dispatch(
      getMediaUploadLocation({ uploadType: UploadImageType.VERIFICATION })
    );
  };
};

export const uploadMedia = ({ mediaList, awsFields, uploadType }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPLOAD_MEDIA
      });

      for (let idx = 0; idx < mediaList.length; idx++) {
        const media = mediaList[idx];
        if (media.uploaded) {
          continue;
        }

        await createAWSFormAndUpdateFile(media, awsFields);
        mediaList[idx].uploaded = true;
      }

      return dispatch({
        type: UPLOAD_MEDIA_COMPLETE
      });
    } catch (error) {
      return dispatch({
        type: UPLOAD_MEDIA_COMPLETE,
        error
      });
    } finally {
      // This allows the user to see their uploaded images while waiting for processing.
      // Moved this to the finally so that even if uploading fails part way through, we can keep
      // what items did successfully upload and prevent duplicates on retry -- Corey
      dispatch({
        type: MEDIA_UPDATED,
        payload: {
          media: [...mediaList],
          type: uploadType
        }
      });
    }
  };
};

const createAWSFormAndUpdateFile = async (media, awsFields) => {
  if (!awsFields || !awsFields.fields) {
    throw new Error("AWS upload values must be passed");
  }

  const data = new FormData();
  Object.keys(awsFields.fields).forEach((key) => {
    data.append(key, awsFields.fields[key]);
  });

  data.append("file", media, sanitizeFileName(media.name));

  return uploadMediaToAWS(awsFields.url, data);
};
