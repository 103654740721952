import React from "react";
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";

export const ICON_ROTATION = {
  r90: "90deg",
  r180: "180deg",
  r270: "270deg"
};

// rotation can be any number, though the exported const above is for easy use -- Corey
const CustomIcon = ({
  className,
  icon,
  color,
  size = 30,
  rotation = null,
  style = {}
}) => {
  let compiledStyle = { ...style };
  if (rotation) {
    compiledStyle = { transform: `rotate(${rotation})` };
  }

  return (
    <IcomoonReact
      className={className ? `${className} custom-icon` : "custom-icon"}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={compiledStyle}
    />
  );
};

export default CustomIcon;
