/** @format */

import { SUBMIT_CASE_REACTION } from "../actions/case.actions";
import { cloneDeep } from "lodash";

import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain/";

const FeedsReducer = (
  state = InitialReducerStates.FEEDS_INITIAL_STATE,
  action
) => {
  // Do everything in base first, and then anything custom after
  const base = { ...BrainReducers.FeedsReducer(state, action) };

  switch (action.type) {
    case SUBMIT_CASE_REACTION:
      let newFeedList = cloneDeep(base.feedList);
      Object.keys(newFeedList).forEach((feedId) => {
        const feed = newFeedList[feedId];

        feed.feed.forEach((feedItem) => {
          if (feedItem.caseUuid === action.caseUuid) {
            let newAllReactionItem =
              feedItem.allReactions?.[action.reaction] || 0;

            newAllReactionItem = newAllReactionItem + (action.isOn ? 1 : -1);
            feedItem.allReactions = {
              ...feedItem.allReactions,
              [action.reaction]: newAllReactionItem
            };
            feedItem.userReactions = action.isOn ? [action.reaction] : [];
          }
        });
      });

      return {
        ...base,
        feedList: newFeedList
      };

    default:
      return base;
  }
};

export default FeedsReducer;
