/** @format */

import React from "react";
import PropTypes from "prop-types";
import Figure1Layout from "../../@layouts/Figure1Layout";
import ISIHeader from "./ISIHeader";
import ISIFooter from "./ISIFooter";
import useISI from "./useISI";
import AppPromptAuthenticated from"../app-prompt/AppPromptAuthenticated";

const ISILayout = ({ isi, hidden, enableGatedBanner = false, children }) => {
  /** ********************************** CONFIG ***************************************/
  const { isiHeaderRef, showHeader, showFooter } = useISI(isi, hidden);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout
      enableGatedBanner={enableGatedBanner}
      header={
        showHeader && (
          <ISIHeader
            title={isi?.isiText}
            url={isi?.isiLink}
            contentItem={isi}
            ref={isiHeaderRef}
          />
        )
      }
      footer={
        <>
          {showFooter && (
            <ISIFooter
              url={isi?.isiEmbeddedContentLink}
              contentItem={isi}
              isiHeaderRef={isiHeaderRef}
            />
          )}
          <AppPromptAuthenticated />
        </>
      }>
      {children}
    </Figure1Layout>
  );
};

ISILayout.propTypes = {
  children: PropTypes.node.isRequired,
  isi: PropTypes.shape({
    externalLink: PropTypes.string,
    header: PropTypes.string,
    url: PropTypes.string
  }),
  hidden: PropTypes.bool,
  enableGatedBanner: PropTypes.bool
};

export default ISILayout;
