/** @format */

export const defaultFeatures = {
  commentBarEnabled: false,
  commentsEnabled: false,
  reactionsEnabled: false,
  showComments: false,
  showViews: false,
  reportEnabled: true,
  shareEnabled: true,
  saveEnabled: true,
  commentTabsEnabled: false,
  showLabels: false,
  zoomEnabled: true
};
