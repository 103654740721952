import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Comment from "./Comment";
import _ from "lodash";
import i18n from "../../utils/i18n";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import { scrubComments, sortComments } from "../../utils/comment-utils";
import { useSelector } from "react-redux";

const CommentThread = ({
  commentStatus,
  sortBy,
  filter,
  reportEnabled,
  caseUuid,
  contentUuid,
  position,
  caseContent,
  isGroup = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();
  const [comments, setComments] = useState(null);
  const currentUserId = useSelector((state) => state.user.userUid);
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (commentStatus?.originalTree && filter) {
      const cachedComments = _.cloneDeep(
        commentStatus.comments.filter((c) => c.commentUuid === "cache")
      );

      let commentList = _.cloneDeep(commentStatus.originalTree);

      if (Array.isArray(commentList)) {
        // we have comments, and the mutation has occurred to turn comments from obj to array
        commentList = commentList.concat(cachedComments);
      } else if (cachedComments.length > 0) {
        // no comments but yes cached
        commentList = [...cachedComments];
      } else {
        // just in case.
        commentList = [];
      }

      const results = [];

      // now loop through and check for reply threads
      commentList.forEach((c) => {
        if (
          filter !== "physician" ||
          (filter === "physician" &&
            (c.isPhysician === true || c.commentUuid === "cache"))
        ) {
          // check to see if there's a reply thread, if so, convert to an array
          if (c.children && Object.keys(c.children).length > 0) {
            c.replyThread = sortComments(Object.values(c.children), true);
          }

          results.push(c);
        }
      });
      setComments(
        sortComments(scrubComments(results, currentUserId), sortBy === "old")
      );
    }
  }, [currentUserId, filter, commentStatus, sortBy]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  let content = (
    <div
      className={classNames(
        "d-flex justify-content-center align-items-center p-5 rounded",
        theme === THEMES.DARK
          ? "bg-charcoal-gray"
          : "bg-lighter-gray text-denim-blue"
      )}>
      <p className="text-15 mb-0">{i18n.t("comments.noComments")}</p>
    </div>
  );

  if (comments?.length > 0) {
    content = (
      <>
        {comments &&
          comments.map((c) => {
            if (c.commentUuid) {
              return (
                <Comment
                  key={c.commentUuid}
                  comment={c}
                  reportEnabled={reportEnabled}
                  caseUuid={caseUuid}
                  contentUuid={contentUuid}
                  position={position}
                  caseContent={caseContent}
                  commentStatus={commentStatus}
                  isGroup={isGroup}
                />
              );
            }
            return null;
          })}
      </>
    );
  }

  return <div className="py-3 px-3">{content}</div>;
};

CommentThread.propTypes = {
  commentStatus: PropTypes.shape({
    physicianCount: PropTypes.number,
    allCount: PropTypes.number,
    originalTree: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object
    ])
  }),
  sortBy: PropTypes.string.isRequired,
  filter: PropTypes.string,
  reportEnabled: PropTypes.bool,
  caseUuid: PropTypes.string.isRequired,
  contentUuid: PropTypes.string.isRequired
};

export default CommentThread;
