/** @format */

import {
  FETCH_COMMENTS,
  FETCH_COMMENTS_COMPLETE,
  POST_COMMENT,
  REPORT_COMMENT,
  DELETE_COMMENT,
  POST_COMMENT_COMPLETE,
  REPORT_COMMENT_COMPLETE,
  DELETE_COMMENT_COMPLETE,
  COMMENTS_UPDATED,
  COMMENTS_LISTENER_UPDATE,
  COMMENTS_EXPAND_THREAD,
  COMMENTS_COLLAPSE_THREAD,
  COMMENTS_EXPAND_ROOT,
  COMMENTS_COLLAPSE_ROOT,
  COMMENTS_RESET,
  SELECT_COMMENT_SORT,
  SELECT_COMMENT_FILTER,
  CACHE_COMMENT,
  RESET_COMMENT_CACHE,
  TRANSLATE_COMMENT,
  TRANSLATE_COMMENT_COMPLETE,
  VIEW_ORIGINAL_COMMENT
} from "../actions/comment.actions";
import { RESET_ALL } from "../actions/global.actions";
import {
  COMMENT_FILTER,
  COMMENT_LIMIT,
  COMMENT_SORT
} from "../constants/comment-constants";

export const INITIAL_STATE = {
  /**
   * caseComments is a map of comment status with caseUuid as key,
   * value is a map as
   * {
   *   comments: [],
   *   physicianComments: [],
   *   originalTree: {},
   *   allCount: 0,
   *   physicianCount: 0,
   *   isFetchingList: false,
   *   isProcessingAction: false,
   *   listener: null,
   *   error: false,
   *   message: null,
   * }
   **/
  caseComments: {},
  expandedStates: {},
  sort: COMMENT_SORT.NEWEST,
  filter: COMMENT_FILTER.PHYSICIAN,
  cache: {},
  translations: {}
};

const commentReducer = (state = INITIAL_STATE, action) => {
  const getCommentStatus = () => {
    if (!action.caseUuid) {
      console.error("caseUuid missing from action ", action);
    }
    const commentStatus = state.caseComments[action.caseUuid] || {};
    return { ...commentStatus };
  };

  switch (action.type) {
    case FETCH_COMMENTS: {
      const commentStatus = getCommentStatus();
      commentStatus.isFetchingList = true;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case FETCH_COMMENTS_COMPLETE: {
      const commentStatus = getCommentStatus();
      commentStatus.isFetchingList = false;
      commentStatus.comments = action.comments;
      commentStatus.originalTree = action.originalTree;
      commentStatus.error = action.error;
      commentStatus.message = action.message;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case POST_COMMENT:
    case REPORT_COMMENT:
    case DELETE_COMMENT: {
      const commentStatus = getCommentStatus();
      commentStatus.isProcessingAction = true;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case POST_COMMENT_COMPLETE:
    case REPORT_COMMENT_COMPLETE:
    case DELETE_COMMENT_COMPLETE: {
      const commentStatus = getCommentStatus();
      commentStatus.isProcessingAction = false;
      commentStatus.error = action.error;
      commentStatus.message = action.message;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case COMMENTS_UPDATED: {
      const commentStatus = getCommentStatus();
      commentStatus.comments = action.comments;
      commentStatus.originalTree = action.originalTree;
      commentStatus.physicianComments = action.physicianComments;
      commentStatus.allCount = action.allCount;
      commentStatus.physicianCount = action.physicianCount;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case COMMENTS_LISTENER_UPDATE: {
      const commentStatus = getCommentStatus();
      commentStatus.listener = action.listener;
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: commentStatus
        }
      };
    }
    case COMMENTS_EXPAND_THREAD: {
      const currentLimit =
        (state.expandedStates[action.commentUuid] ||
          COMMENT_LIMIT.CHILD_INITIAL_THRESHOLD) + COMMENT_LIMIT.CHILD_BATCH;
      return {
        ...state,
        expandedStates: {
          ...state.expandedStates,
          [action.commentUuid]: currentLimit
        }
      };
    }
    case COMMENTS_COLLAPSE_THREAD: {
      return {
        ...state,
        expandedStates: {
          ...state.expandedStates,
          [action.commentUuid]: COMMENT_LIMIT.CHILD_INITIAL_THRESHOLD
        }
      };
    }
    case COMMENTS_EXPAND_ROOT: {
      const currentLimit =
        (state.expandedStates[action.caseUuid] ||
          COMMENT_LIMIT.ROOT_INITIAL_THRESHOLD) + COMMENT_LIMIT.ROOT_BATCH;
      return {
        ...state,
        expandedStates: {
          ...state.expandedStates,
          [action.caseUuid]: currentLimit
        }
      };
    }
    case COMMENTS_COLLAPSE_ROOT: {
      return {
        ...state,
        expandedStates: {
          ...state.expandedStates,
          [action.caseUuid]: COMMENT_LIMIT.ROOT_INITIAL_THRESHOLD
        }
      };
    }
    case COMMENTS_RESET:
      return {
        ...state,
        caseComments: {
          ...state.caseComments,
          [action.caseUuid]: null
        }
      };
    case SELECT_COMMENT_SORT:
      return {
        ...state,
        sort: action.sort
      };
    case SELECT_COMMENT_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case CACHE_COMMENT:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.caseUuid]: action.payload.comment
        }
      };
    case RESET_COMMENT_CACHE:
      return {
        ...state,
        cache: {}
      };
    case TRANSLATE_COMMENT:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.commentUuid]: {
            isTranslated: false,
            isLoading: true,
            error: null
          }
        }
      };
    case TRANSLATE_COMMENT_COMPLETE:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.commentUuid]: {
            isTranslated: true,
            isLoading: false,
            error: action.error
          }
        }
      };
    case VIEW_ORIGINAL_COMMENT:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.commentUuid]: {
            isTranslated: false,
            isLoading: false,
            error: null
          }
        }
      };
    case RESET_ALL:
      const comments = Object.values(state.caseComments);
      for (const caseComment of comments) {
        if (typeof caseComment?.listener === "function") {
          caseComment.listener();
        }
      }
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default commentReducer;
