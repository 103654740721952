import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { getRegistrationRoute } from "../../actions/login.actions";
import * as ROUTES from "../../constants/routes";
import useCreateAnonUserIfNeeded from "../../hooks/useCreateAnonUserIfNeeded";
import useUserCompletedOnboarding from "../../hooks/useUserCompletedOnboarding";
import LoadingPage from "../../pages/home/LoadingPage";
import { userSignedIn } from "../../utils/route-utils";
import QueryPreserveRedirect from "./QueryPreserveRedirect";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isSignedIn = useSelector((state) => userSignedIn(state.user));

  const isInitialized = useSelector(
    (state) => state.authentication.initialized
  );
  const isUserCompleted = useUserCompletedOnboarding();

  const user = useSelector((state) => state.user);
  const componentProps = rest.componentProps;

  /** ********************************** HOOKS ****************************************/
  useCreateAnonUserIfNeeded();

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isInitialized) {
          return <LoadingPage />;
        } else if (!isSignedIn) {
          return (
            <QueryPreserveRedirect
              preserveQueryString={true}
              to={{
                pathname: ROUTES.SIGN_IN,
                search: `?redirect=${routeProps.location.pathname}`,
                state: { from: routeProps.location }
              }}
            />
          );
        } else if (!isUserCompleted) {
          // find out where to re-route them, either back into creation flow, or to the channels page
          const newRoute = getRegistrationRoute(user);
          return (
            <QueryPreserveRedirect
              preserveQueryString={true}
              to={{
                pathname: newRoute
              }}
            />
          );
        }
        return <Component {...routeProps} {...componentProps} />;
      }}
    />
  );
};

export default PrivateRoute;
