import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Collapse
} from "reactstrap";
import { capitalize, initial, last, orderBy } from "lodash";
import i18n from "../../utils/i18n";

const CaseDetailUpdatesCard = ({ caseContent }) => {
  /** ********************************** CONFIG ***************************************/
  const [isOpen, setIsOpen] = useState(false);
  const [updates, setUpdates] = useState([]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (caseContent && caseContent.updates) {
      setUpdates(
        orderBy(caseContent.updates, [(u) => new Date(u.createdAt)], ["asc"])
      );
    }
  }, [caseContent]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const bodyClass = updates.length > 1 ? "pb-0" : "";
  return (
    <>
      {updates.length > 0 && !caseContent.isSponsored && (
        <Card className="mb-2">
          <CardBody className={bodyClass}>
            <CaseUpdate
              className={"p-0"}
              index={updates.length}
              title={
                updates.length === 1
                  ? capitalize(i18n.t("common.update"))
                  : `${capitalize(i18n.t("common.update"))} ${updates.length}`
              }
              text={last(updates)?.text}
            />
            <Collapse isOpen={isOpen}>
              {initial(updates)
                .map((update, index) => (
                  <CaseUpdate
                    key={index}
                    className={"px-0 pb-0"}
                    index={index + 1}
                    title={`${capitalize(i18n.t("common.update"))} ${
                      index + 1
                    }`}
                    text={update.text}
                  />
                ))
                .reverse()}
            </Collapse>
          </CardBody>
          {updates.length > 1 && (
            <CardFooter className="border-0 bg-white">
              <Button block size="lg" onClick={() => setIsOpen(!isOpen)}>
                {isOpen
                  ? i18n.t("AddUpdateScreen.collapseUpdates")
                  : i18n.t("AddUpdateScreen.expandUpdates", {
                      count: updates.length
                    })}
              </Button>
            </CardFooter>
          )}
        </Card>
      )}
    </>
  );
};

const CaseUpdate = ({ className, title, text }) => {
  return (
    <CardBody className={className}>
      <CardTitle className="d-flex border-bottom border-width-2 border-aqua">
        <span className="bg-aqua px-2 py-1 text-14 text-white font-weight-bold rounded-top">
          {title}
        </span>
      </CardTitle>
      <CardText className="feed-card-info text-15">{text}</CardText>
    </CardBody>
  );
};

export default CaseDetailUpdatesCard;
