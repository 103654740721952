/** @format */

import {
  SEND_INVITES,
  SEND_INVITES_COMPLETED,
  SEND_INVITES_ERROR
} from "../actions/InviteColleagues.actions";

const initialState = {
  isProcessing: false,
  error: null
};

const inviteColleaguesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_INVITES:
      return {
        ...state,
        isProcessing: true,
        error: null
      };

    case SEND_INVITES_COMPLETED:
      return {
        ...state,
        isProcessing: false,
        error: null
      };
    case SEND_INVITES_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default inviteColleaguesReducer;
