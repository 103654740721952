/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardText, Col, Row } from "reactstrap";
import { ContentProp } from "../../../prop-types/ContentProp";
import { useCaseBanner } from "../../../hooks/use-case-banner.hooks";
import i18n from "../../../utils/i18n";
import CaseBanner from "../../../components/case/CaseBanner";
import CaseDropdown from "../../../components/case/CaseDropdown";
import CaseTitle from "../../../components/case/CaseTitle";
import CaseUserCell from "../../../components/case/CaseUserCell";
import { CASE_CARD_ORIGIN } from "../../../metrics/constants.metrics";

const CMCoverSlideRow = ({
  content,
  caseMeta,
  commentStatus,
  onStartActivity
}) => {
  /****************************** Config **************************************/
  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <>
      {showBanner && (
        <Row>
          <Col>
            <CaseBanner bannerType={bannerType} />
          </Col>
        </Row>
      )}
      <Row className="no-gutters mb-4">
        <Col className="bg-dark-gray" lg={6}>
          <Card className="bg-transparent text-white border-0 h-100">
            <CardBody>
              <CaseTitle caseContent={content} size={28} maxLine={3} />
            </CardBody>
            {content.feedCardLabel && (
              <CardBody className="pt-0 flex-grow-0">
                <CardText className="border border-width-1 border-white rounded py-1 px-2 text-white text-13 float-left">
                  {content.feedCardLabel}
                </CardText>
              </CardBody>
            )}
          </Card>
        </Col>

        <Col className="bg-dark-gray" lg={1} />

        <Col className="bg-dark-gray" lg={5}>
          <Card className="bg-transparent text-white border-0 h-100 justify-content-between">
            <CardBody className="d-inline-flex justify-content-between align-items-center w-100 flex-grow-0">
              <CaseUserCell
                caseContent={caseMeta}
                tagline={i18n.t("CMEScreens.hostBy")}
                inline
              />
              <CaseDropdown
                caseContent={caseMeta}
                caseNotification={handleShowBanner}
                from={CASE_CARD_ORIGIN.DETAIL}
                commentStatus={commentStatus}
              />
            </CardBody>
            <CardBody className="pt-0 flex-grow-0">
              <Button
                className="py-3"
                color="aqua"
                size="lg"
                block
                onClick={onStartActivity}>
                {content?.buttonText}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

CMCoverSlideRow.propTypes = {
  content: ContentProp,
  caseMeta: PropTypes.object
};

export default CMCoverSlideRow;
