/** @format */

import * as auth from "../api/auth";
import i18n from "../utils/i18n";
import {
  authenticationCheckCall,
  createUser,
  legacyUpdateUID,
  validateEmail,
  resetPasswordCall
} from "../api/user.cloud-functions";
import NonUserActionTypes from "./non-user-action-types";
import Regex from "../constants/regex";

import { LEGACY_MIGRATION, signIn } from "./login.actions";

import LEGACY_SIGNIN_STATUS from "../constants/legacy-status";
import SIGN_IN_ERROR_STATE from "../constants/sign-in-error-state";
import { hideGlobalMessage, resetGatedState } from "./global.actions";
import { isRequestSuccess } from "../utils/general-utils";

export const signUp = (
  email,
  pass,
  firstName,
  lastName,
  profession,
  otherSpecialtyTreeUuid
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NonUserActionTypes.SIGN_UP
      });
      const validEmailResponse = await validateEmail(email);
      if (validEmailResponse.status === 409) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: "Email is associated with an existing user. Please login"
        });
      } else if (validEmailResponse.status === 422) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: "Invalid Email Address"
        });
      } else if (!isRequestSuccess(validEmailResponse)) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: "Invalid Email"
        });
      }

      const fbResponse = await auth.signUp(email, pass);

      const specialtyInfo = otherSpecialtyTreeUuid
        ? {
            tree_uuid: otherSpecialtyTreeUuid,
            profession_uuid: profession
          }
        : {
            profession_tree_uuid: profession,
            profession_uuid: profession
          };

      const backendResponse = await createUser({
        email: email,
        user_uid: fbResponse.user.uid,
        first_name: firstName,
        last_name: lastName,
        ...specialtyInfo
      });
      if (isRequestSuccess(backendResponse)) {
        dispatch(resetGatedState());

        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          signUpResult: { uid: fbResponse.user.uid },
          authResult: fbResponse
        });
      } else {
        console.log("Sign up error: ", backendResponse.status);
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: "failed to create user in backend"
        });
      }
    } catch (error) {
      console.log("Sign up error: ", error);
      return dispatch({
        type: NonUserActionTypes.SIGN_UP_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const signUpV2 = (email, pass, country, groupUuid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NonUserActionTypes.SIGN_UP
      });
      const validEmailResponse = await validateEmail(email);
      if (validEmailResponse.status === 409) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: i18n.t("RegistrationScreens.createAccount.emailTaken")
        });
      } else if (validEmailResponse.status === 422) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: i18n.t("RegistrationScreens.createAccount.emailInvalid")
        });
      } else if (!isRequestSuccess(validEmailResponse)) {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: i18n.t("RegistrationScreens.createAccount.emailInvalid")
        });
      }

      const fbResponse = await auth.signUp(email, pass);

      const backendResponse = await createUser({
        email: email,
        user_uid: fbResponse.user.uid,
        country_uuid: country,
        group_uuid: groupUuid
      });
      if (isRequestSuccess(backendResponse)) {
        dispatch(resetGatedState());

        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          signUpResult: { uid: fbResponse.user.uid },
          authResult: fbResponse,
          user: backendResponse.store
        });
      } else {
        console.log("Sign up error: ", backendResponse.status);
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          message: "failed to create user in backend"
        });
      }
    } catch (error) {
      console.log("Sign up error: ", error);
      return dispatch({
        type: NonUserActionTypes.SIGN_UP_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const resetPassword = (input) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NonUserActionTypes.RESET_PASSWORD
      });
      const isEmail = Regex.SimpleEmail.test(input);

      const result = await resetPasswordCall({
        email: isEmail ? input : null,
        username: isEmail ? null : input
      });

      if (isRequestSuccess(result)) {
        return dispatch({
          type: NonUserActionTypes.RESET_PASSWORD_COMPLETE,
          message: i18n.t("RegistrationScreens.resetPasswordForm.linkSent"),
          email: result.store.email
        });
      } else if (result.status === 404) {
        return dispatch({
          type: NonUserActionTypes.RESET_PASSWORD_COMPLETE,
          error: true,
          message: i18n.t("RegistrationScreens.resetPasswordForm.noMatchFound")
        });
      } else {
        return dispatch({
          type: NonUserActionTypes.RESET_PASSWORD_COMPLETE,
          error: true,
          message: i18n.t("RegistrationScreens.resetPasswordForm.resetFailed")
        });
      }
    } catch (error) {
      return dispatch({
        type: NonUserActionTypes.RESET_PASSWORD_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const authCheckAndSignIn = (input, pass, preventRedirect) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NonUserActionTypes.CHECK_AUTH
      });
      dispatch(hideGlobalMessage());

      const isEmail = Regex.SimpleEmail.test(input);
      const result = await authenticationCheckCall({
        email: isEmail ? input : null,
        username: isEmail ? null : input,
        password: pass
      });

      if (result.status === 404) {
        return dispatch({
          type: NonUserActionTypes.CHECK_AUTH_COMPLETE,
          error: true,
          errorState: SIGN_IN_ERROR_STATE.USER_NOT_FOUND,
          message: i18n.t("RegistrationScreens.loginForm.failedLoginMessage")
        });
      } else if (isRequestSuccess(result)) {
        const stat = result.store.status;
        const email = result.store.email;
        return dispatch(signInOrMigrate(email, pass, stat, preventRedirect));
      } else {
        return dispatch({
          type: NonUserActionTypes.CHECK_AUTH_COMPLETE,
          error: true,
          errorState: SIGN_IN_ERROR_STATE.AUTH_CHECK_FAILED,
          message: i18n.t("RegistrationScreens.loginForm.failedLoginMessage")
        });
      }
    } catch (error) {
      return dispatch({
        type: NonUserActionTypes.CHECK_AUTH_COMPLETE,
        error,
        errorState: SIGN_IN_ERROR_STATE.GENERIC_FAILURE,
        message: i18n.t("RegistrationScreens.loginForm.failedLoginOtherMessage")
      });
    }
  };
};

const signInOrMigrate = (email, password, status, preventRedirect) => {
  return async (dispatch) => {
    dispatch({
      type: NonUserActionTypes.CHECK_AUTH_COMPLETE
    });

    switch (status) {
      case LEGACY_SIGNIN_STATUS.MIGRATED: {
        return dispatch(signIn(email, password, false, preventRedirect));
      }
      case LEGACY_SIGNIN_STATUS.CREDENTIAL_INVALID: {
        return dispatch({
          type: NonUserActionTypes.CHECK_AUTH_COMPLETE,
          error: true,
          errorState: SIGN_IN_ERROR_STATE.CREDENTIAL_INVALID,
          message: i18n.t("RegistrationScreens.loginForm.failedLoginMessage")
        });
      }
      case LEGACY_SIGNIN_STATUS.CREDENTIAL_VALID: {
        // if credential match, register user in firebase and update uid to backend
        dispatch({
          type: NonUserActionTypes.SIGN_UP
        });
        dispatch({
          type: LEGACY_MIGRATION
        });
        const fbResponse = await auth.signUp(email, password);
        const updateResponse = await legacyUpdateUID({
          email: email,
          user_uid: fbResponse?.user?.uid
        });

        if (isRequestSuccess(updateResponse)) {
          return dispatch(signIn(email, password, true, preventRedirect));
        } else {
          return dispatch({
            type: NonUserActionTypes.SIGN_UP_COMPLETE,
            error: true,
            errorState: SIGN_IN_ERROR_STATE.UPDATE_UID_FAILED,
            message: i18n.t(
              "RegistrationScreens.loginForm.failedLoginOtherMessage"
            )
          });
        }
      }
      default: {
        return dispatch({
          type: NonUserActionTypes.SIGN_UP_COMPLETE,
          error: true,
          errorState: SIGN_IN_ERROR_STATE.GENERIC_FAILURE,
          message: i18n.t(
            "RegistrationScreens.loginForm.failedLoginOtherMessage"
          )
        });
      }
    }
  };
};
