import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  declineAppPrompt,
  setAppPromptHeight
} from "../../actions/global.actions";
import { trackGetAppClicked } from "../../actions/metrics.actions";
import { StaticStrings } from "../../constants/static-string-constants";

const useAppPrompt = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const isShown = useSelector((state) => state.global.appPrompt.isShown);
  const isDeclined = useSelector((state) => state.global.appPrompt.isDeclined);
  const height = useSelector((state) => state.global.appPrompt.height);

  const appUrl = StaticStrings.appPromptDeepLink + pathname;

  return {
    appUrl,
    isShown,
    isDeclined,
    height,
    declineAppPrompt: useCallback(
      () => dispatch(declineAppPrompt()),
      [dispatch]
    ),
    setAppPromptHeight: useCallback(
      (height) => dispatch(setAppPromptHeight(height)),
      [dispatch]
    ),
    onOpenApp: trackGetAppClicked
  };
};

export default useAppPrompt;
