import React from "react";
import PropTypes from "prop-types";
import { ModalFooter } from "reactstrap";

const BaseDialogFooter = ({ children, style = {} }) => {
  return (
    <ModalFooter
      className="d-flex flex-column border-0 pb-4 mb-3 pt-3"
      style={{ ...style, maxWidth: "80%", margin: "auto" }}>
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        {children}
      </div>
    </ModalFooter>
  );
};

BaseDialogFooter.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClosed: PropTypes.func
};

export default BaseDialogFooter;
