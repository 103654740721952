import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { SIGN_IN } from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import CaseCarouselContainer from "../../pages/feed/components/case-carousel/CaseCarouselContainer";
import useLoginModalClick from "../../hooks/useLoginModalClick";

const BlockedCaseCarousel = ({ className, cases, title, linkText }) => {
  const { showLoginModal } = useLoginModalClick();

  return (
    <Card className={className}>
      <CardBody className="py-0">
        <CaseCarouselContainer
          cases={(cases || []).slice(0, 6)}
          context={"Ungated case"}
          imgSize={124}
          colSize={2}
          nextItemVisiblePercent={0.2}
          disableClick={true}
          headerElement={
            <h3 className="helv-bold text-18 text-center mb-3">{title}</h3>
          }
          footerElement={
            <div className="text-center">
              <Link
                className="btn btn-lg btn-secondary helv-bold mt-4 btn-block"
                to={getUrl(SIGN_IN)}
                onClick={showLoginModal}>
                {linkText}
              </Link>
            </div>
          }
        />
      </CardBody>
    </Card>
  );
};

export default BlockedCaseCarousel;
