import React from "react";
import { FormFeedback, FormGroup, InputGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import F1YearPicker from "../../../components/common/F1YearPicker";

const ProfileEditYearsFormGroup = ({
  setValue,
  getValues,
  errors,
  control,
  watch
}) => {
  /************************************ CONFIG ***************************************/

  const { t } = useTranslation();
  const startYear = watch("startYear", null);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <FormGroup>
      <Label for="years">{t("Profile.years")}</Label>
      <InputGroup className="d-inline-flex justify-content-center align-items-center">
        <Controller
          as={F1YearPicker}
          control={control}
          className="flex-grow-1"
          id="startYear"
          name="startYear"
          placeholder={t("Profile.startYearPlaceholder")}
          onChangeYear={(year) => {
            const endYear = getValues("endYear");
            setValue("startYear", year, { shouldValidate: !!errors.startYear });
            setValue("endYear", endYear < year ? "" : endYear, {
              shouldValidate: !!errors.endYear
            });
          }}
          rules={{ required: true }}
          invalid={!!errors.startYear}
          defaultValue={""}
        />
        <div className="year-separator flex-grow-0 px-2">-</div>
        <Controller
          as={F1YearPicker}
          control={control}
          className="flex-grow-1"
          id="endYear"
          name="endYear"
          placeholder={t("Profile.endYearPlaceholder")}
          onChangeYear={(year) => {
            setValue("endYear", year, { shouldValidate: !!errors.endYear });
          }}
          canSelectPresent={true}
          rules={{ required: true }}
          startYear={startYear}
          disabled={startYear === null}
          invalid={!!errors.endYear}
          defaultValue={""}
        />
        <FormFeedback>{t("Profile.yearError")}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};

ProfileEditYearsFormGroup.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  watch: PropTypes.func.isRequired
};

ProfileEditYearsFormGroup.defaultProps = {};

export default ProfileEditYearsFormGroup;
