import React, { useState } from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import i18n from "../../utils/i18n";
import RegistrationHeader from "../authentication/components/RegistrationHeader";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { useContactSupport } from "../../hooks/useContactSupport";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import { Button } from "reactstrap";
import { openInTab } from "../../utils/route-utils";
import { getSupportEmail } from "../../utils/email-utils";
import NonHcpContactSupportModal from "./components/NonHcpContactSupportModal";

const OnboardingNonHcpPage = () => {
  /** ********************************** CONFIG ***************************************/
  const onContactSupport = useContactSupport();
  const [isModalOpen, setIsModalOpen] = useState(false);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout
      noGatedHeader={true}
      topHeader={<RegistrationHeader onContactSupport={onContactSupport} />}>
      <div className="bg-gradient d-flex h-100 text-white text-20 m-0 justify-content-center align-items-center ">
        <div
          style={{ width: "361px" }}
          className="d-flex flex-column m-0 justify-content-center align-items-center mb-5">
          <CustomIcon
            icon="verification_icon"
            className="icon-white mb-3"
            size={36}
          />
          <div className="text-center font-weight-bold mb-2 lh-2">
            {i18n.t(`RegistrationScreens.nonHcpPage.text1`)}
          </div>
          <div className="text-center text-16 mb-5">
            {i18n.t("RegistrationScreens.nonHcpPage.text2")}
          </div>
          <Button
            className="w-100 mb-3"
            color="secondary"
            type="button"
            onClick={() => {
              openInTab(StaticStrings.clinicalCases);
            }}>
            <p className="text-16 m-0">
              {i18n.t("RegistrationScreens.nonHcpPage.exploreCases")}
            </p>
          </Button>
          <Button
            className="w-100"
            color="primary"
            type="button"
            onClick={() => {
              setIsModalOpen(true);
            }}>
            <p className="text-16 m-0">
              {i18n.t("RegistrationScreens.nonHcpPage.healthCareProvider")}
            </p>
          </Button>
          <div className="align-self-start mt-3">
            <span className="text-15">
              {i18n.t("RegistrationScreens.nonHcpPage.questions")}
            </span>{" "}
            <ExternalTextLink
              className="text-white cursor-pointer text-underline text-15"
              url={getSupportEmail()}
              linkText={i18n.t("RegistrationScreens.contactSupport")}
            />
          </div>
        </div>
      </div>
      <NonHcpContactSupportModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen((isOpen) => !isOpen)}
      />
    </Figure1Layout>
  );
};

export default OnboardingNonHcpPage;
