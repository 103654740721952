import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import i18n from "../../../utils/i18n";
import DIALOG_TYPE from "../../dialog/DialogType";
import Dialog from "../../dialog/Dialog";

const CommentDeleteDialog = ({
  isOpen,
  onSubmit,
  comment,
  toggle,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const handleSubmit = () => {
    if (typeof onSubmit === "function") {
      onSubmit();
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      type={DIALOG_TYPE.CONFIRM}
      isOpen={isOpen}
      title={i18n.t("common.deleteComment.promptTitle")}
      body={
        <div className="bg-light p-3 border rounded mh-300px overflow-auto w-100">
          {comment?.text}
        </div>
      }
      confirmText={i18n.t("common.deleteComment.buttonLabel")}
      cancelText={capitalize(i18n.t("common.cancel"))}
      onConfirm={handleSubmit}
      onCancel={toggle}
      onClosed={toggle}
      toggle={toggle}
      {...rest}
    />
  );
};

CommentDeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

CommentDeleteDialog.defaultProps = {};

export default CommentDeleteDialog;
