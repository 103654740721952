/** @format */

import {
  LOAD_USER_PREFERENCES,
  LOAD_USER_PREFERENCES_COMPLETE,
  USER_PREFERENCES_UPDATED,
  USER_PREFERENCES_LISTENER_ON,
  USER_PREFERENCES_LISTENER_OFF
} from "../actions/preferences-center.actions";
import { SIGNOUT_COMPLETE } from "../actions/login.actions";

export const INITIAL_STATE = {
  loading: false,
  isProcessing: false,
  communicationGroupings: {},
  userPreferences: {},
  listener: null
};

const userCommunicationPreferencesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_COMPLETE:
      return { ...INITIAL_STATE };
    case LOAD_USER_PREFERENCES:
      return {
        ...state,
        loading: true
      };
    case LOAD_USER_PREFERENCES_COMPLETE:
      return {
        ...state,
        communicationGroupings: action.groupings,
        userPreferences: action.userPreferences
      };
    case USER_PREFERENCES_UPDATED:
      return {
        ...state,
        userPreferences: action.userPreferences
      };
    case USER_PREFERENCES_LISTENER_ON:
      return { ...state, listener: action.payload.listener };
    case USER_PREFERENCES_LISTENER_OFF:
      return {
        ...state,
        isProcessing: false,
        listener: null
      };
    default:
      return state;
  }
};

export default userCommunicationPreferencesReducer;
