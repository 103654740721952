/** @format */

import React from "react";
import PropTypes from "prop-types";
import ISIHeader from "./ISIHeader";
import ISIFooter from "./ISIFooter";
import useISI from "./useISI";

const ISIContainer = ({ isi, hidden, children }) => {
  /** ********************************** CONFIG ***************************************/
  const { isiHeaderRef, showHeader, showFooter } = useISI(isi, hidden);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {showHeader && (
        <ISIHeader
          title={isi?.isiText}
          url={isi?.isiLink}
          contentItem={isi}
          ref={isiHeaderRef}
        />
      )}
      {children}
      {showFooter && (
        <ISIFooter
          url={isi?.isiEmbeddedContentLink}
          contentItem={isi}
          isiHeaderRef={isiHeaderRef}
        />
      )}
    </>
  );
};

ISIContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isi: PropTypes.shape({
    externalLink: PropTypes.string,
    header: PropTypes.string,
    url: PropTypes.string
  }),
  hidden: PropTypes.bool
};

export default ISIContainer;
