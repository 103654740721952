/** @format */

import { toggleTopicFollow as toggleFollow } from "../api/cloud-functions";
import * as topicDB from "../db/topic.db";
import _ from "lodash";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "topics";

export const FETCH_TOPICS = `${actionsPrefix}/Fetch_TOPICS`;
export const FETCH_TOPICS_COMPLETE = `${actionsPrefix}/Fetch_TOPICS_COMPLETE`;
export const FOLLOW_TOPIC = `${actionsPrefix}/FOLLOW_TOPIC`;
export const FOLLOW_TOPIC_ERROR = `${actionsPrefix}/FOLLOW_TOPIC_ERROR`;
export const FOLLOW_TOPIC_COMPLETE = `${actionsPrefix}/FOLLOW_TOPIC_COMPLETE`;

export const fetchTopicsIfNeeded = () => {
  return async (dispatch, getState) => {
    const topics = getState()?.topics?.data;

    if (_.isEmpty(topics)) {
      dispatch(fetchTopics());
    }

    return;
  };
};

export const fetchTopics = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_TOPICS
    });
    try {
      const response = await topicDB.fetchAll();
      if (!_.isEmpty(response.all)) {
        const topics = response.all;
        topics.sort((a, b) => a.displayOrder - b.displayOrder);
        dispatch({
          type: FETCH_TOPICS_COMPLETE,
          data: topics
        });
      } else {
        dispatch({
          type: FETCH_TOPICS_COMPLETE,
          error: true,
          message: "Failed to fetch topics"
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_TOPICS_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const toggleTopicFollow = (topicId) => {
  // optimistically update follow state before api call
  // revert state if error occurs
  return async (dispatch, getState) => {
    const selected = getState().topics.selected;
    const follow = !selected[topicId];

    const newMap = { ...selected, [topicId]: follow };
    dispatch({
      type: FOLLOW_TOPIC,
      selected: newMap
    });
    try {
      const response = await toggleFollow({
        uuid: topicId,
        action: follow ? "follow" : "unfollow"
      });

      if (!isRequestSuccess(response)) {
        return dispatch({
          type: FOLLOW_TOPIC_ERROR,
          message: `Failed to update follow state for topic ${topicId}`,
          selected: selected
        });
      }

      return { status: 200 };
    } catch (error) {
      return dispatch({
        type: FOLLOW_TOPIC_ERROR,
        message: error.message,
        selected: selected
      });
    }
  };
};
