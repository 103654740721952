import React from "react";
import AppPromptSmall from "./AppPromptSmall";
import useAppPrompt from "./useAppPrompt";
import "./AppPromptUnauthenticated.scss";

const AppPromptUnauthenticated = ({ isDark = true }) => {
  const { appUrl, isShown, onOpenApp } = useAppPrompt();

  return (
    isShown && (
      <AppPromptSmall
        appUrl={appUrl}
        cardClassName={
          isDark ? "app-prompt-unauthenticated-dark" : "app-prompt-unauthenticated-light"
        }
        confirmButtonColor={isDark ? "white" : "denim-blue"}
        titleColor={isDark ? "white" : "default-black"}
        onOpenApp={onOpenApp}
      />
    )
  );
};

export default AppPromptUnauthenticated;
