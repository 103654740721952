/** @format */

import React from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import SavedCasesPage from "./SavedCasesPage";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import AppPromptAuthenticated from"../../components/app-prompt/AppPromptAuthenticated";

const SavedCases = () => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPromptAuthenticated />}>
      <div className="mw-491 mx-auto">
        <Figure1Page1Col isChildPage mainContent={<SavedCasesPage />} />
      </div>
    </Figure1Layout>
  );
};

export default SavedCases;
