import React from "react";
import { Card, CardBody } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import "./style.scss";
import THEMES from "../theme/Theme";

const CaseCardLoading = () => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <SkeletonTheme
      color={theme === THEMES.DARK ? "#191818" : null}
      highlightColor={theme === THEMES.DARK ? "#1d2931" : null}>
      <div className="feed-card">
        <Card
          className={classNames(
            "border-0 rounded-0",
            theme === THEMES.DARK ? "bg-darkest" : ""
          )}>
          <CardBody className="pb-2">
            <Skeleton height={16} width={`100%`} />
            <Skeleton height={16} width={`80%`} />

            <div className="skeleton-header">
              <Skeleton circle={true} height={50} width={50} />
              <div className="skeleton-header-name">
                <div className="skeleton-header-line">
                  <Skeleton width={`40%`} />
                </div>
                <div className="skeleton-header-line">
                  <Skeleton width={`30%`} />
                </div>
              </div>
            </div>
          </CardBody>

          <Skeleton height={492} />

          <CardBody className="pt-2">
            <div className="mt-2 mb-3">
              <Skeleton height={16} width={`90%`} />
              <Skeleton height={16} width={`100%`} />
              <Skeleton height={16} width={`80%`} />
            </div>

            <div className="mb-0">
              <Skeleton height={24} width={80} />
              <span className="ml-2">
                <Skeleton height={24} width={90} />
              </span>
            </div>
          </CardBody>
        </Card>
      </div>
    </SkeletonTheme>
  );
};

export default CaseCardLoading;
