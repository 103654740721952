/** @format */

import React from "react";
import PropTypes from "prop-types";
import { ListGroupItem } from "reactstrap";
import LinesEllipsis from "react-lines-ellipsis";
import searchTextImage from "../../../assets/images/searchTextImage.png";
import searchVideoImage from "../../../assets/images/video_case_square.png";

const SearchCaseResultItem = ({ image, title, summary, onClick, type }) => {
  return (
    <ListGroupItem
      className="d-inline-flex align-items-center px-0 cursor-pointer"
      onClick={onClick}>
      <div className="mr-3">
        <img
          width="75"
          height="75"
          src={
            type === "video"
              ? searchVideoImage
              : image
              ? image
              : searchTextImage
          }
          alt=""
        />
      </div>
      <div>
        {title && (
          <LinesEllipsis
            className="mb-0 text-14 font-weight-bold"
            text={title}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        )}
        {summary && (
          <LinesEllipsis
            className="mb-0 text-14"
            text={summary}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        )}
      </div>
    </ListGroupItem>
  );
};

SearchCaseResultItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

SearchCaseResultItem.defaultProps = {
  image: ""
};

export default SearchCaseResultItem;
