import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { FEED_NAMES } from "../../constants/feed-type";
import { HOME_ROOT_FEED } from "../../constants/routes";
import { getRouteFromLegacyUrl } from "../../utils/legacy-url-utils";
import { getUrl } from "../../utils/route-utils";

const RedirectPage = () => {
  const url = window.location.href;
  const [newUrl, setNewUrl] = useState(null);
  const [urlFetched, setUrlFetched] = useState(false);
  let history = useHistory();

  useEffect(() => {
    async function getRedirectUrl() {
      const r = await getRouteFromLegacyUrl(url);
      setNewUrl(r);
      setUrlFetched(true);
    }

    getRedirectUrl();
  }, [url]);

  useEffect(() => {
    if (urlFetched) {
      if (!newUrl || newUrl.indexOf("/rfy") > -1) {
        history.replace(
          getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED })
        );
      } else {
        const urlObj = new URL(newUrl);
        history.replace(urlObj.pathname);
      }
    }
  }, [history, newUrl, urlFetched]);

  return <Loading />;
};

export default RedirectPage;
