import React from "react";
import i18n from "../../utils/i18n";
import useDialog from "../dialog/useDialog";
import { StaticStrings } from "../../constants/static-string-constants";

const CasePostPrivacy = () => {
  /** ********************************** CONFIG ***************************************/
  const { inform } = useDialog();

  /** ********************************* FUNCTIONS *************************************/

  const onReadMore = async () => {
    await inform({
      title: i18n.t("privacy.howWeProtect"),
      body: (
        <>
          <p>{i18n.t("privacy.privacyText1")}</p>
          <p>{i18n.t("privacy.privacyText2")}</p>
          <p className="mb-0">{i18n.t("privacy.privacyText3")}</p>
        </>
      ),
      primaryButtonText: i18n.t("privacy.privacyButtonOk"),
      secondaryButtonText: i18n.t("privacy.privacyButtonReadMore"),
      onSecondaryClick: navigateToPrivacy
    });
  };

  const navigateToPrivacy = () => {
    window.open(StaticStrings.privacyLink, "_blank");
  };

  /** ********************************** RENDER ***************************************/

  return (
    <p
      className="d-flex w-100 justify-content-center text-cool-blue cursor-pointer m-0"
      onClick={onReadMore}>
      {i18n.t("privacy.howWeProtect")}
    </p>
  );
};

export default CasePostPrivacy;
