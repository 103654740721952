/** @format */
import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const outer = {
  backgroundColor: "white",
  height: "113px"
};
const topRowText = {
  color: "white",
  fontSize: "14px",
  whiteSpace: "pre-line",
  padding: "0px 10px",
  lineHeight: "18px"
};
const rightMatter = {
  padding: "4px"
};
const greenBlock = {
  margin: "15px",
  height: "36px",
  padding: "10px",
  borderRadius: "4px"
};

const CMECompletedActivitiesHeader = ({ totalCredits, creditsThisMonth }) => {
  return (
    <div style={outer}>
      <div className="bg-denim-blue d-flex flex-row align-items-center justify-content-between">
        <div style={topRowText}>{i18n.t("CMEScreens.creditEarned")}</div>
        <div className="d-flex flex-column align-items-end" style={rightMatter}>
          <span style={topRowText}>{creditsThisMonth.toFixed(2)}</span>
          <span style={topRowText}>{i18n.t("CMEScreens.cme1")}</span>
        </div>
      </div>
      <div
        className="bg-green d-flex flex-row align-items-center justify-content-between"
        style={greenBlock}>
        <div className="d-flex flex-row align-items-center">
          <CustomIcon icon={"drawer_CME"} color={"#3bafa8"} size={18} />
          <span className="color-aqua">{i18n.t("CMEScreens.cme1Earned")}</span>
        </div>
        <span className="color-aqua">{totalCredits.toFixed(2)}</span>
      </div>
    </div>
  );
};

CMECompletedActivitiesHeader.propTypes = {
  totalCredits: PropTypes.number.isRequired,
  creditsThisMonth: PropTypes.number.isRequired
};

export default CMECompletedActivitiesHeader;
