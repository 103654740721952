/** @format */

import React from "react";
import { ListGroupItem } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const SearchProfileResultItemLoading = () => {
  return (
    <ListGroupItem className="d-inline-flex align-items-center justify-content-between px-0">
      <Skeleton className="mr-3" circle={true} height={50} width={50} />
      <div className="flex-fill">
        <div className="skeleton-header-line">
          <Skeleton className="skeleton-header-line" width={`40%`} />
        </div>
        <div className="skeleton-header-line">
          <Skeleton className="skeleton-header-line" width={`40%`} />
        </div>
      </div>
      <Skeleton width={`80px`} height={`30px`} />
    </ListGroupItem>
  );
};

export default SearchProfileResultItemLoading;
