import React from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import PropTypes from "prop-types";
import CustomIcon from "../custom-icon/CustomIcon";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AddCustomItemModal = ({
  toggle,
  isOpen,
  isValid,
  onSubmit,
  customLabel,
  customPlaceholder,
  dividerClass = "border-bottom border-width-4 lighter-gray w-100 pt-3 px-4",
  confirmText = "common.confirm",
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();

  const { control, watch } = useForm({
    mode: "onChange"
  });

  const customValue = watch("customItem");

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const saveCustomItem = () => {
    onSubmit(customValue);
  };

  /************************************ RENDER ***************************************/

  return (
    <Modal
      className="modal-profile modal-dialog-scrollable modal-profile-edit"
      fade={false}
      centered
      isOpen={isOpen}
      toggle={toggle}
      {...rest}>
      <div
        className="d-flex justify-content-end cursor-pointer p-2"
        onClick={toggle}>
        <CustomIcon
          icon="modal_exit"
          className="icon-default-black"
          size={14}
        />
      </div>
      <form>
        <ModalBody>
          <FormGroup>
            <Label for="customItem">{customLabel}</Label>
            <Controller
              className="mr-1"
              as={Input}
              control={control}
              id="customItem"
              name="customItem"
              defaultValue=""
              type="text"
              placeholder={customPlaceholder}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-inline-flex justify-content-center align-items-center w-100 border-top pt-4">
          <Button
            color="primary"
            onClick={saveCustomItem}
            disabled={customValue?.length === 0}
            className="text-16 p-2 w-125px">
            {t(confirmText)}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

AddCustomItemModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  route: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

AddCustomItemModal.defaultProps = {};

export default AddCustomItemModal;
