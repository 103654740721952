export const REGISTRATION_STATES = {
  INTRO: "intro",
  LOGIN: "login",
  LOGIN_FAILED: "login-failed",
  RESET_PASSWORD: "reset-password"
};

export const RESET_PASSWORD_STATES = {
  RESET: "reset",
  USERNAME_MATCH: "match",
  USERNAME_NO_MATCH: "no-match"
};

export const REGISTRATION_PROFILE_STATES = {
  VERIFIED_WITH_SPECIALITY: "verified-with-specialty",
  VERIFIED_NO_SPECIALITY: "verified-no-specialty",
  UNVERIFIED_WITH_SPECIALITY: "unverified-with-specialty",
  UNVERIFIED_NO_SPECIALITY: "unverified-no-specialty",
  NON_HCP: "non-hcp",
  UNMAPPED_PROFESSION: "unmapped-profession",
  EDITORIAL_PARTNER_SUBSCRIBER: "editorial-partner-subscriber"
};

export const REGISTRATION_VERIFICATION_STATES = {
  SELECT: "select",
  NPI_NUMBER: "npi-number",
  MEDICAL_LICENSE: "medical-license",
  PHOTO: "photo"
};

export const REGISTRATION_PHOTO_STATES = {
  PHOTO: "photo",
  EMAIL: "email",
  DONE: "done"
};
