import Firebase from "../firebase";

const functions = Firebase.functions;

export const trackDetailViews = async (caseUuids) => {
  const result = await functions.httpsCallable("backendTrackDetailViews")({
    caseUuids
  });
  return result.data;
};

export const trackFeedViews = async (feedViews) => {
  const result = await functions.httpsCallable("backendTrackFeedViews")({
    views: feedViews
  });
  return result.data;
};
