/** @format */

import _ from "lodash";
import Regex from "../constants/regex";

/**
 * Validate color string and returns a default color when it's invalid
 * Use this whenever takes color param from external source like firestore data
 *
 * @format
 * param: input, color string
 *
 * return: a valid color string
 */
export const rectifyColorInput = (input) => {
  // append # if absent
  let color = _.startsWith(input, "#") ? input : `#${input}`;

  // check if the color string is valid
  if (!Regex.Color.test(color)) {
    color = "rgba(0,0,0,0)";
  }
  return color;
};
