/** @format */

import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchSuggestions,
  clearSearchSuggestions,
  search
} from "../actions/search.actions";
import { isEmpty, debounce } from "lodash";
import { useSelectSearchSuggestions } from "../selectors/search.selectors";

/**
 * How to use this:
 * 1) Component must manage a "search term" state.
 * 2) Pass that "search term" to this.
 * 3) This then fetches updates as search term updates.
 *
 * @param searchTerm
 * @returns {{showSearchSuggestions: boolean, searchSuggestions}}
 */
export const useGetSearchSuggestions = (searchTerm) => {
  const dispatch = useDispatch();

  const { searchSuggestions, isLoading } = useSelectSearchSuggestions();

  const debounceGetSearchSuggestions = useCallback(
    debounce((_searchTerms) => {
      if (_searchTerms) {
        dispatch(getSearchSuggestions(_searchTerms));
      } else {
        // Do this to prevent showing old search suggestions when
        // user types new search terms and need to wait for new suggestions
        dispatch(clearSearchSuggestions());
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    debounceGetSearchSuggestions(searchTerm);
  }, [debounceGetSearchSuggestions, searchTerm]);

  const showSearchSuggestions =
    !!searchTerm && !isLoading && !isEmpty(searchSuggestions);

  return { searchSuggestions, showSearchSuggestions };
};

/**
 * Return a function that dispatches a search action with given
 * searchTerms, searchCursor and searchResultType that searches
 * the "everything" feed kind.
 */
export const useGetSearchResults = () => {
  const dispatch = useDispatch();
  const everythingFeedUuid = useSelector(
    (state) => state?.userFeedMeta?.everythingFeed?.feed_type_uuid
  );

  return (
    { searchTerm, searchResultType, isPaginating, searchIndex },
    callback
  ) => {
    dispatch(
      search(
        {
          searchTerm,
          searchResultType,
          searchIndex: searchIndex || [everythingFeedUuid],
          isPaginating
        },
        callback
      )
    );
  };
};
