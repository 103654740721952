import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";

import i18n from "../../../utils/i18n";
import "./LegacyTransitionModal.scss";
import FormUserInfoV2 from "./FormUserInfo.v2";
import LoadingButton from "../../../components/common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../actions/user-profiles.actions";
import FormGlobalError from "./FormGlobalError";
import {
  trackVerifyNpi,
  trackVerifyNpiEntry
} from "../../../actions/metrics.actions";
import { postVerification } from "../../../actions/user-verification.actions";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";

const NpiConfirmationModal = ({ isOpen, ...rest }) => {
  const {
    onCancel,
    existingData,
    performNavigate = true,
    performTracking = true,
    performNpiVerification = true
  } = rest;
  /************************************ CONFIG ***************************************/
  const { handleSubmit, control, errors, watch, setValue, getValues } = useForm(
    {
      mode: "onChange"
    }
  );
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const npiData = useSelector((state) => state.userVerification?.npiData);
  const onboardingNavigate = useOnboardingNavigation();

  const attemptedNpiNumber = useSelector(
    (state) => state.userVerification?.attemptedNpiNumber
  );
  const verificationMessage = useSelector(
    (state) => state.userVerification?.message
  );
  const fetchedNpiIncomplete = useSelector(
    (state) => state.userVerification?.incomplete
  );
  const npiIncomplete =
    fetchedNpiIncomplete === true ||
    (npiData && !(npiData.firstName && npiData.specialty && npiData.specialty));
  const npiNumber = npiData?.npiNumber || attemptedNpiNumber;

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (isOpen && performTracking) {
      trackVerifyNpiEntry();
    }
  }, [isOpen, performTracking]);

  /*********************************** FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      formData.profession === undefined ||
      (Object.keys(formData).indexOf("specialty") > -1 &&
        formData.specialty === undefined) ||
      (Object.keys(formData).indexOf("hiddenSpecialty") > -1 &&
        formData.hiddenSpecialty === undefined)
    ) {
      return;
    }

    setIsSaving(true);
    setErrorMessage("");

    let specialtyId = null;
    if (formData.specialty) {
      specialtyId = formData.specialty.value
        ? formData.specialty.value
        : formData.specialty;
    } else if (formData.hiddenSpecialty) {
      specialtyId = formData.hiddenSpecialty;
    }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      primarySpecialty: specialtyId ? specialtyId : formData.profession?.value,
      force_synchronous: true
    };

    if (performTracking) {
      trackVerifyNpi({ ...payload, npiNumber: npiNumber });
    }

    try {
      // Save npi request first
      const result = await dispatch(updateUserProfile(payload));
      let responseUser = result?.user;

      if (!result || result.error) {
        throw new Error(result?.message || "Error updating user information");
      }

      // don't send verification for npi if we don't have it
      if (performNpiVerification) {
        const verificationResult = await dispatch(
          postVerification({
            method: "npi",
            npiNumber: parseInt(npiNumber)
          })
        );

        responseUser = verificationResult?.user;
        if (!verificationResult || verificationResult.error) {
          throw new Error(
            verificationResult?.error ||
              "Error sending NPI verification request"
          );
        }
      }

      // move to next step
      if (performNavigate) {
        onboardingNavigate(responseUser);
      } else {
        handleCancelClick();
      }
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };
  const handleCancelClick = () => {
    setIsSaving(false);
    onCancel();
  };
  /************************************ RENDER ***************************************/

  return (
    <Modal className="" centered isOpen={isOpen}>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <ModalHeader className="flex-column justify-content-center align-items-center mt-3">
          <div className="helv-bold text-center text-18 mb-1">
            {i18n.t("RegistrationScreens.verificationNpiPage.v2.modal.header")}
          </div>
          {npiNumber && (
            <p className="d-flex justify-content-center align-items-center color-blue-gray text-center text-16 mb-0">
              {i18n.t("RegistrationScreens.verificationNpiPage.v2.modal.npiNo")}{" "}
              {npiNumber}
              {i18n.t(
                "RegistrationScreens.verificationNpiPage.v2.modal.npiNotYou"
              )}
              <Button
                className="px-0 ml-1 text-16"
                onClick={handleCancelClick}
                color="link">
                {i18n.t(
                  "RegistrationScreens.verificationNpiPage.v2.modal.btnTryAgain"
                )}
              </Button>
            </p>
          )}
        </ModalHeader>
        <ModalBody className="pb-4">
          <FormGlobalError errorText={errorMessage} />
          {attemptedNpiNumber && npiIncomplete && !verificationMessage && (
            <div className="alert bg-secondary color-blue-gray">
              {i18n.t(
                "RegistrationScreens.verificationNpiPage.v2.modal.notFound"
              )}
            </div>
          )}
          {npiIncomplete && verificationMessage && (
            <FormGlobalError
              classNames="text-left"
              errorText={i18n.t(verificationMessage)}
            />
          )}
          <FormUserInfoV2
            existingData={existingData ? existingData : npiData}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
          />
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between align-items-center border-0 pb-4 mb-0 pt-3">
          <LoadingButton
            size="lg"
            color="secondary"
            onClick={handleCancelClick}
            className={`text-16 ${isMobile ? "min-w-130px" : "min-w-171px"}`}>
            {i18n.t("RegistrationScreens.verificationNpiPage.modalCancel")}
          </LoadingButton>

          <LoadingButton
            size="lg"
            color="primary"
            loading={isSaving}
            className={`text-16 ${isMobile ? "min-w-130px" : "min-w-171px"}`}>
            {i18n.t("RegistrationScreens.verificationNpiPage.modalConfirm")}
          </LoadingButton>
        </ModalFooter>
      </form>
    </Modal>
  );
};

NpiConfirmationModal.defaultProps = {};

export default NpiConfirmationModal;
