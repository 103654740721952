/** @format */

import React from "react";
import PropTypes from "prop-types";
import { ContentProp } from "../../../prop-types/ContentProp";
import CMNormalSlide from "./CMNormalSlide";

const CMConclusionSlide = ({ content, finalAction, ...props }) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return <CMNormalSlide content={content} {...props} />;
};

CMConclusionSlide.propTypes = {
  content: ContentProp,
  finalAction: PropTypes.func
};

export default CMConclusionSlide;
