/** @format */

import React from "react";
import PropTypes from "prop-types";
import { isEmpty, upperCase } from "lodash";
import ProfileInfoCardRow from "./ProfileInfoCardRow";
import {
  PROFILE_INFO_CARD_STATE,
  PROFILE_INFO_TYPE
} from "../../../constants/profile-info-constants";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const ProfileInfoCard = ({ type, items = [], state, onNew, onDelete }) => {
  /************************************ CONFIG ***************************************/
  const displayOnlyMode = state === PROFILE_INFO_CARD_STATE.DISPLAY;
  // show prompt text when the card is in edit mode and there is no existing item
  const showEmptyPrompt = !displayOnlyMode && isEmpty(items);

  const cardTitle = () => {
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        return i18n.t("Profile.experienceTitle");
      case PROFILE_INFO_TYPE.EDUCATION:
        return i18n.t("Profile.education");
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        return i18n.t("Profile.membership");
      case PROFILE_INFO_TYPE.SUBSPECIALTIES:
        return i18n.t("Profile.subspecialties");
      case PROFILE_INFO_TYPE.PRACTICE_AREA:
        return i18n.t("Profile.practiceArea");
      default:
        return "";
    }
  };

  const getInfoCardRowKey = (type, item) => {
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        return item.experienceUuid;
      case PROFILE_INFO_TYPE.EDUCATION:
        return item.educationUuid;
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        return item.affiliationUuid;
      default:
        return undefined;
    }
  };

  const body =
    type === PROFILE_INFO_TYPE.SUBSPECIALTIES ||
    type === PROFILE_INFO_TYPE.PRACTICE_AREA ? (
      <p className="mb-0">
        {items.reduce(
          (acc, cur, index) =>
            acc + cur.name + (index === items.length - 1 ? "" : ", "),
          ""
        )}
      </p>
    ) : (
      items.map((item, index) => {
        return (
          <ProfileInfoCardRow
            key={getInfoCardRowKey(type, item) ?? index}
            data={item}
            editing={!displayOnlyMode}
            onDelete={onDelete}
          />
        );
      })
    );

  const prompt = (() => {
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        return i18n.t("Profile.experiencePrompt");
      case PROFILE_INFO_TYPE.EDUCATION:
        return i18n.t("Profile.educationPrompt");
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        return i18n.t("Profile.affiliationPrompt");
      case PROFILE_INFO_TYPE.SUBSPECIALTIES:
        return i18n.t("Profile.subspecialtyPrompt");
      case PROFILE_INFO_TYPE.PRACTICE_AREA:
        return i18n.t("Profile.practiceAreaPrompt");
      default:
        return "";
    }
  })();

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <>
      <Card className="profile-info-card text-left">
        <CardHeader>
          {upperCase(cardTitle())}
          {displayOnlyMode ? null : (
            <Button
              className="btn-add btn-text btn-circle p-0"
              color="denim-blue-opaque"
              size="xs"
              onClick={onNew}>
              <CustomIcon
                className="icon-denim-blue"
                icon={"upload_shortcut"}
                size={18}
              />
            </Button>
          )}
        </CardHeader>
        <CardBody>
          {showEmptyPrompt ? <p className="text-muted mb-0">{prompt}</p> : body}
        </CardBody>
      </Card>
    </>
  );
};

ProfileInfoCard.propTypes = {
  type: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      date: PropTypes.string
    })
  ),
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired
};

export default ProfileInfoCard;
