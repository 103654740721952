import React from "react";
import Dialog from "./Dialog";
import DialogContext from "./DialogContext";

export const DialogProvider = ({ children }) => {
  /************************************ CONFIG ***************************************/
  const [confirmationState, setConfirmationState] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const awaitingPromiseRef = React.useRef({
    resolve: Promise.resolve,
    reject: Promise.reject
  });

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const openConfirmation = (options) => {
    setConfirmationState(options);
    setIsOpen(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }

    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    setIsOpen(false);
  };

  const handleClosed = () => {
    setConfirmationState(null);
  };

  /************************************ RENDER ***************************************/

  return (
    <>
      <DialogContext.Provider value={openConfirmation} children={children} />
      <Dialog
        isOpen={isOpen}
        onConfirm={handleSubmit}
        onCancel={handleClose}
        onClosed={handleClosed}
        {...confirmationState}
      />
    </>
  );
};

export default DialogProvider;
