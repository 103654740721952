import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { trim } from "lodash";
import i18n from "../../utils/i18n";
import PropTypes from "prop-types";
import { COMMENT_TYPES } from "../../constants/case-constants";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import useIsGated from "../../hooks/useIsGated";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

import "./CommentInput.scss";
import LoadingButton from "../common/LoadingButton";
import useUserHasGroups from "../../hooks/useUserHasGroups";
/**
 * Component mimicking comment text input and post button,
 * used to show gated modal.
 */
const FakeCommentInput = ({
  placeholderText = "",
  buttonText = "",
  onClick = () => {}
}) => {
  const { theme } = useTheme();

  const color = theme === THEMES.DARK ? "light-gray" : "cool-blue";
  const backgroundColor = theme === THEMES.DARK ? "black" : "white";

  return (
    <Button
      onClick={onClick}
      className={classNames(
        "fake-comment-input-container no-decoration-button w-100 border-light-gray bg-transparent",
        backgroundColor
      )}>
      <div className="d-flex justify-content-between align-items-center w-100">
        <p className="fake-text-input m-0 color-f1-gray">{placeholderText}</p>
        <p
          className={classNames("fake-post-button font-weight-500 m-0", color)}>
          {buttonText}
        </p>
      </div>
    </Button>
  );
};

FakeCommentInput.propTypes = {
  placeholderText: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func
};

const CommentInput = React.forwardRef(
  (
    {
      parentComment,
      onPost,
      borderRadius,
      commentType,
      loading,
      onFocus = () => {},
      onCancelClick = () => {},
      existingText = "",
      isGroup = false
    },
    ref
  ) => {
    /** ********************************** CONFIG ***************************************/

    const [placeholderText, setPlaceholderText] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);
    const [text, setText] = useState(existingText);
    const { theme } = useTheme();
    const isGated = useIsGated();
    const editMode = commentType === COMMENT_TYPES.EDIT;
    const userHasGroups = useUserHasGroups();

    /** ********************************** HOOKS ****************************************/

    useEffect(() => {
      if (commentType === COMMENT_TYPES.COMMENT) {
        setPlaceholderText(
          i18n.t(
            isGroup
              ? "comments.addGroupComment"
              : userHasGroups
              ? "comments.addPublicComment"
              : "comments.addComment"
          )
        );
        setButtonText(i18n.t("comments.postText"));
      } else if (commentType === COMMENT_TYPES.REPLY) {
        setPlaceholderText(
          i18n.t(
            isGroup
              ? "comments.addGroupReply"
              : userHasGroups
              ? "comments.addPublicReply"
              : "comments.addReply"
          )
        );
        setButtonText(i18n.t("comments.replyText"));
      } else if (editMode) {
        setPlaceholderText("");
        setButtonText(i18n.t("comments.editCommentButton"));
      }
    }, [commentType, editMode, isGroup]);
    /** ********************************* FUNCTIONS *************************************/

    const onChange = (e) => {
      setHasChanged(e.target.value !== existingText);
      setText(e.target.value);
    };

    const validatePost = async () => {
      if (trim(text).length > 0) {
        if (commentType !== COMMENT_TYPES.EDIT || (editMode && hasChanged)) {
          const success = await onPost(text, parentComment);
          if (success) {
            setText(editMode ? text : "");
          }
        }
      }
    };

    const gatedValidatePost = useGatedActionGate(
      validatePost,
      GATED_MODAL_TYPE.COMMENT,
      UNGATED_ACTIONS.ORIGINAL_ACTIONS.COMMENT_POST
    );
    /** ********************************** RENDER ***************************************/
    return (
      <div style={{ position: "relative" }}>
        {isGated ? (
          <FakeCommentInput
            placeholderText={placeholderText}
            buttonText={buttonText}
            onClick={gatedValidatePost}
          />
        ) : (
          <div
            className={`autoresize-textarea-container d-flex align-items-stretch pr-1 ${
              editMode ? "edit-mode flex-column" : ""
            }`}
            style={{
              borderRadius: borderRadius ? borderRadius : "4px",
              backgroundColor: theme === THEMES.DARK ? "black" : "white"
            }}>
            <div
              className={classNames(
                "d-flex align-items-center",
                editMode ? "mb-2" : "w-85"
              )}>
              <TextareaAutosize
                defaultValue={text}
                className={classNames(
                  theme === THEMES.DARK
                    ? "autoresize-textarea bg-transparent text-white placeholder-light-gray"
                    : "autoresize-textarea"
                )}
                style={{
                  lineHeight: "1.3rem"
                }}
                ref={ref}
                onChange={onChange}
                onFocus={onFocus}
                minRows={1}
                maxRows={5}
                placeholder={placeholderText}
              />
            </div>
            <div
              className={`post-container d-flex align-items-end ${
                editMode ? "edit-mode justify-content-end" : ""
              }`}>
              {editMode && onCancelClick && (
                <Button
                  disabled={loading}
                  outline
                  onClick={onCancelClick}
                  className={classNames(
                    "btn-text no-focus-button border-0",
                    loading && "p-0"
                  )}
                  color={
                    theme === THEMES.DARK ? "light-gray" : "battleship-gray"
                  }>
                  <span className="font-weight-500">
                    {i18n.t("common.cancel")}
                  </span>
                </Button>
              )}
              <LoadingButton
                disabled={(editMode && !hasChanged) || loading ? true : false}
                outline
                onClick={gatedValidatePost}
                className={classNames("btn-text no-focus-button border-0")}
                color={theme === THEMES.DARK ? "light-gray" : "cool-blue"}
                loading={loading}>
                <span className="font-weight-500">{buttonText}</span>
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    );
  }
);

CommentInput.propTypes = {
  borderRadius: PropTypes.string,
  commentType: PropTypes.string,
  buttonText: PropTypes.string,
  existingText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onPost: PropTypes.func
};

export default CommentInput;
