import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ConditionalLink = ({ to, children }) => {
  return to ? <Link to={to}>{children}</Link> : <>{children}</>;
};

ConditionalLink.propTypes = {
  to: PropTypes.any
};

export default ConditionalLink;
