import { useEffect, useRef } from "react";

function useEventListener(element, eventType, listener) {
  const listenerRef = useRef(listener);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const eventListener = (evt) => listenerRef.current.call(element, evt);

    element.addEventListener(eventType, eventListener);

    return () => {
      element.removeEventListener(eventType, eventListener);
    };
  }, [eventType, element]);
}

export default useEventListener;
