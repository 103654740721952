/** @format */

import { isEmpty } from "lodash";
import { searchCasesCall, searchUsersCall } from "../api/cloud-functions";
import { SEARCH_RESULT_TYPES } from "../constants/search-result-constants";
import { fetchSearchSuggestions } from "../utils/autocomplete-utils";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "search";

export const SET_SEARCH_CRITERIA = `${actionsPrefix}/SET_SEARCH_CRITERIA`;
export const RESET_SEARCH = `${actionsPrefix}/RESET_SEARCH`;
export const SEARCH_START = `${actionsPrefix}/SEARCH_START`;
export const SEARCH_COMPLETE = `${actionsPrefix}/SEARCH_COMPLETE`;
export const SEARCH_ERROR = `${actionsPrefix}/SEARCH_ERROR`;
export const FETCH_SEARCH_SUGGESTIONS = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS`;
export const FETCH_SEARCH_SUGGESTIONS_COMPLETE = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS_COMPLETE`;
export const FETCH_SEARCH_SUGGESTIONS_ERROR = `${actionsPrefix}/FETCH_SEARCH_SUGGESTIONS_ERROR`;
export const CLEAR_SEARCH_SUGGESTIONS = `${actionsPrefix}/CLEAR_SEARCH_SUGGESTIONS`;
export const UPDATE_SEARCH_TERM = `${actionsPrefix}/UPDATE_SEARCH_INPUT`;

const CURSOR_INCREMENT = 20; // Backend sends in batches up to 20

export const setSearchCriteria = ({
  searchTerm,
  searchIndex,
  searchResultType
}) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SEARCH_CRITERIA,
      payload: { searchTerm, searchIndex, searchResultType }
    });
  };
};

/**
 * Send a search request to case or profile index in Elastic search.
 * Save search term and search index (feed uuid) in local state for sharing.
 * Optionally paginate search and call given callback.
 *
 * On new search term search, reset pagination. On results received, overwrite
 * previous search results for searchResultType.
 */
export const search = (
  { searchTerm, searchIndex, searchResultType, isPaginating = false },
  callback
) => {
  return async (dispatch, getState) => {
    try {
      const searchState = getState()?.search;
      const isLoading = searchState.isLoading;
      if (!searchTerm || isLoading) {
        return;
      }

      const userUid = getState().user?.userUid;
      let searchCursor = searchState.searchCursor;
      const prevSearchTerm = searchState.searchTerm;
      const prevSearchResultType = searchState.searchResultType;
      const prevSearchResults =
        searchState?.searchResults?.[prevSearchResultType];

      const isNewSearch =
        isEmpty(prevSearchResults) ||
        prevSearchTerm !== searchTerm ||
        prevSearchResultType !== searchResultType;

      dispatch({
        type: SEARCH_START
      });

      dispatch(
        setSearchCriteria({ searchTerm, searchIndex, searchResultType })
      );

      if (isPaginating) {
        searchCursor = isNewSearch ? 0 : searchCursor + CURSOR_INCREMENT;
      }

      let response = null;
      if (searchResultType === SEARCH_RESULT_TYPES.CASE) {
        response = await searchCasesCall({
          searchTerm,
          searchCursor,
          searchIndex,
          userUid
        });
      } else {
        response = await searchUsersCall({
          searchTerm,
          searchCursor
        });
      }

      if (response.error || !isRequestSuccess(response?.data)) {
        dispatch({
          type: SEARCH_ERROR,
          payload: {
            error: new Error(response.error),
            searchResultType
          }
        });

        return;
      }

      const searchResults = response?.data?.store?.search_results;

      dispatch({
        type: SEARCH_COMPLETE,
        payload: {
          isNewSearchTerm: isNewSearch,
          searchResults: searchResults,
          searchResultType,
          isPaginating,
          searchCursor
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_ERROR,
        payload: {
          error,
          searchResultType
        }
      });
    } finally {
      if (callback) {
        callback();
      }
    }
  };
};

export const resetSearch = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH
    });
  };
};

/**** Search Suggestion Actions ****/

export const getSearchSuggestions = (searchTerms) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_SEARCH_SUGGESTIONS });

    const {
      elasticsearchAuth,
      elasticsearchCloudId
    } = getState()?.configuration?.elasticSearch;

    try {
      const results = await fetchSearchSuggestions({
        accessToken: elasticsearchAuth?.access_token,
        cloudId: elasticsearchCloudId,
        searchTerms
      });

      if (results?.timed_out || results?.error) {
        dispatch({
          type: FETCH_SEARCH_SUGGESTIONS_ERROR,
          payload: {
            error: results?.error?.reason || "'getSearchSuggestions' timeout."
          }
        });
        return;
      }

      dispatch({
        type: FETCH_SEARCH_SUGGESTIONS_COMPLETE,
        payload: {
          data: results
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_SEARCH_SUGGESTIONS_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

export const clearSearchSuggestions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_SUGGESTIONS
    });
  };
};

export const updateSearchTerm = (input) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_TERM,
      payload: {
        input: input
      }
    });
  };
};
