/** @format */
import React from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "../../utils/class-names-utils";

const HeadingBanner = ({ text, isPoll }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <CardBody
      className={classNames(
        "py-2",
        isPoll ? "bg-aqua-opaque" : "bg-denim-blue-light"
      )}>
      <p
        className={classNames(
          "mb-0  font-weight-500 text-uppercase text-13 line-height-tight",
          isPoll ? "text-aqua" : "text-denim-blue"
        )}>
        {text}
      </p>
    </CardBody>
  );
};

HeadingBanner.propTypes = {
  text: PropTypes.string.isRequired,
  isPoll: PropTypes.bool
};

HeadingBanner.defaultProps = {
  isPoll: false
};

export default HeadingBanner;
