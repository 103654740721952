/** @format */
import React from "react";
import PropTypes from "prop-types";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

import "./ProminentHeader.scss";

const ProminentHeader = ({ icon = "CME_centre", title, subtitle }) => {
  return (
    <div className="d-flex flex-column bg-gradient justify-content-center prominent-header">
      <div className="d-flex flex-column text-block">
        <CustomIcon icon={icon} color={"white"} size={48} />
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

ProminentHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default ProminentHeader;
