/** @format */

import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardText, FormGroup, ListGroup } from "reactstrap";
import ReactMarkdown from "react-markdown";
import TextareaAutosize from "react-textarea-autosize";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import { quizUpdateTextInput } from "../../../actions/quiz.actions";
import { ContentProp } from "../../../prop-types/ContentProp";
import {
  useSelectUserTextInput,
  useSelectUserVote
} from "../../../selectors/interactive-case-state";
import i18n from "../../../utils/i18n";
import { isFreeFormQuizContent } from "../../../utils/tactic-utils";
import TagButton from "../../../components/case/TagButton";
import VoteOptionsList from "../../../components/case/VoteOptionsList";
import CMSlideBase from "./CMSlideBase";

const convertTag = (text) => {
  return capitalize(text.replace("_", "-"));
};

const CMEQuestionSlide = ({ content, index, heading, onVote }) => {
  /****************************** Config **************************************/
  const dispatch = useDispatch();
  const userAnswer = useSelectUserVote(content.caseUuid, content.contentUuid);
  const textInput = useSelectUserTextInput(
    content.caseUuid,
    content.contentUuid
  );
  const isFreeForm = isFreeFormQuizContent(content);

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <CMSlideBase content={content}>
      <Card className="border-0 rounded-0">
        <CardBody className="d-inline-flex justify-content-between align-items-center w-100">
          <ListGroup
            className={"list-group-spacing-x-2 flex-wrap mb-n2"}
            horizontal>
            <TagButton color="battleship-gray">{convertTag(heading)}</TagButton>
            <TagButton color="battleship-gray">{index}</TagButton>
          </ListGroup>
        </CardBody>

        <CardBody className="pt-0">
          <CardText
            className="feed-card-info feed-card-info-markdown whitespace-pre-line feed-card-detail"
            tag="div">
            <ReactMarkdown>{content.caption}</ReactMarkdown>
          </CardText>
        </CardBody>

        <CardBody className="pt-0">
          {isFreeForm ? (
            <FormGroup>
              <TextareaAutosize
                className="autoresize-textarea form-control resize-none"
                minRows={4}
                placeholder={i18n.t("common.genericTextPlaceholder")}
                defaultValue={textInput || ""}
                onChange={(ev) => {
                  dispatch(
                    quizUpdateTextInput(content.contentUuid, ev.target.value)
                  );
                }}
              />
            </FormGroup>
          ) : (
            <VoteOptionsList
              options={content.questionOptions}
              onVote={onVote}
              isCME={true}
              userAnswer={userAnswer}
              allowReselect={true}
            />
          )}
        </CardBody>
      </Card>
    </CMSlideBase>
  );
};

CMEQuestionSlide.propTypes = {
  content: ContentProp.isRequired,
  heading: PropTypes.string.isRequired,
  onVote: PropTypes.func.isRequired
};

export default CMEQuestionSlide;
