import React, { useEffect, useState } from "react";
import { Card, CardBody, CardText } from "reactstrap";
import FollowUserButton from "../common/FollowUserButton";
import CaseUserCell from "./CaseUserCell";

const CaseDetailAuthor = ({ className, caseContent }) => {
  /** ********************************** CONFIG ***************************************/

  const [author, setAuthor] = useState(null);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (caseContent && Array.isArray(caseContent.authors)) {
      setAuthor(caseContent.authors[0]);
    }
  }, [caseContent]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  const bio =
    author && author.userBio ? (
      <CardBody className="pt-0">
        <CardText className="text-default-black text-14 opacity-90">
          {author?.userBio}
        </CardText>
      </CardBody>
    ) : null;

  return (
    <>
      {author && caseContent && (
        <Card className={className}>
          <CardBody className="d-inline-flex justify-content-between align-items-center w-100">
            <CaseUserCell caseContent={caseContent} />
            {author && !author.isDeleted && (
              <FollowUserButton
                className="text-14"
                style={{ minWidth: "7.5rem" }}
                userUuid={author.userUuid}
                hasAvatar={!!author.avatar}
                username={author.username}
                context="Case Detail"
              />
            )}
          </CardBody>
          {bio}
        </Card>
      )}
    </>
  );
};

export default CaseDetailAuthor;
