/** @format */

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  viewTranslatedComment,
  viewOriginalComment
} from "../actions/comment.actions";
import {
  selectIsLoadingTranslation,
  selectIsTranslationEnabled,
  selectIsViewingTranslation
} from "../selectors/comment.selectors";
import { selectCurrentLanguageCode } from "../selectors/localization.selectors";
import { findTranslation } from "../utils/translation-utils";

const useCommentTranslation = (caseUuid, comment) => {
  const dispatch = useDispatch();
  const isTranslationEnabled = useSelector((state) =>
    selectIsTranslationEnabled(state, comment.commentUuid, caseUuid)
  );

  const isLoadingTranslation = useSelector((state) =>
    selectIsLoadingTranslation(state, comment.commentUuid)
  );

  const isViewingTranslation = useSelector((state) =>
    selectIsViewingTranslation(state, comment.commentUuid)
  );

  const text = useSelector((state) => {
    if (isTranslationEnabled && isViewingTranslation) {
      const currentLanguageCode = selectCurrentLanguageCode(state);
      const translation = findTranslation(
        comment.translations,
        currentLanguageCode
      );
      if (translation) {
        return translation.text;
      }
    }

    return comment.text;
  });

  const toggleTranslation = useCallback(() => {
    if (isViewingTranslation) {
      dispatch(viewOriginalComment(comment.commentUuid));
    } else {
      dispatch(viewTranslatedComment(comment));
    }
  }, [comment, dispatch, isViewingTranslation]);

  return {
    text,
    isLoadingTranslation,
    isViewingTranslation,
    isTranslationEnabled,
    toggleTranslation
  };
};

export default useCommentTranslation;
