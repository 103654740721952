/** @format */

import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import F1Spacer from "../../../components/common/F1Spacer";
import useISI from "../../../components/isi/useISI";
import {
  ISI_HEADER_HEIGHT,
  ISI_FOOTER_HEIGHT
} from "../../../constants/isi-constants";

const TOP_INDICATOR_HEIGHT = 51;
const BOTTOM_SPACER_HEIGHT = 70;

const CMSlideBase = ({ content, children }) => {
  /****************************** Config **************************************/
  const hasMedia = !isEmpty(content?.media);
  const { showHeader, showFooter } = useISI(content ?? {}, false);
  const minHeightBuffer =
    pxToRem(TOP_INDICATOR_HEIGHT) +
    (showHeader ? pxToRem(ISI_HEADER_HEIGHT) : 0) +
    (showFooter ? pxToRem(ISI_FOOTER_HEIGHT) : 0);

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <div
      className="d-flex flex-column justify-content-start"
      style={{
        minHeight: `calc(100vh - ${minHeightBuffer}rem)`
      }}>
      {!hasMedia && <F1Spacer className="h-180px bg-white" />}
      {children}
      <F1Spacer
        className={`flex-grow-1 h-${BOTTOM_SPACER_HEIGHT}px bg-white`}
      />
    </div>
  );
};

function pxToRem(px, baseFontSize = 16) {
  return px / baseFontSize;
}

CMSlideBase.propTypes = {
  content: PropTypes.object,
  children: PropTypes.node
};

export default CMSlideBase;
