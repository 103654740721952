/** @format */

export const buildOptions = Object.freeze({
  DEV: "dev",
  QA: "qa",
  STAGING: "staging",
  RELEASE: "release",
});

export default {
  imgixUrl: "https://figure1.imgix.net/",
  versionBaseUrl: "https://s3.amazonaws.com/figure1-version-url/",
  buildEnv: buildOptions.STAGING,
  mixpanelToken: "110d57d253e07f9e58eeffdf534f2f9d",
  smartlookToken: "19ed977a1dd3bf37038ace520938bcbed9025d0e",
  uxcamToken: "5x4b1cqjv501akh",
  iterableAPIKey: "6379dab658a64e93a2090f4a549a487a",
  gtm_token: "GTM-WT3JTC7",
  gtm_auth: "4pTMF3I_7oJCQuKIMht3VQ",
  gtm_preview: "env-16",
  ipinfo: "5db3d1e380ae71",
};
