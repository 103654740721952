/** @format */

import React from "react";
import PropTypes from "prop-types";
import classNames from "../../utils/class-names-utils";

const F1GridList = ({
  data,
  gap = 2,
  numColumns = 3,
  renderItem,
  className,
  ListFooterComponent
}) => {
  return (
    <div className="f1-gird-list">
      <div
        className={classNames(
          `d-grid gap-${gap} grid-cols-${numColumns}`,
          className
        )}>
        {data.map((item, index) => {
          return renderItem({ item, index });
        })}
      </div>
      {ListFooterComponent && <ListFooterComponent />}
    </div>
  );
};

F1GridList.propTypes = {
  /**
   * The data to be rendered
   */
  data: PropTypes.arrayOf(PropTypes.any).isRequired,

  /**
   * The space between each item in the grid.
   */
  gap: PropTypes.number,

  /**
   * The number of columns in the grid.
   */
  numColumns: PropTypes.number,

  /**
   * Takes an item from data and renders it into the list. Typical usage:
   */
  renderItem: PropTypes.func.isRequired,

  /**
   * Rendered at the very end of the list.
   */
  ListFooterComponent: PropTypes.func,

  /**
   * Any additional class names to be added to the component.
   */
  className: PropTypes.string
};

export default F1GridList;
