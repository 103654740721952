import React from "react";
import { ListGroupItem } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const SearchCaseResultItemLoading = () => {
  return (
    <ListGroupItem className="d-inline-flex align-items-center justify-content-between px-0">
      <Skeleton className="mr-3" circle={true} height={50} width={50} />
      <div className="flex-fill"><Skeleton count={3} /></div>
    </ListGroupItem>
  );
};

export default SearchCaseResultItemLoading;
