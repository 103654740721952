/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const listenForChanges = async (callback, userUid) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USER_DRAFTS}/${userUid}/all`);

  return await ref.onSnapshot(callback, (onObservableNextOrError) => {
    console.error("load user drafts listener error: ", onObservableNextOrError);
  });
};

export const initUserCollection = async (userUid, userEmail) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USER_DRAFTS}`);

  return await ref.doc(userUid).set(
    {
      email: userEmail,
      user_uid: userUid
    },
    { merge: true }
  );
};

export const createNewDraft = async (
  userUid,
  selectedGroupUuid,
  isPaging,
  defaultLabelUuid
) => {
  const newDraftRef = await getCollectionRef(
    `${DB_CONSTANTS.USER_DRAFTS}/${userUid}/all`
  ).doc();
  await newDraftRef.set({
    title: "",
    media: [],
    caption: "",
    created: Date.now(),
    updated: Date.now(),
    specialtiesUuid: [],
    subSpecialtiesUuid: [],
    labelsUuid: [defaultLabelUuid],
    paging: isPaging,
    draftUid: newDraftRef.id,
    groupUuid: selectedGroupUuid
  });

  return newDraftRef.id;
};
