import React, { useEffect, useState } from "react";
import { CustomInput, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import Dialog from "../../dialog/Dialog";
import DIALOG_TYPE from "../../dialog/DialogType";

const data = [
  {
    id: "disrespectful",
    label: i18n.t("common.caseReport.disrespectful.label"),
    description: i18n.t("common.caseReport.disrespectful.description")
  },
  {
    id: "privacyIssue",
    label: i18n.t("common.caseReport.privacyIssue.label"),
    description: i18n.t("common.caseReport.privacyIssue.description")
  },
  {
    id: "lackingEvidence",
    label: i18n.t("common.caseReport.lackingEvidence.label"),
    description: i18n.t("common.caseReport.lackingEvidence.description")
  },
  {
    id: "promotional",
    label: i18n.t("common.caseReport.promotional.label"),
    description: i18n.t("common.caseReport.promotional.description")
  },
  {
    id: "offTopic",
    label: i18n.t("common.caseReport.offTopic.label"),
    description: i18n.t("common.caseReport.offTopic.description")
  },
  {
    id: "other",
    label: i18n.t("common.caseReport.other.label"),
    description: i18n.t("common.caseReport.other.description")
  }
];

// NOTE: this dialog is used to report a case AND a comment,
// they are identical uses with the minor exception of the
// prompt text

const CaseReportDialog = ({
  isComment,
  isOpen,
  onSubmit,
  toggle,
  initialItem = null,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const [current, setCurrent] = useState(initialItem);
  const promptTitle = isComment
    ? i18n.t("common.commentReport.promptTitle")
    : i18n.t("common.caseReport.promptTitle");
  const buttonLabel = i18n.t("common.caseReport.buttonLabel");

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    setCurrent(initialItem);
  }, [initialItem, isComment, isOpen]);

  /*********************************** FUNCTIONS *************************************/
  const update = (reportId) => {
    setCurrent(reportId);
  };

  const handleSubmit = () => {
    if (typeof onSubmit === "function") {
      onSubmit(current);
    }
  };

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      type={DIALOG_TYPE.INFORM}
      isOpen={isOpen}
      title={promptTitle}
      body={
        <div>
          {data.map((report, index) => {
            return (
              <FormGroup key={index}>
                <CustomInput
                  label={report.label}
                  onChange={() => update(report.id)}
                  id={report.id}
                  checked={current === report.id}
                  type="radio"
                  value={report.id}
                  name="report"
                />
                <p className="text-battleship-gray text-14 pl-4">
                  {report.description}
                </p>
              </FormGroup>
            );
          })}
        </div>
      }
      confirmText={buttonLabel}
      onConfirm={handleSubmit}
      onCancel={toggle}
      onClosed={toggle}
    />
  );
};

CaseReportDialog.propTypes = {
  toggle: PropTypes.func.isRequired,
  isComment: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

CaseReportDialog.defaultProps = {};

export default CaseReportDialog;
