import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import F1Avatar from "../../../components/common/F1Avatar";
import { Button } from "reactstrap";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import FollowUserButton from "../../../components/common/FollowUserButton";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import classNames from "../../../utils/class-names-utils";
import { PROFILE_EDIT_IMAGE } from "../../../constants/routes";
import ExternalLink from "./ExternalLink";
import useVerifiedUserGate from "../../../hooks/use-verified-user.hooks";
import ProfileFollowerFollowing from "./ProfileFollowerFollowing";
import ProfessionLine from "../../../components/common/ProfessionLine";
import { PROFILE_USER_TYPES } from "../../../constants/profile-info-constants";

const ProfileBasicInfoView = ({
  profileData,
  isCurrentUser,
  onEditProfile
}) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  const history = useHistory();

  const professionLabel = profileData?.profileDisplayName;
  const specialtyName = profileData?.subspecialtyName;
  const officialAccount =
    profileData?.userType === PROFILE_USER_TYPES.FIGURE1OFFICIAL;

  const practiceLabel = (() => {
    const labels = [];
    if (!isEmpty(profileData.practiceLocation)) {
      labels.push(profileData.practiceLocation);
    }
    if (!isEmpty(profileData.practiceHospital)) {
      labels.push(profileData.practiceHospital);
    }
    return labels.join(", ");
  })();

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const editProfileImage = () => {
    history.replace(
      getUrl(PROFILE_EDIT_IMAGE, { userUuid: profileData.userUuid })
    );
  };
  const verifiedEditProfileImage = useVerifiedUserGate(editProfileImage);

  /************************************ RENDER ***************************************/

  if (!profileData) {
    return null;
  }

  let followButton = (
    <FollowUserButton
      className="m-1"
      style={{ minWidth: "7.5rem" }}
      userUuid={profileData.userUuid}
      username={profileData.username}
      hasAvatar={!!profileData.avatar}
      context="Profile"
    />
  );

  let editButton = (
    <Button
      className="m-1 py-2"
      color="primary"
      size="lg"
      onClick={onEditProfile}>
      <CustomIcon
        className="mr-2"
        icon={"profile_edit_pencil"}
        size={16}
        color={"white"}
      />
      {t("Profile.editProfile")}
    </Button>
  );

  if (profileData._isInstitutional || !isCurrentUser) {
    editButton = null;
  }
  if (isCurrentUser) {
    followButton = null;
  }

  return (
    <div
      className={classNames(
        "profile-about text-center",
        profileData._isInstitutional ? "col-7 mx-auto" : null
      )}>
      <div className="profile-img-container">
        <F1Avatar
          className="profile-img"
          avatar={profileData?.avatar || profileData?.avatar}
          username={profileData?.username || profileData?.displayName}
          size="xl"
        />
        {isCurrentUser ? (
          <Button
            className="btn-icon btn-circle btn-avatar-upload"
            color="default-black-opaque"
            size="xs"
            onClick={verifiedEditProfileImage}>
            <CustomIcon icon={"profile_edit_pencil"} size={12} color="white" />
          </Button>
        ) : null}
      </div>
      <h3 className="mb-0">
        {profileData.displayName || profileData.username}
      </h3>
      <p className="text-battleship-gray text-15 mb-2">
        @{profileData.username}
      </p>

      {!officialAccount && <ProfileFollowerFollowing profile={profileData} />}

      {professionLabel && (
        <ProfessionLine
          className="mb-0 font-weight-bold"
          profession={professionLabel}
        />
      )}
      {specialtyName && <p className="mb-0">{specialtyName}</p>}
      {isEmpty(practiceLabel) ? null : (
        <p className="text-battleship-gray text-15 mb-0">{practiceLabel}</p>
      )}
      {!!profileData.userBio && (
        <p
          style={{ whiteSpace: "pre-line" }}
          className={classNames(
            "mt-3 mb-0",
            profileData._isInstitutional
              ? "text-default-black text-20 font-weight-light line-height-snug"
              : null
          )}>
          {profileData.userBio}
        </p>
      )}
      {!!profileData.disclosureText && (
        <p
          style={{ whiteSpace: "pre-line" }}
          className="mt-3 mb-0 px-5 text-14 text-battleship-gray ">
          <em>{profileData.disclosureText}</em>
        </p>
      )}
      <div className="d-inline-flex justify-content-center align-items-center flex-wrap mt-3">
        {editButton}
        {followButton}
        {!!profileData?.profileLink && (
          <ExternalLink
            className="m-1 py-2"
            url={profileData?.profileLink}
            linkText={profileData?.profileLinkText}
            user={profileData}
          />
        )}
      </div>
    </div>
  );
};

ProfileBasicInfoView.propTypes = {
  profileData: PropTypes.object,
  isCurrentUser: PropTypes.bool,
  onEditProfile: PropTypes.func
};

ProfileBasicInfoView.defaultProps = {
  isCurrentUser: false
};

export default ProfileBasicInfoView;
