import React from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import * as ROUTES from "../../constants/routes";
import THEMES from "../theme/Theme";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import i18n from "../../utils/i18n";
import { getUserDisplayName } from "../../utils/user-utils";
import useIsGated from "../../hooks/useIsGated";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { useDispatch } from "react-redux";
import {
  setGatedMessageOrigin,
  setGatedMessageType
} from "../../actions/global.actions";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

const CommentHeader = ({ comment = {}, reply }) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();
  const { author = {} } = comment;
  const { userUuid = null } = author;
  const uid = userUuid ? userUuid : comment.authorUuid;
  const isGated = useIsGated();
  const dispatch = useDispatch();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const gatedClick = () => {
    dispatch(
      setGatedMessageOrigin(UNGATED_ACTIONS.ORIGINAL_ACTIONS.AVATAR_NAME)
    );
    dispatch(setGatedMessageType(GATED_MODAL_TYPE.PROFILE));
  };
  /** ********************************** RENDER ***************************************/

  const topPadding = reply ? "pt-0" : "pt-2";
  const isCommentAuthorDeleted = comment?.author?.isDeleted;
  const userCommentDisplayName = getUserDisplayName(comment?.author);

  const userLink = isGated ? (
    <div
      onClick={gatedClick}
      className={classNames(
        "cursor-pointer",
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}>
      <div className="font-weight-500">{comment.username}</div>
    </div>
  ) : (
    <Link
      className={classNames(
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}
      to={getUrl(ROUTES.PROFILE_DETAIL, { userUuid: uid })}>
      <div className="font-weight-500">{comment.username}</div>
    </Link>
  );

  const content = isCommentAuthorDeleted ? (
    <>{i18n.t("Profile.accountDeleted")}</>
  ) : (
    <div className="d-flex align-items-center">
      {userLink}
      {userCommentDisplayName && (
        <>
          <div className="divider divider-aqua divider-14 mx-2 mb-1" />
          <div
            className={classNames(
              theme === THEMES.DARK ? "text-light-gray-blue" : "text-muted"
            )}>
            {userCommentDisplayName}
          </div>
        </>
      )}
    </div>
  );
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${topPadding}`}>
      {content}{" "}
      {comment?.edited && (
        <span className="text-muted">
          {i18n.t("comments.editCommentLabel")}
        </span>
      )}
    </div>
  );
};

export default CommentHeader;
