import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";
import GatedCaseThumbnail from "../case/GatedCaseThumbnail";

import "./SimilarCases.scss";

const SimilarCases = ({
  className,
  relatedCases,
  title,
  size = 4,
  itemLimit = 6,
  headerElement = null,
  footerElement = null
}) => {
  /** ********************************** CONFIG ***************************************/
  const slicedCases = relatedCases.slice(0, itemLimit);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {!!relatedCases?.length && (
        <Card className={`p-3 ${className}`}>
          {headerElement}
          {title && <h5 className="card-title">{title}</h5>}
          <Container className="p-0">
            <Row>
              {slicedCases.map((relatedCase, i) => (
                <Col className="mb-3" xs={size} key={i}>
                  <GatedCaseThumbnail key={i} caseContent={relatedCase} />
                </Col>
              ))}
            </Row>
          </Container>
          {footerElement}
        </Card>
      )}
    </>
  );
};

export default SimilarCases;
