/** @format */

import { PROFILE_USER_TYPES } from "../constants/profile-info-constants";

export const profileTypeFlags = (profile) => {
  return {
    _isMember: profile?.userType === PROFILE_USER_TYPES.FIGURE1MEMBER,
    _is100: profile?.userType === PROFILE_USER_TYPES.FIGURE100,
    _isFriends: profile?.userType === PROFILE_USER_TYPES.FIGURE1FRIENDS,
    _isInternal: profile?.userType === PROFILE_USER_TYPES.FIGURE1INTERNAL,
    _isOfficial: profile?.userType === PROFILE_USER_TYPES.FIGURE1OFFICIAL,
    _isPaidContributor:
      profile?.userType === PROFILE_USER_TYPES.FIGURE1PAIDCONTRIBUTOR,
    _isEditorialPartner:
      profile?.userType === PROFILE_USER_TYPES.FIGURE1EDITORIALPARTNER,
    _isEditorialSubscriber:
      profile?.userType === PROFILE_USER_TYPES.FIGURE1EDITORIALSUBSCRIBER,
    _isSponsored: profile?.userType === PROFILE_USER_TYPES.SPONSOREDUSER,
    _isInstitutional: profile?.userType === PROFILE_USER_TYPES.INSTITUTIONALUSER
  };
};
