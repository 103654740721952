import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Button,
  DropdownItem
} from "reactstrap";
import {
  CME_ROOT,
  DRAFTS_ROOT,
  SAVED_CASES_ROOT,
  NOTIFICATIONS_ROOT,
  CASE_POSTING_TYPE,
  PROFILE_NETWORK_TYPE
} from "../../constants/routes";
import DropdownItemLink from "../common/DropdownItemLink";
import { useSelector } from "react-redux";
import { getUserCountry } from "../../utils/cme-utils";
import CustomIcon from "../custom-icon/CustomIcon";
import { useHistory, useParams } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import { trackShareCase } from "../../actions/metrics.actions";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import useIsGroupFeed from "../../hooks/useIsGroupFeed";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";

const MoreDropdown = () => {
  const { t } = useTranslation();
  const userUuid = useSelector((state) => state.user.userUuid);
  const history = useHistory();
  const routeParams = useParams();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const isUS = useSelector((state) => getUserCountry(state.user) === "US");
  const cmeLinkText = isUS ? t("navigation.cme") : t("navigation.cme-non-us");
  const isGroupFeed = useIsGroupFeed(routeParams.feedTypeUuid);
  const currentFeedUuid = useCurrentFeedTypeUuid();

  const onShareCaseClick = () => {
    trackShareCase({
      context: UPLOAD_CASE_ORIGIN.HAMBURGER,
      groupUuid: isGroupFeed ? currentFeedUuid : null
    });
    history.push(CASE_POSTING_TYPE);
  };
  return (
    <Dropdown
      className="dropdown-more mr-3"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}>
      <DropdownToggle
        className="dropdown-toggle-button"
        tag="span"
        aria-expanded={isDropdownOpen}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomIcon
            className="inactive-icon"
            icon={"hamburger_menu"}
            size={20}
          />
          <span className="helv-med text-12">{t("navigation.menu")}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu--center dropdown-menu--pointer-top-center dropdown-menu-scrollable"
        right>
        <DropdownItem header className="pb-2">
          <Button color="primary" block onClick={onShareCaseClick}>
            {t("navigation.share-case")}
          </Button>
        </DropdownItem>
        <DropdownItem divider className="mx-4" />
        <DropdownItem
          header
          className="text-uppercase text-denim-blue font-weight-bold">
          {t("navigation.other")}
        </DropdownItem>
        <DropdownItemLink to={DRAFTS_ROOT}>
          {t("navigation.drats")}
        </DropdownItemLink>
        <DropdownItemLink
          to={{
            pathname: getUrl(PROFILE_NETWORK_TYPE, {
              userUuid,
              viewType: "followers"
            }),
            state: {
              context: "Hamburger menu"
            }
          }}>
          {t("Network.MyNetwork")}
        </DropdownItemLink>

        <DropdownItemLink to={CME_ROOT}>{cmeLinkText}</DropdownItemLink>
        <DropdownItemLink to={SAVED_CASES_ROOT}>
          {t("navigation.saved-cases")}
        </DropdownItemLink>
        <DropdownItemLink to={NOTIFICATIONS_ROOT}>
          {t("navigation.notifications")}
        </DropdownItemLink>
      </DropdownMenu>
    </Dropdown>
  );
};

MoreDropdown.propTypes = {};

export default MoreDropdown;
