/** @format */

const ONBOADRING_STATE_V2 = {
  COUNTRY: "country",
  USA_INFORMATION: "usa_information",
  INFORMATION: "information",
  VERIFICATION: "verification",
  USERNAME: "username",
  COMPLETED: "completed"
};

export default ONBOADRING_STATE_V2;
