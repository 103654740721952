import React from "react";
import PropTypes from "prop-types";
import { GRID_SETTINGS } from "../../../constants/saved-cases-constants";
import SavedCasesTile from "./SavedCasesTile";
import F1GridList from "../../../components/common/F1GridList";

const SavedCasesFound = ({ cases }) => {
  return (
    <F1GridList
      data={cases}
      numColumns={GRID_SETTINGS.NUM_COLUMNS}
      gap={GRID_SETTINGS.COLUMN_GAP}
      renderItem={({ item }) => (
        <SavedCasesTile key={item.caseUuid} caseContent={item} />
      )}
    />
  );
};

SavedCasesFound.propTypes = {
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      caseUuid: PropTypes.string,
      title: PropTypes.string,
      caption: PropTypes.string,
      media: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string
        })
      ),
      isSaved: PropTypes.bool
    })
  ),
  isLoading: PropTypes.bool
};

SavedCasesFound.defaultProps = {
  isLoading: false
};

export default SavedCasesFound;
