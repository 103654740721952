import { LOCALIZATION_UPDATED } from "../actions/localization.actions";

export const INITIAL_STATE = {
  currentLanguage: "en-US"
};

const LocalizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCALIZATION_UPDATED:
      return { ...state, currentLanguage: action.payload.currentLanguage };
    default:
      return state;
  }
};

export default LocalizationReducer;
