/** @format */

import {
  FETCH_SPECIALTIES_START,
  FETCH_SPECIALTIES_COMPLETE,
  ERROR
} from "../actions/case-specialties.actions";

export const INITIAL_STATE = {
  specialties: {},
  isProcessing: false,
  fetched: false,
  error: false,
  message: null
};

const CaseSpecialtiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SPECIALTIES_START:
      return { ...state, isProcessing: true };
    case FETCH_SPECIALTIES_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        specialties: action.specialties || state.specialties,
        fetched: action.fetched,
        error: action.error,
        message: action.message
      };
    case ERROR:
      return {
        ...state,
        isProcessing: action.isProcessing || state.isProcessing,
        specialties: action.specialties || state.specialties,
        fetched: action.fetched || state.fetched,
        error: action.error,
        message: action.message
      };
    default:
      return state;
  }
};

export default CaseSpecialtiesReducer;
