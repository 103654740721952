/** @format */

const Regex = {
  // eslint-disable-next-line no-useless-escape
  SimpleEmail: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
  Password: /^.{6,20}$/,
  username: /^[a-zA-Z0-9+_-]{3,100}$/,
  AtMention: /@[a-zA-Z0-9+_-]*/g,
  Color: /^#([a-fA-F0-9]{8}|[a-fA-F0-9]{6}|[a-fA-F0-9]{3,4})$/
};

export default Regex;
