/** @format */

import React from "react";
import PropTypes from "prop-types";
import "./CaseCarousel.scss";
import TopicViewAllLink from "../../../../components/channel-navigation/TopicViewAllLink";

const CaseCarouselHeader = ({ preTitle = "", title, feedUuid }) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <div className="d-flex align-items-center justify-content-between mb-3 mr-2">
      <div className="flex-column helv-bold text-16">
        {!!preTitle && (
          <span className="color-battleship-gray mb-1">{preTitle}</span>
        )}
        <span className="color-default-black header-title">{title}</span>
      </div>

      {feedUuid && (
        <TopicViewAllLink
          feedUuid={feedUuid}
          linkStyles="text-14"
          iconSize={14}
        />
      )}
    </div>
  );
};

CaseCarouselHeader.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  showFollow: PropTypes.bool,
  isFollowed: PropTypes.bool,
  feedUuid: PropTypes.string
};

export default CaseCarouselHeader;
