/** @format */

import { CONTENT_TYPE } from "../constants/content-type";
import * as UserVotes from "../db/user-votes.db";

const actionsPrefix = "userVoteAnswer";

export const FETCH_VOTES_START = `${actionsPrefix}/FETCH_VOTES_START`;
export const VOTES_UPDATED = `${actionsPrefix}/VOTES_UPDATED`;
export const VOTES_LISTENER_ON = `${actionsPrefix}/VOTES_LISTENER_ON`;
export const VOTES_LISTENER_OFF = `${actionsPrefix}/VOTES_LISTENER_OFF`;
export const ERROR = `${actionsPrefix}/ERROR`;

export const toggleUserVotesListener = (userUid) => {
  return async (dispatch, getState) => {
    const currentListenerReference = getState().userVoteAnswer.listener;
    if (userUid) {
      if (!currentListenerReference) {
        const listener = await UserVotes.listenForChanges((doc) => {
          if (doc && doc.exists) {
            const data = doc.data();
            dispatch({
              type: VOTES_UPDATED,
              payload: { data }
            });
          }
        }, userUid);
        return dispatch({
          type: VOTES_LISTENER_ON,
          payload: { listener }
        });
      }
    } else {
      if (currentListenerReference) {
        currentListenerReference();
      }
      return dispatch({
        type: VOTES_LISTENER_OFF
      });
    }
  };
};

// Note: this is just updating redux optimistically, quizVote is the real data changing call....
export const addVote = (userUid, contentUuid, questionOptionUuid) => {
  return async (dispatch, getState) => {
    // adding vote here, but this should be handled by the backend at some point
    let currentVotesState = { ...getState().userVoteAnswer.data };
    if (currentVotesState[contentUuid]?.choices) {
      currentVotesState[contentUuid].choices.push(questionOptionUuid);
    } else {
      currentVotesState[contentUuid] = {
        choices: [questionOptionUuid]
      };
    }
    dispatch({
      type: FETCH_VOTES_START
    });
    dispatch({
      type: VOTES_UPDATED,
      payload: { data: currentVotesState }
    });
  };
};

export const removeVote = (userUid, contentUuid) => {
  return async (dispatch, getState) => {
    // removing vote here, but this should be handled by the backend at some point
    let currentVotesState = getState().userVoteAnswer.data;
    delete currentVotesState[contentUuid];
    dispatch({
      type: FETCH_VOTES_START
    });
    dispatch({
      type: VOTES_UPDATED,
      payload: { data: currentVotesState }
    });
  };
};

export function listCaseRightAnswers(items) {
  let answers = {};
  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    const i = items[itemIndex];
    if ([CONTENT_TYPE.QUIZ, CONTENT_TYPE.QUIZ_SERIES].includes(i.contentType)) {
      answers[i.contentUuid] = [];
      for (
        let questionOptionIndex = 0;
        questionOptionIndex < i.questionOptions.length;
        questionOptionIndex++
      ) {
        if (i.questionOptions[questionOptionIndex].isAnswer) {
          answers[i.contentUuid].push(
            i.questionOptions[questionOptionIndex].questionOptionUuid
          );
        }
      }
    }
  }
  return answers;
}
