import { isNumber } from "lodash";

export const formatNumber = (value) => {
  return isNumber(value) ? value.toLocaleString() : value;
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export const isRequestSuccess = (response) => {
  if (!response) {
    return false;
  }
  return response?.status >= 200 && response?.status < 300;
};