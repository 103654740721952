import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { trackShareCase } from "../../../actions/metrics.actions";
import F1HorizontalDivider from "../../../components/common/F1HorizontalDivider";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { FEED_NAMES } from "../../../constants/feed-type";
import { CASE_POSTING_TYPE, HOME_ROOT_FEED } from "../../../constants/routes";
import { UPLOAD_CASE_ORIGIN } from "../../../metrics/constants.metrics";
import i18n from "../../../utils/i18n";
import { getUrl } from "../../../utils/route-utils";

const GroupEndOfFeedCard = ({ groupUuid }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <div className="my-5">
        <F1HorizontalDivider className="w-100">
          <CustomIcon
            icon={"web_confirmation_banner"}
            size={57}
            className="icon-battleship-gray flex-shrink-0"
          />
        </F1HorizontalDivider>
        <p className="text-battleship-gray helv-bold text-16 text-center mb-4">
          {i18n.t("GroupsEndOfFeed.title")}
        </p>
        <div className="text-center">
          <Link
            className="d-inline-flex justify-content-center align-items-center nav-link btn btn-outline-primary btn-lg helv-bold w-215px text-center"
            to={CASE_POSTING_TYPE}
            onClick={() => {
              trackShareCase({
                context: UPLOAD_CASE_ORIGIN.END_OF_FEED,
                groupUuid
              });
            }}>
            <CustomIcon className="mr-2" icon={"plus"} size={16} />
            {i18n.t("navigation.share-case")}
          </Link>
        </div>
      </div>
      <Card className="border-0 rounded-0 px-3 py-2 mb-4">
        <CardBody className="text-center">
          <div className="navbar-brand mb-2">Figure 1 PRO</div>
          <h3 className="text-18 helv-med">
            {i18n.t("GroupsEndOfFeed.panel2Title")}
          </h3>
          <p className="text-battleship-gray text-14 mb-4">
            {i18n.t("GroupsEndOfFeed.panel2Description")}
          </p>
          <Link
            className="d-inline-flex justify-content-center align-items-center nav-link btn btn-primary btn-lg helv-bold text-16 w-215px text-center"
            to={getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED })}>
            {i18n.t("GroupsEndOfFeed.buttonText")}
          </Link>
        </CardBody>
      </Card>
    </>
  );
};

export default GroupEndOfFeedCard;
