import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CASE_REACTION } from "../../constants/case-constants";
import CASE_TYPE from "../../constants/case-type";
import { submitCaseReaction } from "../../actions/case.actions";
import { keysToCamelCase } from "../../utils/camelCaseUtils";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";
import ReactionButton from "./ReactionButton";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { trackReaction } from "../../actions/metrics.actions";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

const CaseReactions = ({
  caseContent,
  selectedReaction,
  className,
  reactionContext,
  position
}) => {
  /** ********************************** CONFIG ***************************************/

  const { caseUuid } = keysToCamelCase(caseContent);

  const dispatch = useDispatch();
  const { theme } = useTheme();

  const [agreeTooltipOpen, setAgreeTooltipOpen] = useState(false);

  const toolTipInnerClass =
    caseContent.caseType === CASE_TYPE.CLINICAL_MOMENTS
      ? "bg-aqua"
      : "bg-denim-blue";

  const rawReactionCount =
    caseContent.reactionCount ||
    Object.values(caseContent.allReactions || {}).reduce((t, n) => t + n, 0);
  const reactionCount = Math.max(rawReactionCount, 0);
  const reactionText =
    reactionCount === 0
      ? i18n.t("caseReactions.noLikes")
      : reactionCount === 1
      ? i18n.t("caseReactions.like")
      : i18n
          .t("caseReactions.likes")
          // eslint-disable-next-line no-template-curly-in-string
          .replace("${COUNT}", reactionCount);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleAgree = () => setAgreeTooltipOpen(!agreeTooltipOpen);

  const handleReactionClick = async (reaction) => {
    dispatch(
      submitCaseReaction(caseUuid, reaction, selectedReaction !== reaction)
    );
    trackReaction({
      caseContent,
      position,
      reactionName: reaction,
      reactionContext,
      on: selectedReaction !== reaction
    });
  };

  const verifiedHandleReactionClick = useVerifiedUserGate(handleReactionClick);
  const gatedHandleReactionClick = useGatedActionGate(
    verifiedHandleReactionClick,
    GATED_MODAL_TYPE.REACT,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.REACTION
  );
  /** ********************************** RENDER ***************************************/
  return (
    <>
      {caseUuid && (
        <div
          className={classNames(
            "d-flex align-items-center justify-content-center",
            className
          )}>
          <ReactionButton
            buttonId={`buttonAgree_${caseUuid}`}
            buttonIcon="reaction_like_outlined"
            buttonReactedIcon="reaction_like_filled"
            buttonText={i18n.t("caseReactions.agree")}
            handleToggle={toggleAgree}
            handleReactionClick={gatedHandleReactionClick}
            selectedReaction={selectedReaction}
            reaction={CASE_REACTION.AGREE}
            toolTipOpen={agreeTooltipOpen}
            toolTipInnerClass={toolTipInnerClass}
          />

          <div
            className={classNames(
              "pl-1 font-weight-500",
              selectedReaction === CASE_REACTION.AGREE
                ? "text-denim-blue"
                : theme === THEMES.DARK
                ? "text-white"
                : "text-battleship-gray"
            )}>
            {reactionText}
          </div>
        </div>
      )}
    </>
  );
};

export default CaseReactions;
