import React from "react";
import { CardBody, CardText } from "reactstrap";
import ReactMarkdown from "react-markdown";
import CaseDropdown from "./CaseDropdown";
import CaseMediaView from "./CaseMediaView";
import CaseTitle from "./CaseTitle";
import TagsContainer from "./TagsContainer";
import QuestionExternalLink from "./QuestionExternalLink";
import CaseReferences from "./CaseReferences";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import GatedCaseNotice from "./GatedCaseNotice";
import F1Spacer from "../common/F1Spacer";

const CaseDetailCardInner = ({
  caseContent,
  commentStatus,
  videoPlayerConfig,
  showNewUngated,
  handleShowBanner = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <CardBody className="d-inline-flex justify-content-between align-items-top w-100 bg-white border-0">
        {caseContent.title ? (
          <CaseTitle caseContent={caseContent} />
        ) : (
          <F1Spacer />
        )}
        <CaseDropdown
          caseContent={caseContent}
          caseNotification={handleShowBanner}
          from={CASE_CARD_ORIGIN.DETAIL}
          commentStatus={commentStatus}
        />
      </CardBody>

      <CardBody className="pt-0">
        <TagsContainer caseContent={caseContent} />
      </CardBody>

      {caseContent?.media?.length > 0 && (
        <CaseMediaView
          caseUuid={caseContent.caseUuid}
          className={caseContent.media.length === 1 ? "pb-3" : "pb-0"}
          media={caseContent.media}
          showImages={true}
          context={CASE_CARD_ORIGIN.DETAIL}
          videoPlayerConfig={videoPlayerConfig}
        />
      )}

      <CardBody className="pt-0">
        <GatedCaseNotice visible={showNewUngated} />
        <CardText
          className="feed-card-info feed-card-info-markdown whitespace-pre-line feed-card-detail"
          tag="div">
          <ReactMarkdown>{caseContent.caption}</ReactMarkdown>
        </CardText>
      </CardBody>

      {caseContent?.references && (
        <CardBody className="pt-0">
          <CaseReferences references={caseContent?.references} />
        </CardBody>
      )}
      {!!caseContent.externalLinkUrl && (
        <CardBody className="pt-0">
          <QuestionExternalLink
            caseUuid={caseContent.caseUuid}
            contentUuid={caseContent.contentUuid}
            campaignUuid={caseContent.campaignUuid}
            text={caseContent.externalLinkText}
            url={caseContent.externalLinkUrl}
          />
        </CardBody>
      )}
    </>
  );
};

export default CaseDetailCardInner;
