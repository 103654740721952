import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ProfileEditModal from "./ProfileEditModal";

const ProfileEditPracticeAreasModal = ({
  toggle,
  isOpen,
  userUuid,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/

  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    // TODO: Get form data from caller
    setFormData({});
  }, []);

  /*********************************** FUNCTIONS *************************************/

  const onSubmit = async (event) => {
    event.preventDefault();
    // TODO: Handle submission
    console.log("on submit", formData);
    toggle();
  };

  /************************************ RENDER ***************************************/

  return (
    <ProfileEditModal
      title={t("Profile.practiceArea")}
      toggle={toggle}
      isOpen={isOpen}
      onSubmit={onSubmit}
      formData={formData}
      {...rest}>
      {/* TODO: Implement Modal */}
    </ProfileEditModal>
  );
};

ProfileEditPracticeAreasModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired
};

ProfileEditPracticeAreasModal.defaultProps = {};

export default ProfileEditPracticeAreasModal;
