import React from "react";
import { CardText, CardBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomIcon from "../../components/custom-icon/CustomIcon";

function SearchEmpty({ searchingChannelNames, onSearchAll }) {
  const { t } = useTranslation();

  const message = searchingChannelNames ? (
    <>
      {t("Search.noResults").replace(
        "REPLACE_CHANNEL_NAMES",
        searchingChannelNames
      )}
    </>
  ) : (
    <>{t("Search.noResultsGeneric")}</>
  );

  return (
    <CardBody className="bg-secondary text-denim-blue text-center rounded">
      <CustomIcon
        className="mb-2"
        icon="network_error"
        color="#3f6296"
        size={40}
      />
      <CardText>{message}</CardText>
      <Button color="primary" onClick={() => onSearchAll()}>
        {t("Search.searchAllOfFigure1")}
      </Button>
    </CardBody>
  );
}

SearchEmpty.propTypes = {
  searchingChannelNames: PropTypes.string,
  onSearchAll: PropTypes.func.isRequired,
};

export default SearchEmpty;
