import React from "react";
import PropTypes from "prop-types";
import { head, isEmpty } from "lodash";
import classname from "../../utils/class-names-utils";
import { getImageUrl } from "../../utils/media-utils";

const getImgSize = (size) => {
  switch (size) {
    case "sm":
      return 36;
    case "xl":
      return 170;
    default:
      return 40;
  }
};

const F1Avatar = (props) => {
  const { username, avatar, className, size, isDeleted, onClick } = props;

  const imgSize = getImgSize(size);
  const avatarUrl = getImageUrl(
    { type: "image", url: avatar },
    imgSize,
    imgSize,
    "crop"
  );

  if (isDeleted) {
    return (
      <div
        className={classname(
          "avatar-placeholder",
          "deleted",
          size ? `avatar-placeholder-${size}` : undefined,
          "rounded-circle",
          className
        )}>
        X
      </div>
    );
  }

  if (!isEmpty(avatarUrl)) {
    return (
      <img
        src={avatarUrl}
        className={classname(
          `avatar`,
          size ? `avatar-${size}` : undefined,
          "rounded-circle",
          className
        )}
        alt={`Profile ${username}`}
        onClick={onClick}
      />
    );
  }

  const firstLetter = head(username);

  return (
    <div
      className={classname(
        `avatar-placeholder`,
        size ? `avatar-placeholder-${size}` : undefined,
        "rounded-circle",
        className
      )}
      onClick={onClick}>
      {firstLetter}
    </div>
  );
};

F1Avatar.propTypes = {
  username: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "xl"])
};

export default F1Avatar;
