import React from "react";
import { useDispatch } from "react-redux";
import { Button, Card } from "reactstrap";
import {
  trackPromoCardClicked,
  trackPromoCardDismissed
} from "../../../actions/metrics.actions";
import { removePromoCards } from "../../../actions/user.actions";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const PromoCard = ({ card = {} }) => {
  const {
    buttonText,
    caption,
    caseUuid,
    dismissButton,
    dismissOnClick,
    title,
    web
  } = card;

  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(removePromoCards([caseUuid]));
  };

  const dismiss = () => {
    onDismiss();
    trackPromoCardDismissed(card);
  };

  const onCtaClick = () => {
    if (dismissOnClick) {
      dismiss();
    }

    trackPromoCardClicked(card);
  };

  const CTAButton = buttonText ? (
    <a className="btn btn-primary" href={web.buttonLink} onClick={onCtaClick}>
      {buttonText}
    </a>
  ) : null;

  const CloseButton = dismissButton ? (
    <div style={{ marginRight: "-1.3rem" }} className="r-0">
      <Button close onClick={dismiss}>
        <CustomIcon icon="modal_exit" size={14} color={"black"} />
      </Button>
    </div>
  ) : null;

  return (
    <Card className="mb-2 border-0 px-4 pb-3 pt-1">
      {CloseButton}

      <h4
        className={`m-0 font-weight-bold text-14 ${
          dismissButton ? "" : "mt-2"
        }`}>
        {title}
      </h4>

      <hr style={{ borderTopWidth: "3px" }} className="my-2" />
      <p>{caption}</p>
      {CTAButton}
    </Card>
  );
};

export default PromoCard;
