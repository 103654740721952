import React from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";

import lastStep from "../../assets/images/reg_flow_last_step_web.svg";
import SelectUsernameForm from "./components/SelectUsernameForm";
import i18n from "../../utils/i18n";

const SelectUsernamePage = () => {
  /** ********************************** CONFIG ***************************************/

  /** *********************************** HOOKS ***************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <>
            <h1 className="text-center helv-bold text-22 mt-5 mb-3">
              {i18n.t("RegistrationScreens.usernamePage.v2.title")}
            </h1>
            <div className="text-center mb-3">
              <img src={lastStep} alt="" />
            </div>
            <div className="mt-4 shadow">
              <SelectUsernameForm />
            </div>
          </>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default SelectUsernamePage;
