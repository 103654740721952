import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import {
  getPreviewFeedIfNeeded,
  getPreviewFeedMetaIfNeeded
} from "../../actions/reference.actions";
import { FEED_NAMES } from "../../constants/feed-type";

import "./ExploreCategoryCTA.scss";
import TopicViewAllLink from "./TopicViewAllLink";

const MAX_IMAGES = 4;

const ExploreCategoryCTA = ({
  title,
  description,
  feedLabel = FEED_NAMES.EVERYTHING
}) => {
  /****************************** Config **************************************/
  const metaDataId = useSelector(
    (state) =>
      Object.values(state?.references?.feedsMeta?.data || {})?.find(
        (m) => m.label === feedLabel
      )?.feed_type_uuid
  );

  const feedData = useSelector(
    (state) => state?.references?.feeds?.[metaDataId]?.data
  );

  const [feedFetchMade, setFeedFetchMade] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();

  /****************************** Hooks ***************************************/

  useEffect(() => {
    if (feedLabel && !metaDataId) {
      dispatch(getPreviewFeedMetaIfNeeded());
    }
  }, [metaDataId, feedLabel, dispatch]);

  useEffect(() => {
    if (metaDataId && !feedData?.length && !feedFetchMade) {
      dispatch(
        getPreviewFeedIfNeeded({
          feedTypeUuid: metaDataId
        })
      );
      setFeedFetchMade(true);
    }
  }, [metaDataId, feedData, feedFetchMade, dispatch]);

  useEffect(() => {
    if (feedData?.length && !images.length) {
      const images = [];
      for (let i = 0; i < feedData?.length; i++) {
        const entry = feedData?.[i] || null;
        if (images.length === MAX_IMAGES) {
          break;
        }
        const mediaItem = entry?.media?.[0];
        if (mediaItem) {
          const url = `${mediaItem?.media_url}?w=31&h=31&fit=crop `;
          images.push({
            key: mediaItem.contentUuid,
            url
          });
        }
      }
      setImages(images);
    }
  }, [feedData, images.length]);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <Card className="explore-category-cta p-2 border-0 mb-3 shadow">
      <h3 className="section-header font-weight-bold text-12 text-uppercase py-2 mb-2">
        {title}
      </h3>
      <div className="d-flex align-items-start w-100">
        <ul className="d-flex flex-wrap preview-images">
          {!!images.length &&
            images.map((image) => (
              <li key={image.key}>
                <img src={image.url} alt="" />
              </li>
            ))}
        </ul>
        <div>
          <p className="m-0 text-13">{description}</p>
          <TopicViewAllLink feedUuid={metaDataId} />
        </div>
      </div>
    </Card>
  );
};

export default ExploreCategoryCTA;
