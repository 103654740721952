import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { trackToggleUserFollow } from "../actions/metrics.actions";
import { toggleUserFollow } from "../actions/user-profiles.actions";
import DIALOG_TYPE from "../components/dialog/DialogType";
import useDialog from "../components/dialog/useDialog";
import GATED_MODAL_TYPE from "../components/gated-modal/GatedModalType";
import TOAST_TYPE from "../components/popup/ToastType";
import usePopup from "../components/popup/usePopup";
import { UNGATED_ACTIONS } from "../metrics/constants.metrics";
import useGatedActionGate from "./useGatedActionGate";
import useIsCurrentUser from "./useIsCurrentUser";
import useIsGated from "./useIsGated";

const isProcessingSelector = (userUuid) => (state) =>
  get(state, `userProfiles.userProcessing.${userUuid}`, false) === true;

const isFollowingSelector = (userUuid) => (state) => {
  if (state.user.userUuid === userUuid) {
    return false;
  }

  const find = state.userProfiles.following[state.user.userUuid]?.data?.find(
    (u) => u.userUuid === userUuid
  );

  return !!find;
};

const useFollowUser = ({ userUuid, username, hasAvatar, context }) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  const { showToast } = usePopup();
  const { confirm } = useDialog();
  const dispatch = useDispatch();
  const isProcessing = useSelector(isProcessingSelector(userUuid));
  const isFollowing = useSelector(isFollowingSelector(userUuid));
  const isCurrentUser = useIsCurrentUser(userUuid);
  const isGated = useIsGated();
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const onToggleUserFollow = async () => {
    try {
      const isFollow = !isFollowing;

      trackToggleUserFollow({
        username,
        userUuid,
        hasAvatar,
        context,
        isFollow,
        isGated
      });

      const result = await dispatch(toggleUserFollow(userUuid, isFollow));
      if (result.error) {
        throw new Error(t("Profile.followFailure"));
      }
    } catch (error) {
      showToast({
        message: t("Profile.followFailure"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  const onFollowClick = async () => {
    if (!isFollowing) {
      onToggleUserFollow();
    } else {
      const confirmed = await confirm({
        message: t("Network.modal.message").replace("[name]", username),
        messageDetail: "",
        confirmText: t("Network.modal.confirm"),
        cancelText: t("common.cancel"),
        extra: {
          type: DIALOG_TYPE.CONFIRM_REVERSED
        }
      });

      if (confirmed) {
        onToggleUserFollow();
      }
    }
  };

  const gatedOnFollowClick = useGatedActionGate(
    onFollowClick,
    GATED_MODAL_TYPE.GENERIC,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.AUTHOR_FOLLOW,
    true
  );
  /************************************ RENDER ***************************************/

  return {
    isCurrentUser,
    isFollowing,
    isProcessing,
    onFollowClick: gatedOnFollowClick
  };
};

export default useFollowUser;
