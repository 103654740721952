import React from "react";
import PropTypes from "prop-types";

function Image({ src, fallbackSrc, alt, onError, ...props }) {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const handleError = (e) => {
    e.target.onerror = null;
    e.target.src = fallbackSrc;
  };

  /************************************ RENDER ***************************************/

  return <img src={src} alt={alt} onError={handleError} {...props} />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Image;
