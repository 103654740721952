import React from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import { DropdownItem } from "reactstrap";
import "./style.scss";

const CaseDropdownItem = ({
  icon,
  iconColor,
  primaryText,
  secondaryText,
  handleClick,
  primaryTextClass = "",
  secondaryTextClass = "",
  disabled = false
}) => {
  return (
    <DropdownItem disabled={disabled} onClick={handleClick}>
      <div className="case-dropdown-item">
        <CustomIcon icon={icon} color={iconColor} size={20} />

        <div className="case-dropdown-text-area">
          {primaryText && (
            <div className={"case-dropdown-text-primary " + primaryTextClass}>
              {primaryText}
            </div>
          )}
          {secondaryText && (
            <div
              className={"case-dropdown-text-secondary " + secondaryTextClass}>
              {secondaryText}
            </div>
          )}
        </div>
      </div>
    </DropdownItem>
  );
};

export default CaseDropdownItem;
