import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ScrollRestoreContext from "./ScrollRestoreContext";

export const ScrollRestoreProvider = ({ children }) => {
  /************************************ CONFIG ***************************************/
  const history = useHistory();
  const location = useLocation();
  const scrollRef = useRef(null);
  const scrollPositions = useRef({});
  const saveScrollPosition = (scrollLeft, scrollTop) => {
    scrollPositions.current[location.key] = {
      left: scrollLeft,
      top: scrollTop
    };
  };

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    if (history.action === "POP") {
      const { left, top } = scrollPositions.current[location.key] || {
        left: 0,
        top: 0
      };
      scrollRef.current.scrollTo(left, top);
      delete scrollPositions.current[location.key];
    } else {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [location.key, history.action]);

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <ScrollRestoreContext.Provider value={{ scrollRef, saveScrollPosition }}>
      {children}
    </ScrollRestoreContext.Provider>
  );
};

export default ScrollRestoreProvider;
