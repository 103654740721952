/** @format */

import _, { get, isEmpty } from "lodash";
import { RESET_ALL } from "../actions/global.actions";
import {
  AVATAR_UPDATED,
  CACHE_SPECIALTIES,
  CLEAR_SPECIALTY_UPDATE_CACHE,
  DELETE_USER_INFO_ENTRY_COMPLETE,
  DELETE_USER_INFO_ENTRY,
  FETCH_USER_CONTENT,
  TOGGLE_USER_FOLLOW_COMPLETE,
  TOGGLE_USER_FOLLOW,
  UPDATE_USER_PROFILE_COMPLETE,
  UPDATE_USER_PROFILE,
  UPLOAD_AVATAR_COMPLETE,
  UPLOAD_AVATAR,
  USER_CONTENT_LISTENER_OFF,
  USER_CONTENT_LISTENER_ON,
  USER_CONTENT_UPDATED,
  USER_PROFILE_LISTENER_OFF,
  USER_PROFILE_LISTENER_ON,
  USER_PROFILE_UPDATED,
  USER_FOLLOWING_LISTENER_ON,
  USER_FOLLOWING_LISTENER_OFF,
  USER_FOLLOWERS_UPDATED,
  USER_FOLLOWING_UPDATED,
  FETCH_USER_FOLLOWERS,
  FETCH_USER_FOLLOWING,
  USER_PROFILE_ACTIVE_TAB_UPDATED
} from "../actions/user-profiles.actions";

const normalizeProfile = ({ userUuid, profile }) => {
  if (isEmpty(userUuid) || isEmpty(profile) || isEmpty(profile.profile)) {
    return profile;
  }

  if (!profile.profile.userUuid) {
    return { ...profile, profile: { ...profile.profile, userUuid } };
  }

  return profile;
};

export const INITIAL_STATE = {
  profiles: {},
  contents: {},
  listeners: {},
  contentListeners: {},
  userProcessing: {},
  updateCache: [],
  isProcessing: false,
  isUploading: false,
  error: false,
  message: null,
  followingListener: null,
  following: {},
  followers: {}
};

const INITIAL_CONTENT_STATE = {
  data: [],
  loading: false,
  endReached: false
};

const userProfilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE_LISTENER_ON:
      return {
        ...state,
        listeners: { ...state.listeners, [action.userUuid]: action.listener }
      };
    case USER_PROFILE_LISTENER_OFF:
      return {
        ...state,
        listeners: { ...state.listeners, [action.userUuid]: null }
      };
    case USER_PROFILE_UPDATED:
      if (!action.userUuid) {
        return state;
      }

      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.userUuid]: {
            ...state.profiles[action.userUuid],
            ...normalizeProfile(action)
          }
        }
      };
    case USER_CONTENT_LISTENER_ON:
      return {
        ...state,
        contentListeners: {
          ...state.contentListeners,
          [action.userUuid]: action.contentListener
        }
      };
    case USER_CONTENT_LISTENER_OFF:
      return {
        ...state,
        contentListeners: { ...state.contentListeners, [action.userUuid]: null }
      };
    case FETCH_USER_CONTENT: {
      const oldContent = get(state, ["contents"], INITIAL_CONTENT_STATE);

      return {
        ...state,
        contents: {
          ...state.contents,
          [action.userUuid]: {
            ...oldContent[action.userUuid],
            ...{
              [action.contentType]: {
                ...oldContent[action.userUuid]?.[action.contentType],
                loading: true
              }
            }
          }
        }
      };
    }
    case USER_CONTENT_UPDATED: {
      const oldContent = get(
        state,
        ["contents", action.userUuid, action.contentType],
        INITIAL_CONTENT_STATE
      );

      const newContent = { ...state.contents };

      if (action.refreshing) {
        newContent[action.userUuid] = {
          ...newContent[action.userUuid],
          ...{ [action.contentType]: { data: action.content } }
        };
      } else {
        newContent[action.userUuid][action.contentType].data = [
          ...oldContent.data,
          ...action.content
        ];
      }

      newContent[action.userUuid][action.contentType].loading = false;
      newContent[action.userUuid][action.contentType].endReached =
        action.isEndReached;

      return {
        ...state,
        contents: newContent
      };
    }
    case UPDATE_USER_PROFILE: {
      let profiles = state.profiles;
      if (action.specialties && action.userUuid && profiles[action.userUuid]) {
        profiles = {
          ...profiles,
          [action.userUuid]: {
            ...profiles[action.userUuid],
            specialties: action.specialties
          }
        };
      }
      return {
        ...state,
        isProcessing: true,
        profiles: profiles,
        userProcessing: { ...state.userProcessing, [action.userUuid]: true }
      };
    }
    case DELETE_USER_INFO_ENTRY:
    case TOGGLE_USER_FOLLOW:
      return {
        ...state,
        isProcessing: true,
        userProcessing: { ...state.userProcessing, [action.userUuid]: true }
      };
    case UPDATE_USER_PROFILE_COMPLETE:
    case DELETE_USER_INFO_ENTRY_COMPLETE:
    case TOGGLE_USER_FOLLOW_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message,
        userProcessing: {
          ...state.userProcessing,
          [action.userUuid]: false
        }
      };
    case CACHE_SPECIALTIES:
      return {
        ...state,
        updateCache: action.specialties
      };
    case CLEAR_SPECIALTY_UPDATE_CACHE:
      return {
        ...state,
        updateCache: []
      };
    case AVATAR_UPDATED:
      const profileMeta = state.profiles[action.userUuid] || {};
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.userUuid]: {
            ...profileMeta,
            avatar: action.avatar
          }
        }
      };
    case UPLOAD_AVATAR:
      return {
        ...state,
        isUploading: true
      };
    case UPLOAD_AVATAR_COMPLETE:
      return {
        ...state,
        isUploading: false,
        error: action.error,
        message: action.message
      };

    case USER_FOLLOWING_LISTENER_ON:
      return {
        ...state,
        followingListener: action.listener
      };
    case USER_FOLLOWING_LISTENER_OFF:
      return {
        ...state,
        followingListener: null
      };
    case FETCH_USER_FOLLOWERS: {
      const oldData = _.get(
        state,
        ["followers", action.userUuid],
        INITIAL_CONTENT_STATE
      );

      return {
        ...state,
        followers: {
          ...state.followers,
          [action.userUuid]: {
            ...oldData,
            loading: true
          }
        }
      };
    }
    case FETCH_USER_FOLLOWING: {
      const oldData = _.get(
        state,
        ["following", action.userUuid],
        INITIAL_CONTENT_STATE
      );

      return {
        ...state,
        following: {
          ...state.following,
          [action.userUuid]: {
            ...oldData,
            loading: true
          }
        }
      };
    }
    case USER_FOLLOWERS_UPDATED: {
      const payload = action.payload;
      const oldData = _.get(
        state,
        ["followers", payload.userUuid],
        INITIAL_CONTENT_STATE
      );

      const endReached =
        _.isEmpty(payload.followers) || payload.followers.length < 20;

      return {
        ...state,
        followers: {
          ...state.followers,
          [payload.userUuid]: {
            data: payload.refreshing
              ? payload.followers
              : [...oldData.data, ...payload.followers],
            loading: false,
            endReached: endReached
          }
        }
      };
    }
    case USER_FOLLOWING_UPDATED: {
      const payload = action.payload;
      const oldData = _.get(
        state,
        ["following", payload.userUuid],
        INITIAL_CONTENT_STATE
      );
      const endReached =
        _.isEmpty(payload.following) || payload.following.length < 20;

      return {
        ...state,
        following: {
          ...state.following,
          [payload.userUuid]: {
            data: payload.refreshing
              ? payload.following
              : [...oldData.data, ...payload.following],
            loading: false,
            endReached: endReached
          }
        }
      };
    }
    case USER_PROFILE_ACTIVE_TAB_UPDATED: {
      let profiles = state.profiles;
      if (!action.userUuid) {
        return state;
      }

      profiles = {
        ...profiles,
        [action.userUuid]: {
          ...profiles[action.userUuid],
          activeTab: action.tab
        }
      };

      return {
        ...state,
        profiles: profiles
      };
    }

    case RESET_ALL:
      for (const listener of Object.values(state.listeners)) {
        if (typeof listener === "function") listener();
      }
      for (const listener of Object.values(state.contentListeners)) {
        if (typeof listener === "function") listener();
      }
      if (typeof state.followingListener === "function") {
        state.followingListener();
      }
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userProfilesReducer;
