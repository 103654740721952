import React from "react";
import useResetFilter from "../../../hooks/useResetFilter";
import i18n from "../../../utils/i18n";
import BaseEmptyFeed from "./BaseEmptyFeed";

const EmptyTopicalFeedMessage = () => {
  /** ********************************** CONFIG ***************************************/
  const { resetFilters } = useResetFilter();
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <BaseEmptyFeed
      title={i18n.t("EmptyFeed.Title")}
      action={resetFilters}
      actionText={i18n.t("FeedFilter.reset")}>
      <p className="text-16 text-center">{i18n.t("EmptyFeed.P1")}</p>
    </BaseEmptyFeed>
  );
};

export default EmptyTopicalFeedMessage;
