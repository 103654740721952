import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import ShareSideColumn from "./components/ShareSideColumn";
import DraftMainColumn from "./components/DraftMainColumn";
import i18n from "../../utils/i18n";
import {
  addMediaToDraft,
  createEmptyDraft,
  saveDraft,
  sendDraftToBackend,
  setAndPrepareCurrentDraft,
  isValidDraftProps,
  getMediaUploadLocation
} from "../../actions/user-draft.actions";
import { fetchCaseLabel } from "../../actions/case-label.actions";
import PageSideColumn from "./components/PageSideColumn";
import { CASE_TYPES } from "../../constants/case-constants";
import { get, useForm } from "react-hook-form";
import {
  fetchSpecialties,
  getSpecialtiesOptions
} from "../../actions/case-specialties.actions";
import Loading from "../../components/loading";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { showErrorMessage } from "../../actions/global.actions";
import SubmittedCaseModal from "./components/SubmittedCaseModal";
import { CASE_POSTING, HOME_ROOT } from "../../constants/routes";
import { UPDATE_CURRENT_DRAFT_MEDIA } from "../../actions/user-draft.actions";
import { trackUploadDetails } from "../../actions/metrics.actions";
import { UPLOAD_CASE_TYPES } from "../../metrics/constants.metrics";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import {
  getFileNameWithoutExtension,
  sanitizeFileName
} from "../../utils/media-utils";
import UploadImageType from "../../constants/uploadImageType.constants";
import AppPromptAuthenticated from "../../components/app-prompt/AppPromptAuthenticated";

const extractValuesFromSelectData = (formData, name) => {
  if (Array.isArray(formData[name])) {
    return get(formData, name, []).map((s) => (s.value ? s.value : s));
  } else {
    return formData[name]?.value ? [formData[name]?.value] : [];
  }
};
const getSubmissionData = (formData) => {
  const specialtiesUuid = extractValuesFromSelectData(formData, "specialties");
  const subSpecialtiesUuid = extractValuesFromSelectData(
    formData,
    "subSpecialties"
  );

  const labelsUuid = [...formData.caseLabels, formData.status].filter(Boolean);

  const submissionData = {
    title: formData.title,
    caption: formData.caption,
    labelsUuid,
    specialtiesUuid,
    subSpecialtiesUuid,
    post_process_media: true,
    groupUuid: formData.groupUuid?.value
  };

  return submissionData;
};

const DraftDetails = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const { caseType, draftUid } = useParams();
  const history = useHistory();
  const fileRef = useRef(null);
  const { handleSubmit, control, setValue, errors, getValues } = useForm();
  const [caseSubmitted, setCaseSubmitted] = useState(false);

  const userUid = useSelector((state) => state.user.userUid);
  const caseLabelStore = useSelector((state) => state?.caseLabels);
  const draft = useSelector((state) => state?.userDraft?.currentDraft);
  const isSavingDraft = useSelector((state) => state?.userDraft?.isProcessing);
  const [isSaving, setIsSaving] = useState(false);
  const hasError = useSelector((state) => state?.error);
  const isPaging = draft?.paging || caseType === CASE_TYPES.PAGING;
  const isPageLoadingData = useSelector(
    (state) =>
      !state.caseLabels.hasBeenFetched || !state.caseSpecialties.fetched
  );
  const resolvedLabel = (caseLabelStore?.data || []).filter(
    (l) => l.labelKind === CASE_LABEL_TYPES.RESOLVED
  )[0] || { name: "", labelUuid: "nolabelresolved" };
  const specialtiesOptions = dispatch(getSpecialtiesOptions());

  /** ********************************** HOOKS ****************************************/

  // make sure the case labels and case specialties are loaded
  useEffect(() => {
    dispatch(fetchCaseLabel());
    dispatch(fetchSpecialties());
  }, [dispatch]);

  const setupDraft = useCallback(async () => {
    if (!caseLabelStore.hasBeenFetched) {
      return;
    }

    if (!draftUid) {
      const emptyDraftUid = await dispatch(createEmptyDraft(null, isPaging));
      await dispatch(setAndPrepareCurrentDraft(emptyDraftUid));
    } else if (draftUid) {
      await dispatch(setAndPrepareCurrentDraft(draftUid));
    }
  }, [caseLabelStore.hasBeenFetched, draftUid, isPaging, dispatch]);

  useEffect(() => {
    setupDraft();

    return () => {
      dispatch(setAndPrepareCurrentDraft(null));
    };
  }, [dispatch, setupDraft]);

  /** ********************************* FUNCTIONS *************************************/

  const onRemoveFile = (error, file) => {
    let match = false;
    let doDispatch = false;
    if (file.filename) {
      const media = draft.media
        .map((m) => {
          const outputNameWithoutExtension = getFileNameWithoutExtension(
            file.filename
          );
          const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
            m.filename
          );
          match = outputNameWithoutExtension === mediaFileNameWithoutExtension;
          if (match) {
            doDispatch = true;
            return null;
          }
          return m;
        })
        .filter((m) => !!m);

      if (doDispatch) {
        dispatch({
          type: UPDATE_CURRENT_DRAFT_MEDIA,
          payload: { draftUid: draft.draftUid, media }
        });
      }
    }
  };

  const onFileEdit = (output, item) => {
    let match = false;
    let doDispatch = false;
    if (item.filename) {
      const media = draft.media.map((m) => {
        const outputNameWithoutExtension = getFileNameWithoutExtension(
          item.filename
        );
        const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
          m.filename
        );
        match = outputNameWithoutExtension === mediaFileNameWithoutExtension;

        if (match) {
          doDispatch = match;
          delete m.uploaded_to_aws;
        }
        return m;
      });

      if (doDispatch) {
        dispatch({
          type: UPDATE_CURRENT_DRAFT_MEDIA,
          payload: { draftUid: draft.draftUid, media }
        });
      }
    }
  };

  const doFormProcessing = async (formData, submit) => {
    setIsSaving(true);
    const submissionData = getSubmissionData(formData);

    const files = await fileRef.current.prepareFiles();

    const mediaList = files.filter((f) => {
      return !!f.output;
    });

    // redundant check for case submission, but it works
    if (!isValidDraftProps({ ...formData, media: mediaList })) {
      dispatch(
        showErrorMessage(
          "DraftsScreen.draftValidationMinRequirementsError",
          null,
          false,
          null
        )
      );
      setIsSaving(false);
      return;
    }

    let awsValues;

    try {
      awsValues = await dispatch(
        getMediaUploadLocation({
          uploadType: UploadImageType.DRAFT,
          draftUid: draft.draftUid
        })
      );
    } catch (e) {
      dispatch(
        showErrorMessage("DraftsScreen.awsValueFetchError", null, false, null)
      );
      setIsSaving(false);

      return;
    }

    const _newFiles = mediaList
      .map((f, i) => {
        const output = f.output;
        if (!output) return; //eslint-disable-line array-callback-return

        const existingFile = draft.media.find((m) => {
          const outputNameWithoutExtension = getFileNameWithoutExtension(
            output.name
          );
          const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
            m.filename
          );
          return outputNameWithoutExtension === mediaFileNameWithoutExtension;
        });

        if (existingFile) {
          existingFile.index = i;
          if (!existingFile.uploaded_to_aws) {
            // eventually redundant
            if (!existingFile.originalFilename) {
              existingFile.originalFilename = existingFile.filename;
            }

            existingFile.file = output;
            // tick up itteration and update the filename
            existingFile.itteration =
              existingFile.itteration != null ? existingFile.itteration + 1 : 1;
            existingFile.filename = `${
              existingFile.itteration
            }-${sanitizeFileName(existingFile.originalFilename)}`;

            existingFile.url = `${awsValues.media_download_domain}drafts/${userUid}/${draft.draftUid}/${existingFile.filename}`;
          }
          return existingFile;
        }

        return {
          originalFilename: output.name,
          filename: `1-${sanitizeFileName(output.name)}`,
          index: i,
          type: "image", // hardcoded for now until we support multiple types
          url: `${awsValues.media_download_domain}drafts/${userUid}/${
            draft.draftUid
          }/1-${sanitizeFileName(output.name)}`,
          file: output,
          itteration: 1
        };
      })
      .filter((f) => f != null);

    try {
      await dispatch(
        addMediaToDraft(draft.draftUid, _newFiles, awsValues.media_upload_url)
      );
    } catch {
      // addMediaToDraft displays the message
      setIsSaving(false);
      return;
    }

    submissionData.media = _newFiles;

    const labelNames = submissionData.labelsUuid.map((id) => {
      const label = caseLabelStore?.data.find((l) => l.labelUuid === id);
      return label ? label.name : label.labelUuid;
    });
    const specialties = submissionData.specialtiesUuid.map((id) => {
      const label = specialtiesOptions.specialties.find((l) => l.value === id);
      return label ? label.label : label.value;
    });
    const subSpecialties = submissionData.subSpecialtiesUuid.map((id) => {
      const label = specialtiesOptions.subSpecialties.find(
        (l) => l.value === id
      );
      return label ? label.label : label.value;
    });

    trackUploadDetails({
      mediaCount: submissionData.media.length,
      title: submissionData.title,
      caption: submissionData.caption,
      caseStatus: submissionData.labelsUuid.find(
        (l) => l === resolvedLabel.labelUuid
      )
        ? i18n.t("FeedFilter.resolved")
        : i18n.t("FeedFilter.unresolved"),
      caseLabels: labelNames,
      shareOption: isPaging
        ? UPLOAD_CASE_TYPES.PAGING
        : UPLOAD_CASE_TYPES.NORMAL,
      draftUuid: draft.draftUid,
      isSaveDraft: !submit,
      specialties: specialties,
      subSpecialties: subSpecialties,
      groupUuid: submissionData.groupUuid || null
    });

    fileRef.current.removeFiles();
    if (submit) {
      await dispatch(
        sendDraftToBackend(userUid, draft.draftUid, false, submissionData)
      );
      setCaseSubmitted(true);
    } else {
      await dispatch(saveDraft(draft.draftUid, submissionData));
    }
    setIsSaving(false);
  };

  const onSaveCase = async (formData) => {
    return await doFormProcessing(formData, true);
  };

  const onSaveDraft = async () => {
    await doFormProcessing(getValues(), false);
    history.push(`${CASE_POSTING}/${draft?.draftUid}`);
  };

  const verifiedOnSaveCase = useVerifiedUserGate(onSaveCase);

  const onSubmitComplete = () => {
    setCaseSubmitted((isOpen) => !isOpen);
    history.push(HOME_ROOT);
  };

  /** ********************************** RENDER ***************************************/

  if (isPageLoadingData) {
    return <Loading />;
  }

  const rejectedInfo =
    draft?.rejectionReasonMessage || draft?.rejectionReason ? (
      <div className="warning-box mb-3 p-3 text-16">
        <span className="font-weight-bold">
          {i18n.t("DraftsScreen.requiredEditBanner")}:{" "}
        </span>
        {draft.rejectionReasonMessage || draft.rejectionReason}
      </div>
    ) : null;
  return (
    <Figure1Layout footer={<AppPromptAuthenticated />}>
      <Figure1Page2Col
        isChildPage
        headerRowSlot={rejectedInfo}
        mainContent={
          <>
            <SubmittedCaseModal
              toggle={onSubmitComplete}
              isOpen={caseSubmitted && !hasError}
              isPaging={isPaging}
            />
            <DraftMainColumn
              fileRef={fileRef}
              media={draft?.media}
              onRemoveFile={onRemoveFile}
              onFileEdit={onFileEdit}
            />
          </>
        }
        rightSidebarContent={
          !isPaging ? (
            <div className="draft-details-sidebar-content">
              <ShareSideColumn
                draft={draft}
                caseLabels={caseLabelStore.data}
                onSubmit={handleSubmit(verifiedOnSaveCase)}
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                isSaving={isSavingDraft || isSaving}
              />
            </div>
          ) : (
            <div className="draft-details-sidebar-content">
              <PageSideColumn
                draft={draft}
                caseLabels={caseLabelStore.data}
                onSubmit={handleSubmit(verifiedOnSaveCase)}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                isSaving={isSavingDraft || isSaving}
              />
            </div>
          )
        }
        headerButtonTitle={i18n.t("shareCase.buttonSaveDraft")}
        headerButtonClick={onSaveDraft}
        headerButtonLoadingStatus={isSavingDraft || isSaving}
      />
    </Figure1Layout>
  );
};

export default DraftDetails;
