/**
 * All possible types of activities sent from Backend.
 *
 * @format
 */

export const ACTIVITY_TYPES = {
  COMMENT: "comment",
  REPLY: "reply",
  POSTED: "posted",
  UPDATED: "updated",
  FOLLOW: "follow",
  APPROVE: "approve",
  REJECT: "reject",
  REACT: "react",
  MODERATION_ACTIVITY: "moderation_activity",
  CASE_DELETE: "case_delete",
  COMMENT_DELETE: "comment_delete"
};

export const ACTIVITY_STATES = {
  NEW: "new",
  ACKNOWLEDGED: "acknowledged"
};
