import React from "react";
import Button from "reactstrap/lib/Button";
import CustomIcon from "../custom-icon/CustomIcon";

const CaseLabelButton = ({ labelItem, handleClick }) => {
  return (
    <Button
      className="w-100 d-flex justify-content-between mb-2 pr-0"
      outline={!labelItem.active}
      color="primary"
      onClick={() => handleClick(labelItem.key)}>
      <span className={!labelItem.active ? `ml-0` : undefined}>
        {labelItem.name}
      </span>
      <span className="navigation-button-icon">
        {labelItem.active && (
          <CustomIcon
            icon="feed_channel-check"
            className="icon-white"
            size={20}
          />
        )}
      </span>
    </Button>
  );
};

export default CaseLabelButton;
