/** @format */

import {
  DEFAULT_LISTENER_ON,
  ELASTIC_SEARCH_LISTENER_ON,
  FETCH_CONFIGURATION_COMPLETE,
  FETCH_CONFIGURATION,
  FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE,
  LISTENERS_OFF
} from "../actions/configuration.actions";
import { RESET_ALL, UNSUBSCRIBE_LISTENERS } from "../actions/global.actions";
import { isEmpty } from "lodash";

const INITIAL_STATE = {
  data: {},
  selected: {},
  isProcessing: false,
  error: false,
  message: null,
  listeners: {},
  elasticSearch: {}
};

const configurationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONFIGURATION:
      return { ...state, isProcessing: true };
    case FETCH_CONFIGURATION_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        data: action.payload?.data || state.data,
        error: action.error,
        message: action.message
      };
    case FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        elasticSearch: action.payload.data
      };
    case DEFAULT_LISTENER_ON:
      return {
        ...state,
        listeners: { ...state.listeners, defaultListener: action.listener }
      };
    case ELASTIC_SEARCH_LISTENER_ON:
      return {
        ...state,
        listeners: {
          ...state.listeners,
          elasticSearchListener: action.listener
        }
      };
    case LISTENERS_OFF:
      return { ...state, listeners: {} };
    case UNSUBSCRIBE_LISTENERS:
      if (!isEmpty(state.listeners)) {
        Object.values(state.listeners).forEach((unsubscribe) =>
          unsubscribe ? unsubscribe() : null
        );
      }
      return { ...state, listeners: {} };
    case RESET_ALL:
      return clearElasticSearchConfig(state);
    default:
      return state;
  }
};

/**
 * Clear ES config data and deactivate and remove its listener.
 *
 * NOTE: ES config is only accessible if user is logged in, so it should be deleted on sign out.
 * @param state
 */
const clearElasticSearchConfig = (state) => {
  const { elasticSearchListener: unsubscribe, ...rest } = state?.listeners;

  // Unsub ES listener
  if (unsubscribe) {
    unsubscribe();
  }

  return {
    ...state,
    elasticSearch: {},
    listeners: {
      ...rest
    }
  };
};

export default configurationReducer;
