/** @format */

import { get } from "lodash";
import * as auth from "../api/auth";
import * as userDB from "../db/user.db";
import { refreshUserPreferences } from "../api/communications.cloud-functions";
import { toggleUserListener } from "./user.actions";
import { toggleUserFollowingListener } from "./user-profiles.actions";
import { toggleListenForUserPreferences } from "./preferences-center.actions";
import { toggleUserFeedMetaListener } from "./user-feed-meta.actions";
import {
  activateActivityMetaDataListener,
  deactivateActivityMetaDataListener
} from "./activity-center.actions";
import { toggleUserDraftListener } from "./user-draft.actions";
import { RESET_ALL, resetGatedState } from "./global.actions";
import LEGACY_ONBOARDING_STATE from "../constants/legacy-onboarding-state";
import * as ROUTES from "../constants/routes";
import { getUrl } from "../utils/route-utils";
import {
  activateElasticSearchConfigListener,
  deactivateAllListeners as deactivateConfigurationListeners
} from "./configuration.actions";
import { initAnonUserInfo, trackUserSignOut } from "../actions/metrics.actions";
import { deactivateAllListeners as deactivateSavedCaseListeners } from "./saved-cases.actions";
import { createUngatedUser } from "../api/user.cloud-functions";
import NonUserActionTypes from "./non-user-action-types";
import ONBOADRING_STATE_V2 from "../constants/onboarding-state-v2";
import {
  isLegacyUser,
  isNonHCP,
  isUserCompletedSignup
} from "../utils/onboarding-utils";
import i18n from "../utils/i18n";
import { FEED_NAMES } from "../constants/feed-type";
import LOCAL_STORAGE_CONSTANTS from "../constants/local-storage-constants";
import { getAndParseLocalStorageItem } from "../utils/local-storage-utils";

const actionsPrefix = "login";

export const AUTO_LOGIN = `${actionsPrefix}/AUTO_LOGIN`;
export const AUTO_LOGIN_COMPLETE = `${actionsPrefix}/AUTO_LOGIN_COMPLETE`;
export const AUTHENTICATE = `${actionsPrefix}/AUTHENTICATE`;
export const AUTHENTICATION_REVOKED = `${actionsPrefix}/AUTHENTICATION_REVOKED`;
export const AUTHENTICATE_COMPLETE = `${actionsPrefix}/AUTHENTICATE_COMPLETE`;
export const AUTHENTICATE_ERROR = `${actionsPrefix}/AUTHENTICATE_ERROR`;
export const START_VERIFICATION = `${actionsPrefix}/START_VERIFICATION`;
export const SIGNOUT = `${actionsPrefix}/SIGNOUT`;
export const SET_REDIRECT_ROUTE = `${actionsPrefix}/SET_REDIRECT_ROUTE`;
export const CLEAR_REDIRECT_ROUTE = `${actionsPrefix}/CLEAR_REDIRECT_ROUTE`;
export const SET_INITIAL_ROUTE = `${actionsPrefix}/SET_INITIAL_ROUTE`;
export const SIGNOUT_COMPLETE = `${actionsPrefix}/SIGNOUT_COMPLETE`;
export const REAUTHENTICATE = `${actionsPrefix}/REAUTHENTICATE`;
export const REAUTHENTICATE_COMPLETE = `${actionsPrefix}/REAUTHENTICATE_COMPLETE`;
export const LEGACY_MIGRATION = `${actionsPrefix}/LEGACY_MIGRATION`;
export const LEGACY_MIGRATION_COMPLETE = `${actionsPrefix}/LEGACY_MIGRATION_COMPLETE`;

export const anonSignIn = (preventRouting) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getAndParseLocalStorageItem(
        LOCAL_STORAGE_CONSTANTS.AUTH_USER
      );
      const email = getState().nonUser?.anonUserEmail;

      let user;
      let userUid;

      if (authUser) {
        user = authUser;
        userUid = authUser?.uid;
      } else {
        const result = await auth.anonLogin();
        user = result.user;
        userUid = user?.uid;
      }

      const userUuid = await createUngatedUser({
        email: email ? email : null,
        userUid
      });

      if (userUuid) {
        const anonUser = {
          userUid,
          userUuid
        };
        if (email) {
          anonUser.email = email;
        }

        if (!authUser) {
          initAnonUserInfo(anonUser);
        }

        dispatch(setAnonUserData(anonUser.userUuid, anonUser.email));
      }

      if (!authUser) {
        await handleUserPreloadedData(user);
        if (!preventRouting) {
          dispatch(handleUserInitialRoute(user));
        }
      }
    } catch (error) {
      // ???
    }
  };
};

export const setAnonUserData = (userUuid, email) => {
  return async (dispatch) => {
    const data = { type: NonUserActionTypes.SET_ANON_DATA };

    if (userUuid) {
      data.userUuid = userUuid;
    }

    if (email) {
      data.email = email;
    }

    dispatch(data);
  };
};

export const signIn = (email, pass, emitVerificationEvent, preventRedirect) => {
  return async (dispatch) => {
    try {
      dispatch(signOut());
      dispatch(startVerification());

      const result = await auth.login(email, pass);
      const user = await userDB.fetchUser(result.user?.uid);

      // In case an anon-user login/sign up, clear ungated state.
      dispatch(resetGatedState());

      await handleUserPreloadedData(user);
      if (!preventRedirect) {
        dispatch(handleUserInitialRoute(user));
      }

      if (emitVerificationEvent) {
        dispatch({
          type: LEGACY_MIGRATION_COMPLETE
        });
      }
      return dispatch(authenticationVerificationComplete(result));
    } catch (error) {
      return dispatch(
        authenticationVerificationError(
          new Error(i18n.t("RegistrationScreens.loginForm.failedLoginMessage"))
        )
      );
    }
  };
};

export const handleUngatedSignIn = () => {
  return async (dispatch, getState) => {
    const user = getState()?.user;

    const routeNameResult = await handleUserNavigation(user);
    dispatch(setInitialRoute(routeNameResult));
  };
};

export const handleUserInitialRoute = (user) => {
  return async (dispatch, getState) => {
    const redirectRoute = getState().authentication?.redirectRoute;
    const isGated = getState().global?.isGated;
    // if a route exists and they're finished registering
    if (redirectRoute && isUserCompletedSignup(user)) {
      dispatch(setInitialRoute(redirectRoute));
      dispatch(setRedirectRoute(null));
    } else if (!isGated) {
      const routeNameResult = await handleUserNavigation(user);
      dispatch(setInitialRoute(routeNameResult));
    }
  };
};

export const reAuth = (pass) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REAUTHENTICATE
      });
      await auth.reAuthenticate(pass);
      return dispatch({
        type: REAUTHENTICATE_COMPLETE
      });
    } catch (error) {
      return dispatch({
        type: REAUTHENTICATE_COMPLETE,
        error: true,
        errorResult: error
      });
    }
  };
};

export const handleAuthChange = (authResult) => {
  return async (dispatch, getState) => {
    if (authResult) {
      // Signed in
      const user = await userDB.fetchUser(authResult.uid);
      await handleUserPreloadedData(user);
      dispatch(authenticationVerificationComplete({ user: authResult }));
    } else {
      // Signed out

      const redirectRoute = getState().authentication.redirectRoute;
      // dispatch(signOut());
      // Why? because signout get's called even when the user is signed out... a couple times
      // and whilst that's not great, this is a bit of a workaround for the time being
      if (redirectRoute) {
        dispatch(setRedirectRoute(redirectRoute));
      }
    }
  };
};

export const toggleStateListeners = (user, forceListener) => {
  return async (dispatch) => {
    dispatch(toggleUserListener(user?.uid, forceListener));
    dispatch(toggleListenForUserPreferences(user?.uid));
    dispatch(toggleUserFeedMetaListener(user?.uid));
    dispatch(toggleUserDraftListener(user?.uid, user?.email));
    if (user) {
      dispatch(activateElasticSearchConfigListener());
      dispatch(activateActivityMetaDataListener());
    } else {
      dispatch(deactivateActivityMetaDataListener());
      dispatch(toggleUserFollowingListener(null));
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_ALL });
    dispatch(toggleStateListeners(null));
    dispatch(deactivateSavedCaseListeners());
    dispatch(deactivateConfigurationListeners());
    await auth.signOut();
    trackUserSignOut();
    dispatch(signoutComplete());
    // create new anon user otherwise some things break
  };
};

/**
 * Examples of data safe and desirable to load here
 * 1. Profile Data
 * 2. Settings Data
 * 3. Preferences for Communications
 */
const handleUserPreloadedData = async (user) => {
  const uid = get(user, "uid", "no uid for user");

  try {
    await refreshUserPreferences(user);
  } catch (error) {
    console.error(`There was an error refreshing preferences for user ${uid}`);
  }
};

/*
 * Display initial screen based on user onboarding state
 *
 * Used for auto login when user come back to the app after registration
 * Not intent to be used for registration flow itself.
 * */
// export const getInitialRouteName = (user) => {
//   if (!user) {
//     return ROUTES.REGISTRATION;
//   }
//   if (user) {
//     switch (onboardingState(user)) {
//       case ONBOARDING_STATE.USER_CREATED:
//       case LEGACY_ONBOARDING_STATE.VERIFICATION:
//         return ROUTES.REGISTRATION_VERFICATION_SELECT; //VERIFICATION_SCREENS.ROOT;
//       case ONBOARDING_STATE.VERIFICATION_COMPLETE:
//         return ROUTES.REGISTRATION_CREATE_ACCOUNT; //REGISTRATION_SCREENS.CREATE_ACCOUNT.ROOT;
//       case ONBOARDING_STATE.ONBOARDING_COMPLETE:
//         return getUrl(ROUTES.HOME_ROOT_FEED, { feed: "recommend" }); //HOME RECOMMEDED FEED;
//       case LEGACY_ONBOARDING_STATE.MIGRATE_ACCOUNT:
//         return ROUTES.REGISTRATION_ONBOARDING; //LEGACY_ON_BOARDING.ROOT;
//       case LEGACY_ONBOARDING_STATE.NON_HCP:
//         return ROUTES.REGISTRATION_ONBOARDING_NON_HCP; //NON_HCP.ROOT;
//       default:
//         return ROUTES.REGISTRATION; //REGISTRATION_SCREENS.ROOT;
//     }
//   }
//   return null;
// };

export const getRegistrationRoute = (user) => {
  switch (newOnboardingState(user)) {
    case ONBOADRING_STATE_V2.COUNTRY:
      return ROUTES.REGISTRATION_COUNTRY_V2;
    case ONBOADRING_STATE_V2.USA_INFORMATION:
      return ROUTES.REGISTRATION_INFORMATION_V2;
    case ONBOADRING_STATE_V2.INFORMATION:
      return ROUTES.REGISTRATION_INFORMATION_V2;
    case ONBOADRING_STATE_V2.VERIFICATION:
      return ROUTES.REGISTRATION_VERFICATION_PHOTO_V2;
    case ONBOADRING_STATE_V2.USERNAME:
      return ROUTES.REGISTRATION_USERNAME_V2;
    case ONBOADRING_STATE_V2.COMPLETED:
      if (user.groups?.length) {
        return getUrl(ROUTES.HOME_ROOT_TOPIC, {
          feed: FEED_NAMES.GROUP,
          feedTypeUuid: user.groups[0].groupUuid
        });
      }
      return getUrl(ROUTES.HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED }); //HOME RECOMMEDED FEED;
    case LEGACY_ONBOARDING_STATE.NON_HCP:
      return ROUTES.REGISTRATION_ONBOARDING_NON_HCP; //NON_HCP.ROOT;
    default:
      return ROUTES.REGISTRATION;
  }
};

export const newOnboardingState = (user) => {
  if (!user) {
    return ONBOADRING_STATE_V2.SIGN_UP;
  }

  if (isNonHCP(user)) {
    return LEGACY_ONBOARDING_STATE.NON_HCP;
  }

  if (isUserCompletedSignup(user)) {
    return ONBOADRING_STATE_V2.COMPLETED;
  }

  if (user.onboardingState) {
    return user.onboardingState;
  }

  // If user went through old reg flow but didn't complete, go to country screen
  return ONBOADRING_STATE_V2.COUNTRY;
};

const handleUserNavigation = async (user) => {
  let routeName;

  if (user) {
    if (
      (isLegacyUser(user) && !isUserCompletedSignup(user)) ||
      !user?.onboardingState
    ) {
      routeName = ROUTES.REGISTRATION_ONBOARDING_V2;
    } else if (isUserCompletedSignup(user) || user?.onboardingState) {
      routeName = await getRegistrationRoute(user);
    } else {
      routeName = ROUTES.REGISTRATION_COUNTRY_V2;
    }
  }
  return routeName;
};

export const setInitialRoute = (route) => {
  return {
    type: SET_INITIAL_ROUTE,
    initialRoute: route
  };
};

export const setRedirectRoute = (route) => {
  return {
    type: SET_REDIRECT_ROUTE,
    redirectRoute: route
  };
};

const startVerification = () => {
  return {
    type: START_VERIFICATION
  };
};

const authenticationVerificationComplete = (loginResult) => {
  return {
    type: AUTHENTICATE_COMPLETE,
    loginResult: loginResult
  };
};

const authenticationVerificationError = (error) => {
  return {
    type: AUTHENTICATE_ERROR,
    loginResult: error,
    error
  };
};

const signoutComplete = () => {
  return {
    type: SIGNOUT_COMPLETE
  };
};
