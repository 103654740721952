import React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CaseHeader from "./CaseHeader";
import CaseMediaView from "./CaseMediaView";
import ReactMarkdown from "react-markdown";
import CardFooter from "reactstrap/lib/CardFooter";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import classNames from "../../utils/class-names-utils";
import CaseBanner from "./CaseBanner";
import SocialContainer from "./SocialContainer";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import TagsContainer from "./TagsContainer";
import { defaultFeatures } from "../../constants/case-feature-constants";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import { getCaseTypeUrl } from "../../utils/case-utils";
import CaseTitle from "./CaseTitle";

const CaseCard = ({ caseContent, className, position }) => {
  /** ********************************** CONFIG ***************************************/
  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();
  const features = caseContent?.features || defaultFeatures;
  const showCaseActionBar =
    features.commentsEnabled || features.reactionsEnabled;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const linkUrl = getCaseTypeUrl(caseContent);

  return (
    <>
      {caseContent && (
        <div className={classNames("feed-card", className)}>
          {showBanner && <CaseBanner bannerType={bannerType} />}

          <Card className="border-0 rounded-0">
            <CaseHeader
              caseContent={caseContent}
              caseNotification={handleShowBanner}
            />

            {caseContent.media?.length > 0 && (
              <CaseMediaView
                className={caseContent.media.length === 1 ? "pb-3" : "pb-0"}
                canNavigate={true}
                caseUuid={caseContent.caseUuid}
                media={caseContent.media}
                videoPlayerConfig={{
                  videoControlMode: VIDEO_CONTROL_MODE.FEED
                }}
                linkUrl={linkUrl}
                context={CASE_CARD_ORIGIN.FEED}
                position={position}
              />
            )}

            <CardBody className="pt-0">
              <Link
                to={{
                  pathname: linkUrl,
                  state: {
                    position
                  }
                }}
                style={{ textDecoration: "none" }}>
                {caseContent.title && (
                  <div className="no-underline d-flex mb-1">
                    <CaseTitle caseContent={caseContent} />
                  </div>
                )}
                <div className="feed-card-info feed-card-info-markdown mb-0">
                  {caseContent.caption && (
                    <CardText
                      tag="div"
                      className={`text-default-black whitespace-pre-line ${
                        caseContent.title
                          ? "text-clamp-21-3"
                          : "text-clamp-21-2"
                      }`}>
                      <ReactMarkdown>{caseContent.caption}</ReactMarkdown>
                    </CardText>
                  )}
                </div>
              </Link>
              <TagsContainer className="mt-2" caseContent={caseContent} />
            </CardBody>

            {showCaseActionBar && (
              <CardFooter className="bg-white">
                <SocialContainer
                  caseContent={caseContent}
                  hideComments={!features.commentsEnabled}
                  hideReactions={!features.reactionsEnabled}
                  userReactions={caseContent.userReactions || []}
                  position={position}
                  socialContext={CASE_CARD_ORIGIN.FEED}
                  activateListener={false}
                  caseNotification={handleShowBanner}
                  hideSave={false}
                  iconSize={24}
                />
              </CardFooter>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

CaseCard.propTypes = {
  caseContent: PropTypes.object,
  className: PropTypes.string
};

export default CaseCard;
