/** @format */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import TouchableOpacity from "./TouchableOpacity";
import i18n from "../../../utils/i18n";
import { CLINICAL_MOMENTS_ACTIVITY } from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import { withRouter } from "react-router-dom";
import { getActivityStatus } from "../../../utils/cme-utils";
import useDialog from "../../../components/dialog/useDialog";

import "./CMEAvailableActivityCell.scss";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const CMEAvailableActivityCell = ({ activity, history }) => {
  const { isEligible = false, inProgress = false } = activity;
  const { confirm } = useDialog();

  let url = "";
  if (activity && activity.media && activity.media.url) {
    url = activity.media.url;
  }

  const imageContainer = {
    aspectRatio: "1",
    backgroundColor: "lightgrey",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    maxWidth: "281px"
  };

  const image = {
    maxWidth: "100%",
    display: "block",
    height: "auto",
    width: "auto",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  };

  const containerStyle = { width: "48%" };

  const navigateToActivity = useCallback(async () => {
    const status = getActivityStatus(activity);
    if (status.expired) {
      await confirm({
        message: i18n.t("CMEScreens.activityExpired"),
        confirmText: i18n.t("CMEScreens.Ok"),
        cancelText: ""
      });
    } else {
      history.push(
        getUrl(CLINICAL_MOMENTS_ACTIVITY, {
          caseId: activity.caseUuid
        })
      );
    }
  }, [activity, confirm, history]);

  return (
    <TouchableOpacity onPush={navigateToActivity} style={containerStyle}>
      <div className="d-flex flex-column outer-style">
        {activity?.credits && isEligible && (
          <div className="d-flex bg-lighter-gray text-default-black cme-earnable-credits credits-style helv-med align-items-center justify-content-center">
            <CustomIcon
              className="mr-1"
              icon="webnav_education_selected"
              size={16}
            />

            <ReactMarkdown>
              {`${activity?.credits || ""} ${
                activity?.credits && activity?.credits === 1
                  ? i18n.t("CMEScreens.cmeCredit")
                  : i18n.t("CMEScreens.cmeCredits")
              }`}
            </ReactMarkdown>
          </div>
        )}
        <div style={imageContainer}>
          {url && (
            <img alt="" src={`${url}?fit=crop&w=281&h=281`} style={image} />
          )}
        </div>
        <div className="d-flex flex-column justify-content-between lower-pane">
          <span className="color-default-black title-style">
            {activity.title}
          </span>
          {inProgress && (
            <div className="bg-lighter-gray text-battleship-gray d-flex align-items-center justify-content-center progress-style">
              In Progress
            </div>
          )}
        </div>
      </div>
    </TouchableOpacity>
  );
};

CMEAvailableActivityCell.propTypes = {
  activity: PropTypes.shape({
    caseUuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    credits: PropTypes.number,
    media: PropTypes.object,
    currentSlide: PropTypes.number,
    totalSlide: PropTypes.number,
    buttonUrl: PropTypes.string
  }).isRequired
};

export default withRouter(CMEAvailableActivityCell);
