import React from "react";
import PropTypes from 'prop-types';
import F1FlatList from "../../components/common/F1FlatList";
import { SEARCH_RESULT_TYPES } from "../../constants/search-result-constants";
import SearchCaseResultItemLoading from "./components/SearchCaseResultItemLoading";
import SearchProfileResultItemLoading from "./components/SearchProfileResultItemLoading";

function SearchResultsLoading({ searchResultType }) {
  const data = new Array(3).fill();
  return (
    <F1FlatList
      data={data}
      renderItem={({  index  }) =>
        searchResultType === SEARCH_RESULT_TYPES.CASE ? (
          <SearchCaseResultItemLoading key={index} />
        ) : (
          <SearchProfileResultItemLoading key={index} />
        )
      }
    />
  );
}

SearchResultsLoading.propTypes = {
  searchResultType: PropTypes.string
};

export default SearchResultsLoading;
