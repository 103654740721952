/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  GET_MEDIA_UPLOAD_LOCATION_COMPLETE,
  GET_MEDIA_UPLOAD_LOCATION_START,
  MEDIA_UPDATED,
  UPLOAD_MEDIA,
  UPLOAD_MEDIA_COMPLETE
} from "../actions/image-upload.actions";
import UploadImageType from "../constants/uploadImageType.constants";

const INITIAL_STATE = {
  data: {
    [UploadImageType.VERIFICATION]: [],
    [UploadImageType.DRAFT]: {}
  },
  isProcessing: false,
  isUploading: false,
  isFetchingUrl: false,
  error: false,
  message: null
};

const ImageUploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEDIA_UPDATED:
      return updateMediaList(state, action);
    case GET_MEDIA_UPLOAD_LOCATION_START:
      return { ...state, isFetchingUrl: true };
    case GET_MEDIA_UPLOAD_LOCATION_COMPLETE:
      return { ...state, isFetchingUrl: false };
    case UPLOAD_MEDIA:
      return { ...state, isUploading: true };
    case UPLOAD_MEDIA_COMPLETE:
      return { ...state, isUploading: false };
    case RESET_ALL:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

const handleMediaUpdate = ({ state, action, uploadType, newMedia }) => {
  switch (uploadType) {
    case UploadImageType.VERIFICATION: {
      return {
        ...state,
        data: { ...state.data, [UploadImageType.VERIFICATION]: newMedia }
      };
    }
    case UploadImageType.DRAFT: {
      return {
        ...state,
        data: {
          ...state.data,
          [UploadImageType.DRAFT]: {
            ...state.data[UploadImageType.DRAFT],
            [action.payload.draftUid]: newMedia
          }
        }
      };
    }
    default:
      return state;
  }
};

const updateMediaList = (state, action) => {
  const uploadType = action.payload.type;
  return handleMediaUpdate({
    state,
    action,
    uploadType,
    newMedia: action.payload.media
  });
};

export default ImageUploadReducer;
