/** @format */

import { getCollectionRef, getDocRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

const getSchoolsByProfession = async (professionUuid) => {
  try {
    const querySnapshot = await getDocRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/schools_by_profession/${professionUuid}/schools`
    ).get();

    return querySnapshot.exists ? querySnapshot.data() : [];
  } catch (error) {
    console.log(
      "failed to fetch schools by profession reference data with error: ",
      error.message
    );
    throw error;
  }
};

/**
 * Query Firebase for countries data, where each country doc has its regions nested within.
 *
 * Note: Fallback country data. Expect to use elastic search to get country/regions.
 * Use `countriesV2/countries` for app. `countries/countries` is used for admin tools.
 * Using `countriesV2/regions` is an option but fetching is too slow.
 * @returns
 */
const getAllCountriesAndRegions = async () => {
  try {
    const col = await getCollectionRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/countriesV2/countries`
    ).get();

    const data = [];

    if (!col.empty) {
      col.docs.forEach((doc) => {
        data.push(doc.data());
      });
    }

    return data;
  } catch (error) {
    console.log(
      "There was an error fetching countries and regions: ",
      error.message
    );
    throw new Error("There was an error fetching countries and regions");
  }
};

const getProfessions = async () => {
  try {
    const doc = await getDocRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/professions`
    ).get();

    return doc.exists ? doc.data() : {};
  } catch (error) {
    console.log("There was an error fetching professions: ", error.message);
    throw new Error("There was an error fetching professions");
  }
};

const getProfessionSpecialties = async (professionUuid) => {
  try {
    const collectionSnapshot = await getCollectionRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/professions/${professionUuid}`
    ).get();

    const professionSpecialties = [];

    if (!collectionSnapshot.empty) {
      collectionSnapshot.docs.forEach((doc) => {
        professionSpecialties.push(doc.data());
      });
    }

    return professionSpecialties;
  } catch (error) {
    console.log(
      "There was an error fetching profession specialties: ",
      error.message
    );
    throw new Error("There was an error fetching professions specialties");
  }
};

const getInterests = async () => {
  try {
    const docSnapshot = await getDocRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/interests`
    ).get();

    return docSnapshot.exists ? docSnapshot.data() : {};
  } catch (error) {
    console.log("There was an error fetching interests: ", error.message);
    throw new Error("There was an error fetching interests");
  }
};

const getPreviewFeed = async ({ feedTypeUuid, limit }) => {
  try {
    const snapshot = await getCollectionRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/feeds/${feedTypeUuid}`
    )
      .limit(limit)
      .get();

    const feed = [];
    if (!snapshot.empty) {
      snapshot.docs.forEach((doc) => {
        feed.push(doc.data());
      });
    }
    return feed;
  } catch (error) {
    console.log(
      "There was an error fetching preview feed: " + feedTypeUuid,
      error.message
    );
    throw new Error(
      "There was an error fetching preview feed: " + feedTypeUuid
    );
  }
};

const getPreviewFeedMeta = async () => {
  try {
    const docSnapshot = await getDocRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/feeds`
    ).get();

    return docSnapshot.exists ? docSnapshot.data()?.all : {};
  } catch (error) {
    console.log("There was an error fetching preview feeds: ", error.message);
    throw new Error("There was an error fetching preview feeds");
  }
};

const getGroups = async () => {
  try {
    const docSnapshot = await getDocRef(
      `${DB_CONSTANTS.REFERENCE_DATA_NEW}/groups`
    ).get();

    return docSnapshot.exists ? docSnapshot.data() : {};
  } catch (error) {
    console.log("There was an error fetching groups: ", error.message);
    throw new Error("There was an error fetching groups");
  }
};

export {
  getProfessions,
  getProfessionSpecialties,
  getSchoolsByProfession,
  getInterests,
  getAllCountriesAndRegions,
  getPreviewFeed,
  getPreviewFeedMeta,
  getGroups
};
