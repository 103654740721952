/** @format */

const FEED_KIND = {
  SEARCH: "search",
  RECOMMENDED: "made_for_you",
  EVERYTHING: "everything",
  TOPIC: "topic"
};

const FEED_NAMES = {
  RECOMMENDED: "recommend",
  EVERYTHING: "everything",
  TOPIC: "topic",
  TRENDING: "trending",
  GROUP: "group"
};

const FEED_META_LABELS = {
  RECOMMENDED: "madeForYou",
  EVERYTHING: "everything",
  TRENDING: "trendingCases"
};

export default FEED_KIND;
export { FEED_NAMES, FEED_META_LABELS };
