/** @format */

const GATED_MODAL_TYPE = {
  CERTIFICATE: "CERTIFICATE",
  COMMENT: "COMMENT",
  REACT: "REACT",
  PROFILE: "PROFILE",
  GENERIC: "GENERIC"
};

export default GATED_MODAL_TYPE;
