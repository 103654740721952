import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import LoadingButton from "../../../components/common/LoadingButton";
import { authCheckAndSignIn } from "../../../actions/non-user.actions";
import { toggleStateListeners } from "../../../actions/login.actions";
import { StaticStrings } from "../../../constants/static-string-constants";
import ExternalTextLink from "./ExternalTextLink";
import FormElementPassword from "./FormElementPassword";
import { Link, useHistory } from "react-router-dom";
import { trackEvent, trackUserSignIn } from "../../../actions/metrics.actions";
import { isMobile } from "react-device-detect";
import FormGlobalError from "./FormGlobalError";
import {
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useContactSupport } from "../../../hooks/useContactSupport";

const ClaimGroupSigninForm = ({ userEmail, groupUuid, switchFlow }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors } = useForm({
    mode: "onChange"
  });

  const [isLoading, setIsLoading] = useState(false);

  const onContactSupport = useContactSupport();

  const loginErrorMessage = useSelector((state) => {
    if (state?.authentication?.error) {
      return state.authentication.errorResult?.message;
    } else if (state?.nonUser?.error) {
      return state.nonUser.message;
    }
    return null;
  });

  /****************************** Hooks ***************************************/

  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (loginData) => {
    setIsLoading(true);
    trackUserSignIn();
    const result = await dispatch(
      authCheckAndSignIn(loginData.email || userEmail, loginData.password)
    );

    setIsLoading(false);
    if (!result.error) {
      if (result.loginResult?.user) {
        dispatch(toggleStateListeners(result.loginResult.user));
      }
    } else {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: result.message
      });
    }
  };

  const handleResetPasswordClick = () => {
    history.push(ROUTES.REGISTRATION_RESET_PASSWORD);
  };
  const signupSection = (
    <p className="w-100 mb-0 text-battleship-gray text-center text-15">
      {i18n.t("RegistrationScreens.claimGroupPage.dontHaveAccount")}
      <Link
        className="text-cool-blue cursor-pointer pl-1 font-weight-bolder"
        onClick={switchFlow}>
        {i18n.t("RegistrationScreens.claimGroupPage.btnSignUp")}
      </Link>
    </p>
  );

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0 shadow">
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <FormGlobalError errorText={loginErrorMessage} />
          {!userEmail && (
            <FormGroup className="mb-4">
              <Label for="email">
                {i18n.t("RegistrationScreens.claimGroupPage.emailOrUsername")}
              </Label>
              <Controller
                as={Input}
                control={control}
                disabled={isLoading}
                id="email"
                name="email"
                type="text"
                invalid={!!errors.email}
                placeholder={i18n.t(
                  "RegistrationScreens.claimGroupPage.emailOrUsernamePlaceholder"
                )}
                rules={{ required: true }}
              />
              <FormFeedback>
                {i18n.t("RegistrationScreens.claimGroupPage.emailRequired")}
              </FormFeedback>
            </FormGroup>
          )}
          <FormElementPassword
            control={control}
            errors={errors}
            isLoading={isLoading}
            showFeedback={true}
            useHelpAsError="true"
            helpText={i18n.t(
              "RegistrationScreens.claimGroupPage.passwordRequired"
            )}
          />
          <p className="mb-0">
            <Button
              color="link"
              className="pl-0 text-cool-blue text-15"
              onClick={handleResetPasswordClick}>
              {i18n.t("RegistrationScreens.loginForm.forgotPassword")}
            </Button>
          </p>
          <p className="mt-1 mb-3">
            <Button
              color="link"
              className=" pl-0 text-cool-blue text-15"
              onClick={onContactSupport}>
              {i18n.t("RegistrationScreens.loginForm.iDontKnowUsername")}
            </Button>
          </p>
          <LoadingButton
            className="mt-2 w-100"
            color="primary"
            size="lg"
            loading={isLoading}
            spinnerColor="white"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.claimGroupPage.btnSignIn")}
          </LoadingButton>
        </form>
      </Card>

      <p className="text-center text-15 mb-4">
        <span className="text-battleship-gray">
          {i18n.t("RegistrationScreens.claimGroupPage.bySigningIn")}
        </span>
        {isMobile && <br />}
        <ExternalTextLink
          className="text-cool-blue cursor-pointer"
          url={StaticStrings.termsOfServiceUrl}
          linkText={i18n.t(
            "RegistrationScreens.claimGroupPage.termsAndConditionsLink"
          )}
        />
      </p>

      <Card className="p-4 mb-4 rounded-0 shadow">{signupSection}</Card>
    </>
  );
};

ClaimGroupSigninForm.propTypes = {
  registrationState: PropTypes.string,
  setRegistrationState: PropTypes.func
};

export default ClaimGroupSigninForm;
