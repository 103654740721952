import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, FormGroup, CustomInput, Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { CASE_LABEL_TYPES } from "../../../constants/case-label-types";
import { getSpecialtiesOptions } from "../../../actions/case-specialties.actions";

import i18n from "../../../utils/i18n";
import AutoSizer from "../../../components/common/AutoSizer";
import CaseLabelGroup from "../../../components/upload-case/CaseLabelGroup";
import FormFeedback from "reactstrap/lib/FormFeedback";
import LoadingButton from "../../../components/common/LoadingButton";
import { includesInput } from "../../../utils/search-utils";
import TitleSummaryInputs from "./TitleSummaryInputs";
import useUserHasGroups from "../../../hooks/useUserHasGroups";
import { getGroupsInfoIfNeeded } from "../../../actions/reference.actions";
import { compact } from "lodash";

const PUBLIC_GROUP = { label: "Figure 1 public", value: null };

const ShareSideColumn = ({
  draft,
  caseLabels,
  onSubmit,
  control,
  errors = {},
  getValues,
  setValue,
  isSaving
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const hasGroups = useUserHasGroups();
  const availableGroups = useSelector((state) => {
    const userGroups = state?.user?.groups;
    const groupData = state?.references.groups?.data;
    // not ready yet
    if (!userGroups || !groupData) return [];

    return compact(
      userGroups.map((userGroup) => {
        const group = groupData?.[userGroup.groupUuid];
        if (!group) return null;

        return { label: group.groupName, value: userGroup.groupUuid };
      })
    );
  });
  const groupOptions = [PUBLIC_GROUP, ...availableGroups];
  const audienceOption = draft?.groupUuid
    ? availableGroups.find((option) => option.value === draft.groupUuid)
    : PUBLIC_GROUP;
  const specialtiesOptions = dispatch(getSpecialtiesOptions());
  const [caseLabelList, setCaseLabelList] = useState(null);

  const resolvedLabel = caseLabels.filter(
    (l) => l.labelKind === CASE_LABEL_TYPES.RESOLVED
  )[0] || { name: "", labelUuid: "nolabelresolved" };

  const unresolvedLabel = caseLabels.filter(
    (l) => l.labelKind === CASE_LABEL_TYPES.UNRESOLVED
  )[0] || { name: "", labelUuid: "nolabelunresolved" };

  const primarySpecialtyClassName = errors.specialties
    ? "react-select is-invalid"
    : "react-select";
  const subSpecialtyClassName = errors.subSpecialties
    ? "react-select is-invalid"
    : "react-select";
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    setValue("groupUuid", audienceOption);
  }, [audienceOption]);

  useEffect(() => {
    if (caseLabels && caseLabels?.length > 0) {
      const labels = [];

      // common
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.COMMON_PRESENTATION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );

      // trending
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.TEACHING_CASE
        )[0] || {
          name: "",
          labelUuid: "nolabelresolved"
        }
      );

      // rare
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.RARE_CONDITION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );

      setCaseLabelList(labels);
    }
  }, [caseLabels]);

  useEffect(() => {
    dispatch(getGroupsInfoIfNeeded());
  }, [dispatch]);

  /** ********************************* FUNCTIONS *************************************/
  const validateSpeciality = (value) => {
    if (Array.isArray(value) && (value.length < 1 || value.length > 3)) {
      return false;
    }

    return !!value;
  };

  const validateSubSpeciality = (value) => {
    if (!value) return false;

    if (value.value) return true; // single item, will sort this out in for data submission
    return Array.isArray(value) && value.length <= 3;
  };

  const groupSelection =
    hasGroups && availableGroups?.length ? (
      <FormGroup>
        <Label for="groupUuid">{i18n.t("shareCase.audience")}</Label>
        <Controller
          control={control}
          name="groupUuid"
          invalid={!!errors.audience ? 1 : 0}
          defaultValue={availableGroups[0]}
          as={
            <Select
              style={{
                width: "100%",
                resize: "none",
                borderColor: "#ccd3d8",
                borderRadius: "1px",
                lineHeight: "1.3rem",
                padding: "10px 15px 10px 15px"
              }}
              options={groupOptions}
              placeholder={i18n.t("shareCase.audiencePlaceholder")}
            />
          }
        />
      </FormGroup>
    ) : (
      ""
    );
  /** ********************************** RENDER ***************************************/
  return (
    <form onSubmit={onSubmit}>
      <AutoSizer className="position-lg-fixed b-lg-2 overflow-y-lg-auto">
        <Card className="p-3">
          {groupSelection}
          <div className="pb-3">
            <TitleSummaryInputs
              draft={draft}
              control={control}
              errors={errors}
            />
          </div>
          <div className="border-bottom border-width-3 border-lighter-gray w-100 px-4" />
          <div className="pt-3 pb-3">
            <FormGroup tag="fieldset" name="status" className="mb-3">
              <Label
                className="text-16 font-weight-500 text-default-black mb-3"
                for="status">
                {i18n.t("shareCase.caseStatusLabel")}
                <br />
                <span className="text-14 font-weight-normal">
                  {i18n.t("shareCase.caseStatusText")}
                </span>
              </Label>
              <Controller
                control={control}
                name="status"
                rules={{
                  required: true
                }}
                invalid={!!errors.status ? 1 : 0}
                defaultValue={
                  (draft?.labelsUuid || []).find(
                    (labelUuid) => labelUuid === resolvedLabel.labelUuid
                  ) || unresolvedLabel.labelUuid
                }
                render={({ onChange, value }) => (
                  <div
                    className={`radio-list ${
                      errors.status ? "is-invalid" : ""
                    }`}>
                    <CustomInput
                      value={unresolvedLabel.labelUuid}
                      type="radio"
                      id="statusRadio1"
                      name="statusRadio"
                      label={i18n.t("FeedFilter.unresolved")}
                      onChange={(e) =>
                        onChange(
                          e.target.checked
                            ? unresolvedLabel.labelUuid
                            : undefined
                        )
                      }
                      checked={unresolvedLabel.labelUuid === value}
                    />
                    <CustomInput
                      value={resolvedLabel.labelUuid}
                      type="radio"
                      id="statusRadio2"
                      name="statusRadio"
                      label={i18n.t("FeedFilter.resolved")}
                      onChange={(e) =>
                        onChange(
                          e.target.checked ? resolvedLabel.labelUuid : undefined
                        )
                      }
                      checked={resolvedLabel.labelUuid === value}
                    />
                  </div>
                )}
              />
              <FormFeedback>
                {i18n.t("shareCase.caseStatusRequired")}
              </FormFeedback>
            </FormGroup>

            <div className="border-bottom border-width-3 border-lighter-gray w-100 px-4 mb-3" />

            <CaseLabelGroup
              control={control}
              caseLabels={caseLabelList}
              draftLabels={draft?.labelsUuid || []}
              label={i18n.t("shareCase.labelYourCase")}
              requiredText={i18n.t("shareCase.labelRequired")}
              errors={errors.caseLabels}
              getValues={getValues}
            />

            <div className="border-bottom border-width-3 border-lighter-gray w-100 px-4 mt-3" />

            <p className="text-16 font-weight-500 text-default-black mt-3">
              {i18n.t("shareCase.categorizeYourCase")}
            </p>

            <FormGroup>
              <Label for="specialties">
                {i18n.t("shareCase.selectSpeciality")}
              </Label>
              <Controller
                control={control}
                name="specialties"
                rules={{
                  validate: validateSpeciality
                }}
                invalid={!!errors.specialties ? 1 : 0}
                defaultValue={specialtiesOptions.specialties.filter((s) =>
                  (draft?.specialtiesUuid || []).includes(s.value)
                )}
                as={
                  <Select
                    style={{
                      width: "100%",
                      resize: "none",
                      borderColor: "#ccd3d8",
                      borderRadius: "1px",
                      lineHeight: "1.3rem",
                      padding: "10px 15px 10px 15px"
                    }}
                    className={primarySpecialtyClassName}
                    isMulti
                    options={specialtiesOptions.specialties}
                    placeholder={i18n.t("shareCase.specialityPlaceholder")}
                    filterOption={includesInput}
                  />
                }
              />
              <FormFeedback>
                {i18n.t("shareCase.primarySpecialtyRequired")}
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="specialties">
                {i18n.t("shareCase.selectSubSpeciality")}
              </Label>
              <Controller
                control={control}
                name="subSpecialties"
                rules={{
                  validate: validateSubSpeciality
                }}
                invalid={!!errors.subSpecialties ? 1 : 0}
                defaultValue={specialtiesOptions.subSpecialties.filter((s) =>
                  (draft?.subSpecialtiesUuid || []).includes(s.value)
                )}
                as={
                  <Select
                    style={{
                      width: "100%",
                      resize: "none",
                      borderColor: "#ccd3d8",
                      borderRadius: "1px",
                      lineHeight: "1.3rem",
                      padding: "10px 15px 10px 15px"
                    }}
                    className={subSpecialtyClassName}
                    options={specialtiesOptions.subSpecialties}
                    isMulti
                    placeholder={i18n.t("shareCase.specialityPlaceholder")}
                    filterOption={includesInput}
                  />
                }
              />
              <FormFeedback>
                {i18n.t("shareCase.secondarySpecialtyMax")}
              </FormFeedback>
            </FormGroup>
          </div>
          <LoadingButton
            spinnerSize="sm"
            spinnerColor="secondary"
            disabled={isSaving}
            loading={isSaving}
            color="primary"
            size="md"
            type="submit"
            className="w-100 text-15 py-3">
            {i18n.t("shareCase.buttonShareCase")}
          </LoadingButton>
        </Card>
      </AutoSizer>
    </form>
  );
};

ShareSideColumn.propTypes = {
  saveCase: PropTypes.func,
  caseLabels: PropTypes.array
};

export default ShareSideColumn;
