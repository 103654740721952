/** @format */

import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import classNames from "../../../utils/class-names-utils";
import { trackProfileLink } from "../../../actions/metrics.actions";

const ExternalLink = ({ className, url, linkText, user }) => {
  const onLinkClick = () => {
    trackProfileLink({
      link: url,
      username: user.username,
      userUuid: user.userUuid,
      userType: user.userType
    });
  };

  return (
    <Button
      className={classNames("text-denim-blue font-weight-bold", className)}
      size="lg"
      color="lighter-gray"
      tag="a"
      rel="noopener noreferrer"
      href={url}
      target="_blank"
      onClick={onLinkClick}>
      {linkText || url}
      <CustomIcon
        className="icon-denim-blue ml-2"
        icon="external_link_arrow"
        size={24}
      />
    </Button>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  className: PropTypes.string
};

ExternalLink.defaultProps = {
  linkText: ""
};

export default ExternalLink;
