import i18n from "../utils/i18n";

export const FILTER_KEYS = {
  filter_unresolved: "filter_unresolved",
  filter_resolved: "filter_resolved",
  filter_trending: "filter_trending",
  filter_verified_literature: "filter_verified_literature",
  filter_common_presentation: "filter_common_presentation",
  filter_rare_condition: "filter_rare_condition",
  filter_teaching_case: "filter_teaching_case",
  filter_paging_case: "filter_paging_case"
};

export const FEED_FILTERS = {
  [FILTER_KEYS.filter_unresolved]: {
    name: i18n.t("FeedFilter.unresolved"),
    order: 0
  },
  [FILTER_KEYS.filter_resolved]: {
    name: i18n.t("FeedFilter.resolved"),
    order: 1
  },
  [FILTER_KEYS.filter_trending]: {
    name: i18n.t("FeedFilter.trending")
  },
  [FILTER_KEYS.filter_verified_literature]: {
    key: "filter_verified_literature",
    name: i18n.t("FeedFilter.hasLiterature")
  },
  [FILTER_KEYS.filter_common_presentation]: {
    key: "filter_common_presentation",
    name: i18n.t("FeedFilter.commonPresentation")
  },
  [FILTER_KEYS.filter_rare_condition]: {
    key: "filter_rare_condition",
    name: i18n.t("FeedFilter.rareCondition")
  },
  [FILTER_KEYS.filter_teaching_case]: {
    key: "filter_teaching_case",
    name: i18n.t("FeedFilter.teachingCase")
  },
  [FILTER_KEYS.filter_paging_case]: {
    key: "filter_paging_case",
    name: i18n.t("FeedFilter.pagingCase")
  }
};
