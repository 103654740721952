/** @format */
import {
  FETCH_TOPICS,
  FETCH_TOPICS_COMPLETE,
  FOLLOW_TOPIC,
  FOLLOW_TOPIC_ERROR,
  FOLLOW_TOPIC_COMPLETE
} from "../actions/topics.actions";

export const INITIAL_STATE = {
  data: [],
  selected: {},
  isProcessing: false,
  error: false,
  message: null
};

const TopicsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TOPICS:
      return { ...state, isProcessing: true };
    case FETCH_TOPICS_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        data: action.data || state.data,
        message: action.message
      };
    case FOLLOW_TOPIC:
      return {
        ...state,
        selected: action.selected
      };
    case FOLLOW_TOPIC_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
        selected: action.selected
      };
    case FOLLOW_TOPIC_COMPLETE:
      return {
        ...state,
        selected: action.selected
      };
    default:
      return state;
  }
};

export default TopicsReducer;
