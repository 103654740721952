import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import { getSupportEmail } from "../../../utils/email-utils";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const NonHcpContactSupportModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody className="p-5">
        <p className="font-weight-bold text-20">
          {i18n.t(
            "RegistrationScreens.nonHcpPage.healthCareProviderModal.title"
          )}
        </p>
        <hr style={{ height: 4 }} className="mt-3 mb-3" />
        <p className="text-16 mb-2">
          {i18n.t(
            "RegistrationScreens.nonHcpPage.healthCareProviderModal.prompt"
          )}
        </p>
        <ul>
          <li>
            <p className="text-16 font-weight-bold mb-2">
              {i18n.t(
                "RegistrationScreens.nonHcpPage.healthCareProviderModal.req1"
              )}
            </p>
          </li>
          <li>
            <p className="text-16 font-weight-bold mb-2">
              {i18n.t(
                "RegistrationScreens.nonHcpPage.healthCareProviderModal.req2"
              )}
            </p>
          </li>
        </ul>
        <hr style={{ height: 4 }} className="mt-3 mb-3" />
        <p className="text-16">
          {i18n.t(
            "RegistrationScreens.nonHcpPage.healthCareProviderModal.explanation"
          )}
        </p>
        <div className="d-flex flex-column justify-content-center">
          <Button
            className="mt-5 mx-auto px-4"
            color="primary"
            href={getSupportEmail({
              body: i18n.t(
                "RegistrationScreens.nonHcpPage.healthCareProviderModal.emailContent"
              )
            })}>
            <p className="text-16 m-0">
              {i18n.t("RegistrationScreens.contactSupport")}
            </p>
          </Button>
        </div>
      </ModalBody>
      <Button
        className="no-decoration-button d-flex justify-content-end cursor-pointer position-absolute p-2 r-3 t-3"
        onClick={toggle}>
        <CustomIcon
          icon="modal_exit"
          className="icon-default-black"
          size={14}
        />
      </Button>
    </Modal>
  );
};

NonHcpContactSupportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default NonHcpContactSupportModal;
