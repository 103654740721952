import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { RESET_PASSWORD_STATES } from "../../../constants/registration-states";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import { StaticStrings } from "../../../constants/static-string-constants";
import { resetPassword } from "../../../actions/non-user.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import { trackEvent } from "../../../actions/metrics.actions";
import {
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { useContactSupport } from "../../../hooks/useContactSupport";

const ResetPassword = () => {
  /** ********************************** CONFIG ***************************************/
  let history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();
  const [resetPasswordState, setResetPasswordState] = useState(
    RESET_PASSWORD_STATES.RESET
  );

  const [maskedEmail, setMaskedEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onContactSupport = useContactSupport();

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (data) => {
    setIsLoading(true);

    const resetResults = await dispatch(resetPassword(data.email));

    setIsLoading(false);

    trackEvent(METRICS_EVENT_NAME.FORGOT_PASSWORD, {
      [METRICS_USER_PROPERTY.LOGIN]: data.email
    });

    if (resetResults.error) {
      setResetPasswordState(RESET_PASSWORD_STATES.USERNAME_NO_MATCH);
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: `${resetResults.message} (${data.email})`
      });
    } else {
      setMaskedEmail(resetResults.email);
      setResetPasswordState(RESET_PASSWORD_STATES.USERNAME_MATCH);
    }
  };

  const handleShowSupportClick = () => {
    document.location = `mailto:${StaticStrings.supportEmail}`;
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0 ">
        <h1 className="helv-bold text-22 mb-5">
          {i18n.t("RegistrationScreens.resetPasswordForm.resetPasswordTitle")}
        </h1>
        {resetPasswordState === RESET_PASSWORD_STATES.RESET && (
          <>
            <form onSubmit={handleSubmit(handleSubmitClick)}>
              <FormGroup>
                <Label for="email">
                  {i18n.t("RegistrationScreens.loginForm.emailOrUsername")}
                </Label>
                <Controller
                  as={Input}
                  control={control}
                  id="email"
                  name="email"
                  placeholder={i18n.t(
                    "RegistrationScreens.loginForm.emailOrUsernamePlaceholder"
                  )}
                  rules={{ required: true }}
                />
                <FormFeedback>
                  {i18n.t("RegistrationScreens.loginForm.emailRequired")}
                </FormFeedback>
              </FormGroup>

              <p className="mb-0">
                <Button
                  color="link"
                  className="pl-0 text-cool-blue text-15"
                  onClick={onContactSupport}>
                  {i18n.t("RegistrationScreens.loginForm.iDontKnowUsername")}
                </Button>
              </p>
              <div className="d-flex align-items-center">
                <LoadingButton
                  loading={isLoading}
                  spinnerColor="white"
                  type="submit"
                  className="w-100 mt-2 mb-3"
                  size="lg"
                  color="primary">
                  {i18n.t(
                    "RegistrationScreens.resetPasswordForm.btnSentResetLink"
                  )}
                </LoadingButton>
              </div>
            </form>
          </>
        )}
        {resetPasswordState === RESET_PASSWORD_STATES.USERNAME_MATCH && (
          <div className="mb-100">
            <p className="font-weight-500">
              <CustomIcon
                className="icon-default-black cursor-pointer"
                icon="feed_channel-check"
                size={14}
              />
              <span className="pl-1">
                {i18n.t("RegistrationScreens.resetPasswordForm.linkSent")}
              </span>
            </p>

            <p className="mb-0">
              {i18n.t("RegistrationScreens.resetPasswordForm.foundAccount")}
              <span className="pl-1">{maskedEmail}</span>
            </p>
            <p className="mb-4">
              {i18n.t("RegistrationScreens.resetPasswordForm.foundAccount2")}
            </p>

            <p className="d-flex w-100 mb-2">
              <span
                onClick={() => history.push(getUrl(ROUTES.REGISTRATION_LOGIN))}
                className="cursor-pointer text-cool-blue">
                {i18n.t("RegistrationScreens.goBackAndSignIn")}
              </span>
            </p>

            <p
              onClick={onContactSupport}
              className="text-cool-blue cursor-pointer">
              {i18n.t("RegistrationScreens.resetPasswordForm.noAccessToEmail")}
            </p>
          </div>
        )}

        {resetPasswordState === RESET_PASSWORD_STATES.USERNAME_NO_MATCH && (
          <div className="mb-100">
            <p className="font-weight-500">
              <CustomIcon
                className="cursor-pointer icon-red"
                icon="clear_search_x"
                size={14}
              />
              <span className="pl-1">
                {i18n.t("RegistrationScreens.resetPasswordForm.noMatchFound")}
              </span>
            </p>

            <p className="mb-3">
              {i18n.t("RegistrationScreens.resetPasswordForm.noMatchFoundText")}
            </p>

            <Button
              className="w-125px mt-2 mb-4"
              color="primary"
              onClick={() =>
                setResetPasswordState(RESET_PASSWORD_STATES.RESET)
              }>
              {i18n.t("RegistrationScreens.resetPasswordForm.btnTryAgain")}
            </Button>

            <p
              onClick={handleShowSupportClick}
              className="text-cool-blue cursor-pointer">
              {i18n.t("RegistrationScreens.contactSupport")}
            </p>
          </div>
        )}
      </Card>
    </>
  );
};

export default ResetPassword;
