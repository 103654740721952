import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const listenForChanges = async (callback) => {
  return getCollectionRef(`${DB_CONSTANTS.CONFIGURATION}`)
    .doc(DB_CONSTANTS.DEFAULT_CONFIG)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const listenForElasticSearchChanges = async (callback) => {
  getCollectionRef(DB_CONSTANTS.CONFIGURATION)
    .doc(DB_CONSTANTS.ELASTIC_CONFIG)
    .onSnapshot(callback, (error) => {
      console.error("Load elastic search error", error);
    });
};
