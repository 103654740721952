import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem
} from "reactstrap";
import { capitalize } from "lodash";
import CustomIcon from "../custom-icon/CustomIcon";
import CaseDropdownItem from "./CaseDropdownItem";
import i18n from "../../utils/i18n";
import {
  trackCaseCopyLink,
  trackReportCase
} from "../../actions/metrics.actions";
import { reportCase } from "../../actions/case.actions";
import { keysToCamelCase } from "../../utils/camelCaseUtils";
import { CASE_BANNER_TYPE } from "../../constants/case-banner-constants";
import { defaultFeatures } from "../../constants/case-feature-constants";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import useDialog from "../dialog/useDialog";
import useIsGated from "../../hooks/useIsGated";

const CaseDropdown = ({
  caseContent,
  caseNotification,
  commentStatus,
  from
}) => {
  /** ********************************** CONFIG ***************************************/
  const {
    caseUuid,
    features = defaultFeatures,
    shareLink = "https://figure1.com"
  } = keysToCamelCase(caseContent);
  const { reportEnabled } = features;

  const dispatch = useDispatch();
  const { confirm } = useDialog();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isGated = useIsGated();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleCopyLinkClick = () => {
    // Workaround for the occasions when the shareLink has the wrong url for whatever reason
    navigator.clipboard.writeText(
      shareLink.replace("/case-detail/", "/cases/")
    );

    caseNotification(CASE_BANNER_TYPE.LINK_COPIED);
    trackCaseCopyLink(caseUuid);
  };

  const onSubmitReport = async (reportId) => {
    await dispatch(reportCase(caseUuid, reportId));
  };

  const reportCaseTap = async () => {
    const confirmed = await confirm({
      message: i18n.t("CaseAction.reportCaseTitle"),
      confirmText: capitalize(i18n.t("common.report"))
    });

    trackReportCase({ caseContent, commentStatus, from });

    if (!confirmed) {
      return;
    }

    onSubmitReport("unspecified reason");
  };

  const verifiedReportCaseTap = useVerifiedUserGate(reportCaseTap);

  /** ********************************** RENDER ***************************************/

  return (
    <div className="d-flex">
      <Dropdown
        className="dropdown-profile"
        isOpen={isDropdownOpen}
        toggle={toggleDropdown}>
        <DropdownToggle
          className="cursor-pointer"
          tag="span"
          aria-expanded={isDropdownOpen}>
          <CustomIcon icon="ellipses" color="#6b778b" size={20} />
        </DropdownToggle>
        <DropdownMenu right className="px-3">
          {isGated && (
            <>
              <div className="disabled py-2">
                <div className="case-dropdown-text-area d-flex justify-content-center">
                  <div className="case-dropdown-text-secondary text-center helv-med">
                    {i18n.t("caseDropdown.disabledHeader")}
                  </div>
                </div>
              </div>
              <DropdownItem divider />
            </>
          )}
          <CaseDropdownItem
            icon="sheet_copy_link"
            iconColor="#1a252b"
            handleClick={handleCopyLinkClick}
            primaryText={i18n.t("CaseAction.copyLink")}
          />
          {reportEnabled && (
            <>
              <DropdownItem divider />
              <CaseDropdownItem
                disabled={isGated}
                icon="sheet_report"
                iconColor={isGated ? "#adb5bd" : "#ed1c30"}
                primaryText={i18n.t("CaseAction.reportPostTitle")}
                secondaryText={i18n.t("CaseAction.reportPostSubtitle")}
                handleClick={verifiedReportCaseTap}
              />
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default CaseDropdown;
