import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import LoadingButton from "../../../components/common/LoadingButton";
import { signUpV2 } from "../../../actions/non-user.actions";
import { toggleStateListeners } from "../../../actions/login.actions";
import { StaticStrings } from "../../../constants/static-string-constants";
import ExternalTextLink from "./ExternalTextLink";
import FormElementPassword from "./FormElementPassword";
import { Link, useHistory } from "react-router-dom";
import { trackRegistration } from "../../../actions/metrics.actions";
import { isMobile } from "react-device-detect";
import FormGlobalError from "./FormGlobalError";
import FormElementEmail from "./FormElementEmail";
import { getUrl } from "../../../utils/route-utils";

const ClaimGroupSignupForm = ({ userEmail, groupUuid, switchFlow }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onChange"
  });

  const user = useSelector((state) => state.user);

  const [signUpRoute, setSignUpRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /****************************** Hooks ***************************************/

  // trigger state change if it's legacy sign up route
  useEffect(() => {
    if (signUpRoute && user?.userUid) {
      setSignUpRoute(false);
    }
  }, [signUpRoute, user]);

  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      (formData.email || userEmail) === undefined ||
      formData.password === undefined
    ) {
      return;
    }
    if (!formState.isValid) {
      return;
    }

    trackRegistration({
      email: formData.email || userEmail
    });

    setIsLoading(true);
    const result = await dispatch(
      signUpV2(
        formData.email || userEmail,
        formData.password,
        formData.country?.value,
        groupUuid
      )
    );

    if (result.error && result.message) {
      setIsLoading(false);
      setErrorMessage(result.message);
    } else {
      dispatch(toggleStateListeners(result.authResult?.user));
      history.push(
        getUrl(ROUTES.GROUP_WELCOME_SPLASH, {
          groupUuid: groupUuid
        })
      );
    }
  };

  const signinSection = (
    <p className="w-100 mb-0 text-battleship-gray text-center text-15">
      {i18n.t("RegistrationScreens.claimGroupPage.alreadyMember")}
      <Link
        className="text-cool-blue cursor-pointer pl-1 font-weight-bolder"
        onClick={switchFlow}>
        {i18n.t("RegistrationScreens.claimGroupPage.btnSignIn")}
      </Link>
    </p>
  );

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0 shadow">
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <FormGlobalError errorText={errorMessage} />
          {!userEmail && (
            <FormElementEmail
              errors={errors}
              control={control}
              showFeedback={true}
              value={userEmail}
              disabled={!!userEmail}
            />
          )}
          <FormElementPassword
            control={control}
            errors={errors}
            isLoading={isLoading}
            showFeedback={true}
            useHelpAsError="true"
            labelText={i18n.t(
              "RegistrationScreens.claimGroupPage.signupPassword"
            )}
            helpText={i18n.t(
              "RegistrationScreens.claimGroupPage.passwordRequired"
            )}
          />

          <LoadingButton
            className="mt-2 w-100"
            color="primary"
            size="lg"
            loading={isLoading}
            spinnerColor="white"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.claimGroupPage.btnSignUp")}
          </LoadingButton>
        </form>
      </Card>

      <p className="text-center text-15 mb-4">
        <span className="text-battleship-gray">
          {i18n.t("RegistrationScreens.claimGroupPage.bySigningUp")}
        </span>
        {isMobile && <br />}
        <ExternalTextLink
          className="text-cool-blue cursor-pointer"
          url={StaticStrings.termsOfServiceUrl}
          linkText={i18n.t(
            "RegistrationScreens.claimGroupPage.termsAndConditionsLink"
          )}
        />
      </p>

      <Card className="p-4 mb-4 rounded-0 shadow">{signinSection}</Card>
    </>
  );
};

ClaimGroupSignupForm.propTypes = {
  registrationState: PropTypes.string,
  setRegistrationState: PropTypes.func
};

export default ClaimGroupSignupForm;
