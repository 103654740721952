import Firebase from "../firebase";

const functions = Firebase.functions;

export const searchCasesCall = async ({
  searchCursor,
  searchIndex,
  searchTerm,
  userUid
}) => {
  const result = await functions.httpsCallable("backendSearchCases")({
    search_cursor: searchCursor,
    search_index: searchIndex,
    search_term: searchTerm,
    search_user_uid: userUid
  });
  return result;
};

export const searchUsersCall = async ({ searchCursor, searchTerm }) => {
  const result = await functions.httpsCallable("backendSearchUsers")({
    search_cursor: searchCursor,
    search_term: searchTerm
  });
  return result;
};
