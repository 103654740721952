import React from "react";
import CustomIcon from "./CustomIcon";

const CircleCustomIcon = ({
  icon,
  color,
  size,
  rotation,
  style,
  circleColor,
  innerClassName,
  className = ""
}) => {
  return (
    <span
      className={`d-flex bg-${circleColor} rounded rounded-circle ${className}`}>
      <CustomIcon
        className={innerClassName}
        icon={icon}
        color={color}
        size={size}
        rotation={rotation}
        style={style}
      />
    </span>
  );
};

export default CircleCustomIcon;
