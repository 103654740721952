import { useSelector } from "react-redux";

const useIsAStudent = () => {
  const user = useSelector((state) => state.user);
  return (
    user?.primarySpecialty?.tree?.profession?.professionCategoryLabel
      ?.toLowerCase()
      .indexOf("student") > -1
  );
};

export default useIsAStudent;
