/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const listenForChanges = async (callback, userId) => {
  return getCollectionRef(DB_CONSTANTS.USER_FEED)
    .doc(userId)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const listenForFeedUpdate = async (callback, userId, feedTypeUuid) => {
  return getCollectionRef(`${DB_CONSTANTS.USER_FEED}/${userId}/${feedTypeUuid}`)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};
