import React, { useRef } from "react";
import AppPromptSmall from "./AppPromptSmall";
import AppPromptFull from "./AppPromptFull";
import useAppPrompt from "./useAppPrompt";
import useResizeObserver from "../../hooks/use-resize-observer.hooks";
import "./AppPromptAuthenticated.scss";

const AppPromptAuthenticated = () => {
  /****************************** Config **************************************/
  const appPromptRef = useRef(null);
  const {
    appUrl,
    isShown,
    isDeclined,
    declineAppPrompt,
    setAppPromptHeight,
    onOpenApp
  } = useAppPrompt();

  /****************************** Hooks ***************************************/

  useResizeObserver({
    resizeObserverCallback: (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      setAppPromptHeight(entries[0].target.getBoundingClientRect().height);
    },
    elementRef: appPromptRef
  });

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return (
    isShown && (
      <>
        {isDeclined ? (
          <AppPromptSmall
            ref={appPromptRef}
            appUrl={appUrl}
            cardClassName="app-prompt-declined"
            confirmButtonColor="denim-blue"
            titleColor="default-black"
            onOpenApp={onOpenApp}
          />
        ) : (
          <AppPromptFull
            ref={appPromptRef}
            appUrl={appUrl}
            onDecline={declineAppPrompt}
            onOpenApp={onOpenApp}
          />
        )}
      </>
    )
  );
};

export default AppPromptAuthenticated;
