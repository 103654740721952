/** @format */

import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";
import classNames from "../../utils/class-names-utils";

import "./F1FlatList.scss";

const F1FlatList = ({
  data,
  renderItem,
  className,
  ListFooterComponent,
  ItemSeparatorComponent,
  bordered = true
}) => {
  const results = data.map((item, index) => {
    return (
      <React.Fragment key={`fragment-${index}`}>
        {renderItem({ item, index })}
        {ItemSeparatorComponent && index !== data.length - 1 && (
          <ItemSeparatorComponent key={`separator-${index}`} index={index} />
        )}
      </React.Fragment>
    );
  });

  return (
    <div className={classNames("f1-flat-list", className)}>
      <ListGroup className={bordered ? "list-group-border-x" : null} flush>
        {results}
      </ListGroup>
      {ListFooterComponent && <ListFooterComponent />}
    </div>
  );
};

F1FlatList.propTypes = {
  /**
   * The data to be rendered
   */
  data: PropTypes.arrayOf(PropTypes.any).isRequired,

  /**
   * Takes an item from data and renders it into the list. Typical usage:
   */
  renderItem: PropTypes.func.isRequired,

  /**
   * A component rendered in between each item, but not at the top or bottom of the list.
   */
  ItemSeparatorComponent: PropTypes.func,

  /**
   * Rendered at the very end of the list.
   */
  ListFooterComponent: PropTypes.func,

  /**
   * Any additional class names to be added to the component.
   */
  className: PropTypes.string,

  /**
   * Should the list contain top and bottom borders?
   */
  bordered: PropTypes.bool
};

export default F1FlatList;
