import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import PropTypes from "prop-types";
import ProfileEditModal from "./ProfileEditModal";
import ProfileEditYearsFormGroup from "./ProfileEditYearsFormGroup";
import { addOrUpdateInfoEntry } from "../../../actions/user-profiles.actions";
import { PROFILE_INFO_TYPE } from "../../../constants/profile-info-constants";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";
import { trackEditAbout } from "../../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";

const ProfileEditEducationModal = ({ toggle, isOpen, userUuid, ...rest }) => {
  /************************************ CONFIG ***************************************/

  const { setValue, getValues, handleSubmit, errors, control, watch } =
    useForm();
  const data = {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = usePopup();

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const save = async (values) => {
    try {
      trackEditAbout(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_EDUCATION);

      const result = await dispatch(
        addOrUpdateInfoEntry({
          type: PROFILE_INFO_TYPE.EDUCATION,
          description: values.school,
          location: values.degree,
          startYear: values.startYear,
          endYear: values.endYear,
          id: get(data, ["educationUuid"], null)
        })
      );

      if (result.error) {
        showToast({
          message: t("Profile.updateError"),
          toastType: TOAST_TYPE.ERROR
        });
      } else {
        toggle();
      }
    } catch (error) {
      showToast({
        message: t("Profile.updateError"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <ProfileEditModal
      title={t("Profile.education")}
      toggle={toggle}
      isOpen={isOpen}
      onSubmit={handleSubmit(save)}
      {...rest}>
      <FormGroup>
        <Label for="school">{t("Profile.schoolLabel")}</Label>
        <Controller
          as={Input}
          control={control}
          id="school"
          name="school"
          type="text"
          placeholder={t("Profile.schoolPlaceholder")}
          defaultValue={get(data, ["description"], "")}
          rules={{ required: true }}
          invalid={!!errors.school}
        />
        <FormFeedback>{t("Profile.schoolError")}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="degree">{t("Profile.degreeLabel")}</Label>
        <Controller
          as={Input}
          control={control}
          id="degree"
          name="degree"
          type="text"
          placeholder={t("Profile.degreePlaceholder")}
          defaultValue={get(data, ["location"], "")}
          rules={{ required: true }}
          invalid={!!errors.degree}
        />
        <FormFeedback>{t("Profile.degreeError")}</FormFeedback>
      </FormGroup>
      <ProfileEditYearsFormGroup
        setValue={setValue}
        getValues={getValues}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        watch={watch}
      />
      {/* TODO: Implement add more education functionality */}
      {/* <div className="d-inline-flex justify-content-between w-100">
        <div>&nbsp;</div>
        <Button className="btn-text" color="cool-blue" size="sm">
          <CustomIcon
            className="mr-1"
            icon="plus_sign"
            color="#3d76c8"
            size={14}
          />
          {t("Profile.educationAddMore")}
        </Button>
      </div> */}
    </ProfileEditModal>
  );
};

ProfileEditEducationModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired
};

ProfileEditEducationModal.defaultProps = {};

export default ProfileEditEducationModal;
