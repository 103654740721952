/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const listenCmeMeta = (userUid, callback) => {
  return getCollectionRef(`${DB_CONSTANTS.USER_CME}`)
    .doc(userUid)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const listenAvailableActivities = (userUid, callback) => {
  return getCollectionRef(
    `${DB_CONSTANTS.USER_CME}/${userUid}/available`
  ).onSnapshot(callback, (onObservableNextOrError) => {
    console.error("onObservableNextOrError: ", onObservableNextOrError);
  });
};

export const listenCompletedActivities = (userUid, callback) => {
  return getCollectionRef(
    `${DB_CONSTANTS.USER_CME}/${userUid}/completed`
  ).onSnapshot(callback, (onObservableNextOrError) => {
    console.error("onObservableNextOrError: ", onObservableNextOrError);
  });
};
