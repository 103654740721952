import React, { useEffect } from "react";

import { Card } from "reactstrap";

import "./GroupsNavigation.scss";
import GroupsNavItem from "./GroupsNavItem";
import { getUrl } from "../../utils/route-utils";
import { HOME_ROOT_FEED, HOME_ROOT_TOPIC } from "../../constants/routes";
import { FEED_NAMES } from "../../constants/feed-type";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsInfoIfNeeded } from "../../actions/reference.actions";
import i18n from "../../utils/i18n";

// temporary solution for avatars until they get moved into the backend somehow
import Figure1Avatar40 from "../../assets/images/Figure 1 Avatar_40px.jpeg";

const GroupsNavigation = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();

  const groups = useSelector((state) => {
    const allGroups = state.references.groups?.data
      ? Object.values(state.references.groups?.data)
      : [];
    const mapedGroupUuids =
      (state.user?.groups && state.user?.groups.map((g) => g.groupUuid)) || [];
    return allGroups.filter((group) =>
      mapedGroupUuids.includes(group.groupUuid)
    );
  });

  const groupsLoading = useSelector(
    (state) => state.references.groups?.loading
  );
  const mobileClasses = isMobile
    ? "mobile d-flex flex-row px-2 mb-0"
    : "px-0 shadow mb-3";

  /** ********************************** HOOKS ****************************************/
  // fetch groups if required

  useEffect(() => {
    dispatch(getGroupsInfoIfNeeded());
  }, [dispatch]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (groupsLoading) return null;

  return (
    <Card className={`groups-navigation py-2 border-0 ${mobileClasses}`}>
      <GroupsNavItem
        url={getUrl(HOME_ROOT_FEED, {
          feed: FEED_NAMES.RECOMMENDED
        })}
        name={i18n.t("common.figure1")}
        imgUrl={Figure1Avatar40}
        // This is a bit of a hack to make the group highlight every _other_ time
        isActiveFn={(match, location) => {
          return location?.pathname?.indexOf("/group") === -1;
        }}
      />
      {groups.map((group) => (
        <GroupsNavItem
          key={group.groupUuid}
          imgUrl={group.groupAvatar}
          url={getUrl(HOME_ROOT_TOPIC, {
            feed: FEED_NAMES.GROUP,
            feedTypeUuid: group.groupUuid
          })}
          name={group.groupName}
        />
      ))}
    </Card>
  );
};

export default GroupsNavigation;
