import React from "react";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
import PagingIcon from "../common/PulsatingDot";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import { decideIfPagingIsActive } from "../../utils/case-utils";

const CaseTitle = ({ caseContent, size = "16", maxLine = "2" }) => {
  const { theme } = useTheme();

  const isPagingActive =
    caseContent &&
    decideIfPagingIsActive(caseContent.publishedAt, caseContent.isPagingCase);

  // There's a calculation issue with LinesEllipsis, so I'm swapping it to
  // LinesEllipsisLoose which does a css solution. It seems to work fine, but
  // we could run into some browser issue in the future -- Corey
  return (
    <>
      {isPagingActive && (
        <div className="paging-container">
          <PagingIcon />
        </div>
      )}

      <LinesEllipsisLoose
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        className={classNames(
          `text-${size} line-height-tight mr-auto`,
          theme === THEMES.DARK ? "" : "text-default-black font-weight-bold"
        )}
        text={caseContent.title ? caseContent.title : caseContent.caption}
        maxLine={maxLine}
        ellipsis="..."
        lineHeight={"1.25em"}
      />
    </>
  );
};

export default CaseTitle;
