import React from "react";
import { Card } from "reactstrap";
import CaseBanner from "./CaseBanner";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import CaseDetailCardInner from "./CaseDetailCardInner";

const CaseDetailCard = ({ caseContent, commentStatus, videoPlayerConfig }) => {
  /** ********************************** CONFIG ***************************************/

  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {caseContent && (
        <div className="feed-card">
          {showBanner && <CaseBanner bannerType={bannerType} />}
          <Card>
            <CaseDetailCardInner
              caseContent={caseContent}
              commentStatus={commentStatus}
              videoPlayerConfig
              handleShowBanner={handleShowBanner}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default CaseDetailCard;
