import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import i18n from "../../utils/i18n";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import NavLinkList from "./NavLinkList";

import "./PreviewStructure.scss";

const PreviewStructure = (props) => {
  /** ********************************** CONFIG ***************************************/

  const followedFeeds = useSelector(
    (state) => state.userFeedMeta.followedFeeds
  );

  const [topicList, setTopicList] = useState([]);
  const topicIds = useSelector((state) =>
    state.topics?.data.map((t) => t.uuid)
  );

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (topicIds.length && !topicList.length && followedFeeds?.length > 0) {
      const tempList = [];
      followedFeeds.forEach((t) => {
        if (topicIds.indexOf(t.feedTypeUuid) === -1) {
          tempList.push({ name: t.feedName, uuid: t.feedTypeUuid });
        }
      });

      setTopicList(_.orderBy(tempList, "feedName"));
    }
  }, [topicIds, followedFeeds, topicList.length]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (_.isEmpty(topicList)) {
    return null;
  }

  return (
    <Card className="nav-structure p-2 border-0 mb-0 shadow">
      <h3 className="font-weight-bold text-12 text-uppercase py-2 mb-2">
        {i18n.t("navigation.previewFeeds")}
      </h3>
      <NavLinkList items={topicList} iconName="preview_feed_lock" />
    </Card>
  );
};

PreviewStructure.propTypes = {
  activeFeed: PropTypes.string
};

export default PreviewStructure;
