import React, { useEffect, useState } from "react";
import {
  SETTINGS_INTERESTS,
  SETTINGS_ACTIVITY_NOTIFICATIONS,
  SETTINGS_CONTENT_NOTIFICATIONS
} from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import { useLocation } from "react-router-dom";

import "./SettingsNavigation.scss";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItemLink from "../../../components/common/DropdownItemLink";
import i18n from "../../../utils/i18n";

const MobileNavigation = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [navTitle, setNavTitle] = useState(
    i18n.t("Settings.yourInterestsTitle") // TODO: swap to general when general exists
  );

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname) {
      switch (location.pathname) {
        case SETTINGS_INTERESTS:
          setNavTitle(i18n.t("Settings.yourInterestsTitle"));
          break;
        case SETTINGS_ACTIVITY_NOTIFICATIONS:
          setNavTitle(i18n.t("Settings.activityNotificationsTitle"));
          break;
        case SETTINGS_CONTENT_NOTIFICATIONS:
          setNavTitle(i18n.t("Settings.contentNotificationsTitle"));
          break;

        default:
          setNavTitle(i18n.t("Settings.generalSettingsTitle"));
          break;
      }
    }
  }, [setNavTitle, location, location.pathname]);

  return (
    <div className="settings-mobile-nav-container">
      <div style={{ width: "200px" }}>
        <Dropdown
          className="settings-mobile-nav"
          isOpen={isDropdownOpen}
          toggle={toggleDropdown}>
          <DropdownToggle
            className="cursor-pointer"
            tag="span"
            aria-expanded={isDropdownOpen}
            caret>
            <span>{navTitle}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu--pointer-top-right" right>
            {/* <DropdownItemLink
              to={getUrl(SETTINGS_GENERAL_SETTINGS)}
              onClick={toggleDropdown}>
              {i18n.t("Settings.generalSettingsTitle")}
            </DropdownItemLink> */}
            <DropdownItemLink
              to={getUrl(SETTINGS_INTERESTS)}
              onClick={toggleDropdown}>
              {i18n.t("Settings.yourInterestsTitle")}
            </DropdownItemLink>
            {/* <DropdownItemLink
              to={getUrl(SETTINGS_ACTIVITY_NOTIFICATIONS)}
              onClick={toggleDropdown}>
              {i18n.t("Settings.activityNotificationsTitle")}
            </DropdownItemLink>
            <DropdownItemLink
              to={getUrl(SETTINGS_CONTENT_NOTIFICATIONS)}
              onClick={toggleDropdown}>
              {i18n.t("Settings.contentNotificationsTitle")}
            </DropdownItemLink> */}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default MobileNavigation;
