/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const quizVoteCall = async (contentUuid, questionOptionUuid) => {
  try {
    const result = await functions.httpsCallable("backendCaseQuizVote")({
      contentUuid: contentUuid,
      questionOptionUuid: questionOptionUuid
    });
    return result;
  } catch (e) {
    console.log("ERROR: ", e.message);
    throw new Error("Error while voting for content ", contentUuid);
  }
};

export const quizResetCall = async (contentUuid) => {
  try {
    const result = await functions.httpsCallable("backendCaseQuizReset")({
      contentUuid: contentUuid
    });
    return result;
  } catch (e) {
    console.log("ERROR: ", e.message);
    throw new Error("Error while resetting content ", contentUuid);
  }
};
