/** @format */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import { trackQuestionAnswer } from "../../actions/metrics.actions";
import { quizVote, quizReset } from "../../actions/quiz.actions";
import { QUESTION_CONTENT_TYPES } from "../../constants/content-type";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import useListenInteractiveCaseState from "../../hooks/useListenInteractiveCaseState";
import classNames from "../../utils/class-names-utils";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import { useSelectUserVote } from "../../selectors/interactive-case-state";
import HeadingBanner from "./HeadingBanner";
import CaseBanner from "./CaseBanner";
import CaseDropdown from "./CaseDropdown";
import CaseMediaView from "./CaseMediaView";
import TagsContainer from "./TagsContainer";
import VoteOptionsList from "./VoteOptionsList";
import QuestionAnswerDetail from "./QuestionAnswerDetail";
import NextQuestionButton from "./NextQuestionButton";
import QuestionExternalLink from "./QuestionExternalLink";
import PreviousQuestionButton from "./PreviousQuestionButton";
import CaseReferences from "./CaseReferences";
import useIsGated from "../../hooks/useIsGated";
import { useInView } from "react-intersection-observer";

const QuestionDetailCard = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { ref } = useInView({ threshold: 0 });
  const dispatch = useDispatch();
  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();
  const userStore = useSelector((state) => state.user);
  const userAnswer = useSelectUserVote(
    props.caseContent.caseUuid,
    props.caseContent.contentUuid
  );

  const isLastItem = props.maxIndex === props.currentIndex;
  const contentHasAQuestion = QUESTION_CONTENT_TYPES.includes(
    props.caseContent.contentType
  );

  const questionHasBeenAnswered =
    (contentHasAQuestion && userAnswer) || !contentHasAQuestion;

  /** ********************************** HOOKS ****************************************/

  useListenInteractiveCaseState(props.caseContent.caseUuid);
  const isGated = useIsGated();

  /** ********************************* FUNCTIONS *************************************/

  const onVote = async (questionOptionUuid, option) => {
    trackQuestionAnswer({
      campaignUuid: props.caseContent?.campaignUuid,
      caseUuid: props.caseContent?.caseUuid,
      contentItem: props.caseContent,
      answer: option,
      isUngated: isGated
    });

    try {
      dispatch(
        quizVote(
          props.caseContent.contentUuid,
          questionOptionUuid,
          props.caseContent.caseUuid
        )
      );
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
  };

  const onResetVote = async () => {
    try {
      dispatch(
        quizReset(
          userStore.userUid,
          props.caseContent.contentUuid,
          props.caseContent.caseUuid
        )
      );
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
  };

  /** ********************************** RENDER ***************************************/
  const questionAnswerDetails = props.caseContent.questionAnswerDetails;

  return (
    <div ref={ref} className={classNames("feed-card", props.className)}>
      {showBanner && <CaseBanner bannerType={bannerType} />}
      <Card className="border-0 rounded-0">
        {props.caseContent.media?.length > 0 && (
          <CaseMediaView
            className="pb-0"
            caseUuid={props.caseContent.caseUuid}
            media={props.caseContent.media}
            showImages={true}
            context={CASE_CARD_ORIGIN.DETAIL}
            videoPlayerConfig={props.videoPlayerConfig}
          />
        )}

        {!!props.caseContent.heading && (
          <HeadingBanner
            text={props.caseContent.heading}
            isPoll={props.caseContent.isPoll}
          />
        )}

        <CardBody className="d-inline-flex justify-content-between align-items-center w-100">
          <TagsContainer
            caseContent={{
              ...props.caseContent,
              contentCount: props.nbQuestions
            }}
            currentIndex={props.currentIndex}
          />
          {!props.hideCaseOptions && (
            <CaseDropdown
              caseContent={props.caseContent}
              caseNotification={handleShowBanner}
              from={CASE_CARD_ORIGIN.DETAIL}
              commentStatus={props.commentStatus}
            />
          )}
        </CardBody>

        <CardBody className="pt-0">
          <CardTitle className="mb-2 text-darker text-16" tag="h6">
            {props.caseContent.title}
          </CardTitle>
          <CardText
            className="feed-card-info feed-card-info-markdown whitespace-pre-line feed-card-detail"
            tag="div">
            <ReactMarkdown>{props.caseContent.caption}</ReactMarkdown>
          </CardText>
        </CardBody>

        <CardBody className="pt-0">
          <VoteOptionsList
            options={props.caseContent.questionOptions}
            userAnswer={userAnswer || ""}
            onVote={onVote}
          />
        </CardBody>
        {questionAnswerDetails && !!userAnswer && (
          <CardBody className="pt-0">
            <QuestionAnswerDetail
              markdownText={questionAnswerDetails}
              onResetVote={onResetVote}
              isPoll={props.caseContent.isPoll}
            />
          </CardBody>
        )}

        {props.caseContent?.references && (
          <CardBody className="pt-0">
            <CaseReferences references={props.caseContent?.references} />
          </CardBody>
        )}

        {props.maxIndex >= 0 && !isLastItem && (
          <CardBody className="pt-0">
            <NextQuestionButton
              onClick={props.onNext}
              disabled={!questionHasBeenAnswered}
              isPoll={props.caseContent.isPoll}
            />
          </CardBody>
        )}

        {!!props.caseContent.externalLinkUrl && (
          <CardBody className="pt-0">
            <QuestionExternalLink
              caseUuid={props.caseContent.caseUuid}
              contentUuid={props.caseContent.contentUuid}
              campaignUuid={props.caseContent.campaignUuid}
              text={props.caseContent.externalLinkText}
              url={props.caseContent.externalLinkUrl}
            />
          </CardBody>
        )}

        {props.maxIndex > 0 && props.currentIndex > 0 && !isLastItem && (
          <CardBody className="pt-0">
            <PreviousQuestionButton className="ml-n3" onClick={props.onPrev} />
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default QuestionDetailCard;

QuestionDetailCard.propTypes = {
  currentIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  nbQuestions: PropTypes.number,
  caseContent: PropTypes.shape({
    externalLinkText: PropTypes.string,
    externalLinkUrl: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        depth: PropTypes.number,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        label: PropTypes.string,
        legacyAccount: PropTypes.bool,
        name: PropTypes.string,
        specialtyUuid: PropTypes.string,
        authorUid: PropTypes.string,
        username: PropTypes.string
      })
    ),
    author_uid: PropTypes.string,
    labels: PropTypes.array,
    allReactions: PropTypes.object,
    publishedAt: PropTypes.string,
    updatedAt: PropTypes.string,
    author_username: PropTypes.string,
    author_profession_label: PropTypes.string,
    userSaved: PropTypes.array,
    meshTerms: PropTypes.array,
    features: PropTypes.object,
    specialtyUuids: PropTypes.array,
    authorProfessionalLabel: PropTypes.string,
    authorProfessionalUuid: PropTypes.string,
    contentType: PropTypes.string,
    contentUuid: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    caseUuid: PropTypes.string,
    case_uuid: PropTypes.string,
    isSaved: PropTypes.bool,
    is_saved: PropTypes.bool,
    buttonText: PropTypes.string,
    questionAnswerDetails: PropTypes.string,
    questionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        displayOrder: PropTypes.number,
        isAnswer: PropTypes.bool,
        questionOptionUuid: PropTypes.string,
        text: PropTypes.string,
        votes: PropTypes.number
      })
    ).isRequired,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      sponsoredText: PropTypes.string
    }),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        caseUuid: PropTypes.string,
        contentUuid: PropTypes.string,
        displayOrder: PropTypes.number,
        filename: PropTypes.string,
        height: PropTypes.number,
        mediaId: null,
        mediaUuid: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string,
        width: PropTypes.number
      })
    ),
    shareLink: PropTypes.string,
    share_link: PropTypes.string
  }),
  className: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  hideCaseOptions: PropTypes.bool
};

QuestionDetailCard.defaultProps = {
  hideCaseOptions: false
};
