import React from "react";
import { useTranslation } from "react-i18next";
import icon from "../../../assets/images/moderator_review.svg";
import ModalWithText from "../../../components/common/ModalWithText";

const SubmittedCaseModal = ({ toggle, isOpen, isPaging }) => {
  /** ********************************** CONFIG ***************************************/
  const { t } = useTranslation();

  const title = isPaging
    ? "DraftsScreen.submittedDraftPageModalTitle"
    : "DraftsScreen.submittedDraftModalTitle";
  const body = isPaging
    ? "DraftsScreen.submittedDraftPageModalBody"
    : "DraftsScreen.submittedDraftModalBody";
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  const modalTitle = (
    <div>
      <img width="75" height="75" src={icon} alt="" />
      <p className="font-weight-500 text-20 mt-3 mb-0 mr-5">{t(title)}</p>
    </div>
  );

  return (
    <ModalWithText
      toggle={toggle}
      isOpen={isOpen}
      title={modalTitle}
      dividerClass="border-bottom border-width-4 approved-border w-25 pt-3 px-4"
      confirmText="common.done"
      showFooter={true}>
      <p className="text-16">{t(body)}</p>
    </ModalWithText>
  );
};

export default SubmittedCaseModal;
