/** @format */

import { assign } from "lodash";
import moment from "moment";
import DB_CONSTANTS from "./db-constants";
import {
  firestoreBatch,
  getCollectionRef,
  getDocumentIdFieldPath,
  runTransaction
} from "./firestore";

export const updateLastSeen = (user) => {
  return {
    ...user,
    lastSeen: moment().format()
  };
};

/**
 * Guarantees that a user document will be stored in Firebase
 * post authentication.  If the doc exists, it will update a few attributes
 * from the TMUA call.  Otherwise it will create a new document.
 * @param {Object} user
 */
export const initializeUser = async (user) => {
  const ref = getCollectionRef(DB_CONSTANTS.USERS).doc(user.guid);

  const result = await runTransaction(async (transaction) => {
    const doc = await transaction.get(ref);

    // TODO: This is where you can build defaults in on user creation
    // ex: Default user settings
    // ex: Default Notification Preferences
    // ex: Last seen time
    const userToSave = doc.data();
    transaction.update(ref, userToSave);
    return assign(user, userToSave);
  });

  return result;
};

/**
 * Used for updating 1 to many attributes on the user doc
 * @param {string} guid
 * @param {object} userAttributes
 */
export const save = async (guid, userAttributes) => {
  const ref = getCollectionRef(DB_CONSTANTS.USERS).doc(guid);

  const result = await runTransaction(async (transaction) => {
    const doc = await transaction.get(ref);

    // User doc did not exist with the guid.
    if (!doc.exists) {
      return;
    }

    transaction.update(ref, userAttributes);
  });

  return result;
};

/**
 * Used for fetching a user profile doc
 * @param {string} userId
 */
export const fetchUser = async (userId) => {
  const ref = getCollectionRef(DB_CONSTANTS.USERS).doc(userId);

  const user = await ref.get();

  return user.exists ? user.data() : null;
};

export const listenToUserChanges = (callback, userId) => {
  return getCollectionRef(DB_CONSTANTS.USERS)
    .doc(userId)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error(
        "listenToUserChanges.onObservableNextOrError: ",
        onObservableNextOrError
      );
    });
};

/**
 * Queries for user's interactive case state data.
 * Formats data to look like this:
 *
 *   {
 *     [caseUuid]: {
 *       [contentUuid]: { title, caption, ... },
 *       // content 2, content 3...,
 *     },
 *     // case 2, case 3, ...
 *   }
 */
export const queryInteractiveCaseStates = (userUid, caseUuids) => {
  return getCollectionRef(`${DB_CONSTANTS.USERS}/${userUid}/stateDB`)
    .where(getDocumentIdFieldPath(), "in", caseUuids)
    .get()
    .then((querySnapshot) => {
      const data = {};
      if (querySnapshot && !querySnapshot.empty) {
        const docs = querySnapshot.docs;

        for (let i = 0; i < docs.length; i++) {
          const doc = docs[i];
          const caseUuid = doc.ref.id;
          data[caseUuid] = { ...doc.data(), caseUuid };
        }
      }

      return data;
    });
};

export const listenToInteractiveCaseStateChange = (
  userUid,
  caseUuid,
  callback
) => {
  return getCollectionRef(`${DB_CONSTANTS.USERS}/${userUid}/stateDB`)
    .doc(caseUuid)
    .onSnapshot({ includeMetadataChanges: true }, (docSnapshot) => {
      let data = {};

      // If from cache, data is not up-to-date, so we skip it.
      // Do this to avoid flashing answered to unanswered state.
      if (!docSnapshot.metadata.fromCache && docSnapshot.exists) {
        data = { ...docSnapshot.data(), caseUuid };
      }

      callback(data);
    });
};

export const listenToPromoCards = (userUid, callback) => {
  return getCollectionRef(
    `${DB_CONSTANTS.USERS}/${userUid}/promoCards`
  ).onSnapshot(
    (colSnapShot) => {
      let data = [];

      if (!colSnapShot.empty) {
        colSnapShot.docs.forEach((doc) => {
          data.push(doc.data());
        });
      }

      callback(data);
    },
    (onObservableNextOrError) => {
      console.error(
        "listenToPromoCards.onObservableNextOrError: ",
        onObservableNextOrError
      );
    }
  );
};

export const removePromoCardByUuid = (userUid, cardUuid) => {
  return getCollectionRef(`${DB_CONSTANTS.USERS}/${userUid}/promoCards`)
    .doc(cardUuid)
    .delete();
};

export const removePromoCards = async (userUid, cardUuids) => {
  const query = getCollectionRef(
    `${DB_CONSTANTS.USERS}/${userUid}/promoCards`
  ).where("caseUuid", "in", cardUuids);
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    return;
  }

  // Delete documents in a batch
  const batch = firestoreBatch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();
};
