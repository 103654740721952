import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "../../../utils/i18n";
import {
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  FormGroup
} from "reactstrap";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import {
  submitPhotoVerification,
  updateLocalVerificationPictureInfoV2
} from "../../../actions/user-verification.actions";
import {
  generateImageThumbnailURI,
  getDataURL,
  getImageDimensions
} from "../../../utils/media-utils";
import LoadingButton from "../../../components/common/LoadingButton";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import F1CarouselControlButton from "../../../components/common/F1CarouselControlButton";
import FormGlobalError from "./FormGlobalError";

import "./VerificationPhotoUpload.scss";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { trackVerifyPhoto } from "../../../actions/metrics.actions";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import useIsAStudent from "../../../hooks/useIsAStudent";
import useIsUSAUser from "../../../hooks/useIsUSAUser";

const FILE_LIMIT = 4;

const VerificationPhotoUploadV2 = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const onboardingNavigate = useOnboardingNavigation();

  const { handleSubmit } = useForm({
    mode: "onChange"
  });

  const userVerificationStore = useSelector((state) => state.userVerification);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isStudent = useIsAStudent();
  const isUsa = useIsUSAUser();
  const showNpiLink = isUsa && !isStudent && !photos.length;
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (userVerificationStore.verificationPictureInfos) {
      const dataUrls = [];
      for (let i = 0; i < FILE_LIMIT; i++) {
        const f = userVerificationStore.verificationPictureInfos[i];

        if (f) {
          dataUrls.push(getDataURL(f));
        }
      }

      Promise.all(dataUrls).then((urls) => {
        return Promise.all(
          urls.map((url) => {
            return getImageDimensions(url);
          })
        )
          .then((imgDimensions) => {
            return Promise.all(
              imgDimensions.map((d) => {
                if (d.height > 500 || d.width || 500) {
                  return generateImageThumbnailURI(d.url, 500);
                }
                return Promise.resolve(d.url);
              })
            );
          })
          .then((newUrls) => {
            setPhotos(newUrls);
          });
      });
    }
  }, [userVerificationStore.verificationPictureInfos]);

  /** ********************************* FUNCTIONS *************************************/

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === photos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? photos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleSubmitClick = async () => {
    setErrorMessage("");
    setIsLoading(true);
    trackVerifyPhoto();

    try {
      if (!userVerificationStore?.verificationPictureInfos?.length) {
        throw new Error("At least one photo must be uploaded for verification");
      }
      const result = await dispatch(
        submitPhotoVerification(userVerificationStore?.verificationPictureInfos)
      );

      if (result.error) {
        throw new Error(result.message);
      } else {
        onboardingNavigate(result.user);
      }

      setIsLoading(false);
    } catch (e) {
      setErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const selectFile = (event) => {
    event.preventDefault();
    setErrorMessage("");
    hiddenFileInput.current.click();
  };

  const onUpload = async (event) => {
    event.preventDefault();
    const existingPhotos =
      userVerificationStore?.verificationPictureInfos || [];

    let filesToUpload = [
      ...(get(event, "target.files") || []),
      ...existingPhotos
    ];
    if (filesToUpload.length > FILE_LIMIT) {
      filesToUpload = filesToUpload.slice(0, FILE_LIMIT);
    }

    if (filesToUpload) {
      dispatch(updateLocalVerificationPictureInfoV2(filesToUpload));
    }
  };

  const onRemoveFile = (index) => {
    const newFilesToUpload = [
      ...userVerificationStore.verificationPictureInfos
    ];

    if (index === photos.length - 1) {
      setAnimating(false);
      previous();
    }

    newFilesToUpload.splice(index, 1);

    dispatch(updateLocalVerificationPictureInfoV2(newFilesToUpload));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <div className="mb-3">
        {!photos.length && (
          <div
            className="mt-2 d-flex flex-column justify-content-center align-items-center w-100 cursor-pointer"
            style={{
              height: isMobile ? 230 : 440,
              border: "2px dashed gray",
              borderRadius: "0.5rem"
            }}
            onClick={selectFile}>
            <CustomIcon
              icon={"verification_add_photos_circle_web"}
              color={"battleship_gray"}
              size={60}
            />
            <div>
              {i18n.t("RegistrationScreens.verificationPhotoPage.v2.addPhotos")}
            </div>
            <div>
              {i18n.t("RegistrationScreens.verificationPhotoPage.v2.upTo")}
            </div>
          </div>
        )}

        {!!photos.length && (
          <>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={false}
              slide={false} // looks pretty, but the animation breaks things on multiple delete.
              className="verification-photos">
              {photos.map((photo, index) => (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={index}>
                  <div className="position-relative" key={index}>
                    <Button
                      className="position-absolute btn-icon btn-circle t-2 r-2 z-2 bg-battleship-gray"
                      onClick={() => onRemoveFile(index)}>
                      <CustomIcon
                        icon={"post_case_media_trash"}
                        color={"white"}
                        size={20}
                      />
                    </Button>
                    <img src={photo} alt="verify" style={{ width: "100%" }} />
                  </div>
                </CarouselItem>
              ))}
              {photos.length > 1 && (
                <>
                  {activeIndex > 0 && (
                    <div className="f1-carousel-control f1-carousel-control--prev">
                      <F1CarouselControlButton
                        className="f1-carousel-control-btn"
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                        buttonColor="white"
                        iconColor="darkest"
                      />
                    </div>
                  )}
                  {activeIndex < photos.length - 1 && (
                    <div className="f1-carousel-control f1-carousel-control--next">
                      <F1CarouselControlButton
                        className="f1-carousel-control-btn"
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                        buttonColor="white"
                        iconColor="darkest"
                      />
                    </div>
                  )}
                </>
              )}
            </Carousel>
            <CarouselIndicators
              className="f1-carousel-indicators position-relative r-0 b-0 l-0 my-1 mx-auto"
              items={photos}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
          </>
        )}
      </div>
      <FormGlobalError errorText={errorMessage} />

      <div className="mb-3 text-center">
        {photos.length < FILE_LIMIT && photos.length > 0 && (
          <Button className="w-200px" color="link" onClick={selectFile}>
            {i18n.t("RegistrationScreens.verificationPhotoPage.v2.btnAddMore")}
          </Button>
        )}
        {photos.length >= FILE_LIMIT && (
          <p className="text-14 color-blue-gray">
            {i18n.t("RegistrationScreens.verificationPhotoPage.v2.maxPhotos")}
          </p>
        )}
      </div>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onUpload}
        accept="image/*"
        style={{ display: "none" }}
        multiple
      />

      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <FormGroup>
          <div className="mb-3 text-center">
            <LoadingButton
              className="w-100"
              color="primary"
              loading={isLoading}
              spinnerColor="white"
              size="lg"
              disabled={isLoading}>
              {i18n.t("RegistrationScreens.verificationPhotoPage.v2.btnSubmit")}
            </LoadingButton>
          </div>
        </FormGroup>
      </form>

      {showNpiLink && (
        <Link
          className="text-cool-blue cursor-pointer align-self-center mb-4"
          to={ROUTES.REGISTRATION_INFORMATION_V2}>
          {i18n.t("RegistrationScreens.verificationPhotoPage.v2.npiLink")}
        </Link>
      )}
    </>
  );
};

export default VerificationPhotoUploadV2;
