import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import standInImage from "../../assets/images/text case_rounded.png";
import { getCaseTypeUrl } from "../../utils/case-utils";

const CaseThumbnail = ({ caseContent, disableClick, onClickCallback }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const imgLink = caseContent.media[0]?.url
    ? `${caseContent?.media[0]?.url}?fit=crop&w=168&h=168`
    : standInImage;

  return (
    <Link
      to={getCaseTypeUrl(caseContent)}
      className={
        disableClick
          ? "similar-cases-link cursor-not-allowed"
          : "similar-cases-link"
      }
      onClick={(e) => {
        if (disableClick) {
          e.preventDefault(); // Prevent link from navigating
        }

        if (onClickCallback) {
          onClickCallback();
        }
      }}>
      <div className="square-image">
        <img className="rounded" src={imgLink} alt={caseContent.caption} />
      </div>
      <div className="text-13 line-height-tight">
        <LinesEllipsis
          text={caseContent.caption}
          maxLine="3"
          ellipsis="..."
          trimRight
          basedOn="words"
        />
      </div>
    </Link>
  );
};

export default CaseThumbnail;
