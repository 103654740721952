/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  VOTE_START,
  VOTE_RESET_START,
  VOTE_COMPLETE,
  VOTE_RESET_COMPLETE,
  QUIZ_ERROR,
  QUIZ_UPDATE_TEXT_INPUT
} from "../actions/quiz.actions";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  textInput: {}
};

const quizReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case VOTE_START:
    case VOTE_RESET_START:
      return {
        ...state,
        isLoading: true
      };
    case VOTE_COMPLETE:
    case VOTE_RESET_COMPLETE:
      return {
        ...state,
        isLoading: false
      };
    case QUIZ_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          ...action.payload.error
        }
      };
    case QUIZ_UPDATE_TEXT_INPUT:
      return {
        ...state,
        textInput: {
          ...state.textInput,
          [action.payload?.contentUuid]: action.payload?.textInput
        }
      };
    case RESET_ALL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default quizReducer;
