/** @format */

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { CONTENT_TYPE } from "../constants/content-type";

export const useInteractiveCaseState = (caseUuid) => {
  const _state = useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid];
  });

  return _state;
};

export const useSelectUserVote = (caseUuid, contentUuid) => {
  return useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid]?.[contentUuid]
      ?.userVote;
  });
};

export const useSelectUserTextInput = (caseUuid, contentUuid) => {
  return useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid]?.[contentUuid]
      ?.userFreeFormText;
  });
};

export const useLocalQuizScore = (caseUuid) => {
  return useSelector((state) => {
    const userSelections = state.user.interactiveCaseStates?.[caseUuid];
    const items = state.case.cases?.[caseUuid]?.data?.contentItems;
    let nbCorrectAnswer = 0;
    if (!userSelections || isEmpty(items)) {
      return nbCorrectAnswer;
    }
    for (const item of items) {
      if (
        item.contentType === CONTENT_TYPE.QUIZ ||
        item.contentType === CONTENT_TYPE.QUIZ_SERIES
      ) {
        const selection = userSelections?.[item.contentUuid];
        if (selection) {
          const option = item.questionOptions.find(
            (option) => option.questionOptionUuid === selection.userVote
          );

          if (option) {
            nbCorrectAnswer += option.isAnswer;
          }
        }
      }
    }
    return nbCorrectAnswer;
  });
};
