import React from "react";
import { Button, Card, CardBody, CardText } from "reactstrap";
import * as PropTypes from "prop-types";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";

const CaseEmptyView = ({ className, onOk }) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card
      className={classNames(
        "border-0 p-3",
        theme === THEMES.DARK
          ? "bg-dark-gray text-white"
          : "bg-white text-denim-blue",
        className
      )}>
      <CardBody className={"text-center rounded"}>
        <CustomIcon
          className={classNames(
            "mb-2",
            theme === THEMES.DARK ? "icon-white" : "icon-denim-blue"
          )}
          icon="network_error"
          size={40}
        />
        <CardText className="my-4">
          {i18n.t("caseDetail.noCaseAvailable")}
        </CardText>
        <Button
          className="mt-1"
          color={theme === THEMES.DARK ? "dark" : "primary"}
          onClick={onOk}>
          {i18n.t("common.goBack")}
        </Button>
      </CardBody>
    </Card>
  );
};

CaseEmptyView.propTypes = {
  className: PropTypes.string,
  onOk: PropTypes.func.isRequired
};

export default CaseEmptyView;
