import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { trackPromoCardServed } from "../../../actions/metrics.actions";
import { listenToPromoCards } from "../../../actions/user.actions";
import PromoCard from "./PromoCard";

const PromoCardFeed = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const validCards = useSelector((state) =>
    (state.user.promoCards?.validCards || []).slice(0, 2)
  );
  const userUid = useSelector((state) => state.user.userUid);
  const [servedCards, setServedCards] = useState([]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    dispatch(listenToPromoCards(userUid, true));
    return () => {
      dispatch(listenToPromoCards(userUid, false));
    };
  }, [dispatch, userUid]);

  useEffect(() => {
    if (validCards) {
      const unserveredCards = [...validCards].filter(
        (card) => servedCards.indexOf(card.caseUuid) < 0
      );

      if (unserveredCards.length) {
        unserveredCards.forEach((card) => {
          trackPromoCardServed(card);
        });
        setServedCards([
          ...servedCards,
          ...unserveredCards.map((card) => card.caseUuid)
        ]);
      }
    }
  }, [validCards, servedCards]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div>
      {validCards.map((item) => (
        <PromoCard key={item.caseUuid} card={item} />
      ))}
    </div>
  );
};

export default PromoCardFeed;
