import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentFeed from "./ContentFeed";
import { fetchCaseLabel } from "../../../actions/case-label.actions";

const FeedStructure = (props) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const caseLabelStore = useSelector((state) => state.caseLabels);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    // only run this once
    if (caseLabelStore.data?.length === 0 && !caseLabelStore.isProcessing) {
      dispatch(fetchCaseLabel());
    }
  }, [dispatch, caseLabelStore]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <ContentFeed
      data={props.data}
      onRefresh={props.onRefresh}
      loadMore={props.loadMore}
      isLoading={props.isLoading}
      navigation={props.navigation}
      isEOF={props.isEOF}
      eofCard={props.eofCard}
      feedTypeUuid={props.feedTypeUuid}
    />
  );
};

export default FeedStructure;
