import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";

const BaseDialog = ({ isOpen, onClosed, children, ...rest }) => {
  return (
    <Modal centered isOpen={isOpen} onClosed={onClosed} {...rest}>
      {children}
    </Modal>
  );
};

BaseDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func
};

export default BaseDialog;
