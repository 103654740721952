import React from "react";
import Comment from "./Comment";

const ReplyThread = ({
  replies,
  reportEnabled,
  caseUuid,
  contentUuid,
  position,
  caseContent,
  commentStatus
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="p-0">
      {replies &&
        replies.map((r) => {
          if (r.commentUuid) {
            return (
              <Comment
                key={r.commentUuid}
                comment={r}
                reply
                reportEnabled={reportEnabled}
                caseUuid={caseUuid}
                contentUuid={contentUuid}
                position={position}
                caseContent={caseContent}
                commentStatus={commentStatus}
              />
            );
          }
          return null;
        })}
    </div>
  );
};

export default ReplyThread;
