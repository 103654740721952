import { sendInviteColleagues } from "../api/send-invite-colleagues.cloud-function";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "invite-colleagues";

export const SEND_INVITES = `${actionsPrefix}/SEND_INVITES`;
export const SEND_INVITES_COMPLETED = `${actionsPrefix}/SEND_INVITES_COMPLETED`;
export const SEND_INVITES_ERROR = `${actionsPrefix}/SEND_INVITES_ERROR`;

export const sendInvites = ({ emails }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_INVITES });

      const result = await sendInviteColleagues({ emails });
      if (isRequestSuccess(result)) {
        dispatch({ type: SEND_INVITES_COMPLETED });
      } else {
        dispatch({
          type: SEND_INVITES_ERROR,
          error: "Invites not successfully sent. Please try again."
        });
      }
    } catch (e) {
      dispatch({
        type: SEND_INVITES_ERROR,
        error: "Invites not successfully sent. Please try again."
      });
    }
  };
};
