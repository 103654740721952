/** @format */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import { trackTacticExternalLink } from "../../actions/metrics.actions";

const QuestionExternalLink = ({
  className,
  text,
  url,
  caseUuid,
  campaignUuid,
  contentUuid
}) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const sendMetrics = () => {
    trackTacticExternalLink({
      url,
      caseUuid,
      campaignUuid,
      contentUuid
    });
  };
  /** ********************************** RENDER ***************************************/
  return (
    <Button
      className={classNames(
        "d-inline-flex justify-content-between align-items-center text-15 text-left",
        theme === THEMES.DARK
          ? "text-light-gray font-weight-500"
          : "text-denim-blue font-weight-bold",
        className
      )}
      size="lg"
      color={theme === THEMES.DARK ? "charcoal-gray" : "lighter-gray"}
      block
      tag="a"
      rel="noopener noreferrer"
      href={url}
      onClick={sendMetrics}
      target="_blank">
      {text || url}
      <CustomIcon
        className={classNames(
          "ml-2 flex-shrink-0",
          theme === THEMES.DARK ? "icon-light-gray" : "icon-denim-blue"
        )}
        icon="external_link_arrow"
        size={24}
      />
    </Button>
  );
};

QuestionExternalLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string.isRequired
};

export default QuestionExternalLink;
