import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  PROFILE_INFO_CARD_STATE,
  PROFILE_INFO_TYPE
} from "../../../constants/profile-info-constants";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import { trackEditAbout } from "../../../actions/metrics.actions";
import { getUrl } from "../../../utils/route-utils";
import * as ROUTES from "../../../constants/routes";
import ProfileInfoCard from "./ProfileInfoCard";
import { deleteUserInfoEntry } from "../../../actions/user-profiles.actions";
import useVerifiedUserGate from "../../../hooks/use-verified-user.hooks";
import i18n from "../../../utils/i18n";
import useIsCurrentUser from "../../../hooks/useIsCurrentUser";

const ProfileAboutView = ({ profileData, state }) => {
  /****************************** CONFIG **************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const displayOnly = state === PROFILE_INFO_CARD_STATE.DISPLAY;
  const userUuid = profileData?.userUuid;

  const emptyProfile =
    (!profileData?.experience || profileData?.experience.length === 0) &&
    (!profileData?.education || profileData?.education.length === 0) &&
    (!profileData?.affiliations || profileData?.affiliations.length === 0);

  const isCurrentUser = useIsCurrentUser(userUuid);

  /*************************** FUNCTIONS  *************************************/

  const onNew = (type) => {
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        history.replace(getUrl(ROUTES.PROFILE_EDIT_EXPERIENCE, { userUuid }));
        break;
      case PROFILE_INFO_TYPE.EDUCATION:
        history.replace(getUrl(ROUTES.PROFILE_EDIT_EDUCATION, { userUuid }));
        break;
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        history.replace(getUrl(ROUTES.PROFILE_EDIT_MEMBERSHIP, { userUuid }));
        break;
      case PROFILE_INFO_TYPE.SUBSPECIALTIES:
        history.replace(
          getUrl(ROUTES.PROFILE_EDIT_SUBSPECIALTIES, { userUuid })
        );
        break;
      case PROFILE_INFO_TYPE.PRACTICE_AREA:
        history.replace(
          getUrl(ROUTES.PROFILE_EDIT_PRACTICE_AREAS, { userUuid })
        );
        break;
      default:
        console.warn(`Unknown type provided. ${type}`);
        break;
    }
  };

  const onDelete = (type, data) => {
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        trackEditAbout(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_EXPERIENCE);
        return dispatch(
          deleteUserInfoEntry(PROFILE_INFO_TYPE.EXPERIENCE, data.experienceUuid)
        );
      case PROFILE_INFO_TYPE.EDUCATION:
        trackEditAbout(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_EDUCATION);
        return dispatch(
          deleteUserInfoEntry(PROFILE_INFO_TYPE.EDUCATION, data.educationUuid)
        );
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        trackEditAbout(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_AFFILIATION);
        return dispatch(
          deleteUserInfoEntry(
            PROFILE_INFO_TYPE.AFFILIATIONS,
            data.affiliationUuid
          )
        );
      default:
        console.warn(`Unknown type provided. ${type}`);
        break;
    }
  };
  const verifiedOnNew = useVerifiedUserGate(onNew);
  const verifiedOnDelete = useVerifiedUserGate(onDelete);
  /***************************  RENDERER  *************************************/

  if (!profileData) {
    return null;
  }
  if (emptyProfile && !isCurrentUser) {
    return (
      <div className="px-2 py-3">
        <p className="text-battleship-gray m-0 text-center">
          {i18n.t("Profile.emptyProfile")}
        </p>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      {(!isEmpty(profileData.experience) || !displayOnly) && (
        <ProfileInfoCard
          state={state}
          type={PROFILE_INFO_TYPE.EXPERIENCE}
          items={profileData.experience}
          onNew={() => {
            verifiedOnNew(PROFILE_INFO_TYPE.EXPERIENCE);
          }}
          onDelete={(data) =>
            verifiedOnDelete(PROFILE_INFO_TYPE.EXPERIENCE, data)
          }
        />
      )}
      {(!isEmpty(profileData.education) || !displayOnly) && (
        <ProfileInfoCard
          state={state}
          type={PROFILE_INFO_TYPE.EDUCATION}
          items={profileData.education}
          onNew={() => {
            verifiedOnNew(PROFILE_INFO_TYPE.EDUCATION);
          }}
          onDelete={(data) =>
            verifiedOnDelete(PROFILE_INFO_TYPE.EDUCATION, data)
          }
        />
      )}
      {(!isEmpty(profileData.affiliations) || !displayOnly) && (
        <ProfileInfoCard
          state={state}
          type={PROFILE_INFO_TYPE.AFFILIATIONS}
          items={profileData.affiliations}
          onNew={() => {
            verifiedOnNew(PROFILE_INFO_TYPE.AFFILIATIONS);
          }}
          onDelete={(data) =>
            verifiedOnDelete(PROFILE_INFO_TYPE.AFFILIATIONS, data)
          }
        />
      )}
    </div>
  );
};

ProfileInfoCard.propTypes = {
  state: PropTypes.string,
  profileData: PropTypes.object
};

export default ProfileAboutView;
