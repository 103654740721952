/** @format */

import React from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import AppPromptAuthenticated from"../../components/app-prompt/AppPromptAuthenticated";
import NotificationsDetailsPage from "./NotificationsDetailsPage";

const Notifications = () => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPromptAuthenticated />}>
      <NotificationsDetailsPage />
    </Figure1Layout>
  );
};

export default Notifications;
