import { useRef } from "react";
import useEventListener from "./useEventListener";

const useSwipe = ({
  enableTouch = true,
  swipeThreshold = 40,
  onSwipeLeft = () => {},
  onSwipeRight = () => {}
}) => {
  const elementRef = useRef(null);
  const touchStartXRef = useRef(0);
  const touchStartYRef = useRef(0);

  useEventListener(elementRef.current, "touchstart", (e) => {
    if (!enableTouch) {
      return;
    }

    touchStartXRef.current = e.changedTouches[0].screenX;
    touchStartYRef.current = e.changedTouches[0].screenY;
  });

  useEventListener(elementRef.current, "touchend", (e) => {
    if (!enableTouch) {
      return;
    }

    const currentX = e.changedTouches[0].screenX;
    const currentY = e.changedTouches[0].screenY;
    const diffX = Math.abs(touchStartXRef.current - currentX);
    const diffY = Math.abs(touchStartYRef.current - currentY);

    // Only handle horizontal swipes greater than the swipe threshold.
    if (diffX < diffY || diffX < swipeThreshold) {
      return;
    }

    if (currentX < touchStartXRef.current) {
      onSwipeLeft();
    } else {
      onSwipeRight();
    }
  });

  return {
    elementRef
  };
};

export default useSwipe;
