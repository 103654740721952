/** @format */

const CME_SECTION = {
  FRONT_MATTER: "front_matter",
  PRE_TEST: "pre_test",
  ACTIVITY: "activity",
  POST_TEST: "post_test",
  POST_TEST_SUMMARY: "post_test_summary",
  SURVEY: "survey",
  COMPLETION: "cme_completion"
};

export const CME_QUESTION_SECTIONS = [
  CME_SECTION.PRE_TEST,
  CME_SECTION.POST_TEST,
  CME_SECTION.SURVEY
];

export default CME_SECTION;
