/** @format */

import * as caseLabelDB from "../db/case-label.db";

const actionsPrefix = "caseLabels";

export const FETCH_CASE_LABELS_START = `${actionsPrefix}/FETCH_CASE_LABELS_START`;
export const FETCH_CASE_LABELS_COMPLETE = `${actionsPrefix}/FETCH_CASE_LABELS_COMPLETE`;

export const fetchCaseLabel = () => {
  return async (dispatch, getState) => {
    try {
      if (!getState().caseLabels.hasBeenFetched) {
        dispatch({
          type: FETCH_CASE_LABELS_START
        });

        let labels = await caseLabelDB.fetchAll();
        let labelArray = [];
        labelArray = Object.values(labels).map((l) => {
          l.name = l.name["en"];
          return l;
        });
        dispatch({
          type: FETCH_CASE_LABELS_COMPLETE,
          message: "case labels successfully fetched",
          error: null,
          payload: {
            labels: labelArray
          }
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASE_LABELS_COMPLETE,
        message: error.message,
        error: error,
        payload: {
          labels: null
        }
      });
    }
  };
};
