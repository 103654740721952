/** @format */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import { ISI_FOOTER_HEIGHT } from "../../constants/isi-constants";
import { trackEventWithName } from "../../actions/metrics.actions";
import {
  METRICS_CATEGORY,
  METRICS_EVENT_KEY
} from "../../metrics/constants.metrics";
import useWindowDimensions from "../../hooks/use-window-size.hooks";
import classNames from "../../utils/class-names-utils";

const ISIFooter = ({ url, contentItem, isiHeaderRef }) => {
  /** ********************************** CONFIG ***************************************/
  const isiFooterRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const windowDimensions = useWindowDimensions();
  const height = isExpanded
    ? windowDimensions.height -
      isiHeaderRef.current.getBoundingClientRect().bottom -
      (windowDimensions.height -
        isiFooterRef.current.getBoundingClientRect().bottom)
    : ISI_FOOTER_HEIGHT;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const onExpandCollapse = () => {
    if (!isExpanded) {
      trackEventWithName(METRICS_CATEGORY.VIEW_ISI, "TapExpandFooter", {
        [METRICS_EVENT_KEY.CASE_UUID]: contentItem.caseUuid,
        [METRICS_EVENT_KEY.CONTENT_UUID]: contentItem.contentUuid,
        [METRICS_EVENT_KEY.CAMPAIGN_ID]: contentItem.campaignUuid
      });
    }

    setIsExpanded(!isExpanded);
  };

  /** ********************************** RENDER ***************************************/
  return (
    <div
      className="w-100 bg-white"
      style={{
        boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
        transition: "all 0.35s ease 0s",
        height
      }}
      ref={isiFooterRef}>
      <Container className="h-100">
        <Row className="h-100">
          <Col>
            <iframe
              className="w-100 h-100 border-0"
              src={url}
              title="ISI Content"
              sandbox="allow-popups allow-popups-to-escape-sandbox"
            />
            <Button
              className="d-flex align-self-end rounded-circle r-0 mt-2 mr-3 p-1 position-absolute t-0"
              color="battleship-gray"
              size="sm"
              onClick={onExpandCollapse}>
              <CustomIcon
                icon="drawer_chevron"
                className={classNames(
                  "icon-white",
                  isExpanded ? "rotate-90" : "rotate-270"
                )}
                style={{ transition: "transform 0.25s" }}
                size={15}
              />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ISIFooter.propTypes = {
  url: PropTypes.string.isRequired,
  contentItem: PropTypes.object,
  isiHeaderRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default ISIFooter;
