import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPreviewFeedMetaIfNeeded } from "../../actions/reference.actions";
import { FEED_META_LABELS, FEED_NAMES } from "../../constants/feed-type";
import { HOME_ROOT_FEED, HOME_ROOT_TOPIC } from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import CustomIcon from "../custom-icon/CustomIcon";

const TopicViewAllLink = ({
  feedUuid,
  linkStyles = "text-13",
  iconSize = 12
}) => {
  /****************************** Config **************************************/
  const metaData = useSelector(
    (state) => state.references.feedsMeta?.data?.[feedUuid]
  );
  const [target, setTarget] = useState(null);
  /****************************** Hooks ***************************************/
  useEffect(() => {
    getPreviewFeedMetaIfNeeded();
  }, []);

  useEffect(() => {
    if (metaData && feedUuid) {
      const label = metaData.label;
      let url;
      if (label === FEED_META_LABELS.EVERYTHING) {
        url = getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.EVERYTHING });
      } else if (label === FEED_META_LABELS.TRENDING) {
        url = getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.TRENDING });
      } else if (label === FEED_META_LABELS.RECOMMENDED) {
        url = getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED });
      } else {
        url = getUrl(HOME_ROOT_TOPIC, {
          feed: FEED_NAMES.TOPIC,
          feedTypeUuid: feedUuid
        });
      }
      setTarget(url);
    }
  }, [feedUuid, metaData]);
  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  if (!feedUuid || !target) return null;
  return (
    <Link to={target} className={`d-flex align-items-center ${linkStyles}`}>
      {i18n.t("ExploreCategoryCTA.viewAll")}{" "}
      <CustomIcon color="#3f6296" icon="drawer_draft_chevron" size={iconSize} />
    </Link>
  );
};

export default TopicViewAllLink;
