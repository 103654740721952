/** @format */
import {
  postVerificationRequest,
  uploadPictureForVerification,
  postNotificationPreferencesForVerification,
  postNpiInfo,
  checkNpiInfo
} from "../api/user.cloud-functions";
import UploadImageType from "../constants/uploadImageType.constants";
import {
  getVerificationUploadLocation,
  uploadMedia
} from "./image-upload.actions";
import { isRequestSuccess } from "../utils/general-utils";
import { sanitizeFileName } from "../utils/media-utils";

const actionsPrefix = "userVerification";

export const UPDATE_VERIFICATION_PICTURE_INFO = `${actionsPrefix}/UPDATE_VERIFICATION_PICTURE_INFO`;
export const POST_VERIFICATION_REQUEST_START = `${actionsPrefix}/POST_VERIFICATION_REQUEST_START`;
export const POST_VERIFICATION_REQUEST_COMPLETE = `${actionsPrefix}/POST_VERIFICATION_REQUEST_COMPLETE`;
export const POST_VERIFICATION_PICTURE_START = `${actionsPrefix}/POST_VERIFICATION_PICTURE_START`;
export const POST_VERIFICATION_PICTURE_COMPLETE = `${actionsPrefix}/POST_VERIFICATION_PICTURE_COMPLETE`;
export const POST_NOTIFICATION_REQUEST_START = `${actionsPrefix}/POST_NOTIFICATION_REQUEST_START`;
export const POST_NOTIFICATION_REQUEST_COMPLETE = `${actionsPrefix}/POST_NOTIFICATION_REQUEST_COMPLETE`;
export const CLEAR_FETCH_NPI_INFO = `${actionsPrefix}/CLEAR_FETCH_NPI_INFO`;
export const FETCH_INFO_WITH_NPI = `${actionsPrefix}/FETCH_INFO_WITH_NPI`;
export const FETCH_INFO_WITH_NPI_COMPLETE = `${actionsPrefix}/FETCH_INFO_WITH_NPI_COMPLETE`;
export const FETCH_INFO_WITH_NPI_COMPLETE_NOTFOUND = `${actionsPrefix}/FETCH_INFO_WITH_NPI_COMPLETE_NOTFOUND`;
export const RESET_STATE = `${actionsPrefix}/RESET_STATE`;

// - method: ['npi', 'license', 'picture']. The type of verification which is being submitted.
// Required for npi method:
// - npi_number: The NPI number of the user.
// - graduation_location: The graduation location for the user.
// - graduation_year: The graduation year for the user.
// Required license method:
// - license_school_code: The school code for the user.
// - license_number: The medical license number for the user.
// - license_country_code: The country code for the user's medical license.
// - license_state_code: The state code for the user's medical license.
// picture would not really be a part of this function, see uploadVerificationPicture

export const postVerification = (verificationData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_VERIFICATION_REQUEST_START
      });

      const postData = {
        method: verificationData.method,
        npi_number: verificationData.npiNumber,
        graduation_location: verificationData.school,
        graduation_year: verificationData.graduationYear,
        license_school_code: verificationData.school,
        license_number: verificationData.license,
        license_country_code: verificationData.country,
        license_state_code: verificationData.subdivision,
        institutional_email: verificationData.institutionalEmail,
        photos: verificationData.photos
      };

      // remove the undefined props to prevent FB issue
      for (const property in postData) {
        if (postData[property] === undefined) {
          delete postData[property];
        }
      }

      const result = await postVerificationRequest(postData);
      return dispatch({
        type: POST_VERIFICATION_REQUEST_COMPLETE,
        error: null,
        message: "Successfully pushed the verification request",
        user: result.store,
        status: result.status
      });
    } catch (error) {
      return dispatch({
        type: POST_VERIFICATION_REQUEST_COMPLETE,
        error: error,
        message: error.message
      });
    }
  };
};

export const uploadVerificationPicture = (file, countryUuid) => {
  return async (dispatch, getState) => {
    try {
      // Something seems to have changed with authstore and now have to go user._user to get uid.
      // Keeping old option in case it's still needed
      const userUid =
        getState()?.user?.userUid ||
        getState()?.authStore?.user?._user?.uid ||
        getState()?.authStore?.user?.uid;

      if (!file.name) {
        throw new Error("no file attached");
      }
      dispatch({
        type: POST_VERIFICATION_PICTURE_START
      });

      let fileExtension = "jpg";
      if (file.type) {
        fileExtension = file.type.split("/")[1];
      }
      const fileName = `${userUid}-verification-request-picture.${fileExtension}`;

      const data = new FormData();
      data.append("picture", file, fileName);
      data.append("country_uuid", countryUuid);
      await uploadPictureForVerification(data);

      return dispatch({
        type: POST_VERIFICATION_PICTURE_COMPLETE,
        error: null,
        message: "Successfully uploaded the verification picture"
      });
    } catch (error) {
      return dispatch({
        type: POST_VERIFICATION_PICTURE_COMPLETE,
        error: error,
        message: error.message
      });
    }
  };
};

export const removeLocalVerificationPictureInfo = () => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_VERIFICATION_PICTURE_INFO,
      payload: { verificationPictureInfo: null }
    });
  };
};
export const updateLocalVerificationPictureInfo = (fileInfo) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_VERIFICATION_PICTURE_INFO,
      payload: { verificationPictureInfo: fileInfo }
    });
  };
};

export const updateLocalVerificationPictureInfoV2 = (files) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_VERIFICATION_PICTURE_INFO,
      payload: { verificationPictureInfos: files }
    });
  };
};

export const resetState = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_STATE
    });
  };
};

export const postNotificationPreferences = (preferences) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_NOTIFICATION_REQUEST_START
      });
      await postNotificationPreferencesForVerification(preferences);

      dispatch({
        type: POST_NOTIFICATION_REQUEST_COMPLETE,
        error: null,
        message: "Successfully posted the notification preferences request"
      });
    } catch (error) {
      dispatch({
        type: POST_NOTIFICATION_REQUEST_COMPLETE,
        error: error,
        message: error.message
      });
    }
  };
};

export const fetchInfoWithNpi = (npiNumber) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_INFO_WITH_NPI
      });
      const npiResponse = await checkNpiInfo(npiNumber);

      if (isRequestSuccess(npiResponse) && !npiResponse?.data?.results?.[0]) {
        throw new Error("Please enter a valid NPI #");
      }

      const processedResponse = await postNpiInfo(npiResponse?.data);

      if (isRequestSuccess(processedResponse)) {
        if (!processedResponse?.store?.results?.[0]) {
          return dispatch({
            type: FETCH_INFO_WITH_NPI_COMPLETE,
            payload: {
              npiNumber
            },
            incomplete: true
          });
        } else {
          return dispatch({
            type: FETCH_INFO_WITH_NPI_COMPLETE,
            payload: {
              npiNumber,
              data: processedResponse?.store?.results?.[0]
            }
          });
        }
      } else if (isRequestSuccess(npiResponse)) {
        // fall back to the npi response if something happened with the processing
        return dispatch({
          type: FETCH_INFO_WITH_NPI_COMPLETE,
          payload: {
            npiNumber,
            data: {
              // reformat it to align with the successful api response
              firstName:
                npiResponse?.data?.results?.[0]?.basic?.first_name?.toLowerCase(),
              lastName:
                npiResponse?.data?.results?.[0]?.basic?.last_name?.toLowerCase(),
              npiNumber: npiResponse?.data?.results?.[0]?.number,
              specialty: null
            },
            incomplete: true
          }
        });
      } else {
        return dispatch({
          type: FETCH_INFO_WITH_NPI_COMPLETE,
          payload: {
            npiNumber,
            incomplete: true
          },
          message:
            "RegistrationScreens.verificationNpiPage.v2.NpiConnectionError"
        });
      }
    } catch (e) {
      return dispatch({
        type: FETCH_INFO_WITH_NPI_COMPLETE,
        error: true,
        message: e.message,
        npiNumber
      });
    }
  };
};

export const clearFetchedNpi = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_FETCH_NPI_INFO
    });
  };
};
export const submitPhotoVerification = (images) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_VERIFICATION_PICTURE_START
      });

      const urlResult = await dispatch(getVerificationUploadLocation());
      const awsResponse = urlResult.payload?.awsResponse;
      if (!awsResponse) {
        throw new Error("Failed to prepare for upload");
      }
      const awsFields = awsResponse.media_upload_url;
      const uploadResult = await dispatch(
        uploadMedia({
          awsFields,
          mediaList: images,
          uploadType: UploadImageType.VERIFICATION
        })
      );

      if (uploadResult?.error) {
        throw new Error("Failed to upload one or more of the images");
      }

      const downloadDomain = awsResponse.media_download_domain;
      const downloadPath = awsFields?.fields?.key;
      const photoPaths = images.map(
        (image) =>
          `${downloadDomain}/${downloadPath.replace(
            "${filename}",
            sanitizeFileName(image.name)
          )}`
      );

      const result = await dispatch(
        postVerification({ method: "photo", photos: photoPaths })
      );

      if (result.error || !isRequestSuccess(result)) {
        throw new Error("Error uploading verification images");
      } else {
        return dispatch({
          type: POST_VERIFICATION_PICTURE_COMPLETE,
          message: "Successfully uploaded the verification images",
          user: result.user
        });
      }
    } catch (e) {
      return dispatch({
        type: POST_VERIFICATION_PICTURE_COMPLETE,
        error: true,
        message: e.message
      });
    }
  };
};
