import React from "react";
import { Nav, NavItem } from "reactstrap";
import {
  SETTINGS_INTERESTS
  // SETTINGS_GENERAL_SETTINGS,
  // SETTINGS_ACTIVITY_NOTIFICATIONS,
  // SETTINGS_CONTENT_NOTIFICATIONS
} from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import { NavLink } from "react-router-dom";

import "./SettingsNavigation.scss";
import useBreakpoint, { BREAKPOINT_SIZES } from "../../../hooks/useBreakpoint";
import i18n from "../../../utils/i18n";
import MobileNavigation from "./MobileNavigation";

const SettingsNavigation = () => {
  const size = useBreakpoint();
  if (
    size === BREAKPOINT_SIZES.EXTRA_SMALL ||
    size === BREAKPOINT_SIZES.SMALL
  ) {
    return <MobileNavigation />;
  }

  return (
    <Nav className="settings-navigation flex-column">
      {/* <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_GENERAL_SETTINGS)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.generalSettingsTitle")}</div>
        </NavLink>
      </NavItem> */}
      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_INTERESTS)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.yourInterestsTitle")}</div>
        </NavLink>
      </NavItem>
      {/* <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_ACTIVITY_NOTIFICATIONS)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.activityNotificationsTitle")}</div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_CONTENT_NOTIFICATIONS)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.contentNotificationsTitle")}</div>
        </NavLink>
      </NavItem> */}
    </Nav>
  );
};

export default SettingsNavigation;
