/** @format */
import Firebase from "../firebase";
import { isRequestSuccess } from "../utils/general-utils";

const functions = Firebase.functions;

export const refreshMediaUploadUrlCall = async (data) => {
  return await functions.httpsCallable("backendRefreshUploadUrl")(data);
};

export const uploadMediaToAWS = async (url, formData) => {
  try {
    let response = await fetch(url, {
      method: "POST",
      body: formData
    });

    if (isRequestSuccess(response)) {
      return true;
    } else {
      throw new Error(`failed to upload media with ${response.status}`);
    }
  } catch (error) {
    throw new Error(
      `error while uploading media file to draft: ${error.message}`
    );
  }
};
