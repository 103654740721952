/** @format */

import React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import PropTypes from "prop-types";

const ProfileEmptyCard = ({ title, children }) => {
  return (
    <Card className="border-0">
      <CardBody className="bg-white text-battleship-gray text-center text-14 rounded">
        <CardText className="whitespace-pre-line">{title}</CardText>
        {children}
      </CardBody>
    </Card>
  );
};

ProfileEmptyCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

ProfileEmptyCard.defaultProps = {
  children: null
};

export default ProfileEmptyCard;
