import React from "react";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { Link, NavLink } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import i18n from "../../../utils/i18n";
import { useSelector } from "react-redux";
import { useContactSupport } from "../../../hooks/useContactSupport";
import { isMobile } from "react-device-detect";

const RegistrationHeaderV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const userExists = useSelector(
    (state) => state.user?.initialized && state.user?.userUid
  );

  const onContactSupport = useContactSupport();

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  const spacer = <span style={{ width: userExists ? 153 : 75 }}></span>;

  const signoutButton = userExists ? (
    <NavLink
      to={ROUTES.SIGN_OUT}
      className="text-white d-flex align-items-center pr-3 cursor-pointer text-16">
      {i18n.t("navigation.log-out")}
    </NavLink>
  ) : isMobile ? (
    spacer
  ) : null;

  const logo = (
    <Link to={ROUTES.REGISTRATION}>
      <span className="navbar-brand navbar-brand-white">Figure 1 PRO</span>
    </Link>
  );
  const contactButton = (
    <span
      className="d-flex align-items-center pl-3 pr-0 pr-sm-3 cursor-pointer text-16"
      onClick={onContactSupport}>
      <CustomIcon
        className="text-default-black cursor-pointer mr-1"
        icon="contact_support"
        color="white"
        size={19}
      />
      {i18n.t("RegistrationScreens.banner.helpText")}
    </span>
  );

  if (isMobile) {
    return (
      <div className="d-flex justify-content-between bg-navy-blue py-3 text-white">
        {contactButton}
        {logo}
        {signoutButton}
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-between bg-navy-blue py-3 text-white">
      {spacer}
      {logo}
      <div className="d-flex">
        {contactButton}
        {signoutButton}
      </div>
    </div>
  );
};

export default RegistrationHeaderV2;
