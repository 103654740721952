import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "./LoadingButton";

const HeaderButton = (props) => {
  return (
    <div className="d-flex justify-content-end text-denim-blue">
      <LoadingButton
        size="md"
        spinnerSize="sm"
        spinnerColor="primary"
        disabled={props.loading}
        loading={props.loading}
        onClick={props.onClick}
        className="cursor-pointer">
        {props.buttonText}
      </LoadingButton>
    </div>
  );
};

HeaderButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default HeaderButton;
