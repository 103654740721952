import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { find, get, isEmpty, negate } from "lodash";
import { Navbar, Nav, NavItem, ButtonToggle, Button } from "reactstrap";
import {
  HOME_ROOT,
  CME_ROOT,
  DRAFTS_ROOT,
  SAVED_CASES_ROOT,
  NOTIFICATIONS_ROOT,
  CASE_POSTING_TYPE,
  PROFILE_NETWORK_TYPE
} from "../../constants/routes";
import { activateListener } from "../../actions/saved-cases.actions";
import { shouldShowDraftToUser } from "../../actions/user-draft.actions";
import F1Badge from "../common/F1Badge";
import NotificationsIcon from "../common/NotificationsIcon";
import ProfileDropdown from "./ProfileDropdown";
import MoreDropdown from "./MoreDropdown";
import FeedSearch from "./FeedSearch";
import { SAVED_CASE_TYPES } from "../../constants/saved-cases-constants";
import i18n from "../../utils/i18n";
import { getUserCountry } from "../../utils/cme-utils";
import { trackShareCase } from "../../actions/metrics.actions";
import CustomIcon from "../custom-icon/CustomIcon";
import { getUrl } from "../../utils/route-utils";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import { FEED_META_LABELS } from "../../constants/feed-type";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import GroupsNavigation from "../channel-navigation/GroupsNavigation";
import { isMobile } from "react-device-detect";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import useIsGroupFeed from "../../hooks/useIsGroupFeed";

const GlobalHeader = () => {
  const searchRef = useRef(null);
  const hasGroups = useUserHasGroups();
  const routeParams = useParams();

  const userUuid = useSelector((state) => state.user.userUuid);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isGroupFeed = useIsGroupFeed(routeParams.feedTypeUuid);
  const currentFeedUuid = useCurrentFeedTypeUuid();

  const [showSearchSubHeader, setShowSearchSubHeader] = useState(false);
  const currentUserId = useSelector((state) => state.user.userUid);
  const isUS = useSelector((state) => getUserCountry(state.user) === "US");
  const cmeLinkText = isUS
    ? i18n.t("navigation.cme")
    : i18n.t("navigation.cme-non-us");
  const savedCasesStore = useSelector((state) => state.savedCases);
  const numSavedCases = useSelector((state) =>
    get(state, "savedCases.data.all", []).length.toString()
  );
  const numDrafts = useSelector((state) =>
    Object.values(get(state, "userDraft.data", {}))
      .filter(shouldShowDraftToUser)
      .length.toString()
  );
  const hasNotifications = useSelector(
    (state) => get(state, "activity.activityMeta.newActivityCount", 0) > 0
  );

  const feed = useSelector((state) => {
    const currentFeed = get(
      state,
      `references.feedsMeta.data.${currentFeedUuid}`
    );
    const everythingFeed = Object.values(
      state?.references?.feedsMeta?.data || {}
    )?.find((m) => m.label === FEED_META_LABELS.EVERYTHING);

    return find([currentFeed, everythingFeed], negate(isEmpty));
  });

  useEffect(() => {
    dispatch(activateListener(currentUserId, SAVED_CASE_TYPES.ALL));
  }, [dispatch, currentUserId, savedCasesStore.data]);

  const hiddenMobile = "d-smed-none d-inline-flex";
  const visibleMobile = "d-none d-smed-inline-flex";
  return (
    <header className="bg-white p-0">
      <Navbar
        className="primary-nav justify-content-between header--shadowed"
        color="white"
        light
        expand>
        <div className="d-inline-flex justify-content-end align-items-center side-segment search-segment">
          <NavLink to={HOME_ROOT} className="navbar-brand">
            Figure 1 PRO
          </NavLink>
          <FeedSearch
            ref={searchRef}
            className={hiddenMobile}
            currentFeed={feed}
          />
        </div>

        <div
          className={`${hiddenMobile} justify-content-center align-items-center flex-grow-1`}>
          <Nav
            className={`align-items-center ${hiddenMobile} center-nav`}
            navbar>
            <NavItem>
              <NavLink
                to={{
                  pathname: HOME_ROOT,
                  state: {
                    context: "Navigation"
                  }
                }}
                activeClassName="active"
                className="nav-link">
                <CustomIcon
                  className="inactive-icon"
                  icon={"webnav_home_unselected"}
                  size={18}
                />
                <CustomIcon
                  className="active-icon"
                  icon={"webnav_home_selected"}
                  size={18}
                />
                <div>{t("navigation.home")}</div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={{
                  pathname: getUrl(PROFILE_NETWORK_TYPE, {
                    userUuid,
                    viewType: "following"
                  }),
                  state: {
                    context: "Navigation"
                  }
                }}
                activeClassName="active"
                className="nav-link">
                <CustomIcon
                  className="inactive-icon"
                  icon={"my_network_unselected"}
                  size={24}
                  style={{ marginBottom: "2px" }}
                />
                <CustomIcon
                  className="active-icon"
                  icon={"my_network_selected"}
                  size={24}
                  style={{ marginBottom: "2px" }}
                />
                <div>{t("Network.MyNetwork")}</div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={{
                  pathname: CME_ROOT,
                  state: {
                    context: "Navigation"
                  }
                }}
                activeClassName="active"
                className="nav-link">
                <CustomIcon
                  className="inactive-icon offset"
                  icon={"webnav_education_unselected"}
                  size={20}
                />
                <CustomIcon
                  className="active-icon offset"
                  icon={"webnav_education_selected"}
                  size={20}
                />
                <div>{cmeLinkText}</div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={DRAFTS_ROOT}
                activeClassName="active"
                className="nav-link">
                <CustomIcon
                  className="inactive-icon"
                  icon={"webnav_drafts_unselected"}
                  size={18}
                />
                <CustomIcon
                  className="active-icon"
                  icon={"webnav_drafts_selected"}
                  size={18}
                />
                <div>
                  {t("navigation.drats")}{" "}
                  <F1Badge
                    textColor="battleship-gray"
                    color="lighter-gray"
                    name={numDrafts}
                  />
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={SAVED_CASES_ROOT}
                activeClassName="active"
                className="nav-link">
                <CustomIcon
                  className="inactive-icon"
                  icon={"webnav_save_unselected"}
                  size={18}
                />
                <CustomIcon
                  className="active-icon"
                  icon={"webnav_save_selected"}
                  size={18}
                />
                <div>
                  {t("navigation.saved-cases")}{" "}
                  <F1Badge
                    textColor="battleship-gray"
                    color="lighter-gray"
                    name={numSavedCases}
                  />
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <div className="d-inline-flex justify-content-end align-items-center side-segment">
          <Nav className={`align-items-center ${hiddenMobile}`} navbar>
            <NavItem>
              <NavLink
                className="nav-link"
                onClick={() => {
                  trackShareCase({
                    context: UPLOAD_CASE_ORIGIN.PLUS_SIGN,
                    groupUuid: isGroupFeed ? currentFeedUuid : null
                  });
                }}
                to={CASE_POSTING_TYPE}>
                <CustomIcon icon={"upload_shortcut"} size={24} />
              </NavLink>
            </NavItem>
            <span className="nav-divider" />
          </Nav>

          <Nav className={`align-items-center ${hiddenMobile}`} navbar>
            <NavItem>
              <NavLink
                to={NOTIFICATIONS_ROOT}
                activeClassName="active"
                className="nav-link">
                <div className="notifications">
                  <NotificationsIcon active={hasNotifications} />
                </div>
              </NavLink>
            </NavItem>
          </Nav>

          <Nav className="align-items-center" navbar>
            <NavItem className={`mr-3 ${visibleMobile}`}>
              <ButtonToggle
                className="toggle-search-sub-header"
                onClick={() => {
                  setShowSearchSubHeader(!showSearchSubHeader);

                  if (searchRef?.current) {
                    searchRef.current.clearSearchTerms();
                  }
                }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <CustomIcon icon={"feed_search"} size={20} />
                  <p className="helv-med text-12">{t("navigation.search")}</p>
                </div>
              </ButtonToggle>
            </NavItem>
            <span className={`nav-divider mr-3 ${visibleMobile}`} />
            <NavItem className={visibleMobile}>
              <MoreDropdown />
            </NavItem>
            <span className="nav-divider" />
            <NavItem>
              <ProfileDropdown />
            </NavItem>
          </Nav>
        </div>
      </Navbar>
      {showSearchSubHeader && (
        <div className={`d-flex p-1 ${visibleMobile} w-100 align-items-center`}>
          <FeedSearch
            ref={searchRef}
            className={visibleMobile}
            currentFeed={feed}
            autoFocus
          />
          <Button
            className={`helv-med text-14 p-1 ${visibleMobile} ml-1`}
            close
            onClick={() => {
              setShowSearchSubHeader(false);

              if (searchRef?.current) {
                searchRef.current.clearSearchTerms();
              }
            }}>
            {t("navigation.cancelSearchSubheader")}
          </Button>
        </div>
      )}
      {isMobile && hasGroups && <GroupsNavigation />}
    </header>
  );
};

GlobalHeader.propTypes = {};

export default GlobalHeader;
