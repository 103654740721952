import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { fetchProfessionsIfNeeded } from "../../../actions/reference.actions";
import ModalWithText from "../../../components/common/ModalWithText";
import { termsAndConditions } from "../../../assets/files/termsAndConditions";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import LoadingButton from "../../../components/common/LoadingButton";
import FormElementCountry from "./FormElementCountry";
import FormGlobalError from "./FormGlobalError";
import { updateUserProfile } from "../../../actions/user-profiles.actions";

const RegistrationCountryFormV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  let history = useHistory();

  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onChange"
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    dispatch(fetchProfessionsIfNeeded());
  }, [dispatch]);
  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (formData.country === undefined) {
      return;
    }
    if (!formState.isValid) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const payload = {
      country_uuid: formData.country?.value
    };

    const result = await dispatch(updateUserProfile(payload));

    if (result.error) {
      setErrorMessage(result.message);
      setIsLoading(false);
    } else {
      history.push(getUrl(ROUTES.REGISTRATION_INFORMATION_V2));
    }
  };
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0">
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <h1 className="text-22 text-center helv-bold mb-2">
            {i18n.t("RegistrationScreens.countryPage.title")}
          </h1>
          <p className="text-16 color-battleship-gray text-center mx-4 mb-4">
            {i18n.t("RegistrationScreens.countryPage.description")}
          </p>
          <FormGlobalError errorText={errorMessage} />
          <FormElementCountry
            control={control}
            errors={errors}
            showFeedback={true}
            helpText={i18n.t(
              "RegistrationScreens.createRegistration.v2.countryHelpText"
            )}
          />

          <LoadingButton
            className="w-100 mt-4"
            color="primary"
            loading={isLoading}
            spinnerColor="white"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.countryPage.btnNext")}
          </LoadingButton>
        </form>
      </Card>

      <ModalWithText
        title={i18n.t("RegistrationScreens.termsAndConditions")}
        toggle={() => setIsOpen((isOpen) => !isOpen)}
        isOpen={isOpen}>
        <p className="text-16">{termsAndConditions.text}</p>
      </ModalWithText>
    </>
  );
};

export default RegistrationCountryFormV2;
