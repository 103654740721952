import {
  HIDE_GLOBAL_MESSAGE,
  SHOW_GLOBAL_MESSAGE,
  SET_GATED,
  SET_GATED_MESSAGE_TYPE,
  SET_GATED_MESSAGE_ORIGIN,
  RESET_GATED_STATE,
  APP_PROMPT_DECLINED,
  APP_PROMPT_HEIGHT_CHANGED,
  SET_GATED_VIEW_COUNT,
  SET_EMAIL_MISMATCH_DETAILS,
  CLEAR_EMAIL_MISMATCH_DETAILS
} from "../actions/global.actions";
import { get } from "lodash";
import { isIOS, isAndroid } from "react-device-detect";
import TOAST_TYPE from "../components/popup/ToastType";
import { TOGGLE_LOGIN_MODAL } from "../../../admin-moderation-tagging/src/actions/global.actions";

const INITIAL_STATE = {
  message: null,
  title: null,
  error: null,
  messageType: TOAST_TYPE.INFO,
  persist: false,
  isGated: false,
  gatedMessageType: null,
  gatedMessageOrigin: null,
  ungatedViewCount: 0,
  appPrompt: {
    isShown: isIOS || isAndroid,
    isDeclined: false,
    height: 0
  },
  loginModalVisible: false
};

const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_MESSAGE: {
      let errorMessage;
      try {
        errorMessage =
          action.error &&
          action.error.message &&
          get(JSON.parse(action.error.message), "store.error");
      } catch (e) {}
      return {
        ...state,
        message: errorMessage ? errorMessage : action.message,
        title: action.title,
        messageType: action.messageType || TOAST_TYPE.INFO,
        error: action.error,
        persist: action.persist || false
      };
    }
    case HIDE_GLOBAL_MESSAGE: {
      return {
        ...state,
        message: null,
        title: null,
        messageType: null,
        error: null,
        persist: null
      };
    }
    case SET_GATED: {
      return {
        ...state,
        isGated: action.isGated,
        isSponsored: action.isSponsored
      };
    }
    case SET_GATED_MESSAGE_TYPE: {
      return {
        ...state,
        gatedMessageType: action.messageType
      };
    }
    case SET_GATED_MESSAGE_ORIGIN: {
      return {
        ...state,
        gatedMessageOrigin: action.origin
      };
    }
    case RESET_GATED_STATE: {
      return {
        ...state,
        isGated: false,
        gatedMessageType: null,
        gatedMessageOrigin: null
      };
    }
    case APP_PROMPT_DECLINED: {
      return {
        ...state,
        appPrompt: { ...state.appPrompt, isDeclined: true }
      };
    }
    case APP_PROMPT_HEIGHT_CHANGED: {
      return {
        ...state,
        appPrompt: { ...state.appPrompt, height: action.height }
      };
    }
    case SET_GATED_VIEW_COUNT: {
      return {
        ...state,
        ungatedViewCount: state.ungatedViewCount + 1
      };
    }
    case SET_EMAIL_MISMATCH_DETAILS: {
      return {
        ...state,
        emailMismatchModal: action.emailMismatchModal
      };
    }
    case CLEAR_EMAIL_MISMATCH_DETAILS: {
      return {
        ...state,
        emailMismatchModal: action.emailMismatchModal
      };
    }
    case TOGGLE_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: action.visible
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
