/** @format */

import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Carousel, CarouselIndicators, CarouselItem, Col } from "reactstrap";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import { CONTENT_TYPE } from "../../../constants/content-type";
import CME_SECTION from "../../../constants/cme-section";
import ISIContainer from "../../../components/isi/ISIContainer";
import F1CarouselControlButton from "../../../components/common/F1CarouselControlButton";
import { CaseDataProp } from "../../../prop-types/ContentProp";
import CMConclusionSlide from "./CMConclusionSlide";
import CMNormalSlide from "./CMNormalSlide";
import CMQuestionSlide from "./CMQuestionSlide";
import CMEModalSection from "./CMEModalSection";
import useCarouselNavigation from "../../../hooks/useCarouselNavigation";
import CASE_TYPE from "../../../constants/case-type";
import { updateCaseProgress } from "../../../actions/case.actions";
import i18n from "../../../utils/i18n";
import CMEModalButton from "./CMEModalFooterButton";
import useTrackSlideView from "../../../hooks/useTrackSlideView";

const CMEModalSectionActivity = ({
  caseContent,
  navigateToSection,
  onExit,
  initialIndex
}) => {
  /****************************** Config **************************************/
  const dispatch = useDispatch();
  const modalSectionRef = useRef(null);
  const { caseUuid } = caseContent;
  const data = caseContent?.[CME_SECTION.ACTIVITY] || [];
  const items = data.filter((d) => d.contentType !== CONTENT_TYPE.COVER);
  const firstOrder = items?.[0]?.displayOrder;

  const { activeIndex, next, previous, goToIndex, onExiting, onExited } =
    useCarouselNavigation({ items, currentIndex: initialIndex });

  /****************************** Hooks ***************************************/

  useEffect(() => {
    // track CME progress. For activity section, we only need to know if user
    // is currently in this section, exact slide progress isn't important.
    if (caseUuid && firstOrder !== null && firstOrder !== undefined) {
      dispatch(updateCaseProgress(caseUuid, firstOrder, null));
    }
  }, [dispatch, caseUuid, firstOrder]);

  useEffect(() => {
    modalSectionRef.current.scrollTop = 0;
  }, [activeIndex]);

  const { onNext, onPrev, sendMetrics } = useTrackSlideView({
    items,
    activeIndex,
    next,
    previous
  });

  /****************************** Functions ***********************************/

  const finalAction = () => {
    sendMetrics();
    if (caseContent.caseType !== CASE_TYPE.CME) {
      onExit(false);
    } else {
      navigateToSection(CME_SECTION.POST_TEST);
    }
  };

  /****************************** Render **************************************/

  const slides = items.map((item, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
        {item.contentType === CONTENT_TYPE.QUIZ ? (
          <CMQuestionSlide content={item} count={1} index={1} />
        ) : item.contentType === CONTENT_TYPE.CONCLUSION ? (
          <CMConclusionSlide content={item} finalAction={finalAction} />
        ) : (
          <CMNormalSlide content={item} />
        )}
      </CarouselItem>
    );
  });

  return (
    <ISIContainer isi={items[activeIndex]}>
      <CMEModalSection
        ref={modalSectionRef}
        content={items[activeIndex]}
        onExit={onExit}
        footer={
          <>
            <Col>
              <F1CarouselControlButton
                className="btn-cme-activity float-left"
                direction="prev"
                directionText="Previous"
                onClickHandler={onPrev}
                buttonColor="lighter-gray"
                iconColor="darkest"
                disabled={activeIndex === 0}
              />
            </Col>
            <Col xs="auto">
              {items[activeIndex].contentType === CONTENT_TYPE.CONCLUSION ? (
                <CMEModalButton
                  className="float-right"
                  color="denim-blue"
                  onClick={finalAction}>
                  {capitalize(
                    items[activeIndex].buttonText ||
                      i18n.t("CMEScreens.discuss")
                  )}
                </CMEModalButton>
              ) : (
                <F1CarouselControlButton
                  className="float-right"
                  direction="next"
                  directionText="Next"
                  onClickHandler={onNext}
                  buttonColor="lighter-gray"
                  iconColor="darkest"
                  disabled={activeIndex === items.length - 1}
                />
              )}
            </Col>
          </>
        }>
        <Carousel
          className="pt-0 w-100"
          activeIndex={activeIndex}
          next={onNext}
          previous={onPrev}
          interval={false}>
          <CarouselIndicators
            className="sticky-top t-0 w-100 mx-0 mb-1 pt-4 bg-darkest opacity-90"
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
        </Carousel>
      </CMEModalSection>
    </ISIContainer>
  );
};

CMEModalSectionActivity.propTypes = {
  caseContent: CaseDataProp.isRequired,
  onExit: PropTypes.func.isRequired
};

export default CMEModalSectionActivity;
