/** @format */

/**
 * All Extractable constants that could be used to make changes to the running system
 * can go here with the goal of pulling them to FireStore / Realtime DB
 */

const DEFAULT_COUNTRY_CODE_ACCEPT_LIST = ["US", "CA"];
const DEFAULT_YEARS_BACK = 100;

export { DEFAULT_COUNTRY_CODE_ACCEPT_LIST, DEFAULT_YEARS_BACK };
