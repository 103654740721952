import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import FilterModal from "./FilterModal";
import "./NavFeedFilter.scss";
import CustomIcon from "../custom-icon/CustomIcon";
import { FEED_FILTERS } from "../../constants/feed-filters";
import { Button, Card } from "reactstrap";
import feedActions from "../../actions/feeds.actions";

const getActiveFilterKeys = (filters) =>
  Object.keys(filters).filter((filterKey) => filters[filterKey]);

const AddMore = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-nowrap add-more align-items-center text-13 helv-med">
      <CustomIcon
        className="m-0 mr-1"
        icon={"plus"}
        size={13}
        color="#3f6296"
      />
      {t("FeedFilter.addFilters")}
    </div>
  );
};

const FilterTagContainer = ({ filters = {} }) => {
  if (isEmpty(filters)) {
    return null;
  }

  const activeFiltersKeys = getActiveFilterKeys(filters);

  return activeFiltersKeys.map((filterKey) => (
    <p key={filterKey} className="filter-tag mb-1">
      {FEED_FILTERS[filterKey]?.name || filterKey}
    </p>
  ));
};

const NavFeedFilter = ({ currentFeed }) => {
  /****************************** Config **************************************/
  const filters = useSelector((state) => {
    return state?.feeds?.filters?.[currentFeed] || {};
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/
  const resetFilters = () => {
    dispatch(feedActions.updateFeedFilter(currentFeed, {}));
  };
  /****************************** Render **************************************/
  const hasFilters = !isEmpty(getActiveFilterKeys(filters));

  return (
    <Card className="feed-sidebar-filter-container nav-structure border-0 shadow p-2">
      <h3 className="font-weight-bold text-12 text-uppercase py-2 mb-2 d-flex justify-content-between align-items-center">
        {t("FeedFilter.filters")}{" "}
        <FilterModal
          feedTypeUuid={currentFeed}
          popperModifiers={{
            offset: { enabled: true, offset: "-170px, 0px" }
          }}
          activeDropdownMenuClassName="d-flex flex-column pb-0 px-2 pt-2"
          toggleButtonContents={<AddMore />}
        />
      </h3>
      <div className={`filter-tags-container ${hasFilters ? "" : "empty"}`}>
        {hasFilters ? (
          <FilterTagContainer filters={filters} />
        ) : (
          <>
            <p className="m-0 my-2 no-filters-text text-12">
              {t("FeedFilter.noFiltersApplied")}
            </p>
          </>
        )}
      </div>
      {hasFilters && (
        <>
          <hr className="my-1 mb-2" />
          <div className="pb-2 filter-actions">
            <FilterModal
              feedTypeUuid={currentFeed}
              activeDropdownMenuClassName="d-flex flex-column pb-0 px-2 pt-2"
              toggleButtonContents={<p>{t("FeedFilter.editFilters")}</p>}
            />
            <Button className="clear-filters-button p-0" onClick={resetFilters}>
              {t("FeedFilter.clearAll")}
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default NavFeedFilter;
