/** @format */
import {
  FETCH_SEARCH_SUGGESTIONS,
  FETCH_SEARCH_SUGGESTIONS_COMPLETE,
  FETCH_SEARCH_SUGGESTIONS_ERROR,
  CLEAR_SEARCH_SUGGESTIONS
} from "../actions/elastic-search.actions";
import { isEmpty } from "lodash";

export const initialState = {
  searchSuggestions: null,
  error: null,
  isLoading: false
};

// Checkout Kibana's dev tools to play around and view results!
const formatSearchResults = (searchResults) => {
  const suggestions = searchResults?.suggest?.suggestion?.[0]?.options;

  if (!isEmpty(suggestions)) {
    return suggestions.map((suggestion) => ({
      text: suggestion.text
    }));
  }

  return [];
};

const elasticSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_SUGGESTIONS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SEARCH_SUGGESTIONS_COMPLETE:
      return {
        ...state,
        isLoading: false,
        searchSuggestions: formatSearchResults(action.payload.data)
      };
    case FETCH_SEARCH_SUGGESTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case CLEAR_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: null
      };
    default:
      return state;
  }
};

export default elasticSearchReducer;
