/** @format */

import React from "react";
import PropTypes from "prop-types";
import classNames from "../../../utils/class-names-utils";

const ExternalTextLink = ({ className, url, linkText, trackClick }) => {
  /** ********************************* FUNCTIONS *************************************/

  const handleLinkClick = async () => {
    // call back to handle any metric tracking
    trackClick && trackClick();
  };

  /** ********************************** RENDER ***************************************/

  return (
    <a
      className={classNames("text-denim-blue", className)}
      size="lg"
      color="lighter-gray"
      tag="a"
      rel="noopener noreferrer"
      onClick={handleLinkClick}
      href={url}
      target="_blank">
      {linkText || url}
    </a>
  );
};

ExternalTextLink.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string
};

ExternalTextLink.defaultProps = {
  linkText: ""
};

export default ExternalTextLink;
