/** @format */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ProminentHeader from "./ProminentHeader";
import CMEAvailableActivityCell from "./CMEAvailableActivityCell";
import moment from "moment";
import { getActivityStatus } from "../../../utils/cme-utils";
import "../cme.scss";

const CMEAvailableActivitiesGallery = ({
  activities,
  icon,
  title,
  subtitle,
  emptyText = ""
}) => {
  // First retrieve the status for all activities
  const availableActivities = useMemo(() => {
    return activities.map((activity) => ({
      ...activity,
      ...getActivityStatus(activity)
    }));
  }, [activities]);

  // Compute an array of all activities to be displayed
  // and in the specified order
  const sortedActivities = useMemo(() => {
    // Weed out expired activities
    const all = availableActivities
      .filter((activity) => !activity.expired)
      .sort((a1, a2) => {
        if (a1.inProgress && !a2.inProgress) return -1;
        if (a2.inProgress && !a1.inProgress) return 1;

        if (a1.isEligible && !a2.isEligible) return -1;
        if (a2.isEligible && !a1.isEligible) return 1;

        if (a1.credits && !a2.credits) return -1;
        if (a2.credits && !a1.credits) return 1;

        return moment(a2.startAt) - moment(a1.startAt);
      });
    return all;
  }, [availableActivities]);

  const outer = {
    width: "98%",
    maxWidth: "577px"
  };

  return (
    <div style={outer}>
      <ProminentHeader icon={icon} title={title} subtitle={subtitle} />
      {sortedActivities.length === 0 ? (
        <div className="emptyActivities">{emptyText}</div>
      ) : (
        <div className="d-flex flex-row flex-wrap justify-content-between">
          {sortedActivities.map((activity, index) => {
            return (
              <CMEAvailableActivityCell
                key={index.toString()}
                activity={activity}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

CMEAvailableActivitiesGallery.propTypes = {
  activities: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  emptyText: PropTypes.string.isRequired
};

export default CMEAvailableActivitiesGallery;
