/** @format */

import ONBOARDING_STATE from "../constants/onboarding-state";
import LEGACY_ONBOARDING_STATE from "../constants/legacy-onboarding-state";
import { VERIFICATION_STATUS } from "../constants/verification-status";

import {
  isVerificationInProgress,
  isLegacyUser,
  hasPrimarySpecialty,
  isNonHCP
} from "./onboarding-utils";
import { OTHER_PROFESSION_CATEGORY_LABELS } from "../constants/profession-category-labels";
import { PROFILE_USER_TYPES } from "../constants/profile-info-constants";

export const onboardingState = (user) => {
  if (user) {
    if (user.resetOnboarding === true) {
      // Short Circuit to help test onboarding
      return ONBOARDING_STATE.VERIFICATION_COMPLETE;
    }

    const _isVerificationInProgress = isVerificationInProgress(user);

    if (isLegacyUser(user) && !user.onboardingCompleted) {
      if (isNonHCP(user)) {
        return LEGACY_ONBOARDING_STATE.NON_HCP;
      } else if (!user.onboardingInterestsCompleted) {
        // This has to be checked first since it's first step of legacy onboarding
        return LEGACY_ONBOARDING_STATE.MIGRATE_ACCOUNT;
      } else if (!_isVerificationInProgress) {
        return LEGACY_ONBOARDING_STATE.VERIFICATION;
      } else {
        // Note: Verification status can be carried over from legacy
        return ONBOARDING_STATE.ONBOARDING_COMPLETE;
      }
    }
    // legacy users should have a username at least, so legacy check has to be done before this
    const _hasPrimarySpecialty = hasPrimarySpecialty(user);

    if (!_isVerificationInProgress) {
      return ONBOARDING_STATE.USER_CREATED;
    } else if (!user.username || !_hasPrimarySpecialty) {
      return ONBOARDING_STATE.VERIFICATION_COMPLETE;
    }

    return ONBOARDING_STATE.ONBOARDING_COMPLETE;
  }
  return ONBOARDING_STATE.UNDETERMINED;
};

/**
 * Return true if submitted or is verified user.
 * @param user
 * @returns {boolean}
 */
export const userHasSubmittedVerification = (user) => {
  return (
    user?.verification?.verificationStatus ===
      VERIFICATION_STATUS.PENDING_MANUAL || userIsVerified(user)
  );
};

export const userIsVerified = (user) => {
  return (
    user?.verification?.verificationStatus === VERIFICATION_STATUS.VERIFIED
  );
};

export const isOtherProfessionCategory = (professionCategoryLabel) =>
  OTHER_PROFESSION_CATEGORY_LABELS.includes(professionCategoryLabel);

export const isUserProfessionCategoryOther = (user) => {
  const tree = user?.primarySpecialty?.tree;

  return isOtherProfessionCategory(tree?.profession?.professionCategoryLabel);
};

/**
 * Return the correct display name to user for institutional users.
 *
 * For institutional profiles, the `profileDisplayName` holds "Institution or Focus" value.
 * this might change.
 *
 * Note: caseCommentDisplayName - usually specialty for most users,
 * can be set in modified tool for.
 *
 * Note 2: New institutional profiles created via admin tool (so not modifyign an existing
 * user) do not have `caseCommentDisplayName` set, thus use `profileDisplayName`.
 *
 * @param {Object} userOrAuthor - either user or author object. They have similar structure.
 * @returns {string} display name
 */
export const getInstitutionalCommentDisplayName = (userOrAuthor) =>
  userOrAuthor?.caseCommentDisplayName || userOrAuthor?.profileDisplayName;

export const isInstitutionalUserType = (userType) =>
  userType === PROFILE_USER_TYPES.INSTITUTIONALUSER;

/**
 * Return string used for user display name in:
 * 1) Case card user cell, text under username
 * 2) Comment text after user's username
 *
 * This is in prioritized order. Having `sponsoredText` included
 * is a little weird but I wanted to show this order fully!
 *
 * @param {Object} userOrAuthor
 * @param {string} sponsoredText
 * @returns {string}
 */
export const getUserDisplayName = (userOrAuthor, sponsoredText) => {
  const caseCommentDisplayName = userOrAuthor?.caseCommentDisplayName;

  if (userOrAuthor?.isDeleted) {
    return "";
  } else if (sponsoredText) {
    return sponsoredText;
  } else if (
    userOrAuthor?.isPartner ||
    isInstitutionalUserType(userOrAuthor?.userType)
  ) {
    return getInstitutionalCommentDisplayName(userOrAuthor);
  } else {
    // Fallback: show nothing if nothing is defined
    return caseCommentDisplayName || "";
  }
};
