import React from "react";
import Dialog from "../../../components/dialog/Dialog";
import { StaticStrings } from "../../../constants/static-string-constants";
import i18n from "../../../utils/i18n";

import "./DeletedPostItem.scss";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { ACTIVITY_TYPES } from "../../../constants/activity-constants";

import "./DeletedPostItem.scss";

const DeletedPostDialog = ({ isOpen, onCancel, date, reason, type }) => {
  const title =
    type === ACTIVITY_TYPES.CASE_DELETE
      ? "ActivityScreen.deletedCaseModalTitle"
      : "ActivityScreen.deletedCommentModalTitle";
  const titleDetail =
    type === ACTIVITY_TYPES.CASE_DELETE
      ? "ActivityScreen.deletedCaseModalTitleDetail"
      : "ActivityScreen.deletedCommentModalTitleDetail";
  const deletedText =
    type === ACTIVITY_TYPES.CASE_DELETE
      ? "ActivityScreen.deletedCaseModalDeleted"
      : "ActivityScreen.deletedCommentModalDeleted";

  return (
    <Dialog
      centered
      isOpen={isOpen}
      onCancel={onCancel}
      body={
        <>
          <div className="deletedPostDialog" style={{ width: "100%" }}>
            <div className="d-flex align-items-center px-0 flex-grow-1">
              <CustomIcon icon="case-delete-icon" size={60} />
              <div className="d-flex flex-column ml-2">
                <h2 className="m-0 text-14 font-weight-bold text-battleship-gray">
                  {i18n.t(title)}
                </h2>
                <h2 className="text-16 font-weight-bold mb-0">
                  {i18n.t(titleDetail).replace("[date]", date)}
                </h2>
              </div>
            </div>
            <hr style={{ borderWidth: 4 }} className="border-light-gray-blue" />
            <div className="text-left">
              <p className="text-18 text-danger helv-bold mb-1">
                {i18n.t(deletedText)}
              </p>
              <p className="text-battleship-gray">{reason}</p>
              <div className="strap text-battleship-gray">
                {i18n.t("ActivityScreen.deletedCaseModalFooter1")}{" "}
                <a href={StaticStrings.communityGuidelines}>
                  {i18n.t(
                    "RegistrationScreens.privacyPage.communityGuidelines"
                  )}
                </a>{" "}
                {i18n.t("ActivityScreen.deletedCaseModalFooter2")}{" "}
                <a href={StaticStrings.contentPolicy}>
                  {i18n.t("ActivityScreen.deletedCaseModalFooter3")}
                </a>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default DeletedPostDialog;
