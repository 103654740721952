/** @format */

import * as userActivityRecordDB from "../db/activity-record.db";
import { markAllReadCall } from "../api/cloud-functions";

const actionsPrefix = "activityCenter";

export const FETCH_USER_ACTIVITY_RECORDS = `${actionsPrefix}/FETCH_USER_ACTIVITY_RECORDS`;
export const FETCH_USER_ACTIVITY_RECORDS_COMPLETE = `${actionsPrefix}/FETCH_USER_ACTIVITY_RECORDS_COMPLETE`;
export const FETCH_USER_ACTIVITY_RECORDS_ERROR = `${actionsPrefix}/FETCH_USER_ACTIVITY_RECORDS_ERROR`;
export const SUBSCRIBE_USER_ACTIVITY_METADATA = `${actionsPrefix}/SUBSCRIBE_USER_ACTIVITY_METADATA`;
export const FETCH_USER_ACTIVITY_METADATA_COMPLETE = `${actionsPrefix}/FETCH_USER_ACTIVITY_METADATA_COMPLETE`;
export const FETCH_USER_ACTIVITY_METADATA_ERROR = `${actionsPrefix}/FETCH_USER_ACTIVITY_METADATA_ERROR`;
export const SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_ON = `${actionsPrefix}/SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_ON`;
export const SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_OFF = `${actionsPrefix}/SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_OFF`;
export const SET_ALL_ACTIVITY_READ = `${actionsPrefix}/SET_ALL_ACTIVITY_READ`;
export const SET_ALL_ACTIVITY_READ_COMPLETE = `${actionsPrefix}/SET_ALL_ACTIVITY_READ_COMPLETE`;
export const SET_ALL_ACTIVITY_READ_ERROR = `${actionsPrefix}/SET_ALL_ACTIVITY_READ_ERROR`;

/**
 * Initialize a listener for user activity meta data and store its unsubscribe function
 * in the redux store. If subscription exists already, do nothing.
 * @param {bool} subscriptionExists
 */
export const activateActivityMetaDataListener = () => {
  return async (dispatch, getState) => {
    const subscriptionExists = !!getState()?.activity?.metaDataListener;
    if (subscriptionExists) {
      return;
    }

    dispatch({
      type: SUBSCRIBE_USER_ACTIVITY_METADATA
    });

    try {
      if (!getState()?.activity?.metaDataListener) {
        const listener = await userActivityRecordDB.listenForUserActivityRecordChanges(
          (metadata) => {
            dispatch({
              type: FETCH_USER_ACTIVITY_METADATA_COMPLETE,
              payload: {
                metadata
              }
            });
          }
        );

        dispatch({
          type: SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_ON,
          payload: {
            listener
          }
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_USER_ACTIVITY_METADATA_ERROR,
        payload: {
          message: error.message
        }
      });
    }
  };
};

// TODO: In future, show error toast if something went wrong!
export const setAllActivityRead = () => {
  return async (dispatch, getState) => {
    const userUid = getState()?.user?.userUid;

    dispatch({
      type: SET_ALL_ACTIVITY_READ
    });

    try {
      const result = await markAllReadCall(userUid);

      if (result.error) {
        dispatch({
          type: SET_ALL_ACTIVITY_READ_ERROR,
          message: result.error
        });
      } else {
        dispatch({
          type: SET_ALL_ACTIVITY_READ_COMPLETE
        });
      }
    } catch (error) {
      const message = error?.message || error;

      dispatch({
        type: SET_ALL_ACTIVITY_READ_ERROR,
        message
      });
    }
  };
};

/**
 *
 * @param {bool} shouldClearOldActivity used to delete old state only when refreshing the list
 */
export const fetchActivityRecord = ({
  limit,
  startAfterTimestamp = null,
  shouldClearOldActivity = false,
  callback = null
}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_USER_ACTIVITY_RECORDS
    });
    try {
      userActivityRecordDB.getActivityBatch(
        limit,
        startAfterTimestamp || new Date().toString(),
        (allActivity) => {
          dispatch({
            type: FETCH_USER_ACTIVITY_RECORDS_COMPLETE,
            payload: {
              allActivity,
              shouldClearOldActivity,
              isEndReached: allActivity.length < limit
            }
          });
        }
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: FETCH_USER_ACTIVITY_RECORDS_ERROR,
        error: true,
        message: error.message
      });
    }
  };
};

export const deactivateActivityMetaDataListener = () => {
  return (dispatch, getState) => {
    const unsubscribeMetaData = getState()?.activity.metaDataListener;

    if (unsubscribeMetaData) {
      unsubscribeMetaData();
    }

    dispatch({
      type: SUBSCRIBE_USER_ACTIVITY_METADATA_LISTENER_OFF
    });
  };
};
