/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const deleteUserInfoEntryCall = async (data) => {
  const result = await functions.httpsCallable("backendUserProfileDeleteInfo")(
    data
  );
  return result.data;
};

export const syncUserProfileCall = async (userUuid) => {
  const result = await functions.httpsCallable("backendUserProfileSync")({
    userUuid: userUuid
  });
  return result.data;
};

export const syncUserProfileWithNameCall = async (username) => {
  const result = await functions.httpsCallable(
    "backendUserProfileSyncWithUsername"
  )({
    username: username
  });
  return result.data;
};

export const followUserCall = async (userUuid) => {
  const result = await functions.httpsCallable("backendUserProfileFollow")({
    userUuid: userUuid
  });
  return result.data;
};

export const unfollowUserCall = async (userUuid) => {
  const result = await functions.httpsCallable("backendUserProfileUnfollow")({
    userUuid: userUuid
  });
  return result.data;
};

export const uploadUserAvatarCall = async (formData) => {
  try {
    const postUrl = `https://us-central1-${
      Firebase.app.app().options.projectId
    }.cloudfunctions.net/backendUserProfileUploadAvatar/user/upload-avatar`;

    const userToken = await Firebase.auth.currentUser.getIdToken(true);
    let response = await fetch(postUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    return response;
  } catch (error) {
    throw new Error("error while uploading user avatar");
  }
};
