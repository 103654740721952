import React from "react";
import PropTypes from "prop-types";
import GlobalHeader from "../components/global-header/GlobalHeader";
import GatedHeader from "../components/global-header/GatedHeader";
import useTheme from "../components/theme/useTheme";
import classNames from "../utils/class-names-utils";
import THEMES from "../components/theme/Theme";
import useScrollRestore from "../components/scroll-restore/useScrollRestore";
import ToastContainer from "../components/popup/ToastContainer";
import GatedModal from "../components/gated-modal/GatedModal";
import useIsGated from "../hooks/useIsGated";
import GatedBanner from "../components/gated-banner/GatedBanner";
import GatedHeaderV2 from "../components/global-header/GatedHeader.V2";
import useIsSponsored from "../hooks/useIsSponsored";
import ClaimGroupSignoutModal from "../pages/authentication/components/ClaimGroupSignoutModal";

/**
 * @param enableGatedBanner Only certain pages should show the gated banner. Enable them by passing in this prop.
 */
const Figure1Layout = ({
  topHeader,
  header,
  children,
  footer,
  noGatedHeader,
  enableGatedBanner = false,
  pageContentClassNames = ""
}) => {
  const { theme } = useTheme();
  const scrollRef = useScrollRestore();
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();

  const actualTopHeader =
    isGated && !noGatedHeader ? (
      isSponsored ? (
        <GatedHeader />
      ) : (
        <GatedHeaderV2 />
      )
    ) : (
      topHeader
    );

  return (
    <div
      className={classNames(
        "page-wrapper",
        theme === THEMES.DARK && "bg-darkest",
        isGated && "gated"
      )}>
      <div className="page-header w-100 z-fixed">
        <ToastContainer duration={10000} />
        <GatedModal />
        <ClaimGroupSignoutModal />
        {actualTopHeader}
        {header}
      </div>
      <div
        ref={scrollRef}
        className={classNames("page-content", pageContentClassNames)}>
        {children}
      </div>
      {enableGatedBanner && <GatedBanner />}
      {footer && <div className="page-footer w-100 z-fixed">{footer}</div>}
    </div>
  );
};

Figure1Layout.propTypes = {
  topHeader: PropTypes.node,
  header: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  pageContentClassNames: PropTypes.string,
  enableGatedBanner: PropTypes.bool
};

Figure1Layout.defaultProps = {
  topHeader: <GlobalHeader />
};

export default Figure1Layout;
