/** @format */
import React from "react";
import PropTypes from "prop-types";
import placeholderImage from "../../../assets/images/image-text-case-related-cases.svg";
import { DRAFT_STATES } from "../../../constants/case-constants";

const DraftListItemThumbnail = ({ url, state }) => {
  /************************************ CONFIG ***************************************/
  const THUMBNAIL_SIZE = 75;
  const thumbnailUrl = !url
    ? placeholderImage
    : `${url}?w=${THUMBNAIL_SIZE}&h=${THUMBNAIL_SIZE}&fit=crop`;

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <>
      <img
        width={THUMBNAIL_SIZE}
        height={THUMBNAIL_SIZE}
        src={thumbnailUrl}
        alt=""
      />
    </>
  );
};

DraftListItemThumbnail.propTypes = {
  url: PropTypes.string,
  state: PropTypes.oneOf(Object.values(DRAFT_STATES))
};

export default DraftListItemThumbnail;
