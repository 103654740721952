import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownToggle, DropdownMenu, Dropdown, Button } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import FilterListItem from "./FilterListItem";
import { FEED_FILTERS, FILTER_KEYS } from "../../constants/feed-filters";
import feedActions from "../../actions/feeds.actions";

import i18n from "../../utils/i18n";
import PropTypes from "prop-types";
import "./FilterModal.scss";
import classNames from "../../utils/class-names-utils";
import {
  trackApplyFilters,
  trackFiltersOpen
} from "../../actions/metrics.actions";

/**
 * Note: requires a parent element to have `position: relative`
 */
const FilterModal = ({
  ellipsesClass,
  activeFeed,
  ellipsesColor = "#3f6296",
  ellipsesActiveColor = "#3bafa8",
  toggleButtonContents,
  dropDownClassName = "",
  dropdownMenuClassName = "",
  toggleButtonClassName = "",
  activeDropdownMenuClassName = "",
  dropdownMenuRight = false,
  popperModifiers = {},
  feedTypeUuid
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentEllipsesColor, setCurrentEllipsesColor] =
    useState(ellipsesColor);

  const contentFeedFilters = useSelector((state) => state.feeds.filters);

  const [filters, setFilters] = useState({});
  const [feed, setFeed] = useState(null);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (contentFeedFilters && feedTypeUuid) {
      setFilters(getDefaultFiltersByFeedType());
      setFeed(feedTypeUuid);
    }
    // eslint-disable-next-line
  }, [feedTypeUuid, contentFeedFilters]);

  useEffect(() => {
    if (!isDropdownOpen) {
      if (Object.keys(filters).length === 0 || !hasActiveFilter()) {
        setCurrentEllipsesColor(ellipsesColor);
      } else {
        setCurrentEllipsesColor(ellipsesActiveColor);
      }
    }
    // eslint-disable-next-line
  }, [ellipsesActiveColor, ellipsesColor, activeFeed, filters, isDropdownOpen]);

  useEffect(() => {
    if (isDropdownOpen) {
      trackFiltersOpen();
    }
  }, [isDropdownOpen]);
  /** ********************************* FUNCTIONS *************************************/

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const toggleFilterItem = (key) => {
    const f = { ...filters };
    f[key] = !f[key];
    setFilters(f);
  };

  const applyFilters = () => {
    dispatch(feedActions.updateFeedFilter(feed, filters));
    trackApplyFilters(filters);
    setDropdownOpen(false);
  };
  const hasActiveFilter = () => {
    return Object.values(filters).find((v) => v === true);
  };

  const resetFilters = () => {
    dispatch(feedActions.updateFeedFilter(feed, {}));
    setDropdownOpen(false);
  };

  /**
   * Get the default filters based on feed type
   * @param {*} type 'everything' 'recommend' or 'topic'
   * @returns {} or object with filters
   */
  const getDefaultFiltersByFeedType = () => {
    if (contentFeedFilters) {
      return contentFeedFilters[feedTypeUuid] || {};
    }
    return {};
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Dropdown
      className={classNames("filter-modal", dropDownClassName)}
      isOpen={isDropdownOpen}
      direction="down"
      toggle={toggleDropdown}>
      <DropdownToggle
        className={classNames(
          "filter-modal-toggle-button",
          toggleButtonClassName
        )}
        aria-expanded={isDropdownOpen}>
        {toggleButtonContents || (
          <CustomIcon
            className={ellipsesClass}
            icon="ellipses_vertical"
            color={currentEllipsesColor}
            size={20}
          />
        )}
      </DropdownToggle>
      <DropdownMenu
        modifiers={popperModifiers}
        className={`${dropdownMenuClassName} ${
          isDropdownOpen ? activeDropdownMenuClassName : ""
        }`}
        right={dropdownMenuRight}>
        <FilterListItem
          key={FILTER_KEYS.filter_unresolved}
          labelItem={{
            key: FILTER_KEYS.filter_unresolved,
            name: FEED_FILTERS[FILTER_KEYS.filter_unresolved].name,
            active: filters && filters[FILTER_KEYS.filter_unresolved]
          }}
          handleClick={toggleFilterItem}
        />

        <FilterListItem
          key={FILTER_KEYS.filter_resolved}
          labelItem={{
            key: FILTER_KEYS.filter_resolved,
            name: FEED_FILTERS[FILTER_KEYS.filter_resolved].name,
            active: filters && filters[FILTER_KEYS.filter_resolved]
          }}
          handleClick={toggleFilterItem}
        />

        <FilterListItem
          key={FILTER_KEYS.filter_trending}
          labelItem={{
            key: FILTER_KEYS.filter_trending,
            name: FEED_FILTERS[FILTER_KEYS.filter_trending].name,
            active: filters && filters[FILTER_KEYS.filter_trending]
          }}
          handleClick={toggleFilterItem}
        />

        <FilterListItem
          key={FILTER_KEYS.filter_common_presentation}
          labelItem={{
            key: FILTER_KEYS.filter_common_presentation,
            name: FEED_FILTERS[FILTER_KEYS.filter_common_presentation].name,
            active: filters && filters[FILTER_KEYS.filter_common_presentation]
          }}
          handleClick={toggleFilterItem}
        />

        <FilterListItem
          key={FILTER_KEYS.filter_rare_condition}
          labelItem={{
            key: FILTER_KEYS.filter_rare_condition,
            name: FEED_FILTERS[FILTER_KEYS.filter_rare_condition].name,
            active: filters && filters[FILTER_KEYS.filter_rare_condition]
          }}
          handleClick={toggleFilterItem}
        />

        <FilterListItem
          key={FILTER_KEYS.filter_teaching_case}
          labelItem={{
            key: FILTER_KEYS.filter_teaching_case,
            name: FEED_FILTERS[FILTER_KEYS.filter_teaching_case].name,
            active: filters && filters[FILTER_KEYS.filter_teaching_case]
          }}
          handleClick={toggleFilterItem}
        />

        <div className="d-flex flex-row justify-content-between align-items-center py-2 px-2 mx-n2 actions">
          <Button
            size="lg"
            color="link"
            onClick={resetFilters}
            className="text-13 p-0 text-battleship-gray helv-med">
            {i18n.t("FeedFilter.reset")}
          </Button>

          <Button
            size="lg"
            color="link"
            onClick={applyFilters}
            className="text-13 p-0 helv-med">
            {i18n.t("FeedFilter.apply")}
          </Button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

FilterModal.propTypes = {
  ellipsesClass: PropTypes.string,
  activeFeed: PropTypes.string,
  ellipsesColor: PropTypes.string,
  ellipsesActiveColor: PropTypes.string,
  toggleButtonContents: PropTypes.node,
  dropdownMenuClassName: PropTypes.string,
  activeDropdownMenuClassName: PropTypes.string,
  popperModifiers: PropTypes.object,
  dropdownMenuRight: PropTypes.bool
};

export default FilterModal;
