/** @format */

import React from "react";
import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "../../utils/class-names-utils";
import CustomIcon from "../custom-icon/CustomIcon";

const TagButton = ({ color, outline = false, icon, children }) => {
  return (
    <ListGroupItem
      className={classNames(
        "d-flex align-items-center text-14 rounded-sm px-2 py-1 mb-2",
        outline && "font-weight-500",
        outline
          ? `border border-width-1 border-${color} bg-white text-${color}`
          : `border-0 bg-${color} text-white`
      )}>
      {icon && (
        <CustomIcon
          className={classNames(
            "ml-0 mr-1 my-0",
            outline ? `icon-${color}` : "white"
          )}
          size={18}
          icon={icon}
        />
      )}
      {children}
    </ListGroupItem>
  );
};

TagButton.propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  icon: PropTypes.string
};

export default TagButton;
