import React from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import ResetPassword from "./components/ResetPassword";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";

const ResetPasswordPage = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-5 shadow">
            <ResetPassword />
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default ResetPasswordPage;
