import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import i18n from "../../../utils/i18n";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Select from "react-select";
import { fetchProfessionSpecialtiesIfNeeded } from "../../../actions/reference.actions";
import { orderBy } from "lodash";
import AddCustomItemModal from "../../../components/common/AddCustomItemModal";
import { PROFESSION_CATEGORY_LABELS } from "../../../constants/profession-category-labels";
import { SEARCH_RESULTS } from "../../../constants/search-result-constants";

const FormElementSpecialtyV2 = ({
  control,
  errors,
  specialties,
  watch,
  setValue,
  showFeedback
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const professionSpecialties = useSelector(
    (state) => state.references?.professionSpecialties
  );

  const [isOpen, setIsOpen] = useState(false);
  const professions = useSelector((state) => state?.references?.professions);
  const [specialtyList, setSpecialtyList] = useState(specialties);
  const [isMedicalStudent, setIsMedicalStudent] = useState(false);

  const specialtyWatch = watch("specialty");
  const isInvalid = !!errors.specialty;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (specialtyWatch?.value === SEARCH_RESULTS.NOTFOUND) {
      setIsOpen(true);
      setValue("specialty", null);
    }
    // eslint-disable-next-line
  }, [specialtyWatch]);

  useEffect(() => {
    if (user.professionUuid && specialties.length === 0) {
      dispatch(fetchProfessionSpecialtiesIfNeeded(user.professionUuid));
    }
  }, [dispatch, specialties.length, user, professions]);

  useEffect(() => {
    if (user.professionUuid && professions.specificProfessions) {
      const professionLabel =
        professions.raw[user.professionUuid].professionCategoryLabel;

      setIsMedicalStudent(
        professionLabel === PROFESSION_CATEGORY_LABELS.MEDICAL_STUDENT
      );
    }
  }, [user, professions]);

  useEffect(() => {
    if (professionSpecialties) {
      // use the passed in specialites has values, then let's use it, otherwise, we'll calculate the profession specialties
      if (specialties?.length > 0) {
        setSpecialtyList([
          ...orderBy(specialties, "label")
          // Currently removed until this is once again supported in backend
          // {
          //   label: i18n.t("RegistrationScreens.profileSetup.specialtyNotFound"),
          //   value: SEARCH_RESULTS.NOTFOUND
          // }
        ]);
      }
    }
  }, [specialties, professionSpecialties, user.professionUuid]);

  /** ********************************* FUNCTIONS *************************************/

  const filterOptions = (option, inputValue) => {
    if (inputValue) {
      const { label, value } = option;
      return (
        label.toLowerCase().includes(inputValue.toLowerCase()) ||
        value === specialtyList[specialtyList.length - 1].value
      );
    }
    return true;
  };

  const handleCustomSpecialty = (customItem) => {
    setIsOpen(false);
    setValue("specialty", { value: "custom", label: customItem });
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <FormGroup className="mt-4">
        <Label for="firstName" className="mb-1">
          {i18n.t(
            isMedicalStudent
              ? "RegistrationScreens.profileSetup.primarySpecialtyLabelMedStudent"
              : "RegistrationScreens.profileSetup.primarySpecialtyLabel"
          )}
        </Label>
        <Controller
          className={`mr-1 ${isInvalid ? "is-invalid" : ""}`}
          as={<Select options={specialtyList} filterOption={filterOptions} />}
          control={control}
          id="specialty"
          name="specialty"
          type="text"
          placeholder={i18n.t(
            isMedicalStudent
              ? "RegistrationScreens.profileSetup.specialtyPlaceholderMedStudent"
              : "RegistrationScreens.profileSetup.specialtyPlaceholder"
          )}
          rules={{ required: true }}
          invalid={isInvalid}
        />
        {(!showFeedback || (showFeedback && !isInvalid)) && (
          <div className="text-10 color-lighter-grey pl-1 pt-1">
            {i18n.t("RegistrationScreens.common.displayInProfile")}
          </div>
        )}
        {showFeedback && (
          <FormFeedback>
            {i18n.t("RegistrationScreens.userInfo.v2.specialtyErrorText")}
          </FormFeedback>
        )}
      </FormGroup>

      <AddCustomItemModal
        toggle={() => setIsOpen((isOpen) => !isOpen)}
        confirmText={i18n.t("RegistrationScreens.profileSetup.btnAddSpecialty")}
        customLabel={i18n.t(
          "RegistrationScreens.profileSetup.customSpecialtyLabel"
        )}
        customPlaceholder={i18n.t(
          "RegistrationScreens.profileSetup.customSpecialtyPlaceholder"
        )}
        isOpen={isOpen}
        onSubmit={handleCustomSpecialty}
      />
    </>
  );
};

FormElementSpecialtyV2.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  specialties: PropTypes.array,
  style: PropTypes.object
};

FormElementSpecialtyV2.defaultProps = {
  specialties: [],
  style: {}
};

export default FormElementSpecialtyV2;
