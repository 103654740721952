/** @format */

import React from "react";
import PropTypes from "prop-types";
import { StaticStrings } from "../../../constants/static-string-constants";
import i18n from "../../../utils/i18n";
// import { trackClaimCredit } from "../../../actions/metrics.actions";
import Dialog from "../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../components/dialog/DialogType";

const CMEPostNoticeModal = ({
  // Modal Props
  toggle,
  isOpen,
  ...rest
}) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  const contactSupport = () => {
    window.location.href = `mailto:${StaticStrings.supportEmail}`;
    toggle();
  };

  /****************************** Render **************************************/
  return (
    <Dialog
      type={DIALOG_TYPE.CONFIRM}
      isOpen={isOpen}
      title={i18n.t("CMECaseScreens.postNoticeTitle")}
      body={i18n.t("CMECaseScreens.postNoticeBody")}
      confirmText={i18n.t("CMECaseScreens.postNoticeContactButton")}
      cancelText={i18n.t("CMECaseScreens.postNoticeCloseButton")}
      onConfirm={contactSupport}
      onCancel={toggle}
      onClosed={toggle}
      {...rest}
    />
  );
};

CMEPostNoticeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default CMEPostNoticeModal;
