import { useCallback } from "react";
import {
  trackHelpModalPageEntry,
  trackOnboardingSupportClick
} from "../actions/metrics.actions";
import useDialog from "../components/dialog/useDialog";
import { StaticStrings } from "../constants/static-string-constants";
import i18n from "../utils/i18n";

export function useContactSupport() {
  const { confirm } = useDialog();

  const handleContactSupportClick = useCallback(async () => {
    trackHelpModalPageEntry();
    const confirmed = await confirm({
      message: i18n.t("RegistrationScreens.needHelpModal.title"),
      messageDetail: i18n.t("RegistrationScreens.needHelpModal.prompt"),
      confirmText: i18n.t("RegistrationScreens.needHelpModal.contactSupport"),
      cancelText: ""
    });

    if (confirmed) {
      trackOnboardingSupportClick();
      document.location = `mailto:${StaticStrings.supportEmail}`;
    }
  }, [confirm]);

  return handleContactSupportClick;
}
