import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get } from "lodash";
import ProfileEditModal from "./ProfileEditModal";
import ProfileEditYearsFormGroup from "./ProfileEditYearsFormGroup";
import { PROFILE_INFO_TYPE } from "../../../constants/profile-info-constants";
import { addOrUpdateInfoEntry } from "../../../actions/user-profiles.actions";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";
import { trackEditAbout } from "../../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";

const ProfileEditExperienceModal = ({ toggle, isOpen, userUuid, ...rest }) => {
  /************************************ CONFIG ***************************************/
  const {
    setValue,
    getValues,
    handleSubmit,
    errors,
    control,
    watch
  } = useForm();
  const data = {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = usePopup();

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const save = async (values) => {
    try {
      trackEditAbout(METRICS_EVENT_NAME.TAP.PROFILE.EDIT_EXPERIENCE);

      const result = await dispatch(
        addOrUpdateInfoEntry({
          type: PROFILE_INFO_TYPE.EXPERIENCE,
          description: values.position,
          location: values.institution,
          startYear: values.startYear,
          endYear: values.endYear,
          id: get(data, ["experienceUuid"], null)
        })
      );

      if (result.error) {
        showToast({
          message: t("Profile.updateError"),
          toastType: TOAST_TYPE.ERROR
        });
      } else {
        toggle();
      }
    } catch (error) {
      showToast({
        message: t("Profile.updateError"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <ProfileEditModal
      title={t("Profile.experienceTitle")}
      toggle={toggle}
      isOpen={isOpen}
      onSubmit={handleSubmit(save)}
      {...rest}>
      <FormGroup>
        <Label for="position">{t("Profile.position")}</Label>
        <Controller
          as={Input}
          control={control}
          id="position"
          name="position"
          type="text"
          placeholder={t("Profile.positionPlaceholder")}
          defaultValue={get(data, ["description"], "")}
          rules={{ required: true }}
          invalid={!!errors.position}
        />
        <FormFeedback>{t("Profile.positionError")}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="institution">{t("Profile.institution")}</Label>
        <Controller
          as={Input}
          control={control}
          id="institution"
          name="institution"
          type="text"
          placeholder={t("Profile.institutionPlaceholder")}
          rules={{ required: true }}
          defaultValue={get(data, ["location"], "")}
          invalid={!!errors.institution}
        />
        <FormFeedback>{t("Profile.institutionError")}</FormFeedback>
      </FormGroup>
      <ProfileEditYearsFormGroup
        setValue={setValue}
        getValues={getValues}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        watch={watch}
      />
    </ProfileEditModal>
  );
};

ProfileEditExperienceModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired
};

ProfileEditExperienceModal.defaultProps = {};

export default ProfileEditExperienceModal;
