/** @format */

import { quizVoteCall, quizResetCall } from "../api/quiz.cloud-function";
import { optimisticAddVote, optimisticResetVote } from "./user.actions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "quiz";

export const VOTE_START = `${actionsPrefix}/VOTE_START`;
export const VOTE_COMPLETE = `${actionsPrefix}/VOTE_COMPLETE`;
export const VOTE_RESET_START = `${actionsPrefix}/VOTE_RESET_START`;
export const VOTE_RESET_COMPLETE = `${actionsPrefix}/VOTE_RESET_COMPLETE`;
export const QUIZ_ERROR = `${actionsPrefix}/QUIZ_ERROR`;
export const QUIZ_UPDATE_TEXT_INPUT = `${actionsPrefix}/QUIZ_UPDATE_INPUT`;
export const QUIZ_SUBMIT_TEXT_INPUT = `${actionsPrefix}/QUIZ_SUBMIT_INPUT`;

export const quizVote = (
  contentUuid,
  questionOptionUuid,
  caseUuid,
  textInput
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VOTE_START
      });

      dispatch(
        optimisticAddVote({
          caseUuid,
          contentUuid,
          questionOptionUuid
        })
      );

      const response = await quizVoteCall(
        contentUuid,
        questionOptionUuid,
        textInput
      );

      if (isRequestSuccess(response?.data)) {
        dispatch({
          type: VOTE_COMPLETE
        });
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      dispatch({
        type: QUIZ_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

export const quizReset = (userUid, contentUuid, caseUuid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VOTE_RESET_START
      });

      dispatch(optimisticResetVote({ caseUuid, contentUuid }));

      const response = await quizResetCall(contentUuid);

      if (isRequestSuccess(response?.data)) {
        dispatch({
          type: VOTE_RESET_COMPLETE
        });
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      dispatch({
        type: QUIZ_ERROR,
        payload: {
          error
        }
      });
    }
  };
};

export const resetQuizBundle = (userUid, contentItems) => {
  return async (dispatch) => {
    for (const item of contentItems) {
      dispatch(quizReset(userUid, item.contentUuid));
    }
  };
};

export const quizUpdateTextInput = (contentUuid, textInput) => {
  return async (dispatch) => {
    dispatch({
      type: QUIZ_UPDATE_TEXT_INPUT,
      payload: { contentUuid: contentUuid, textInput: textInput }
    });
  };
};
