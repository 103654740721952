/** @format */

export const CASE_LABEL_TYPES = {
  UNRESOLVED: "unresolved",
  RARE_CONDITION: "rare_condition",
  RESOLVED: "resolved",
  VERIFIED_LITERATURE: "verified_literature",
  TRENDING: "trending",
  TEACHING_CASE: "teaching_case",
  COMMON_PRESENTATION: "common_presentation"
};
