/** @format */

import { useSelector } from "react-redux";

export const useSelectSearchTerm = () => {
  return useSelector((state) => state?.search?.searchTerm);
};

export const useSelectSearchSuggestions = () => {
  return useSelector((state) => {
    const _state = state?.search?.searchSuggestions;

    return {
      searchSuggestions: _state?.searchSuggestions,
      isLoading: _state?.isLoading
    };
  });
};

export const useSelectSearchResultType = () => {
  return useSelector((state) => state?.search?.searchResultType);
};
