/** @format */

import { useSelector } from "react-redux";

export const useSelectSchoolSuggestions = () => {
  return useSelector((state) => {
    const references = state?.references;

    return {
      data: references?.schoolSuggestions,
      isLoadingSchoolSuggestions: references?.isLoadingSchoolSuggestions
    };
  });
};

export const useSelectProfessions = () => {
  return useSelector((state) => state?.references?.professions);
};

export const useSelectProfessionSpecialties = (professionUuid) => {
  return useSelector(
    (state) =>
      ({
        data: state?.references?.professionSpecialties?.[professionUuid] || [],
        isLoadingSpecialties:
          state?.references?.professionSpecialties?.isLoadingSpecialties
      } || {})
  );
};

export const useSelectInterests = () => {
  return useSelector((state) => state?.references?.interests);
};

export const useSelectProfessionRawData = (professionUuid) => {
  return useSelector((state) => {
    const professions = state?.references?.professions;

    return {
      isLoadingProfessions: professions.isLoadingProfessions,
      rawProfessionData: professions?.raw?.[professionUuid]
    };
  });
};

export const useSelectFallbackCountryAndRegions = () => {
  return useSelector((state) => {
    const fallbackLocations = state?.references?.fallbackLocations;

    return {
      countries: fallbackLocations.countries,
      regions: fallbackLocations.regions,
      isLoadingFallbackLocations: fallbackLocations.isLoadingFallbackLocations
    };
  });
};
