import React from "react";
import HomePage from "./home/HomePage";
import Figure1Layout from "../@layouts/Figure1Layout";
import AppPromptAuthenticated from"../components/app-prompt/AppPromptAuthenticated";

const HomeApp = () => {
  return (
    <Figure1Layout footer={<AppPromptAuthenticated />}>
      <HomePage />
    </Figure1Layout>
  );
};

export default HomeApp;
