/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { isEmpty } from "lodash";
import {
  deleteDraft,
  shouldShowDraftToUser
} from "../../actions/user-draft.actions";
import i18n from "../../utils/i18n";
import useDialog from "../../components/dialog/useDialog";
import usePopup from "../../components/popup/usePopup";
import TOAST_TYPE from "../../components/popup/ToastType";
import DraftsEmpty from "./components/DraftsEmpty";
import DraftsFound from "./components/DraftsFound";
import { trackDraftsPageEntry } from "../../actions/metrics.actions";

const DraftsPage = () => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const { showToast } = usePopup();
  const isProcessing = useSelector((state) => state.userDraft.isProcessing);
  const drafts = useSelector((state) => state.userDraft.data);
  const draftToDisplay = Object.values(drafts)
    .filter(shouldShowDraftToUser)
    .sort((d1, d2) => d2.updated - d1.updated);

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    trackDraftsPageEntry();
  }, []);

  /*********************************** FUNCTIONS *************************************/

  const onDelete = async (draftUid) => {
    const confirmed = await confirm({
      message: i18n.t("DraftsScreen.deleteEntryConfirmation")
    });

    if (!confirmed) {
      return;
    }

    try {
      await dispatch(deleteDraft(draftUid));
    } catch (e) {
      showToast({
        message: i18n.t("common.genericError"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Card className="border-0 rounded-0">
      <CardBody>
        <CardTitle
          className="text-15 text-default-black pb-4 pt-2 mb-0"
          tag="h5">
          {i18n.t("DraftsScreen.drafts", {
            count: draftToDisplay.length
          })}
        </CardTitle>
        <Card className="border-0">
          {isEmpty(draftToDisplay) ? (
            <DraftsEmpty />
          ) : (
            <DraftsFound
              drafts={draftToDisplay}
              isProcessing={isProcessing}
              onDeleteDraft={onDelete}
            />
          )}
        </Card>
      </CardBody>
    </Card>
  );
};

export default DraftsPage;
