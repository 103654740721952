/** @format */
import React from "react";
import PropTypes from "prop-types";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const DownloadButton = ({
  text,
  onPush,
  href,
  classNames = "download-button",
  icon = "CMEcertificate_download"
}) => {
  const textStyle = {
    fontSize: "14px",
    marginRight: "6px"
  };

  if (href) {
    return (
      <div
        style={{ alignSelf: "flex-end", margin: "10px" }}
        className="d-flex flex-row justify-content-end align-items-center">
        <a
          href={href}
          onClick={onPush}
          className={`${classNames} d-flex flex-row justify-content-center align-items-center`}>
          <span style={textStyle}>{text}</span>
          <CustomIcon className="custom-icon-hover" icon={icon} size={14} />
        </a>
      </div>
    );
  }
  return (
    <div
      style={{ alignSelf: "flex-end", margin: "10px" }}
      onClick={onPush}
      className={`${classNames} d-flex flex-row justify-content-center align-items-center`}>
      <span style={textStyle}>{text}</span>
      <CustomIcon className="custom-icon-hover" icon={icon} size={14} />
    </div>
  );
};

DownloadButton.propTypes = {
  text: PropTypes.string.isRequired,
  onPush: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default DownloadButton;
