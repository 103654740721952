/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const syncCMEHubCall = async () => {
  const result = await functions.httpsCallable("backendCmeTriggerUpdate")({});
  return result.data;
};

export const submitCertificateDegreeCall = async (caseUuid, degree) => {
  const result = await functions.httpsCallable("backendCmeSubmitDegree")({
    caseUuid: caseUuid,
    degree: degree
  });
  return result.data;
};
