/** @format */

const DIALOG_TYPE = {
  INFORM: "INFORM",
  CONFIRM: "CONFIRM",
  CONFIRM_REVERSED: "CONFIRM_REVERSED",
  GATED: "GATED"
};

export default DIALOG_TYPE;
