import React from "react";
import PropTypes from "prop-types";
import classNames from "../../utils/class-names-utils";
import LoadingButton from "../common/LoadingButton";

const BaseDialogButton = ({
  className,
  outline,
  color,
  spinnerColor,
  isLoading,
  isDisabled,
  onClick,
  children,
  block
}) => {
  return (
    <LoadingButton
      size="lg"
      outline={outline}
      color={color}
      spinnerColor={spinnerColor}
      loading={isLoading}
      disabled={isDisabled}
      onClick={onClick}
      block={block}
      className={classNames("text-16 min-w-171px mx-auto", className)}>
      {children}
    </LoadingButton>
  );
};

BaseDialogButton.propTypes = {
  outline: PropTypes.bool,
  color: PropTypes.string,
  spinnerColor: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

BaseDialogButton.defaultProps = {
  color: "primary",
  spinnerColor: "light",
  outline: false,
  isLoading: false,
  isDisabled: false
};

export default BaseDialogButton;
