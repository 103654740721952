import React, { useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import CMEPage from "./CMEPage";
import CMECertificateModal from "./components/CMECertificateModal";
import CMEPostNoticeModal from "./components/CMEPostNoticeModal";
import { withOpenModalManagement } from "../../components/common/WithModalManagement";
import * as ROUTES from "../../constants/routes";

const CME = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();

  const cmeRoutes = useMemo(
    () => [
      {
        path: ROUTES.CME_CERTIFICATE,
        component: withOpenModalManagement(CMECertificateModal),
        props: {
          onClosed: () => history.replace(ROUTES.CME_ROOT)
        }
      },
      {
        path: ROUTES.CME_POST_NOTICE,
        component: withOpenModalManagement(CMEPostNoticeModal),
        props: {
          onClosed: () => history.replace(ROUTES.CME_ROOT)
        }
      }
    ],
    [history]
  );

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <CMEPage />
      <Switch>
        {cmeRoutes.map((route) => (
          <Route key={route.path} path={route.path}>
            <route.component {...route.props} />
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default CME;
