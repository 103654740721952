import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { trackUngatedJoinCardCTA } from "../../actions/metrics.actions";
import CircleCustomIcon from "../../components/custom-icon/CircleCustomIcon";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import i18n from "../../utils/i18n";
import { getUrlWithRedirect } from "../../utils/route-utils";

const BlockedCaseUpsell = () => {
  const userExists = useAnonUserExists();
  const { showLoginModal } = useLoginModalClick();
  const loginClick = userExists ? showLoginModal : () => {};

  const titleText = userExists
    ? "Blocked.upsell.title2"
    : "Blocked.upsell.title";

  const signUpText = userExists
    ? "Blocked.upsell.signUp2"
    : "Blocked.upsell.signUp";

  return (
    <Card className="mb-2">
      <CardBody>
        <h3 className="helv-bold text-22 text-center mb-4">
          {i18n.t(titleText)}
        </h3>
        <ul className="m-0 p-0 helv-standard text-16">
          {userExists && (
            <>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_personalized"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.altPoint1")}</div>
              </li>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_access"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.altPoint2")}</div>
              </li>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_search"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.altPoint3")}</div>
              </li>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_cme"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.altPoint4")}</div>
              </li>
            </>
          )}
          {!userExists && (
            <>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_share_knowledge"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.point1")}</div>
              </li>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_help"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.point2")}</div>
              </li>
              <li className="d-flex flex-row align-items-center mb-2">
                <CircleCustomIcon
                  circleColor="lighter-gray p-1"
                  icon="gated_learn"
                  className="mr-2"
                  innerClassName="icon-charcoal-gray"
                />
                <div>{i18n.t("Blocked.upsell.point3")}</div>
              </li>
            </>
          )}
        </ul>
        <Link
          className="btn btn-lg btn-secondary btn-block helv-bold mt-4"
          onClick={(e) => {
            trackUngatedJoinCardCTA();
            loginClick(e);
          }}
          to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}>
          {i18n.t(signUpText)}
        </Link>
      </CardBody>
    </Card>
  );
};

export default BlockedCaseUpsell;
