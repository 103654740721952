/** @format */

export const CONTENT_TYPE = {
  QUIZ: "quiz",
  QUIZ_SERIES: "quiz_series",
  QUIZ_SUMMARY: "quiz_summary",
  POLL: "poll", // TODO: This doesn't exist, polls are quizzes with all answers false. Delete this
  BASIC: "content",
  COVER: "cover",
  CONCLUSION: "conclusion",
  FEED_CARD: "feed_card",
  CME_HUB_CARD: "cme_hub_card"
};

export const QUESTION_CONTENT_TYPES = [
  CONTENT_TYPE.QUIZ,
  CONTENT_TYPE.QUIZ_SERIES
];
