/** @format */

import {
  FETCH_CASE_LABELS_START,
  FETCH_CASE_LABELS_COMPLETE
} from "../actions/case-label.actions";

export const INITIAL_STATE = {
  data: [],
  labelByKind: {},
  isProcessing: false,
  error: false,
  hasBeenFetched: false,
  message: null
};

const caseLabelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CASE_LABELS_START:
      return { ...state, isProcessing: true, hasBeenFetched: false };
    case FETCH_CASE_LABELS_COMPLETE:
      let hasBeenFetched = false;
      if (action.payload.labels) {
        hasBeenFetched = true;
      }
      let labelByKind = {};
      if (action.payload.labels) {
        const nbLabels = action.payload.labels.length;
        for (let labelIndex = 0; labelIndex < nbLabels; labelIndex++) {
          labelByKind[action.payload.labels[labelIndex].labelKind] =
            action.payload.labels[labelIndex].name;
        }
      }
      return {
        ...state,
        isProcessing: false,
        hasBeenFetched: hasBeenFetched,
        data: action.payload.labels,
        error: action.error,
        message: action.message,
        labelByKind: labelByKind
      };
    default:
      return state;
  }
};

export default caseLabelReducer;
