import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import useOnScreen from "../../hooks/use-on-screen.hooks";
import useInViewPlaybackControl from "../../hooks/video/useInViewPlaybackControl";
import useLocalVideoConfiguration from "../../hooks/video/useLocalVideoConfiguration";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import "./case-media-video.scss";
import CustomIcon from "../custom-icon/CustomIcon";

function videoError(error) {
  console.log("VIDEO ERROR", error);
}

const ON_SCREEN_OPTIONS = {
  threshold: 0.75
};

/**
 * VIDEO_CONTROL_MODE settings:
 * - FULL (default): show controls, do not repeat, unmuted
 * - FEED_MODE: show only mute control, repeat, muted
 */

const CaseMediaVideo = ({
  url,
  contentUuid,
  videoControlMode = VIDEO_CONTROL_MODE.FULL,
  forceAutoplay = false,
  forceMuted = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const playerWrapperRef = useRef(null);
  const isInView = useOnScreen(playerWrapperRef, ON_SCREEN_OPTIONS);
  const [isStarted, _setIsStarted] = useState(false);

  const prevCurrentTime = useSelector(
    (state) => state?.videoState?.videos?.[contentUuid]?.currentTime
  );
  const isFeedMode = videoControlMode === VIDEO_CONTROL_MODE.FEED;
  const {
    _player,
    _handleSeek,
    _onProgress,
    _currentTimeRef,
    _muted,
    _setMuted,
    _pause,
    _setPause
  } = useLocalVideoConfiguration({
    initialPause: false,
    initialCurrentTime: prevCurrentTime || 0,
    initialMuted: forceMuted || isFeedMode
  });

  /**
   * Need to prioritize isInView. If other way around, all CME/CM
   *  video slides will auto-play all videos instead of the one in view...
   */
  useInViewPlaybackControl({
    isInView: isInView || forceAutoplay,
    _setPause,
    _currentTimeRef,
    contentUuid
  });

  /** ********************************** HOOKS ****************************************/

  /** ******************************** FUNCTIONS **************************************/
  const onLoad = () => {
    if (!isStarted && prevCurrentTime) {
      _handleSeek({ seekTime: prevCurrentTime });
    }

    if (!isStarted) {
      _setIsStarted(true);
    }
  };

  /** ******************************** RENDER ****************************************/

  if (!url || isEmpty(url)) {
    return null;
  }

  return (
    <div className="position-relative" ref={playerWrapperRef}>
      {isFeedMode && (
        <button
          type="button"
          className="d-block position-absolute toggle-mute"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            _setMuted(!_muted);
          }}>
          <CustomIcon
            color="white"
            icon={_muted ? "video_sound-off" : "video_sound-on"}
            size={20}
          />
        </button>
      )}
      <ReactPlayer
        ref={_player}
        url={url}
        width="100%"
        height="100%"
        controls={videoControlMode === VIDEO_CONTROL_MODE.FULL}
        playing={!_pause}
        muted={_muted}
        loop={isFeedMode}
        onReady={onLoad}
        onProgress={_onProgress}
        onError={videoError}
      />
    </div>
  );
};

export default CaseMediaVideo;
