import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Button, Card } from "reactstrap";

import i18n from "../../../utils/i18n";
import NpiConfirmationModal from "./NpiConfirmationModal";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const SideBarUserDetails = () => {
  /** ********************************** CONFIG ***************************************/
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  /** ********************************* FUNCTIONS *************************************/
  const onCancelModal = () => setIsModalOpen(false);
  /** ********************************** RENDER ***************************************/

  return (
    <>
      {isMobile && (
        <>
          <Card className="p-4 rounded-0 mb-2">
            <h2 className="text-15 helv-bold mb-0">
              {i18n.t(
                "RegistrationScreens.verificationPhotoPage.v2.sidebar.mobileTitle"
              )}
            </h2>
            <div className="d-flex flex-row w-100 justify-content-between">
              <div className="flex-grow-1" style={{ maxWidth: "70vw" }}>
                <div>{user.displayName}</div>
                <div
                  className="color-battleship-gray"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}>
                  {user.profileDisplayName}
                </div>
              </div>
              <Button
                className="p-0"
                color="link"
                onClick={() => setIsModalOpen(true)}>
                <CustomIcon
                  icon="profile_edit_pencil"
                  size="23"
                  className="icon-denim-blue "
                />
              </Button>
            </div>
          </Card>
        </>
      )}

      {!isMobile && (
        <Card className="p-4 rounded-0">
          <h2 className="text-18 helv-bold mb-4">
            {i18n.t(
              "RegistrationScreens.verificationPhotoPage.v2.sidebar.title"
            )}
          </h2>
          <dl className="mb-2 text-15">
            <dt className="text-blue-gray">
              {i18n.t(
                "RegistrationScreens.verificationPhotoPage.v2.sidebar.fullName"
              )}
            </dt>
            <dd>{user.displayName}</dd>

            <dt className="text-blue-gray">
              {i18n.t(
                "RegistrationScreens.verificationPhotoPage.v2.sidebar.profession"
              )}
            </dt>
            <dd>{user.professionName}</dd>
            {(user.subspecialtyName || user.specialtyName) && (
              <>
                <dt className="text-blue-gray">
                  {i18n.t(
                    "RegistrationScreens.verificationPhotoPage.v2.sidebar.specialty"
                  )}
                </dt>
                <dd>{user.subspecialtyName || user.specialtyName}</dd>
              </>
            )}
          </dl>
          <Button
            className="w-100 p-0 text-left text-15 d-flex align-items-center color-denim-blue"
            color="link"
            onClick={() => setIsModalOpen(true)}>
            <CustomIcon
              icon="profile_edit_pencil"
              size="15"
              className="icon-denim-blue mr-2"
            />{" "}
            {i18n.t(
              "RegistrationScreens.verificationPhotoPage.v2.sidebar.btnEdit"
            )}
          </Button>
        </Card>
      )}
      {isModalOpen && (
        <NpiConfirmationModal
          isOpen={isModalOpen}
          onCancel={onCancelModal}
          existingData={user}
          performTracking={false}
          performNpiVerification={false}
          performNavigate={false}
        />
      )}
    </>
  );
};

export default SideBarUserDetails;
