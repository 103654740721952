/** @format */

import React from "react";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const NetworkCTA = ({ message, tall = false, icon = "follow-empty-state" }) => {
  const styleProps = {};
  if (tall) {
    styleProps.minHeight = "200px";
  }
  return (
    <div
      style={styleProps}
      className="d-flex flex-column justify-content-center align-items-center text-align-center mt-3 p-4 panel-denim-blue-light text-denim-blue px-5">
      <CustomIcon icon={icon} size={40} className="mb-2 icon-denim-blue" />
      <p className="text-16 mb-0 text-center" style={{ maxWidth: "300px" }}>
        {message}
      </p>
    </div>
  );
};

export default NetworkCTA;
