/** @format */
import Firebase from "../firebase";

const functions = Firebase.functions;

export const urlRedirectCall = async (legacyUrl) => {
  try {
    const result = await functions.httpsCallable("backendLegacyUrlRedirect")({
      url: legacyUrl
    });
    return result.data;
  } catch (e) {
    console.log("ERROR: ", e.message);
    throw new Error("Error while getting redirect url ", legacyUrl);
  }
};
