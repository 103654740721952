/** @format */

import React from "react";
import { ListGroupItem } from "reactstrap";
import { ACTIVITY_TYPES } from "../../../constants/activity-constants";
import placeholderImage from "../../../assets/images/image-text-case-related-cases.svg";
import RejectedPostItem from "./RejectedPostItem";
import DeletedPostItem from "./DeletedPostItem";

const generateSummary = (content) => {
  if (!content) return "";

  const { sourceUser, longMessage } = content;
  switch (content.type) {
    case ACTIVITY_TYPES.REJECT:
      return <RejectedPostItem title={content.case.title} />;

    case ACTIVITY_TYPES.CASE_DELETE:
    case ACTIVITY_TYPES.COMMENT_DELETE: // show deleted
      return (
        <DeletedPostItem
          title={content.longMessage}
          image={content.caseMediaUrl}
          createdDate={content.case?.publishedAt}
          reason={content.rejectionReasonMessage}
          type={content.type}
        />
      );

    case ACTIVITY_TYPES.COMMENT:
    case ACTIVITY_TYPES.REPLY:
      return (
        <>
          {!!sourceUser && (
            <span className="text-denim-blue">
              {"@" + sourceUser.username + " "}
            </span>
          )}
          {!!longMessage && longMessage}
        </>
      );
    default:
      return longMessage;
  }
};

const ActivityItemType = ({ image, content, onClick, showPointer = true }) => {
  return (
    <ListGroupItem
      className={`d-inline-flex align-items-center px-0 ${
        showPointer ? "cursor-pointer" : ""
      }`}
      onClick={onClick}>
      <div className="position-relative mr-3">
        <img
          width="75"
          height="75"
          src={image ? image : placeholderImage}
          alt=""
        />
      </div>
      <div className="flex-grow-1">
        <div className="mb-0">{content}</div>
      </div>
    </ListGroupItem>
  );
};

/**
 * There are a few special cases for images:
 * - All activity should come with an image, except for
 * text only cases which uses a local icon
 * - If it's a profile, the image should be circular
 */
const ActivityItem = ({ content, onClick }) => {
  const { type, caseMedia } = content;
  const summary = generateSummary(content);

  switch (type) {
    case ACTIVITY_TYPES.APPROVE:
    case ACTIVITY_TYPES.REACT:
      return (
        <ActivityItemType
          image={caseMedia.caseMediaUrl}
          onClick={onClick}
          content={summary}
        />
      );
    case ACTIVITY_TYPES.REJECT:
      return (
        <ActivityItemType
          image={caseMedia.caseMediaUrl}
          onClick={onClick}
          content={summary}
        />
      );
    case ACTIVITY_TYPES.MODERATION_ACTIVITY:
      return (
        <ActivityItemType
          image={caseMedia.caseMediaUrl}
          onClick={onClick}
          content={summary}
        />
      );
    case ACTIVITY_TYPES.COMMENT:
    case ACTIVITY_TYPES.REPLY:
      return (
        <ActivityItemType
          image={caseMedia.caseMediaUrl}
          onClick={onClick}
          content={summary}
        />
      );
    default:
      return (
        <ActivityItemType
          image={caseMedia.caseMediaUrl}
          onClick={onClick}
          content={summary}
        />
      );
  }
};

export default ActivityItem;
