/** @format */

import {
  CACHE_DETAIL_VIEW,
  CACHE_FEED_VIEW,
  RESET_CACHE
} from "../actions/metrics.actions";

const INITIAL_STATE = {
  detailViewUuids: [],
  feedViewUuids: []
};

const metricsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CACHE_DETAIL_VIEW:
      return {
        ...state,
        detailViewUuids: [...state.detailViewUuids, action.payload?.caseUuid]
      };
    case CACHE_FEED_VIEW:
      return {
        ...state,
        feedViewUuids: [...state.feedViewUuids, action.payload]
      };
    case RESET_CACHE:
      return {
        ...state,
        detailViewUuids: [],
        feedViewUuids: []
      };
    default:
      return state;
  }
};

export default metricsReducer;
