/** @format */

import { useState, useRef, useCallback, useEffect } from "react";
import { throttle } from "lodash";

// Used underscores to indicate local state
const useLocalVideoConfiguration = ({
  initialPause,
  initialMuted,
  initialCurrentTime
}) => {
  const _player = useRef(null);
  const _currentTimeRef = useRef(initialCurrentTime);
  const [_pause, _setPause] = useState(initialPause);
  const [_muted, _setMuted] = useState(initialMuted);
  const [_currentTime, _setCurrentTime] = useState(initialCurrentTime);

  /**
   * Using _currentTime and _currentTimeRef is needed to save current time without
   * updating the Redux state every bunch of milliseconds as the video progresses...
   */
  const debounceUpdateRef = useCallback(
    throttle((time) => {
      _currentTimeRef.current = time;
    }, 1000),
    [_currentTimeRef]
  );

  useEffect(() => {
    debounceUpdateRef(_currentTime);
  }, [_currentTime, debounceUpdateRef]);

  const _togglePause = () => {
    _setPause(!_pause);
  };

  const _toggleMuted = () => {
    _setMuted(!_muted);
  };

  const _onProgress = (data) => {
    _setCurrentTime(data.playedSeconds);
  };

  const _handleSeek = (data) => {
    _player.current.seekTo(data.seekTime);
    _setCurrentTime(data.seekTime);
  };

  return {
    _player,
    _pause,
    _setPause,
    _togglePause,
    _muted,
    _setMuted,
    _toggleMuted,
    _currentTime,
    _setCurrentTime,
    _onProgress,
    _handleSeek,
    _currentTimeRef
  };
};

export default useLocalVideoConfiguration;
