/** @format */

export const SEARCH_RESULT_TYPES = {
  CASE: "case",
  PROFILE: "profile"
};

export const SEARCH_RESULTS = {
  NOTFOUND: "not-found"
};
