import { useSelector } from "react-redux";
import { userIsVerified } from "../utils/user-utils";

export const useVerifiedMemberStatus = () => {
  const _state = useSelector((state) => {
    return userIsVerified(state?.user);
  });

  return _state;
};
