import React from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";

const NavMoreButton = (props) => {
  return (
    <div
      tabIndex={0}
      role="button"
      className="navigation-item navigation-more-button text-13"
      onClick={props.toggleSeeMore}>
      <div className="mr-1">
        {i18n.t(props.isOpen ? "navigation.viewLess" : "navigation.viewMore")}
      </div>
      <div
        className={
          props.isOpen
            ? "topic-list-expand topic-list-open"
            : "topic-list-expand"
        }>
        <CustomIcon color="#3f6296" icon="detail_comment_sort" size="11" />
      </div>
    </div>
  );
};

export default NavMoreButton;
