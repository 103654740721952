/** @format */

import React from "react";
import PropTypes from "prop-types";
import INTERACTIVE_CASE_STATES from "../../constants/interactive-case-states";
import i18n from "../../utils/i18n";
import TagButton from "./TagButton";

const FeedProgressTag = ({
  numCorrect,
  numQuestions,
  currentQuestion,
  status,
  isPoll
}) => {
  let text = "";
  let icon = null;
  let color = isPoll ? "aqua" : "denim-blue";
  let outline = false;

  switch (status) {
    case INTERACTIVE_CASE_STATES.IN_PROGRESS:
      // Use currentQuestion display order in case member
      // reset a question in the middle of a quiz
      text = i18n
        .t("CaseCard.progress.inProgress")
        .replace(
          "$<PROGRESS>",
          `${currentQuestion.displayOrder + 1}/${numQuestions}`
        );
      icon = "quiz_progress";
      outline = true;
      break;
    case INTERACTIVE_CASE_STATES.COMPLETED:
      text = i18n.t("CaseCard.progress.completed");

      if (!isPoll) {
        text +=
          " " +
          i18n
            .t("CaseCard.progress.score")
            .replace("$<SCORE>", `${numCorrect}/${numQuestions}`);
      }

      icon = "quiz_completed";
      outline = false;
      break;
    default:
      text = i18n
        .t("CaseCard.progress.numQuestions")
        .replace("$TOTAL_NUMBER", numQuestions);
      break;
  }

  return (
    <TagButton color={color} outline={outline} icon={icon}>
      {text}
    </TagButton>
  );
};

FeedProgressTag.propTypes = {
  numCorrect: PropTypes.number,
  numQuestions: PropTypes.number,
  currentQuestion: PropTypes.shape({
    displayOrder: PropTypes.number
  }),
  status: PropTypes.string
};

export default FeedProgressTag;
