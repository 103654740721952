import React, { useEffect, useMemo, useRef, useState } from "react";
import useResizeObserver from "../../hooks/use-resize-observer.hooks";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import useScrollRestore from "../scroll-restore/useScrollRestore";

const AutoSizer = ({
  className,
  children,
  yOnly,
  setOnce,
  style = {},
  ...props
}) => {
  /** ********************************** CONFIG ***************************************/
  const autoSizerRef = useRef(null);
  const { top, width } = useAutoSizer({
    elementRef: autoSizerRef,
    yOnly: yOnly,
    setOnce: setOnce
  });

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div
      ref={autoSizerRef}
      className={`autosizer ${className}`}
      style={{ ...style, top, width }}
      {...props}>
      {width > 0 && children}
    </div>
  );
};

/**
 * Hook to get the width and top position of the provided element after it is resized.
 *
 * @param {{ elementRef: React.MutableRefObject<HTMLElement> }} param0
 */
const useAutoSizer = ({ elementRef, yOnly, setOnce }) => {
  /** ********************************** CONFIG ***************************************/
  const parentRef = useRef(null);
  const entriesRef = useRef([]);
  const scrollRef = useScrollRestore();

  const [position, setPosition] = useState({
    top: 0,
    width: 0
  });
  const toastIsAvailable = useSelector(
    (state) => !isEmpty(state.global.message)
  );
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!parentRef.current && elementRef.current) {
      parentRef.current = elementRef.current.parentNode;
    }
  }, [elementRef]);

  useEffect(() => {
    setTimeout(resetPosition, 205);
    // eslint-disable-next-line
  }, [toastIsAvailable]);

  useResizeObserver({
    resizeObserverCallback: (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      entriesRef.current = entries;
      if ((setOnce && !position) || !setOnce) {
        resetPosition();
      }
    },
    elementRef: parentRef
  });

  const resetPosition = () => {
    if (!Array.isArray(entriesRef.current) || !entriesRef.current.length) {
      return;
    }

    const clientRect = entriesRef.current[0].target.getBoundingClientRect();
    const contentRect = entriesRef.current[0].contentRect;
    const scrollTopOffset = scrollRef?.current?.scrollTop || 0;

    if (yOnly) {
      setPosition({
        top: "auto",
        width: contentRect.width
      });
    } else {
      setPosition({
        top: clientRect.top + scrollTopOffset,
        width: contentRect.width
      });
    }
  };

  return useMemo(() => position, [position]);
};

export default AutoSizer;
