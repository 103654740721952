/** @format */

import { useRef } from "react";
import { trackCMESlide } from "../actions/metrics.actions";
import useIsGated from "./useIsGated";

const useTrackSlideView = ({ items, activeIndex, next, previous }) => {
  const timestamp = useRef(new Date().getTime());
  const isGated = useIsGated();

  const onNext = () => {
    sendMetrics();
    next();
  };

  const onPrev = () => {
    sendMetrics();
    previous();
  };

  const sendMetrics = () => {
    const item = items[activeIndex];
    if (item) {
      const currentTime = new Date().getTime();
      const duration = currentTime - timestamp.current;
      timestamp.current = currentTime;

      trackCMESlide({
        caseContent: item,
        duration,
        campaignUuid: item.campaignUuid,
        contentUuid: item.contentUuid,
        displayOrder: item.displayOrder,
        section: item.section,
        contentType: item.contentType,
        isUngated: isGated
      });
    }
  };

  return { onNext, onPrev, sendMetrics };
};

export default useTrackSlideView;
