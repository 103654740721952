/** @format */

import { isEmpty } from "lodash";
import * as countryDB from "../db/country.db";

const actionsPrefix = "country";

export const CACHE_COUNTRIES = `${actionsPrefix}/Fetch_COUNTRIES`;
export const FETCH_SUBDIVISION = `${actionsPrefix}/Fetch_SUBDIVISION`;
export const FETCH_COUNTRIES_COMPLETE = `${actionsPrefix}/Fetch_COUNTRIES_COMPLETE`;
export const FETCH_SUBDIVISION_COMPLETE = `${actionsPrefix}/Fetch_SUBDIVISION_COMPLETE`;
export const CACHE_SUBDIVISION = `${actionsPrefix}/CACHE_SUBDIVISION`;
export const CACHE_SUBDIVISION_COMPLETE = `${actionsPrefix}/CACHE_SUBDIVISION_COMPLETE`;

export const fetchCountriesIfNeeded = () => {
  return (dispatch, getState) => {
    try {
      if (isEmpty(getState()?.countries?.countries)) {
        return dispatch(fetchCountry());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Note: This uses the old `b_referenceData` DB, that has all countries and regions.
 */
export const fetchCountry = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CACHE_COUNTRIES
      });

      const countries = await countryDB.fetchCountries();
      dispatch({
        type: FETCH_COUNTRIES_COMPLETE,
        error: null,
        message: "Countries fetched successfully",
        payload: {
          countries: countries
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_COUNTRIES_COMPLETE,
        error: error,
        message: error.message,
        payload: {
          countries: null
        }
      });
    }
  };
};

export const fetchSubdivision = (countryUuidArray) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_SUBDIVISION
      });

      let subdivision = {};
      for (
        let arrayUuidIndex = 0;
        arrayUuidIndex < countryUuidArray.length;
        arrayUuidIndex++
      ) {
        const countryUuid = countryUuidArray[arrayUuidIndex];

        let objectSubdivision = await countryDB.fetchSubdivision(countryUuid);
        // transform the object from the database into an array
        if (objectSubdivision) {
          let arraySubdivision = [];
          Object.keys(objectSubdivision).foreach((subdivisionId) => {
            arraySubdivision.push(objectSubdivision[subdivisionId]);
          });
          subdivision[countryUuid] = arraySubdivision;
        }
      }

      dispatch({
        type: FETCH_SUBDIVISION_COMPLETE,
        error: null,
        message: "Subdivision fetched successfully",
        payload: {
          subdivision
        }
      });
    } catch (error) {
      dispatch({
        type: FETCH_SUBDIVISION_COMPLETE,
        error: error,
        message: error.message,
        payload: {
          subdivision: null
        }
      });
    }
  };
};

export const cacheSubdivision = (countryReferenceObject) => {
  return async (dispatch) => {
    dispatch({
      type: CACHE_SUBDIVISION
    });

    const countryUuid = countryReferenceObject.uuid;
    let objectSubdivision = await countryDB.fetchSubdivision(countryUuid);
    dispatch({
      type: CACHE_SUBDIVISION_COMPLETE,
      error: null,
      message: "Subdivision cached successfully",
      payload: {
        subdivision: objectSubdivision,
        subdivisionCountryUuid: countryUuid
      }
    });
  };
};
