/** @format */

import { searchInFeed } from "../api/feeds.cloud-functions";
import { EMPTY_FEED_TYPE_UUID } from "../constants/empty-feed-type-uuid";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "searchFeed";

export const RESET_SEARCH_TERMS = `${actionsPrefix}/RESET_SEARCH_TERMS`;
export const UPDATE_SEARCH_TERMS = `${actionsPrefix}/UPDATE_SEARCH_TERMS`;
export const SEARCH_FEED = `${actionsPrefix}/SEARCH_FEED`;
export const SEARCH_FEED_COMPLETE = `${actionsPrefix}/SEARCH_FEED_COMPLETE`;

export const resetSearchTerms = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESET_SEARCH_TERMS
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const searchFeed = (feedTypeUuid, searchTerms) => {
  return async (dispatch, getState) => {
    try {
      // doing nothing if the feed type uuid is one of the defaults ones
      if (!Object.values(EMPTY_FEED_TYPE_UUID).includes(feedTypeUuid)) {
        dispatch({
          type: UPDATE_SEARCH_TERMS,
          payload: {
            searchTerms: searchTerms,
            feedTypeUuid: feedTypeUuid
          }
        });
        const searchFeedTypeUuid =
          getState().userFeedMeta.searchFeed.feed_type_uuid;

        const response = await searchInFeed(
          feedTypeUuid,
          searchFeedTypeUuid,
          searchTerms,
          0
        );
        if (isRequestSuccess(response)) {
          dispatch({
            type: SEARCH_FEED_COMPLETE,
            message: "Search request sent successfully",
            error: null
          });
        }
      } else {
        throw new Error("Error while searching the feed");
      }
    } catch (error) {
      dispatch({
        type: SEARCH_FEED_COMPLETE,
        message: error.message,
        error: error
      });
    }
  };
};
