import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import LoadingButton from "../../../components/common/LoadingButton";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const SavedCasesActions = ({ isProcessing, onViewCase, onDeleteCase }) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <div className="position-absolute w-100 h-100 t-0 b-0 r-0 l-0 d-flex flex-column justify-content-between bg-white-opaque">
      <div>&nbsp;</div>
      <LoadingButton
        className="d-flex align-self-center"
        outline
        size="sm"
        color="denim-blue"
        spinnerColor="denim-blue"
        loading={isProcessing}
        disabled={isProcessing}
        onClick={onViewCase}>
        {i18n.t("SavedCases.viewCase")}
      </LoadingButton>
      <LoadingButton
        className="btn-text d-flex align-self-end bg-danger rounded-circle p-1 mr-1 mb-1"
        size="sm"
        color="light"
        spinnerColor="light"
        loading={isProcessing}
        disabled={isProcessing}
        onClick={onDeleteCase}>
        <CustomIcon className="icon-light" size={15} icon={"profile_trash"} />
      </LoadingButton>
    </div>
  );
};

SavedCasesActions.propTypes = {
  isProcessing: PropTypes.bool,
  onViewCase: PropTypes.func.isRequired,
  onDeleteCase: PropTypes.func.isRequired
};

export default SavedCasesActions;
