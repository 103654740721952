/** @format */

import { get, isEmpty } from "lodash";
import * as configurationDB from "../db/configuration-db";
import { getElasticSearchEndpoint } from "../utils/autocomplete-utils";
import ElasticSearchConfig from "../utils/ElasticSearchConfig";

const actionsPrefix = "configuration";

export const FETCH_CONFIGURATION = `${actionsPrefix}/FETCH_CONFIGURATION`;
export const FETCH_CONFIGURATION_COMPLETE = `${actionsPrefix}/FETCH_CONFIGURATION_COMPLETE`;
export const FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE = `${actionsPrefix}/FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE`;
export const DEFAULT_LISTENER_ON = `${actionsPrefix}/DEFAULT_LISTENER_ON`;
export const ELASTIC_SEARCH_LISTENER_ON = `${actionsPrefix}/ELASTIC_SEARCH_LISTENER_ON`;
export const LISTENERS_OFF = `${actionsPrefix}/LISTENERS_OFF`;

// TODO: Setup listener for elastic search
export const activateListener = () => {
  return async (dispatch, getState) => {
    try {
      const listeners = getState().configuration.listeners;

      if (!listeners.defaultListener) {
        const listener = await configurationDB.listenForChanges(
          (querySnapshot) => {
            // if there is no document in the collection, we are not removing them from redux since we want the user to still be able to browse cases
            if (querySnapshot && querySnapshot.exists) {
              dispatch({
                type: FETCH_CONFIGURATION_COMPLETE,
                payload: { data: querySnapshot.data() },
                error: false,
                message: "successfully updated configuration"
              });
            } else {
              console.log(
                "There is no firestore entry in the configuration collection"
              );
            }
          }
        );

        dispatch({
          type: DEFAULT_LISTENER_ON,
          listener: listener
        });
      }
    } catch (error) {
      console.log("ERROR: ", error.message);
      dispatch({
        type: FETCH_CONFIGURATION_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const activateElasticSearchConfigListener = () => {
  return async (dispatch, getState) => {
    const listeners = getState().configuration.listeners;

    try {
      if (!listeners.elasticSearchListener) {
        const elasticSearchListener = await configurationDB.listenForElasticSearchChanges(
          (querySnapshot) => {
            if (querySnapshot && querySnapshot.exists) {
              const data = querySnapshot.data();

              // Set global ElasticSearchConfig properties - This differs from mobile,
              // which queries the store for data. Currently, on web we are directly
              // calling elastic search instead of redux so we need to set this global.
              ElasticSearchConfig.apiToken = get(
                data,
                "elasticsearchAuth.access_token"
              );
              const cloudId = get(data, "elasticsearchCloudId");
              ElasticSearchConfig.endpointUrl = getElasticSearchEndpoint(
                cloudId
              );

              dispatch({
                type: FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE,
                payload: { data }
              });
            }
          }
        );

        dispatch({
          type: ELASTIC_SEARCH_LISTENER_ON,
          listener: elasticSearchListener
        });
      }
    } catch (error) {
      console.log("activateElasticSearchConfigListener error: ", error.message);
      dispatch({
        type: FETCH_CONFIGURATION_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const deactivateAllListeners = () => {
  return async (dispatch, getState) => {
    try {
      const listeners = getState().configuration.listeners;
      if (!isEmpty(listeners)) {
        Object.values(listeners).forEach((unsubscribe) => unsubscribe());

        dispatch({
          type: LISTENERS_OFF
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_CONFIGURATION_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};
