import { useState } from "react";
import { SHOW_BANNER_TIME } from "../constants/case-banner-constants";

export function useCaseBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const [bannerType, setBannerType] = useState(false);

  const handleShowBanner = (banner) => {
    setBannerType(banner);
    setShowBanner(true);
    setTimeout(() => {
      setShowBanner(false);
    }, SHOW_BANNER_TIME);
  };

  return { showBanner, bannerType, handleShowBanner };
}
