/** @format */

import { listenForTypeUpdate } from "../db/saved-cases.db";

const actionsPrefix = "saved-cases";

export const SAVED_CASES_UPDATE = `${actionsPrefix}/SAVED_CASES_UPDATE`;
export const SAVED_CASES_LISTENER_ON = `${actionsPrefix}/SAVED_CASES_LISTENER_ON`;
export const SAVED_CASES_LISTENER_OFF = `${actionsPrefix}/SAVED_CASES_LISTENER_OFF`;
export const SAVED_CASES_START = `${actionsPrefix}/SAVED_CASES_START`;
export const SAVED_CASES_CANCEL = `${actionsPrefix}/SAVED_CASES_CANCEL`;
export const SAVED_CASES_COMPLETE = `${actionsPrefix}/SAVED_CASES_COMPLETE`;

export const activateListener = (userUid, savedCasesType) => {
  return async (dispatch, getState) => {
    try {
      if (
        !getState().savedCases.listeners[savedCasesType] &&
        userUid &&
        savedCasesType
      ) {
        const listener = await listenForTypeUpdate(
          (querySnapshot) => {
            if (querySnapshot) {
              const nbDocs = querySnapshot.size;
              let newFeedContent = [];
              for (let docIndex = 0; docIndex < nbDocs; docIndex++) {
                const currentDoc = querySnapshot.docs[docIndex];
                newFeedContent.push({ ...currentDoc.data(), isSaved: true });
              }
              dispatch({
                type: SAVED_CASES_UPDATE,
                savedCasesType: savedCasesType,
                payload: newFeedContent
              });
            } else {
              console.log(
                "There is no firestore entry for this saved case type yet"
              );
            }
          },
          userUid,
          savedCasesType
        );

        // storing the listener's reference in redux
        dispatch({
          type: SAVED_CASES_LISTENER_ON,
          savedCasesType: savedCasesType,
          listener: listener
        });
      }
    } catch (error) {
      dispatch({
        type: SAVED_CASES_COMPLETE,
        savedCasesType: savedCasesType,
        error: true,
        message: error.message
      });
    }
  };
};

export const deactivateAllListeners = () => {
  return async (dispatch, getState) => {
    try {
      let listenerList = Object.values(getState().savedCases.listeners);
      for (let lIndex = 0; lIndex < listenerList.length; lIndex++) {
        listenerList[lIndex]();
      }
      dispatch({
        type: SAVED_CASES_LISTENER_OFF
      });
    } catch (error) {
      dispatch({
        type: SAVED_CASES_COMPLETE,
        savedCasesType: "all",
        error: true,
        message: error.message
      });
    }
  };
};
