import React from "react";
import { CardText, CardBody, CardImg } from "reactstrap";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import webSavedCasesImage from "../../../assets/images/web_saved_cases_image.png";
import i18n from "../../../utils/i18n";

const SavedCasesEmpty = () => {
  return (
    <CardBody className="bg-secondary text-denim-blue text-center text-15 rounded px-5 pb-0">
      <CustomIcon
        className="icon-denim-blue mb-0"
        icon="saved_cases_empty"
        size={40}
      />
      <CardText className="whitespace-pre-line px-3 pb-4 pt-1 mb-0">
        {i18n.t("SavedCases.noSavedCases")}
      </CardText>
      <CardImg
        top
        width="100%"
        src={webSavedCasesImage}
        alt="How to save a case"
      />
    </CardBody>
  );
};

SavedCasesEmpty.propTypes = {};

export default SavedCasesEmpty;
