/** @format */

const actionsPrefix = "nonUser";

export const NonUserActionTypes = {
  SIGN_UP: `${actionsPrefix}/SIGN_UP`,
  SIGN_UP_COMPLETE: `${actionsPrefix}/SIGN_UP_COMPLETE`,
  RESET_PASSWORD: `${actionsPrefix}/RESET_PASSWORD`,
  RESET_PASSWORD_COMPLETE: `${actionsPrefix}/RESET_PASSWORD_COMPLETE`,
  CHECK_AUTH: `${actionsPrefix}/CHECK_AUTH`,
  CHECK_AUTH_COMPLETE: `${actionsPrefix}/CHECK_AUTH_COMPLETE`,
  SET_ANON_DATA: `${actionsPrefix}/SET_ANON_DATA`
};

export default NonUserActionTypes;
