import React from "react";
import {
  Button,
  CustomInput,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { capitalize, get } from "lodash";
import PropTypes from "prop-types";
import { submitCaseUpdate } from "../../actions/case.actions";
import { FEED_FILTERS, FILTER_KEYS } from "../../constants/feed-filters";
import CustomIcon from "../custom-icon/CustomIcon";
import LoadingButton from "../common/LoadingButton";
import usePopup from "../popup/usePopup";
import TOAST_TYPE from "../popup/ToastType";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";

const CaseDetailUpdateModal = ({
  // Component specific props
  caseId,
  // Modal specific props
  toggle,
  isOpen,
  title,
  children,
  onSubmit,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const isProcessing = useSelector((state) =>
    get(state, ["case", "cases", caseId, "isProcessing"], false)
  );
  const caseContent = useSelector((state) =>
    get(state, ["case", "cases", caseId, "data"], {})
  );
  const resolved = (caseContent.labels || []).includes(
    i18n.t("FeedFilter.resolved").toLowerCase()
  );
  const { handleSubmit, errors, control } = useForm();

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const cancel = () => {
    toggle();
  };

  const save = async (values) => {
    try {
      const result = await dispatch(
        submitCaseUpdate(
          caseId,
          values.caption,
          values.status === FEED_FILTERS[FILTER_KEYS.filter_resolved].order
        )
      );

      if (!result.error) {
        toggle();
      } else {
        showToast({
          message: i18n.t("AddUpdateScreen.postFailed"),
          toastType: TOAST_TYPE.ERROR
        });
      }
    } catch (error) {
      console.log("Submit update error: ", error.message);
      showToast({
        message: i18n.t("AddUpdateScreen.postFailed"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Modal
      className="modal-profile modal-profile-edit-info"
      centered
      isOpen={isOpen}
      toggle={toggle}
      {...rest}>
      <ModalHeader
        className="border-0"
        toggle={toggle}
        close={
          <Button close onClick={toggle}>
            <CustomIcon icon="modal_exit" size={14} color={"black"} />
          </Button>
        }
      />
      <ModalBody className="h-400px">
        <FormGroup className="mb-3">
          <Label
            className="text-16 text-default-black font-weight-500 mw-75"
            for="caption">
            {i18n.t("AddUpdateScreen.prompt")}
          </Label>
          <Controller
            className={classNames(
              "form-control text-15 text-default-black",
              !!errors.caption && "is-invalid"
            )}
            as={TextareaAutosize}
            control={control}
            id="caption"
            name="caption"
            placeholder={i18n.t("AddUpdateScreen.placeholder")}
            type="textarea"
            defaultValue={""}
            minRows={6}
            rules={{ required: true }}
          />
          {!!errors.caption && (
            <FormFeedback>
              {i18n.t("AddUpdateScreen.informationRequired")}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup tag="fieldset" name="status" className="mb-3">
          <Label
            className="text-16 text-default-black font-weight-500 mw-75"
            for="status">
            {i18n.t("AddUpdateScreen.toggleTitle")}
          </Label>
          <Controller
            control={control}
            name="status"
            defaultValue={resolved ? 1 : 0}
            render={({ onChange, value }) =>
              [
                FEED_FILTERS[FILTER_KEYS.filter_unresolved],
                FEED_FILTERS[FILTER_KEYS.filter_resolved]
              ].map((s) => (
                <CustomInput
                  key={s.order}
                  type="radio"
                  id={`status${s.order}`}
                  name="status"
                  label={capitalize(s.name)}
                  value={s.order}
                  onChange={(e) =>
                    onChange(e.target.checked ? s.order : undefined)
                  }
                  checked={s.order === value}
                />
              ))
            }
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={cancel}>
          {capitalize(i18n.t("common.cancel"))}
        </Button>
        <LoadingButton
          color="primary"
          spinnerColor="light"
          loading={isProcessing}
          disabled={isProcessing}
          onClick={handleSubmit(save)}>
          {capitalize(i18n.t("common.post"))}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

CaseDetailUpdateModal.propTypes = {
  caseId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

CaseDetailUpdateModal.defaultProps = {};

export default CaseDetailUpdateModal;
