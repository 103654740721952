/** @format */

import {
  CACHE_USER_UPDATE_COMPLETE,
  CACHE_USER_UPDATE,
  CLEAR_ERROR,
  INITIALIZE_USER_COMPLETE,
  SAVE_ADMIN_SETTINGS_COMPLETE,
  SAVE_AUTH_BIOMETRIC_COMPLETE,
  SAVE_AUTH_NOT_SET_COMPLETE,
  SAVE_AUTH_PIN_COMPLETE,
  SAVE_DETAILS_COMPLETE,
  SAVE_NOTIFICATIONS_COMPLETE,
  SAVE_ONBOARDING_COMPLETE,
  SAVE_RELEASE_NOTES_COMPLETE,
  SAVE_USER_UPDATE_COMPLETE,
  SAVE_USER_UPDATE,
  SET_AUTH_SETTING,
  START_SAVE_DATA,
  UPDATE_USER_PERMISSIONS,
  USER_LISTENER_OFF,
  USER_LISTENER_ON,
  USER_UPDATE_EMAIL_COMPLETE,
  USER_UPDATE_EMAIL,
  FETCH_USER_INTERACTIVE_CASE_STATES,
  FETCH_USER_INTERACTIVE_CASE_STATES_COMPLETE,
  LISTEN_INTERACTIVE_CASE_STATE_ON,
  LISTEN_INTERACTIVE_CASE_STATE_OFF,
  UPDATE_INTERACTIVE_CASE_STATE,
  OPTIMISTIC_ADD_VOTE,
  OPTIMISTIC_RESET_VOTE,
  PROMO_CARDS_LISTENER_ON,
  PROMO_CARDS_LISTENER_OFF,
  PROMO_CARDS_UPDATE,
  PROMO_CARDS_REMOVE,
  PROMO_CARDS_REMOVE_COMPLETE
} from "../actions/user.actions.js";
import { SIGNOUT_COMPLETE } from "../actions/login.actions";
import { userIsVerified } from "../utils/verification-utils.js";
import { QUESTION_CONTENT_TYPES } from "../constants/content-type.js";
import INTERACTIVE_CASE_STATES from "../constants/interactive-case-states.js";
import { isEmpty } from "lodash";
import { UPDATE_USER_PROFILE_COMPLETE } from "../actions/user-profiles.actions.js";
import { POST_VERIFICATION_REQUEST_COMPLETE } from "../actions/user-verification.actions.js";

export const INITIAL_STATE = {
  initialized: false,
  secondaryAuthType: null,
  saving: false,
  userUpdate: {},
  error: false,
  message: null,
  listenerIsOn: false,
  listener: null,
  verification: {
    verificationStatus: null
  },
  topics: [],
  verified: false,
  interactiveCaseStates: {},
  isFetchingInteractiveCaseState: false,
  country: {},
  promoCards: {
    unsubscribe: null,
    data: [],
    deleting: false
  }
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_USER_COMPLETE: {
      const user = action.user || {};
      return {
        ...state,
        ...user,
        initialized: true,
        verified: userIsVerified(user)
      };
    }
    case POST_VERIFICATION_REQUEST_COMPLETE:
    case UPDATE_USER_PROFILE_COMPLETE: {
      const user = action.user || {};
      return {
        ...state,
        ...user
      };
    }
    case SAVE_DETAILS_COMPLETE:
      return {
        ...state,
        ...action.values,
        saving: false
      };
    case SAVE_NOTIFICATIONS_COMPLETE:
      return {
        ...state,
        settings: action.settings,
        saving: false
      };
    case SIGNOUT_COMPLETE:
      return {
        ...INITIAL_STATE
      };
    case SET_AUTH_SETTING:
      return {
        ...state,
        secondaryAuthType: action.secondaryAuthType,
        saving: false
      };
    case SAVE_AUTH_PIN_COMPLETE:
    case SAVE_AUTH_BIOMETRIC_COMPLETE:
    case SAVE_AUTH_NOT_SET_COMPLETE:
      return {
        ...state,
        secondaryAuthType: action.secondaryAuthType,
        saving: false
      };
    case SAVE_ONBOARDING_COMPLETE:
      return {
        ...state,
        onboardingComplete: action.onboardingComplete,
        viewedReleaseNotes: action.viewedReleaseNotes,
        saving: false
      };
    case SAVE_ADMIN_SETTINGS_COMPLETE:
      return {
        ...state,
        userType: action.userType,
        stores: action.stores,
        saving: false
      };
    case START_SAVE_DATA:
      return {
        ...state,
        saving: true
      };
    case SAVE_RELEASE_NOTES_COMPLETE:
      return {
        ...state,
        viewedReleaseNotes: action.viewedReleaseNotes,
        saving: false
      };
    case UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        isAuthorized: action.authorized,
        isReadOnly: action.readOnly
      };
    case CACHE_USER_UPDATE:
      return {
        ...state,
        saving: true
      };
    case CACHE_USER_UPDATE_COMPLETE:
      return {
        ...state,
        userUpdate: { ...state.userUpdate, ...action.userUpdate },
        error: action.error,
        message: action.message,
        saving: false
      };
    case SAVE_USER_UPDATE:
      return {
        ...state,
        saving: true
      };
    case SAVE_USER_UPDATE_COMPLETE:
      return {
        ...state,
        saving: false,
        error: action.error,
        message: action.message
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: false
      };
    case USER_LISTENER_ON:
      return {
        ...state,
        listenerIsOn: true,
        listener: action.payload.listener
      };
    case USER_LISTENER_OFF:
      return {
        ...state,
        listenerIsOn: false,
        listener: null
      };
    case USER_UPDATE_EMAIL:
      return {
        ...state,
        saving: true
      };
    case USER_UPDATE_EMAIL_COMPLETE:
      return {
        ...state,
        saving: false,
        error: action.error,
        message: action.message
      };
    case FETCH_USER_INTERACTIVE_CASE_STATES:
      return {
        ...state,
        isFetchingInteractiveCaseState: true
      };
    case FETCH_USER_INTERACTIVE_CASE_STATES_COMPLETE:
      const interactiveCaseStates = action.payload.interactiveCaseStates;
      const formatted = {};
      Object.keys(interactiveCaseStates || {}).forEach((caseUuid) => {
        formatted[caseUuid] = determineCaseProgress(
          interactiveCaseStates[caseUuid]
        );
      });

      return {
        ...state,
        isFetchingInteractiveCaseState: false,
        interactiveCaseStates: {
          ...state.interactiveCaseStates,
          ...formatted
        }
      };
    case LISTEN_INTERACTIVE_CASE_STATE_ON:
    case LISTEN_INTERACTIVE_CASE_STATE_OFF:
      return setInteractiveCaseListener(state, action);
    case UPDATE_INTERACTIVE_CASE_STATE:
      return updateInteractiveCase(state, action);
    case OPTIMISTIC_ADD_VOTE:
      return handleOptimisticAddVote(state, action);
    case OPTIMISTIC_RESET_VOTE:
      return handleOptimisticResetVote(state, action);

    case PROMO_CARDS_LISTENER_ON:
    case PROMO_CARDS_LISTENER_OFF:
      return {
        ...state,
        promoCards: {
          ...state.promoCards,
          unsubscribe: action.payload.unsubscribe
        }
      };
    case PROMO_CARDS_UPDATE:
      return {
        ...state,
        promoCards: {
          ...state.promoCards,
          data: action.payload.promoCards,
          validCards: action.payload.validCards
        }
      };
    case PROMO_CARDS_REMOVE:
      return {
        ...state,
        promoCards: {
          ...state.promoCards,
          deleting: true
        }
      };
    case PROMO_CARDS_REMOVE_COMPLETE:
      return {
        ...state,
        promoCards: {
          ...state.promoCards,
          deleting: false
        }
      };
    default:
      return state;
  }
};

export default userReducer;

const updateInteractiveCase = (state, action) => {
  const { caseUuid, newState } = action.payload;

  /**
   * Handle case where use taps to see not started
   * interactive case in detail, but doesn't answer.
   */
  if (isEmpty(newState)) {
    return state;
  }

  const processed = determineCaseProgress(newState);

  return {
    ...state,
    interactiveCaseStates: {
      ...state.interactiveCaseStates,
      [caseUuid]: {
        ...(state.interactiveCaseStates[caseUuid] || {}),
        ...processed
      }
    }
  };
};

const setInteractiveCaseListener = (state, action) => {
  const { caseUuid, listener = null } = action.payload;

  return {
    ...state,
    interactiveCaseStates: {
      ...state.interactiveCaseStates,
      [caseUuid]: {
        ...(state.interactiveCaseStates[caseUuid] || {}),
        listener
      }
    }
  };
};

/**
 * Note: This is just useful data for feed data cards to
 * show in-progress/completed interactive cases.
 */
const determineCaseProgress = (_case) => {
  let numQuestions = 0;
  let numAnswered = 0;
  let numCorrect = 0;
  const currentQuestion = {};

  // Remove non-interactive slides (like quiz summary) and return a more easily workable array of case data
  const onlyQuestionContent = Object.values(_case).filter((value) =>
    QUESTION_CONTENT_TYPES.includes(value?.contentType)
  );

  // Create a list of content uuids sorted by display order.
  const sorted = onlyQuestionContent.sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  sorted.forEach((content) => {
    const { options, userVote, displayOrder, contentUuid } = content;
    numQuestions += 1;

    if (userVote) {
      numAnswered += 1;

      const selectedOption = options.find(
        (option) => option.questionOptionUuid === userVote
      );
      if (selectedOption && selectedOption.isAnswer) {
        numCorrect += 1;
      }
    } else {
      // Find the first question a user is on, based off a lowest display order
      if (isEmpty(currentQuestion)) {
        currentQuestion.displayOrder = displayOrder;
        currentQuestion.contentUuid = contentUuid;
      }
    }
  });

  // Determine status
  let status = INTERACTIVE_CASE_STATES.NOT_STARTED;
  if (numAnswered === numQuestions) {
    status = INTERACTIVE_CASE_STATES.COMPLETED;
  } else if (numAnswered > 0 && numAnswered < numQuestions) {
    status = INTERACTIVE_CASE_STATES.IN_PROGRESS;
  }

  return {
    numQuestions,
    numAnswered,
    numCorrect,
    currentQuestion,
    sorted: sorted.map((content) => content.contentUuid), // Keep it to just content uuids so we don't have duplicate data
    status,
    ..._case
  };
};

/**
 * Optimistically update userVote state for given case and content uuid. If case has
 * no progress, just create a placeholder state, it will be updated once
 * Firebase is updated.
 */
const handleOptimisticAddVote = (state, action) => {
  const { caseUuid, contentUuid, questionOptionUuid } = action.payload;

  const oldCase = state.interactiveCaseStates[caseUuid];

  if (oldCase) {
    const updated = {
      ...oldCase,
      [contentUuid]: {
        ...oldCase[contentUuid],
        userVote: questionOptionUuid
      }
    };

    return {
      ...state,
      interactiveCaseStates: {
        ...state.interactiveCaseStates,
        [caseUuid]: updated
      }
    };
  } else {
    return {
      ...state,
      interactiveCaseStates: {
        ...state.interactiveCaseStates,
        [caseUuid]: {
          [contentUuid]: {
            userVote: questionOptionUuid
          }
        }
      }
    };
  }
};

/**
 * Optimistically reset userVote state for given case and content uuid.
 * It will be updated more accurately and with all data once Firebase
 * is updated.
 */
const handleOptimisticResetVote = (state, action) => {
  const { caseUuid, contentUuid } = action.payload;

  const oldCase = state.interactiveCaseStates[caseUuid];
  const updated = {
    ...oldCase,
    [contentUuid]: {
      ...oldCase[contentUuid],
      userVote: null
    }
  };

  return {
    ...state,
    interactiveCaseStates: {
      ...state.interactiveCaseStates,
      [caseUuid]: updated
    }
  };
};
