import { isEmpty } from "lodash";
import { useMemo, useRef } from "react";

const useISI = (isi, hidden) => {
  const isiHeaderRef = useRef(null);
  return useMemo(
    () => ({
      isiHeaderRef: isiHeaderRef,
      showHeader: !isEmpty(isi?.isiText) && !hidden,
      showFooter: !isEmpty(isi?.isiEmbeddedContentLink) && !hidden
    }),
    [isi, hidden]
  );
};

export default useISI;
