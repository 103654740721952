import { useDispatch } from "react-redux";
import {
  setGatedMessageOrigin,
  setGatedMessageType
} from "../actions/global.actions";
import useIsGated from "./useIsGated";
import useIsSponsored from "./useIsSponsored";

function useGatedActionGate(
  cb,
  actionType,
  originalAction,
  ignoreSponsorFlag = false
) {
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();

  const dispatch = useDispatch();

  const triggerGatedModal = () => {
    dispatch(setGatedMessageType(actionType));
    dispatch(setGatedMessageOrigin(originalAction));
    return false;
  };

  if (isGated && ignoreSponsorFlag) {
    return triggerGatedModal;
  } else if (!isGated || (isGated && !isSponsored)) {
    return cb;
  }
  return triggerGatedModal;
}

export default useGatedActionGate;
