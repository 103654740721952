/** @format */

import { isEmpty, split } from "lodash";
import { decode } from "base-64";
import ElasticSearchConfig from "./ElasticSearchConfig";

export const getElasticSearchEndpoint = (cloudId) => {
  if (!isEmpty(cloudId)) {
    const cloudUrls = decode(split(cloudId, ":")[1])
      .toString()
      .split("$");

    return `https://${cloudUrls[1]}.${cloudUrls[0]}`;
  }

  return null;
};

export const fetchSearchSuggestions = async ({
  accessToken,
  cloudId,
  searchTerms
}) => {
  const url = getElasticSearchEndpoint(cloudId);

  const response = await fetch(
    `${url}/public_search_terms/_search?_source_includes=text`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        size: 20,
        suggest: {
          suggestion: {
            prefix: searchTerms,
            completion: { field: "typeAhead", skip_duplicates: "true" }
          }
        }
      })
    }
  );

  return await response.json();
};

export const fetchSchools = async (searchTerm) => {
  if (isEmpty(ElasticSearchConfig.apiToken)) {
    return {};
  }

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${ElasticSearchConfig.apiToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      size: 20,
      query: {
        multi_match: {
          query: searchTerm,
          type: "bool_prefix",
          fields: [
            "schoolCompletions",
            "schoolCompletions._2gram",
            "schoolCompletions._3gram"
          ]
        }
      }
    })
  };

  const response = await fetch(
    `${ElasticSearchConfig.endpointUrl}/public_schools/_search`,
    options
  );

  return response.json();
};

/**
 * must.multi_match.query:
 * - Must have a search term for `query` to work!
 * - Search term supports searching for parts of school names as well as their abbreviations.
 *
 * filter.term.professions:
 * - optional
 * - actually only supports a string, which must be a single profession uuid! Watch out!
 *
 * filter.term.countries:
 * - optional
 * - supports filtering country, province/state and city.
 *
 * Misc:
 * must.multi_match.fields: are required to get sane results.
 */
export const fetchSchoolSuggestions = async ({
  accessToken,
  cloudId,
  professionUuid,
  searchTerm,
  locationUuid
}) => {
  const url = getElasticSearchEndpoint(cloudId);

  const filter = [
    {
      term: {
        professions: professionUuid
      }
    }
  ];

  if (locationUuid) {
    filter.push({
      term: {
        countries: locationUuid
      }
    });
  }

  const computedHeaders = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    : {
        "Content-Type": "application/json"
      };

  try {
    const response = await fetch(`${url}/public_schools/_search`, {
      method: "POST",
      headers: computedHeaders,
      body: JSON.stringify({
        query: {
          bool: {
            filter,
            must: [
              {
                multi_match: {
                  query: searchTerm,
                  type: "bool_prefix",
                  fields: [
                    "schoolCompletions",
                    "schoolCompletions._2gram",
                    "schoolCompletions._3gram"
                  ]
                }
              }
            ]
          }
        }
      })
    });

    return await response.json();
  } catch (error) {
    console.log("Error while fetching schools: ", error);
    return { error: "No Results Found" };
  }
};
