import React, { useEffect } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { useHistory, useLocation } from "react-router-dom";
import { setRedirectRoute } from "../../actions/login.actions";
import { useDispatch } from "react-redux";
import RegistrationFormV2 from "./components/RegistrationForm.v2";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import i18n from "../../utils/i18n";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";

const CreateRegistrationPageV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const loc = useLocation();

  const urlParams = new URLSearchParams(loc.search);
  const redirect = urlParams.get("redirect");

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirectRoute(redirect));
      history.replace(loc.pathname, {});
    }
  }, [history, dispatch, loc, redirect]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <>
            <div className="mt-0 mt-lg-5 shadow">
              <RegistrationFormV2 />
            </div>
            <p className="px-5 px-lg-0 text-15 color-battleship-gray text-center">
              {i18n.t("RegistrationScreens.createRegistration.v2.bySigningUp")}{" "}
              <ExternalTextLink
                className="text-cool-blue cursor-pointer"
                url={StaticStrings.termsOfServiceUrl}
                linkText={i18n.t(
                  "RegistrationScreens.loginForm.termsAndConditions"
                )}
              />
            </p>
          </>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default CreateRegistrationPageV2;
