import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { FormGroup, Label, FormText } from "reactstrap";
import { Controller, useWatch } from "react-hook-form";

import i18n from "../../../utils/i18n";
import FormFeedback from "reactstrap/lib/FormFeedback";

const TitleSummaryInputs = ({ draft, control, errors }) => {
  const MAX_TITLE_CHAR = 100;
  const MAX_SUMMARY_CHAR = 2500;

  const fieldWatch = useWatch({
    control,
    name: ["title", "caption"],
    defaultValue: {
      title: "",
      caption: ""
    }
  });

  const titleContents = fieldWatch ? fieldWatch.title : null;
  const captionContents = fieldWatch ? fieldWatch.caption : null;
  const remainTitleChar = titleContents
    ? MAX_TITLE_CHAR - titleContents.length
    : MAX_TITLE_CHAR;
  const remainSummaryChar = captionContents
    ? MAX_SUMMARY_CHAR - captionContents.length
    : MAX_SUMMARY_CHAR;

  const [titleError, setTitleError] = useState(null);
  const [captionError, setCaptionError] = useState(null);

  useEffect(() => {
    if (errors.title) {
      switch (errors.title.type) {
        case "maxLength":
          setTitleError("shareCase.titleLength");
          break;
        case "required":
        default:
          setTitleError("shareCase.titleRequired");
          break;
      }
    }
  }, [errors.title]);

  useEffect(() => {
    if (errors.caption) {
      switch (errors.caption.type) {
        case "maxLength":
          setCaptionError("shareCase.caseSummaryLength");
          break;
        case "required":
        default:
          setCaptionError("shareCase.caseSummaryRequired");
          break;
      }
    }
  }, [errors.caption]);

  return (
    <>
      <FormGroup>
        <Label for="title">{i18n.t("shareCase.titleLabel")}</Label>
        <Controller
          control={control}
          name="title"
          rules={{
            required: true,
            maxLength: MAX_TITLE_CHAR
          }}
          invalid={!!errors.title ? 1 : 0}
          as={
            <TextareaAutosize
              className={
                errors.title
                  ? "autoresize-textarea is-invalid"
                  : "autoresize-textarea"
              }
              minRows={1}
              maxRows={2}
              placeholder={i18n.t("shareCase.titlePlaceholder")}
            />
          }
          defaultValue={draft?.title || ""}
        />
        <FormFeedback>{i18n.t(titleError)}</FormFeedback>
        <FormText color="muted">
          {remainTitleChar} {i18n.t("shareCase.remainingChars")}
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label for="caption">{i18n.t("shareCase.caseSummaryLabel")}</Label>
        <Controller
          control={control}
          name="caption"
          rules={{
            required: true,
            maxLength: MAX_SUMMARY_CHAR
          }}
          invalid={!!errors.caption ? 1 : 0}
          as={
            <TextareaAutosize
              style={{
                lineHeight: "1.3rem"
              }}
              minRows={4}
              maxRows={6}
              className={
                errors.caption
                  ? "autoresize-textarea is-invalid"
                  : "autoresize-textarea"
              }
              placeholder={i18n.t("shareCase.caseSummaryPlaceholder")}
            />
          }
          defaultValue={draft?.caption || ""}
        />
        <FormFeedback>{i18n.t(captionError)}</FormFeedback>
        <FormText color="muted">
          {remainSummaryChar} {i18n.t("shareCase.remainingChars")}
        </FormText>
      </FormGroup>
    </>
  );
};

export default TitleSummaryInputs;
