/** @format */

import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";
import classNames from '../../utils/class-names-utils';

const F1SectionList = ({ sections, renderItem, className }) => {
  const suggestions = sections.reduce((acc, section, sectionIndex) => {
    const items = (
      <ListGroup key={`section-${section.title}-${sectionIndex}`}>
        {section.data.map((item, index) =>
          renderItem({ section, item, index })
        )}
      </ListGroup>
    );

    acc.push(items);

    return acc;
  }, []);

  return (
    <div className={classNames("f1-section-list", className)}>
      {suggestions}
    </div>
  );
};

F1SectionList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.any)
    })
  ).isRequired,
  renderItem: PropTypes.func,
  className: PropTypes.string,
};

export default F1SectionList;
