/** @format */

import { RESET_ALL } from "../actions/global.actions";
import NonUserActionTypes from "../actions/non-user-action-types";

const INITIAL_STATE = {
  isProcessing: false,
  error: false,
  message: null,
  anonUserUuid: null,
  anonUserEmail: null
};

const nonUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ALL:
      return { ...state, isProcessing: false, error: false, message: null };

    case NonUserActionTypes.SIGN_UP:
    case NonUserActionTypes.RESET_PASSWORD:
    case NonUserActionTypes.CHECK_AUTH:
      return {
        ...state,
        error: false,
        message: null,
        isProcessing: true
      };
    case NonUserActionTypes.SIGN_UP_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        signUpResult: action.signUpResult,
        error: action.error,
        message: action.message,
        anonUserUuid: null,
        anonUserEmail: null
      };
    case NonUserActionTypes.RESET_PASSWORD_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message
      };
    case NonUserActionTypes.CHECK_AUTH_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        loginResult: action.loginResult,
        error: action.error,
        message: action.message
      };
    case NonUserActionTypes.SET_ANON_DATA:
      const newData = {};

      if (action.userUuid) {
        newData.anonUserUuid = action.userUuid;
      }
      if (action.email) {
        newData.anonUserEmail = action.email;
      }

      return {
        ...state,
        ...newData
      };
    default:
      return state;
  }
};

export default nonUserReducer;
