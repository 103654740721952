import React from "react";
import CaseLabelButton from "./CaseLabelButton";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";
import FormFeedback from "reactstrap/lib/FormFeedback";

const CaseLabelGroup = ({
  control,
  caseLabels,
  draftLabels,
  label,
  getValues,
  errors,
  requiredText
}) => {
  /** ********************************** CONFIG ***************************************/
  const validClass =
    errors?.filter((e) => !!e).length === caseLabels?.length
      ? "button-list is-invalid"
      : "button-list";

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const validateLabels = () => {
    // do your validation here check one of them are checked and return true to pass validation
    const labelsArr = getValues()?.caseLabels;
    return labelsArr.filter((v) => !!v).length > 0;
  };

  /** ********************************** RENDER ***************************************/
  return (
    <FormGroup tag="fieldset" name="caseLabels">
      <Label
        className="text-16 font-weight-500 text-default-black mb-3"
        for="caseLabels">
        {label}
      </Label>
      <div className={validClass}>
        {(caseLabels ?? []).map(
          ({ labelUuid, name: labelName }, labelIndex) => (
            <Controller
              key={labelUuid}
              control={control}
              name={`caseLabels[${labelIndex}]`}
              rules={{
                validate: validateLabels
              }}
              defaultValue={
                (draftLabels || []).find(
                  (draftLabelUuid) => draftLabelUuid === labelUuid
                ) || ""
              }
              render={({ onChange, value }) => (
                <CaseLabelButton
                  key={labelUuid}
                  labelItem={{
                    key: labelUuid,
                    name: labelName,
                    active: labelUuid === value
                  }}
                  handleClick={() =>
                    onChange(labelUuid === value ? undefined : labelUuid)
                  }
                />
              )}
            />
          )
        )}
      </div>
      <FormFeedback>{requiredText}</FormFeedback>
    </FormGroup>
  );
};

CaseLabelGroup.propTypes = {
  control: PropTypes.any,
  caseLabels: PropTypes.array,
  draftLabels: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string
};

export default CaseLabelGroup;
