/** @format */

import React from "react";
import PropTypes from "prop-types";
import { DRAFT_STATES } from "../../../constants/case-constants";
import i18n from "../../../utils/i18n";
import { truncate } from "lodash";

const DraftListItemText = ({ title, caption, state }) => {
  const truncTitle = title ? truncate(title, { length: 32 }) : null;
  const truncCaption = caption ? truncate(caption, { length: 70 }) : null;
  return (
    <p className="mb-0">
      <>
        {!!truncTitle && (
          <div className="text-break font-weight-bold">{truncTitle}</div>
        )}
        {!!truncCaption && <div className="text-break">{truncCaption}</div>}
      </>
      {state === DRAFT_STATES.REJECTED ? (
        <>
          <div className="">{i18n.t("DraftsScreen.edit")}</div>
        </>
      ) : !truncTitle && !truncCaption ? (
        <div className="">{i18n.t("DraftsScreen.continueEditing")}</div>
      ) : (
        ""
      )}
    </p>
  );
};

DraftListItemText.propTypes = {
  title: PropTypes.string,
  caption: PropTypes.string,
  state: PropTypes.oneOf(Object.values(DRAFT_STATES))
};

export default DraftListItemText;
