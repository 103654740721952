import React, { useEffect, useState } from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import CaseReactions from "./CaseReactions";
import { Link } from "react-router-dom";
import _ from "lodash";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import i18n from "../../utils/i18n";
import useComments from "../comments/useComments";
import { getCaseTypeUrl } from "../../utils/case-utils";
import SaveCaseButton from "./SaveCaseButton";
import { keysToCamelCase } from "../../utils/camelCaseUtils";
import { defaultFeatures } from "../../constants/case-feature-constants";

const SocialContainer = ({
  caseContent,
  selectedReaction,
  hideComments,
  hideReactions,
  position,
  socialContext,
  caseLinkUrl,
  caseNotification,
  disableClick,
  userReactions = [],
  activateListener = true,
  hideSave = true,
  iconSize = 30,
  className = "d-flex w-100 justify-content-between align-items-center h-40px row no-gutters"
}) => {
  /** ********************************** CONFIG ***************************************/

  const { theme } = useTheme();
  const [commentText, setCommentText] = useState(null);
  const caseLink = caseLinkUrl || getCaseTypeUrl(caseContent);
  const { features = defaultFeatures } = keysToCamelCase(caseContent);

  const { saveEnabled } = features;
  const { commentStatus } = useComments(
    caseContent.caseUuid,
    caseContent.contentUuid,
    activateListener
  );

  const commentCount = activateListener
    ? commentStatus?.allCount
    : caseContent.commentCount;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (commentCount === undefined || commentCount === 0) {
      setCommentText(i18n.t("common.noComments"));
    } else if (commentCount === 1) {
      setCommentText(i18n.t("common.comment"));
    } else {
      setCommentText(
        // eslint-disable-next-line no-template-curly-in-string
        i18n.t("common.comments").replace("${COUNT}", commentCount)
      );
    }
  }, [commentCount]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <div className={className}>
      {hideComments ? (
        <span />
      ) : (
        <Link
          className="col"
          to={{
            pathname: caseLink,
            state: {
              position
            }
          }}
          onClick={(e) => {
            if (disableClick) {
              e.preventDefault();
            }
          }}
          style={{ textDecoration: "none" }}>
          <div className="d-flex align-items-center">
            <CustomIcon
              className={classNames(
                theme === THEMES.DARK ? "icon-white" : "icon-battleship-gray"
              )}
              icon="settings_bubble"
              size={iconSize}
            />
            <span
              className={classNames(
                "pl-1 font-weight-500",
                theme === THEMES.DARK ? "text-white" : "text-battleship-gray"
              )}>
              {commentText}
            </span>
          </div>
        </Link>
      )}
      {!hideReactions && (
        <CaseReactions
          caseContent={caseContent}
          position={position}
          reactionContext={socialContext}
          selectedReaction={
            selectedReaction ?? _.get(userReactions, "[0]", null)
          }
        />
      )}
      {!hideSave && saveEnabled && (
        <SaveCaseButton
          className="col d-flex justify-content-end"
          caseContent={caseContent}
          caseNotification={caseNotification}
          commentStatus={commentStatus}
        />
      )}
    </div>
  );
};

export default SocialContainer;
