/** @format */

import React from "react";
import PropTypes from "prop-types";
import classNames from "../../utils/class-names-utils";

const F1MasonryGrid = ({
  data,
  renderItem,
  ListHeaderComponent,
  ListFooterComponent,
  className,
  numColumns = { xs: 1, lg: 2 },
  spacing = 3
}) => {
  return (
    <div className={classNames("f1-masonry-grid", className)}>
      {ListHeaderComponent && <ListHeaderComponent />}
      <div
        className={`column-count-${numColumns.xs} column-count-lg-${numColumns.lg} column-gap-${spacing} column-fill-initial`}>
        {data.map((item, index) => {
          const renderedItem = renderItem({ item, index });
          return (
            renderedItem && (
              <div
                className={`d-inline-block align-top w-100 mb-${spacing}`}
                key={index}>
                {renderedItem}
              </div>
            )
          );
        })}
      </div>
      {ListFooterComponent && <ListFooterComponent />}
    </div>
  );
};

F1MasonryGrid.propTypes = {
  /**
   * The data to be rendered
   */
  data: PropTypes.arrayOf(PropTypes.any).isRequired,

  /**
   * Takes an item from data and renders it into the grid.
   */
  renderItem: PropTypes.func.isRequired,

  /**
   * Rendered at the very end of the list.
   */
  ListFooterComponent: PropTypes.func,

  /**
   * Any additional class names to be added to the component.
   */
  className: PropTypes.string,

  /**
   * The number of columns within the grid.
   */
  numColumns: PropTypes.shape({
    /**
     * The number of columns on screens up to 'lg'
     */
    xs: PropTypes.number.isRequired,
    /**
     * The number of columns on large screens and above
     */
    lg: PropTypes.number.isRequired
  }),

  /**
   * The amount of spacing between the grid columns and items.
   */
  spacing: PropTypes.number
};

export default F1MasonryGrid;
