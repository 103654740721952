import React from "react";
import { Button, Card, CardBody, CardText } from "reactstrap";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import i18n from "../../utils/i18n";

const EmptyProfileFeed = ({ message, action, actionText, icon }) => {
  return (
    <div className="d-flex flex-grow-1 bg-white">
      <Card className="m-5 border-0 w-100">
        <CardBody className="bg-secondary text-denim-blue text-center rounded p-5">
          {icon && (
            <CustomIcon
              className="mb-3"
              icon={icon}
              color="#3f6296"
              size={41}
            />
          )}
          <CardText className="text-16 mb-4">{i18n.t(message)}</CardText>
          {action && (
            <Button className="px-5 py-2" color="primary" onClick={action}>
              {i18n.t(actionText)}
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EmptyProfileFeed;
