import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LoadingButton from "./LoadingButton";
import useFollowUser from "../../hooks/useFollowUser";

const FollowUserButton = ({
  userUuid,
  username,
  hasAvatar,
  context,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  const { isCurrentUser, isFollowing, isProcessing, onFollowClick } =
    useFollowUser({
      userUuid,
      hasAvatar,
      username,
      context
    });

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  if (isCurrentUser) {
    return null;
  }

  return (
    <LoadingButton
      size="lg"
      color="primary"
      spinnerSize="sm"
      spinnerColor={isFollowing ? "primary" : "light"}
      outline={isFollowing}
      disabled={isProcessing}
      loading={isProcessing}
      onClick={onFollowClick}
      {...rest}>
      {isFollowing ? t("common.unfollow") : t("common.follow")}
    </LoadingButton>
  );
};

FollowUserButton.propTypes = {
  userUuid: PropTypes.string.isRequired
};

FollowUserButton.defaultProps = {};

export default FollowUserButton;
