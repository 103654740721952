import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PreviewStructure from "./PreviewStructure";
import NavFeedFilter from "./NavFeedFilter";
import CategoryStructure from "./CategoryStructure";
import ExploreCategoryCTA from "./ExploreCategoryCTA";
import i18n from "../../utils/i18n";
import AutoSizer from "../common/AutoSizer";
import useIsRecommendedFeed from "../../hooks/useIsRecommendedFeed";
import { useDispatch } from "react-redux";
import { getPreviewFeedMetaIfNeeded } from "../../actions/reference.actions";
import { FEED_META_LABELS } from "../../constants/feed-type";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import GroupsNavigation from "./GroupsNavigation";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import useAppPrompt from "../app-prompt/useAppPrompt";

const ChannelNavigation = ({ activeFeed }) => {
  /****************************** Config **************************************/
  const isRecommended = useIsRecommendedFeed();
  const dispatch = useDispatch();
  const feedTypeUuid = useCurrentFeedTypeUuid();
  const hasGroups = useUserHasGroups();
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();

  /****************************** Hooks ***************************************/

  useEffect(() => {
    dispatch(getPreviewFeedMetaIfNeeded());
  }, [dispatch]);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <AutoSizer
      className={`feed-sidebar-left z-1 mt-0 sticky-top position-lg-fixed overflow-y-lg-auto b-lg-3 px-2`}
      style={isAppPromptShown && { marginBottom: appPromptHeight }}>
      {
        <div>
          {hasGroups && <GroupsNavigation />}
          <NavFeedFilter currentFeed={feedTypeUuid} />
          {isRecommended && (
            <>
              <hr />
              <h2 className="text-14 text-battleship-gray helv-bold">
                {i18n.t("navigation.explore")}
              </h2>
              <ExploreCategoryCTA
                feedLabel={FEED_META_LABELS.EVERYTHING}
                title={i18n.t("ExploreCategoryCTA.newCaseTitle")}
                description={i18n.t("ExploreCategoryCTA.newCaseDescription")}
              />
              <ExploreCategoryCTA
                feedLabel={FEED_META_LABELS.TRENDING}
                title={i18n.t("ExploreCategoryCTA.trendingCaseTitle")}
                description={i18n.t(
                  "ExploreCategoryCTA.trendingCaseDescription"
                )}
              />

              <CategoryStructure activeFeed={activeFeed} />
              <PreviewStructure activeFeed={activeFeed} />
            </>
          )}
        </div>
      }
    </AutoSizer>
  );
};

ChannelNavigation.propTypes = {
  activeFeed: PropTypes.string
};

export default ChannelNavigation;
