import React, { useEffect } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import i18n from "../../utils/i18n";
import RegistrationHeader from "../authentication/components/RegistrationHeader";
import RegistrationForm from "../authentication/components/RegistrationForm";
import { useContactSupport } from "../../hooks/useContactSupport";
import { useHistory, useLocation } from "react-router-dom";
import { setRedirectRoute } from "../../actions/login.actions";
import { useDispatch } from "react-redux";

const CreateRegistrationPage = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const onContactSupport = useContactSupport();
  const dispatch = useDispatch();
  const loc = useLocation();

  const urlParams = new URLSearchParams(loc.search);
  const redirect = urlParams.get("redirect");

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirectRoute(redirect));
      history.replace(loc.pathname, {});
    }
  }, [history, dispatch, loc, redirect]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout
      noGatedHeader={true}
      topHeader={<RegistrationHeader onContactSupport={onContactSupport} />}>
      <p className="bg-gradient h-200px text-white text-20 d-flex flex-column justify-content-center align-items-center m-0 registration-header-text">
        {i18n.t("RegistrationScreens.createRegistration.title")}
      </p>
      <Figure1Page1Col
        mainContentSmall={
          <div>
            <RegistrationForm />
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default CreateRegistrationPage;
