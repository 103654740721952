import React from "react";
import PropTypes from "prop-types";
import BaseDialog from "./BaseDialog";
import BaseDialogButton from "./BaseDialogButton";
import DIALOG_TYPE from "./DialogType";
import BaseDialogHeader from "./BaseDialogHeader";
import BaseDialogBody from "./BaseDialogBody";
import BaseDialogFooter from "./BaseDialogFooter";
import CustomIcon from "../custom-icon/CustomIcon";

const Dialog = ({
  // Header Props
  title,
  subtitle,
  icon,

  // Body Props
  body,

  // Confirm Button Props
  confirmText,
  confirmLoading,
  confirmDisabled,

  // Cancel Button Props
  cancelText,
  cancelLoading,
  cancelDisabled,
  onCancelClick,

  // Base Dialog Props
  type,
  isOpen,
  onConfirm,
  onCancel,
  onClosed,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const isInformDialog = type === DIALOG_TYPE.INFORM;
  const isGatedDialog = type === DIALOG_TYPE.GATED;
  const isConfirmReversedDialog = type === DIALOG_TYPE.CONFIRM_REVERSED;

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const handleCancelClick = (e) => {
    if (typeof onCancelClick === "function") onCancelClick(e);
    onCancel(e);
  };

  /************************************ RENDER ***************************************/

  return (
    <BaseDialog
      centered
      isOpen={isOpen}
      onCancel={onCancel}
      onClosed={onClosed}
      {...rest}>
      <BaseDialogHeader center={!isInformDialog} onCancel={onCancel}>
        {icon && <CustomIcon icon="email_icon" color="#1a252b" size={40} />}
        {title && <h4 className="mb-0 font-weight-bold">{title}</h4>}

        {subtitle && (
          <h5 className="mb-0 mt-2 text-16 line-height-tight">{subtitle}</h5>
        )}
      </BaseDialogHeader>

      <BaseDialogBody center={!isInformDialog}>
        {isInformDialog && (
          <div className="border-bottom border-width-4 border-lighter-gray w-100 mb-3" />
        )}
        {body}
      </BaseDialogBody>

      <BaseDialogFooter>
        {!!confirmText && (
          <BaseDialogButton
            block={true}
            outline={false}
            color={isConfirmReversedDialog ? "secondary" : "primary"}
            isLoading={confirmLoading}
            isDisabled={confirmDisabled}
            onClick={onConfirm}>
            {confirmText}
          </BaseDialogButton>
        )}
        {!!cancelText && (
          <BaseDialogButton
            block={true}
            className="mt-2"
            outline={isInformDialog || isGatedDialog}
            color={
              isInformDialog || isGatedDialog || isConfirmReversedDialog
                ? "primary"
                : "secondary"
            }
            isLoading={cancelLoading}
            isDisabled={cancelDisabled}
            onClick={handleCancelClick}>
            {cancelText}
          </BaseDialogButton>
        )}
      </BaseDialogFooter>
    </BaseDialog>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(DIALOG_TYPE)),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  body: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClosed: PropTypes.func
};

Dialog.defaultProps = {
  type: DIALOG_TYPE.CONFIRM
};

export default Dialog;
