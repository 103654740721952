import React, { useState, useCallback, useMemo } from 'react';

const TouchableOpacity = ({ onPush, style = {}, children, animate = true }) => {
    const gap = 75;
    const [touched, setTouched] = useState(false);

    const btn = useMemo(() => {
        return {
            ...style,
            opacity: 1,
            cursor: 'pointer',
            transition: `opacity ${gap}ms ease`
        }
    }, [style]);

    const onMouseDown = useCallback((e) => {
        e.stopPropagation();
        setTouched(!touched);
        return false;
    }, [touched]);

    const onMouseUp = useCallback((e) => {
        e.stopPropagation();
        setTimeout(() => {
            setTouched(false);
            if (typeof onPush === 'function') {
                onPush();
            }
        }, gap);
        return false;
    }, [onPush]);

    return (
        <div style={touched && animate ? { ...btn, opacity: '0.5' } : btn}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp} >
            {children}
        </div>
    );
}

export default TouchableOpacity;

