// Authentication Routes
export const ROOT = `/`;
export const SIGN_IN = `/login`;
export const SIGN_UP = `/registration/new`;
export const SIGN_OUT = `/logout`;
export const NOT_FOUND = "/404";
// Home Routes
export const HOME_ROOT_TOPIC = `/home/:feed/:feedTypeUuid`;
export const HOME_ROOT_FEED = `/home/:feed`;
export const HOME_ROOT = `/home`;
export const NOT_AUTHORIZED = `${HOME_ROOT}/not-authorized`;

// Feed Routes
export const CASE_DETAIL = `/case-detail/:caseId`;
export const CASE_DETAIL_UPDATE = `${CASE_DETAIL}/update`;
export const MANAGE_CHANNELS = `/manage-channels`;
export const CASES = `/cases/:caseId`;

// Case Explorer Routes
export const CASE_EXPLORER_ROOT = `/case-explorer`;

// Case Explorer Routes
export const CME_ROOT = `/cme`;
export const CME_CERTIFICATE = `${CME_ROOT}/:caseId/certificate`;
export const CME_POST_NOTICE = `${CME_ROOT}/:caseId/postNotice`;

// Clinical Moments Routes
export const CLINICAL_MOMENTS_ROOT = `/clinical-moments`;
export const CLINICAL_MOMENTS_DETAIL = `${CLINICAL_MOMENTS_ROOT}/:caseId`;
export const CLINICAL_MOMENTS_ACTIVITY = `${CLINICAL_MOMENTS_ROOT}/:caseId/activity`;

// Case Posting
export const CASE_POSTING = `/draft-detail`;
export const CASE_POSTING_TYPE = `/select-type/`;
export const CASE_POSTING_NEW = `${CASE_POSTING}/new/:caseType`;
export const CASE_POSTING_EDIT = `${CASE_POSTING}/:draftUid`;

// Drafts Routes
export const DRAFTS_ROOT = `/drafts`;

// Saved Cases Routes
export const SAVED_CASES_ROOT = `/saved-cases`;

// Saved Cases Routes
export const ACCOUNT_SETTINGS_ROOT = `/account-settings`;

// Notifications Routes
export const NOTIFICATIONS_ROOT = `/notifications`;

// Search Routes
export const SEARCH_ROOT = `/search`;
export const SEARCH_PATH = `/search/:tab`;

// Profile Routes
export const PROFILE_ROOT = `/profile`;
export const PROFILE_DETAIL = `${PROFILE_ROOT}/:userUuid`;
export const PROFILE_USERNAME_REDIRECT = `${PROFILE_ROOT}/name/:username`;
export const PROFILE_EDIT_INFO = `${PROFILE_DETAIL}/edit/info`;
export const PROFILE_EDIT_IMAGE = `${PROFILE_DETAIL}/edit/image`;
export const PROFILE_EDIT_SUBSPECIALTIES = `${PROFILE_DETAIL}/edit/subspecialties`;
export const PROFILE_EDIT_PRACTICE_AREAS = `${PROFILE_DETAIL}/edit/practiceareas`;
export const PROFILE_EDIT_EXPERIENCE = `${PROFILE_DETAIL}/edit/experience`;
export const PROFILE_EDIT_EDUCATION = `${PROFILE_DETAIL}/edit/education`;
export const PROFILE_EDIT_MEMBERSHIP = `${PROFILE_DETAIL}/edit/membership`;
export const PROFILE_NETWORK = `${PROFILE_DETAIL}/network`;
export const PROFILE_NETWORK_TYPE = `${PROFILE_DETAIL}/network/:viewType`;

// Registration Routes
export const REGISTRATION = `/registration`;
export const REGISTRATION_NEW_V2 = `/registration/new`;
export const REGISTRATION_ONBOARDING_V2 = `/registration/onboarding`;
export const REGISTRATION_COUNTRY_V2 = `/registration/country`;
export const REGISTRATION_INFORMATION_V2 = `/registration/information`;
export const REGISTRATION_VERFICATION_PHOTO_V2 = `/registration/photo-verification`;
export const REGISTRATION_USERNAME_V2 = `/registration/username`;
export const REGISTRATION_ONBOARDING_NON_HCP = `/registration/onboarding/non-hcp`;

// TO BE REMOVED
export const REGISTRATION_NEW = `/registration/new`;
export const REGISTRATION_CREATE_ACCOUNT = `/registration/new/account`;
export const REGISTRATION_FOLLOW_CHANNELS = `/registration/follow-channels`;
export const REGISTRATION_VERFICATION_SELECT = `/registration/verification`;
export const REGISTRATION_VERFICATION_PHOTO = `/registration/verification-photo`;
export const REGISTRATION_VERFICATION = `/registration/verification/:verificationType`;
export const REGISTRATION_VERFICATION_COMPLETE = `/registration/complete`;
export const REGISTRATION_SUPPORT = `/registration/support`;
export const REGISTRATION_PRIVACY = `/registration/privacy`;
export const REGISTRATION_ONBOARDING = `/registration/onboarding`;
export const REGISTRATION_ONBOARDING_PROFILE = `/registration/onboarding/:profileState`;
// TO BE REMOVED ^^^
export const REGISTRATION_LOGIN = `/login`;
export const REGISTRATION_RESET_PASSWORD = `/reset-password`;

// Group Registration Routes
export const CLAIM_GROUP = `/claim/:groupUuid`;
export const CLAIM_GROUP_WITH_PROFILE = `/claim/profile/:groupFilterUuid`;
export const GROUP_WELCOME_SPLASH = `/groupwelcome/:groupUuid`;

// Legacy Routes

export const LEGACY_HELLO = `/hello`;
export const LEGACY_RD = `/rd`;
export const LEGACY_REDIRECT = `/rfy`;

// Settings Routes

export const SETTINGS_ROOT = `/settings`;
export const SETTINGS_GENERAL_SETTINGS = `/settings/general`;
export const SETTINGS_INTERESTS = `/settings/interests`;
export const SETTINGS_ACTIVITY_NOTIFICATIONS = `/settings/activity`;
export const SETTINGS_CONTENT_NOTIFICATIONS = `/settings/content`;
