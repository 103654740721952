/** @format */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  UncontrolledTooltip
} from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import CASE_TYPE from "../../constants/case-type";
import { getImageUrl } from "../../utils/media-utils";
import { rectifyColorInput } from "../../utils/color-utils";

const HighlightCard = ({ content, onClick }) => {
  /****************************** Config **************************************/
  const mediaUri = getImageUrl(
    { type: "image", url: content.feedCardMedia?.url },
    512,
    512,
    "crop"
  );
  const isCME = content.caseType === CASE_TYPE.CME;
  const tooltipId = `highlightCardSponsoredTextTooltip${content.caseUuid}`;

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <Card className="feed-card border-0 cursor-pointer" onClick={onClick}>
      <CardImg top width="100%" src={mediaUri} alt={content.feedCardLabel} />
      <CardImgOverlay
        className="d-flex flex-column justify-content-center align-items-start b-auto"
        style={{ backgroundColor: rectifyColorInput(content.colour) }}>
        <CardText className="border border-width-1 border-white rounded py-1 px-2 text-white text-13">
          {content.feedCardLabel}
        </CardText>
        <CardTitle className="text-white font-weight-normal m-0" tag="h4">
          {content.feedCardTitle}
        </CardTitle>
      </CardImgOverlay>
      <CardFooter className="border-0 py-2 bg-white text-battleship-gray">
        <div className="d-inline-flex justify-content-between align-items-center w-100 p-0">
          <CardText className="text-16 mb-0">
            {content.buttonText}
            <CustomIcon
              icon="drawer_draft_chevron"
              className={"icon-battleship-gray mb-1"}
              size={16}
            />
          </CardText>
          {content.sponsoredContent && (
            <>
              <CardText id={tooltipId} className="card-text text-14 mb-0">
                {content.sponsoredContent.sponsoredText}
              </CardText>
              <UncontrolledTooltip
                className="pointer-events-none"
                popperClassName="tooltip-battleship-gray"
                placement="top"
                target={tooltipId}>
                {content.sponsoredContent?.disclosureText}
              </UncontrolledTooltip>
            </>
          )}
        </div>
        {!isCME && !!content.sponsoredContent?.jobCode && (
          <div className="d-inline-flex justify-content-end align-items-center w-100 p-0">
            <CardText>
              <small>{content.sponsoredContent?.jobCode}</small>
            </CardText>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

HighlightCard.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    caseType: PropTypes.string.isRequired,
    caseUuid: PropTypes.string.isRequired,
    feedCardLabel: PropTypes.string.isRequired,
    feedCardMedia: PropTypes.shape({
      caseUuid: PropTypes.string,
      contentUuid: PropTypes.string,
      displayOrder: PropTypes.number,
      filename: PropTypes.string,
      height: PropTypes.number,
      mediaUuid: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
      uuid: PropTypes.string,
      width: PropTypes.number
    }),
    feedCardTitle: PropTypes.string.isRequired,
    feedCardType: PropTypes.string.isRequired,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      jobCode: PropTypes.string,
      sponsoredText: PropTypes.string
    })
  })
};

export default HighlightCard;
