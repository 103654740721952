/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import LinesEllipsis from "react-lines-ellipsis";
import { capitalize } from "lodash";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import LoadingButton from "../../../components/common/LoadingButton";
import useDialog from "../../../components/dialog/useDialog";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";

const ProfileInfoCardRow = ({ data, editing, onDelete }) => {
  /************************************ CONFIG ***************************************/
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { confirm } = useDialog();
  const { showToast } = usePopup();
  const date = `${data.startYear} - ${data.endYear ||
    capitalize(t("common.present"))}`;

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const confirmDeleteEntry = async () => {
    const confirmed = await confirm({
      message: t("Profile.deleteEntryConfirmation")
    });

    if (!confirmed) {
      return;
    }

    try {
      setIsDeleting(true);

      const result = await onDelete(data);

      if (result.error) {
        showToast({
          message: t("Profile.updateError"),
          toastType: TOAST_TYPE.ERROR
        });
        setIsDeleting(false);
      }
    } catch (e) {
      showToast({
        message: t("Profile.updateError"),
        toastType: TOAST_TYPE.ERROR
      });
      setIsDeleting(false);
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Row className="profile-info-card-row" noGutters>
      <Col>
        <p className="mb-0">{data.description}</p>
        {data.location ? (
          <LinesEllipsis
            className="text-muted"
            text={data.location}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        ) : null}
      </Col>
      <Col
        xs="auto"
        className="d-inline-flex justify-content-end align-items-center flex-shrink-0 ml-2">
        <p className="mb-0 pt-1">{date}</p>
        {editing ? (
          <LoadingButton
            className="btn-text btn-delete"
            size="sm"
            color="default-black"
            spinnerColor="default-black"
            loading={isDeleting}
            disabled={isDeleting}
            onClick={confirmDeleteEntry}>
            <CustomIcon size={16} icon={"profile_trash"} />
          </LoadingButton>
        ) : null}
      </Col>
    </Row>
  );
};

ProfileInfoCardRow.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    location: PropTypes.string,
    startYear: PropTypes.string,
    endYear: PropTypes.string
  }),
  onDelete: PropTypes.func,
  editing: PropTypes.bool
};

export default ProfileInfoCardRow;
