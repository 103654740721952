import React from "react";
import i18n from "../../../utils/i18n";
import BaseEmptyFeed from "./BaseEmptyFeed";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import * as ROUTES from "../../../constants/routes";

const EmptyRecommendedFeedMessage = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onClick = () => {
    history.push(getUrl(ROUTES.SETTINGS_INTERESTS));
  };
  /** ********************************** RENDER ***************************************/

  return (
    <BaseEmptyFeed
      title={i18n.t("EmptyFeed.Title")}
      actionText={i18n.t("EmptyFeed.AddInterests")}
      action={onClick}>
      <p className="text-16 text-center">{i18n.t("EmptyFeed.P1")}</p>
    </BaseEmptyFeed>
  );
};

export default EmptyRecommendedFeedMessage;
