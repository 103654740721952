/** @format */
import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import ReactMarkdown from "react-markdown";
import i18n from "../../utils/i18n";

const QuestionAnswerDetail = ({ onResetVote, markdownText, isPoll }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card outline color={isPoll ? "light-gray" : "aqua"}>
      <CardBody className="pb-0">
        <ReactMarkdown className="whitespace-pre-line">
          {markdownText}
        </ReactMarkdown>
      </CardBody>
      {!isPoll && (
        <CardFooter className="bg-white border-0 pl-2">
          <Button className="btn-text" color="denim-blue" onClick={onResetVote}>
            {i18n.t("questionDetailCard.resetQuiz")}
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

QuestionAnswerDetail.propTypes = {
  onResetVote: PropTypes.func,
  markdownText: PropTypes.string
};

export default QuestionAnswerDetail;
