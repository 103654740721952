import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentSection } from "../components/CMEUtils";

function useInitialSection(caseUuid) {
  const [initialSection, setInitialSection] = useState(null);

  const section = useSelector((state) => getCurrentSection(state, caseUuid));

  useEffect(() => {
    // Ensure we only set the initial section once
    if (!initialSection && section) {
      setInitialSection(section);
    }
  }, [initialSection, section]);

  return [initialSection, setInitialSection];
}

export default useInitialSection;
