import React from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import i18n from "../../../utils/i18n";
import { Controller } from "react-hook-form";

const FormElementNameV2 = ({ control, errors, model }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <FormGroup>
      <Label for="firstName">
        {i18n.t("RegistrationScreens.userInfo.nameLabel")}
      </Label>
      <div className="d-flex">
        <Controller
          className="mr-1"
          as={Input}
          control={control}
          id="firstName"
          name="firstName"
          type="text"
          defaultValue={model?.firstName || ""}
          placeholder={i18n.t(
            "RegistrationScreens.userInfo.firstNamePlaceholder"
          )}
          rules={{ required: true, minLength: 2 }}
          invalid={!!errors.firstName}
        />
        <Controller
          className="ml-1"
          as={Input}
          control={control}
          id="lastName"
          name="lastName"
          defaultValue={model?.lastName || ""}
          type="text"
          placeholder={i18n.t(
            "RegistrationScreens.userInfo.lastNamePlaceholder"
          )}
          rules={{ required: true, minLength: 2 }}
          invalid={!!errors.lastName}
        />
      </div>
      <FormText>
        {i18n.t("RegistrationScreens.userInfo.v2.nameHelpText")}
      </FormText>
      {(!!errors.firstName || !!errors.lastName) && (
        <div className="d-block invalid-feedback">
          {i18n.t("RegistrationScreens.v2.nameRequired")}
        </div>
      )}
    </FormGroup>
  );
};

export default FormElementNameV2;
