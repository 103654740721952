/** @format */

import { isEmpty } from "lodash";
import CME_SECTION from "../../../constants/cme-section";

export const getCMEBundleFor = (section, contentItems) => {
  if (!contentItems || contentItems.length === 0) {
    return [];
  }

  return contentItems
    .filter((c) => c.section === section)
    .sort((a, b) => a.displayOrder - b.displayOrder);
};

export const processCaseDataForBundles = (caseData) => {
  if (
    !caseData ||
    !caseData.contentItems ||
    caseData.contentItems.length === 0
  ) {
    return;
  }

  const contentItems = caseData.contentItems;
  caseData[CME_SECTION.FRONT_MATTER] = getCMEBundleFor(
    CME_SECTION.FRONT_MATTER,
    contentItems
  );
  caseData[CME_SECTION.PRE_TEST] = getCMEBundleFor(
    CME_SECTION.PRE_TEST,
    contentItems
  );
  caseData[CME_SECTION.ACTIVITY] = getCMEBundleFor(
    CME_SECTION.ACTIVITY,
    contentItems
  );
  caseData[CME_SECTION.POST_TEST] = getCMEBundleFor(
    CME_SECTION.POST_TEST,
    contentItems
  );
  caseData[CME_SECTION.SURVEY] = getCMEBundleFor(
    CME_SECTION.SURVEY,
    contentItems
  );
};

export const getCurrentSection = (state, caseUuid) => {
  const caseContent = state.case.cases[caseUuid]?.data;

  const contentItems = caseContent?.contentItems;

  const currentContentPosition =
    state.user.interactiveCaseStates?.[caseUuid]?.currentQuestion?.displayOrder;

  const currentSlide = contentItems?.find(
    (item) => item.displayOrder === currentContentPosition
  );

  return currentSlide?.section || getInitialSection(caseContent);
};

const getInitialSection = (caseData) => {
  if (!caseData) {
    return undefined;
  }

  const sections = [
    CME_SECTION.FRONT_MATTER,
    CME_SECTION.PRE_TEST,
    CME_SECTION.ACTIVITY,
    CME_SECTION.POST_TEST,
    CME_SECTION.SURVEY
  ];

  return sections.find((s) => !isEmpty(caseData[s]));
};
