import React from "react";
import { Col } from "reactstrap";
import * as PropTypes from "prop-types";
import Figure1PageContainer from "./Figure1PageContainer";
import BackButton from "../components/common/BackButton";

const Figure1Page1Col = React.forwardRef((props, ref) => {
  return (
    <Figure1PageContainer
      className="center-only-container"
      headerClass={props.headerClass}
      isChildPage={props.isChildPage}
      headerRowSlot={props.headerRowSlot}>
      {props.mainContent && <Col>{props.mainContent}</Col>}
      {props.mainContentSmall && (
        <Col lg={{ size: 8, offset: 2 }}>
          {!props.hideBackButton && (
            <div className="pb-2">
              <BackButton />
            </div>
          )}

          {props.mainContentSmall}
        </Col>
      )}
    </Figure1PageContainer>
  );
});

Figure1Page1Col.propTypes = {
  mainContent: PropTypes.node,
  isChildPage: PropTypes.bool,
  headerClass: PropTypes.string,
  headerRowSlot: PropTypes.element
};

Figure1Page1Col.defaultProps = {
  isChildPage: false,
  headerClass: "py-5"
};

export default Figure1Page1Col;
