import { defaultTo, isEmpty, get, orderBy } from "lodash";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const onInvalidSubmit = () => {
  scrollToTop();
};

export const getFieldName = (name, prefix = "") => {
  if (isEmpty(prefix)) {
    return name;
  }

  return `${prefix}.${name}`;
};

export const getFieldValue = (
  formData,
  name,
  prefix = "",
  defaultValue = ""
) => {
  return defaultTo(get(formData, getFieldName(name, prefix)), defaultValue);
};

// as described here: https://www.eclaims.com/articles/how-to-calculate-the-npi-check-digit/
export const checkNpiNumber = (npi) => {
  if (npi.length > 9) {
    npi = npi.split("");
    let checkBit = npi.pop(),
      npi_double = [],
      npi_unaffected = [];
    npi.reverse();

    for (let i = 0; i < npi.length; i++) {
      if (i % 2 === 0) {
        npi_double.push(npi[i] * 2);
      }
      if (i % 2 === 1) {
        npi_unaffected.push(npi[i]);
      }
    }

    npi_double = npi_double.toString().replace(/,/g, "");
    npi_unaffected = npi_unaffected.toString().replace(/,/g, "");
    let total = npi_unaffected + npi_double,
      total_sum = 0;

    for (let i = 0; i < total.length; i++) {
      total_sum += parseInt(total.charAt(i));
    }
    total_sum += 24; //Magic Bit
    let final = Math.ceil(total_sum / 10) * 10;
    return final - total_sum === Number(checkBit);
  } else {
    return false;
  }
};

/**
 * Return the specialty data formatted for `updateUserProfile` API call.
 *
 * Note: Args are expected to come from `FormElementSpecialty` component.
 * @param {string} label - usually Specialty name. However can be user's custom specialty
 * @param {string} value - specialty_tree_uuid
 * @return \{ primarySpecialty: value \} or \{ userCustomSpecialty: label \}
 */
export const formatSpecialtyForUpdateUserProfile = ({ value, label }) => {
  return value === "custom"
    ? { userCustomSpecialty: label }
    : { primarySpecialty: value };
};

/**
 * Return specialties formatted and sorted to be displayed in FormElementSpecialty.
 *
 * @param specialties
 * @returns {[{label, value}]}
 */
export const formatSpecialtyDataForFormElement = (specialties) => {
  const formatted = specialties.map((s) => {
    return {
      label: s.onboardingDisplayName,
      value: s.treeUuid
    };
  });

  return orderBy(formatted, "label");
};
