import React from "react";
import PropTypes from "prop-types";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import CustomIcon from "../custom-icon/CustomIcon";
import GatedButtons from "../gated-buttons/GatedButtons";
import i18n from "../../utils/i18n";

const CommentBlock = ({ campaignUuid, caseUuid, caseType }) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="d-flex flex-column align-items-center justify-content-between p-5 text-center flex-grow-1 comment-block">
      <CustomIcon
        icon="lock"
        size={41}
        className={`mb-2 ${theme === THEMES.DARK ? "icon-white" : null}`}
      />
      <h3 className="text-18 my-2 font-weight-bold">
        {i18n.t("Gated.CommentBlock.title")}
      </h3>
      <p>{i18n.t("Gated.CommentBlock.message")}</p>
      <GatedButtons
        color={theme === THEMES.DARK ? "aqua" : "primary"}
        campaignUuid={campaignUuid}
        caseUuid={caseUuid}
        caseType={caseType}
        source={UNGATED_ACTIONS.ORIGIN.COMMENT_BLOCK}
      />
    </div>
  );
};

CommentBlock.propTypes = {
  reportEnabled: PropTypes.bool,
  replyEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  onReplyClick: PropTypes.func.isRequired
};

export default CommentBlock;
