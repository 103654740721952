import React, { useEffect } from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { Button } from "reactstrap";
import fallbackImg from "../../assets/images/web hero.webp";
import { getRegistrationRoute } from "../../actions/login.actions";
import { getUrl } from "../../utils/route-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import i18n from "../../utils/i18n";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import "./GroupWelcomeSplashPage.scss";
import { trackLegacyWelcomePageEntry } from "../../actions/metrics.actions";
import RegistrationHeader from "./components/RegistrationHeader";
import { isMobile } from "react-device-detect";
import { getGroupsInfoIfNeeded } from "../../actions/reference.actions";

const GroupWelcomeSplashPage = () => {
  /** ********************************** CONFIG ***************************************/
  const { groupUuid } = useParams();
  const user = useSelector((state) => state.user);

  //TODO: may be we will have to figure out group name here

  const nextRoute = getRegistrationRoute(user);
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedGroup = useSelector(
    (state) => state.references.groups?.data?.[groupUuid] || {}
  );

  useEffect(() => {
    trackLegacyWelcomePageEntry();
  }, []);

  // fetch groups if required
  useEffect(() => {
    dispatch(getGroupsInfoIfNeeded());
  }, [dispatch]);
  /** ********************************* FUNCTIONS *************************************/

  const onContinueClick = () => {
    history.push(getUrl(nextRoute));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeader />}>
      <div className="text-white d-flex flex-column align-items-center m-0 groupSpashPage">
        <>
          <h1 className={`helv-standerd mb-2 ${isMobile && "text-left"}`}>
            {i18n.t("RegistrationScreens.groupWelcomeSplashPage.title", {
              groupname: selectedGroup.groupName
            })}
          </h1>
          <p className={`color-white text-20 mb-4 ${isMobile && "text-left"}`}>
            {i18n.t("RegistrationScreens.groupWelcomeSplashPage.description")}
          </p>

          <ul>
            <li className="d-flex mb-3 text-20 align-items-center">
              <CustomIcon
                className="mr-3 flex-shrink-0"
                size={32}
                icon="groups_welcome_CME"
              />{" "}
              <div className="color-white">
                {i18n.t("RegistrationScreens.groupWelcomeSplashPage.bullet1")}
              </div>
            </li>
            <li className="d-flex mb-3 text-20 align-items-center">
              <CustomIcon
                className="mr-3 flex-shrink-0"
                size={32}
                icon="groups_welcome_safe"
              />{" "}
              <div className="color-white">
                {i18n.t("RegistrationScreens.groupWelcomeSplashPage.bullet2")}
              </div>
            </li>
            <li className="d-flex text-20 align-items-center">
              <CustomIcon
                className="mr-3 flex-shrink-0"
                size={32}
                icon="groups_welcome_updates"
              />{" "}
              <div className="color-white">
                {i18n.t("RegistrationScreens.groupWelcomeSplashPage.bullet3", {
                  groupname: selectedGroup.groupName
                })}
              </div>
            </li>
          </ul>

          <div className="d-flex mt-4 w-100 justify-content-center">
            <Button
              className="mt-4 w-215px"
              color="primary"
              size="lg"
              onClick={onContinueClick}>
              {i18n.t(
                "RegistrationScreens.groupWelcomeSplashPage.btnGetStarted"
              )}
            </Button>
          </div>
        </>
      </div>
      <div id="background-media">
        <img src={fallbackImg} alt="" />
      </div>
    </Figure1Layout>
  );
};

export default GroupWelcomeSplashPage;
