/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { trackEventWithName } from "../../actions/metrics.actions";
import {
  METRICS_CATEGORY,
  METRICS_EVENT_KEY
} from "../../metrics/constants.metrics";

const ISIHeader = React.forwardRef(function ISIHeader(
  { title, url, contentItem },
  ref
) {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onLinkTapped = () => {
    trackEventWithName(METRICS_CATEGORY.VIEW_ISI, "TapISILink", {
      [METRICS_EVENT_KEY.CASE_UUID]: contentItem.caseUuid,
      [METRICS_EVENT_KEY.CONTENT_UUID]: contentItem.contentUuid,
      [METRICS_EVENT_KEY.CAMPAIGN_ID]: contentItem.campaignUuid
    });
  };

  /** ********************************** RENDER ***************************************/

  return (
    <div
      className="d-inline-flex justify-content-center align-items-center w-100 px-3 py-2 bg-battleship-gray"
      ref={ref}>
      <Button
        className="btn-text text-15 text-white text-underline font-weight-500"
        size="lg"
        color="lighter-gray"
        tag="a"
        rel="noopener noreferrer"
        href={url}
        target="_blank"
        onClick={onLinkTapped}>
        {title || url}
      </Button>
    </div>
  );
});

ISIHeader.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  contentItem: PropTypes.object
};

export default ISIHeader;
