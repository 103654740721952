/** @format */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { listenInteractiveCase } from "../actions/user.actions";

/**
 * Setup listener to specific caseUuid's state for the current user.
 * On unmount, unsubscribe.
 * @param {string} caseUuid
 */
const useListenInteractiveCaseState = (caseUuid) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listenInteractiveCase(caseUuid, true));

    return () => {
      dispatch(listenInteractiveCase(caseUuid, false));
    };
  }, [caseUuid, dispatch]);
};

export default useListenInteractiveCaseState;
