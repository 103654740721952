import React, { useEffect } from "react";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import BaseDialog from "../../../components/dialog/BaseDialog";
import BaseDialogHeader from "../../../components/dialog/BaseDialogHeader";
import BaseDialogBody from "../../../components/dialog/BaseDialogBody";
import BaseDialogFooter from "../../../components/dialog/BaseDialogFooter";
import BaseDialogButton from "../../../components/dialog/BaseDialogButton";
import { getSupportEmail } from "../../../utils/email-utils";
import ExternalTextLink from "./ExternalTextLink";
import { getPotentialGroupDetails } from "../../../actions/reference.actions";
import { clearEmailMismatchModal } from "../../../actions/global.actions";
import { signOut } from "../../../actions/login.actions";
import "./ClaimGroupSignoutModal.scss";
import { useHistory } from "react-router-dom";

const ClaimGroupSignoutModal = () => {
  /************************************ CONFIG ***************************************/
  const user = useSelector((state) => state.user);
  const modalDetails = useSelector(
    (state) => state.global?.emailMismatchModal || {}
  );
  const potentialGroupDetails = useSelector(
    (state) => state?.references?.potentialGroupDetails?.data || {}
  );
  const isOpen = modalDetails?.showModal || false;
  const groupFilterUuid = modalDetails?.groupFilterUuid || "";

  /************************************ HOOKS ****************************************/
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupFilterUuid) {
      dispatch(getPotentialGroupDetails(groupFilterUuid));
    }
  }, [dispatch, groupFilterUuid]);

  /*********************************** FUNCTIONS *************************************/

  const onConfirm = () => {
    dispatch(clearEmailMismatchModal());
    dispatch(signOut());
    history.push(
      ROUTES.CLAIM_GROUP_WITH_PROFILE.replace(
        ":groupFilterUuid",
        groupFilterUuid
      )
    );
  };

  const onClosed = () => dispatch(clearEmailMismatchModal());

  /************************************ RENDER ***************************************/

  return (
    <BaseDialog
      centered
      isOpen={isOpen}
      onCancel={onClosed}
      onClosed={onClosed}>
      <BaseDialogHeader onCancel={onClosed}>
        {potentialGroupDetails && (
          <h4 className="mb-0 font-weight-bold">
            {i18n.t("RegistrationScreens.claimGroupSignoutModal.title", {
              groupname: potentialGroupDetails?.potentialGroup?.groupName
            })}
          </h4>
        )}
      </BaseDialogHeader>

      <BaseDialogBody>
        <div className="border-bottom border-width-4 border-lighter-gray w-100 mb-3" />
        <div className="pt-1 mh-300px overflow-auto w-100">
          {i18n.t("RegistrationScreens.claimGroupSignoutModal.subtitle", {
            useremail: user.email,
            groupname: potentialGroupDetails?.potentialGroup?.groupName
          })}
        </div>
      </BaseDialogBody>

      <BaseDialogFooter centered style={{ width: "100%" }}>
        <BaseDialogButton
          block={true}
          outline={false}
          color="primary"
          onClick={onConfirm}>
          {i18n.t("RegistrationScreens.claimGroupSignoutModal.signOut")}
        </BaseDialogButton>
        <div className="orDivider m-3">
          {i18n.t("RegistrationScreens.claimGroupSignoutModal.divider")}
        </div>
        <div className="bg-light p-3 border rounded mh-300px overflow-auto w-100 text-center">
          {i18n.t("RegistrationScreens.claimGroupSignoutModal.support1")}{" "}
          <ExternalTextLink
            className="cursor-pointer text-underline text-15"
            url={getSupportEmail()}
            linkText={i18n.t(getSupportEmail())}
          />{" "}
          {i18n.t("RegistrationScreens.claimGroupSignoutModal.support2")}
        </div>
      </BaseDialogFooter>
    </BaseDialog>
  );
};

ClaimGroupSignoutModal.propTypes = {};

ClaimGroupSignoutModal.defaultProps = {};

export default ClaimGroupSignoutModal;
