import React, { useState } from "react";
import Case from "case";
import i18n from "../../utils/i18n";

const CommentTabs = ({ onChangeTab, commentDetails }) => {
  /** ********************************** CONFIG ***************************************/

  const [activeTab, setActiveTab] = useState("physician");
  const physicianCount = commentDetails.physicianCount || 0;
  const allCount = commentDetails.allCount || 0;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const changeCommentTab = (tab) => {
    setActiveTab(tab);
    onChangeTab(tab);
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {commentDetails && (
        <div className="case-detail-comments-tab-group">
          <div
            className={
              activeTab === "physician"
                ? "case-detail-comments-tab-active"
                : "case-detail-comments-tab"
            }
            onClick={() => changeCommentTab("physician")}>
            {Case.capital(i18n.t("common.physician"))}
            {physicianCount > 0 && ` (${physicianCount})`}
          </div>
          <div
            className={
              activeTab === "all"
                ? "case-detail-comments-tab-active"
                : "case-detail-comments-tab"
            }
            onClick={() => changeCommentTab("all")}>
            {Case.capital(i18n.t("common.all"))}
            {allCount > 0 && ` (${allCount})`}
          </div>
        </div>
      )}
    </>
  );
};

export default CommentTabs;
