import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useBeforeunload } from "react-beforeunload";
import { Link, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import {
  resetCaseDetails,
  requestCaseSyncIfNeeded,
  toggleCaseDetailsListener,
  toggleCaseUserActionsListener,
  toggleRelatedCasesListener,
  toggleCasePublicationsListener
} from "../../actions/case.actions";
import { resetComments } from "../../actions/comment.actions";
import CaseCardLoading from "../../components/case/CaseCardLoading";
import CaseDetailAuthor from "../../components/case/CaseDetailAuthor";
import Comments from "../../components/comments/Comments";
import ContentItem from "../../components/case/ContentItem";
import AutoSizer from "../../components/common/AutoSizer";
import ISILayout from "../../components/isi/ISILayout";
import useScrollRestore from "../../components/scroll-restore/useScrollRestore";
import { defaultFeatures } from "../../constants/case-feature-constants";
import { QUESTION_CONTENT_TYPES } from "../../constants/content-type";
import {
  getCurrentContentItem,
  hideCommentsSwitch
} from "../../utils/case-utils";
import { isPoll, isQuiz, isSponsoredContent } from "../../utils/tactic-utils";
import { useInteractiveCaseState } from "../../selectors/interactive-case-state";
import {
  cacheDetailViewMetrics,
  trackCaseDetailEntryView,
  trackCaseDetailView,
  trackSlideView
} from "../../actions/metrics.actions";
import useComments from "../../components/comments/useComments";
import i18n from "../../utils/i18n";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import CaseEmptyView from "../../components/case/CaseEmptyView";
import { ISI_FOOTER_HEIGHT } from "../../constants/isi-constants";
import useISI from "../../components/isi/useISI";
import useIsGated from "../../hooks/useIsGated";
import useAppPrompt from "../../components/app-prompt/useAppPrompt";
import { Card } from "reactstrap";
import CaseDetailCardInner from "../../components/case/CaseDetailCardInner";
import { setGatedState } from "../../actions/global.actions";
import useIsSponsored from "../../hooks/useIsSponsored";
import SimilarCases from "../../components/similar-cases/SimilarCases";
import { FEED_META_LABELS } from "../../constants/feed-type";
import {
  getPreviewFeedIfNeeded,
  getPreviewFeedMetaIfNeeded
} from "../../actions/reference.actions";
import GatedCommentBar from "../../components/case/GatedCommentBar";
import CaseCarouselContainer from "../feed/components/case-carousel/CaseCarouselContainer";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";
import BlockedCaseDetailPage from "./blocked-case-detail/BlockedCaseDetailPage";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";

const CaseDetailPage = ({ caseId, questionIndex }) => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();

  const dispatch = useDispatch();
  const scrollRef = useScrollRestore();
  const enterTimestamp = useRef(new Date().getTime());
  const location = useLocation();
  const metrics = useRef({});
  const [position, setPosition] = useState(null);
  const caseStatus = useSelector((state) => state.case.cases[caseId]);
  const locationState = location?.state;
  const features = caseStatus?.data?.features || defaultFeatures;
  const isAuthor = useSelector(
    (state) => state.user.userUid === caseStatus?.data?.author_uid
  );
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();

  const [caseDetails, setCaseDetails] = useState(null);

  const details = caseStatus?.data || {};
  const detailsItems = _.get(details, "contentItems", []);
  let contentUuidForComments = null;
  if (detailsItems.length === 1) {
    contentUuidForComments = detailsItems[0].contentUuid;
  } else if (detailsItems.length > 1) {
    const feedCardItem = detailsItems.filter((item) => item.isFeedCard);
    contentUuidForComments = feedCardItem.contentUuid;
  }
  const { commentStatus } = useComments(caseId, contentUuidForComments);
  const commentCount = commentStatus?.allCount || 0;

  const [currentIndex, setCurrentIndex] = useState(questionIndex || 0);
  const [haveSetUngated, setHaveSetUngated] = useState(false);

  const caseState = useInteractiveCaseState(caseId);
  const nbQuestions = detailsItems.filter((i) => {
    return QUESTION_CONTENT_TYPES.includes(i.contentType);
  }).length;

  const nbUserRightAnswers = caseState?.numCorrect || 0;
  const { exceededLimit, tickCount, sessionCount, countLimit } =
    useGatedBlockCounter();
  const userExists = useAnonUserExists();
  const limitBroke = exceededLimit();

  const currentItem = getCurrentContentItem(details, currentIndex) || {};
  const shouldHideCommentsSwitch = hideCommentsSwitch(details, currentIndex);

  const itemContent = {
    authors: details.authors,
    author_uid: details.author_uid,
    labels: details.labels,
    allReactions: details.allReactions,
    publishedAt: details.publishedAt,
    updatedAt: details.updatedAt,
    author_username: details.author_username,
    author_profession_label: details.author_profession_label,
    userSaved: details.userSaved,
    meshTerms: details.meshTerms,
    specialtyUuids: details.specialtyUuids,
    features: features,
    unverifiedViewCount: details.unverifiedViewCount,
    isPagingCase: details.isPagingCase,
    isi: details.isi, // Update this once the ISI backend is setup,
    isPoll: isPoll(currentItem),
    isQuiz: isQuiz(currentItem),
    shareLink: details.shareLink,
    isSponsored: isSponsoredContent(details),
    ...currentItem
  };

  const { showFooter: showISIFooter } = useISI(itemContent, false);
  const showNewUngated =
    isGated && !itemContent.isPoll && !itemContent.isQuiz && !isSponsored;

  useBeforeunload(() => {
    metrics.current.duration = new Date().getTime() - enterTimestamp.current;
    trackCaseDetailView(metrics.current);
  });

  const metaDataId = useSelector(
    (state) =>
      Object.values(state?.references?.feedsMeta?.data || {})?.find((m) => {
        return m.label === FEED_META_LABELS.TRENDING;
      })?.feed_type_uuid
  );

  const [feedFetchMade, setFeedFetchMade] = useState(false);
  const [trackedCaseId, setTrackedCaseId] = useState(false);

  const trendingCasesData = useSelector(
    (state) => state?.references?.feeds?.[metaDataId]?.data
  );

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    return () => {
      dispatch(resetCaseDetails(caseId));
      dispatch(resetComments(caseId));
    };
  }, [dispatch, caseId]);

  useEffect(() => {
    dispatch(requestCaseSyncIfNeeded(caseId));
    dispatch(toggleCaseDetailsListener(caseId, true));
    dispatch(toggleCaseUserActionsListener(caseId, true));
    dispatch(toggleRelatedCasesListener(caseId, true));
    dispatch(toggleCasePublicationsListener(caseId, true));
    return () => {
      dispatch(toggleCaseDetailsListener(caseId, false));
      dispatch(toggleCaseUserActionsListener(caseId, false));
      dispatch(toggleRelatedCasesListener(caseId, false));
      dispatch(toggleCasePublicationsListener(caseId, false));
    };
  }, [isGated, caseId, dispatch]);

  useEffect(() => {
    if (!metaDataId) {
      dispatch(getPreviewFeedMetaIfNeeded());
    }
  }, [metaDataId, dispatch]);

  useEffect(() => {
    if (metaDataId && !feedFetchMade) {
      dispatch(
        getPreviewFeedIfNeeded({
          feedTypeUuid: metaDataId
        })
      );
      setFeedFetchMade(true);
    }
  }, [metaDataId, feedFetchMade, dispatch]);

  useEffect(() => {
    if (isGated && !haveSetUngated && contentUuidForComments) {
      dispatch(
        setGatedState(
          true,
          details.isSponsored || itemContent.isPoll || itemContent.isQuiz
        )
      );
      setHaveSetUngated(true);
    }
  }, [
    dispatch,
    isGated,
    contentUuidForComments,
    haveSetUngated,
    details.isSponsored,
    itemContent.isPoll,
    itemContent.isQuiz
  ]);

  useEffect(() => {
    if (caseStatus) {
      if (caseStatus.relatedCases) {
        setCaseDetails(caseStatus);
      }
    }
  }, [caseStatus]);

  // METRICS EFFECTS
  useEffect(() => {
    metrics.current.ungated = isGated;

    if (locationState?.position != null) {
      metrics.current.position = locationState.position;
      setPosition(locationState.position);
    }
    if (caseDetails) {
      metrics.current.caseContent = caseDetails?.data;
    }
    if (commentCount != null) {
      metrics.current.commentCount = commentCount;
    }

    // Only send this if we have the data, and we've not already sent it
    if (
      trackedCaseId !== caseId &&
      metrics.current?.caseContent &&
      metrics.current?.commentCount != null
    ) {
      if (isGated && !isSponsored && !limitBroke) {
        tickCount();
      }

      if (isGated) {
        if (userExists) {
          metrics.current.ungatedState =
            UNGATED_ACTIONS.BLOCKED_STATUS.EXISTING_USER;
        } else {
          if (sessionCount >= countLimit) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.NEW_LIMIT_HIT_IN_SESSION;
          } else if (limitBroke) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.NEW_LIMIT_HIT;
          } else {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.NEW_NO_VIEWS;
          }
        }
      }

      trackCaseDetailEntryView(metrics.current);
      dispatch(cacheDetailViewMetrics(metrics.current?.caseContent?.caseUuid));

      metrics.current = {};

      setTrackedCaseId(caseId);
    }
  }, [
    trackedCaseId,
    caseId,
    locationState,
    caseDetails,
    commentCount,
    isGated,
    userExists,
    limitBroke,
    sessionCount,
    countLimit,
    dispatch,
    isSponsored
  ]);

  // not actually redundant! this fires when the component unloads
  // the other one above is when the page is changed
  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      metrics.current.duration = new Date().getTime() - enterTimestamp.current;
      // eslint-disable-next-line
      trackCaseDetailView(metrics.current);
    };
  }, []);
  /** ********************************* FUNCTIONS *************************************/

  const onNext = () => {
    if (detailsItems.length > currentIndex + 1) {
      scrollRef.current.scrollTo(0, 0);
      setCurrentIndex(currentIndex + 1);
      trackSlideView(
        caseId,
        currentIndex?.contentUuid,
        details?.campaignUuid,
        currentIndex + 1
      );
    }
  };

  const onPrev = () => {
    if (currentIndex - 1 >= 0) {
      scrollRef.current.scrollTo(0, 0);
      setCurrentIndex(currentIndex - 1);
      trackSlideView(
        caseId,
        currentIndex?.contentUuid,
        details?.campaignUuid,
        currentIndex - 1
      );
    }
  };

  let content = null;
  let rightContent = null;

  if (_.isEmpty(caseDetails)) {
    content = <CaseCardLoading />;
  } else if (!caseDetails?.data?.displayable) {
    // Note that "displayable" is set to tru in the case reducer if the
    // caseStatus is one of DISPLAYABLE_CASE_STATES; APPROVED, SC_APPROVED,
    // and SC_REVIEW as of this writing
    content = <CaseEmptyView className="ml-3" onOk={history.goBack} />;
  } else if (showNewUngated && (exceededLimit() || !!userExists)) {
    return (
      <BlockedCaseDetailPage
        trendingCasesData={trendingCasesData}
        caseDetails={caseDetails}
        itemContent={itemContent}
        commentStatus={commentStatus}
      />
    );
  } else if (showNewUngated) {
    if (!isMobileOnly) {
      content = (
        <>
          <CaseDetailAuthor className="mb-2" caseContent={caseDetails?.data} />

          <Card>
            <CaseDetailCardInner
              caseContent={itemContent}
              userReactions={caseDetails.userReactions || []}
              commentStatus={commentStatus}
              campaignUuid={details?.campaignUuid}
              videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
              showNewUngated={showNewUngated}
            />
            <hr className="my-0" />
            <GatedCommentBar
              caseDetails={caseDetails}
              linkText={i18n.t("Gated.CommentBar.signUpText")}
            />
          </Card>
        </>
      );
    } else {
      content = (
        <>
          <Card>
            <CaseDetailCardInner
              caseContent={itemContent}
              userReactions={caseDetails.userReactions || []}
              commentStatus={commentStatus}
              campaignUuid={details?.campaignUuid}
              videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
              showNewUngated={showNewUngated}
            />
            <hr className="my-0" />
            <GatedCommentBar
              caseDetails={caseDetails}
              linkText={i18n.t("Gated.CommentBar.signUpText")}
            />
          </Card>

          <CaseDetailAuthor className="my-2" caseContent={caseDetails?.data} />

          {itemContent.features.similarCasesEnabled && (
            <CaseCarouselContainer
              className="pl-3"
              cases={caseDetails?.relatedCases?.slice(0, 6) || []}
              context={"Ungated case"}
              headerElement={
                <div className="mb-2 text-14 helv-bold">
                  {" "}
                  {i18n.t("caseDetail.similarCases")}
                </div>
              }
              imgSize={124}
              colSize={2}
              nextItemVisiblePercent={0.27}
            />
          )}

          {trendingCasesData?.length && (
            <CaseCarouselContainer
              className="pl-3"
              cases={trendingCasesData?.slice(0, 6) || []}
              context={"Ungated case"}
              headerElement={
                <div className="mb-2 text-14 helv-bold">
                  {" "}
                  {i18n.t("caseDetail.trendingCases")}
                </div>
              }
              imgSize={124}
              colSize={2}
              nextItemVisiblePercent={0.27}
            />
          )}
        </>
      );
    }
    if (!isMobileOnly) {
      rightContent = (
        <>
          {itemContent.features.similarCasesEnabled && (
            <div className="mb-3">
              <SimilarCases
                relatedCases={caseDetails.relatedCases || []}
                itemLimit={4}
                size={6}
                title={i18n.t("caseDetail.similarCases")}
              />
            </div>
          )}

          {trendingCasesData?.length && (
            <SimilarCases
              relatedCases={trendingCasesData}
              size={6}
              itemLimit={4}
              title={i18n.t("caseDetail.trendingCases")}
            />
          )}
        </>
      );
    }
  } else {
    content = (
      <ContentItem
        item={itemContent}
        userReactions={caseDetails.userReactions || {}}
        relatedCases={caseDetails.relatedCases || []}
        maxIndex={detailsItems.length - 1}
        currentIndex={currentIndex}
        nbQuestions={nbQuestions}
        nbUserRightAnswers={nbUserRightAnswers}
        onNext={onNext}
        onPrev={onPrev}
        commentStatus={commentStatus}
        campaignUuid={details?.campaignUuid}
        casePublications={caseDetails.casePublications || []}
      />
    );

    rightContent = (
      <div className="feed-sidebar-right">
        <CaseDetailAuthor
          className="position-sticky"
          caseContent={caseDetails?.data}
        />
        {caseDetails?.data && isAuthor && !itemContent.isSponsored && (
          <Link
            className="btn btn-lg btn-denim-blue btn-block my-2"
            role="button"
            to={getUrl(ROUTES.CASE_DETAIL_UPDATE, { caseId })}>
            {i18n.t("caseDetail.updateCase")}
          </Link>
        )}
        <Comments
          caseContent={caseDetails?.data}
          currentItem={currentItem}
          commentStatus={commentStatus}
          hideComments={!features.commentsEnabled}
          hideCommentsSwitch={shouldHideCommentsSwitch}
          hideReactions={!features.reactionsEnabled}
          userReactions={caseDetails?.userReactions || []}
          position={position}
        />
      </div>
    );
  }

  /** ********************************** RENDER ***************************************/

  const styleProps = {};
  if (showISIFooter && !isMobileOnly) {
    styleProps.marginBottom = ISI_FOOTER_HEIGHT;
  }
  if (isAppPromptShown) {
    styleProps.marginBottom = (styleProps.marginBottom ?? 0) + appPromptHeight;
  }
  if (isGated && !isSponsored) {
    if (isSponsored) {
      styleProps.bottom = "5rem";
    } else {
      styleProps.bottom = "0";
    }
  }

  return (
    <ISILayout isi={itemContent} enableGatedBanner>
      <Figure1Page2Col
        isChildPage
        mainContent={content}
        rightSidebarContent={
          <>
            {rightContent && (
              <AutoSizer
                className={`position-lg-fixed ${
                  isGated && !isSponsored ? "" : "b-lg-2"
                } overflow-y-lg-auto ${isGated && !isSponsored ? "gated" : ""}`}
                style={styleProps}>
                {rightContent}
              </AutoSizer>
            )}
          </>
        }
      />
    </ISILayout>
  );
};

export default CaseDetailPage;
