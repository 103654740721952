import React from "react";
import { useHistory } from "react-router-dom";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import Button from "reactstrap/lib/Button";

const BackButton = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const { theme } = useTheme();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const goBack = () => {
    history.goBack();
  };

  /** ********************************** RENDER ***************************************/

  return (
    <div
      className={classNames(
        "d-flex justify-content-start align-items-center",
        theme === THEMES.DARK ? "text-light-gray" : "text-denim-blue"
      )}>
      <Button
        onClick={goBack}
        color={theme === THEMES.DARK ? "theme-dark" : "link"}
        className={classNames("btn btn-md btn-back")}>
        <div
          className="d-inline-flex flex-row align-items-center"
          style={{ flexWrap: "nowrap" }}>
          <CustomIcon
            className={classNames(
              "rotate-180",
              theme === THEMES.DARK ? "icon-light-gray" : "icon-denim-blue"
            )}
            icon="drawer_chevron"
            size="12"
          />
          <div className="pl-1">Back</div>
        </div>
      </Button>
    </div>
  );
};

export default BackButton;
