import React from "react";
import Skeleton from "react-loading-skeleton";
import { GRID_SETTINGS } from "../../../constants/saved-cases-constants";
import F1GridList from "../../../components/common/F1GridList";

const SavedCasesLoading = () => {
  const data = new Array(9).fill();
  return (
    <F1GridList
      data={data}
      numColumns={GRID_SETTINGS.NUM_COLUMNS}
      gap={GRID_SETTINGS.COLUMN_GAP}
      renderItem={({ index }) => (
        <div key={index} className="square-image">
          <div className="image">
            <Skeleton className="h-100 w-100" />
          </div>
        </div>
      )}
    />
  );
};

SavedCasesLoading.propTypes = {};

export default SavedCasesLoading;
