import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Button, Card } from "reactstrap";

import Figure1Layout from "../../@layouts/Figure1Layout";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import i18n from "../../utils/i18n";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import VerificationPhotoUploadV2 from "./components/VerificationPhotoUpload.v2";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import "./PhotoUploadPage.scss";
import SideBarUserDetails from "./components/SideBarUserDetails";
import useIsAStudent from "../../hooks/useIsAStudent";
import EmailVerificationModal from "./components/EmailVerificationModal";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import { trackPhotoPageEntry } from "../../actions/metrics.actions";

const PhotoUploadPage = () => {
  /** ********************************** CONFIG ***************************************/
  const isStudent = useIsAStudent();
  const [modalOpen, setModalOpen] = useState(false);
  /** ********************************* FUNCTIONS *************************************/

  useEffect(() => {
    trackPhotoPageEntry();
  }, []);

  /** ********************************** RENDER ***************************************/

  const checkListItems = isStudent ? (
    <>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.student.checklist.item1"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.student.checklist.item2"
        )}
      </li>
    </>
  ) : (
    <>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item1"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item2"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item3"
        )}
      </li>
    </>
  );

  const mainContent = (
    <>
      <Card className="p-3 p-lg-4 rounded-0">
        <h1 className="text-18 helv-bold mb-4">
          {i18n.t("RegistrationScreens.verificationPhotoPage.v2.title")}
        </h1>
        <ul className="check-list">{checkListItems}</ul>
        <div className="alert alert-aqua rounded text-battleship-gray p-2 d-flex align-items-center border-0">
          <CustomIcon
            icon={"verification_checkshield_web"}
            size={28}
            className="mr-2"
          />
          {isStudent &&
            i18n.t(
              "RegistrationScreens.verificationPhotoPage.v2.student.badgeText"
            )}
          {!isStudent &&
            i18n.t("RegistrationScreens.verificationPhotoPage.v2.badgeText")}
        </div>
        <VerificationPhotoUploadV2 />
      </Card>
      {isStudent && (
        <>
          <div className="text-center text-battleship-gray my-3">
            <p className="text-16 m-0">
              {i18n.t(
                "RegistrationScreens.verificationPhotoPage.v2.student.noPhotoMessage"
              )}
            </p>
            <Button
              className="text-16"
              color="link"
              onClick={() => setModalOpen(true)}>
              {i18n.t(
                "RegistrationScreens.verificationPhotoPage.v2.student.btnSubmit"
              )}
            </Button>
          </div>
          {modalOpen && (
            <EmailVerificationModal
              onCancel={() => {
                setModalOpen(false);
              }}
              isOpen={modalOpen}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      {isMobile && (
        <Figure1Page1Col
          hideBackButton={true}
          headerClass="py-0"
          mainContentSmall={
            <>
              <SideBarUserDetails />
              {mainContent}
            </>
          }
        />
      )}

      {!isMobile && (
        <Figure1Page3Col
          leftSidebarContent={<SideBarUserDetails />}
          mainContent={mainContent}
          hideBackButton={true}
          headerClass="py-0"
        />
      )}
    </Figure1Layout>
  );
};

export default PhotoUploadPage;
