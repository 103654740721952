/** @format */

import React, { useState } from "react";
import { Button, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CustomIcon from "../../../components/custom-icon/CustomIcon";
import Dialog from "../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../components/dialog/DialogType";
import Regex from "../../../constants/regex";
import { isEmpty, uniq } from "lodash";
import { showErrorMessage } from "../../../actions/global.actions";
import { sendInvites } from "../../../actions/InviteColleagues.actions";
import {
  trackInviteColleaguesAddEmail,
  trackInviteColleaguesRemoveEmail,
  trackInviteColleaguesSubmission
} from "../../../actions/metrics.actions";
import i18n from "../../../utils/i18n";

const MAX_EMAIL = 15;

const InviteColleaguesModal = ({ isOpen, toggle }) => {
  const [emails, setEmails] = useState({ email0: "" });
  const [emailErrors, setEmailErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const isProcessing = useSelector(
    (state) => state.inviteColleages.isProcessing
  );
  const processingError = useSelector((state) => state.inviteColleages.error);

  const dispatch = useDispatch();

  const canAddEmails = Object.keys(emails).length < MAX_EMAIL;
  const hasFirstMail = emails["email0"].length > 0;

  const canSendEmails =
    hasFirstMail &&
    Object.keys(emails).length <= MAX_EMAIL &&
    Object.keys(emailErrors).length === 0;

  const [keyIndex, setKeyIndex] = useState(0);

  const validateEmail = (email, key) => {
    const isEmail = Regex.SimpleEmail.test(email);
    const newErrors = { ...emailErrors };
    if (isEmail) {
      delete newErrors[key];
    } else {
      newErrors[key] = i18n.t("InviteColleagues.invalidEmail");
    }
    setEmailErrors(newErrors);
  };

  const onSubmit = () => {
    try {
      const allEmails = uniq(
        Object.values(emails).filter((email) => !isEmpty(email))
      );
      if (allEmails.length) {
        trackInviteColleaguesSubmission(allEmails);
        dispatch(sendInvites({ emails: allEmails }));
        setSubmitted(true);
      }
    } catch (e) {
      dispatch(showErrorMessage(e.message, "", false, e));
    }
  };

  const onClose = () => {
    toggle();
  };

  const onEmailAdd = () => {
    const newKey = `email${keyIndex + 1}`;
    setEmails({ ...emails, [newKey]: "" });
    setKeyIndex(keyIndex + 1);
    trackInviteColleaguesAddEmail();
  };

  const onFieldChange = (e, key) => {
    const newEmails = { ...emails };
    newEmails[key] = e.target.value;
    setEmails(newEmails); // debounce?
  };

  const onFieldRemove = (key) => {
    const newEmails = { ...emails };
    const newErrors = { ...emailErrors };
    delete newEmails[key];
    delete newErrors[key];
    setEmails(newEmails);
    setEmailErrors(newErrors);
    trackInviteColleaguesRemoveEmail();
  };

  const onBlurInput = (e, index) => {
    if (e.target.value?.length > 0) {
      validateEmail(e.target.value, index);
    }
  };

  const ErrorLine = ({ text }) => <p className="my-1 text-danger">{text}</p>;

  const emailList = (
    <ul className="list-unstyled m-0 p-0">
      {Object.entries(emails).map(([key, value], index) => {
        return (
          <li key={key} className="mb-1">
            {index === 0 && (
              <Label>{i18n.t("InviteColleagues.emailLabel")}</Label>
            )}
            <div
              className={`d-flex ${index === 0 ? "flex-column" : "flex-row"}`}>
              <Input
                className={`email-input ${
                  emailErrors[key] ? "is-invalid" : ""
                }`}
                value={value}
                placeholder={i18n.t("InviteColleagues.emailPlaceholder")}
                onChange={(e) => {
                  onFieldChange(e, key);
                }}
                onBlur={(e) => onBlurInput(e, key)}
              />
              {index === 0 && emailErrors[key] && (
                <ErrorLine text={emailErrors[key]} />
              )}
              {index > 0 && (
                <Button color="link" onClick={() => onFieldRemove(key)}>
                  <CustomIcon
                    icon="minus_icon"
                    className="icon-red"
                    size={16}
                  />
                </Button>
              )}
            </div>
            {index > 0 && emailErrors[key] && (
              <ErrorLine text={emailErrors[key]} />
            )}
          </li>
        );
      })}
    </ul>
  );

  if (submitted && !isProcessing && !processingError) {
    return (
      <Dialog
        centered
        isOpen={isOpen}
        onCancel={toggle}
        onConfirm={onClose}
        confirmText={i18n.t("InviteColleagues.confirmDialog.button")}
        type={DIALOG_TYPE.GATED}
        icon="web-bar_save_filled"
        title={i18n.t("InviteColleagues.confirmDialog.title")}
        body={i18n.t("InviteColleagues.confirmDialog.body")}
      />
    );
  }
  console.log("emails.length", emails.length);
  return (
    <Dialog
      centered
      isOpen={isOpen}
      onCancel={toggle}
      onConfirm={onSubmit}
      confirmDisabled={!canSendEmails || isProcessing}
      confirmLoading={isProcessing}
      confirmText={i18n.t(
        Object.keys(emails).length === 1
          ? "InviteColleagues.dialog.buttonSingular"
          : "InviteColleagues.dialog.buttonPlural"
      )}
      type={DIALOG_TYPE.INFORM}
      title={i18n.t("InviteColleagues.dialog.title")}
      body={
        <>
          <div className="bg-lighter-gray p-3">
            “{i18n.t("InviteColleagues.dialog.previewText")}”
          </div>
          <form className="my-3 w-100">
            {emailList}
            {canAddEmails && (
              <Button color="link" className="pl-0" onClick={onEmailAdd}>
                <CustomIcon
                  icon="plus_sign"
                  className="icon-denim-blue"
                  size={16}
                />{" "}
                {i18n.t("InviteColleagues.addEmail")}
              </Button>
            )}
            {!canAddEmails && (
              <p className="text-battleship-gray">
                {i18n.t("InviteColleagues.emailMax")}
              </p>
            )}
          </form>

          {processingError && (
            <div className="alert alert-danger w-100">
              <p className="m-0">{processingError}</p>
            </div>
          )}
        </>
      }
    />
  );
};

export default InviteColleaguesModal;
