import React, { useLayoutEffect } from "react";
import ScrollRestoreContext from "./ScrollRestoreContext";

const useScrollRestore = () => {
  const { scrollRef, saveScrollPosition } = React.useContext(
    ScrollRestoreContext
  );

  useLayoutEffect(() => {
    const currentScrollRef = scrollRef.current;
    return () => {
      if (currentScrollRef) {
        saveScrollPosition(
          currentScrollRef.scrollLeft,
          currentScrollRef.scrollTop
        );
      }
    };
  }, [saveScrollPosition, scrollRef]);

  return scrollRef;
};

export default useScrollRestore;
