import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import {
  fetchProfessionsIfNeeded,
  fetchProfessionSpecialtiesIfNeeded
} from "../../../actions/reference.actions";
import { signUp } from "../../../actions/non-user.actions";
import ModalWithText from "../../../components/common/ModalWithText";
import { termsAndConditions } from "../../../assets/files/termsAndConditions";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import FormElementName from "./FormElementName";
import FormElementProfession from "./FormElementProfession";
import FormElementEmail from "./FormElementEmail";
import FormElementPassword from "./FormElementPassword";
import { has } from "lodash";
import {
  OTHER_PROFESSION_CATEGORY_LABELS,
  PROFESSION_CATEGORY_LABELS,
  LEGACY_ONLY_PROFESSIONS
} from "../../../constants/profession-category-labels";
import { signOut, toggleStateListeners } from "../../../actions/login.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import TOAST_TYPE from "../../../components/popup/ToastType";
import usePopup from "../../../components/popup/usePopup";
import { StaticStrings } from "../../../constants/static-string-constants";
import ExternalTextLink from "./ExternalTextLink";
import { useSelectProfessionSpecialties } from "../../../selectors/references.selectors";

const RegistrationForm = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  let history = useHistory();
  const { showToast } = usePopup();

  const { handleSubmit, control, errors, formState, getValues, watch } =
    useForm({
      mode: "onChange"
    });
  const watchProfession = watch("profession");
  const watchSpecificProfession = watch("specificProfession");

  const professions = useSelector((state) => state?.references?.professions);
  const [isOpen, setIsOpen] = useState(false);
  const [professionList, setProfessionList] = useState(null);
  const [specificProfessionList, setSpecificProfessionList] = useState(null);
  const [showSpecificProfession, setShowSpecificProfession] = useState(false);
  const [dataModel, setDataModel] = useState(null);
  const [professionUuid, setProfessionUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState(null);

  const { data } = useSelectProfessionSpecialties(
    watchSpecificProfession?.value
  );
  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    dispatch(fetchProfessionsIfNeeded());
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    if (watchProfession?.value) {
      const formValues = getValues();

      const showOther = OTHER_PROFESSION_CATEGORY_LABELS.includes(
        formValues.profession.value
      );

      setSelectedProfession(formValues.profession.value);

      setShowSpecificProfession(showOther);

      const profList =
        professions?.specificProfessions?.[formValues.profession.value];
      setSpecificProfessionList(profList);

      setProfessionUuid(profList[0].value);

      // Only get specialties for Other professions.
      if (watchSpecificProfession?.value) {
        dispatch(
          fetchProfessionSpecialtiesIfNeeded(watchSpecificProfession?.value)
        );
      }
    }
    // eslint-disable-next-line
  }, [dispatch, professions, watchProfession, watchSpecificProfession]);

  useEffect(() => {
    if (professions) {
      const validProfessionsCategories = (professions?.categories || []).filter(
        (p) =>
          has(p, "hidden")
            ? !p.hidden
            : ![
                ...LEGACY_ONLY_PROFESSIONS,
                PROFESSION_CATEGORY_LABELS.NON_HCP
              ].includes(p.value)
      );

      setProfessionList(validProfessionsCategories);
    }
  }, [professions]);

  useEffect(() => {
    setDataModel({
      firstName: "",
      lastName: ""
    });
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (formData) => {
    setIsLoading(true);

    const professionPayload = showSpecificProfession
      ? formData.specificProfession?.value
      : professionUuid;

    const otherProfessionSpecialtyTreeUuid = showSpecificProfession
      ? data?.[0]?.treeUuid
      : null;

    const result = await dispatch(
      signUp(
        formData.email,
        formData.password,
        formData.firstName,
        formData.lastName,
        professionPayload,
        otherProfessionSpecialtyTreeUuid
      )
    );

    if (result.error && result.message) {
      setIsLoading(false);
      showToast({
        message: result.message,
        toastType: TOAST_TYPE.ERROR
      });
    } else {
      dispatch(toggleStateListeners(result.authResult?.user));
      history.push(ROUTES.REGISTRATION_PRIVACY);
    }
  };

  const handleGoToLogin = () => {
    history.push(getUrl(ROUTES.REGISTRATION_LOGIN));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {dataModel && (
        <Card className="p-4 mb-4 rounded-0">
          <form onSubmit={handleSubmit(handleSubmitClick)}>
            <FormElementName
              errors={errors}
              control={control}
              model={{ firstName: "", lastName: "" }}
            />
            <FormElementEmail
              errors={errors}
              control={control}
              showFeedback={true}
            />
            <FormElementPassword
              control={control}
              errors={errors}
              isLoading={isLoading}
            />
            <FormElementProfession
              name="profession"
              label={i18n.t("RegistrationScreens.profileSetup.professionLabel")}
              placeholder={i18n.t(
                "RegistrationScreens.profileSetup.professionPlaceholder"
              )}
              errors={errors}
              control={control}
              professionList={professionList}
              groupClassName="mt-0 pt-0"
            />
            {showSpecificProfession && (
              <FormElementProfession
                name="specificProfession"
                label={
                  selectedProfession === "otherstudent"
                    ? i18n.t(
                        "RegistrationScreens.profileSetup.otherStudentLabel"
                      )
                    : i18n.t(
                        "RegistrationScreens.profileSetup.specificProfessionLabel"
                      )
                }
                placeholder={
                  selectedProfession === "otherstudent"
                    ? i18n.t(
                        "RegistrationScreens.profileSetup.otherStudentPlaceholder"
                      )
                    : i18n.t(
                        "RegistrationScreens.profileSetup.professionPlaceholder"
                      )
                }
                errors={errors}
                control={control}
                professionList={specificProfessionList}
              />
            )}
            <p className="w-100 mt-4 mb-4 text-battleship-gray">
              {i18n.t(
                "RegistrationScreens.createRegistration.byCreatingAccount"
              )}
              <ExternalTextLink
                className="text-cool-blue cursor-pointer"
                url={StaticStrings.termsOfServiceUrl}
                linkText={i18n.t(
                  "RegistrationScreens.createRegistration.termsOfService"
                )}
              />
            </p>
            <LoadingButton
              color="primary"
              loading={isLoading}
              spinnerColor="white"
              disabled={!formState.isValid || isLoading}>
              {i18n.t("RegistrationScreens.createRegistration.btnJoinNow")}
            </LoadingButton>
            <p className="d-flex w-100 mt-4 mb-4">
              <span className="text-battleship-gray">
                {i18n.t("RegistrationScreens.createRegistration.alreadyMember")}{" "}
              </span>

              <span
                className="text-cool-blue cursor-pointer pl-1"
                onClick={handleGoToLogin}>
                {i18n.t("RegistrationScreens.createRegistration.signIn")}
              </span>
            </p>
          </form>
        </Card>
      )}

      <ModalWithText
        title={i18n.t("RegistrationScreens.termsAndConditions")}
        toggle={() => setIsOpen((isOpen) => !isOpen)}
        isOpen={isOpen}>
        <p className="text-16">{termsAndConditions.text}</p>
      </ModalWithText>
    </>
  );
};

export default RegistrationForm;
