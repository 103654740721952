import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { get } from "lodash";
import { uploadAvatarImage } from "../../../actions/user-profiles.actions";
import usePopup from "../../../components/popup/usePopup";
import TOAST_TYPE from "../../../components/popup/ToastType";
import DIALOG_TYPE from "../../../components/dialog/DialogType";
import Dialog from "../../../components/dialog/Dialog";

const ProfileEditImageModal = ({ toggle, isOpen, userUuid, ...rest }) => {
  /************************************ CONFIG ***************************************/

  const { t } = useTranslation();
  const { showToast } = usePopup();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const isUploading = useSelector((state) => state.userProfiles.isUploading);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const selectFile = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onUpload = async (event) => {
    event.preventDefault();
    try {
      const file = get(event, "target.files[0]");

      const result = await dispatch(uploadAvatarImage(file));

      if (result.error) {
        showToast({
          toastType: TOAST_TYPE.ERROR,
          message: t("Profile.uploadAvatarError")
        });
      } else {
        toggle();
      }
    } catch (error) {
      showToast({
        toastType: TOAST_TYPE.ERROR,
        message: t("Profile.uploadAvatarError")
      });
    }
  };

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      type={DIALOG_TYPE.CONFIRM}
      isOpen={isOpen}
      title={t("Profile.editAvatarTitle")}
      body={
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={onUpload}
          accept="image/*"
          style={{ display: "none" }}
        />
      }
      confirmText={t("Profile.uploadAvatar")}
      confirmLoading={isUploading}
      confirmDisabled={isUploading}
      onConfirm={selectFile}
      onCancel={toggle}
      onClosed={toggle}
      toggle={toggle}
      {...rest}
    />
  );
};

ProfileEditImageModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired
};

ProfileEditImageModal.defaultProps = {};

export default ProfileEditImageModal;
