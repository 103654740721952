/** @format */

export const PROFILE_INFO_TYPE = {
  EXPERIENCE: "experience",
  EDUCATION: "education",
  AFFILIATIONS: "affiliations",
  SUBSPECIALTIES: "subspecialties",
  PRACTICE_AREA: "practice_area"
};

export const PROFILE_INFO_CARD_STATE = {
  DISPLAY: "DISPLAY",
  EDIT: "EDIT"
};

export const PROFILE_USER_TYPES = {
  FIGURE1MEMBER: "Figure1Member",
  FIGURE100: "Figure100",
  FIGURE1FRIENDS: "Figure1Friends",
  FIGURE1INTERNAL: "Figure1Internal",
  FIGURE1OFFICIAL: "Figure1Official",
  FIGURE1PAIDCONTRIBUTOR: "Figure1PaidContributor",
  FIGURE1EDITORIALPARTNER: "Figure1EditorialPartner",
  FIGURE1EDITORIALSUBSCRIBER: "Figure1EditorialSubscriber",
  SPONSOREDUSER: "SponsoredUser",
  INSTITUTIONALUSER: "InstitutionalUser"
};
