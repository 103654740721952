import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

const LoadingButton = ({
  children,
  loading = false,
  spinnerSize = "sm",
  spinnerColor = "primary",
  ...rest
}) => {
  return (
    <Button {...rest}>
      {loading ? <Spinner size={spinnerSize} color={spinnerColor} /> : children}
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  spinnerSize: PropTypes.string,
  spinnerColor: PropTypes.string
};

export default LoadingButton;
