/** @format */

const CASE_TYPE = {
  STATIC: "static",
  QUIZ: "quiz",
  QUIZ_SERIES: "quiz_series",
  CLINICAL_MOMENTS: "clinical_moments",
  CME: "cme"
};

export const INTERACTIVE_CASE_TYPES = [
  CASE_TYPE.QUIZ,
  CASE_TYPE.QUIZ_SERIES,
  CASE_TYPE.CLINICAL_MOMENTS,
  CASE_TYPE.CME
];

export const PREVIEW_FEED_TYPE = {
  TRENDING_CASES: "trendingCases",
  NEW_CASES: "newCases"
};

export const QUESTION_CASE_TYPES = [CASE_TYPE.QUIZ, CASE_TYPE.QUIZ_SERIES];

export default CASE_TYPE;
