import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import i18n from "../../../utils/i18n";
import { Controller } from "react-hook-form";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Regex from "../../../constants/regex";

const FormElementEmail = ({
  value,
  control,
  errors,
  showFeedback,
  disabled = false
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <FormGroup>
      <Label for="email">
        {i18n.t("RegistrationScreens.common.emailLabel")}
      </Label>
      <Controller
        as={Input}
        control={control}
        id="email"
        name="email"
        type="text"
        placeholder={i18n.t("RegistrationScreens.common.emailPlaceholder")}
        rules={{ required: true, pattern: Regex.SimpleEmail }}
        invalid={!!errors.email}
        defaultValue={value || ""}
        disabled={disabled}
      />
      {showFeedback && (
        <FormFeedback>
          {i18n.t("RegistrationScreens.common.emailRequired")}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormElementEmail;
