/** @format */

import Firebase from "../firebase";

const functions = Firebase.functions;

export const loadFeed = async (
  feedTypeUuid,
  start,
  filters,
  feedMode = false
) => {
  const callableFn = functions.httpsCallable("backendFeedsLoadFeed");
  const props = {
    return_feed: feedMode,
    feedTypeUuid: feedTypeUuid,
    ...filters
  };
  if (start !== null) {
    props.start = start;
  }

  const result = await callableFn(props);
  return result.data;
};

export const searchInFeed = async (
  feedTypeUuid,
  searchFeedTypeUuid,
  searchTerms,
  start
) => {
  const result = await functions.httpsCallable("backendFeedsSearchInFeed")({
    feedTypeUuid: feedTypeUuid,
    searchFeedTypeUuid: searchFeedTypeUuid,
    searchTerms: searchTerms,
    start: start
  });
  return result.data;
};
