import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import F1CarouselControlButton from "./F1CarouselControlButton";
import useSwipe from "../../hooks/useSwipe";

const MIN_INDEX = 0;

const F1Carousel = ({
  children,
  className = "",
  nextItemVisiblePercent = 0.2,
  colSize = 4
}) => {
  /****************************** Config **************************************/
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const numChildren = React.Children.count(children);
  const maxIndex = numChildren ? numChildren - colSize : 0;

  /****************************** Hooks ***************************************/
  useEffect(() => {
    const childNode = ref.current?.childNodes[activeIndex];
    if (childNode) {
      ref.current?.scroll({
        left: childNode.offsetLeft - 1,
        behavior: "smooth"
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    const containerWidth = ref.current?.getBoundingClientRect()?.width || 800;
    const shrunkContainerWidth = containerWidth * (1 - nextItemVisiblePercent);
    const colWidth = shrunkContainerWidth / colSize;
    setWidth(colWidth);
  }, [colSize, nextItemVisiblePercent]);

  /****************************** Functions ***********************************/

  const prev = () => {
    setActiveIndex((prevState) => Math.max(prevState - 2, MIN_INDEX));
  };

  const next = () => {
    setActiveIndex((prevState) => Math.min(prevState + 2, maxIndex));
  };

  const { elementRef } = useSwipe({
    enableTouch: isMobile,
    onSwipeLeft: next,
    onSwipeRight: prev
  });

  /****************************** Render **************************************/

  return (
    <div
      className={`position-relative d-flex w-100 ${className}`}
      ref={elementRef}>
      <div
        className="d-inline-flex justify-content-start flex-nowrap w-100 overflow-hidden"
        ref={ref}>
        {React.Children.map(children, (child, index) => {
          return (
            <div
              key={child.key ?? index}
              className="mr-3"
              style={{
                minWidth: width,
                maxWidth: width,
                width,
                flexBasis: width
              }}>
              {child}
            </div>
          );
        })}
      </div>

      {activeIndex !== MIN_INDEX && !isMobile && (
        <div className="f1-carousel-control f1-carousel-control--prev d-flex align-items-center justify-content-start ml-n3">
          <F1CarouselControlButton
            className="f1-carousel-control-btn mt-0"
            direction="prev"
            directionText="Previous"
            onClickHandler={prev}
            buttonColor="white"
            iconColor="darkest"
          />
        </div>
      )}

      {activeIndex !== maxIndex && !isMobile && (
        <div className="f1-carousel-control f1-carousel-control--next d-flex align-items-center justify-content-end">
          <F1CarouselControlButton
            className="f1-carousel-control-btn mt-0"
            direction="next"
            directionText="Next"
            onClickHandler={next}
            buttonColor="white"
            iconColor="darkest"
          />
        </div>
      )}
    </div>
  );
};

F1Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  nextItemVisiblePercent: PropTypes.number,
  colSize: PropTypes.number
};

export default F1Carousel;
