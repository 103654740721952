/** @format */

import * as specialtyDB from "../db/specialty.db";
import { get } from "lodash";

const actionsPrefix = "caseSpecialties";

export const FETCH_SPECIALTIES_START = `${actionsPrefix}/FETCH_SPECIALTIES_START`;
export const FETCH_SPECIALTIES_COMPLETE = `${actionsPrefix}/FETCH_SPECIALTIES_COMPLETE`;
export const ERROR = `${actionsPrefix}/ERROR`;

export const fetchSpecialties = () => {
  return async (dispatch, getState) => {
    if (getState().caseSpecialties.fetched) {
      return;
    }

    dispatch({
      type: FETCH_SPECIALTIES_START
    });
    try {
      const docs = await specialtyDB.fetchTaggingSpecialties();

      let specialties = { level0: [], level1: [], level2: [] };

      for (const key in docs) {
        const doc = docs[key];
        switch (doc.depth) {
          case 0:
            specialties.level0.push(doc);
            break;
          case 1:
            specialties.level1.push(doc);
            break;
          case 2:
            specialties.level2.push(doc);
            break;
          default:
            console.log("unknown level for ", JSON.stringify(doc));
        }
      }

      dispatch({
        type: FETCH_SPECIALTIES_COMPLETE,
        fetched: true,
        specialties: specialties,
        error: false,
        message: "case specialties complete"
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        error: true,
        message: error.message
      });
    }
  };
};

export const getSpecialtiesOptions = () => {
  return (dispatch, getState) => {
    const specialtyOptions = get(
      getState().caseSpecialties.specialties,
      "level1",
      []
    )
      .map((spe) => ({
        label: spe.name,
        value: spe.uuid
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    const subSpecialtyOptions = get(
      getState().caseSpecialties.specialties,
      "level2",
      []
    )
      .map((subSpe) => ({
        label: subSpe.name,
        value: subSpe.uuid
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      specialties: specialtyOptions,
      subSpecialties: subSpecialtyOptions
    };
  };
};
