import React, { useEffect } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import RegistrationCountryFormV2 from "./components/RegistrationCountryForm.v2";
import { trackCountryPageEntry } from "../../actions/metrics.actions";

const RegistrationCountryPageV2 = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* FUNCTIONS *************************************/

  useEffect(() => {
    trackCountryPageEntry();
  }, []);

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-4 shadow">
            <RegistrationCountryFormV2 />
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default RegistrationCountryPageV2;
