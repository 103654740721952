import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupsInfoIfNeeded } from "../actions/reference.actions";
import { FEED_META_LABELS, FEED_NAMES } from "../constants/feed-type";

const useCurrentFeedTypeUuid = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const feedMeta = useSelector((state) => state?.references?.feedsMeta?.data);
  const groupFeedUuid = useSelector(
    (state) =>
      state.references.groups?.data?.[routeParams.feedTypeUuid]?.feedTypeUuid
  );

  const getFeedUuidByLabel = (label) =>
    Object.values(feedMeta || {})?.find((m) => m.label === label)
      ?.feed_type_uuid;

  useEffect(() => {
    dispatch(getGroupsInfoIfNeeded());
  }, [dispatch]);

  if (routeParams?.feed === FEED_NAMES.EVERYTHING) {
    return getFeedUuidByLabel(FEED_NAMES.EVERYTHING);
  } else if (routeParams?.feed === FEED_NAMES.RECOMMENDED) {
    return getFeedUuidByLabel(FEED_META_LABELS.RECOMMENDED);
  } else if (routeParams?.feed === FEED_NAMES.TRENDING) {
    return getFeedUuidByLabel(FEED_META_LABELS.TRENDING);
  } else if (routeParams?.feed === FEED_NAMES.TOPIC) {
    return routeParams.feedTypeUuid;
  } else if (routeParams?.feed === FEED_NAMES.GROUP) {
    return groupFeedUuid;
  } else {
    return null;
  }
};

export default useCurrentFeedTypeUuid;
