/** @format */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CaseCarouselContainer from "./CaseCarouselContainer";
import { useDispatch, useSelector } from "react-redux";
import CaseCarouselHeader from "./CaseCarouselHeader";
import { getPreviewFeedIfNeeded } from "../../../../actions/reference.actions";
import { slice } from "lodash";

const PREVIEW_COUNT = 6;

const NormalPreviewFeedCard = ({ previewFeedName, previewFeedTypeUuid }) => {
  /****************************** Config **************************************/
  const dispatch = useDispatch();
  const cases = useSelector((state) =>
    slice(state.references.feeds?.[previewFeedTypeUuid]?.data, 0, PREVIEW_COUNT)
  );

  /****************************** Hooks ***************************************/
  useEffect(() => {
    dispatch(
      getPreviewFeedIfNeeded({
        feedTypeUuid: previewFeedTypeUuid
      })
    );
  }, [dispatch, previewFeedTypeUuid]);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <CaseCarouselContainer
      cases={cases}
      context={previewFeedName}
      headerElement={
        <CaseCarouselHeader
          title={previewFeedName}
          feedUuid={previewFeedTypeUuid}
        />
      }
      colSize={2}
      nextItemVisiblePercent={0.19}
      className="pl-3"
    />
  );
};

NormalPreviewFeedCard.propTypes = {
  headerElement: PropTypes.node,
  contentType: PropTypes.string,
  feedCardType: PropTypes.string,
  previewFeedLabel: PropTypes.string,
  previewFeedName: PropTypes.string,
  previewFeedTypeUuid: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default NormalPreviewFeedCard;
