/** @format */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";

const PreviousQuestionButton = ({ className, onClick }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Button
      className={classNames(
        className,
        "btn-text d-inline-flex justify-content-center align-items-center"
      )}
      color="denim-blue"
      onClick={onClick}>
      <CustomIcon
        className="icon-denim-blue mr-2 rotate-180"
        icon="drawer_chevron"
        size={12}
      />
      {i18n.t("questionDetailCard.previousQuestion")}
    </Button>
  );
};

PreviousQuestionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default PreviousQuestionButton;
