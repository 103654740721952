/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const fetchCase = async (caseId) => {
  const ref = getCollectionRef(DB_CONSTANTS.CASES_V2).doc(caseId);
  const caseDoc = await ref.get();
  return caseDoc.exists ? caseDoc.data() : null;
};

export const listenForDetailsUpdate = async (caseId, callback) => {
  return getCollectionRef(DB_CONSTANTS.CASES_V2)
    .doc(caseId)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const listenForUserActionsUpdate = async (
  caseUuid,
  userUid,
  callback
) => {
  return getCollectionRef(`${DB_CONSTANTS.CASES_V2}/${caseUuid}/userActions`)
    .doc(userUid)
    .onSnapshot(callback, (onObservableNextOrError) => {
      console.error("onObservableNextOrError: ", onObservableNextOrError);
    });
};

export const listenForRelatedCases = async (caseUuid, callback) => {
  return getCollectionRef(
    `${DB_CONSTANTS.CASES_V2}/${caseUuid}/relatedCases`
  ).onSnapshot(callback, (onObservableNextOrError) => {
    console.error("onObservableNextOrError: ", onObservableNextOrError);
  });
};

export const listenForCasePublications = async (caseUuid, callback) => {
  return getCollectionRef(
    `${DB_CONSTANTS.CASES_V2}/${caseUuid}/casePublications`
  ).onSnapshot(callback, (onObservableNextOrError) => {
    console.error("onObservableNextOrError: ", onObservableNextOrError);
  });
};
