/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { isEmpty } from "lodash";
import { activateListener } from "../../actions/saved-cases.actions";
import i18n from "../../utils/i18n";
import SavedCasesEmpty from "./components/SavedCasesEmpty";
import SavedCasesFound from "./components/SavedCasesFound";
import SavedCasesLoading from "./components/SavedCasesLoading";
import TOAST_TYPE from "../../components/popup/ToastType";
import usePopup from "../../components/popup/usePopup";
import { SAVED_CASE_TYPES } from "../../constants/saved-cases-constants";
import { trackSavedCasesEntry } from "../../actions/metrics.actions";

const SavedCasesScreen = () => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const userUid = useSelector((state) => state.user.userUid);
  const savedCases = useSelector(
    (state) => state.savedCases.data[SAVED_CASE_TYPES.ALL]
  );
  const savedCasesError = useSelector((state) =>
    state.savedCases.error[SAVED_CASE_TYPES.ALL]
      ? state.savedCases.message[SAVED_CASE_TYPES.ALL]
      : null
  );
  const isLoading = useSelector(
    (state) => state.savedCases.isProcessing[SAVED_CASE_TYPES.ALL]
  );

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (!isEmpty(savedCasesError)) {
      showToast({
        message: savedCasesError,
        toastType: TOAST_TYPE.ERROR
      });
    }
  }, [showToast, savedCasesError]);

  useEffect(() => {
    dispatch(activateListener(userUid, SAVED_CASE_TYPES.ALL));
  }, [dispatch, userUid, savedCases]);

  useEffect(() => {
    trackSavedCasesEntry();
  }, []);
  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Card className="border-0 rounded-0">
      <CardBody>
        <CardTitle
          className="text-15 text-default-black pb-4 pt-2 mb-0"
          tag="h5">
          {i18n.t("SavedCases.savedCases", {
            count: savedCases.length
          })}
        </CardTitle>
        <Card className="border-0">
          {isEmpty(savedCases) ? (
            isLoading ? (
              <SavedCasesLoading />
            ) : (
              <SavedCasesEmpty />
            )
          ) : (
            <SavedCasesFound cases={savedCases} isLoading={isLoading} />
          )}
        </Card>
      </CardBody>
    </Card>
  );
};

export default SavedCasesScreen;
