import React from "react";
import { getUrl } from "../../utils/route-utils";
import { SIGN_UP } from "../../constants/routes";
import { Link } from "react-router-dom";
import i18n from "../../utils/i18n";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";

const GatedCaseNotice = ({ visible = false }) => {
  /** ********************************** CONFIG ***************************************/

  const { currentCount, countLimit } = useGatedBlockCounter();
  const computedCount = countLimit - currentCount + 1; // lag by 1
  const textKey =
    currentCount === countLimit
      ? "Gated.CaseLimit.lastChanceText"
      : "Gated.CaseLimit.countText";
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!visible) return null;

  return (
    <div className="alert bg-lighter-gray-alt text-14 px-3 py-2 helv-med">
      {i18n.t(textKey).replace("$COUNT", computedCount)}{" "}
      <Link to={getUrl(SIGN_UP)}> {i18n.t("Gated.CaseLimit.signUpText")}</Link>
    </div>
  );
};

export default GatedCaseNotice;
