import React, { useEffect, useMemo, useRef, useState } from "react";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import PropTypes from "prop-types";
import "./style.scss";
import { MediaProp } from "../../prop-types/MediaProp";
import classNames from "../../utils/class-names-utils";
import CaseMediaVideo from "./CaseMediaVideo";
import CaseMediaPhoto from "./CaseMediaPhoto";
import F1CarouselControlButton from "../common/F1CarouselControlButton";
import { trackMediaViewTraversal } from "../../actions/metrics.actions";
import ConditionalLink from "../common/ConditionalLink";
import { getMaxHeightDimension } from "../../utils/media-utils";

const MAX_WIDTH = 577;

const CaseMediaView = (props) => {
  /** ********************************** SETUP *****************************************/

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [maxHeight, setMaxHeight] = useState(undefined);
  const maxIndex = useRef(0);
  const activeIndexRef = useRef(0);
  const constrainImageSize =
    !props.disabled && props.showImages && props.media.length > 1;
  const media = useMemo(() => {
    return props?.media || [];
  }, [props]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (activeIndex > maxIndex.current) {
      maxIndex.current = activeIndex;
    }
    activeIndexRef.current = activeIndex;
  }, [activeIndex]);

  useEffect(() => {
    return () => {
      if (maxIndex.current > 0) {
        callMetrics();
      }
    };
    //eslint-disable-next-line
  }, [media, props.caseUuid, props.context]);

  useEffect(() => {
    if (constrainImageSize) {
      const imageUrls = media.reduce((urls, item) => {
        if (item.type === "image") {
          urls.push(`${item.url}?w=${Math.min(window.innerWidth, MAX_WIDTH)}`);
        }

        return urls;
      }, []);

      getMaxHeightDimension(imageUrls)
        .catch((e) => {
          console.log("Error calculating max height dimension", e);
          return undefined;
        })
        .then((dimension) => {
          setMaxHeight(dimension?.height);
        });
    }
  }, [constrainImageSize, media]);

  /** ******************************** FUNCTIONS ****************************************/

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === media?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? media?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const callMetrics = () => {
    const item = media[activeIndex];

    trackMediaViewTraversal({
      contentUuid: item.contentUuid,
      mediaCount: media.length,
      maxPosition: maxIndex.current + 1, // offset for zero-based index
      context: props.context,
      currentPosition: activeIndexRef.current + 1
    });
  };

  /** ******************************** RENDER ****************************************/
  const slides = (
    props.disabled ? (media?.length ? [media[0]] : []) : media
  ).map((item) => {
    const slideContent =
      item.type === "image" ? (
        props.showImages ? (
          <img
            className="carousel-img object-contain bg-lighter-gray"
            // display images are being constrained so imgix can give us an optomized image
            src={`${item.url}?w=${MAX_WIDTH}`}
            alt=""
            style={{ height: constrainImageSize ? maxHeight : undefined }}
          />
        ) : (
          <CaseMediaPhoto
            url={item.url}
            width={item.width}
            height={item.height}
            constrainToContainer={true}
            linkUrl={props.linkUrl}
            position={props.position}
          />
        )
      ) : item.type === "video" ? (
        <ConditionalLink to={props.linkUrl}>
          <CaseMediaVideo
            contentUuid={item.contentUuid}
            url={item.url}
            {...(props?.videoPlayerConfig || {})}
          />
        </ConditionalLink>
      ) : null;

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.uuid || item.mediaUuid}>
        {props.caseUuid && slideContent}
      </CarouselItem>
    );
  });

  return (
    <>
      <Carousel
        className={classNames("figure1-carousel", props.className)}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}>
        {slides}
        {!props.disabled && slides.length > 1 && (
          <>
            <CarouselIndicators
              className="f1-carousel-indicators position-relative r-0 b-0 l-0 my-1 mx-auto"
              items={media}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {activeIndex > 0 && (
              <div className="f1-carousel-control f1-carousel-control--prev">
                <F1CarouselControlButton
                  className="shadow-lg"
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                  buttonColor="white"
                  iconColor="darkest"
                />
              </div>
            )}
            {activeIndex < slides.length - 1 && (
              <div className="f1-carousel-control f1-carousel-control--next">
                <F1CarouselControlButton
                  className="f1-carousel-control-btn"
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                  buttonColor="white"
                  iconColor="darkest"
                />
              </div>
            )}
          </>
        )}
      </Carousel>
    </>
  );
};

CaseMediaView.propTypes = {
  className: PropTypes.string,
  canNavigate: PropTypes.bool,
  caseUuid: PropTypes.string,
  media: PropTypes.arrayOf(MediaProp),
  showImages: PropTypes.bool,
  linkUrl: PropTypes.string,
  context: PropTypes.string,
  disabled: PropTypes.bool
};

CaseMediaView.defaultProps = {
  canNavigate: false
};

export default CaseMediaView;
