/** @format */

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import Figure1Layout from "../../@layouts/Figure1Layout";
import ProfilePage from "./ProfilePage";
import ProfileEditEducationModal from "./components/ProfileEditEducationModal";
import ProfileEditExperienceModal from "./components/ProfileEditExperienceModal";
import ProfileEditAffiliationModal from "./components/ProfileEditAffiliationModal";
import ProfileEditPracticeAreasModal from "./components/ProfileEditPracticeAreasModal";
import ProfileEditSubSpecialtiesModal from "./components/ProfileEditSubSpecialtiesModal";
import ProfileEditImageModal from "./components/ProfileEditImageModal";
import { withOpenModalManagement } from "../../components/common/WithModalManagement";
import ProfileEditInfoModal from "./components/ProfileEditInfoModal";
import QueryPreserveRedirect from "../../components/common/QueryPreserveRedirect";
import AppPromptAuthenticated from"../../components/app-prompt/AppPromptAuthenticated";

const Profile = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const { userUuid } = useParams();
  const isCurrentUser = useSelector(
    (state) => state.user.userUuid === userUuid
  );

  const profileDetailRoute = getUrl(ROUTES.PROFILE_DETAIL, {
    userUuid
  });

  const editProfileRoutes = useMemo(
    () => [
      {
        path: ROUTES.PROFILE_EDIT_INFO,
        component: withOpenModalManagement(ProfileEditInfoModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_IMAGE,
        component: withOpenModalManagement(ProfileEditImageModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_SUBSPECIALTIES,
        component: withOpenModalManagement(ProfileEditSubSpecialtiesModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_PRACTICE_AREAS,
        component: withOpenModalManagement(ProfileEditPracticeAreasModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_EXPERIENCE,
        component: withOpenModalManagement(ProfileEditExperienceModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_EDUCATION,
        component: withOpenModalManagement(ProfileEditEducationModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      },
      {
        path: ROUTES.PROFILE_EDIT_MEMBERSHIP,
        component: withOpenModalManagement(ProfileEditAffiliationModal),
        props: { userUuid, onClosed: () => history.replace(profileDetailRoute) }
      }
    ],
    [userUuid, profileDetailRoute, history]
  );

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const redirectIfNotCurrentUser = (pageComponent) => {
    if (!isCurrentUser) {
      return (
        <QueryPreserveRedirect
          preserveQueryString={true}
          to={profileDetailRoute}
        />
      );
    }
    return pageComponent;
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Figure1Layout footer={<AppPromptAuthenticated />}>
        <ProfilePage userUuid={userUuid} />
      </Figure1Layout>
      {redirectIfNotCurrentUser(
        <Switch>
          {editProfileRoutes.map((route) => (
            <Route key={route.path} path={route.path}>
              <route.component {...route.props} />
            </Route>
          ))}
        </Switch>
      )}
    </>
  );
};

export default Profile;
