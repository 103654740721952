import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import LoginForm from "./components/LoginForm";
import { useHistory, useLocation } from "react-router-dom";
import { setRedirectRoute } from "../../actions/login.actions";
import { hideGlobalMessage } from "../../actions/global.actions";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import useInitialRouteRedirect from "../../hooks/useInitialRouteRedirect";
import { isMobile } from "react-device-detect";
import i18n from "../../utils/i18n";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import BecomeMemberCTA from "./components/BecomeMemberCTA";
import { Card } from "reactstrap";
import AppPromptUnauthenticated from "../../components/app-prompt/AppPromptUnauthenticated";

const LoginPage = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();

  const loc = useLocation();

  const urlParams = new URLSearchParams(loc.search);
  const redirect = urlParams.get("redirect");

  /** ********************************* FUNCTIONS *************************************/

  useInitialRouteRedirect();

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirectRoute(redirect));
      history.replace(loc.pathname, {});
    }
  }, [history, dispatch, loc, redirect]);

  // hide error toast when moving away from login screen
  useEffect(() => {
    const unsub = history.listen(() => {
      dispatch(hideGlobalMessage());
    });

    return () => {
      unsub();
    };
  }, [history, dispatch]);

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-0 mt-lg-5">
            <Card className="p-4 mb-4 rounded-0 shadow">
              <h1
                className={`helv-bold text-center ${
                  isMobile ? "text-18 mb-3" : "text-22 mb-5"
                } `}>
                {i18n.t("RegistrationScreens.loginForm.title")}
              </h1>
              <LoginForm />
              {isMobile && (
                <div className="mt-3">
                  <BecomeMemberCTA />
                </div>
              )}
              <div className="mt-4">
                <AppPromptUnauthenticated isDark={false} />
              </div>
            </Card>
            {!isMobile && (
              <>
                <p className="text-center text-15 mb-4">
                  <span className="text-battleship-gray">
                    {i18n.t("RegistrationScreens.loginForm.bySigningIn")}
                  </span>
                  <ExternalTextLink
                    className="text-cool-blue cursor-pointer"
                    url={StaticStrings.termsOfServiceUrl}
                    linkText={i18n.t(
                      "RegistrationScreens.loginForm.termsAndConditions"
                    )}
                  />
                </p>
                <Card className="p-4 mb-4 rounded-0 shadow">
                  <BecomeMemberCTA />
                </Card>
              </>
            )}
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
        noGatedHeader={true}
      />
    </Figure1Layout>
  );
};

export default LoginPage;
