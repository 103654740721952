/** @format */

import {
  DISPLAY_REACTION_START,
  DISPLAY_REACTION_END,
  SAVE_CASE_COMPLETE,
  REPORT_CASE_COMPLETE,
  SUBMIT_CASE_REACTION_COMPLETE,
  SUBMIT_CASE_UPDATE_COMPLETE,
  SAVE_CASE,
  REPORT_CASE,
  SUBMIT_CASE_UPDATE,
  FETCH_DETAILS_START,
  SUBMIT_CASE_REACTION,
  FETCH_DETAILS_COMPLETE,
  DETAILS_UPDATED,
  USER_ACTIONS_UPDATED,
  RELATED_CASES_UPDATED,
  DETAILS_LISTENER_UPDATE,
  USER_ACTIONS_LISTENER_UPDATED,
  RELATED_CASES_LISTENER_UPDATE,
  DETAILS_RESET,
  SYNC_CASE,
  SYNC_CASE_COMPLETE,
  CASE_PUBLICATIONS_UPDATED,
  CASE_PUBLICATIONS_LISTENER_UPDATE
} from "../actions/case.actions";
import CASE_TYPE from "../constants/case-type";
import { processCaseDataForBundles } from "../utils/cme-utils";
import {
  isInteractiveCase,
  processInteractiveCaseDetailsContentItems
} from "../utils/tactic-utils";
import { DISPLAYABLE_CASE_STATES } from "../constants/case-state";

export const INITIAL_STATE = {
  /**
   * cases is a map of case status with caseUuid as key,
   * value is a map as
   * {
   *   caseData: null
   *   userReactions: [],
   *   relatedCases: [],
   *   isProcessing: false,
   *   error: false,
   *   message: null,
   *   listeners: {}
   * }
   *
   * From now one, caseUuid is required in all case actions except displayReaction
   * which is a global UI state
   * */
  cases: {},
  syncing: [],
  displayReaction: false
};

const caseReducer = (state = INITIAL_STATE, action) => {
  const getCaseStatus = () => {
    if (!action.caseUuid) {
      console.error("caseUuid missing from action ", action);
    }
    const caseStatus = state.cases[action.caseUuid] || {};
    return { ...caseStatus };
  };

  switch (action.type) {
    case DISPLAY_REACTION_START:
      return { ...state, displayReaction: true };
    case DISPLAY_REACTION_END:
      return { ...state, displayReaction: false };
    case SAVE_CASE_COMPLETE:
    case REPORT_CASE_COMPLETE:
    case SUBMIT_CASE_REACTION_COMPLETE:
    case SUBMIT_CASE_UPDATE_COMPLETE: {
      const caseStatus = getCaseStatus();
      caseStatus.isProcessing = false;
      caseStatus.error = action.error;
      caseStatus.message = action.message;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case SAVE_CASE:
    case REPORT_CASE:
    case SUBMIT_CASE_UPDATE:
    case FETCH_DETAILS_START: {
      const caseStatus = getCaseStatus();
      caseStatus.isProcessing = true;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case SUBMIT_CASE_REACTION: {
      const caseStatus = getCaseStatus();
      caseStatus.isProcessing = true;
      caseStatus.userReactions = action.isOn ? [action.reaction] : [];
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case FETCH_DETAILS_COMPLETE:
    case DETAILS_UPDATED: {
      const caseDetails = action.data;
      const caseStatus = getCaseStatus();
      caseStatus.isProcessing = false;
      caseStatus.data = caseDetails;

      if (isInteractiveCase(caseDetails?.caseType)) {
        caseStatus.data.contentItems =
          processInteractiveCaseDetailsContentItems(caseDetails.contentItems);
      }

      if (
        [CASE_TYPE.CME, CASE_TYPE.CLINICAL_MOMENTS].includes(
          caseDetails?.caseType
        )
      ) {
        processCaseDataForBundles(caseStatus.data);
      }

      caseDetails.displayable = !!DISPLAYABLE_CASE_STATES.includes(
        caseDetails.caseState
      );

      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case USER_ACTIONS_UPDATED: {
      const caseStatus = getCaseStatus();
      caseStatus.userReactions = action.actions.reactions;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case RELATED_CASES_UPDATED: {
      const caseStatus = getCaseStatus();
      caseStatus.relatedCases = action.relatedCases;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case CASE_PUBLICATIONS_UPDATED: {
      const caseStatus = getCaseStatus();
      caseStatus.casePublications = action.casePublications;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case DETAILS_LISTENER_UPDATE: {
      const caseStatus = getCaseStatus();
      const listeners = caseStatus.listeners || {};
      listeners.detailListener = action.detailListener;
      caseStatus.listeners = listeners;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case USER_ACTIONS_LISTENER_UPDATED: {
      const caseStatus = getCaseStatus();
      const listeners = caseStatus.listeners || {};
      listeners.userActionsListener = action.userActionsListener;
      caseStatus.listeners = listeners;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case RELATED_CASES_LISTENER_UPDATE: {
      const caseStatus = getCaseStatus();
      const listeners = caseStatus.listeners || {};
      listeners.relatedCasesListener = action.relatedCasesListener;
      caseStatus.listeners = listeners;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case CASE_PUBLICATIONS_LISTENER_UPDATE: {
      const caseStatus = getCaseStatus();
      const listeners = caseStatus.listeners || {};
      listeners.casePublicationsListener = action.casePublicationsListener;
      caseStatus.listeners = listeners;
      return {
        ...state,
        cases: { ...state.cases, [action.caseUuid]: caseStatus }
      };
    }
    case DETAILS_RESET:
      return { ...state, cases: { ...state.cases, [action.caseUuid]: {} } };
    case SYNC_CASE:
      return { ...state, syncing: [...state.syncing, action.caseUuid] };
    case SYNC_CASE_COMPLETE:
      return {
        ...state,
        syncing: state.syncing.filter((u) => u !== action.caseUuid)
      };
    default:
      return state;
  }
};

export default caseReducer;
