/** @format */
import _ from "lodash";
import { VERIFICATION_STATUS } from "../constants/verification-status";
import {
  PROFESSION_CATEGORY_LABELS,
  OTHER_PROFESSION_CATEGORY_LABELS
} from "../constants/profession-category-labels";
import ONBOADRING_STATE_V2 from "../constants/onboarding-state-v2";

/**
 * Taken from old registration code. Not sure if verified is reliable or
 * if it relies on username and specialties being selected.
 */
export const isVerifiedUser = (user) => {
  return (
    user.verified ||
    (user.verification?.verificationStatus &&
      user.verification?.verificationStatus === VERIFICATION_STATUS.VERIFIED)
  );
};

export const isUserCompletedSignup = (user) => {
  return (
    user.onboardingState === ONBOADRING_STATE_V2.COMPLETED ||
    user.onboardingCompleted
  );
};
/**
 * This is from old registration code. Not sure if it is safe to
 * use specific states, like pending manual etc, instead of
 * not unknown.
 *
 * TODO: Confirm if it's safe to use specific verification statuses
 * instead of not unknown. From what I can tell, it doesn't look like we
 * have anything to handle states outside of `VERIFIED` so I don't
 * feel confident in changing this.
 */
export const isVerificationInProgress = (user) => {
  return (
    user.verification?.verificationStatus &&
    user.verification?.verificationStatus !== VERIFICATION_STATUS.UNKNOWN
  );
};

export const isLegacyUser = (user) => {
  return user.legacyAccount;
};

/**
 * Checks if essential profile data is missing in legacy user's profile.
 * All of this info is requested in Legacy onboarding if any is missing.
 */
export const isMigratedLegacyUser = (user) => {
  const missingCoreInfo =
    !user.firstName ||
    !user.lastName ||
    _.isEmpty(user.primarySpecialty) ||
    _.isEmpty(user.professionUuid);

  return isLegacyUser(user) && !missingCoreInfo && user.onboardingCompleted;
};

/**
 * Return true if user has a specialty.
 *
 * Note: Using `.primarySpecialty` from new structure, everything else is old structure.
 */
export const hasPrimarySpecialty = (user) =>
  !_.isEmpty(getUserPrimarySpecialty(user));

export const isOtherProfession = (user) => {
  const userProfession = getFormattedUserProfession(user);
  return OTHER_PROFESSION_CATEGORY_LABELS.includes(
    userProfession?.professionCategoryLabel
  );
};

/**
 * Return user's primary specialty object. If Other HCP/Student, return profession.
 * Otherwise return subspecialty or specialty.
 *
 * Note: Take subspecialty over specialty since it also contains
 * specialty but with an extra degree of specialty.
 * @param user
 */
export const getUserPrimarySpecialty = (user) => {
  const _isOtherProfession = isOtherProfession(user);
  const tree = user?.primarySpecialty?.tree;

  // Other HCP/Student don't have specialty/subspecialty.
  if (_isOtherProfession) {
    return tree?.profession;
  } else {
    return tree?.subspecialty || tree?.specialty;
  }
};

/**
 * Return formatted profession. Return empty object if no values found.
 */
export const getFormattedUserProfession = (user) => {
  const professionName = user?.professionName;
  const professionUuid = user?.professionUuid;
  const professionCategoryLabel =
    user?.primarySpecialty?.tree?.profession?.professionCategoryLabel;

  return {
    ...(professionName && { professionName }),
    ...(professionUuid && { professionUuid }),
    ...(professionCategoryLabel && { professionCategoryLabel })
  };
};

/**
 * Return true if non HCP.
 *
 * Note: Relies on primarySpecialty to be populated with profession with non-HCP.
 *
 * @param {*} user
 */
export const isNonHCP = (user) => {
  const userProfession = getFormattedUserProfession(user);

  return (
    userProfession?.professionCategoryLabel ===
      PROFESSION_CATEGORY_LABELS.NON_HCP ||
    userProfession?.professionCategoryLabel ===
      PROFESSION_CATEGORY_LABELS.LEGACY_OTHER_NON_HCP_STUDENT
  );
};

export const getSecondarySpecialtyUuids = (user) => {
  return user.secondarySpecialties.map((s) => s.treeUuid);
};
