import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../utils/i18n";
import { useHistory, useLocation } from "react-router-dom";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import { Button } from "reactstrap";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { trackGatedCTA } from "../../actions/metrics.actions";
import "./GatedButtons.scss";
import LOCAL_STORAGE_CONSTANTS from "../../constants/local-storage-constants";
import { handleUngatedSignIn } from "../../actions/login.actions";
import { getAndParseLocalStorageItem } from "../../utils/local-storage-utils";

const GatedButtons = ({
  campaignUuid,
  caseUuid,
  caseType,
  source,
  color = "primary"
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // if we have a uuid, we have a record of em
  const userExists = !!useSelector((state) => state.user?.userUuid);

  const onSignIn = async () => {
    trackGatedCTA({
      campaignUuid,
      caseUuid,
      caseType,
      source,
      action: UNGATED_ACTIONS.ACTION.SIGN_IN
    });
    const authUser = getAndParseLocalStorageItem(
      LOCAL_STORAGE_CONSTANTS.AUTH_USER
    );
    if (!authUser.isAnonymous) {
      dispatch(handleUngatedSignIn());
    }

    history.push(`${SIGN_IN}?redirect=${location.pathname}`);
  };

  const onBecomeMember = () => {
    trackGatedCTA({
      campaignUuid,
      caseUuid,
      caseType,
      source,
      action: UNGATED_ACTIONS.ACTION.SIGN_UP
    });
    history.push(`${SIGN_UP}?redirect=${location.pathname}`);
  };

  // If not signed in, show become member and sign in and continue
  // if signed in but not completed, show sign in and continue
  const signInProps = !userExists ? { outline: true } : {};

  const signInButton = (
    <Button className="cta" color={color} onClick={onSignIn} {...signInProps}>
      {i18n.t("Gated.SignIn")}
    </Button>
  );

  const becomeMemberButton = userExists ? null : (
    <Button className="cta mb-3" color={color} onClick={onBecomeMember}>
      {i18n.t("Gated.Banner.becomeMember")}
    </Button>
  );

  return (
    <>
      {becomeMemberButton}
      {signInButton}
    </>
  );
};

GatedButtons.propTypes = {};

export default GatedButtons;
