import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import i18n from "../../../utils/i18n";
import Spinner from "reactstrap/lib/Spinner";

import "./LegacyTransitionModal.scss";

const LegacyTransitionModal = ({ isOpen, ...rest }) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Modal
      className="legacy-transition-modal"
      centered
      isOpen={isOpen}
      {...rest}>
      <ModalBody className="pt-5 pb-4">
        <div className=" d-flex h-100 flex-column justify-content-center align-items-center">
          <Spinner size="lg" className="mb-3" color="primary" />
          <h2 className="mb-3 helv-med">{i18n.t("LegacyMigration.title")}</h2>
          <p className="color-f1-gray">
            {i18n.t("LegacyMigration.p1")}
            <br />
            {i18n.t("LegacyMigration.p2")}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

LegacyTransitionModal.propTypes = {
  isOpen: PropTypes.bool
};

LegacyTransitionModal.defaultProps = {};

export default LegacyTransitionModal;
